<template>
<div class="modal is-active">
	<div class="modal-background"></div>
	<div class="modal-content">
		<slot></slot>
		<button class="modal-close is-large" aria-label="close" @click="close"></button>
	</div>
</div>
</template>

<script>
export default {
	methods: {
		close: function() {
			this.$emit('close');
		}
	}
}
</script>

<style>
.modal-content {
	background-color: #FFF;
	border-radius: 0.5rem;
	position: relative;
}

.modal-close {
	position: absolute !important;
}

.modal-close::before, .modal-close::after {
	background-color: #000 !important;
}
</style>