<template>
<div>
    <div v-show="kpi_trail.length > 0" class="button float-left is-info mr" @click="back()">Back</div>
    <div class="text-left is-size-4 is-capitalized">{{kpi_trail_bread_crumb}}</div>
    
    <div v-if="filtered_options.length">
        <div 
            v-if="kpi_trail.length === 0 && global_location"
            class="kpi-choice"
            @click="choose({name: 'Global', path: '/'})"
        >
            Global
        </div>
        <div class="kpi-choice" v-for="(kpi, index) in filtered_options" :key="index" @click="choose(kpi)">
            {{kpi.name}}
        </div>
    </div>
</div>
</template>

<script>
import MenuOptions from '@/assets/scripts/value-creation-mapping.js';
export default {
    props: {
        global_location: Boolean
    },
    data: function() {
        return {
            filtered_options: MenuOptions,
            kpi_counter: 0,
            kpi_trail: []
        }
    },
    methods: {
        back: function() {
            this.filtered_options = MenuOptions;
            if (this.kpi_trail.length > 1) {
                this.kpi_trail.pop();
                this.kpi_trail.forEach(kpi => {
                    this.choose({name: kpi}, true);
                });
            } else {
                this.kpi_trail = [];
            }
            this.$emit('reset', null);
        },
        choose: function(kpi, back = false) {
            
            let selected_area = this.filtered_options.filter(option => {
                return option.name === kpi.name
            }).pop();
            if (!this.global_location && 'areas' in selected_area) {
                this.filtered_options = selected_area.areas;
            } else {
                this.filtered_options = [];
                this.$emit('chosen', kpi);
            }
            if (!back) this.kpi_trail.push(kpi.name);
        }
    },
    computed: {
        kpi_trail_bread_crumb: function() {
            let bread_crumb = this.kpi_trail.map((kpi, index) => index ? ` > ${kpi}` : kpi);
            return bread_crumb.join('');
        }
    }
}
</script>

<style>
.kpi-choice {
    background-color: #BEC;
    border-radius: 5px;
    margin: 0.2em;
    padding: 0.5em;
}

.kpi-choice:hover {
    background-color: rgb(101, 235, 146);
    color: #FFF;
    cursor: pointer;
}
</style>
