export default [
    {
        "name": "furniture-that-fits-my-budget",
        "path": "/three-roads/furniture-that-fits-my-budget"
    },
    {
        "name": "low-price-in-reality",
        "path": "/three-roads/low-price-in-reality"
    },
    {
        "name": "penetration",
        "path": "/three-roads/penetration"
    },
    {
        "name": "positive-impact",
        "path": "/three-roads/positive-impact"
    },
    {
        "name": "desire",
        "path": "/brand/desire"
    },
    {
        "name": "trust",
        "path": "/brand/trust"
    },
    
    {
        "name": "sales-trend-total",
        "path": "/business/total-sales/sales-trend-total"
    },
    {
        "name": "sales-tree",
        "path": "/business/sales-tree"
    },
    {
        "name": "market-share",
        "path": "/business/market-share"
    },

    {
        "name": "hcs",
        "path": "/customer/happy-customer"
    },

    {
        "name": "operational-gross-margin",
        "path": "/finance/operational-gross-margin"
    },
    {
        "name": "cost-per-country-vs-goal",
        "path": "/finance/cost-per-country-vs-goal"
    },

    {
        "name": "leadership-index",
        "path": "/people/leadership-index"
    },
    {
        "name": "engagement-index",
        "path": "/people/engagement-index"
    },
    {
        "name": "ikea-values",
        "path": "/people/ikea-values"
    },
    {
        "name": "balanced-turnover",
        "path": "/people/balanced-co-worker-turnover"
    },
    {
        "name": "gender-share",
        "path": "/people/gender-manager-share"
    },

    {
        "name": "sustainable-sales",
        "path": "/sustainability/sustainable-sales"
    },
    {
        "name": "planetary-footprint",
        "path": "/sustainability/climate-footprint"
    },

    {
        "name": "sales-planning-precision",
        "path": "/process/sales-planning-precision"
    },
    {
        "name": "procurement",
        "path": "/process/procurement"
    }
]
