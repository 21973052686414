<template>
<div>
    <AdminUniversal
        component="title"
        :props="title_props"
    />
    
    <KPIChooser
        @chosen="kpi_chosen"
        @reset="chosen_kpi = null"
        class="kpi-chooser"
    />
    
    <div v-show="chosen_kpi" class="editor-wrapper">
        <Title
            v-if="chosen_kpi"
            :title="chosen_kpi.name"
            subtitle="KPI Definition"
        />
        
        <div class="text-title-left">
            Current Definition
        </div>
        <div v-if="current_definition" class="current-definition" v-html="current_definition"></div>

        <div class="text-title-left">
            New Definition
        </div>
        <WYSIWYG
            v-if="current_definition"
            :content="current_definition"
            @textUpdate="update_text"

            class="new-definition"
        />
        <a class="button is-success" @click="update_definition">Update Definition</a>
    </div>

</div>
</template>

<script>
import AdminUniversal from '@/components/admin/index.vue';
import KPIChooser from '@/components/admin/KPIChooser.vue';
import Title from '@/components/admin/Title.vue';
import WYSIWYG from '@/components/bespoke/commenting/WYSIWYGEditor.vue';

export default {
    components: {
        AdminUniversal,
        KPIChooser,
        Title,
        WYSIWYG
    },
    data: function() {
        return {
            title_props: {
                title: 'KPI Definitions'
            },

            chosen_kpi: null,
            updated_definition: null,
            current_definition: null,
            new_definition: null,
            location: null,
        }
    },
    methods: {
        kpi_chosen: function(kpi) {
            this.chosen_kpi = kpi;
            this.location = this.$helper.seperate_link_into_areas(kpi.location || kpi.path);
            this.current_definition = null;
            this.$http.post('/admin/kpi-definition/load', {
                location: this.location
            }).then(
                succ => {
                    if (succ.data.length === 0 || succ.data[0].definition === '<p></p>' || succ.data[0].definition === null) {
                        this.current_definition = 'There is no definition for the kpi';
                    } else {
                        this.current_definition = succ.data[0].definition;
                    }
                },
                this.$helper.error
            );
        },
        update_text: function(text) {
            this.updated_definition = text;
        },
        update_definition: function() {
            this.$http.post('/admin/kpi-definition/update', {
                location: this.location,
                definition: this.updated_definition
            }).then(
                succ => {
                    this.current_definition = this.updated_definition;
                },
                this.$helper.error
            );
        }
    },
    computed: {
        current_kpi: function() {
            if (this.chosen_kpi) {
                return this.chosen_kpi[this.chosen_kpi.length-1];
            }
        }
    }
}
</script>

<style scoped>
.current-definition {
    background-color: #f3f3f3;
    border-radius: 5px;
    color: #666;
    margin: 0.5em 1.5em 1.5em 1.5em;
    padding: 1em;
}
.editor-wrapper {
    max-width: 600px;
}
.kpi-chooser {
    max-width: 500px;
}

.new-definition {
    margin: 0.5em 1.5em 1.5em 1.5em;
}

.section-small {
    padding: 1.5rem;
}
</style>