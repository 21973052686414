<template>
<div>
    <Title
        title="People + Planet Positive Sales"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <Table
        v-if="table.render"
        :key="table.headers.length"

        :headers="table.headers"
        :rows="table.rows"

        :decimal-settings="table.decimal_settings"
        :icon-settings="table.icon_settings"
        :bridge-settings="table.bridge_settings"
        :sort-settings="{ 'Country': { asc: true } }"
        :font-settings="{ bold: {row: {regex: 'Total Retail Countries' } } }"
        class="ms1p5"
    />
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: [],
                decimal_settings: {},
                render: true
            },
            location: '/sustainability/sustainable-sales',
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.load_total_ingka_by_country();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
            ];
        },
        load_total_ingka_by_country: function() {
            this.$http.get('/sustainability/sales/total-ingka-by-country').then(
                succ => {
                    let d = succ.data;
                    if (d[0].measure_metadata) {
                        this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                    }
                    this.table.headers = ['Country'].concat(Object.keys(d[0].measures));
                    this.table.headers, this.table.rows = [];
                    this.table.headers = [
                        // Row 1
                        [{'': 1}, {'Sustainable Sales Share': 3}, {'Sustainable Sales (MEUR)': 4}],
                        //Row 2
                        [
                            {'Country': 1},

                            {'YTD': 1, alias: 'Share YTD'},
                            {'1WK Trend': 1, alias: 'Share Week'},
                            {'Goal': 1, alias: 'Share Goal'},

                            {'YTD': 1, alias: 'MEUR YTD'},
                            {'YTD Index': 1, alias: 'MEUR YTD Index'},        
                            {'Week Trend': 1, alias: 'MEUR Week'},
                            {'Week Index': 1, alias: 'MEUR Week Index'}
                        ]
                    ];

                    this.table.bridge_settings = {
                        columns: {
                            'Sustainable Sales Share': { hide: true },
                            'Sustainable Sales (MEUR)': { hide: true }
                        }
                    }
                    this.table.icon_settings = {
                        'Share YTD': {
                            comparison: 'Share Goal',
                            icon: 'circle'
                        },
                        'Share Week': {
                            comparison: 'Share YTD',
                            icon: 'arrow'
                        },
                    }
                    this.table.rows = d.map(row => {
                    let val = row.measures;
                    let t = [row.caption];
                    // Push the measures
                    t.push(
                        val["YTD Sustainable sales share"],
                        val["Week Sustainable sales share"],
                        val["Year Goal"],
                        val["YTD"],
                        val["YTD Index"],
                        val["Week"],
                        val["Week Index"]
                    )
                    return t;
                });
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        }
    }
}
</script>

<style>

</style>
