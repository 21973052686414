<template>
<div class="field">
    <div class="control">
        <button @click="method" :class="classes" class="button">{{label}}</button>
    </div>
</div>
</template>

<script>
export default {
    props: {
        classes: String,
        label: String,
        method: Function
    }
}
</script>
