import IKEABusinessShareOfSales from '@/views/business/IKEABusinessShareOfSales.vue';
import IKEABusinessShareOfSalesMarket from '@/views/business/IKEABusinessShareOfSalesMarket.vue';
import IKEABusinessShareOfSalesTop8 from '@/views/business/IKEABusinessShareOfSalesTop8.vue';

import MarketShareTrend from '@/views/business/MarketShareTrend.vue';

import MarketShareCalculator from '@/views/business/MarketShareCalculator.vue';
import MarketShareCalculatorTrend from '@/views/business/MarketShareCalculatorTrend.vue';
import MarketSharePrioritisedCities from '@/views/business/MarketSharePrioritisedCities.vue';

import StoreFulfilment38 from '@/views/business/StoreFulfilment38.vue';
import StoreFulfilment78 from '@/views/business/StoreFulfilment78.vue';
import StoreFulfilmentMarket78 from '@/views/business/StoreFulfilmentMarket78.vue';
import StoreFulfilmentTop878 from '@/views/business/StoreFulfilmentTop878.vue';

import StockLevels from '@/views/business/StockLevels.vue';

import SalesTree from '@/views/business/SalesTree.vue';
import SalesTreeDetailedOld from '@/views/business/SalesTreeDetailedOld.vue';
import SalesTreeDetailed from '@/views/business/SalesTreeDetailed.vue';
import SalesTreeHidden from '@/views/business/SalesTreeHidden.vue';
import SalesTreeMarket from '@/views/business/SalesTreeMarket.vue';
import SalesTreeByMarket from '@/views/business/SalesTreeByMarket.vue';
import SalesTreeTop8 from '@/views/business/SalesTreeTop8.vue';

import StockAccuracy from '@/views/business/StockAccuracy.vue';
import StockAccuracyTrend from '@/views/business/StockAccuracyTrend.vue';
import StockAccuracyTop8 from '@/views/business/StockAccuracyTop8.vue';

import CustomerDist from '@/views/business/CustomerDist.vue';
import CustomerDistTrend from '@/views/business/CustomerDistTrend.vue';
import CustomerDistTop8 from '@/views/business/CustomerDistTop8.vue';

import HfAccessories from '@/views/business/sales-per-range/HfAccessories.vue';
import HfAccessoriesPA from '@/views/business/sales-per-range/HfAccessoriesPA.vue';
import HfAccessoriesMarket from '@/views/business/sales-per-range/HfAccessoriesMarket.vue';
import HfAccessoriesTop8 from '@/views/business/sales-per-range/HfAccessoriesTop8.vue';

import LeadTimes from '@/views/business/LeadTimes.vue';
import LeadTimesTrend from '@/views/business/LeadTimesTrend.vue';
import LeadTimesTop8 from '@/views/business/LeadTimesTop8.vue';

import GoodsValueConnected from '@/views/business/sales-per-range/GoodsValueConnected.vue';
import GoodsValueConnectedMarket from '@/views/business/sales-per-range/GoodsValueConnectedMarket.vue';
import GoodsValueConnectedTop8 from '@/views/business/sales-per-range/GoodsValueConnectedTop8.vue';
import GoodsValueConnectedByHFB from '@/views/business/sales-per-range/GoodsValueConnectedByHFB.vue';

import FoodSales from '@/views/business/sales-per-range/FoodSales.vue';
import FoodSalesTrend from '@/views/business/sales-per-range/FoodSalesTrend.vue';
import FoodSalesTop8 from '@/views/business/sales-per-range/FoodSalesTop8.vue';
import FoodSalesByMarket from '@/views/business/sales-per-range/FoodSalesByMarket.vue';
import FoodSalesTree from '@/views/business/sales-per-range/FoodSalesTree.vue';

import Hfb from '@/views/business/sales-per-range/sales-per-hfb/Hfb.vue';
import HfbMarket from '@/views/business/sales-per-range/sales-per-hfb/HfbMarket.vue'; 
import HfbTop8 from '@/views/business/sales-per-range/sales-per-hfb/HfbTop8.vue';
import HfbByHfb from '@/views/business/sales-per-range/sales-per-hfb/ByHfb.vue';
import HfbCCpHfb from '@/views/business/sales-per-range/sales-per-hfb/CCpHfb.vue';

import CoreAreas from '@/views/business/sales-per-range/core-areas/CoreAreas.vue';
import CoreAreasMarket from '@/views/business/sales-per-range/core-areas/CoreAreasMarket.vue';
import CoreAreasTop8 from '@/views/business/sales-per-range/core-areas/CoreAreasTop8.vue';
import CoreAreasTopFamilies from '@/views/business/sales-per-range/core-areas/CoreAreasTopFamilies.vue';
import CoreAreasGrowthContribution from '@/views/business/sales-per-range/core-areas/CoreAreasGrowthContribution.vue';
import CoreAreasSalesInMEUR from '@/views/business/sales-per-range/core-areas/CoreAreasSalesinMEUR.vue';
import CoreAreasIndex from '@/views/business/sales-per-range/core-areas/CoreAreasIndex.vue';

import SalesTrendTotal from '@/views/business/total-sales/SalesTrendTotal.vue';
import SalesTrendTotalByMarket from '@/views/business/total-sales/SalesTrendTotalByMarket.vue';
import SalesTrendTotalSummary from '@/views/business/total-sales/SalesTrendTotalSummary.vue';
import SalesTrendTotalTrend from '@/views/business/total-sales/SalesTrendTotalTrend.vue';

import SalesContributionPerCountry from '@/views/business/total-sales/SalesContributionPerCountry.vue';
import SalesContributionPerCountryByMarket from '@/views/business/total-sales/SalesContributionPerCountryByMarket.vue';

import SalesAndQuantityPerCountry from '@/views/business/total-sales/SalesAndQuantityPerCountry.vue';
import SalesAndQuantityPerCountryTrend from '@/views/business/total-sales/SalesAndQuantityPerCountryTrend.vue';
import SalesAndQuantityPerCountryTop8 from '@/views/business/total-sales/SalesAndQuantityPerCountryTop8.vue';

import SalesPerCountryVsGoal from '@/views/business/total-sales/SalesPerCountryVsGoal.vue';
import SalesPerCountryVsGoalByMarket from '@/views/business/total-sales/SalesPerCountryVsGoalByMarket.vue';

import SalesPrioritised30Cities from '@/views/business/total-sales/SalesPrioritised30Cities.vue';
import SalesPrioritised30CitiesAccumulated from '@/views/business/total-sales/SalesPrioritised30CitiesAcc.vue';
import SalesPrioritised30CitiesBubbleGraph from '@/views/business/total-sales/SalesPrioritised30CitiesBubbleGraph.vue';

import ChannelContributionPerCountry from '@/views/business/total-sales/ChannelContributionPerCountry.vue';
import ChannelContributionPerCountryTest from '@/views/business/total-sales/ChannelContributionPerCountryTest.vue';

import AvailabilityTotal from '@/views/business/AvailabilityTotal.vue';
import AvailabilityDetail from '@/views/business/AvailabilityDetail.vue';

import ProductAvailabilityTotal from '@/views/business/ProductAvailabilityTotal.vue';
import ProductAvailabilityDetail from '@/views/business/ProductAvailabilityDetail.vue';

import TopFamiliesGrowthTop10Turnover from '@/views/business/sales-per-range/TopFamiliesGrowthTop10Turnover.vue';
import TopFamiliesGrowthTop10Quantity from '@/views/business/sales-per-range/TopFamiliesGrowthTop10Quantity.vue';

import FlowMatrix from '@/views/business/total-sales/FlowMatrix.vue';

import FinancialServices from '@/views/business/FinancialServices.vue';
import FinancialServicesTrend from '@/views/business/FinancialServicesTrend.vue';
import FinancialServicesTop8 from '@/views/business/FinancialServicesTop8.vue';

export default [
    {
        path: '/business/availability',
        component: AvailabilityTotal,
    },
    {
        path: '/business/availability/detail',
        component: AvailabilityDetail,
    },
    // {
    //     path: '/business/product-availability',
    //     component: ProductAvailabilityTotal,
    // },
    // {
    //     path: '/business/product-availability/detail',
    //     component: ProductAvailabilityDetail,
    // },
    {
        path: '/business/stock-levels/:market',
        component: StockLevels,
        props: true,
    },
    // {
    //     path: '/business/ikea-family-share-of-sales',
    //     props: {template: './business/IKEAFamilyShareofSales/by_country.js'},
    // },
    // {
    //     path: '/business/ikea-family-share-of-sales/top-10',
    //     props: {template: './business/IKEAFamilyShareofSales/Top_10.js'}
    // },
    // {
    //     path: '/business/ikea-family-share-of-sales/:market',
    //     props: route => ({
    //         template: './business/IKEAFamilyShareofSales/by_trend.js',
    //         market: route.params.market
    //     })
    // },
    // {
    //     path: '/business/ikea-business-share-of-sales-b2b',
    //     props: {template: './business/B2B/by_country.js'},
    // },
    {
        path: '/business/ikea-business-share-of-sales-b2b/top-10',
        props: {template: './business/B2B/Top_10.js'}
    },
    {
        path: '/business/ikea-business-share-of-sales-b2b/:market',
        props: route => ({
            template: './business/B2B/by_trend.js',
            market: route.params.market
        })
    },    
    {
        path: '/business/ikea-business-share-of-sales',
        component: IKEABusinessShareOfSales,
    },
    {
        path: '/business/ikea-business-share-of-sales/top-8',
        component: IKEABusinessShareOfSalesTop8,
    },
    {
        path: '/business/ikea-business-share-of-sales/:market',
        component: IKEABusinessShareOfSalesMarket,
        props: true
    },
    // {
    //     path: '/business/market-share-fs',
    //     component: MarketShareTrend
    // },
    // {
    //     path: '/business/market-share/market-share-prioritized-thirty-cities',
    //     component: MarketSharePrioritisedCities
    // },
    // {
    //     path: '/business/market-share',
    //     component: MarketShareCalculator
    // },
    // {
    //     path: '/business/market-share/:market',
    //     component: MarketShareCalculatorTrend,
    //     props: true
    // },
    {
        path: '/business/store-fulfilment',
        component: StoreFulfilment38,
    },
    {
        path: '/business/store-fulfilment-7-8',
        component: StoreFulfilment78,
    },
    {
        path: '/business/store-fulfilment-7-8/top-8',
        component: StoreFulfilmentTop878,
    },
    {
        path: '/business/store-fulfilment-7-8/:market',
        component: StoreFulfilmentMarket78,
        props: true
    },
    // {
    //     path: '/business/sales-per-range/hf-accessories',
    //     name: 'Sales-hfb',
    //     component: HfAccessories,
    //     props: true
    // },
    // {
    //     path: '/business/sales-per-range/hf-accessories/by-pa',
    //     component: HfAccessoriesPA,
    // },
    // {
    //     path: '/business/sales-per-range/hf-accessories/top-8',
    //     component: HfAccessoriesTop8,
    // },
    // {
    //     path: '/business/sales-per-range/hf-accessories/:market',
    //     component: HfAccessoriesMarket,
    //     props: true
    // },
    // {
    //     path: '/business/sales-tree',
    //     component: SalesTree,
    // },
    // {
    //     path: '/business/sales-tree/tree/:market',
    //     component: SalesTreeDetailed,
    //     props: true
    // },
    {
        path: '/business/sales-tree/detailed-old',
        component: SalesTreeDetailedOld,
    },
    {
        path: '/business/sales-tree-hidden',
        component: SalesTreeHidden,
    },
    // {
    //     path: '/business/sales-tree/by-market/:market',
    //     component: SalesTreeByMarket,
    //     props: true
    // },
    // {
    //     path: '/business/sales-tree/top-8/:option',
    //     component: SalesTreeTop8,
    //     props: true
    // },
    // {
    //     path: '/business/sales-tree/:market',
    //     component: SalesTreeMarket,
    //     props: true
    // },
    // {
    //     path: '/business/stock-accuracy',
    //     component: StockAccuracy
    // },
    // {
    //     path: '/business/stock-accuracy/top-8',
    //     component: StockAccuracyTop8
    // },
    // {
    //     path: '/business/stock-accuracy/:market/:option?',
    //     component: StockAccuracyTrend,
    //     props: true
    // },
    {
        path: '/business/customer-distribution-center',
        component: CustomerDist
    },
    {
        path: '/business/customer-distribution-center/top-8',
        component: CustomerDistTop8
    },
    {
        path: '/business/customer-distribution-center/:market/:option?',
        component: CustomerDistTrend,
        props: true
    },
    {
        path: '/business/sales-per-range/goods-value-connected',
        component: GoodsValueConnected,
    },
    {
        path: '/business/sales-per-range/goods-value-connected/top-8',
        component: GoodsValueConnectedTop8,
    },
    {
        path: '/business/sales-per-range/goods-value-connected/by-hfb/:market',
        component: GoodsValueConnectedByHFB,
        props: true
    },
    {
        path: '/business/sales-per-range/goods-value-connected/:market',
        component: GoodsValueConnectedMarket,
        props: true
    },
    // {
    //     path: '/business/sales-per-range/food-sales',
    //     component: FoodSales,
    // },
    // {
    //     path: '/business/sales-per-range/food-sales/top-8',
    //     component: FoodSalesTop8,
    // },
    // {
    //     path: '/business/sales-per-range/food-sales/by-market/:market',
    //     component: FoodSalesByMarket,
    //     props: true
    // },
    // {
    //     path: '/business/sales-per-range/food-sales/:market',
    //     component: FoodSalesTrend,
    //     props: true
    // },
    // {
    //     path: '/business/sales-per-range/food-sales/tree/:market',
    //     component: FoodSalesTree,
    //     props: true
    // },
    // {
    //     path: '/business/sales-per-range/hfb',
    //     component: Hfb,
    // },
    // {
    //     path: '/business/sales-per-range/hfb/top-8',
    //     component: HfbTop8,
    // },
    // {
    //     path: '/business/sales-per-range/hfb/by-hfb/:market',
    //     component: HfbByHfb,
    //     props: true
    // },
    // {
    //     path: '/business/sales-per-range/hfb/channel-contribution/:market',
    //     component: HfbCCpHfb,
    //     props: true
    // },
    // {
    //     path: '/business/sales-per-range/hfb/:market',
    //     component: HfbMarket,
    //     props: true
    // },
    {
        path: '/business/sales-per-range/core-areas/:option',
        component: CoreAreas,
        props: true
    },
    {
        path: '/business/sales-per-range/core-areas/top-8/:option',
        component: CoreAreasTop8,
        props: true
    },
    {
        path: '/business/sales-per-range/core-areas/top-families/:option/:market',
        component: CoreAreasTopFamilies,
        props: true
    },
    {
        path: '/business/sales-per-range/core-areas/growth-contribution/:market',
        component: CoreAreasGrowthContribution,
        props: true
    },
    {
        path: '/business/sales-per-range/core-areas/sales-in-meur/:market',
        component: CoreAreasSalesInMEUR,
        props: true
    },
    {
        path: '/business/sales-per-range/core-areas/index/:market',
        component: CoreAreasIndex,
        props: true
    },
    {
        path: '/business/sales-per-range/core-areas/trend/:market',
        component: CoreAreasMarket,
        props: true
    },
    // {
    //     path: '/business/sales-per-range/top-families-growth/top-10-turnover',
    //     component: TopFamiliesGrowthTop10Turnover,
    // },
    // {
    //     path: '/business/sales-per-range/top-families-growth/top-10-quantity',
    //     component: TopFamiliesGrowthTop10Quantity,
    // },
    {
        path: '/business/lead-times',
        component: LeadTimes
    },
    {
        path: '/business/lead-times/top-8/:option',
        component: LeadTimesTop8,
        props: true
    },
    {
        path: '/business/lead-times/:option/:market',
        component: LeadTimesTrend,
        props: true
    },
    {
		path: '/business/total-sales/flow-matrix/:market',
        component: FlowMatrix,
        props: true
    },
    // {
    //     path: '/business/total-sales/sales-trend-total',
    //     component: SalesTrendTotal
    // },
    // {
    //     path: '/business/total-sales/sales-trend-total/summary/:market',
    //     component: SalesTrendTotalSummary,
    //     props: true
    // },
    // {
    //     path: '/business/total-sales/sales-trend-total/by-market/:market',
    //     component: SalesTrendTotalByMarket,
    //     props: true
    // },
    // {
    //     path: '/business/total-sales/sales-trend-total/:market',
    //     component: SalesTrendTotalTrend,
    //     props: true
    // },
    // {
    //     path: '/business/total-sales/sales-contribution-per-country',
    //     component: SalesContributionPerCountry
    // },
    // {
    //     path: '/business/total-sales/sales-contribution-per-country/:market',
    //     component: SalesContributionPerCountryByMarket,
    //     props: true
    // },
    // {
    //     path: '/business/total-sales/sales-per-country-vs-goal',
    //     component: SalesPerCountryVsGoal
    // },
    // {
    //     path: '/business/total-sales/sales-per-country-vs-goal/:market',
    //     component: SalesPerCountryVsGoalByMarket,
    //     props: true
    // },

    // {
    //     path: '/business/total-sales/sales-and-quantity-per-country',
    //     props: {template: './business/total-sales/SalesandQuantity/by_country.js'}
    // },
    // {
    //     path: '/business/total-sales/sales-and-quantity-per-country/by-hfb',
    //     props: {template: './business/total-sales/SalesandQuantity/by_hfb.js'}
    // },
    // {
    //     path: '/business/total-sales/sales-and-quantity-per-country/by-pma',
    //     props: {template: './business/total-sales/SalesandQuantity/by_pma.js'}
    // },
    // {
    //     path: '/business/total-sales/sales-and-quantity-per-country/:market',
    //     props: route => ({
    //         template: './business/total-sales/SalesandQuantity/by_trend.js',
    //         market: route.params.market,
    //     })
    // },
    // {
    //     path: '/business/total-sales/sales-prioritized-thirty-cities',
    //     component: SalesPrioritised30Cities
    // },
    // {
    //     path: '/business/total-sales/sales-prioritized-thirty-cities/bubble-graph',
    //     component: SalesPrioritised30CitiesBubbleGraph
    // },
    // {
    //     path: '/business/total-sales/sales-prioritized-thirty-cities/accumulated/:city',
    //     component: SalesPrioritised30CitiesAccumulated,
    //     props: true
    // },
    // {
    //     path: '/business/total-sales/channel-contribution-per-country',
    //     component: ChannelContributionPerCountry
    // },
    // {
    //     path: '/business/total-sales/channel-contribution-per-country-fs',
    //     component: ChannelContributionPerCountryTest
    // },
    // {
    //     path: '/business/fuo/dc-lead-time-precision',
    //     props: {template: './business/DcLeadTimePrecision/by_country.js'}
    // },
    // {
    //     path: '/business/fuo/dc-lead-time-precision/:market',
    //     props: route => ({
    //         template: './business/DcLeadTimePrecision/by_trend.js',
    //         market: route.params.market
    //     })
    // },
    // {
    //     path: '/business/fuo/cost-of-internal-damages/',
    //     props: {template: './business/CostOfInternalDamages/by_country.js'}
    // },
    {
        path: '/business/fuo/cost-of-internal-damages/:market',
        props: route => ({
            template: './business/CostOfInternalDamages/by_trend.js',
            market: route.params.market
        })
    },
    {
        path: '/business/fuo/cost-of-internal-damages-store',
        props: {template: './business/CostOfInternalDamages_Store/by_country.js'}
    },
    {
        path: '/business/fuo/cost-of-internal-damages-store/:market',
        props: route => ({
            template: './business/CostOfInternalDamages_Store/by_trend.js',
            market: route.params.market
        })
    },
    // {
    //     path: '/business/fuo/gross-inventory-differences',
    //     props: {template: './business/GrossInventoryDifferences/by_country.js'}
    // },
    // {
    //     path: '/business/fuo/gross-inventory-differences/:market',
    //     props: route => ({
    //         template: './business/GrossInventoryDifferences/by_trend.js',
    //         market: route.params.market
    //     })
    // },
    // {
    //     path: '/business/fuo/gross-inventory-differences-store',
    //     props: {template: './business/GrossInventoryDifferences_Store/by_country.js'}
    // },
    // {
    //     path: '/business/fuo/gross-inventory-differences-store/:market',
    //     props: route => ({
    //         template: './business/GrossInventoryDifferences_Store/by_trend.js',
    //         market: route.params.market
    //     })
    // },
    {
        path: '/business/missing-slots-for-inbound',
        props: {template: './business/MissingSlotsForInbound/by_country.js'}
    },
    {
        path: '/business/missing-slots-for-inbound/:market',
        props: route => ({
            template: './business/MissingSlotsForInbound/by_trend.js',
            market: route.params.market
        })
    },
    // {
    //     path: '/business/fuo/customer-order-quality',
    //     props: {template: './business/CustomerOrderQuality/by_country.js'}
    // },
    // {
    //     path: '/business/fuo/customer-order-quality/:market',
    //     props: route => ({
    //         template: './business/CustomerOrderQuality/by_trend.js',
    //         market: route.params.market
    //     })
    // },
    // {
    //     path: '/business/fuo/dc-transport-equipment-utilization',
    //     props: {template: './business/DcTransportEquipmentUtilization/by_country.js'}
    // },
    // {
    //     path: '/business/fuo/dc-transport-equipment-utilization/:market',
    //     props: route => ({
    //         template: './business/DcTransportEquipmentUtilization/by_trend.js',
    //         market: route.params.market
    //     })
    // },
    // {
    //     path: '/business/fuo/cdc-stock-accuracy',
    //     props: {template: './business/CDCStockAccuracy/by_country.js'}
    // },
    // {
    //     path: '/business/fuo/cdc-stock-accuracy/:market',
    //     props: route => ({
    //         template: './business/CDCStockAccuracy/by_trend.js',
    //         market: route.params.market
    //     })
    // },
    // {
    //     path: '/business/financial-services',
    //     component: FinancialServices,
    // },
    // {
    //     path: '/business/financial-services/top-8',
    //     component: FinancialServicesTop8
    // },
    // {
    //     path: '/business/financial-services/:market',
    //     component: FinancialServicesTrend,
    //     props: true
    // },
    {
        path: '/business/fuo/volumes/share-fulfilled',
        props: {template: './business/FulfilmentUnitVolumes/by_country_a.js'}
    },
    {
        path: '/business/fuo/volumes/share-fulfilled/:market',
        props: route => ({
            template: './business/FulfilmentUnitVolumes/trend_b.js',
            market: route.params.market 
        })
    },
    {
        path: '/business/fuo/volumes/fulfilled',
        props: {template: './business/FulfilmentUnitVolumes/by_country_a2.js'}
    },
    {
        path: '/business/fuo/volumes/fulfilled/:market',
        props: route => ({
            template: './business/FulfilmentUnitVolumes/trend_b2.js',
            market: route.params.market
        })
    },
    // {
    //     path: '/business/offered-lead-time',
    //     props: {template: './business/OfferedLeadTime/by_country.js'}
    // },
    // {
    //     path: '/business/offered-lead-time/top-10',
    //     props: {template: './business/OfferedLeadTime/Top_10.js'}
    // },
    // {
    //     path: '/business/offered-lead-time/:market',
    //     props: route => ({
    //         template: './business/OfferedLeadTime/by_trend.js',
    //         market: route.params.market
    //     })
    // },
    // {
    //     path: '/business/fuo/store-fulfilment-productivity',
    //     props: {template: './business/StoreFulfilmentProductivity/by_country.js'}
    // },
    // {
    //     path: '/business/fuo/store-fulfilment-productivity/:market',
    //     props: route => ({
    //         template: './business/StoreFulfilmentProductivity/by_trend.js',
    //         market: route.params.market
    //     })
    // },
    // {
    //     path: '/business/central-units-productivity',
    //     props: {template: './business/CentralUnitsProductivity/by_country.js'}
    // },
    // {
    //     path: '/business/central-units-productivity/:market',
    //     props: route => ({
    //         template: './business/CentralUnitsProductivity/by_trend.js',
    //         market: route.params.market
    //     })
    // },
    // {
    //     path: '/business/add-to-cart-rate',
    //     props: {template: './business/Add_to_cart_rate/by_country.js'}
    // },
    // {
    //     path: '/business/add-to-cart-rate/:market',
    //     props: route => ({
    //         template: './business/Add_to_cart_rate/by_trend.js',
    //         market: route.params.market
    //     })
    // },
    {
        path: '/business/cumulative-checkout-abandonment-rate',
        props: {template: './business/cumulative_checkout_abandonment_rate/by_country.js'}
    },
    {
        path: '/business/cumulative-checkout-abandonment-rate/:market',
        props: route => ({
            template: './business/cumulative_checkout_abandonment_rate/by_trend.js',
            market: route.params.market
        })
    }
];
