import Vue from 'vue';
import Router from 'vue-router';
import UniversalView from '@/views/UniversalView.vue';
import LoadTracker from '@/views/LoadTracker.vue';
import PageNotFound from '@/views/404.vue';

/* Routes */
import Access from './routes/access.js';
import Admin from './routes/admin.js';
import Brand from './routes/brand.js';
import Business from './routes/business.js';
import CountrySelect from './routes/country-select.js';
import Customer from './routes/customer.js';
import ExecutiveSummary from './routes/executive-summary.js';
import Exceptions from './routes/exceptions.js';
import Exports from './routes/exports.js';
import Feedback from './routes/feedback.js';
import Financial from './routes/financial.js';
import Home from './routes/home.js';
import MarketInsights from './routes/market-insights';
import People from './routes/people.js';
import Process from './routes/process.js';
import Sitemap from './routes/sitemap.js';
import Sustainability from './routes/sustainability.js';
import Testing from './routes/testing.js';
import ThreeRoads from './routes/three-roads.js';
import Page from '@/views/Page.vue';

let routes = [].concat(
  Access,
  Admin,
  Brand,
  Business,
  CountrySelect,
  Customer,
  ExecutiveSummary,
  Exceptions,
  Exports,
  Feedback,
  Financial,
  Home,
  MarketInsights,
  People,
  Process,
  Sitemap,
  Sustainability,
  Testing,
  ThreeRoads
);

routes = routes.map(page => {
  if (!('component' in page)) page.component = UniversalView;
  return page;
});

routes.push({
  path: '/data-load-tracker',
  name: 'loadtracker',
  component: LoadTracker
});

routes.push({
  path: '/market-view',
  name: 'Market-view',
  component: Page
});

routes.push({
  path: '/404',
  name: '404',
  component: PageNotFound
});

// must be the last route
routes.push({
  path: '*',
  redirect: '/404'
});


routes.push({
  // path: '/:url',
  path: '/:url(.*)',
      name: 'Page',
      component: Page,
      props: true
},
{
  path: '*',
  component: PageNotFound
});


Vue.use(Router);

/* Environment Variable */
export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
});

