import { render, staticRenderFns } from "./AccessTemplate.vue?vue&type=template&id=4d617246"
import script from "./AccessTemplate.vue?vue&type=script&lang=js"
export * from "./AccessTemplate.vue?vue&type=script&lang=js"
import style0 from "./AccessTemplate.vue?vue&type=style&index=0&id=4d617246&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4d617246')) {
      api.createRecord('4d617246', component.options)
    } else {
      api.reload('4d617246', component.options)
    }
    module.hot.accept("./AccessTemplate.vue?vue&type=template&id=4d617246", function () {
      api.rerender('4d617246', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/admin/access-templates/AccessTemplate.vue"
export default component.exports