<template>
<div>
    <AdminUniversal
        component="title"
        :props="title_props"
    />
    <AdminUniversal
        component="button"
        :props="button_props"
    />
    <AdminUniversal
        v-if="table_props.render"
        component="table"
        :props="table_props"
    />
</div>
</template>

<script>
import AccessTemplate from '@/components/admin/access-templates/AccessTemplate.vue';
import AdminUniversal from '@/components/admin/index.vue';

export default {
    components: {
        AccessTemplate,
        AdminUniversal
    },
    data: function() {
        return {
            title_props: {
                title: 'Access Templates',
                subtitle: 'Create, Edit and Manage access templates'
            },
            button_props: {
                label: 'New Template',
                classes: 'is-success',
                method: this.navigate
            },
            table_props: {
                buttons: [
                    {
                        label: 'Edit',
                        classes: '',
                        method: this.edit_template
                    },
                    {
                        label: 'Copy',
                        classes: 'is-primary',
                        method: this.copy_template
                    }
                ],
                headers: ['ID', 'Name', 'Actions'],
                data: null,
                render: false
            },

            selected_data: {},
            access_templates: [],
            non_menu_items: {
                country_insights: {},
                execute_summary: {}
            }
        }
    },
    mounted: function() {
        this.load_access_templates();
    },
    methods: {
        copy_template: function(row) {
            const template_id = this.table_props.data[row].id;
            this.$http.post('/admin/user-management/access-templates/copy',
            {
                template_id: template_id
            }).then(
                succ => {
                    this.load_access_templates();
                },
                this.$helper.error
            );
        },
        edit_template: function(row) {
            const policy_id = this.table_props.data[row].id;
            this.$router.push(`/admin/user-management/access-templates/edit/${policy_id}`);
        },
        load_access_templates: function() {
            this.table_props.render = false;
            this.$http.get('/admin/user-management/access-templates/all').then(
                succ => {
                    let data = succ.data;
                    data.forEach(datum => {delete datum.template});
                    this.table_props.data = data;
                    this.table_props.render = true;
                },
                this.$helper.error
            );
        },
        navigate: function() {this.$router.push('/admin/user-management/access-templates/new');}
    }
}
</script>

<style>

</style>