<template>
<div>
    <div class="editor-menu">
        <button class="button" :class="{ 'is-active': editor?.isActive('bold') }" @click="editor?.chain().focus().toggleBold().run()">
            <i class="fas fa-bold"></i>
        </button>
        <button class="button" :class="{ 'is-active': editor?.isActive('italic') }" @click="editor?.chain().focus().toggleItalic().run()">
            <i class="fas fa-italic"></i>
        </button>
        <button class="button" :class="{ 'is-active': editor?.isActive('underline') }" @click="editor?.chain().focus().toggleUnderline().run()">
            <i class="fas fa-underline"></i>
        </button>
        <button class="button" :class="{ 'is-active': editor?.isActive('strike') }" @click="editor?.chain().focus().toggleStrike().run()">
            <i class="fas fa-strikethrough"></i>
        </button>
        <button class="button" :class="{ 'is-active': editor?.isActive('bulletList') }" @click="editor?.chain().focus().toggleBulletList().run()">
            <i class="fas fa-list-ul"></i>
        </button>
        <button class="button" :class="{ 'is-active': editor?.isActive('orderedList') }" @click="editor?.chain().focus().toggleOrderedList().run()">
            <i class="fas fa-list-ol"></i>
        </button>
    </div>
    <editor-content id="editor" class="editor-content" :editor="editor" />
</div>
</template>

<script>
import { Editor } from '@tiptap/core';
import { EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Placeholder from '@tiptap/extension-placeholder';

export default {
    props: {
        content: String
    },
    components: {
        EditorContent,
    },
    data() {
        return {
            editor: null
        };
    },
    mounted() {
        this.editor = new Editor({
            content: this.content,
            extensions: [
                StarterKit,
                Underline,
                Placeholder.configure({
                    placeholder: 'Write a message here'
                })
            ],
            onUpdate: ({ editor }) => {
                this.$emit('textUpdate', editor.getHTML());
            }
        });
    },
    beforeDestroy() {
        // Always destroy your editor instance when it's no longer needed
        this.editor.destroy();
    },
    methods: {
        clear: function() {
        }
    }
}
</script>

<style>
.editor-content {
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 0.5em;
}

.editor-menu button {
    margin: 0.2em;
}

#editor .ProseMirror {
    text-align: left;
    padding: 1em;
}
.ProseMirror ul {
    list-style: disc;
}
.ProseMirror ul li {
    margin-left: 1rem;
    margin-bottom: 1rem;
}
.ProseMirror ol li {
    margin-left: 1rem;
    margin-bottom: 1rem;
}
</style>