<template>
    <div :id="title" style="width: 100%; height:100%;"></div>
</template>

<script>
import * as echarts from 'echarts/dist/echarts.min.js';
export default {
    props: {
        title: String, //title of the graph
        showtitle: Boolean,
        subtitle: String, //subtitle of the graph
        xcategories: Array, //data that will be displayed on the x axis
        yaxislabel: String, //name of the data on the y axis e.g 'After sales delivery share' or 'services sale by month'
        ymetric: String, //'%', '£', 'kg' etc.
        type: String, //bar
        xvalue: Number,

        color1: String, //color of the bar lie or scatter for the paticular data set
        color2: String,
        color3: String,
        color4: String,

        seriesname1: String, //name of the data such as IKEA 
        seriesset1: Array,

        seriesname2: String,
        seriesset2: Array,

        seriesname3: String,
        seriesset3: Array,

        seriesname4: String,
        seriesset4: Array,

        seriesname5: String,
        seriesset5: Array,

        show: Boolean, // true to show the exta features such as 'save graph as image', 'refresh', 'change to bar graph', etc
        gridbottom: Number,
        xaxisrotation: Number,
        xaxisfontsize: Number
    },
    mounted: function() {
		let myChart = echarts.init(document.getElementById(this.title));
        //loop through to find the highest and lowest combination then set those for the upper and lower limits for the y axis also append the labels on the lowest value

        let option = {
           title:{
                text: this.title,
                subtext: this.subtitle,
                show: this.showtitle,
                subtextStyle: {
                    color: 'black',
                },
                top: '-5em',
            },
            tooltip: {
                show: false,
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            toolbox: {
                top: '85em',
                right: '30em',
                show: this.show,
                feature: {
                    restore: {},
                    saveAsImage: {}
                }
            },
            grid: {
               top: '80em',
               left: '35vh',
               bottom: this.gridbottom ? `${this.gridbottom}em` : '5em',
               containLabel: true
			},
			legend: {
                top: '45em',
                left: '0em',
                itemGap: 15,
                data: [this.seriesname1, this.seriesname2, this.seriesname3, this.seriesname4, this.seriesname5],
                selectedMode: false,
			},
			textStyle: {
                fontFamily: 'NotoIKEALatin-Regular',
            },
            xAxis: [
                {
                    type: 'category',
                    data: this.xcategories,
                    axisPointer: {
                        type: 'shadow'
                    },
                    axisLabel: {
                        rotate: this.xaxisrotation ? `${this.xaxisrotation}` : null,
                        fontSize: this.xaxisfontsize ? this.xaxisfontsize : null
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: this.yaxislabel,
                    nameLocation: 'middle',
                    nameGap: 60,
                    //max: 10,
                    axisLabel: {
                        formatter: `{value} ${this.ymetric}`
                    }
                }
            ],
            series: [
                {
                    name: this.seriesname1,
                    type: this.type,
                    stack: 'true',
                    data: this.call_data(this.seriesset1),
                    color: this.color1,
                    markPoint: {
                        symbolSize: 0.1,
                        label: {
                            color: 'black',
                            position: 'insideBottom',
                        },
                        data: this.call_label(1)
                    }
                },
                {
                    name: this.seriesname2,
                    type: this.type,
                    stack: 'true',
                    data: this.call_data(this.seriesset2),
                    color: this.color3,
                },
                {
                    name: this.seriesname3,
                    type: this.type,
                    stack: 'true',
                    data: this.call_data(this.seriesset3),
                    color: this.color2,
                    markPoint: {
                        symbolSize: 0.1,
                        label: {
                            color: 'white',
                            position: 'insideTop',
                        },
                        data: this.call_label(3)
                    }
                },
                {
                    name: this.seriesname4,
                    type: this.type,
                    data: this.call_data(this.seriesset4),
                    color: this.color4,
                    stack: true,
                },
                {
                    name: this.seriesname5,
                    type: 'line',
                    data: this.call_data(this.seriesset5),
                    color: 'black',
                },
            ]
            // series: this.all_data(),
        };
        myChart.setOption(option);
    },
    methods: {
        call_data:function(set) {
            let data = [];
            for (let index = 0; index < set.length; index++) {
                data.push(set[index].value)
            }
            return data;
        },
        call_label:function(option) {
            let labels = [];
            if (option === 1) {
                for (let index = 0; index < this.seriesset1.length; index++) {
                    labels.push({value: `${
                        this.$helper.maybe(this.seriesset1[index].label) === 0 ? '' : this.$helper.maybe(this.seriesset1[index].label)}`, 
                        xAxis: index, yAxis: this.seriesset1[index].value})
                }
            } else if (option == 3) {
                for (let index = 0; index < this.seriesset3.length; index++) {
                    let x = this.seriesset1[index].value;
                    let y = this.seriesset2[index].value;
                    let z = this.seriesset3[index].value;
                    if (x < 0) x = 0;
                    if (y < 0) y = 0;
                    if (z < 0) z = 0;
                    labels.push({value: `${
                        this.$helper.maybe(this.seriesset3[index].label) === 0 ? '' : this.$helper.maybe(this.seriesset3[index].label)}`, 
                        xAxis: index, yAxis: (x + y + z)})
                }
            }
            return labels;
        },
    }
}
</script>
