<template>
<div>
    <AdminUniversal
		component="title"
		:props="title_props"
	/>
    <div class="options-bar">
		<KPIChooserDropdowns 
			@update="update_kpi"
		/>
	</div>
    <CommentKey 
        style="margin-top: -1rem"
        :status_colors="status_colors"
    />
    <section v-if="render" class="accordions accordians-height vertical-scroll mt">
		<article class="accordion" v-for="(location, index) in locations" :key="index" :class="`${active_accordian === location.id ? 'is-active' : ''}`">
			
			<div class="accordion-header relative" @click="toggle_active(location.id)">
                <router-link :to="`/${location.kpi}/${location.kpi_area}${location.market_area !== null ? '/' + location.market_area : ''}${location.store_area !== null ? '/' + location.store_area : ''}`" target="_blank">
                    {{location.kpi_area}}{{location.market_area !== null ? '/' + location.market_area : ''}}{{location.store_area !== null ? '/' + location.store_area : ''}}
                </router-link>
				<div v-for="(observation, index) in location.observations" :key="index">
                    <div class="status-badges" :style="`background-color:${status_colors[status(observation.status)]}; left:${25 + (index * 1.5) + (index / 2)}rem`"></div>
                </div>
                <div v-if="location.observations.length === 0">
                    <div class="status-badges" :style="`background-color:${status_colors['none']}; left:25rem`"></div>
                </div>
				<i :class="`${active_accordian === location.id ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}`"></i>
			</div>

			<div class="accordion-body cursor-default">
				<div class="accordion-content bg-white" v-if="location.observations.length > 0">
					<div class="insight columns is-gapless" v-for="(observation, i) in location.observations" :key="i">
                        
						<div class="column">Status: {{observation.status}}</div>
                        <div class="column">Author: {{observation.display_name}}</div>
						<div class="column">
							{{observation.status === 'LIVE' ? 'Started: ' : 'Starting: '}}{{display_date(observation.scheduled_date)}}
							<br><b class="">-</b><br> 
							Ending: {{display_date(observation.comment_lifetime)}}
						</div>
                        <div class="column">
							<div class="button is-success content-button" @click="open_modal(observation.content_id)">Content</div>
						</div>
						<div class="modal" :class="`${active_modal === observation.content_id ? 'is-active' : ''}`">
							<div class="modal-background"></div>
							<div class="modal-content relative">
								<button class="delete top-right" aria-label="close" @click="close_modal()"></button>
								<div class="mt mb" v-html="observation.content"></div>
							</div>
						</div>
					</div>
				</div>
                <div v-else class="accordion-content bg-white">No Observations Found</div>
			</div>
		</article>
	</section>
</div>
</template>

<script>
import AdminUniversal from '@/components/admin/index.vue';
import KPIChooserDropdowns from '@/components/admin/KPIChooserDropdowns.vue';
import CommentKey from '@/components/admin/CommentKey.vue';

export default {
    components: {
        AdminUniversal,
        CommentKey,
		KPIChooserDropdowns
    },
    data: function() {
        return {
            title_props: {
				title: "Observations Tracker"
			},

            selected_kpi_area: null,
			selected_kpi_lvl1: null,
            selected_kpi_lvl2: null,
            
            active_accordian: 'none',
            active_area: 'none',
            active_modal: null,
            render: false,
            status_colors: {
				live: '#008000',
				scheduled: '#7cb9e8',
                draft: '#ffff00',
                none: '#ff0000'
            },
            left_over_observations: null,
            filtered_paths: null,
            locations: null
        }
    },
    methods: {
        update_kpi: function(kpi_location_array) {
            this.selected_kpi_area = this.url_text(kpi_location_array[0]);
            this.selected_kpi_lvl1 = this.url_text(kpi_location_array[1]);
            this.selected_kpi_lvl2 = this.url_text(kpi_location_array[2]);
            this.load_observations();
        },
        url_text: function(name) {
            if (name) {
                return name.toLowerCase().replace(/3/g, "three").replace(/ /g, "-");
            }
        },
        load_observations: function() {
            this.$http.post('/admin/observations-tracker', {
                kpi_area: this.selected_kpi_area,
                kpi: this.selected_kpi_lvl1,
                market_area: this.selected_kpi_lvl2
			}).then(
				succ => {
                    let d = succ.data;
                    this.locations = d;
                    this.render = true;
				},
				this.$helper.error
			);
        },
        close_modal: function() {
			this.active_modal = null;
        },
        open_modal: function (id) {
			this.active_modal = id;
		},
		display_date: function(date) {
			return date.split('T')[0];
        },
        status: function(message) {
            return message.toLowerCase();
        },
        toggle_active: function(market) {
            if (this.active_accordian === market) {
                this.active_accordian = 'none';
            } else {
                this.active_accordian = market;
            }
        }
    }
}
</script>

<style>
.status-badges {
	border-radius: 100%;
	height: 1.5rem;
	width: 1.5rem !important;
    position: absolute;
    margin-top: -0.7rem;
}
</style>