import FurnitureThatFitsMyBudget from '@/views/three-roads/FurnitureThatFitsMyBudget.vue';
import FurnitureThatFitsMyBudgetTop8 from '@/views/three-roads/FurnitureThatFitsMyBudgetTop8.vue';
import FurnitureThatFitsMyBudgetMarket from '@/views/three-roads/FurnitureThatFitsMyBudgetMarket.vue';
import FurnitureThatFitsMyBudgetMap from '@/views/three-roads/FurnitureThatFitsMyBudgetMap.vue';

import LowPriceInReality from '@/views/three-roads/LowPriceInReality.vue';
import LowPriceInRealityTop8 from '@/views/three-roads/LowPriceInRealityTop8.vue';
import LowPriceInRealityMarket from '@/views/three-roads/LowPriceInRealityMarket.vue';

import PenetrationView from '@/views/three-roads/PenetrationView.vue';
import PenetrationTop8View from '@/views/three-roads/PenetrationTop8View.vue';
import PenetrationMarketView from '@/views/three-roads/PenetrationMarketView.vue';

import PositiveImpact from '@/views/three-roads/PositiveImpact.vue';
import PositiveImpactTop8 from '@/views/three-roads/PositiveImpactTop8.vue';
import PositiveImpactMarket from '@/views/three-roads/PositiveImpactMarket.vue';

export default [
    // {
    //     path: '/three-roads/furniture-that-fits-my-budget',
    //     component: FurnitureThatFitsMyBudget,
    // },
    // {
    //     path: '/three-roads/furniture-that-fits-my-budget/top-8',
    //     component: FurnitureThatFitsMyBudgetTop8,
    // },
    // {
    //     path: '/three-roads/furniture-that-fits-my-budget/map',
    //     component: FurnitureThatFitsMyBudgetMap,
    // },
    // {
    //     path: '/three-roads/furniture-that-fits-my-budget/:market',
    //     component: FurnitureThatFitsMyBudgetMarket,
    //     props: true
    // },
    {
        path: '/three-roads/low-price-in-reality',
        component: LowPriceInReality,
    },
    {
        path: '/three-roads/low-price-in-reality/top-8',
        component: LowPriceInRealityTop8,
    },
    {
        path: '/three-roads/low-price-in-reality/:market',
        component: LowPriceInRealityMarket,
        props: true
    },
    // {
    //     path: '/three-roads/penetration',
    //     component: PenetrationView
    // },
    // {
    //     path: '/three-roads/penetration/top-8',
    //     component: PenetrationTop8View
    // },
    // {
    //     path: '/three-roads/penetration/:market',
    //     component: PenetrationMarketView,
    //     props: true
    // },
    // {
    //     path: '/three-roads/positive-impact',
    //     component: PositiveImpact,
    // },
    // {
    //     path: '/three-roads/positive-impact/top-8',
    //     component: PositiveImpactTop8,
    // },
    // {
    //     path: '/three-roads/positive-impact/:market',
    //     component: PositiveImpactMarket,
    //     props: true
    // }
];

// 