var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.render
    ? _c(
        "div",
        { staticClass: "tile is-child box" },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.order[_vm.index])),
          ]),
          _vm._l(
            _vm.menu_items[_vm.find_kpi_area(_vm.order[_vm.index])].areas,
            function (area, areaIndex) {
              return _c(
                "div",
                { key: areaIndex, staticClass: "tile-content" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex full-width cursor-pointer kpi-dropdown-item",
                      style: {
                        backgroundColor: _vm.colors_disabled
                          ? ""
                          : area.color || "",
                      },
                      on: {
                        click: function ($event) {
                          !_vm.auto_expanded_areas.includes(area.name)
                            ? _vm.click_kpi(area)
                            : ""
                        },
                      },
                    },
                    [
                      area.areas && !_vm.mobile_mode
                        ? _c("i", {
                            class: `fas ${
                              !_vm.auto_expanded_areas.includes(area.name)
                                ? `fa-chevron-${
                                    _vm.active_kpis.includes(area.name)
                                      ? "up"
                                      : "down"
                                  }`
                                : ""
                            }`,
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "mlp5 mbp5",
                          class:
                            _vm.mobile_mode && area.areas ? "text-bold" : "",
                        },
                        [_vm._v(" " + _vm._s(area.name) + " ")]
                      ),
                    ]
                  ),
                  area.areas &&
                  (_vm.active_kpis.includes(area.name) || _vm.mobile_mode)
                    ? _c(
                        "div",
                        { staticClass: "ml" },
                        _vm._l(area.areas, function (sub_area1, i) {
                          return _c("div", { key: i }, [
                            !sub_area1.areas
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "cursor-pointer kpi-dropdown-item mlp25",
                                    style: {
                                      backgroundColor: _vm.colors_disabled
                                        ? ""
                                        : sub_area1.color || "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.click_kpi(sub_area1)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "item-no-area" }, [
                                      _vm._v(_vm._s(sub_area1.name)),
                                    ]),
                                  ]
                                )
                              : _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex kpi-dropdown-item cursor-pointer",
                                      style: {
                                        backgroundColor: sub_area1.color || "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.click_kpi(sub_area1)
                                        },
                                      },
                                    },
                                    [
                                      sub_area1.areas && !_vm.mobile_mode
                                        ? _c("i", {
                                            class: `fas fa-chevron-${
                                              _vm.active_kpis.includes(
                                                sub_area1.name
                                              )
                                                ? "up"
                                                : "down"
                                            }`,
                                          })
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mlp5 mbp5",
                                          class:
                                            _vm.mobile_mode && sub_area1.areas
                                              ? "text-bold"
                                              : "",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(sub_area1.name) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  sub_area1.areas &&
                                  (_vm.active_kpis.includes(sub_area1.name) ||
                                    _vm.mobile_mode)
                                    ? _c(
                                        "div",
                                        { staticClass: "ml" },
                                        _vm._l(
                                          sub_area1.areas,
                                          function (sub_area2, j) {
                                            return _c("div", { key: j }, [
                                              !sub_area2.areas
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "kpi-dropdown-item cursor-pointer mlp25",
                                                      style: {
                                                        backgroundColor:
                                                          _vm.colors_disabled
                                                            ? ""
                                                            : sub_area2.color ||
                                                              "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.click_kpi(
                                                            sub_area2
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "item-no-area",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              sub_area2.name
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "flex kpi-dropdown-item cursor-pointer",
                                                        style: {
                                                          backgroundColor:
                                                            _vm.colors_disabled
                                                              ? ""
                                                              : sub_area2.color ||
                                                                "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.click_kpi(
                                                              sub_area2
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        sub_area2.areas &&
                                                        !_vm.mobile_mode
                                                          ? _c("i", {
                                                              class: `fas fa-chevron-${
                                                                _vm.active_kpis.includes(
                                                                  sub_area2.name
                                                                )
                                                                  ? "up"
                                                                  : "down"
                                                              }`,
                                                            })
                                                          : _vm._e(),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "mlp5 mbp5",
                                                            class:
                                                              _vm.mobile_mode &&
                                                              sub_area2.areas
                                                                ? "text-bold"
                                                                : "",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  sub_area2.name
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    sub_area2.areas &&
                                                    (_vm.active_kpis.includes(
                                                      sub_area2.name
                                                    ) ||
                                                      _vm.mobile_mode)
                                                      ? _c(
                                                          "div",
                                                          { staticClass: "ml" },
                                                          _vm._l(
                                                            sub_area2.areas,
                                                            function (
                                                              sub_area3,
                                                              k
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                { key: k },
                                                                [
                                                                  !sub_area3.areas
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "kpi-dropdown-item cursor-pointer mlp25",
                                                                          style:
                                                                            {
                                                                              backgroundColor:
                                                                                _vm.colors_disabled
                                                                                  ? ""
                                                                                  : sub_area3.color ||
                                                                                    "",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.click_kpi(
                                                                                  sub_area3
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "item-no-area",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  sub_area3.name
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "flex kpi-dropdown-item cursor-pointer",
                                                                              style:
                                                                                {
                                                                                  backgroundColor:
                                                                                    _vm.colors_disabled
                                                                                      ? ""
                                                                                      : sub_area3.color ||
                                                                                        "",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.click_kpi(
                                                                                      sub_area3
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              sub_area3.areas &&
                                                                              !_vm.mobile_mode
                                                                                ? _c(
                                                                                    "i",
                                                                                    {
                                                                                      class: `fas fa-chevron-${
                                                                                        _vm.active_kpis.includes(
                                                                                          sub_area3.name
                                                                                        )
                                                                                          ? "up"
                                                                                          : "down"
                                                                                      }`,
                                                                                    }
                                                                                  )
                                                                                : _vm._e(),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "mlp5 mbp5",
                                                                                  class:
                                                                                    _vm.mobile_mode &&
                                                                                    sub_area3.areas
                                                                                      ? "text-bold"
                                                                                      : "",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        sub_area3.name
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          sub_area3.areas &&
                                                                          (_vm.active_kpis.includes(
                                                                            sub_area3.name
                                                                          ) ||
                                                                            _vm.mobile_mode)
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "ml",
                                                                                },
                                                                                _vm._l(
                                                                                  sub_area3.areas,
                                                                                  function (
                                                                                    sub_area4,
                                                                                    m
                                                                                  ) {
                                                                                    return _c(
                                                                                      "div",
                                                                                      {
                                                                                        key: m,
                                                                                      },
                                                                                      [
                                                                                        !sub_area4.areas
                                                                                          ? _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "kpi-dropdown-item cursor-pointer mlp25",
                                                                                                style:
                                                                                                  {
                                                                                                    backgroundColor:
                                                                                                      _vm.colors_disabled
                                                                                                        ? ""
                                                                                                        : sub_area4.color ||
                                                                                                          "",
                                                                                                  },
                                                                                                on: {
                                                                                                  click:
                                                                                                    function (
                                                                                                      $event
                                                                                                    ) {
                                                                                                      return _vm.click_kpi(
                                                                                                        sub_area4
                                                                                                      )
                                                                                                    },
                                                                                                },
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "item-no-area",
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        sub_area4.name
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            )
                                                                                          : _vm._e(),
                                                                                      ]
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                0
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      : _vm._e(),
                                                  ]),
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]),
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                ]
              )
            }
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }