<template>
<div>
    <Title
        title="Market Share Test"
        :location="location"
    />
    <div :key="$store.getters.page_size" id="market-share-trend" class="section three-quarters-height box ms">
        <div class="title-text">
            <div class="title-header">
                <div class="blue-highlight">{{$store.getters.selected_country_name}}</div>
                <div class="mlp25">Market Share development.</div>
            </div>
            <div class="sub-title-text font-size-p8">PRS's Gaming and Living with Pets are <div class="blue-highlight mlp25">excluded</div></div>
            <div class="sub-title-text font-size-p8">Excluded rate used is <div class="blue-highlight mlp25">GF22</div></div>
        </div>

        <ForecastTrendGraph
            
            :title="`${$store.getters.selected_country_name}` + ' Market Share development'"
            :show-title="!$store.state.global.bot_mode"
            
            v-bind="graph"
        />
        
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import ForecastTrendGraph from '@/components/visualisations/general/ForecastTrendGraph.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        ForecastTrendGraph,
        Title
    },
    props: {
        market: String
    },
    data: function() {
        return {
            graph: {
                x_categories: ['FY21 Total', 'FY22 T1', 'FY22 T2', 'FY22 T3', 'FY22 Total'],
                y_axis_label: '',
                y_metric: '%',
                
                names: ['Performance', 'Forecast', 'Trend', 'Goal'],
                data_sets: [
                    [7.98, 7.95, 8.22,    ,     ],
                    [    ,     , 8.22, 9.89, 8.64],
                    [    ,     , 8.22, 10.51, 8.83],
                    [8.53, 8.53, 8.53, 8.53, 8.53],
                ],
                types: ['line', 'line', 'line', 'line'],
                type_styles: [{}, {}, {lineStyle: 'dashed'}, {}],
                colors: [
                    this.$store.state.colors['focus-yellow'],
                    '#1565c0',
                    this.$store.state.colors['focus-yellow'],
                    '#4caf50'
                ],
                year_goal_index: 3,
                render: false
            },

            location: '/business/market-share-fs'
        }
    }
}
</script>

<style scoped>
.blue-highlight {
    color: #1565c0
}
.title-header {
    font-size: 1.2rem;
    text-align: left;
    font-weight: bold;
    display: flex;
}
.sub-title-text {
    font-size: 0.8rem;
    text-align: left;
    font-weight: bold;
    display: flex;
}
.title-text{
    position: absolute;
}
</style>
