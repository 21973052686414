var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: { title: "Market Share Test", location: _vm.location },
      }),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size,
          staticClass: "section half-height box ms",
          attrs: { id: "market-share-trend" },
        },
        [
          _c(
            "Graph",
            _vm._b(
              {
                attrs: {
                  title: "Sales per Week",
                  "show-title": !_vm.$store.state.global.bot_mode,
                },
              },
              "Graph",
              _vm.graph,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }