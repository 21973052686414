var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: {
          title: "Services Gross Margin By Service",
          location: _vm.location,
        },
      }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons(), dataset: _vm.table },
          }),
          _c(
            "div",
            { staticClass: "label-container" },
            [
              _c("p", [_vm._v("Actuality")]),
              _c("DropDown", {
                key: _vm.act_filter,
                attrs: {
                  options: _vm.act_filter_options,
                  values: _vm.act_filter_values,
                  default: _vm.act_filter,
                },
                on: { updateDropDown: _vm.update_act_filter },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                key: _vm.$store.getters.market,
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: _vm.$store.getters.market,
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.table.render
        ? _c("Table", {
            attrs: {
              headers: _vm.table.headers,
              rows: _vm.table.rows,
              "icon-settings": _vm.table.icon_settings,
              "decimal-settings": _vm.table.decimal_settings,
              "data-settings": _vm.table.data_settings,
              "sort-settings": {
                static: [
                  "Total delivery&collect/picking services",
                  "Total other services",
                  "ALL SERVICE TYPES",
                ],
              },
              "font-settings": {
                bold: { row: { regex: /all service|total/ } },
              },
              metaSettings: { is_selectable: false },
            },
          })
        : _c("div", [
            _vm._v(
              "No data found for " +
                _vm._s(_vm.$store.getters.selected_country_name)
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }