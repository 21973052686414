import helper from '@/helper';

export default {
    props: Object,
    logic: function (props, data) {
        this.data = { ...data };
        let headers = this.data.parsed_headers;
        let rows = this.data.parsed_rows;

        // Check if there's a global decimal settings
        let global = props.global ? props.global : 0;
        let ignore = props.ignore ? props.ignore : [];

        if ('global' in props) {
            rows.forEach(row => row.forEach((cell, index) => {
                if (ignore.includes(index)) {
                    return;
                };

                if (!cell.value) return;
                const content = helper.round(cell.value, global);

                cell.html = `<span>${content}</span>`;
            }));
        }

        // Rule for specific column
        for (let key in props) {
            let index = -1;

            headers.forEach(row => {
                let colspan = 0;
                row.forEach(cell => {
                    let k = Object.keys(cell).shift();
                    let v = Object.values(cell).shift();
                    if (key === k || key === cell.alias) {
                        index = colspan;
                    }

                    colspan += v;
                });
            });

            if (index === -1) {
                continue;
            }

            if (!('precision' in props[key])) {
                throw new Error("Missing 'precision' key.");
            }

            rows.forEach(row => {
                let cell = row[index];
                let precision = props[key].precision;

                if (!cell.value) return;
                const content = helper.round(cell.value, precision);
                cell.html = `<span>${content}</span>`;
            });
        }
        return rows;
    }
}
