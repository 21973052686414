<template>
<div>
    <Title
        title="Operational Climate Footprint"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <Table
        v-if="table.render"

        :headers="table.headers"
        :rows="table.rows"
        
        :icon-settings="table.icon_settings"
        :data-settings="{ nullify_zeros: true }"
        :sort-settings="{ 'Country': { asc: true } }"
        :font-settings="{ bold: { row: {regex: 'Total Retail Countries' } } }"
        :decimal-settings="table.decimal_settings"

        class="ms1p5"
    />
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: [],
                decimal_settings: {},
                render: false
            },
            location: '/sustainability/climate-footprint'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.get_planetary_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
                // {
                //     name: '🔗 Climate Footprint - Food Ingredients',
                //     link: `/sustainability/climate-footprint-food-ingredients`
                // }
            ];
        },
        get_planetary_data: function() {
            this.table.render = false;
            this.$http.get('/sustainability/footprint/total-ingka-retail').then(
                succ => {
                    let d = succ.data;
                    let keys = Object.keys(d[0].measures);
                    if (d[0].measure_metadata) {
                        this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                    }
                    this.table.headers = [['Country'].concat(keys).map(c => {
                        return {
                            [c]: 1
                        }
                    })];
                    this.table.rows = d.map(row => {
                        let temp = [];
                        // If the key/values don't exist, set them to 0
                        keys.map(key => {
                            if (row.measures) {
                                temp.push(row.measures[key]);
                            } else {
                                temp.push('');
                            }
                        });
                        return [row.caption].concat(temp);
                    });
                    this.table.icon_settings = {
                        'YTD (ton CO2e)': {
                            icon: 'circle',
                            comparison: 'YTD Goal (ton CO2e)',
                            reversed: true,
                        },
                        'Month Index': {
                            icon: 'arrow',
                            comparison: 'YTD Index',
                            reversed: true
                        }
                    }
                    this.$helper.header_replace(this.table.headers, {
                        'Month (ton CO2e)': 'Month Trend (ton CO2e)'
                    });
                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        }
    }
}
</script>

<style>

</style>
