var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "tree-canvas" } },
    _vm._l(_vm.nodes, function (node, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "node",
          staticStyle: { "text-align": "center" },
          attrs: { id: index },
        },
        [_c("div", { domProps: { innerHTML: _vm._s(node.content) } })]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }