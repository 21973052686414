var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal is-active" }, [
    _c("div", { staticClass: "modal-background" }),
    _c(
      "div",
      { staticClass: "modal-content" },
      [
        _vm._t("default"),
        _c("button", {
          staticClass: "modal-close is-large",
          attrs: { "aria-label": "close" },
          on: { click: _vm.close },
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }