<template>
    <button @click="click" class="button"><slot/></button>
</template>

<script>
export default {
    methods: {
        click: function() {
            this.$emit('click');
        }
    }
}
</script>

<style scoped>
.button {
    color: #4a4a4a;
}
</style>