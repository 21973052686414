<template>
<div>
    <Title
        title="Sales Prioritised Cities Accumulated"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
        <div class="label-container">
            <p>City</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_city"
            />
        </div>
        <div class="label-container">
            <p>Index / Share</p>
            <DropDown
                :key="filter2"
                :options="options2"
                :values="values2"
                :default="filter2"
                @updateDropDown="update_filter"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section half-height box ms">
        <Graph
            v-if="graph.render"

            :title="`Showing data for: ${filter}`"
            :show-title="!$store.state.global.bot_mode"
            
            v-bind="graph"
        />
        <div v-else> No data found for {{$store.getters.selected_country_name}}</div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Graph from '@/components/visualisations/general/Graph.vue';
import Title from '@/components/ui/Title.vue';

export default {
    props: {
        city: String
    },
    components: {
        Buttons,
        DropDown,
        Graph,
        Title
    },
    data: function() {
        return {
            graph: {
                colors: [
                    this.$store.state.colors['yellow'],
                    this.$store.state.colors['light-grey'],
                    this.$store.state.colors.YG
                ],
                data_sets: [],
                names: [`City Accumulated`, `Rest of Country Accumulated`, `Total Accumulated`],
                types: ['line', 'line', 'line'],
                x_categories: null,
                y_axis_label: 'Accumulated Index',
                y_metric: '%',

                render: false
            },
            filter: null,
            values: [],
            options: [],
            filter2: 'Index',
            values2: ['Index', 'Online Share'],
            options2: ['Index', 'Online Share'],
            location: '/business/total-sales/sales-prioritized-thirty-cities',
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        this.get_market_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By City',
                    link: this.location
                },
                {
                    name: 'Accumulated',
                    link: `${this.location}/accumulated/null`
                },
                {
                    name: 'Bubble Graph',
                    link: `${this.location}/bubble-graph`
                },
            ];
        },
        get_market_data: function() {
            this.$http.post('/business/total-sales/sales-prioritized-thirty-cities/accumulated').then(
                succ => {
                    let d = succ.data;
                    if (!d[0]) return;
                    
                    this.values = this.values.length ? this.values : Object.keys(d[0].measures);
                    this.options = this.options.length ? this.options : Object.keys(d[0].measures);
                    this.filter = this.filter ? this.filter : this.values[0];

                    if (this.city === 'null') {
                        this.$router.push(`${this.location}/accumulated/${this.filter}`);
                    }
                    
                    this.graph.x_categories = Object.keys(d[0].measures[this.filter]);
                    let temp = [[], [], []];
                    this.graph.x_categories.forEach(week => {
                        this.graph.names.forEach((name, i) => {
                            let val = d[0].measures[this.filter];
                            temp[i].push(val[week][`${name} ${this.filter2}`]);
                        });
                    });
                    this.graph.data_sets = temp;
                    this.graph.render = true;
                },
                this.$helper.error
            );
        },
        update_city: function(city) {
            this.filter = city;
            this.graph.render = false;
            this.get_market_data();
            this.$router.push(`${this.location}/accumulated/${this.filter}`);
        },
        update_filter: function(filter) {
            this.filter2 = filter;
            this.graph.render = false;
            this.graph.y_axis_label = `Accumulated ${filter}`;
            this.get_market_data();
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', null);
        }
    }
}
</script>
