var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "kitchensink" } },
    [
      _c("Title", { attrs: { title: "Brand Desire" } }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "is-pulled-left",
            attrs: { buttons: _vm.generate_buttons(), dataset: _vm.table },
          }),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                key: _vm.$store.getters.market,
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: _vm.$store.getters.market,
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "section box" }, [
        _c("iframe", {
          attrs: {
            title: "FlightTowerTest",
            src: `https://app.powerbi.com/reportEmbed?reportId=937e5e82-9f73-4051-a5ec-544505e155e7&appId=e663d921-84f7-4b88-a3f0-e128bbf3fc6b&autoAuth=true&ctid=720b637a-655a-40cf-816a-f22f40755c2c`,
            frameborder: "0",
            allowFullScreen: "true",
          },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }