<template>
<div>
    <Title
        title="Sales Tree"
        :location="location"
    />

    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
        <div class="label-container" 
            :class="!$store.getters.market ? 'ft-tooltip': null" 
            :tooltiptext="!$store.getters.market ? 'Select a country from top filter to see stores' : null"
        >
            <p>Market Area</p>
            <DropDown
                :key="stores.values.length"
                :options="stores.options"
                :values="stores.values"
                :default="stores.values[0]"
                @updateDropDown="update_store"
            />
        </div>
        <div class="label-container">
            <p>Channel</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>
        <div class="label-container">
            <p>Period</p>
            <DropDown
                :key="filter2"
                :options="options2"
                :values="values2"
                :default="filter2"
                @updateDropDown="update_filter2"
            />
        </div>
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms">
        <Graph
            v-if="graph.render"

            :title="`Showing data for: ${$store.getters.selected_country_name}`"
            :show-title="!$store.state.global.bot_mode"
            
            v-bind="graph"
        />
        <div v-else>There is no data for <b>{{$store.getters.selected_country_name}}</b> with the <b>{{options[values.indexOf(filter)]}}</b> and <b>{{options2[values2.indexOf(filter2)]}}</b> filters selected</div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Graph from '@/components/visualisations/general/Graph.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Graph,
        Title
    },
    props: {
        market: String
    },
    data: function() {
        return {
            options2: ['YTD', '4WK Trend', '1WK Trend'],
            values2: ['YTD', 'WK4', 'WK'],
            filter2: 'YTD',
            options: ['Total Store','Store (Comp)', 'Online', 'Remote'],
			values: ['Store', 'Store(Comp)', 'Online', 'Remote'],
			filter: 'Store',
            store: null,
            location: '/business/sales-tree',
            graph: {
                x_categories: [],
                y_axis_label: 'YTD Index and EUR',
                y_metric: '',

                y2_axis_label: '',
                y2_metric: '%',
                y2_axis_min: 0,
                y2_axis_max: 100,
                y_zoom: true,
                
                names: [],
                data_sets: [],
                types: new Array(7).fill("line"),
                colors: [
                    this.$store.state.colors['focus-yellow'],
                    '#0000FF',
                    '#9BC5F5',
                    '#a060f2',
                    '#006400',
                    '#64A664',
                    this.$store.state.colors['dark-grey'],
                    this.$store.state.colors['light-grey'],
                    this.$store.state.colors['dark-grey'],
                ],
                render: false
            },
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.filter = this.$store.getters.filter_check(this.values);
        this.load_market_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'By Market',
                    link: `${this.location}/by-market/${this.$store.getters.market}`
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
					name: "Tree",
					link: `${this.location}/tree/${this.$store.getters.market}`
				},
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8/Online`
                },
            ];
        },
        load_market_data: function() {
            if (!this.filter && !this.filter2) return;
            this.$http.post('/business/sales-tree/market', {
                market: this.store || this.$store.getters.market,
                filter1: this.filter,
                filter2: this.filter2
            }).then(
                succ => {
                    let d = succ.data;
                    if (this.filter === 'Store' && !d[0].measures) {
                        this.options.shift();
                        this.values.shift();
                        this.filter = this.values[0];
                        this.load_market_data();
                        return;
                    }
                    if (!d[0] || !d[0].measures) return;
                    this.graph.x_categories = Object.keys(d[0].measures);
                    this.graph.data_sets = [[],[],[],[],[],[],[]];
                    this.graph.names = [
                        'Created Sales, Net',
                        'Delivered Sales, net',
                        'Customers, Gross',
                        'Conversion',
                        'Avg, ticket',
                        'Visitors',
                        'Conversion (%)'
                    ];
                    this.graph.yAxisIndexes = [
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        1
                    ];

                    let x = this.filter2 === 'YTD' ? 'ytd' : this.filter2 === 'WK' ? 'wk' : '4wk';
                    let perc_conversion_min = 100;
                    this.graph.x_categories.forEach(week => {
                        let val = d[0].measures[week];
                        this.graph.data_sets[0].push(this.$helper.round(val[`cre_sales_net_${x}_index`], 1));
                        this.graph.data_sets[1].push(this.$helper.round(val[`dlvd_sales_net_${x}_index`], 1));
                        this.graph.data_sets[2].push(this.$helper.round(val[`customer_gross_${x}_index`], 1));
                        this.graph.data_sets[3].push(this.$helper.round(val[`conversion_${x}_index`], 1));
                        this.graph.data_sets[4].push(this.$helper.round(val[`avg_ticket_${x}_index`], 1));
                        this.graph.data_sets[5].push(this.$helper.round(val[`visitors_${x}_index`], 1));
                        this.graph.data_sets[6].push(this.$helper.round(val[`conversion_${x}`], 1));
                    });
                    let conversion_perc_vals = this.graph.data_sets[6].map(x => parseFloat(x)).filter(x => !isNaN(x));
                    this.graph.y2_axis_min = Math.max(Math.min(...conversion_perc_vals) - 1, 0);
                    this.graph.y2_axis_max = Math.min(Math.max(...conversion_perc_vals) + 1, 100);
                    this.graph.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.graph.render = false;
            this.load_market_data();
        },
        update_filter2: function(filter) {
            this.filter2 = filter;
            this.graph.y_axis_label = `${filter} Index and EUR`;
            this.graph.render = false;
            this.load_market_data();
        },
        update_store: function(store) {
            this.store = store;
            this.graph.render = false;
            this.load_market_data();
        },
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.graph.render = false;
            this.update_view(newMarket);
            this.options = ['Total Store', 'Online', 'Store (Comp)', 'Remote'];
			this.values =  ['Store', 'Online', 'Store(Comp)', 'Remote'];
            this.filter = this.$store.getters.filter_check(this.values);
            this.load_market_data();
        }
    },
    computed: {
        stores: function() {
            if (this.$store.getters.stores.values.length) {
                return this.$store.getters.stores;
            } 
            return {
                all: [],
                options: [],
                values: []
            }
        }
    }
}
</script>
