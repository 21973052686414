<template>
<div>
    <Title
        title="Food Sales"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
    
        <div class="label-container" 
            :class="!$store.getters.market ? 'ft-tooltip': null" 
            :tooltiptext="!$store.getters.market ? 'Select a country from top filter to see stores' : null"
        >
            <p>Market Area</p>
            <DropDown
                :key="stores.values.length"
                :options="stores.options"
                :values="stores.values"
                :default="stores.values[0]"
                @updateDropDown="update_store"
            />
        </div>

        <div class="label-container">
            <p>Work station</p>
            <DropDown
                :key="filter.work_station.state"
                :options="filter.work_station.options"
                :values="filter.work_station.values"
                :default="filter.work_station.state"
                @updateDropDown="update_filter_work_station"
            />
        </div>

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms">
        <Triple
            v-if="triple.render"

            :title="`Showing data for: ${$store.getters.selected_country_name}`"
            :showtitle="!$store.state.global.bot_mode"

            subtitle=""

            :xcategories="triple.xcategories"
            yaxislabel="Food Sales TY/LY"
            ymetric=""
            
            seriesname1="YTD"
            seriestype1="line"
            :seriesset1="triple.seriesset1"
            :color1="$store.state.colors['focus-yellow']"
            
            seriesname2="4WK Trend"
            seriestype2="line"
            :seriesset2="triple.seriesset2"
            :color2="$store.state.colors['dark-grey']"
            
            seriesname3="Year Goal"
            seriestype3="line"
            :seriesset3="triple.seriesset3"
            :color3="$store.state.colors.YG"

            :show="!$store.state.global.bot_mode"

        />
        <div v-else> No data found for {{$store.state.selected.country}}</div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Triple from '@/components/visualisations/general/Triple.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Title,
        Triple
    },
    props: {
        market: String
    },
    data: function() {
        return {
            filter: {
                work_station: {
                    state: null,
                    options: [],
                    values: []
                }
            },
            triple: {
                title: null,
                subtitle: null,

                xcategories: null,
                yaxislabel: null,
                ymetric: null,
                
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],

                render: false
            },
            store: null,
            location: '/business/sales-per-range/food-sales'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        this.get_filters();
    },
    methods: {
         generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
                {
                    name: 'By Market',
                    link: `${this.location}/by-market/${this.$store.getters.market}`
                },
                {
                    name: 'Tree',
                    link: `${this.location}/tree/${this.$store.getters.market}`
                },
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'food-sales-work-station',
                disable_sort: true
            }).then(
                succ => {
                    succ.data.forEach(filter => {
                        this.filter.work_station.values.push(filter.value);
                        this.filter.work_station.options.push(filter.option);
                    });
                    this.filter.work_station.state = this.$store.getters.filter_check(this.filter.work_station.values); 
                },
                this.$helper.error
            );
        },
        get_data: function() {
            this.triple.render = false;
            if (!this.filter.work_station.state) return;
            let market, parent;
            if (this.store === null) {
                market = this.$store.getters.market || 'ALL';
                parent = this.$store.getters.market ? 'ALL' : null;
            } else {
                parent = this.$store.getters.market;
                market =  this.store;
            }
            this.$http.post('/business/sales-per-range/food-sales/trend', {
                market,
                parent,
                work_station_filter: this.filter.work_station.state
            }).then(
                succ => {
                    let d = succ.data;
                    if (!d || !d[0]) return;
                    this.triple.xcategories = Object.keys(d[0].measures);
                    
                    this.triple.seriesset1 = [];
                    this.triple.seriesset2 = [];
                    this.triple.seriesset3 = [];
                    
                    this.triple.xcategories.map(month => {
                        let val = d[0].measures[month];
                        this.triple.seriesset1.push(this.$helper.maybe(val['ytd_index']));
                        this.triple.seriesset2.push(this.$helper.maybe(val['wk4_index']));
                        this.triple.seriesset3.push(this.$helper.maybe(val['goal']));
                    });
                    this.triple.render = true;
                },
                this.$helper.error
            );
        },
        update_filter_work_station: function(work_station_filter) {
            this.filter.work_station.state = work_station_filter;
            this.$store.state.global.temp_work_station_filter = work_station_filter;
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location,
            });
        },
        update_store: function(store) {
            this.store = store;
            this.get_data();
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.store = null;
            this.get_data();
        },
        '$store.getters.stores': function() {
            return this.$store.getters.stores;
        },
    },
    computed: {
        stores: function() {
            if (this.$store.getters.stores.values.length) {
                return this.$store.getters.stores;
            } else {
                return {
                    all: [],
                    options: [],
                    values: []
                }
            }
        }
    },
    watch: {
        '$store.getters.countries': function(newCountries, oldCountries) {
            this.$store.commit('update_market', this.market);	
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.get_data();       
        },
        'filter.work_station.state': function() {
            this.get_data();
        }
    }
}
</script>

<style>

</style>
