var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: {
          title: "Profit & Loss Total Ingka Retail",
          location: _vm.location,
        },
      }),
      _c("div", { staticClass: "options-bar" }, [
        _c(
          "div",
          { staticClass: "label-container" },
          [
            _c("p", [_vm._v("Filter")]),
            _c("DropDown", {
              key: _vm.values[0],
              attrs: {
                options: _vm.options,
                values: _vm.values,
                default: _vm.values[0],
              },
              on: { updateDropDown: _vm.update_filter },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "label-container hidden-on-pc" },
          [
            _c("p", [_vm._v("Country")]),
            _c("DropDown", {
              key: _vm.$store.getters.market,
              attrs: {
                options: _vm.$store.state.countries.options,
                values: _vm.$store.state.countries.values,
                default: _vm.$store.getters.market,
              },
              on: { updateDropDown: _vm.update_view },
            }),
          ],
          1
        ),
      ]),
      _c("Table", {
        staticClass: "ms1p5 no-all",
        attrs: {
          headers: _vm.table.headers,
          rows: _vm.table.rows,
          "icon-settings": _vm.table.icon_settings,
          "sort-settings": _vm.table.sort_settings,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }