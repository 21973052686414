<template>
<div>
    <Title
        title="Customer Distribution Center"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container">
            <p>Filter</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms">
        <Sextuple
            v-if="sextuple.render"

            title="Stock Accuracy"
            :showtitle="!$store.state.global.bot_mode"
            subtitle=""

            :xcategories="sextuple.xcategories"
            
            yaxislabel1="Stock Accuracy %"
            
            ymetric1=""
            
            yaxislabel2="Number of Checks"
            
            ymetric2=""

            seriesname1="YTD Total Stock Checks"
            seriestype1="line"
            :seriesset1="sextuple.seriesset1"
            :color1="$store.state.colors['grey']"
            
            seriesname2="YTD %"
            seriestype2="line"
            :seriesset2="sextuple.seriesset2"
            :color2="$store.state.colors['focus-yellow']"
            
            seriesname3="1WK %"
            seriestype3="line"
            :seriesset3="sextuple.seriesset3"
            :color3="$store.state.colors['light-grey']"

            seriesname4="4WK %"
            seriestype4="line"
            :seriesset4="sextuple.seriesset4"
            :color4="$store.state.colors['dark-grey']"

            :seriesname5="this.goal"
            seriestype5="line"
            :seriesset5="sextuple.seriesset5"
            :color5="$store.state.colors.YG"

            :show="!$store.state.global.bot_mode"

        />
        <div v-else> No data found for {{$store.getters.selected_country_name}}</div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Sextuple from '@/components/visualisations/general/DeviationWith4Lines.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Title,
        Sextuple
    },
    props: {
        market: String,
        option: String
    },
    data: function() {
        return {
            sextuple: {
                title: null,
                subtitle: null,

                xcategories: null,
                yaxislabel: null,
                ymetric: null,
                
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],
                seriesset4: [],
                seriesset5: [],
                seriesset6: [],

                render: false
            },
            goal: 'Goal',
            location: '/business/customer-distribution-center',
            options: ['SM0 - Market Hall', 'SM1 - Self Serve Furniture Area', 'SM2 - Full Serve'],
            values: ['0', '1', '2'],
            filter: '0'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        this.filter = this.$store.getters.filter_check(this.option);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.load_market_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.market}/${this.option}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
            ];
        },
        load_market_data: function() {
            this.$http.post('/business/customer-distribution-center/market', {
                market: this.$store.getters.market,
                filter: this.filter
            }).then(
                succ => {
                    let d = succ.data;
                    this.sextuple.xcategories = Object.keys(d[0].measures);
                    this.sextuple.seriesset1 = [];
                    this.sextuple.seriesset2 = [];
                    this.sextuple.seriesset3 = [];
                    this.sextuple.seriesset4 = [];
                    this.sextuple.seriesset5 = [];
                    Object.values(d[0].measures).map(val => {
                        this.sextuple.seriesset1.push(this.$helper.maybe(val['YTD Total Stock Checks']));
                        this.sextuple.seriesset2.push(this.$helper.maybe(val['YTD %']));
                        this.sextuple.seriesset3.push(this.$helper.maybe(val['Week %']));
                        this.sextuple.seriesset4.push(this.$helper.maybe(val['4 Week %']));
                        this.sextuple.seriesset5.push(val['Goal']);
                    });
                    this.goal = this.sextuple.seriesset5.findIndex( goal => goal) ?  null : 'Goal';
                    this.sextuple.render = true;
                },
                this.$helper.error
            );
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.sextuple.render = false;
            this.update_view(this.market, this.filter);
            this.load_market_data();
        },
        update_view: function(market, option) {	
            this.$store.commit('update_view', {	
                market: market,
                option: option,
                location: this.filter_url
            });	
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);  
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.sextuple.render = false;
            this.update_view(newMarket, this.filter);
            this.load_market_data();

        },
        '$route': function(to, from) {
            this.$router.push(this.filter_url);
        }
    },
    computed: {
        filter_formatted:  function() {
            return this.filter ? this.filter.replace(/ /g, '-').replace(/_/g, '-') : null;
        },
        filter_url: function() {
            return `${this.location}/${this.market}/${this.filter_formatted}`
        }
    }
}
</script>

<style>

</style>