<template>
    <div :id="title" style="width: 100%; height:100%;"></div>
</template>

<script>
import * as echarts from 'echarts/dist/echarts.min.js';
export default {
    props: {
        title: String, //title of the graph
        showTitle: Boolean,

        sub_title: String, // subtitle of the graph
        x_categories: Array, //data that will be displayed on the x axis
        x_axis_label_interval: String,
        y_axis_label: String, //name of the data on the y axis e.g 'After sales delivery share' or 'services sale by month'
        y_axis_name_gap: Number,
        y_metric: String, //'%', '£', 'kg' etc.
        
        y_axis_min: Number, //set the minimum value of the the y axis
        y_axis_max: Number, //set the maximum value of the the y axis
        y_start_percent: Number,
        y_end_percent: Number,

        y2_axis_label: String, //name of the data on the y axis e.g 'After sales delivery share' or 'services sale by month'
        y2_metric: String, //'%', '£', 'kg' etc.

        y2_axis_min: Number, //set the minimum value of the the y axis
        y2_axis_max: Number, //set the maximum value of the the y axis
        
        names: Array, //name of the data such as IKEA
        types: Array, //bar, line, scatter etc.
        stack: Array,
        data_sets: Array, //actual data array to fill the graph
        colors: Array, //color of the bar lie or scatter for the paticular data set
        disabled_labels: Array,
        legend_selected: Object, //set the default value of the items in the legend (e.g enabled or disabled)
        yAxisIndexes: Array,
        toolbox: Boolean,
        year_goal_index: Number, //the index of the value which is the year goal, this needs special styling
        grid: Object, //the spacings controling the size of the graph
        type_styles: Array,

        y_zoom: Boolean,
        x_zoom: Boolean,

        show: Boolean, // true to show the exta features such as 'save graph as image', 'refresh', 'change to bar graph', etc
    },
    data: function() {
        return {
            max: -Infinity,
            min: Infinity
        }
    },
    mounted: function() {
        this.calculate_min_max_values();
        let myChart = echarts.init(document.getElementById(this.title));
        if (this.year_goal_index && this.data_sets[this.year_goal_index]) this.fill_goal_values();
        let option = {
            title: {
                text: this.title,
                textStyle: {
                    color: '#1565c0',
                    fontSize: '2rem'
                },
                subtext: this.sub_title,
                subtextStyle: {
                    lineHeight: '1rem',
                    fontSize: '1rem'
                },
                show: this.showTitle || false,
                subtextStyle: {
                    color: 'black',
                },
                top: '5rem',
                left: '50rem'
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            toolbox: {
                top: '0rem',
                right: '70rem',
                show: this.toolbox,
                feature: {
                    restore: {},
                    saveAsImage: {}
                }
            },
            grid: {
               top: '60rem',
               left: this.y_zoom ? '60rem' : '40rem',
               bottom: this.x_zoom ? '120em' : '100rem',
               right: '40rem'
            },
            legend: {
                left: '275rem',
                bottom: this.x_zoom ? '40em' : '0rem',
                itemGap: 35,
                selected: this.legend_selected ? this.legend_selected : this.selected_names,
                data: this.names
            },
            textStyle: {
                fontFamily: 'NotoIKEALatin-Regular',
            },
            xAxis: [
                {
                    type: 'category',
                    data: this.x_categories,
                    axisPointer: {
                        type: 'shadow'
                    },
                    axisLabel: {
                        interval: this.x_axis_label_interval || '0',
                        fontWeight: 'bold',
                        fontSize: 14
                    },
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: this.y_axis_label,
                    min: this.y_axis_min ? this.y_axis_min : this.min,
                    max: this.y_axis_max ? this.y_axis_max : this.max,
                    nameLocation: 'middle',
                    nameGap: this.y_axis_name_gap || 90,
                    axisLabel: {
                        formatter: `{value} ${this.y_metric}`,
                        show: false
                    },
                    splitLine: {
                        show: false
                    }
                    
                },
                {
                    type: this.y2_axis_label,
                    name: this.y2_axis_label,
                    min: this.y2_axis_min,
                    max: this.y2_axis_max,
                    nameLocation: 'middle',
                    nameGap: 70,
                    axisLabel: {
                        formatter: `{value} ${this.y2_metric}`
                    }
                },
            ],
            dataZoom: [
                {
                    show: this.x_zoom,
                    type: 'slider',
                    xAxisIndex: 0,
                    filterMode: 'empty',
                    left:  this.y_zoom ? '90rem' : '100rem',
                    bottom: '10rem',
                },
                {
                    show: this.y_zoom,
                    type: 'slider',
                    start: this.y_start_percent,
                    end: this.y_end_percent,
                    yAxisIndex: 0,
                    filterMode: 'empty',
                    left: '0rem',
                    bottom: this.x_zoom ? '60rem' : '30rem',
                }
            ],
            series: []
        };
        
        this.names.forEach((name, i) => {
            let yAxisIndexes = this.yAxisIndexes || [];
            let temp = {
                name: name,
                type: this.types[i],
                data: this.data_sets[i],
                color: this.colors[i],
                yAxisIndex: yAxisIndexes[i] || 0,
                
            }
            if (this.types[i] === 'line') {
                temp['lineStyle'] = { width: 3 };
                temp['symbolSize'] = 15;
                temp['symbol'] = 'circle';
                temp['label'] = {
                    show: true,
                    formatter: params => {
                        return `${params.value}${this.y_metric || ''}`;
                    }
                };
            }
            if (this.stack && this.stack[i]) temp['stack'] = this.stack[i];
            if (this.type_styles[i] && this.type_styles[i].lineStyle) temp['lineStyle']['type'] = this.type_styles[i].lineStyle;

            let disabled_labels = this.disabled_labels || [];
            if (this.types[i] === 'bar' && !disabled_labels[i]) {
                temp['label'] = {
                    fontSize: 12,
                    show: true,
                    align: 'center',
                    verticalAlign: 'middle',
                    position: 'insideTop',
                    distance: 20,
                    color: 'black',
                    formatter: params => {
                        return this.$helper.round(params.data, false, ',');
                    }
                }
            }
            if (this.year_goal_index && this.year_goal_index === i) {
                temp['markPoint'] = this.year_goal_label(name, this.data_sets[i]);
                temp['symbol'] = 'none';
            }
            option.series.push(temp);
        });
        if (this.grid && Object.keys(this.grid).length) Object.keys(this.grid).forEach(key => option.grid[key] = this.grid[key]);
        
        myChart.setOption(option);
    },
    methods: {
        calculate_min_max_values: function() {
            this.data_sets.forEach(set => {
                set.forEach(value => {
                    this.min = this.min > value ? value : this.min;
                    this.max = this.max < value ? value : this.max;
                });
            });
            this.max = Math.ceil(this.max);
            this.min = Math.floor(this.min);
        },
        fill_goal_values: function() {
            let max_dataset_length = 0;
            this.data_sets.forEach(set => {
                max_dataset_length = set.length > max_dataset_length ? set.length : max_dataset_length;
            });
            let goal_value = this.data_sets[this.year_goal_index][0];
            this.data_sets[this.year_goal_index] = new Array(max_dataset_length).fill(goal_value);
        },
        year_goal_label: function(name, set) {
            let length = set.length;
            return {
                symbolSize: 0,
                label: {
                    color: this.colors[this.year_goal_index],
                    fontSize: 12,
                    offset: [30, -10],
                },
                data: [{value: `${name}: ${set[0]}`, xAxis: 0, yAxis: set[0]}]
            }
        }
    },
    computed: {
        selected_names: function() {
            let temp = {};
            this.names.forEach(name => {
                temp[name] = 'true';
            });
            return temp;
        }
    }
}
</script>