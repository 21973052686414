var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "feedback" }, [
    _c("div", { staticClass: "custom-title section" }, [
      _c("div", { staticClass: "title" }, [_vm._v(" Feedback ")]),
      _c("div", { staticClass: "subtitle ps2p5" }, [
        _vm._v(
          "Spotted a bug, or have an idea for a new feature? Leave us some feedback. "
        ),
      ]),
      _c("div", { staticClass: "container box" }, [
        _vm._m(0),
        _c("div", { staticClass: "mb" }, [
          _vm._v("(This feeback will get our immediate attention)"),
        ]),
        _c(
          "div",
          {
            staticClass: "button is-link top-right",
            on: {
              click: function ($event) {
                _vm.urgent = !_vm.urgent
              },
            },
          },
          [
            _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.urgent,
                  expression: "!urgent",
                },
              ],
              staticClass: "fas fa-chevron-down",
            }),
            _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.urgent,
                  expression: "urgent",
                },
              ],
              staticClass: "fas fa-chevron-up",
            }),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.urgent,
                expression: "urgent",
              },
            ],
          },
          [
            _vm._m(1),
            _c("p", [
              _vm._v(
                "We use Jira to handle feedback in the most efficient way. Don't have access?"
              ),
            ]),
            _c(
              "a",
              {
                attrs: {
                  href: "https://jira-selfservice.azurewebsites.net/new_jira_user",
                  target: "_blank",
                },
              },
              [_vm._v("Create JIRA Account")]
            ),
            _vm.issues.length > 0
              ? _c("div", { staticClass: "feedback-notice issues" }, [
                  _vm._m(2),
                  _c(
                    "div",
                    { staticClass: "-mt" },
                    _vm._l(_vm.issues, function (issue, i) {
                      return _c("div", { key: i }, [
                        _vm._v(
                          " " + _vm._s(i + 1) + ". " + _vm._s(issue.issue) + " "
                        ),
                      ])
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ]),
      _c("div", { staticClass: "container box" }, [
        _vm._m(3),
        _c("div", { staticClass: "ps2p5" }, [
          _vm._v("(How do you feel about Retail Flight Tower over all ?)"),
        ]),
        _c(
          "div",
          {
            staticClass: "button is-link top-right",
            on: {
              click: function ($event) {
                _vm.non_urgent = !_vm.non_urgent
              },
            },
          },
          [
            _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.non_urgent,
                  expression: "!non_urgent",
                },
              ],
              staticClass: "fas fa-chevron-down",
            }),
            _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.non_urgent,
                  expression: "non_urgent",
                },
              ],
              staticClass: "fas fa-chevron-up",
            }),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.non_urgent,
                expression: "non_urgent",
              },
            ],
          },
          [
            _c("div", { staticClass: "label" }, [
              _vm._v(
                "How would you rate your satisfaction with the Flight Tower solution so far?"
              ),
            ]),
            _c(
              "div",
              { staticClass: "face-section mbp5" },
              _vm._l(_vm.faces, function (face, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "ft-tooltip",
                    attrs: { tooltiptext: face.tooltip },
                  },
                  [
                    _c("i", {
                      staticClass: "far cursor-pointer",
                      class: {
                        [face.class]: true,
                      },
                      style: {
                        color: face.color,
                        display: face.selected === false ? "none" : "flex",
                      },
                      on: {
                        click: function ($event) {
                          _vm.can_cancel ? _vm.clear() : _vm.update(i)
                        },
                      },
                    }),
                  ]
                )
              }),
              0
            ),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.can_cancel,
                    expression: "can_cancel",
                  },
                ],
                staticClass: "unselect-text",
              },
              [_vm._v("Click face again to unselect")]
            ),
            _vm.rating < 4 && _vm.rating > 0
              ? _c("div", { staticClass: "label text-left" }, [
                  _vm._v("How could we improve your experience? (Optional)"),
                ])
              : _vm._e(),
            _vm.rating < 4 && _vm.rating > 0
              ? _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.details,
                      expression: "details",
                    },
                  ],
                  staticClass: "textarea mb",
                  attrs: { rows: "7", placeholder: "Your feedback" },
                  domProps: { value: _vm.details },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.details = $event.target.value
                    },
                  },
                })
              : _vm._e(),
            _c(
              "a",
              {
                staticClass: "button input is-success mt",
                attrs: { disabled: !_vm.can_cancel },
                on: { click: _vm.send_feedback },
              },
              [_vm._v("Submit")]
            ),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.non_urgent,
                expression: "!non_urgent",
              },
            ],
            staticClass: "feedback-notice",
          },
          [
            _c("b", [
              _vm._v(
                "This section is not for Bug report, Feature Requests or Questions"
              ),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "subtitle mbp5 ps2p5" }, [
      _c("b", [_vm._v("Bugs, Feature requests and Questions")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://jira.digital.ingka.com/servicedesk/customer/portal/28",
            target: "_blank",
          },
        },
        [_vm._v("SBP Reporting & Analytics Support Page")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "subtitle" }, [
      _c("b", [_vm._v("Issues we are aware of and working on")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "subtitle mbp5 ps2p5" }, [
      _c("b", [_vm._v("Overall Satisfaction")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }