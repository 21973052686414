<template>
<div>
    <div class="title mt">4<span @click="easter = !easter">0</span>4 Page not found</div>
    <p>We could not find the page you were looking for.</p>

    <div class="mt">
        <router-link to="/" class="button">Take me back</router-link>
    </div>

    <img class="image" src="../../public/assets/images/easter/404Wardrobe.jpg">
    <img v-if="easter" class="image easter" src="../../public/assets/images/easter/404Travolta.gif">
</div>
</template>

<script>
export default {
    data: function() {
        return {
            easter: false
        }
    },
    mounted: function() {
        this.$store.state.commenting.width = 0;
    },
    destroyed: function() {
        this.$store.state.commenting.width = 4;
    }
}
</script>

<style scoped>
.image {
    margin: auto;
    height: auto;
    max-width: 360px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.image.easter {
    margin-top: -200px;
    max-width: 120px;;
}

</style>