<template>
<div>
    <Title
        title="People + Planet Positive Sales"
        :location="location"
    />
    <div class="options-bar">
        
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms">
        <Triple
            :key="triple.render"
            v-if="triple.render"
            :title="`Showing data for: ${$store.getters.selected_country_name}`"
            :showtitle="!$store.state.global.bot_mode"
            subtitle=""
            
            :xcategories="triple.xcategories"
            yaxislabel="Share of Total Sales"
            ymetric="%"

            seriesname1="YTD"
            seriestype1="line"
            :seriesset1="triple.seriesset1"
            :color1="$store.state.colors['focus-yellow']"

            seriesname2="1WK Trend"
            seriestype2="line"
            :seriesset2="triple.seriesset2"
            :color2="$store.state.colors['light-grey']"

            seriesname3="Year Goal"
            seriestype3="line"
            :seriesset3="triple.seriesset3"
            :color3="$store.state.colors.YG"

            :show="!$store.state.global.bot_mode"
            
        />
        <div v-else> No data found for {{$store.getters.selected_country_name}}</div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/Table.vue';
import Title from '@/components/ui/Title.vue';
import Triple from '@/components/visualisations/general/Triple.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title,
        Triple
    },
    props: {
        market: String
    },
    data: function() {
        return {
            triple: {
                render: false,

                xcategories: [],
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],
            },
            location: '/sustainability/sustainable-sales'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.load_market_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
            ];
        },
        load_market_data: function() {
            this.triple = {
                render: false,
                xcategories: [],
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],
            };
            this.$http.post('/sustainability/sales/market', {
                market: this.$store.getters.market
            }).then(succ => {
                    let d = succ.data;

                    /**
                     * Termporary code to correctly sort weeks
                     */
                    let sorted_data = Object.keys(d[0].measures).sort((a, b) => {
                            a = parseInt(a.replace(/\D/g, ''));
                            b = parseInt(b.replace(/\D/g, ''));
                            if (a < 35) a += 52;
                            if (b < 35) b += 52;
                            return a > b ? 1 : -1;
                        }).map(key => {
                            return {
                                ...d[0].measures[key],
                                xcategory: key
                            };
                        });

                    sorted_data.forEach(row => {
                        this.triple.xcategories.push(row.xcategory);
                        this.triple.seriesset1.push(this.$helper.maybe(row['YTD Sustainable sales share']));
                        this.triple.seriesset2.push(this.$helper.maybe(row['Week Sustainable sales share']));
                        this.triple.seriesset3.push(this.$helper.maybe(row['Year Goal']));
                    });

                    // Prepatch code
                    // this.triple.xcategories = Object.keys(d[0].measures);
                    // this.triple.seriesset1 = [];
                    // this.triple.seriesset2 = [];
                    // this.triple.seriesset3 = [];
                    
                    // Object.values(d[0].measures).map(row => {
                    //     this.triple.seriesset1.push(this.$helper.maybe(row['YTD Sustainable sales share']));
                    //     this.triple.seriesset2.push(this.$helper.maybe(row['Week Sustainable sales share']));
                    //     this.triple.seriesset3.push(this.$helper.maybe(row['Year Goal']));
                    // });

                    this.triple.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.triple.render = false;
            this.update_view(newMarket);
            this.load_market_data();
        }
    }
}
</script>

<style>

</style>