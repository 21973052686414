<template>
<div>
    <Title
        title="HFB"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />
        
        <div class="label-container" 
            :class="!$store.getters.market ? 'ft-tooltip': null" 
            :tooltiptext="!$store.getters.market ? 'Select a country from top filter to see stores' : null"
        >
            <p>Market Area</p>
            <DropDown
                :key="stores.values.length"
                :options="stores.options"
                :values="stores.values"
                :default="stores.values[0]"
                @updateDropDown="update_store"
            />
        </div>
    
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>      
    </div>

    <TableV2
        v-if="table.render"
        id="hfb"

        :headers="table.headers"
        :rows="table.rows"

        :data-settings="{ nullify_zeros: true }"
        :font-settings="{ bold: {row: {regex: 'Total HFB' } } }"
        :decimal-settings="table.decimal_settings"
        :icon-settings="table.icon_settings"
        :sort-settings="table.sort_settings"

        :metaSettings="{is_selectable: false}"
    />
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import TableV2 from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        TableV2,
        Title
    },
    props: {
        market: String
    },
    data: function() {
        return {
            table: {
                headers: null,
                rows: null,
                icon_settings: {
                    'YTD Index': {
                        comparison: 'Year Goal',
                        icon: 'circle',
                    },
                    '4WK Index': {
                        comparison: 'YTD Index',
                        icon: 'arrow',
                    },
                    'Growth Contribution % point': {
                        icon: 'bar',
                        comparison: 'Growth Contribution % point',
                        ignore: ['Total HFB']
                    },
                    'Forecast vs Goal MEUR': {
                        icon: 'bar',
                        comparison: 'Forecast vs Goal MEUR',
                        ignore: ['Total HFB']
                    },
                    'Sales Share %': {
                        append: '%'
                    }
                },
                decimal_settings: {},
                sort_settings: {
                    'HFB': {
                        asc: true
                    },
                    static: [
                        'Total HFB',
                        'Core Area - Bedroom',
                        'Core Area - Kitchen and Bathroom',
                        'Core Area - Living room',
                        'Core Area - Other',
                        'Furniture',
                        'HFA'
                    ]
                },

                render: false
            },
            store: null,
            location: '/business/sales-per-range/hfb'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (!this.$helper.market_check(this.market) && this.$store.getters.market) {
            this.$router.push(`${this.location}/by-hfb/${this.$store.getters.market}`);
            this.load_ingka_by_country();
        }
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.load_ingka_by_country();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By HFB',
                    link: `${this.location}/by-hfb/${this.$store.getters.market}`
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Channel Contribution',
                    link: `${this.location}/channel-contribution/${this.$store.getters.market}`
                }
            ];
        },
        load_ingka_by_country: function() {
            let reqdata = {};
            if (!this.store) {
                reqdata = {
                    parent: this.$store.getters.market ? 'ALL' : 'TOP',
                    market: this.$store.getters.market || 'ALL'
                };
            } else {
                reqdata = {parent: this.$store.getters.market, market: this.store};
            }
            this.$http.post('business/sales-per-range/sales-per-hfb/hfb-per-country',
                reqdata
            ).then(
                succ => {
                    let d = succ.data;

                    this.table.headers, this.table.rows = [];

                    let keys = Object.keys(d[0].measures[Object.keys(d[0].measures)[0]]);
                    if (d[0].measure_metadata) {
                        this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                    }
                    
                    this.table.headers = [['HFB'].concat(keys).map(c => ({[c]: 1}))];
                    
                    Object.keys(d[0].measures).forEach(row => {
                        let temp = [];
                        // If the key/values don't exist, set them to 0

                        if (this.hfbs.options[this.hfbs.values.indexOf(row)]) {
                            temp.push(this.hfbs.options[this.hfbs.values.indexOf(row)]);
                        } else {
                            temp.push(row)
                        }
                        
                        keys.map(key => {
                            temp.push(this.$helper.maybe(d[0].measures[row][key]));
                        });
                        this.table.rows.push(temp);
                    });

                    this.$helper.header_replace(this.table.headers, {
                        'R4 Index': '4WK Index',
                        'Index YTD': 'YTD Index'
                    });
                    if ('R4 Index' in this.table.decimal_settings) {
                        this.table.decimal_settings['4WK Index'] = this.table.decimal_settings['R4 Index'];
                    }
                    if ('Index YTD' in this.table.decimal_settings) {
                        this.table.decimal_settings['YTD Index'] = this.table.decimal_settings['Index YTD'];
                    }
                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        update_store: function(store) {
            this.store = store;
            this.table.render = false;
            this.load_ingka_by_country();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: `${this.location}/by-hfb`
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.store = null;
            this.table.render = false;
            this.load_ingka_by_country();
        },
        '$store.getters.stores': function() {
            this.store = null;
            this.table.render = false;
            this.load_ingka_by_country();
        },
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
    },
    computed: {
        stores: function() {
            if (this.$store.getters.stores.values.length) {
                return this.$store.getters.stores;
            } else {
                return {
                    all: [],
                    options: [],
                    values: []
                }
            }
        },
        hfbs: function() {
            return this.$store.getters.hfbs;
        }
    }
}
</script>
