import Home from '@/views/admin/Home.vue';
import AdminKitchenSink from '@/views/admin/AdminKitchenSink.vue';
import KPIDefinitions from '@/views/admin/KPIDefinitions.vue';
import KPIDefinitionsFeedback from '@/views/admin/KPIDefinitionsFeedback.vue';
import KPIInsightsSettings from '../views/admin/KPIInsightsSettings.vue';
import EnableDisableKPIs from '@/views/admin/EnableDisableKPIs.vue';
import LoadTracker from '@/views/admin/LoadTracker.vue';
import WhichKPIsHaveComments from '@/views/admin/WhichKPIsHaveComments.vue';
import KPIPictures from '@/views/admin/KPIPictures.vue';

import GlobalAnnouncements from '@/views/admin/GlobalAnnouncements.vue';

import NPS from '@/views/admin/nps';
import NPSForms from '@/views/admin/nps/forms';
import NPSFormsAll from '@/views/admin/nps/forms/Home.vue';
import NPSFormsNew from '@/views/admin/nps/forms/New.vue';
import NPSQuestions from '@/views/admin/nps/questions';
import NPSQuestionsAll from '@/views/admin/nps/questions/Home.vue';
import NPSQuestionEdit from '@/views/admin/nps/questions/Edit.vue';
import NPSQuestionsNew from '@/views/admin/nps/questions/New.vue';

import Metrics from '@/views/admin/Metrics.vue';
import UserManagement from '@/views/admin/user-management';
import ReleaseNotes from '@/views/admin/ReleaseNotes.vue';

import AccessTemplates from '@/views/admin/user-management/AccessTemplates.vue';
import EditAccessTemplate from '@/views/admin/user-management/EditAccessTemplate.vue';
import NewAccessTemplate from '@/views/admin/user-management/NewAccessTemplate.vue';
import ApproveAccessRequests from '@/views/admin/user-management/ApproveAccessRequests.vue';
import EditUsers from '@/views/admin/user-management/EditUsers.vue';

import KPIOwners from '@/views/admin/KPIOwners.vue';
import KPIContacts from '@/views/admin/KPIContacts.vue';

import RetailManagers from '@/views/admin/RetailManagers.vue';

import ObservationsTracker from '@/views/admin/ObservationsTracker.vue';
import CountryInsightsTracker from '@/views/admin/CountryInsightsTracker.vue';

import InsightsTemplates from '@/views/admin/InsightsTemplates.vue';
import InsightsResponsibles from '@/views/admin/InsightsResponsibles.vue';
import InsightsReminders from '@/views/admin/InsightsReminders.vue';

import HelpLinks from '@/views/admin/HelpLinks.vue';

import KnownIssues from '@/views/admin/KnownIssues.vue';

export default [
    {
        path: '/admin',
        name: 'admin',
        component: Home,
        children: [
            {
                path: 'kpi-definitions',
                name: 'KPI Definitions',
                component: KPIDefinitions
            },
            {
                path: 'kpi-definitions-feedback',
                name: 'KPI Definitions Feedback',
                component: KPIDefinitionsFeedback
            },
            {
                path: 'kpi-insights-settings',
                name: 'KPI Insights Settings',
                component: KPIInsightsSettings
            },
            {
                path: 'enable-disable-kpis',
                name: 'Enable/Disable KPIs',
                component: EnableDisableKPIs
            },
            {
                path: 'data-load-tracker',
                name: 'Load Tracker',
                component: LoadTracker
            },
            {
                path: 'known-issues',
                name: 'Known Issues',
                component: KnownIssues
            },
            {
                path: 'help-links',
                name: 'Help Links',
                component: HelpLinks
            },
            {
                path: 'which-kpis-have-comments',
                name: 'Which KPIs have comments?',
                component: WhichKPIsHaveComments
            },
            {
                path: 'kpi-pictures',
                name: 'KPI Pictures',
                component: KPIPictures
            },
            {
                path: 'kpi-owners',
                name: 'KPI Owners',
                component: KPIOwners
            },
            {
                path: 'kpi-contacts',
                name: 'KPI Contacts',
                component: KPIContacts
            },
            {
                path: 'retail-managers',
                name: 'Retail Managers',
                component: RetailManagers
            },
            {
                path: 'global-announcements',
                name: 'Global Announcements',
                component: GlobalAnnouncements
            },
            {
                path: 'nps',
                name: 'NPS',
                component: NPS,
                children: [
                    {
                        path: 'forms',
                        component: NPSForms,
                        children: [
                            {
                                path: '',
                                name: 'All Forms',
                                component: NPSFormsAll
                            },
                            {
                                path: 'new',
                                name: 'New Form',
                                component: NPSFormsNew
                            }
                        ]
                    },
                    {
                        path: 'questions',
                        component: NPSQuestions,
                        children: [
                            {
                                path: '',
                                name: 'All Questions',
                                component: NPSQuestionsAll
                            },
                            {
                                path: 'new',
                                name: 'New Question',
                                component: NPSQuestionsNew
                            },
                            {
                                path: 'edit/:id',
                                component: NPSQuestionEdit,
                                props: true
                            }
                        ]
                    }
                ]
            },
            {
                path: 'observations-tracker',
                name: 'Observations Tracker',
                component: ObservationsTracker
            },
            {
                path: 'country-insights-tracker',
                name: 'Insights Tracker',
                component: CountryInsightsTracker
            },
            {
                path: 'insights-templates',
                name: 'Insights Templates',
                component: InsightsTemplates
            },
            {
                path: 'insights-responsibles',
                name: 'Insights Responsibles',
                component: InsightsResponsibles
            },
            {
                path: 'insights-reminders',
                name: 'Insights Reminders',
                component: InsightsReminders
            },
            {
                path: 'metrics',
                name: 'Metrics',
                component: Metrics
            },
            {
                path: 'user-management',
                component: UserManagement,
                children: [
                    {
                        path: '',
                        name: 'Access Templates',
                        component: AccessTemplates
                    },
                    {
                        path: 'access-templates/new',
                        name: 'New Access Template',
                        component: NewAccessTemplate
                    },
                    {
                        path: 'access-templates/edit/:template_id',
                        name: 'Edit Access Template',
                        component: EditAccessTemplate,
                        props: route => {
                            return {
                                template_id: parseInt(route.params.template_id)
                            };
                        }
                    },
                    {
                        path: 'approve',
                        name: 'Approve Access Requests',
                        component: ApproveAccessRequests
                    },
                    {
                        path: 'edit-users',
                        name: 'Edit Users',
                        component: EditUsers
                    }
                ]
            },
            {
                path: 'release-notes',
                name: 'Release Notes',
                component: ReleaseNotes
            },
            {
                path: 'kitchen-sink',
                name: 'Admin Kitchen Sink',
                component: AdminKitchenSink
            }
        ]
    }
];
