<template>
  <div class="modal" :class="{'is-active': back_clicked}">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Unsaved Work</p>
                <button class="delete" aria-label="close" @click="back_clicked = false"></button>
            </header>
            <section class="modal-card-body">
                You have unsaved work, leaving this tab will delete it.
            </section>
            <footer class="modal-card-foot">
                <button class="button is-success" @click="$emit('clear-storage'), $emit('toggle-edit-mode')">Delete Changes</button>
                <button class="button" @click="back_clicked = false">Cancel</button>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        back_clicked: Boolean
    }
}
</script>