var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: {
          title: "Services Gross Margin By Service Graph",
          location: _vm.location,
        },
      }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons() },
          }),
          _c(
            "div",
            { staticClass: "label-container" },
            [
              _c("p", [_vm._v("Actuality")]),
              _c("DropDown", {
                key: _vm.filter,
                attrs: {
                  options: _vm.options,
                  values: _vm.values,
                  default: _vm.filter,
                },
                on: { updateDropDown: _vm.update_filter },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                key: _vm.$store.getters.market,
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: _vm.$store.getters.market,
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size,
          staticClass: "section three-quarters-height box ms mb",
        },
        [
          _vm.quadStack.render
            ? _c("QuadStackWithLine", {
                attrs: {
                  title: "Service Income, Cost and Net",
                  subtitle: "",
                  showtitle: !_vm.$store.state.global.bot_mode,
                  xcategories: _vm.quadStack.xcategories,
                  yaxislabel: "",
                  ymetric: "",
                  type: "bar",
                  color1: _vm.$store.state.colors["yellow"],
                  color2: "#fcba03",
                  color3: "#787878",
                  color4: "#000000",
                  seriesname1: "Income",
                  seriesset1: _vm.quadStack.seriesset1,
                  seriestype1: "bar",
                  seriesname2: "Commercial Cost",
                  seriesset2: _vm.quadStack.seriesset2,
                  seriestype2: "bar",
                  seriesname3: "After sales cost",
                  seriesset3: _vm.quadStack.seriesset3,
                  seriestype3: "bar",
                  seriesname4: "Total Net",
                  seriesset4: _vm.quadStack.seriesset4,
                  seriestype4: "line",
                  gridbottom: -5,
                  xaxisrotation: 35,
                  xaxisfontsize: 10,
                  hidebarlabel: true,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section three-quarters-height box ms mb" },
        [
          _vm.bubble.render
            ? _c("BubbleGraph", {
                attrs: {
                  title: "Service Net and Gm per Service Type",
                  "sub-title": "Size based on Income",
                  "show-title": !_vm.$store.state.global.bot_mode,
                  "y-axis-label": "Gm in %",
                  "y-metric": "%",
                  "x-axis-label": "Service net in Euro",
                  "size-label": "Income",
                  datasets: _vm.bubble.datasets,
                  names: _vm.bubble.names,
                  colors: _vm.bubble.colors,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }