<template>
    <div :id="title" style="width: 100%; height:100%;"></div>
</template>

<script>
import * as echarts from 'echarts/dist/echarts.min.js';
export default {
    props: {
        title: String, //title of the graph
        showtitle: Boolean,
        subtitle: String, //subtitle of the graph
        xcategories: Number, //data that will be displayed on the x axis
        yaxislabel: String, //name of the data on the y axis e.g 'After sales delivery share' or 'services sale by month'
        ymetric: String, //'%', '£', 'kg' etc.
        type: String, //bar

        color1: String, //color of the bar lie or scatter for the paticular data set
        color2: String,
        color3: String,

        seriesname1: String, //name of the data such as IKEA
        seriesset1: Number,
        
        seriesname2: String,
        seriesset2: Number,
        
        seriesname3: String,
        seriesset3: Number,
    
        seriesname4: String,
        seriesset4: Number,

        seriesname5: String,
        seriesset5: Number,

        seriesname6: String,
        seriesset6: Number,

        seriesname7: String,
        seriesset7: Number,

        seriesname8: String,
        seriesset8: Number,

        show: Boolean, // true to show the exta features such as 'save graph as image', 'refresh', 'change to bar graph', etc
    },
    mounted: function() {
        let myChart = echarts.init(document.getElementById(this.title));
        let option = {
            title: {
                text: this.title,
                subtext: this.subtitle,
                show: this.showtitle,
                subtextStyle: {
                    color: 'black',
                },
                top: '-5em',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            grid: {
               top: '80em',
               left: '70vh',
               bottom: '60px',
			},
			legend: {
                top: '45em',
                left: '0em',
                itemGap: 15,
                data:[this.seriesname1, this.seriesname2, this.seriesname3, this.seriesname4, this.seriesname5, this.seriesname6, this.seriesname7, this.seriesname8]
			},
			textStyle: {
                fontFamily: 'NotoIKEALatin-Regular',
            },
            xAxis: [
                {
                    type: 'category',
					data: [''],
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: this.yaxislabel,
                    nameLocation: 'middle',
                    nameGap: 50,
                    axisLabel: {
                        formatter: `{value} ${this.ymetric}`
                    }
                }
            ],
            series: [
                this.call_data(this.seriesname1, this.seriesset1,this.color1),
                this.call_data(this.seriesname2, this.seriesset2,this.color1),
				{
                    type: this.type,
                    data: []
                },
				this.call_data(this.seriesname3, this.seriesset3,this.color2),
				this.call_data(this.seriesname4, this.seriesset4,this.color2),
				this.call_data(this.seriesname5, this.seriesset5,this.color2),
				this.call_data(this.seriesname6, this.seriesset6,this.color2),
				this.call_data(this.seriesname7, this.seriesset7,this.color2),
				{
                    type: this.type, 
                    data: []
                },
				this.call_data(this.seriesname8, this.seriesset8,this.color3)
            ]
        };
        myChart.setOption(option);
    },
    methods: {
        call_data: function(name, set, color) {
            return {
                name: name,
                type: this.type,
                data: [(set ? (set - 100).toPrecision(3) : set)],
                color: color,
                label: {
                    show: true,
                    formatter: `${set}`,
                    color: 'black'
                },
                markPoint: {
                    symbolOffset: [0, '20em'],
                    symbolSize: 0.1,
                    label: {color: 'black'},
                    data: [{value: name, xAxis: 0, yAxis: 0, y: '420px'}]
                }
            }
        }
    }
}
</script>
