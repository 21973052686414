<template>
<div>
    <Title
        title="Lead Times"
        :location="location"
    />

    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container">
            <p>Filter</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms mb">
        <Top8Bar
            :key="top_8.render"

            :title="`${filter} Top 10 Countries`"
            :showtitle="!$store.state.global.bot_mode"
            subtitle=""
            :xcategories="top_8.xcategories"
            :yaxislabel="`${filter} offered Lead-time (days)`"
            ymetric=""

            seriesname1="YTD"
            seriesname3="1WK Trend"
            seriesname4="Year Goal"

            seriestype1="bar"
            seriestype2="scatter"
            seriestype3="scatter"
            :seriestype4="`${filter !== values[0] ? 'scatter' : 'line'}`"

            :seriesset1="top_8.seriesset1"
            :color1="$store.state.colors['yellow']"
            
            :seriesset3="top_8.seriesset2"
            :color3="$store.state.colors['grey']"

            :seriesset4="top_8.seriesset3"
            :color4="$store.state.colors.YG"

            :trend="true"
            :trendInvert="true"
            :ranking="[]"
            :competitors="[]"
        />
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Top8Bar from '@/components/visualisations/bar/Top8Bar.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Title,
        Top8Bar
    },
    props: {
        option: String
    },
    data: function() {
        return {
            top_8: {
                render: false,

                xcategories: [],

                seriesset1: [],                
                seriesset2: [],
                seriesset3: [],
                seriesset4: []
            },
            location: '/business/lead-times',
            options: ['Store fulfilment', 'Central fulfilment', 'Parcel'],
            values: ['Store fulfilment', 'Central fulfilment', 'Parcel'],
            filter: null
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);       
        if (this.option && this.option !== 'null') {
            this.filter = this.option.replace(/-/g, ' ');
        } else {
            this.filter = this.$store.getters.filter_check(this.values);
        }
        this.load_market_data();
    },
    methods: {
        generate_buttons: function() {
            if (!this.filter) return;
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.filter.replace(/ /g, '-')}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: this.filter_url
                },
            ];
        },
        load_market_data: function() {
            if (this.filter) {
                this.$http.post('/business/lead-times/top-8', {filter: this.filter}).then(
                    succ => {
                        let d = succ.data;
                        this.top_8.xcategories = [];
                        this.top_8.seriesset1 = [];
                        this.top_8.seriesset2 = [];
                        this.top_8.seriesset3 = [];
                        d.map(row => {
                            this.top_8.xcategories.push(row.caption);

                            // Short term num solution - TJ
                            this.top_8.seriesset1.push(Math.round(row.measures['YTD'] * 10) / 10);
                            this.top_8.seriesset2.push(Math.round(row.measures['Week'] * 10) / 10);
                            this.top_8.seriesset3.push(Math.round(row.measures['Year Goal'] * 10) / 10);
                        });

                        this.top_8.render = true;
                    },
                    this.$helper.error
                );
            }
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.top_8.render = false;
            this.load_market_data();
        }
    },
    watch: {
        market: function(old_val, new_val) {
            this.triple.render = false;
            this.load_market_data();
        },
        '$route': function(to, from) {
            if (to.params.option === 'null') {
                this.$router.push(this.filter_url);
            }
        },
        filter: function() {
            this.$router.push(this.filter_url);
            this.load_market_data();
        }
    },
    computed: {
        filter_formatted:  function() {
            return this.filter ? this.filter.replace(/ /g, '-').replace(/_/g, '-') : null;
        },
        filter_url: function() {
            return `${this.location}/top-8/${this.filter_formatted}`;
        }
    }
}
</script>

<style>

</style>