var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.icon === "Sales"
      ? _c("div", { staticClass: "icon" }, [
          _c("img", {
            attrs: { src: "assets/images/icons/sales.svg", alt: "Sales" },
          }),
        ])
      : _vm._e(),
    _vm.icon === "Quantity"
      ? _c("div", { staticClass: "icon" }, [
          _c("img", {
            attrs: { src: "assets/images/icons/quantity.svg", alt: "Quantity" },
          }),
        ])
      : _vm._e(),
    _vm.icon === "Customer"
      ? _c("div", { staticClass: "icon" }, [
          _c("img", {
            attrs: { src: "assets/images/icons/customer.svg", alt: "Customer" },
          }),
        ])
      : _vm._e(),
    _vm.icon === "Visitation"
      ? _c("div", { staticClass: "icon" }, [
          _c("img", {
            attrs: { src: "assets/images/icons/visitors.svg", alt: "Visitor" },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }