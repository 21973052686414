<template>
<div class="feedback">
    <div class="custom-title section">
        <div class="title">
            Feedback
        </div>
        <div class="subtitle ps2p5">Spotted a bug, or have an idea for a new feature? Leave us some feedback.
            
        </div>
        <div class="container box">
            <div class="subtitle mbp5 ps2p5">
                <b>Bugs, Feature requests and Questions</b>
            </div>
            <div class="mb">(This feeback will get our immediate attention)</div>
            <div class="button is-link top-right" @click="urgent = !urgent">
                <i v-show="!urgent" class="fas fa-chevron-down"></i>
                <i v-show="urgent" class="fas fa-chevron-up"></i>
            </div>
            <div v-show="urgent">
                <p class="mb">
                    <a href="https://jira.digital.ingka.com/servicedesk/customer/portal/28" target="_blank">SBP Reporting & Analytics Support Page</a>
                </p>

                <p>We use Jira to handle feedback in the most efficient way. Don't have access?</p>
                <a href="https://jira-selfservice.azurewebsites.net/new_jira_user" target="_blank">Create JIRA Account</a>

                <div class="feedback-notice issues" v-if="issues.length > 0">
                    <div class="subtitle"><b>Issues we are aware of and working on</b></div>
                    <div class="-mt">
                        <div v-for="(issue, i) in issues" :key="i">
                            {{(i + 1)}}. {{issue.issue}}
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="container box">
            <div class="subtitle mbp5 ps2p5"><b>Overall Satisfaction</b></div> 
            <div class="ps2p5">(How do you feel about Retail Flight Tower over all ?)</div>
            <div class="button is-link top-right" @click="non_urgent = !non_urgent">
                <i v-show="!non_urgent" class="fas fa-chevron-down"></i>
                <i v-show="non_urgent" class="fas fa-chevron-up"></i>
            </div>
            <div v-show="non_urgent">
                <div class="label">How would you rate your satisfaction with the Flight Tower solution so far?</div>
                <div class="face-section mbp5">
                    <div v-for="(face, i) in faces" :key="i" class="ft-tooltip" :tooltiptext="face.tooltip">
                        <i
                            class="far cursor-pointer"
                            :class="{
                                [face.class]: true
                            }"
                            :style="{
                                color: face.color,
                                display: face.selected === false ? 'none' : 'flex'
                            }"
                            @click="can_cancel ? clear() : update(i)"
                        ></i>
                    </div>
                </div>
                <p class="unselect-text" v-show="can_cancel">Click face again to unselect</p>
                <!-- <div class="label text-left">Subject (Optional)</div>
                <input class="input mb" v-model="subject" placeholder="Subject"> -->
                <div  v-if="rating < 4  && rating > 0" class="label text-left">How could we improve your experience? (Optional)</div>
                <textarea  v-if="rating < 4  && rating > 0" class="textarea mb" rows="7" placeholder="Your feedback" v-model="details"></textarea>

                <a class="button input is-success mt" @click="send_feedback" :disabled="!(can_cancel)">Submit</a>
            </div>
            <div v-show="!non_urgent" class="feedback-notice"><b>This section is not for Bug report, Feature Requests or Questions</b></div>
        </div>
    </div>
</div>
</template>

<script>
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Title
    },
    data: function() {
        return {
            subject: "",
            details: "",
            user_agent: "",
            form_disabled: false,
            send_disabled: false,
            hover: null,
            can_cancel: false,
            urgent: false,
            non_urgent: false,
            issues: [],
            faces: [
                {
                    id: 1,
                    class: 'fa-angry',
                    color: '#ff0000',
                    tooltip: '1 - Very Unsatisfied',
                    selected: null
                },
                {   
                    id: 2,
                    class: 'fa-frown',
                    color: '#ffa500',
                    tooltip: '2 - Unsatisfied',
                    selected: null
                },
                {
                    id: 3,
                    class: 'fa-meh',
                    color: '#ffff00',
                    tooltip: '3 - Neutral',
                    selected: null
                },
                {
                    id: 4,
                    class: 'fa-smile',
                    color: '#90ee90',
                    tooltip: '4 - Satisfied',
                    selected: null
                },
                {
                    id: 5,
                    class: 'fa-laugh',
                    color: '#008000',
                    tooltip: '5 - Very Satisfied',
                    selected: null
                }
            ]
        }
    },
    mounted: function() {
        this.$store.state.commenting.width = 0;
        this.get_issues()
    },
    destroyed: function() {
        this.$store.state.commenting.width = 4;
    },
    methods: {
        get_issues: function() {
            this.$http.get('/known-issues/get').then(
                succ => {
                    if (succ.data) {
                        this.issues = succ.data;
                    }
                },
                this.$helper.error
            );
        },
        send_feedback: function() {
            // if (!this.subject || this.subject.trim() === '') { making the subject and details optional
            //     alert("Please fill in the subject field");
            //     return;
            // }
            // if (!this.details || this.details.trim() === '') {
            //     alert("Please fill in the details field");
            //     return;
            // }
            if (!this.can_cancel) {
                alert("Please select a face most appropriate to the tone of your feedback");
                return;
            }
            
            const face_id = this.faces.filter(face => face.selected).pop().id;

            this.$http.post('/feedback/send', {
                subject : this.subject,
                details : this.details,
                user_agent : navigator.userAgent,
                happiness_rating: face_id
            }).then(
                succ => {
                    this.subject = this.details = this.user_agent = '';
                    alert("Thank you! You have Successfully submitted your Feedback. We will send you a copy email of the one we recieve and we will check it out as soon as we can. ");
                    this.clear();
                },
                error => {
                    console.log(error);
                    alert("An unexpected error has occured, please try again.");
                }
            );
            
        },
        update: function(i) {
            this.faces.forEach( face => {
                if (face.class === this.faces[i].class) {
                    face.selected = true;
                } else {
                    face.selected = false;
                }
            });
            this.can_cancel = true;
        },
        clear: function() {
            this.faces.forEach( face => {
                face.selected = null
            });
            this.can_cancel = false;
        }
    },
    computed: {
        rating: function() {
            return this.faces.findIndex(face => face.selected) + 1
        }
    }
}
</script>

<style scoped>
.column {
    padding: 0 !important;
}

.face-section {
    height: 3rem;
    display: flex;
    justify-content: center;
}

.face-section i {
    font-size: 2.5rem;
    margin: 0.5rem !important;
}

.face-section i:hover {
    font-size: 3rem;
    margin: 0.25rem !important;
}

.feedback {
    background-color: #F7F7F7;
    min-height: calc(100vh - 3.5rem);
}

.feedback-notice {
    margin: 0rem -1.3rem -1.3rem -1.3rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    height: fit-content;
    padding: 0.5rem;
}

.issues {
    background-color: rgb(241 241 241);
    margin-top: 0.5rem !important;
}

.ps2p5 {
    padding: 0rem 2.5rem;
}
.top-right {
    top: 1rem;
    position: absolute;
    right: 1rem;
}
.unselect-text {
    font-size: 0.8rem;
    color: #808080;
}
</style>