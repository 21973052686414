<template>
<div>
    <Title
        title="HFB"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
        
        <div class="label-container">
            <p>HFB</p>
            <DropDown
                v-if="hfbs.all.length > 0"
                :key="hfbs.all.length"
                :options="hfbs.options"
                :values="hfbs.values"
                :default="hfbs.values[0]"
                @updateDropDown="update_hfb"
            />
        </div>

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>        
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms">
        <Top8BarDoubleYAxis
            :key="top_8.render"

            title="HFB Per Country Top 10 Countries"
            :showtitle="!$store.state.global.bot_mode"

            subtitle=""
            :xcategories="top_8.xcategories"
            yaxislabel1="Sales TY/LY"
            ymetric1=""
            yaxislabel2= "Growth (TEUR)"
            ymetric2=""

            seriesname1="Growth YTD (TEUR)"
            seriesname2="YTD Index "
            seriesname3="4WK Index"
            seriesname4="Year Goal"

            seriestype1="line"
            seriestype2="bar"
            seriestype3="scatter"
            seriestype4="scatter"

            :seriesset1="top_8.seriesset1"
            :color2="$store.state.colors['yellow']"
            
            :seriesset2="top_8.seriesset2"
            :color3="$store.state.colors['grey']"

            :seriesset3="top_8.seriesset3"
            color1="#787878"

            :seriesset4="top_8.seriesset4"
            :color4="$store.state.colors.YG"
            
            :trend="true"
            :ranking="[]"
            :competitors="[]"
        />
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Top8BarDoubleYAxis from '@/components/visualisations/bar/Top8DoubleYAxis.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Title,
        Top8BarDoubleYAxis
    },
    props: {
        market: String
    },
    data: function() {
        return {
            top_8: {
                render: false,

                xcategories: [],

                seriesset1: [],                
                seriesset2: [],
                seriesset3: [],
                seriesset4: []
            },
            hfb: 'Total HFB',
            location: '/business/sales-per-range/hfb'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.load_market_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By HFB',
                    link: `${this.location}/by-hfb/${this.$store.getters.market}`
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Channel Contribution',
                    link: `${this.location}/channel-contribution/${this.$store.getters.market}`
                },
            ];
        },
        load_market_data: function() {
            this.$http.post('business/sales-per-range/sales-per-hfb/top-8', {
                hfb: this.hfb
            }).then(
                succ => {
                    let d = succ.data;
                    this.top_8.xcategories = [];
                    this.top_8.seriesset1 = [];
                    this.top_8.seriesset2 = [];
                    this.top_8.seriesset3 = [];
                    this.top_8.seriesset4 = [];
                    d.map(row => {
                        this.top_8.xcategories.push(row.caption);
                        this.top_8.seriesset1.push(this.$helper.maybe(row.data['Growth YTD TEUR']));
                        this.top_8.seriesset2.push(this.$helper.maybe(row.data['Index YTD']));
                        this.top_8.seriesset3.push(this.$helper.maybe(row.data['R4 Index']));
                        this.top_8.seriesset4.push(this.$helper.maybe(row.data['Year Goal']));
                    });

                    this.top_8.render = true;
                },
                this.$helper.error
            );
        },
        update_hfb: function(hfb) {
            this.hfb = hfb;
            this.top_8.render = false;
            this.load_market_data();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        }
    },
    computed: {
        hfbs: function() {
            return this.$store.getters.hfbs;
        }
    }
}
</script>

<style>

</style>