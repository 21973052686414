var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.errored
      ? _c("div", {
          staticClass: "section",
          style: { width: _vm.dimensions.width, height: _vm.dimensions.height },
          attrs: { id: "embedContainer" },
        })
      : _c("div", { staticClass: "section box" }, [
          _c("h2", [
            _vm._v("There has been an error fetching the power-bi report"),
          ]),
        ]),
    _vm.refreshpage_container
      ? _c("div", { staticClass: "refresh-btn-contatiner" }, [
          _c("p", [
            _vm._v(
              "The session has expired. Please click the button below to refresh the data."
            ),
          ]),
          _c("button", { on: { click: _vm.reloadPage } }, [
            _vm._v(" Refresh Data"),
          ]),
        ])
      : _vm._e(),
    _vm.reportRendered
      ? _c("div", { attrs: { id: "report-rendered" } })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }