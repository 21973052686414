var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "kitchensink" } },
    [
      _vm._v(" test "),
      _c("Title", {
        attrs: {
          title: "Cheat Sheet",
          subtitle:
            "A compilation of all of the usable graphs for FT2, for any more complex additions to the graphs ask developers",
        },
      }),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c("Single", {
            attrs: {
              title: "Single Bar Chart",
              subtitle: "This is a single chart",
              xcategories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              yaxislabel: "Y Label",
              ymetric: "%",
              seriesname1: "Series 1",
              seriestype1: "bar",
              seriesset1: [-10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80],
              color1: "#169100",
              show: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c("Single", {
            attrs: {
              title: "Single Line Chart",
              subtitle: "This is a single chart",
              xcategories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              yaxislabel: "Y Label",
              ymetric: "%",
              seriesname1: "Series 1",
              seriestype1: "line",
              seriesset1: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80],
              color1: "#169100",
              show: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c("Single", {
            attrs: {
              title: "Single Scatter Graph",
              subtitle: "This is a single chart",
              xcategories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              yaxislabel: "Y Label",
              ymetric: "%",
              seriesname1: "Series 1",
              seriestype1: "scatter",
              seriesset1: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80],
              color1: "#169100",
              show: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c("Double", {
            attrs: {
              title: "Double Bar Graph",
              subtitle: "This is a double chart",
              xcategories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              yaxislabel: "Y Label",
              ymetric: "%",
              seriesname1: "Series 1",
              seriestype1: "bar",
              seriesset1: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80],
              color1: "#169100",
              seriesname2: "Series 2",
              seriestype2: "bar",
              seriesset2: [80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50],
              color2: "#787878",
              show: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c("Double", {
            attrs: {
              title: "Double Bar and Line Graph",
              subtitle: "This is a double chart",
              xcategories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              yaxislabel: "Y Label",
              ymetric: "%",
              seriesname1: "Series 1",
              seriestype1: "bar",
              seriesset1: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80],
              color1: "#169100",
              seriesname2: "Series 2",
              seriestype2: "line",
              seriesset2: [80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50],
              color2: "#787878",
              show: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c("Double", {
            attrs: {
              title: "Double Bar and Scatter Graph",
              subtitle: "This is a double chart",
              xcategories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              yaxislabel: "Y Label",
              ymetric: "%",
              seriesname1: "Series 1",
              seriestype1: "bar",
              seriesset1: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80],
              color1: "#169100",
              seriesname2: "Series 2",
              seriestype2: "scatter",
              seriesset2: [80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50],
              color2: "#787878",
              show: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c("Double", {
            attrs: {
              title: "Double Line Graph",
              subtitle: "This is a double chart",
              xcategories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              yaxislabel: "Y Label",
              ymetric: "%",
              seriesname1: "Series 1",
              seriestype1: "line",
              seriesset1: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80],
              color1: "#169100",
              seriesname2: "Series 2",
              seriestype2: "line",
              seriesset2: [80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50],
              color2: "#787878",
              show: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c("Triple", {
            attrs: {
              title: "Triple Bar Graph",
              subtitle: "This is a triple chart",
              xcategories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              yaxislabel: "Y Label",
              ymetric: "%",
              seriesname1: "Series 1",
              seriestype1: "bar",
              seriesset1: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80],
              color1: "#169100",
              seriesname2: "Series 2",
              seriestype2: "bar",
              seriesset2: [80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50],
              color2: "#787878",
              seriesname3: "Series 3",
              seriestype3: "bar",
              seriesset3: [0, 5, 20, 50, 80, 60, 40, 30, 20, 10, 20, 55],
              color3: "#fcfc05",
              show: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c("Triple", {
            attrs: {
              title: "Double Bar with Line Graph",
              subtitle: "This is a triple chart",
              xcategories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              yaxislabel: "Y Label",
              ymetric: "%",
              seriesname1: "Series 1",
              seriestype1: "bar",
              seriesset1: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80],
              color1: "#169100",
              seriesname2: "Series 2",
              seriestype2: "bar",
              seriesset2: [80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50],
              color2: "#787878",
              seriesname3: "Series 3",
              seriestype3: "line",
              seriesset3: [0, 5, 20, 50, 80, 60, 40, 30, 20, 10, 20, 55],
              color3: "#fcfc05",
              show: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c("Triple", {
            attrs: {
              title: "Bar with Line and Scatter Graph",
              subtitle: "This is a triple chart",
              xcategories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              yaxislabel: "Y Label",
              ymetric: "%",
              seriesname1: "Series 1",
              seriestype1: "bar",
              seriesset1: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80],
              color1: "#169100",
              seriesname2: "Series 2",
              seriestype2: "line",
              seriesset2: [80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50],
              color2: "#787878",
              seriesname3: "Series 3",
              seriestype3: "scatter",
              seriesset3: [0, 5, 20, 50, 80, 60, 40, 30, 20, 10, 20, 55],
              color3: "#fcfc05",
              show: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c("Triple", {
            attrs: {
              title: "Triple Line Graph",
              subtitle: "This is a triple chart",
              xcategories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              yaxislabel: "Y Label",
              ymetric: "%",
              seriesname1: "Series 1",
              seriestype1: "line",
              seriesset1: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80],
              color1: "#169100",
              seriesname2: "Series 2",
              seriestype2: "line",
              seriesset2: [80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50],
              color2: "#787878",
              seriesname3: "Series 3",
              seriestype3: "line",
              seriesset3: [0, 5, -20, 50, 80, 60, 40, 30, 20, 10, 20, 55],
              color3: "#fcfc05",
              show: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c("Quad", {
            attrs: {
              title: "Quad Bar Graph",
              subtitle: "This is a quad chart",
              xcategories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              yaxislabel: "Y Label",
              ymetric: "%",
              seriesname1: "Series 1",
              seriestype1: "bar",
              seriesset1: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80],
              color1: "#169100",
              seriesname2: "Series 2",
              seriestype2: "bar",
              seriesset2: [80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50],
              color2: "#787878",
              seriesname3: "Series 3",
              seriestype3: "bar",
              seriesset3: [0, 5, 20, 50, 80, 60, 40, 30, 20, 10, 20, 55],
              color3: "#fcfc05",
              seriesname4: "Series 4",
              seriestype4: "bar",
              seriesset4: [60, 30, 40, 60, 70, 30, 20, 10, 50, 70, 100, 90],
              color4: "blue",
              show: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c("Quad", {
            attrs: {
              title: "Triple Bar with Line",
              subtitle: "This is a quad chart",
              xcategories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              yaxislabel: "Y Label",
              ymetric: "%",
              seriesname1: "Series 1",
              seriestype1: "bar",
              seriesset1: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80],
              color1: "#169100",
              seriesname2: "Series 2",
              seriestype2: "bar",
              seriesset2: [80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50],
              color2: "#787878",
              seriesname3: "Series 3",
              seriestype3: "bar",
              seriesset3: [0, 5, 20, 50, 80, 60, 40, 30, 20, 10, 20, 55],
              color3: "#fcfc05",
              seriesname4: "Series 4",
              seriestype4: "line",
              seriesset4: [60, 30, 40, 60, 70, 30, 20, 10, 50, 70, 100, 90],
              color4: "blue",
              show: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c("Quad", {
            attrs: {
              title: "Double Bar with Double Line",
              subtitle: "This is a quad chart",
              xcategories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              yaxislabel: "Y Label",
              ymetric: "%",
              seriesname1: "Series 1",
              seriestype1: "bar",
              seriesset1: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80],
              color1: "#169100",
              seriesname2: "Series 2",
              seriestype2: "bar",
              seriesset2: [80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50],
              color2: "#787878",
              seriesname3: "Series 3",
              seriestype3: "line",
              seriesset3: [0, 5, 20, 50, 80, 60, 40, 30, 20, 10, 20, 55],
              color3: "#fcfc05",
              seriesname4: "Series 4",
              seriestype4: "line",
              seriesset4: [60, 30, 40, 60, 70, 30, 20, 10, 50, 70, 100, 90],
              color4: "blue",
              show: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c("Quad", {
            attrs: {
              title: "Double Bar with Line and Scatter",
              subtitle: "This is a quad chart",
              xcategories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              yaxislabel: "Y Label",
              ymetric: "%",
              seriesname1: "Series 1",
              seriestype1: "bar",
              seriesset1: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80],
              color1: "#169100",
              seriesname2: "Series 2",
              seriestype2: "bar",
              seriesset2: [80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50],
              color2: "#787878",
              seriesname3: "Series 3",
              seriestype3: "line",
              seriesset3: [0, 5, 20, 50, 80, 60, 40, 30, 20, 10, 20, 55],
              color3: "#fcfc05",
              seriesname4: "Series 4",
              seriestype4: "scatter",
              seriesset4: [60, 30, 40, 60, 70, 30, 20, 10, 50, 70, 100, 90],
              color4: "blue",
              show: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c("QuadStack", {
            attrs: {
              title: "Triple Bar Stack with Line",
              subtitle: "This is a quad stack chart",
              xcategories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              yaxislabel: "Y Label",
              ymetric: "%",
              seriesname1: "Series 1",
              seriestype1: "bar",
              seriesset1: [10, 20, 30, 15, 20, 10, 5, 2, 17, 38, 30, 10],
              color1: "#169100",
              seriesname2: "Series 2",
              seriestype2: "bar",
              seriesset2: [2, 16, 35, 7, 24, 16, 23, 7, 21, 4, 40, 22],
              color2: "#787878",
              seriesname3: "Series 3",
              seriestype3: "bar",
              seriesset3: [0, 5, 20, 30, 40, 21, 12, 15, 3, 6, 27, 25],
              color3: "#fcfc05",
              seriesname4: "Series 4",
              seriestype4: "line",
              seriesset4: [60, 30, 40, 60, 70, 30, 20, 10, 50, 70, 100, 90],
              color4: "blue",
              show: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c("AreaStack", {
            attrs: {
              title: "Area Stack Graph",
              subtitle: "This is a area stack chart",
              xcategories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              yaxislabel: "Y Label",
              ymetric: "%",
              seriesname1: "Series 1",
              seriesset1: [10, 20, 30, 15, 20, 10, 5, 2, 17, 38, 30, 10],
              color1: "#169100",
              seriesname2: "Series 2",
              seriesset2: [2, 16, 35, 7, 24, 16, 23, 7, 21, 4, 40, 22],
              color2: "#787878",
              seriesname3: "Series 3",
              seriesset3: [0, 5, 20, 30, 40, 21, 12, 15, 3, 6, 27, 25],
              color3: "#fcfc05",
              seriesname4: "Series 4",
              seriesset4: [10, 20, 6, 9, 12, 14, 3, 13, 5, 2, 7, 9],
              color4: "purple",
              seriesname5: "Series 5",
              seriesset5: [1, 3, 5, 7, 9, 2, 4, 6, 8, 10, 12, 13],
              color5: "#ffc105",
              seriesname6: "Series 6",
              seriesset6: [9, 7, 3, 5, 9, 5, 12, 14, 6, 3, 7, 9],
              color6: "aqua",
              show: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c("HorizontalSingleBar", {
            attrs: {
              title: _vm.title,
              subtitle: _vm.subtitle,
              ycategories: _vm.ycategories,
              xaxislabel: _vm.xaxislabel,
              xmetric: _vm.xmetric,
              seriesname1: _vm.seriesname1,
              seriestype1: _vm.seriestype1,
              seriesset1: _vm.seriesset4,
              color1: _vm.color1,
              show: _vm.show,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c("PieChart", {
            attrs: {
              title: "Pie Chart",
              subtitle: "This is a Pie Chart",
              names: [
                "IKEA",
                "Harvey Norman / Domaine",
                "Freedom",
                "Bunnings Warehouse",
                "Kmart",
                "Ebay",
              ],
              values: [39, 23, 7, 69, 63, 43],
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section long" },
        [
          _c("DeviationWith2Lines", {
            attrs: {
              title: "Deviation Graph",
              subtitle: "This is a deviation graph with 2 lines",
              xcategories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              yaxislabel1: "Y Label",
              ymetric1: "%",
              yaxislabel2: "Deviation",
              ymetric2: "%",
              yaxismin1: -100,
              yaxismax1: 100,
              yaxismin2: -200,
              yaxismax2: 200,
              seriesname1: "Deviation",
              seriestype1: "bar",
              seriesset1: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80],
              color1: "#169100",
              seriesname2: "Series 2",
              seriestype2: "line",
              seriesset2: [80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50],
              color2: "#787878",
              seriesname3: "Series 3",
              seriestype3: "line",
              seriesset3: [0, 5, -20, 50, 80, 60, 40, 30, 20, 10, 20, 55],
              color3: "#fcfc05",
              show: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section long" },
        [
          _c("DeviationWith3Lines", {
            attrs: {
              title: "Deviation Graph with 3 Lines",
              subtitle: "This is a deviation graph with 2 lines",
              xcategories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              yaxislabel1: "Y Label",
              ymetric1: "%",
              yaxislabel2: "Deviation",
              ymetric2: "%",
              yaxismin1: "-100",
              yaxismax1: "100",
              yaxismin2: "-200",
              yaxismax2: "200",
              seriesname1: "Deviation",
              seriestype1: "bar",
              seriesset1: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80],
              color1: "#169100",
              seriesname2: "Series 2",
              seriestype2: "line",
              seriesset2: [80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50],
              color2: "#787878",
              seriesname3: "Series 3",
              seriestype3: "line",
              seriesset3: [0, 5, -20, 50, 80, 60, 40, 30, 20, 10, 20, 55],
              color3: "#fcfc05",
              seriesname4: "Series 4",
              seriestype4: "line",
              seriesset4: [60, 30, 40, 60, 70, 30, 20, 10, 50, 70, 100, 90],
              color4: "blue",
              show: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section long" },
        [
          _c("Quad", {
            attrs: {
              title: "Double Bar with Line and Scatter (long)",
              subtitle: "This is a quad chart",
              xcategories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              yaxislabel: "Y Label",
              ymetric: "%",
              seriesname1: "Series 1",
              seriestype1: "bar",
              seriesset1: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80],
              color1: "#169100",
              seriesname2: "Series 2",
              seriestype2: "bar",
              seriesset2: [80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50],
              color2: "#787878",
              seriesname3: "Series 3",
              seriestype3: "line",
              seriesset3: [0, 5, 20, 50, 80, 60, 40, 30, 20, 10, 20, 55],
              color3: "#fcfc05",
              seriesname4: "Series 4",
              seriestype4: "scatter",
              seriesset4: [60, 30, 40, 60, 70, 30, 20, 10, 50, 70, 100, 90],
              color4: "blue",
              show: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c("Quad", {
            attrs: {
              title: "Double Bar with Line and Scatter (comparison)",
              subtitle: "This is a quad chart",
              xcategories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              yaxislabel: "Y Label",
              ymetric: "%",
              seriesname1: "Series 1",
              seriestype1: "bar",
              seriesset1: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80],
              color1: "#169100",
              seriesname2: "Series 2",
              seriestype2: "bar",
              seriesset2: [80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50],
              color2: "#787878",
              seriesname3: "Series 3",
              seriestype3: "line",
              seriesset3: [0, 5, 20, 50, 80, 60, 40, 30, 20, 10, 20, 55],
              color3: "#fcfc05",
              seriesname4: "Series 4",
              seriestype4: "scatter",
              seriesset4: [60, 30, 40, 60, 70, 30, 20, 10, 50, 70, 100, 90],
              color4: "blue",
              show: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section long" },
        [
          _c("AreaStack", {
            attrs: {
              title: "Area Stack Graph (long)",
              subtitle: "This is a area stack chart",
              xcategories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              yaxislabel: "Y Label",
              ymetric: "%",
              seriesname1: "Series 1",
              seriesset1: [10, 20, 30, 15, 20, 10, 5, 2, 17, 38, 30, 10],
              color1: "#169100",
              seriesname2: "Series 2",
              seriesset2: [2, 16, 35, 7, 24, 16, 23, 7, 21, 4, 40, 22],
              color2: "#787878",
              seriesname3: "Series 3",
              seriesset3: [0, 5, 20, 30, 40, 21, 12, 15, 3, 6, 27, 25],
              color3: "#fcfc05",
              seriesname4: "Series 4",
              seriesset4: [10, 20, 6, 9, 12, 14, 3, 13, 5, 2, 7, 9],
              color4: "purple",
              seriesname5: "Series 5",
              seriesset5: [1, 3, 5, 7, 9, 2, 4, 6, 8, 10, 12, 13],
              color5: "#ffc105",
              seriesname6: "Series 6",
              seriesset6: [9, 7, 3, 5, 9, 5, 12, 14, 6, 3, 7, 9],
              color6: "aqua",
              show: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section" },
        [
          _c("AreaStack", {
            attrs: {
              title: "Area Stack Graph (comparison)",
              subtitle: "This is a area stack chart",
              xcategories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              yaxislabel: "Y Label",
              ymetric: "%",
              seriesname1: "Series 1",
              seriesset1: [10, 20, 30, 15, 20, 10, 5, 2, 17, 38, 30, 10],
              color1: "#169100",
              seriesname2: "Series 2",
              seriesset2: [2, 16, 35, 7, 24, 16, 23, 7, 21, 4, 40, 22],
              color2: "#787878",
              seriesname3: "Series 3",
              seriesset3: [0, 5, 20, 30, 40, 21, 12, 15, 3, 6, 27, 25],
              color3: "#fcfc05",
              seriesname4: "Series 4",
              seriesset4: [10, 20, 6, 9, 12, 14, 3, 13, 5, 2, 7, 9],
              color4: "purple",
              seriesname5: "Series 5",
              seriesset5: [1, 3, 5, 7, 9, 2, 4, 6, 8, 10, 12, 13],
              color5: "#ffc105",
              seriesname6: "Series 6",
              seriesset6: [9, 7, 3, 5, 9, 5, 12, 14, 6, 3, 7, 9],
              color6: "aqua",
              show: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section high" },
        [
          _c("Quad", {
            attrs: {
              title: "Double Bar with Line and Scatter (test)",
              subtitle: "This is a quad chart",
              xcategories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              yaxislabel: "Y Label",
              ymetric: "%",
              seriesname1: "Series 1",
              seriestype1: "bar",
              seriesset1: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80],
              color1: "#169100",
              seriesname2: "Series 2",
              seriestype2: "bar",
              seriesset2: [80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50],
              color2: "#787878",
              seriesname3: "Series 3",
              seriestype3: "line",
              seriesset3: [0, 5, 20, 50, 80, 60, 40, 30, 20, 10, 20, 55],
              color3: "#fcfc05",
              seriesname4: "Series 4",
              seriestype4: "scatter",
              seriesset4: [60, 30, 40, 60, 70, 30, 20, 10, 50, 70, 100, 90],
              color4: "blue",
              show: true,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section box ms" },
        [
          _c("Top8Bar", {
            attrs: {
              title: "Top 10 Countries Penetration",
              subtitle:
                "Showing penetration data for the Top 10 countries including IKEA and the other top 2 competitors",
              xcategories: [
                "Sweden",
                "Italy",
                "China",
                "Germany",
                "France",
                "Canada",
                "UK",
                "US",
              ],
              yaxislabel: "Penetration",
              ymetric: "%",
              seriesname1: "IKEA",
              seriestype1: "bar",
              seriesset1: [74, 62, 56, 68, 46, 40, 39, 25],
              color1: "#169100",
              seriesname2: "Company 2",
              seriestype2: "bar",
              seriesset2: [43, 67, 44, 68, 50, 63, 70, 75],
              color2: "lightgreen",
              seriesname3: "Company 3",
              seriestype3: "bar",
              seriesset3: [29, 36, 30, 23, 34, 61, 48, 68],
              color3: "#787878",
              seriesname4: "Goal",
              seriestype4: "scatter",
              seriesset4: [78, 63, , 56, 48, 41, 39, 22],
              color4: "#ffc105",
              show: true,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }