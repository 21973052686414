<template>
<div id="kitchensink">
    <Title
        title="Brand Desire"
    />

    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="is-pulled-left"  
        />
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>

    <div class="section box">
        <iframe
            title="FlightTowerTest"
            :src="`https://app.powerbi.com/reportEmbed?reportId=937e5e82-9f73-4051-a5ec-544505e155e7&appId=e663d921-84f7-4b88-a3f0-e128bbf3fc6b&autoAuth=true&ctid=720b637a-655a-40cf-816a-f22f40755c2c`"
            frameborder="0"
            allowFullScreen="true">
        </iframe>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
export default {
    components: {
        Buttons,
        DropDown,
        Title,
    },
    data: function() {
        return{
            location: '/testing/power-bi/brand-desire'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
    },
    destroyed: function() {
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                }
            ];
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        },
    }
}
</script>

<style scoped>
iframe {
    height: 100%;
    width: 100%;
}
.section{
    float: left;
    margin-left: 1em;
    width: calc(33% - 1em);
    height: 25em;
    border: 1px solid green;
    margin-top: 1em;
}
.box{
    padding: 0;
    width: 90%;
    height: 70vh;
}
</style>