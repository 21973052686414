<template>
<div>
<Modal
    title="Retail Flight Tower"
    :closeable="false"
    :class="{'is-active': is_active}"
    
    id="access-modal"
>
    <template v-slot:content>
        <p class="text-large text-bold">Welcome to Retail Flight Tower, let's figure out your level of access</p>
        
        <div class="text-left content">    
            <p>Choose one of the following access types</p>

            <table class="table is-striped is-hoverable is-fullwidth">
                <thead>
                    <tr>
                        <th>Selection</th>
                        <th>Access Type</th>
                        <th>Description</th>
                        <th>Manager Approval Required?</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, index) of roles_table" :key="index" @click="selected_role = row.access_type" class="cursor-pointer">
                        <td><input class="radio" type="radio" name="job-title" :value="row.access_type" v-model="selected_role" /></td>
                        <td>{{ row.access_type }}</td>
                        <td>{{ row.description }}</td>
                        <td>{{ row.manager_approval ? 'Yes, BASIC access will be granted while awaiting approval.' : 'No.'}}</td>
                    </tr>
                </tbody>
            </table>

            <p>Which access is right for me?</p>
            <table class="table is-striped is-hoverable is-fullwidth">
                <thead>
                    <tr>
                        <th>BASIC</th>
                        <th>ADVANCED</th>
                        <th>CONTRIBUTOR</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(desc, index) in access_descriptions" :key="index">
                        <td>{{desc.basic}}</td>
                        <td>{{desc.advanced}}</td>
                        <td>{{desc.contributor}}</td>
                    </tr>
                </tbody>
            </table>
            
        </div>
        <hr />
        <div class="text-left text-small">
            <b class="text-medium text-center">Collection of personal information</b>
            <p>When you use Retail Flight Tower we will collect your personal data related to the usage of Retail Flight Tower.</p>
            <p>The personal information collected by Retail Flight Tower will be treated in accordance with the Group Rule on Data Privacy.</p>
            <p>We will only retain personal information within Retail Flight Tower for as long as is needed and in line with the retention schedule of the platforms.</p>
            <p>The law on data protection gives you certain rights over your personal information. If you would like more information on your rights and how to exercise them please visit the Individual Rights internal site (Inside - Co-worker individual rights)</p>
        </div>
    </template>
    <template v-slot:buttons>
        <div class="buttons">
            <button class="button ikea-blue text-white" :disabled="is_confirmed" @click="confirm">Confirm</button>
        </div>
    </template>
</Modal>
</div>
</template>

<script>
import DropDown from '@/components/ui/DropDown.vue';
import Modal from '@/components/ui/Modal.vue';

export default {
    components: {
        DropDown,
        Modal
    },
    mounted: function() {
        this.update_drop_down(this.roles.options[0]);
        this.check_user_access();
        this.check_query_params();
    },
    data: function() {
        return {
            access_descriptions: [
                {
                    basic: 'Unit or market department manager',
                    advanced: 'Unit or Market management team',
                    contributor: 'Country BN'
                },
                {
                    basic: 'SO leader',
                    advanced: 'Country Management team',
                    contributor: 'Business Navigation Group'
                },
                {
                    basic: 'SO specialist',
                    advanced: 'SO Manager',
                    contributor: 'KPI Responsible'
                },
                {
                    basic: 'Unit or Market Specialist',
                    advanced: 'Group Function Specialist',
                    contributor: 'KPI Contributors'
                },
                {
                    basic: 'Unit or Market department leader',
                    advanced: 'Group Function Manager',
                    contributor: ''
                },
                {
                    basic: '',
                    advanced: 'Group Function Leader',
                    contributor: ''
                }
            ],
            access_definition: null,
            access_definitions: {
                'Advanced': 'READ access only, but all content available.',
                'Basic': 'READ Access only with limited content. Financial, executive summary and call for action are excluded.',
                'Contributor': 'All content available to READ with additional WRITE access to edit Country Insights.',
                'No Access': 'No READ or WRITE access to the system.'
            },
            is_confirmed: false,
            extra_access: {
                show: false,
                description: null,
                observations: false,
                country_insights: false,
                executive_summary: false
            },
            role_definition: null,
            role_definitions: {
                'Unit or Market Management Team': 'Any Unit/Market managerial role that is also part of the management team. Managers that are not part of the management team should not select this group.',
                'Unit or Market Manager': 'Any Unit/Market managerial role that is not part of the management team.',
                'Unit or Market Leader': 'Any Unit/Market department leader as well as responsible role.',
                'Unit or Market Specialist': 'Any Unit/Market specialist role.',
                'Country Management Team': 'Any country managerial role that is also part of the management team. Managers that are not part of the management team should not select this group.',
                'SO Manager': 'Any SO manager that is not part of the country management team.',
                'SO Leader': 'Any SO leader as well as responsible role.',
                'SO Specialist': 'Any SO specialist role.',
                'Group Function Manager': 'Any group function manager.',
                'Group Function Leader': 'Any group function leader and responsible role.',
                'Group Function Specialist': 'Any group function specialist role.',
            },
            roles: {
                options: [
                    'Unit or Market Management Team',
                    'Unit or Market Manager',
                    'Unit or Market Leader',
                    'Unit or Market Specialist',
                    'Country Management Team',
                    'SO Manager',
                    'SO Leader',
                    'SO Specialist',
                    'Group Function Manager',
                    'Group Function Leader',
                    'Group Function Specialist',
                ],
                values: [
                    'Advanced',
                    'Basic',
                    'No Access',
                    'No Access',
                    'Advanced',
                    'Advanced',
                    'Basic',
                    'Basic',
                    'Advanced',
                    'Advanced',
                    'Advanced',
                ]
            },
            roles_table: [
                {
                    access_type: 'BASIC',
                    description: 'Limited access, excluding financial details and executive summary.',
                    manager_approval: false
                },
                {
                    access_type: 'ADVANCED',
                    description: 'Access to all content, including Finance, executive summary.',
                    manager_approval: true
                },
                {
                    access_type: 'CONTRIBUTOR',
                    description: 'Access to all content, including Finance, executive summary, and commenting possibilities for observations and country insights.',
                    manager_approval: true
                }
            ],
            selected_role: null,
        }
    },
    methods: {
        check_query_params: function() {
            if (this.$route.query.bot) {
                this.$store.state.global.bot_mode = true;
            }

            if (this.$route.query.presentation_mode == 1) {
                this.$store.commit('set_presentation_mode', 1);
            } else if (this.$route.query.presentation_mode == 2) {
                this.$store.commit('set_presentation_mode', 2);
            }
        },
        check_user_access: function() {
            this.$http.get('force','/user/me').then(
                succ => {
                    let a_id = succ.data.access_template_id;
                    if (a_id === undefined || a_id === null || !('template' in succ.data)) {
                        this.$store.commit('set_access_request_show', true);
                    } else {
                        this.$store.commit('set_access_request_show', false);
                        this.$store.commit('set_access_template_name', succ.data.name);
                        this.$store.state.access.access_template = succ.data.template;
                        this.$store.state.global.is_ift_user = succ.data.has_ift_admin !== null;

                        let developer_users = [
                            '0aeaa7d6-27fa-45ea-bfbf-06d1f7e85db1', // Alex Stefanou
                            'fb1d9c91-2fb6-4061-bfb8-8d7667143d90', // Benjamin Chambers
                            '68ac4898-f8e1-41a6-9e8b-33ea38e135f7', // Donatas Gedgaudas
                            '9f550e9e-69a4-402d-b5dc-d11a37d9c06e', // Fredrik Hoffman
                            '7c9627b5-5f18-4869-a5aa-accad331a6b7', // Jacob Dickinson
                            '155f4b96-383b-40e6-8822-9e27cf9d407c', // Linus Probert,
                            'bdbdf344-bff6-422c-94c0-3345cffd67d4', // Martin Jurlander
                            '98cae376-bbcf-44df-bf0e-8c04ca91d09f', // Melvyn Mathews
                        ];

                        const user = succ.data;
                        this.$store.commit('set_super_user_permissions', user.super_user);
                        const is_developer = developer_users.includes(user.user_id);

                        // Google Analytics
                        this.$gtag.set({
                            'dimension1': user.name,
                            'dimension2': is_developer,
                            'dimension3': user.job_title,
                            'dimension4': user.office_location,
                            'dimension5': user.email
                        });
                    }
                },
                error => console.log(error)
            );
        },
        confirm: function() {
            this.is_confirmed = true;
            this.$http.post('/admin/user-management/access-requests/new', {
                job_title: this.selected_role,
                extra_access: this.extra_access
            }).then(
                succ => {
                    if (this.selected_role === 'BASIC') {
                        alert("You have now been granted BASIC access.");
                    } else {    
                        alert(`An email has been sent to your manager to approve your ${this.selected_role} access. Until the approval is complete, you have now been granted BASIC access.`);
                    }
                    location.reload();
                },
                error => {
                    this.is_confirmed = false;
                    console.log(error);
                }
            );
        },
        update_drop_down: function(update) {
            let index = this.roles.options.findIndex(option => option === update);
            let access_type = this.roles.values[index];

            this.selected_role = update;
            
            this.access_definition = this.access_definitions[access_type];
            this.role_definition = this.role_definitions[update];
        }
    },
    computed: {
        is_active: function() {
            return this.$store.getters.access_request_show;
        }
    }
}
</script>

<style lang="scss" scoped>
#access-modal {
    table.table {
        input[type=radio] {
            height: 1rem;
            width: 1rem;
        }
        
        thead tr th {
            color: #373737;
        }
    }
}
</style>
