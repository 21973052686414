<template>
<div>
    <Title
        title="Affordability (Proof of Concept)"
        :location="location"
    />

    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="three-quarters-height m">
        <MapGraph
            v-if="map.render"
            :values="map.values"
            :settings="map.settings"
        />
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import MapGraph from '@/components/visualisations/general/MapGraph.vue'
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        MapGraph,
        Title    
    },
    data: function() {
        return {
            map: {
                values: {},
                settings: {
                    YTD: {
                        name: 'YTD (Most Recent)',
                        format: '{0} %',
                        thousandSeparator: ','
                    },
                    'Yearly Goal': {
                        name: 'Yearly Goal',
                        format: '{0} %'
                    }
                },
                render: false
            },
            location: '/three-roads/furniture-that-fits-my-budget'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.get_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Map',
                    link: `${this.location}/map`
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
            ];
        },
        get_data: function() {
            this.$http.get('/three-roads/furniture-that-fits-my-budget/total-ingka-by-country').then(
                succ => {
                    if (!succ.data) return;
                    succ.data.forEach(({market, measures}) => {
                        if (market === 'ALL') return;
                        if (measures) {
                            let val = measures[`${Object.keys(measures)[Object.keys(measures).length - 2]}`];
                            if (val) {
                                this.map.values[market] = {
                                    'YTD': val || '',
                                    'Yearly Goal': measures['Yearly Goal'] || '',
                                    'link': `${this.location}/${market}`
                                }
                            }
                        } 
                    });
                    this.map.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        }
    }
}
</script>
