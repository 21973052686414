<template>
<div>
    <b>{{dates_picked}}</b>
    <input id="DateTimePicker" class="calendar-input" v-model="dates_picked" type="textinput" placeholder="Pick a date..." data-input >
    <div id="CalendarButton" class="button calendar-button is-info">
        <i class="far fa-calendar-alt"></i>
    </div>
</div>
</template>

<script>
import 'flatpickr/dist/flatpickr.css';
import flatpickr from 'flatpickr';
export default {
    props: {
        default: Array,
        minDate: String,
        singleDate: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            dates_picked: null,
        }
    },
    mounted: function() {
        let res = flatpickr('#DateTimePicker', {
            enableTime: false,
            mode: this.singleDate ? "single" : "range",
            minDate : new Date()
        });

        if (this.default) {
            if (this.singleDate) {
                this.dates_picked = this.default;
            } else {
                this.dates_picked = this.default.map(d => new Date(d).toISOString().split('T').shift()).join(' to ');
            }
        }
        
        document.getElementById('CalendarButton').addEventListener("click", function() {
            res.open();
        });
    },
    methods: {
        open_calendar: function() {
            let calendar = flatpickr('#DateTimePicker');
            calendar.open();
        }
    },
    watch: {
        dates_picked: function() {
            let d = this.dates_picked;
            if (d) {
                let dates, val;
                if (d.indexOf(' to ') > -1) {
                    dates = d.split(' to ');
                    val = [dates[0], dates[1]];
                } else {
                    val = [d, null];
                }
                this.$emit('updated', val);
            }
        }
    }
}
</script>

<style>
#DateTimePicker {
    border: 1px solid #CCC;
    border-radius: 3px;
    box-shadow: 0 1px 3px #ccc;
    -webkit-box-shadow: 0 1px 3px #ccc;
    font-size: 1em;
    padding: 0.5em;
    text-align: center;
    width: 100%;
}

.calendar-button {
    display: block !important;
    margin-top: -2rem;
}

.calendar-input {
    visibility: collapse;
}
</style>