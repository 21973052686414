<template>
    <div :id="title" style="width: 100%; height:100%;"></div>
</template>

<script>
import * as echarts from 'echarts/dist/echarts.min.js';
import { str_color_gen } from '@/assets/colors/colors.js';

export default {
    props: {
        title: String, //title of the graph
        showTitle: Boolean,
        subTitle: String,
        names: Array, //data that will be displayed on the x axis
        yAxisLabel: String,
        xAxisLabel: String,
        sizeLabel: String,
        yMin: Number,
        yMetric: String, //'%', '£', 'kg' etc.
        datasets: Array, //actual data array to fill the graph
        colors: Array,
    },
    mounted: function() {
        let myChart = echarts.init(document.getElementById(this.title));
        let total = 0;
        if (!this.datasets) return;
        this.datasets.forEach((set, i) => {
            total += Object.values(set.values).reduce((val, acc) => val + acc, 0);
        });
        const average = Math.round(total/Math.max(this.datasets.length, 1));
        const list_of_values = this.datasets.map(
            plot_point => Object.values(plot_point.values).reduce((v, a) => v + a, 0)
        );
        const max = Math.max(...list_of_values);
        const min = Math.min(...list_of_values);
        const metadata = {
            average,
            max,
            min,
            total
        };
        let option = {
            title: {
                text: this.title,
                subtext: this.subTitle,
                show: this.showTitle,
                subtextStyle: {
                    color: 'black',
                },
                top: '-5em',
            },
            legend: {
                top: '45em',
                left: '0em',
                data: this.names
            },
            grid: {
                top: '140em',
                left: '120em',
                right: '40em',
                bottom: '90em'
            },
            toolbox: {
                top: '85em',
                right: '30em',
                show: true,
                feature: {
                    restore: {},
                    saveAsImage: {}
                }
            },
            tooltip: {
                trigger: 'axis',
                formatter: param => {
                    const item = param[0].data;
                    return `
                        <p><b>${param[0].seriesName} ${param[0].marker}</b></p>
                        <p>${this.xAxisLabel}: ${item[0]}</p>
                        <p>${this.yAxisLabel}: ${item[1]}</p>
                        ${item[2] ? `<p>${this.sizeLabel}: ${item[2]}</p>` : ''}
                    `;
                },
                axisPointer: {
                    type: 'shadow',
                    axis: 'x',
                    snap: false
                }
            },
            dataZoom: [
                {
                    show: this.show,
                    type: 'slider',
                    xAxisIndex: 0,
                    filterMode: 'empty',
                    left: '110em' ,
                    bottom: '10em' ,
                    handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                    handleSize: '80%', 
                    handleStyle: {
                        color: '#fff',
                        shadowBlur: 3,
                        shadowColor: 'rgba(0, 0, 0, 0.6)',
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    }
                },
                {
                    show: this.show,
                    type: 'slider',
                    yAxisIndex: 0,
                    filterMode: 'empty',
                    left: '0em' ,
                    bottom: '90em' ,
                    handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                    handleSize: '80%',
                    handleStyle: {
                        color: '#FFF',
                        shadowBlur: 3,
                        shadowColor: 'rgba(0, 0, 0, 0.6)',
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    }
                }
            ],
            xAxis: {
                name: this.xAxisLabel,
                nameLocation: 'middle',
                nameGap: 30,
                splitLine: {
                    lineStyle: { type: 'dashed' }
                }
            },
            yAxis: {
                name: this.yAxisLabel,
                nameLocation: 'middle',
                nameGap: 60,
                min: this.yMin || null,
                splitLine: {
                    lineStyle: {
                        type: 'dashed'
                    }
                },
                axisLabel: {
                    formatter: `{value} ${this.yMetric}`
                },
                scale: true
            },
            series: this.call_data(this.datasets, metadata)
        };
        myChart.setOption(option);
    },
    methods: {
        call_data: function(datasets, metadata) {
            let temp = [];
            datasets.forEach((item, i) => {
                temp.push({
                    name: item.name,
                    data: [[item.values.x, item.values.y, item.values.size]],
                    type: 'scatter',
                    symbolSize: item.values.display_size || 20 + ((item.values.x + item.values.y) / ((metadata.max - metadata.min) / 40)),
                    itemStyle: {
                        color: this.colors[i] || str_color_gen(item.name)
                    }
                });
            });
            return temp;
        }
    }
}
</script>


