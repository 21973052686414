var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "mt" }, [
      _c("b", [
        _vm._v(
          " Downloads shown are for " +
            _vm._s(_vm.$store.getters.selected_country_name) +
            " "
        ),
      ]),
    ]),
    _c("div", { staticClass: "mb" }, [
      _vm._v(
        "* The country selected in the top filter decides which country the download belong to *"
      ),
    ]),
    _vm.current_year && Object.keys(_vm.formatted_downloads).length
      ? _c(
          "div",
          _vm._l(
            Object.keys(_vm.formatted_downloads).length,
            function (number, i) {
              return _c(
                "div",
                { key: i, staticClass: "text-left ml mb" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.current_year - i)),
                  ]),
                  _vm._l(
                    _vm.formatted_downloads[_vm.current_year + 1 - number],
                    function (week, j) {
                      return _c(
                        "div",
                        {
                          key: j,
                          staticClass: "export mb",
                          on: {
                            click: function ($event) {
                              return _vm.download_path(week)
                            },
                          },
                        },
                        [
                          _c("b", [_vm._v(_vm._s(_vm.get_week(week, i)))]),
                          _c("i", { staticClass: "fas fa-file-word ml" }),
                        ]
                      )
                    }
                  ),
                ],
                2
              )
            }
          ),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-bar" }, [
      _c("div", { staticClass: "export-title" }, [_vm._v("Reports Downloads")]),
      _c("div", { staticClass: "export-key" }, [
        _c("div", [
          _c("i", { staticClass: "fas fa-file-word mr" }),
          _vm._v(" Country Board Package "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }