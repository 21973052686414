var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("AdminUniversal", {
        attrs: { component: "title", props: _vm.title_props },
      }),
      _vm._m(0),
      _vm.table_props.render
        ? _c("AdminUniversal", {
            attrs: { component: "table", props: _vm.table_props },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-left mb" }, [
      _c("p", [
        _vm._v(
          "KPI Definitions that have received feedback on their perceived usefulness since last updated will appear in the table below."
        ),
      ]),
      _c("p", [
        _vm._v(
          "Positive feedback represents the number of people who perceived definition useful, and negative feedback represents the number of people who did not."
        ),
      ]),
      _c("p", [
        _vm._v(
          "Rating is the ratio of positive to negative feedback, a higher ratio meaning the definition was found more useful."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }