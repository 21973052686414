<template>
<div id="table">
    <table class="table table-component is-narrow is-striped" :style="render_font">
        <thead>
            <tr>
                <th v-for="(header, index) in headers" :key="index" @click="sort_row_by(index)" class="ikea-yellow">
                    <!-- Add an invisible arrow to fill out the space when the column hasn't been selected -->
                    <span v-show="index !== last_col_clicked && index !== 0" class="icon has-text-white">
                        <i v-show="!asc" class="fas invisible fa-arrow-down"></i>
                    </span>

                    <!-- If this is the last column that's been clicked and not the first column, render an arrow -->
                    <span v-show="index === last_col_clicked && index !== 0" class="icon has-text-white">
                        <i v-show="asc" class="fas fa-sort-up"></i>
                        <i v-show="!asc" class="fas fa-sort-down"></i>
                    </span>
                    {{header}}
                    <span v-show="index === last_col_clicked && index === 0" class="icon has-text-white">
                        <i v-show="asc" class="fas fa-sort-up"></i>
                        <i v-show="!asc" class="fas fa-sort-down"></i>
                    </span>
                </th>
            </tr>
        </thead>

        <tbody>
            <tr v-for="(row, index) in sorted_rows" :key="index" :class="render_row(row[0])" @click="clickedTable(row[0])" >
                <td v-for="(col, i) in row" :key="i">
                    <span v-if="typeof col === 'string'" v-html="col"></span>
                    <span v-else>{{col}}</span>
                </td>
            </tr>
        </tbody>
    </table>
</div>
</template>

<script>
export default {
    props: {
        headers: Array,
        rows: Array,
        iconSettings: Object,
        sortSettings: Object,
        decimal: Boolean,
        bold: String
    },
    data: function() {
        return {
            just_loaded: true,
            has_all_row: false,
            last_col_clicked: 0,
            asc: false,
            show_info: false,
        }
    },
    methods: {
        clickedTable: function(market) {
            this.$emit('clickedTable', market);
        },
        render_bar: function(value, settings) {
            let lower = 0, upper = 0;
            let max = Math.max;
            let abs = Math.abs;
            
            let normalised = max(abs(settings.max), abs(settings.min));
            if (value > 0) {
                upper = (value/normalised)*100;
            } else {
                lower = (abs(value)/normalised)*100;
            }
            
            let lower_width = 50, upper_width = 50;
            if (settings.min > 0) {
                upper_width = 100;
                lower_width = 0;
            } else if (settings.max < 0) {
                upper_width = 0;
                lower_width = 100;
            }

            let red = '#f80202', green = '#1a8245';
            if (settings.reversed) {
                let temp = red;
                red = green;
                green = temp;
            }

            return `
            <div id="wrapper" style="width: 10rem;">
                <div style="float: left; width: 75%;">
                    <div style="display: inline-block; float: left; height: 1.5rem; width: ${lower_width}%;">
                        <div class="pb-bar" style="background-color: ${red}; float: right; height: 100%; width: ${lower}%"></div>
                    </div>
                    <div style="display: inline-block; float: left; height: 1.5rem; width: ${upper_width}%;">
                        <div class="pb-bar" style="background-color: ${green}; height: 100%; width: ${upper}%"></div>
                    </div>
                </div>
                <div style="width: 25%;" class="inline-block">
                    ${value}
                </div>
            </div>
            `;
        },
        render_arrow: function (value, color, direction) {
            return `<div style="display:inline-block;">
                    ${value}
                </div>
                <span class="icon has-text-${color} ft-tooltip" tooltiptext="Compares to YTD">
                    <i class="fas fa-arrow-${direction}"></i>
                </span>`;
        },
        render_circle: function(value, color) {
            return `
                <div style="display:inline-block;">
                    ${value}
                </div>
                <span class="icon has-text-${color} ft-tooltip" tooltiptext="Compares to Year Goal">
                    <i class="fas fa-circle"></i>
                </span>
                `;
        },
        render_equals: function(value) {
            return `<div style="display:inline-block;">
                    ${value}
                </div>
                <span class="icon has-text-primary">
                    <i class="fas fa-equals"></i>
                </span>`;
        },
        render_space: function(value) {
            return `<div style="display:inline-block;">
                    ${value}
                </div>
                <span class="icon invisible">
                    <i class="fas fa-circle"></i>
                </span>`;
        },
        render_append: function(value) {
            return `<div style="display:inline-block;">
                    ${value}
                </div>`;
        },
        render_icon: function (icon, value, color, direction) {
            switch(icon) {
                case 'arrow':
                    return this.render_arrow(value, color, direction);
                break;
                // case 'bar':
                    // return this.render_bar(value, color, direction);
                // break;
                case 'circle':
                    return this.render_circle(value, color);
                break;
                case 'equals':
                    return this.render_equals(value);
                break;
                case 'space':
                    return this.render_space(value);
                break;
                case 'append':
                    return this.render_append(value);
                break;
            }
        },
        render_row: function(item) {
            let colors = {};
            colors['is-selected'] = item === this.$store.getters.selected_country_name;
            colors[this.$helper.location().kpi] = item === this.$store.getters.selected_country_name;;
            return colors;
        },
        sort_row_by: function(index) {
            let pos = -1, neg = 1;
            // If we clicked the same icon twice, switch the ordering
            
            if (this.last_col_clicked === index) {
                if (this.asc) {
                    pos = 1;
                    neg = -1;
                    this.asc = false;
                } else {
                    this.asc = true;
                }
            } else if (!this.asc) {
                pos = 1;
                neg = -1;
            }

            this.rows.sort((a, b) => {
                if (
                    !(isNaN(a[index])) && 
                    !(isNaN(b[index])) && 
                    a[index] && 
                    b[index]
                ) {
                    a[index] = parseFloat(a[index]);
                    b[index] = parseFloat(b[index]);
                }

                if (a[index] > b[index]) {
                    return pos;
                } else if (a[index] === b[index]) {
                    return 0;
                }
                return neg;
            });
            this.last_col_clicked = index;
        },
    },
    computed: {
        render_font: function() {
            let font_size = this.$store.state.global.table.font_size;
            if (!font_size) {
                font_size = 16;
            }
            return {
                'font-size': `${font_size}px`
            }
        },
        rendered_rows: function() {
            let decimal_exist = false;
            let temp_rows = JSON.parse(JSON.stringify(this.rows));
            temp_rows.forEach(row => {
                row.forEach((r, index) => {
                    if (r === null) {
                        decimal_exist = false;
                    } else if (r.toString().indexOf('.') > 0) {
                        decimal_exist = true;
                    }
                });
            });

            temp_rows = temp_rows.map(row => {
                return row.map((r, index) => {
                    if (index === 0) return r;
                    if (this.decimal || decimal_exist) {
                        if (r == 0) return '';
                        if (isNaN(parseInt(r))) return r;
                        if (r.toString().indexOf('.') > 0) {
                            return r.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        } else {
                            return `${r.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.0`;
                        }
                    } else {
                        return r;  
                    }
                });
            });

            for (let key in this.iconSettings) {
                let header_index = this.headers.findIndex(header => header === key);
                if (header_index > -1) {
                    // Found header
                    let comparison_name = this.iconSettings[key].comparison;
                    let comparison_index = this.headers.findIndex(header => header === comparison_name);

                    // Compare the values of header index and comparison index
                    for (let i = 0; i < temp_rows.length; i++) {
                        let row = temp_rows[i];
                        let initial_col = this.rows[i][header_index];
                        let comparison_col = this.rows[i][comparison_index];
                        let append = '';
                        if (this.iconSettings[key].append !== undefined && !isNaN(parseInt(row[header_index]))) {
                            if (this.iconSettings.ignore !== undefined) {
                                if (this.iconSettings.ignore.findIndex(ignore => row[0] === ignore) < 0) {
                                    append = this.iconSettings[key].append;
                                }
                            } else {
                                append = this.iconSettings[key].append;
                            }
                        }

                        if (this.iconSettings[key].icon === undefined) {
                            temp_rows[i][header_index] = this.render_icon(
                                'append',
                                `${row[header_index]}${append}`,
                                null,
                                null
                            );
                        } else if (
                            initial_col    == 0     ||
                            initial_col    == ""    ||
                            comparison_col == 0     ||
                            comparison_col == ""
                        ) { // Weak evaluation on purpose
                            temp_rows[i][header_index] = this.render_icon(
                                'space',
                                `${row[header_index]}${append}`,
                                null,
                                null
                            );
                        } else if (this.iconSettings[key].icon === 'bar') {
                            let maybe_NaN = function(value) {
                                if (isNaN(value)) {
                                    return null;
                                } else {
                                    return parseFloat(value);
                                }
                            }
                            let ignored = this.iconSettings[key].ignore ? this.iconSettings[key].ignore : [];
                            let rows = [...this.rows].filter(row => ignored.indexOf(row[0]) < 0); // Make a copy of our rows

                            // Find minimum value in column
                            let min = Math.min(...rows.map(row => maybe_NaN(row[comparison_index])));

                            // Find maximum value in column
                            let max = Math.max(...rows.map(row => maybe_NaN(row[comparison_index])));
                            
                            let settings = {...this.iconSettings[key], min, max};

                            let country = temp_rows[i][0];
                            if (ignored.indexOf(country) < 0) {
                                temp_rows[i][header_index] = this.render_bar(row[header_index], settings);
                            }
                        } else {
                            let comparison = parseFloat(this.rows[i][header_index]) >= parseFloat(comparison_col);
                            if (this.iconSettings[key].reversed) {
                                comparison = !comparison;
                            }
                            
                            let direction = comparison ? 'up' : 'down';
                            
                            if (this.iconSettings[key].color_reversed) {
                                comparison = !comparison;
                            }
                            let color = comparison ? 'success' : 'danger'
                            temp_rows[i][header_index] = this.render_icon(
                                this.iconSettings[key].icon,
                                `${row[header_index]}${append}`,
                                color,
                                direction
                            );
                        }
                    }
                }
            }
            return temp_rows;
        },
        sorted_rows: function() {
            let temp_rows = JSON.parse(JSON.stringify(this.rendered_rows));
            let bold_text = this.bold || 'Total Retail Countries';
            let all_index = temp_rows.findIndex((row) => row[0] === bold_text);
            
            if (all_index > -1) {
                this.has_all_row = true;
            } else {
                return temp_rows;
            }

            let all_row = temp_rows[all_index];

            // Remove 'ALL' from the array
            temp_rows.splice(all_index, 1);

            // Insert it at the end of the array
            temp_rows.push(all_row);
            return temp_rows;
        }
    },
    watch: {
        headers: function() {
            this.just_loaded = true;
            this.has_all_row = false;
            this.last_col_clicked = null;
            
            if (this.sortSettings) {
                if ('unsorted' in this.sortSettings) {
                    if (this.sortSettings.unsorted) {
                        return;
                    }
                }
                this.asc = this.sortSettings.asc;
                this.last_col_clicked = this.sortSettings.column;
                this.sort_row_by(this.sortSettings.column);
            } else {
                this.asc = false;
                this.sort_row_by(0);
            }
        }
    }
}
</script>

<style scoped>
.description {
    display: contents;
    text-align: left;
}

.fa-info-circle {
    height: 1rem;
}

.icon {
    height: 1em;
}

#table {
    margin: 0;
    margin-left: 1rem;
    width: fit-content;
}

.table {
    position: relative;
}

.table thead th {
    position: sticky;
    top: 3.2rem;
    min-width: 6rem;
}

.table thead tr th {
    padding: 0 0.2rem !important;
}

.table th {
    color: #FFF !important;
}

.table th:not([align]):first-child {
    text-align: left;
}

.table th:not([align]) {
    text-align: right;
}

.table tr {
    cursor: pointer;
}

table tbody tr:last-child {
    font-weight: bold;
}

.table tr.is-selected {
    /* background-color: #009d00; */
    color: #fff;
}

table td:first-child {
    text-align: left !important;
}

table td {
    padding: 0 0.2rem !important;
    text-align: right !important;
}

thead * {
    z-index: 1;
}
</style>
