<template>
<div>
    <div class="title">
        DATA LOAD TRACKER
    </div>
    <div>
        <span class="sorting">Sort by</span> 
        <DropDown
            :default="sort_key"
            :options="sort_options"
            :values="sort_values"
            @updateDropDown="update_sort"
        />
        <input class="input" placeholder="Search KPIs" v-model="search_str"/>
        <button v-if="debug_mode" @click="get_data" class="button is-info ml">Refresh</button>
        <button @click="toggle_comments()" class="button ikea-blue has-text-white ml mr">{{display_comments.length ? 'Hide' : 'Show' }} comments</button>
        <input type="checkbox" id="checkbox" v-model="display_front_page_only">
        <label for="checkbox">Show frontpage KPIs only</label>
    </div>
    <div>
        <table class="table">
            <thead class="ikea-yellow">
                <tr>
                    <th rowspan="2">KPI</th>
                    <th rowspan="2">Frequency</th>
                    <th rowspan="2">Type</th>
                    <th colspan="2">Processing date <span class="tooltip cursor-pointer" :data-tooltip="processing_description"><i class="fas fa-question-circle"></i></span></th>
                    <th rowspan="2">Current Version</th>
                    <th rowspan="2" v-if="debug_mode">Expected Version</th>
                    <th rowspan="2">Status</th>
                </tr>
                <tr>
                    <th>Start</th>
                    <th>End</th>
                </tr>
            </thead>
            <template v-for="row, i in filtered_table_data">
                <tr :key=2*i>
                    <td :class="{unfolded: (display_comments.includes(i) && row.load_comment)}">
                        {{row.report}}
                        <span v-if="row.load_comment" @click="toggle_comments(i)" class="ml tooltip cursor-pointer" :data-tooltip="'View comment'">
                            <i class="fas fa-comment-dots"></i>
                        </span>
                    </td>
                    <td :class="{unfolded: (display_comments.includes(i) && row.load_comment)}">{{row.frequency_label}}</td>
                    <td :class="{unfolded: (display_comments.includes(i) && row.load_comment)}">{{row.load_type}}</td>
                    <td :class="{unfolded: (display_comments.includes(i) && row.load_comment)}" class="has-text-right">{{row.processing_start ? row.processing_start.toUTCString().slice(0,16) : ''}}</td>
                    <td :class="{unfolded: (display_comments.includes(i) && row.load_comment)}" class="has-text-right">{{row.processing_end ? row.processing_end.toUTCString().slice(0,16) : ''}}</td>
                    <td :class="{unfolded: (display_comments.includes(i) && row.load_comment)}">{{row.formatted_current_version}}</td>
                    <td :class="{unfolded: (display_comments.includes(i) && row.load_comment)}" v-if="debug_mode">{{row.formatted_expected_version}}</td>
                    <td :class="[row.status_indicator, {unfolded: (display_comments.includes(i) && row.load_comment)}]" class="has-text-centered"><i class="fas fa-circle"></i></td>
                </tr>
                <tr v-if="row.load_comment && display_comments.includes(i)" :key=2*i+1>
                    <td class="comment-title comment">Comment:</td>
                    <td colspan="6" class="comment">{{row.load_comment}}</td>
                </tr>
            </template>
        </table>
    </div>
</div>
</template>

<script>
/**
 * Debug this commponent by setting this.debug_mode: true
 */
import load_tracker_helper from '../helper/load-tracker-helpers.js';
import DropDown from '@/components/ui/DropDown.vue';

export default {
    components: {
        DropDown
    },
    data: function() {
        return{
            display_comments: [],
            display_front_page_only: false,
            table_data: null,
            search_str: '',
            sort_key: 'report#desc',
            sort_options: ['KPI Name', 'Load Status', 'Frequency', 'Processing start'],
            sort_values: ['report#desc', 'status_indicator#desc', 'frequency_label#desc', 'processing_start#desc'], //Sort values are a compound string of <kpi key>#<order (asc/desc)>

            // Constants
            front_page_kpi_codes: [
                'sales-tree',
                'hcs',
                'trust',
                'furniture-that-fits-my-budget',
                'sustainable-sales',
                'planetary-footprint',
                'fs#ZeroEmissions',
                'fs#ZeroWaste',
                'fs#eNPS',
                'fs#SocialImpact',
                'fs#Co-WorkerTurnover',
                'engagement-index',
                'ikea-values',
                'sales-trend-total',
                'Sales-hfb',
                'pl-2',
                'operational-gross-margin-forecast-vs-goal',
                'market-share'
            ],
            processing_description: 'Expected next load interval',
            months: ['January', 'February', 'March',
                'April', 'May', 'June', 'July', 'August',
                'September', 'October', 'November', 'December'],
            debug_mode: false
        }
    },
    mounted: function() {
        this.$store.state.commenting.width = 0;
        this.get_data();
    },
    destroyed: function() {
        this.$store.state.commenting.width = 4;
    },
    computed: {
        filtered_table_data: function() {
            if (!this.table_data || this.table_data.length === 0) return this.table_data;

            const [sort_key, sort_order] = this.sort_key.split('#');
            let rows = this.table_data.sort((a,b) => {
                return sort_order === 'asc'
                    ? a[sort_key] < b[sort_key] ? 1 : -1
                    : a[sort_key] > b[sort_key] ? 1 : -1
            });
            if (!!this.search_str) rows = rows.filter(kpi => kpi.report.toLowerCase().includes(this.search_str.toLowerCase()));
            if (this.display_front_page_only) rows = rows.filter(kpi => this.front_page_kpi_codes.includes(kpi.kpi_code));
            return rows;
        }
    },
    methods: {
        get_data: function() {
            if (this.debug_mode) console.log(getISOWeek(new Date()));
            this.$http.get('/data-load-tracker').then(
                succ => {
                    this.table_data = succ.data;
                    this.process_response();
                },
                this.$helper.error
            );
        },
        process_response: function() {
            this.table_data = this.table_data.filter(kpi => kpi.load_comment !== '/hide'); //Secret code to instantly hide rows
            this.table_data = this.table_data.map(kpi => load_tracker_helper.process_load_tracker_table_row(kpi));
        },
        toggle_comments: function(id) {
            if (!id && id !== 0) {
                if (this.display_comments.length === 0) this.table_data.forEach((row, i) => this.display_comments.push(i));
                else this.display_comments = [];
            } else {
                if (!this.display_comments.includes(id)) this.display_comments.push(id);
                else this.display_comments.splice(this.display_comments.findIndex(el => el === id), 1);
            }
        },
        update_sort: function(sort) {
            this.sort_key = sort
        }
    }
}
</script>

<style scoped>
div {
    text-align: left;
}

#checkbox {
    margin-left: 5px;
    margin-right: 5px;
    height: 20px;
    width: 20px;
}
.comment {
    color: #7c7c7c;
    padding-top: 0 !important;
}
.comment-title {
    justify-content: right !important;
}
.fa-question-circle, .fa-comment-dots {
    color: #0051BA;
    font-size: 1.2rem;
}
.fa-question-circle:hover, .fa-comment-dots:hover {
    color: #00369A;
}

.input {
    margin-left: 1rem;
    max-width: 16rem;
}
.sorting {
    line-height: 2.4rem;
    margin-left: 1rem;
    margin-right: 1rem;
}
.unfolded {
    border-bottom: none;
}

table {
    margin: 1rem;
    width: 97vw;
    max-width: 1600px;
}

.title {
    margin: 1rem;
}

.table td {
    padding: 0.6rem 0.8rem 0.6rem 0.8rem;
}
th {
    border: 1px solid white !important;
}
table.table thead tr th {
    color: black !important;
    font-weight: 600;
}
</style>