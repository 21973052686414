var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app" }, [
    _vm._m(0),
    _c("div", { staticClass: "columns is-gapless" }, [
      _c(
        "div",
        {
          key: _vm.$store.state.commenting.width,
          staticClass: "column",
          class: `is-${12 - Number(_vm.$store.state.commenting.width)}`,
        },
        [
          _c("Spinner", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$store.state.loading !== 0,
                expression: "$store.state.loading !== 0",
              },
            ],
          }),
          _c("router-view"),
        ],
        1
      ),
      _vm.$store.state.commenting.width > 0
        ? _c(
            "div",
            {
              staticClass: "column",
              class: `is-${_vm.$store.state.commenting.width}`,
            },
            [_c("Comments")],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "progress-bar" }, [
      _c("div", { staticClass: "fill", attrs: { id: "progress_bar" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }