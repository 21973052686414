var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("AdminUniversal", {
        attrs: { component: "title", props: _vm.title_props },
      }),
      _c(
        "div",
        { staticClass: "options-bar text-left" },
        [
          _c("label", { staticClass: "label" }, [_vm._v("Search KPI")]),
          _vm.search_kpi_areas
            ? _c("DropDown", {
                key: _vm.search_kpi_areas[0],
                attrs: {
                  options: _vm.search_kpi_areas,
                  values: _vm.search_kpi_areas,
                  default: _vm.search_kpi_areas[0],
                },
                on: { updateDropDown: _vm.updated_search_areas_dropdown },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.table_props.render
        ? _c("AdminUniversal", {
            attrs: { component: "table", props: _vm.table_props },
          })
        : _c("div", [_vm._v("There are no owners for this KPI yet")]),
      _c("hr"),
      _c("AdminUniversal", {
        attrs: { component: "title", props: _vm.title_props2 },
      }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("KPIChooser", {
            staticClass: "kpi-chooser",
            on: { chosen: _vm.kpi_chosen, reset: _vm.back },
          }),
        ],
        1
      ),
      Object.keys(_vm.location).length
        ? _c(
            "div",
            { staticClass: "options-bar text-left mt" },
            [
              _c("label", { staticClass: "label" }, [_vm._v("Search User")]),
              _c("UserSearch", {
                staticClass: "user-search",
                on: { SelectedUser: _vm.user_selected },
              }),
              _c("div", { staticClass: "options-bar" }, [
                _c(
                  "button",
                  {
                    staticClass: "button is-success float-left",
                    on: {
                      click: function ($event) {
                        return _vm.find_location_id()
                      },
                    },
                  },
                  [_vm._v("Update")]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }