<template>
<div>
    <Title
        title="Customer Promised Service Precision"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <Table
        v-if="table.render"
        :key="table.headers.length"

        :headers="table.headers"
        :rows="table.rows"

        :icon-settings="table.icon_settings"
        :bridge-settings="table.bridge_settings"
        :decimal-settings="table.decimal_settings"
        :sort-settings="{ 'Country': { asc: true } }"
        :font-settings="{ bold: {row: {regex: 'Total Retail Countries' } } }"
        class="ms1p5"
    />
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: [],
                decimal_settings: {},
                render: true
            },
            location: '/customer/promised-delivery-precision'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.load_ingka_by_country();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
            ];
        },
        load_ingka_by_country: function() {
            this.$http.get('/customer/customer-promised-delivery-precision/total-ingka-by-country').then(
                succ => {
                    this.table.render = false;
                    let d = succ.data;
                    if (d[0].measure_metadata) {
                        this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                    }
                    if (!d || !d[0]) return;
                    let keys = Object.keys(d[0].measures);
                    this.table.headers, this.table.rows = [];
                    this.table.headers = [
                        // Row 1
                        [{'': 1}, {'CPDP': 2}, {'Breach IKEA': 2}, {'Breach Customer': 2}, {'Breach Service Provider': 2}],
                        //Row 2
                        [
                            {'Country': 1},

                            {'YTD': 1, alias: 'CPDP YTD'},
                            {'4WK Trend': 1, alias: 'CPDP 4WK Trend'},

                            {'YTD': 1, alias: 'Breach IKEA YTD'},
                            {'Goal': 1, alias: 'Breach IKEA Goal'},        

                            {'YTD': 1, alias: 'Customer YTD'},
                            {'Goal': 1, alias: 'Customer Goal'}, 

                            {'YTD': 1, alias: 'Service Provider YTD'},
                            {'Goal': 1, alias: 'Service Provider Goal'}
                        ]
                    ];

                    this.table.bridge_settings = {
                        columns: {
                            'CPDP': { hide: true },
                            'Breach IKEA': { hide: true },
                            'Breach Customer': { hide: true },
                            'Breach Service Provider': { hide: true },
                        }
                    }
                    this.table.icon_settings = {
                        'CPDP 4WK Trend': {
                            comparison: 'CPDP YTD',
                            icon: 'arrow',
                            colors: {
                                positive: '#0F0',
                                equal: '#00F',
                                negative: '#F00',
                            }
                        },
                        'IKEA YTD': {
                            comparison: 'IKEA Goal',
                            icon: 'circle',
                            reversed: true,
                            colors: {
                                positive: '#0F0',
                                equal: '#00F',
                                negative: '#F00',
                            }
                        },
                        'Customer YTD': {
                            comparison: 'Customer Goal',
                            icon: 'circle',
                            reversed: true,
                            colors: {
                                positive: '#0F0',
                                equal: '#00F',
                                negative: '#F00',
                            }
                        },
                        'Service Provider YTD': {
                            comparison: 'Service Provider Goal',
                            icon: 'circle',
                            reversed: true,
                            colors: {
                                positive: '#0F0',
                                equal: '#00F',
                                negative: '#F00',
                            }
                        }
                    }
                    this.table.rows = d.map(row => {
                        let t = [row.caption];
                        let val = row.measures;
                        // Push the measures
                        t.push(
                            val["YTD"],
                            val["Week 4"],
                            val["IKEA YTD"],
                            val["IKEA Goal"],
                            val["Customer YTD"],
                            val["Customer Goal"],
                            val["SP YTD"],
                            val["SP goal"]
                        );

                        return t;
                    });
                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        }
    }
}
</script>