<template>
<div>
    <div class="buttons">
        <a class="button" :class="{'is-success': set_read_all, 'is-danger': !set_read_all}" @click="read_all">Read All</a>
        <a class="button" :class="{'is-success': set_write_all, 'is-danger': !set_write_all}" @click="write_all">Write All</a>
    </div>

    <Node :key="render" :node="template" :path="[]" @update="update"/>
</div>
</template>

<script>
import KPIDataMapping from '@/assets/scripts/kpi-data-mapping.js';
import ExtraMapping from '@/assets/scripts/extra-mapping.js';
import Title from '@/components/admin/Title.vue';
import Node from '@/components/admin/access-templates/Node.vue';

export default {
    props: {
        prop_template: Object
    },
    components: {
        Title,
        Node
    },
    data: function() {
        return {
            template: {},
            render: 0,
            set_read_all: false,
            set_write_all: false
        }
    },
    mounted: function() {
        if (!this.prop_template) {
            this.template = {};
        } else {
            this.template = this.prop_template;
        }

        // /brand/desire, 2 will return 'desire'
        let split_path = function(path, level) {
            let split_up_path = path.split('/');
            // if (split_up_path.length < level) return split_up_path[split_up_path.length];
            return split_up_path[level];
        }
        
        for (let kpi of this.kpi_data_mapping) {
            if (!(split_path(kpi.path, 1) in this.template)) {
                // Level 1
                this.template[split_path(kpi.path, 1)] = this.create_node(kpi);
            }

            if ('areas' in kpi) {
                // Level 2
                for (let area of kpi.areas) {
                    if (!(split_path(area.path, 2) in this.template[split_path(kpi.path, 1)])) {
                        this.template[split_path(kpi.path, 1)][split_path(area.path, 2)] = this.create_node(area);
                    }
                    
                    if ('areas' in area) {
                    // Level 3
                        for (let a of area.areas) {
                            if (!(split_path(a.path, 3) in this.template[split_path(kpi.path, 1)][split_path(area.path, 2)])) {
                                this.template[split_path(kpi.path, 1)][split_path(area.path, 2)][split_path(a.path, 3)] = this.create_node(a);
                            }
                        }
                    }
                }
            }
        }
    },
    methods: {
        alert: function(message) {
            alert(message);
        },
        create_node: function(kpi) {
            const is_no_sql = 'document' in kpi;

            return is_no_sql ? {
                "*": {
                    r: false,
                    w: false,
                    document: kpi.document
                }
            } :
            {
                "*": {
                    r: false,
                    w: false
                }
            };

        },
        update: function(path) {
            let dig = function(tree, path) {
                let p = path.shift();

                if (path.length === 0) {
                    tree[p] = !tree[p];
                } else {
                    dig(tree[p], path);
                }
            }

            dig(this.template, path);

            this.render++;

            this.$emit('update', this.template);
        },
        read_all: function() {
            this.set_read_all = !this.set_read_all;
            this.set_all(this.template, 'r', this.set_read_all);
            this.render++;
            this.$emit('update', this.template);
        },
        set_all: function(tree, key, value) {
            for (let k in tree) {
                if (k === key) {
                    tree[k] = value;
                } else if (typeof tree[k] === 'object' && tree[k] !== null) {
                    this.set_all(tree[k], key, value);
                }
            }
        },
        write_all: function() {
            this.set_write_all = !this.set_write_all;
            this.set_all(this.template, 'w', this.set_write_all);
            this.render++;
            this.$emit('update', this.template);
        }
    },
    computed: {
        kpi_data_mapping: function() {
            return KPIDataMapping.concat(ExtraMapping);
        }
    }
}
</script>

<style>
.kpi-item {
    border: solid 1px #000;
    border-radius: 3px;
    margin: 0.3em;
    padding: 0.3em;
}

.kpi-item:hover {
    background-color: #ADE;
    cursor: pointer;
}

.kpi-selected {
    background-color: #ADE;
}
</style>
