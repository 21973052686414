var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "options-bar" }, [
    _c("div", { staticClass: "dropdown" }, [
      _c("div", { staticClass: "dropdown-trigger" }, [
        _c("div", { staticClass: "field" }, [
          _c("p", { staticClass: "control is-expanded has-icons-right" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searched,
                  expression: "searched",
                },
              ],
              staticClass: "input",
              attrs: { type: "search", placeholder: "Search..." },
              domProps: { value: _vm.searched },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.searched = $event.target.value
                },
              },
            }),
            _vm._m(0),
          ]),
        ]),
      ]),
      _vm.search_render && _vm.searched_list.length > 0
        ? _c(
            "div",
            {
              staticClass: "dropdown-menu",
              attrs: { id: "dropdown-menu", role: "menu" },
            },
            [
              _c(
                "div",
                { staticClass: "dropdown-content" },
                _vm._l(_vm.searched_list, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "search-items",
                      on: {
                        click: function ($event) {
                          return _vm.set_name(item)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item.display_name) + " ")]
                  )
                }),
                0
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small is-right" }, [
      _c("i", { staticClass: "fas fa-search" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }