var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "table-container" }, [
      _c("table", { staticClass: "table is-fullwidth" }, [
        _c("thead", [
          _c(
            "tr",
            { staticClass: "business" },
            _vm._l(_vm.headers, function (header, i) {
              return _c("th", { key: i }, [_vm._v(_vm._s(header))])
            }),
            0
          ),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.data, function (row, j) {
            return _c(
              "tr",
              { key: j },
              [
                _vm._l(Object.keys(row), function (property, k) {
                  return _c("td", { key: k }, [_vm._v(_vm._s(row[property]))])
                }),
                _vm.buttons.length > 0
                  ? _c(
                      "td",
                      _vm._l(_vm.buttons, function (button, m) {
                        return _c("span", { key: m }, [
                          _c(
                            "button",
                            {
                              staticClass: "button mr",
                              class: button.classes,
                              on: {
                                click: function ($event) {
                                  return button.method(j)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(button.label) + " ")]
                          ),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
              ],
              2
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }