import CostPerCountryVsGoal from '@/views/financial/CostPerCountryVsGoal.vue';
import CostPerCountryVsGoalByMarket from '@/views/financial/CostPerCountryVsGoalByMarket.vue';

import CostPerCountry from '@/views/financial/CostPerCountry.vue';
import CostPerCountryException from '@/views/financial/CostPerCountryException.vue';
import CostPerCountryTop8 from '@/views/financial/CostPerCountryTop8.vue';
import CostPerCountryByMarket from '@/views/financial/CostPerCountryByMarket.vue';

import CostOfInternalDamages from '@/views/financial/CostOfInternalDamages.vue';
import CostOfInternalDamagesMarket from '@/views/financial/CostOfInternalDamagesTrend.vue';
import CostOfInternalDamagesTop8 from '@/views/financial/CostOfInternalDamagesTop8.vue';

import GrossMarginPerCountry from '@/views/financial/GrossMarginPerCountry.vue';
import GrossMarginPerCountryByMarket from '@/views/financial/GrossMarginPerCountryByMarket.vue';
import GrossMarginPerCountryTop8 from '@/views/financial/GrossMarginPerCountryTop8.vue';

import OperationalGrossMarginForecastVsGoal from '@/views/financial/OperationalGrossMarginForecastVsGoal.vue';
import OperationalGrossMarginForecastVsGoalByMarket from '@/views/financial/OperationalGrossMarginForecastVsGoalByMarket.vue';

import OperationalGrossMargin from '@/views/financial/OperationalGrossMargin.vue';
import OperationalGrossMarginTrend from '@/views/financial/OperationalGrossMarginTrend.vue';

import ProfitAndLossPerCountry from '@/views/financial/ProfitAndLossPerCountry.vue';
import ProfitAndLossTotal from '@/views/financial/ProfitAndLossTotal.vue';

import ProfitAndLossTotalIngkaRetail from '@/views/financial/ProfitAndLossTotalIngkaRetail.vue';
import ProfitAndLossTotalIngkaRetailExceptionGoal from '@/views/financial/ProfitAndLossTotalIngkaRetailExceptionGoal.vue';
import ProfitAndLossTotalIngkaRetailExceptionYTD from '@/views/financial/ProfitAndLossTotalIngkaRetailExceptionYTD.vue';

import ResultContributionPerCountry from '@/views/financial/ResultContributionPerCountry.vue';
import ResultContributionPerCountryByMarket from '@/views/financial/ResultContributionPerCountryByMarket.vue';

import ResultPerCountryVsGoal from '@/views/financial/ResultPerCountryVsGoal.vue';
import ResultPerCountryVsGoalByMarket from '@/views/financial/ResultPerCountryVsGoalByMarket.vue';

import TransformationalCost from '@/views/financial/TransformationalCost.vue';

import AfterSalesDeliveryShare from '@/views/financial/AfterSalesDeliveryShare.vue';
import AfterSalesDeliveryShareMarket from '@/views/financial/AfterSalesDeliveryShareMarket.vue';
import AfterSalesDeliveryShareTop8 from '@/views/financial/AfterSalesDeliveryShareTop8.vue';

import ProductQualityShare from '@/views/financial/ProductQualityShare.vue';
import ProductQualityShareByMarket from '@/views/financial/ProductQualityShareByMarket.vue';
import ProductQualityShareTop8 from '@/views/financial/ProductQualityShareTop8.vue';

import ServiceIncomeByCountry from '@/views/financial/ServiceIncomeByCountry.vue';
import ServiceIncomeByMarket from '@/views/financial/ServiceIncomeByMarket.vue';
import ServiceIncomeByMarketGraph from '@/views/financial/ServiceIncomeByMarketGraph.vue';

import CostPerServiceByCountry from '@/views/financial/CostPerServiceByCountry.vue';
import CostPerServiceByService from '@/views/financial/CostPerServiceByService.vue';
import CostPerServiceTrend from '@/views/financial/CostPerServiceTrend.vue';

import GmDevByCountry from '@/views/financial/GmDevByCountry.vue';
import GmDevByMarket from '@/views/financial/GmDevByMarket.vue';
import GmDevByCountryGraph from '@/views/financial/GmDevByCountryGraph.vue';
import GmDevByMarketGraph from '@/views/financial/GmDevByMarketGraph.vue';
import ServiceNetWaterfall from '@/views/financial/ServiceNetWaterfall.vue';

import CustomerCostPerFlow from '@/views/financial/CostPerFlow.vue';
import CustomerCostPerFlowMarket from '@/views/financial/CostPerFlowMarket.vue';


export default [
    // {
    //     path: '/finance/cost-per-country-vs-goal',
    //     component: CostPerCountryVsGoal,
    // },
    // {
    //     path: '/finance/cost-per-country-vs-goal/:market',
    //     component: CostPerCountryVsGoalByMarket,
    //     props: true
    // },
    {
        path: '/finance/cost-per-country-ex',
        component: CostPerCountryException,
    },
    {
        path: '/finance/cost-per-country/top-8',
        component: CostPerCountryTop8,
    },
    {
        path: '/finance/cost-per-country/trend/:market',
        component: CostPerCountryByMarket,
        props: true
    },
    {
        path: '/finance/cost-per-country',
        component: CostPerCountry,
        props: true
    },
    // {
    //     path: '/finance/cost-of-internal-damages',
    //     component: CostOfInternalDamages
    // },
    {
        path: '/finance/cost-of-internal-damages/top-8',
        component: CostOfInternalDamagesTop8
    },
    {
        path: '/finance/cost-of-internal-damages/:market/:option?',
        component: CostOfInternalDamagesMarket,
        props: true
    },
    // {
    //     path: '/finance/gross-margin-per-country/by-country/:option',
    //     component: GrossMarginPerCountry,
    //     props: true
    // },
    // {
    //     path: '/finance/gross-margin-per-country/top-8/:option',
    //     component: GrossMarginPerCountryTop8,
    //     props: true
    // },
    // {
    //     path: '/finance/gross-margin-per-country/:market',
    //     component: GrossMarginPerCountryByMarket,
    //     props: true
    // },
    // {
    //     path: '/finance/operational-gross-margin-forecast-vs-goal',
    //     component: OperationalGrossMarginForecastVsGoal,
    // },
    // {
    //     path: '/finance/operational-gross-margin-forecast-vs-goal/:market',
    //     component: OperationalGrossMarginForecastVsGoalByMarket,
    //     props: true
    // },
    // {
    //     path: '/finance/operational-gross-margin',
    //     component: OperationalGrossMargin
    // },
    // {
    //     path: '/finance/operational-gross-margin/trend/:market',
    //     component: OperationalGrossMarginTrend,
    //     props: true
    // },
    // {
    //     path: '/finance/profit-and-loss',
    //     component: ProfitAndLossTotal,
    // },
    {
        path: '/finance/profit-and-loss-per-country',
        component: ProfitAndLossPerCountry,
    },
    {
        path: '/finance/profit-and-loss-total-ingka-retail',
        component: ProfitAndLossTotalIngkaRetail,
    },
    {
        path: '/finance/profit-and-loss-total-ingka-retail-ex-goal',
        component: ProfitAndLossTotalIngkaRetailExceptionGoal,
    },
    {
        path: '/finance/profit-and-loss-total-ingka-retail-ex-ytd',
        component: ProfitAndLossTotalIngkaRetailExceptionYTD,
    },
    // {

    //     path: '/finance/result-per-country-vs-goal',
    //     component: ResultPerCountryVsGoal,
    // },
    // {
    //     path: '/finance/result-per-country-vs-goal/:market',
    //     component: ResultPerCountryVsGoalByMarket,
    //     props: true,
    // },
    {
        path: '/finance/result-contribution-per-country/:market',
        component: ResultContributionPerCountryByMarket,
        props: true,
    },
    {
        path: '/finance/result-contribution-per-country',
        component: ResultContributionPerCountry,
    },
    {
        path: '/finance/transformational-cost',
        component: TransformationalCost,
    },
    // {
    //     path: '/finance/after-sales-delivery-share',
    //     component: AfterSalesDeliveryShare,
    // },
    // {
    //     path: '/finance/after-sales-delivery-share/top-8/:option',
    //     component: AfterSalesDeliveryShareTop8,
    //     props: true
    // },
    // {
    //     path: '/finance/after-sales-delivery-share/:market',
    //     component: AfterSalesDeliveryShareMarket,
    //     props: true,
    // },
    {
        path: '/finance/product-quality',
        component: ProductQualityShare,
    },
    {
        path: '/finance/product-quality/top-8',
        component: ProductQualityShareTop8,
    },
    {
        path: '/finance/product-quality/:market',
        component: ProductQualityShareByMarket,
        props: true,
    },
    // {
    //     path: '/finance/service-net/services-gross-margin',
    //     component: ServiceIncomeByCountry
    // },
    // {
    //     path: '/finance/service-net/services-gross-margin/waterfall/:market',
    //     component: ServiceNetWaterfall,
    //     props: () => ({
    //         location: '/finance/service-net/services-gross-margin'
    //     })
    // },
    // {
    //     path: '/finance/service-net/services-gross-margin/by-market/:market',
    //     component: ServiceIncomeByMarket,
    //     props: true
    // },
    // {
    //     path: '/finance/service-net/services-gross-margin/by-market-graph/:market',
    //     component: ServiceIncomeByMarketGraph,
    //     props: true
    // },
    {
        path: '/finance/service-net/gm-deviation',
        component: GmDevByCountry
    },
    {
        path: '/finance/service-net/gm-deviation/by-market/:market',
        component: GmDevByMarket,
        props: true
    },
    {
        path: '/finance/service-net/gm-deviation/graph',
        component: GmDevByCountryGraph
    },
    {
        path: '/finance/service-net/gm-deviation/by-market-graph/:market',
        component: GmDevByMarketGraph,
        props: true
    },
    {
        path: '/finance/service-net/gm-deviation/waterfall/:market',
        component: ServiceNetWaterfall,
        props: () => ({
            location: '/finance/service-net/gm-deviation'
        })
    },
    {
        path: '/finance/cost-per-service',
        component: CostPerServiceByCountry
    },
    {
        path: '/finance/cost-per-service/by-service/:market',
        component: CostPerServiceByService,
        props: true
    },
    {
        path: '/finance/cost-per-service/:market',
        component: CostPerServiceTrend,
        props: true
    },
    // {
    //     path: '/finance/cost-per-flow',
    //     component: CustomerCostPerFlow,
    // },
    // {
    //     path: '/finance/cost-per-flow/:market',
    //     component: CustomerCostPerFlowMarket,
    //     props: true
    // },
];


