<template>
<div>
    <div class="section three-quarters-height box ms mb">
        <Graph
            v-if="graph"
            :title="`Showing data for: ${$store.getters.selected_country_name}`"
            :show-title="!$store.state.global.bot_mode"
            
            v-bind="graph"
        />
    </div>
</div>
</template>

<script>
import Graph from '@/components/visualisations/bar/Top10Bar_precalculated.vue';

export default {
    components: {
        Graph
    },
    props: {
        graph: Object,
    }
}
</script>

<style>
</style>