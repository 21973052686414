<template>
<div>
    <Title
        title="Operational Gross Margin"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />
        
        <div class="label-container" 
            :class="!$store.getters.market ? 'ft-tooltip': null" 
            :tooltiptext="!$store.getters.market ? 'Select a country from top filter to see stores' : null"
        >
            <p>Market Area</p>
            <DropDown
                :key="stores.values.length"
                :options="stores.options"
                :values="stores.values"
                :default="stores.values[0]"
                @updateDropDown="update_store"
            />
        </div>
        
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
 
    <Table
        v-if="table.render"
        id="operational-gross-margin"
        :headers="table.headers"
        :rows="table.rows"
        :icon-settings="table.icon_settings"
        :decimal-settings="table.decimal_settings"
        :data-settings="{ nullify_zeros: true }"
        :font-settings="{ bold: {row: {regex: 'Total Retail Countries' } } }"
        :sort-settings="{ 'enabled': false }"
        :metaSettings="{is_selectable: false}"
        
        class="ms1p5"
    />
    <div class="text-left ml">*Gross Profit Incl. Portfolios & Agreed Overspend</div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: [],
                icon_settings: {},
                decimal_settings: {},
                render: false
            },
            store: null,
            location: '/finance/operational-gross-margin',
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.load_ingka_by_country();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: `${this.location}`
                },
                {
                    name: 'Trend',
                    link: `${this.location}/trend/${this.$store.getters.market}`
                }
            ];
        },
        load_ingka_by_country: function() {
            this.table.render = false;
            let url = '/finance/operational-gross-margin/total-ingka-by-country';
            let succ = (succ) => {
                let d = succ.data;
                if (d[0].measure_metadata) {
                        this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                    }
                this.table.headers, this.table.rows = [];

                let keys = Object.keys(d[0].measures[Object.keys(d[0].measures)[0]]);

                Object.keys(d[0].measures).forEach(row => {
                    Object.keys(d[0].measures[row]).forEach(key => {
                        if (keys.indexOf(key) === - 1) {
                            keys.push(key);
                        }
                    });
                });
                
                this.table.headers = [[''].concat(keys).map(c => {
                    let h = {};
                    h[c] = 1;
                    return h;
                })];

                Object.keys(d[0].measures).forEach(row => {
                    let temp = [];
                    // If the key/values don't exist, set them to 0
                    temp.push(row);
                    keys.map(key => {
                        temp.push(this.$helper.maybe(d[0].measures[row][key]));
                    });
                    this.table.rows.push(temp)
                });
                this.table.icon_settings = {
                    'Diff Goal-YTD': {
                        fix: { post: '%' }
                    },
                    'Year Goal': {
                        fix: { post: '%' }
                    },
                    'YTD': {
                        fix: { post: '%' },
                        comparison: 'Year Goal',
                        icon: 'circle'
                    },
                    '4WK Trend': {
                        fix: { post: '%' },
                        comparison: 'YTD',
                        icon: 'arrow'                       
                    },
                    'FC': {
                        fix: { post: '%' }                   
                    }
                }
                this.$helper.header_replace(this.table.headers, {
                    '4 Weeks': '4WK Trend'
                });
                if ('4 Weeks' in this.table.decimal_settings) {
                    this.table.decimal_settings['4WK Trend'] = this.table.decimal_settings['4 Weeks'];
                }
                this.table.render = true;
            }
            let error = (error) => console.log(error);
            if (this.store === null) {
                if (this.$store.getters.market === null) {
                    this.$http.post(url, {parent: 'TOP', market: 'ALL'}).then(succ, error);
                } else {
                    this.$http.post(url, {parent: 'ALL', market: `${this.$store.getters.market}`}).then(succ, error);
                }
            } else {
                this.$http.post(url, {parent: `${this.$store.getters.market}`, market: `${this.store}`}).then(succ, error);
            }
        },
        update_store: function(store) {
            this.store = store;
            this.load_ingka_by_country();
        },
        update_view: function(market) {
            this.load_ingka_by_country();
            
            if (market != null) {
                this.$store.commit('update_view', {
                    market: market,
                    location: this.location
                });
            } else {
                this.$store.commit('update_view', {
                    market: market,
                    location: `${this.location}/null`
                });
            }
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        },
        '$store.getters.stores': function(newStores, oldStores) {
            return this.$store.getters.stores;
        }
    },
    computed: {
        stores: function() {
            if (this.$store.getters.stores.values.length) {
                return this.$store.getters.stores;
            } else {
                return {
                    all: [],
                    options: [],
                    values: []
                }
            }
        }
    }
}
</script>