var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "kpi-box" },
    _vm._l(_vm.frequency, function (update_frequency, i) {
      return _c("div", { key: i }, [
        _vm.kpi_data && _vm.kpi_data[update_frequency]
          ? _c(
              "table",
              { staticClass: "table is-striped is-fullwidth is-narrow" },
              [
                _c("thead", [
                  _c(
                    "tr",
                    { staticClass: "ikea-yellow" },
                    _vm._l(
                      [
                        { label: update_frequency, value: 1 },
                        ..._vm.headers(update_frequency)[0],
                      ],
                      function (header, key) {
                        return _c(
                          "th",
                          { key: key, attrs: { colspan: header.value } },
                          [_vm._v(" " + _vm._s(header.label) + " ")]
                        )
                      }
                    ),
                    0
                  ),
                ]),
                _c(
                  "tbody",
                  [
                    _vm._l(
                      _vm.chunk_data(update_frequency),
                      function (data_grouped_by_headers) {
                        return [
                          data_grouped_by_headers[0].headers[1]
                            ? _c(
                                "tr",
                                [
                                  _c("td"),
                                  _vm._l(
                                    data_grouped_by_headers[0].headers[1],
                                    function (sub_header, key) {
                                      return _c(
                                        "td",
                                        { key: key, staticClass: "sub-header" },
                                        [
                                          _vm._v(
                                            " " + _vm._s(sub_header.label) + " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._l(data_grouped_by_headers, function (row, key) {
                            return _c(
                              "tr",
                              { key: key },
                              [
                                _c(
                                  "td",
                                  {
                                    staticClass: "name-column",
                                    class: `${row.version ? "ft-tooltip" : ""}`,
                                    attrs: { tooltiptext: row.version || "" },
                                  },
                                  [_vm._v(" " + _vm._s(row.name) + " ")]
                                ),
                                _vm._l(row.data_keys, function (value, index) {
                                  return _c(
                                    "td",
                                    {
                                      key: index,
                                      staticClass: "data-column text-left",
                                    },
                                    [
                                      `icon_${value}` in row.icon_values
                                        ? _c(
                                            "div",
                                            { staticClass: "inline-flex" },
                                            [
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$helper.round(
                                                      row.measures[value],
                                                      null,
                                                      ","
                                                    )
                                                  ) +
                                                    _vm._s(
                                                      row.measures[value]
                                                        ? row.append
                                                        : ""
                                                    )
                                                ),
                                              ]),
                                              _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.icon_logic(
                                                      row.icon_values,
                                                      value
                                                    )
                                                  ),
                                                },
                                              }),
                                            ]
                                          )
                                        : _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$helper.round(
                                                    row.measures[value],
                                                    null,
                                                    ","
                                                  )
                                                ) +
                                                _vm._s(
                                                  row.measures[value]
                                                    ? row.append
                                                    : ""
                                                ) +
                                                " "
                                            ),
                                          ]),
                                    ]
                                  )
                                }),
                              ],
                              2
                            )
                          }),
                        ]
                      }
                    ),
                  ],
                  2
                ),
              ]
            )
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }