<template>
<div>
    <Title
        title="Product Quality Costs"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container">
            <p>Filter</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <!-- <div :key="$store.getters.page_size" class="section three-quarters-height box ms mb">
        <Top8Bar
            v-if="top_8.render"

            title="Product Quality Share Top 10 Countries"
            :showtitle="!$store.state.global.bot_mode"
            subtitle=""
            :xcategories="top_8.xcategories"
            :yaxislabel="filter"
            ymetric="%"

            seriesname1="YTD"
            seriesname3="Month Trend"
            seriesname4="Year Goal"

            seriestype1="bar"
            seriestype2="scatter"
            seriestype3="scatter"
            seriestype4="scatter"

            :seriesset1="top_8.seriesset1"
            :color1="$store.state.colors['yellow']"

            :seriesset3="top_8.seriesset3"
            :color3="$store.state.colors['grey']"

            :seriesset4="top_8.seriesset4"
            :color4="$store.state.colors.YG"

            :trend="true"
            :ranking="[]"
            :competitors="[]"
        />
    </div> -->
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Top8Bar from '@/components/visualisations/bar/Top8Bar.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Top8Bar,
        Title
    },
    data: function() {
        return {
            top_8: {
                render: false,

                xcategories: [],

                seriesset1: [],                
                seriesset2: [],
                seriesset3: [],
                seriesset4: []
            },
            location: '/finance/product-quality',
            options: [],
            values: [],
            filter: null,
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                }
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'pq-cost-type-1'
            }).then(
                succ => {
                    succ.data.map(filter => {
                        this.values.push(filter.value);
                        this.options.push(filter.option);
                    });
                    //first time right filter selecting - ben 29/06/2020
                    let index = this.values.indexOf('First Time Right');
                    this.filter = this.values.indexOf(this.$store.state.global.temp_filter) > 0 ? this.$store.state.global.temp_filter : this.values[index !== -1 ? index : 0];
                },
                this.$helper.error
            );
            this.load_ingka_by_country();
        },
        load_ingka_by_country: function() {
            this.top_8.render = false;
            if (!this.filter) return;
            this.$http.post(`/finance/product-quality/top-8`, {filter: this.filter}).then(
                succ => {
                    let d = succ.data;
                    if (!d) return;
                    this.top_8.xcategories = [];
                    this.top_8.seriesset1 = [];
                    this.top_8.seriesset2 = [];
                    this.top_8.seriesset3 = [];
                    this.top_8.seriesset4 = [];

                    d.map(row => {
                        this.top_8.xcategories.push(row.caption);
                        this.top_8.seriesset1.push(this.$helper.maybe(row.measures)['YTD']);
                        this.top_8.seriesset3.push(this.$helper.maybe(row.measures)['Month Trend']);
                        this.top_8.seriesset4.push(this.$helper.maybe(row.measures)['Goal']);
                    });

                    this.top_8.render = true;
                },
                this.$helper.error
            );
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.top_8.render = false;
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        },
        filter: function() {
            this.load_ingka_by_country();
        } 
    }
}
</script>