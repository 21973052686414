var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: { title: "Cost of Internal Damages", location: _vm.location },
      }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons(), dataset: _vm.table },
          }),
          _c(
            "div",
            { staticClass: "label-container" },
            [
              _c("p", [_vm._v("Filter")]),
              _c("DropDown", {
                key: _vm.filter,
                attrs: {
                  options: _vm.options,
                  values: _vm.values,
                  default: _vm.filter,
                },
                on: { updateDropDown: _vm.update_filter },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                key: _vm.$store.getters.market,
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: _vm.$store.getters.market,
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.table.render
        ? _c("Table", {
            staticClass: "ms1p5",
            attrs: {
              headers: _vm.table.headers,
              rows: _vm.table.rows,
              "icon-settings": _vm.table.icon_settings,
              "data-settings": { nullify_zeros: true },
              "font-settings": {
                bold: { row: { regex: "Total Retail Countries" } },
              },
              "sort-settings": { Country: { asc: true } },
              "decimal-settings": _vm.table.decimal_settings,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }