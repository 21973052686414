<template>
<div>
    <Title
        title="Store Fulfilment (Flows 7-8)"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="is-pulled-left mt2"
        />

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                default=""
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms mb">
        <Top8Bar
            :key="top_8.render"

            title="Store Fulfilment Top 10 Countries"
            :showtitle="!$store.state.global.bot_mode"
            subtitle=""
            :xcategories="top_8.xcategories"
            yaxislabel="Store Distribution M3 (markets)"
            ymetric=""

            seriesname1="YTD avg per month"
            
            seriesname4="Required remaining monthly average"

            seriestype1="bar"
            seriestype2="scatter"
            seriestype3="scatter"
            seriestype4="scatter"

            :seriesset1="top_8.seriesset1"
            :color1="$store.state.colors['yellow']"
            
            :seriesset4="top_8.seriesset3"
            :color4="$store.state.colors.YG"

            
        />
        <!-- 
        seriesname3="Latest Month"  - Ben 19/11/2020 
        :seriesset3="top_8.seriesset2"
        :color3="$store.state.colors['grey']"
        :trend="true"
        :ranking="[]"
        :competitors="[]" --> 
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Top8Bar from '@/components/visualisations/bar/Top8Bar.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Title,
        Top8Bar
    },
    props: {
        market: String
    },
    data: function() {
        return {
            top_8: {
                render: false,

                xcategories: [],

                seriesset1: [],                
                seriesset2: [],
                seriesset3: [],
                seriesset4: []
            },
            location: '/business/store-fulfilment'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.load_market_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: `${this.location}-7-8`
                },
                {
                    name: 'Trend',
                    link: `${this.location}-7-8/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}-7-8/top-8`
                },
            ];
        },
        load_market_data: function() {
            this.$http.get('/business/store-fulfilment/top-8-7-8').then(
                succ => {
                    let d = succ.data;
                    d.map(row => {
                        this.top_8.xcategories.push(row.caption);
                        this.top_8.seriesset1.push(row.measures['Month']);
                        // this.top_8.seriesset2.push(row.measures['Month LY']); commented for when data comes back - Ben 19/11/2020
                        this.top_8.seriesset3.push(row.measures['Required remaining monthly average']);
                    });

                    this.top_8.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: `${this.location}-7-8`
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        }
    }
}
</script>
