<template>
<div>
    <div v-if="render_graph" :id="id" class="section half-height box ms mb">
        <Graph
            v-if="graphs && selected_graph"
            :title="`Showing data for: ${$store.getters.selected_country_name}`"
            :show-title="!$store.state.global.bot_mode"
            
            v-bind="selected_graph"
        />
    </div>
</div>
</template>

<script>
import Graph from '@/components/visualisations/general/Graph.vue';

export default {
    components: {
        Graph
    },
    props: {
        graphs: Object,
        selected_measure: String,
        id: String
    },
    data: function() {
        return {
            selected_graph: null,
            render_graph: true
        }
    },
    mounted: function() {
        if (!this.graphs) return;
        this.selected_graph = this.graphs[ Object.keys(this.graphs).sort()[0] ]
    },
    watch: {
        selected_measure: function() {
            if (!this.selected_measure || !this.graphs[this.selected_measure]) return;
            this.selected_graph = this.graphs[this.selected_measure];
            this.render_graph = false;
            setTimeout(() => this.render_graph = true, 0); // This can be improved but it is a work around to render toggling in a single function
        }
    }
}
</script>

<style>

</style>
