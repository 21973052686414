var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "label-container" }, [
    _vm.label ? _c("p", [_vm._v(_vm._s(_vm.label))]) : _vm._e(),
    _c("div", { staticClass: "select" }, [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.current_selection,
              expression: "current_selection",
            },
          ],
          on: {
            change: [
              function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.current_selection = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              _vm.update,
            ],
          },
        },
        _vm._l(_vm.options, function (option, index) {
          return _c(
            "option",
            { key: index, domProps: { value: _vm.values[index] } },
            [_vm._v(" " + _vm._s(option) + " ")]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }