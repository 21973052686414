var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: { title: "Services Net Waterfall", location: _vm.location },
      }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons() },
          }),
          _c(
            "div",
            { staticClass: "label-container" },
            [
              _c("p", [_vm._v("Filter")]),
              _c("DropDown", {
                key: _vm.filter.comparison.state,
                attrs: {
                  options: _vm.filter.comparison.options,
                  values: _vm.filter.comparison.values,
                  default: _vm.filter.comparison.state,
                },
                on: { updateDropDown: _vm.update_filter_comparison },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "label-container" },
            [
              _c("p", [_vm._v("After Sales")]),
              _c("DropDown", {
                key: _vm.filter.after_sales.state,
                attrs: {
                  options: _vm.filter.after_sales.options,
                  values: _vm.filter.after_sales.values,
                  default: _vm.filter.after_sales.state,
                },
                on: { updateDropDown: _vm.update_filter_after_sales },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size,
          staticClass: "section three-quarters-height box ms mb",
        },
        [
          _vm.graph.render
            ? _c("Waterfall", {
                attrs: {
                  title: "Gross Margin Waterfall",
                  showtitle: true,
                  seriesset: _vm.seriesset,
                  yaxislabel: "",
                  ymetric: "",
                  seriesname: "GM Deviation",
                  seriestype: "bar",
                  totals: this.filter_split,
                  remove: ["GM YTD", "GM"],
                  show: true,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }