var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "title" }, [_vm._v(" DATA LOAD TRACKER ")]),
    _c(
      "div",
      [
        _c("span", { staticClass: "sorting" }, [_vm._v("Sort by")]),
        _c("DropDown", {
          attrs: {
            default: _vm.sort_key,
            options: _vm.sort_options,
            values: _vm.sort_values,
          },
          on: { updateDropDown: _vm.update_sort },
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.search_str,
              expression: "search_str",
            },
          ],
          staticClass: "input",
          attrs: { placeholder: "Search KPIs" },
          domProps: { value: _vm.search_str },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.search_str = $event.target.value
            },
          },
        }),
        _vm.debug_mode
          ? _c(
              "button",
              { staticClass: "button is-info ml", on: { click: _vm.get_data } },
              [_vm._v("Refresh")]
            )
          : _vm._e(),
        _c(
          "button",
          {
            staticClass: "button ikea-blue has-text-white ml mr",
            on: {
              click: function ($event) {
                return _vm.toggle_comments()
              },
            },
          },
          [
            _vm._v(
              _vm._s(_vm.display_comments.length ? "Hide" : "Show") +
                " comments"
            ),
          ]
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.display_front_page_only,
              expression: "display_front_page_only",
            },
          ],
          attrs: { type: "checkbox", id: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.display_front_page_only)
              ? _vm._i(_vm.display_front_page_only, null) > -1
              : _vm.display_front_page_only,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.display_front_page_only,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.display_front_page_only = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.display_front_page_only = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.display_front_page_only = $$c
              }
            },
          },
        }),
        _c("label", { attrs: { for: "checkbox" } }, [
          _vm._v("Show frontpage KPIs only"),
        ]),
      ],
      1
    ),
    _c("div", [
      _c(
        "table",
        { staticClass: "table" },
        [
          _c("thead", { staticClass: "ikea-yellow" }, [
            _c("tr", [
              _c("th", { attrs: { rowspan: "2" } }, [_vm._v("KPI")]),
              _c("th", { attrs: { rowspan: "2" } }, [_vm._v("Frequency")]),
              _c("th", { attrs: { rowspan: "2" } }, [_vm._v("Type")]),
              _c("th", { attrs: { colspan: "2" } }, [
                _vm._v("Processing date "),
                _c(
                  "span",
                  {
                    staticClass: "tooltip cursor-pointer",
                    attrs: { "data-tooltip": _vm.processing_description },
                  },
                  [_c("i", { staticClass: "fas fa-question-circle" })]
                ),
              ]),
              _c("th", { attrs: { rowspan: "2" } }, [
                _vm._v("Current Version"),
              ]),
              _vm.debug_mode
                ? _c("th", { attrs: { rowspan: "2" } }, [
                    _vm._v("Expected Version"),
                  ])
                : _vm._e(),
              _c("th", { attrs: { rowspan: "2" } }, [_vm._v("Status")]),
            ]),
            _vm._m(0),
          ]),
          _vm._l(_vm.filtered_table_data, function (row, i) {
            return [
              _c("tr", { key: 2 * i }, [
                _c(
                  "td",
                  {
                    class: {
                      unfolded:
                        _vm.display_comments.includes(i) && row.load_comment,
                    },
                  },
                  [
                    _vm._v(" " + _vm._s(row.report) + " "),
                    row.load_comment
                      ? _c(
                          "span",
                          {
                            staticClass: "ml tooltip cursor-pointer",
                            attrs: { "data-tooltip": "View comment" },
                            on: {
                              click: function ($event) {
                                return _vm.toggle_comments(i)
                              },
                            },
                          },
                          [_c("i", { staticClass: "fas fa-comment-dots" })]
                        )
                      : _vm._e(),
                  ]
                ),
                _c(
                  "td",
                  {
                    class: {
                      unfolded:
                        _vm.display_comments.includes(i) && row.load_comment,
                    },
                  },
                  [_vm._v(_vm._s(row.frequency_label))]
                ),
                _c(
                  "td",
                  {
                    class: {
                      unfolded:
                        _vm.display_comments.includes(i) && row.load_comment,
                    },
                  },
                  [_vm._v(_vm._s(row.load_type))]
                ),
                _c(
                  "td",
                  {
                    staticClass: "has-text-right",
                    class: {
                      unfolded:
                        _vm.display_comments.includes(i) && row.load_comment,
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        row.processing_start
                          ? row.processing_start.toUTCString().slice(0, 16)
                          : ""
                      )
                    ),
                  ]
                ),
                _c(
                  "td",
                  {
                    staticClass: "has-text-right",
                    class: {
                      unfolded:
                        _vm.display_comments.includes(i) && row.load_comment,
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        row.processing_end
                          ? row.processing_end.toUTCString().slice(0, 16)
                          : ""
                      )
                    ),
                  ]
                ),
                _c(
                  "td",
                  {
                    class: {
                      unfolded:
                        _vm.display_comments.includes(i) && row.load_comment,
                    },
                  },
                  [_vm._v(_vm._s(row.formatted_current_version))]
                ),
                _vm.debug_mode
                  ? _c(
                      "td",
                      {
                        class: {
                          unfolded:
                            _vm.display_comments.includes(i) &&
                            row.load_comment,
                        },
                      },
                      [_vm._v(_vm._s(row.formatted_expected_version))]
                    )
                  : _vm._e(),
                _c(
                  "td",
                  {
                    staticClass: "has-text-centered",
                    class: [
                      row.status_indicator,
                      {
                        unfolded:
                          _vm.display_comments.includes(i) && row.load_comment,
                      },
                    ],
                  },
                  [_c("i", { staticClass: "fas fa-circle" })]
                ),
              ]),
              row.load_comment && _vm.display_comments.includes(i)
                ? _c("tr", { key: 2 * i + 1 }, [
                    _c("td", { staticClass: "comment-title comment" }, [
                      _vm._v("Comment:"),
                    ]),
                    _c(
                      "td",
                      { staticClass: "comment", attrs: { colspan: "6" } },
                      [_vm._v(_vm._s(row.load_comment))]
                    ),
                  ])
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_vm._v("Start")]), _c("th", [_vm._v("End")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }