<template>
<div>
    <Title
        title="Sales Planning Precision"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <Table
        v-if="table.render"
        :headers="table.headers"
        :rows="table.rows"
        :icon-settings="table.icon_settings"
        :decimal-settings="table.decimal_settings"
        :data-settings="{ nullify_zeros: true }"
        :font-settings="{ bold: {row: {regex: 'Total Retail Countries' } } }"
        :sort-settings="{ 'Families within bracket': { asc: true } }"
        
        class="ms1p5"
    />
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';


export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: [],
                icon_settings: {},
                decimal_settings: {},
                render: false
            },
            location: '/process/sales-planning-precision',
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.load_ingka_by_country();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Top 10 Highest Precision',
                    link: `${this.location}/top-10-highest-precision`
                },
                {
                    name: 'Top 10 Lowest Precision',
                    link: `${this.location}/top-10-lowest-precision`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                }
            ];
        },
        load_ingka_by_country: function() {
            this.table.render = false;
            this.$http.get(`/process/sales-planning-precision/total`).then(
                succ => {
                    let d = succ.data.filter(measure => measure.market !== "" && measure.market !== null);
                    if (d[0].measure_metadata) {
                        this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                    }
                    let keys = Object.keys(d[0].measures);
                    this.table.headers = [['Country'].concat(keys).map(c => {
                        return {
                            [c]: 1
                        }
                    })];
                    this.table.rows = d.map(row => {
                        let temp = [];
                        // If the key/values don't exist, set them to 0
                        keys.map(key => {
                            temp.push(this.$helper.maybe(row.measures[key]));
                        });
                        return [row.caption].concat(temp);
                    });
                    this.table.icon_settings = {
                        'Families within bracket': {
                            fix: { post: '%' },
                            icon: 'circle',
                            comparison: 'Ambition',
                        },
                        'Ambition': {
                            fix: { post: '%' },
                        },
                        'Yearly impact of QTY below bracket (MQTY)': {
                            icon: 'bar',
                            comparison: 'Yearly impact of QTY below bracket (MQTY)',
                            ignore: ['Total Retail Countries']
                        },
                        'Yearly impact of QTY above bracket (MQTY)': {
                            icon: 'bar',
                            comparison: 'Yearly impact of QTY above bracket (MQTY)',
                            ignore: ['Total Retail Countries']
                        }
                    }
                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        }
    }
}
</script>
