<template>
<div>
    <Title
        title="Services Gross Margin By Service Graph"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
        <div class="label-container">
            <p>Actuality</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>      
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms mb">
        <QuadStackWithLine
            v-if="quadStack.render"

            title="Service Income, Cost and Net"
            subtitle=""
            :showtitle="!$store.state.global.bot_mode"

            :xcategories="quadStack.xcategories"
            yaxislabel=""
            ymetric=""
            
            type="bar"
            :color1="$store.state.colors['yellow']"
            color2="#fcba03"
            color3="#787878"
            color4="#000000"

            seriesname1="Income"
            :seriesset1="quadStack.seriesset1"
            seriestype1="bar"
            
            seriesname2="Commercial Cost"
            :seriesset2="quadStack.seriesset2"
            seriestype2="bar"
            
            seriesname3="After sales cost"
            :seriesset3="quadStack.seriesset3"
            seriestype3="bar"

            seriesname4="Total Net"
            :seriesset4="quadStack.seriesset4"
            seriestype4="line"

            :gridbottom="-5"
            :xaxisrotation="35"
            :xaxisfontsize="10"

            :hidebarlabel="true"
        />
    </div>
    <div class="section three-quarters-height box ms mb">
        <BubbleGraph
            v-if="bubble.render"

            title="Service Net and Gm per Service Type"
            sub-title="Size based on Income"
            :show-title="!$store.state.global.bot_mode"
            
            y-axis-label="Gm in %"
            y-metric="%"
            x-axis-label="Service net in Euro"
            size-label="Income"

            :datasets="bubble.datasets"
            :names="bubble.names"
            :colors="bubble.colors"
        />
    </div>
</div>
</template>

<script>
import BubbleGraph from '@/components/visualisations/general/Bubble.vue'
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import QuadStackWithLine from '@/components/visualisations/general/QuadStackWithLine.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        BubbleGraph,
        Buttons,
        DropDown,
        Title,
        QuadStackWithLine
    },
    props: {
        market: String
    },
    data: function() {
        return {
            quadStack: {
                xcategories: [],
                
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],
                seriesset4: [],
                
                render: false
            },
            bubble: {
                datasets: [],
                names: [],
                colors: ['#D07C7A', '#B1CA7E', '#9F89B8', '#76C0D3', '#F9AF72', '#5E7896', '#985F5D', '#849560', '#786A87', '#5B8D9C', '#FF0000'],
                render: false
            },
            filter: null,
            options: [],
            values: [],
            location: '/finance/service-net/services-gross-margin'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'By Service',
                    link: `${this.location}/by-market/${this.$store.getters.market}`
                },
                {
                    name: 'Waterfall',
                    link: `${this.location}/waterfall/${this.$store.getters.market}`
                },
                {
                    name: 'By Service (Graph)',
                    link: `${this.location}/by-market-graph/${this.$store.getters.market}`
                }
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'service-net-actuality'
            }).then(
                succ => {
                    succ.data.map(filter => {
                        this.values.push(filter.value);
                        this.options.push(filter.option);
                    });
                    this.filter = this.$store.getters.filter_check(this.values);
                    this.$http.post('/filters/load', {
                        filter: 'service-net-service',
                        disable_sort: true
                    }).then(
                        succ => {
                            succ.data.map(filter => {
                                this.bubble.datasets.push(filter.value);
                            });
                            this.bubble.datasets.shift();

                            if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
                            this.load_by_market_data();
                            this.load_service_net_gm_per_service();
                        },
                        this.$helper.error
                    )
                },
                this.$helper.error
            );
        },
        load_by_market_data: function() {
            this.quadStack.render = false;
            this.$http.post('/finance/service-net-dashboard/service-income-cost-net', {
                market: this.$store.getters.market,
                actuality: this.filter
            }).then(
                succ => {
                    if (!succ.data || !succ.data[0] || !succ.data[0].measures) return;
                    let d = succ.data[0].measures;
                    
                    this.quadStack.xcategories = [];
                    this.quadStack.seriesset1 = [];
                    this.quadStack.seriesset2 = [];
                    this.quadStack.seriesset3 = [];
                    this.quadStack.seriesset4 = [];
                    
                    Object.keys(d).map(service => {
                        let val = d[service].pop();
                        let data_names = ['income', 'cost_commercial_ty', 'cost_after_sales_ty', 'total_service_net_ty'];
                        if (!service || !d[service]) return;
                        this.quadStack.xcategories.push(service);
                        data_names.forEach( (d_name, i) => {
                            if (!val[d_name]) {
                                this.quadStack[`seriesset${i + 1}`].push(0);
                            } else {
                                this.quadStack[`seriesset${i + 1}`].push(val[d_name]);
                            }
                        });
                    });
                    this.quadStack.render = true;
                },
                this.$helper.error
            );
        },
        load_service_net_gm_per_service: function() {
            this.bubble.render = false;
            this.$http.post('/finance/service-net-dashboard/service-net-gm-per-service', {
                market: this.$store.getters.market,
                actuality: this.filter
            }).then(
                succ => {
                    if (!succ.data || !succ.data[0] || !succ.data[0].measures) return;
                    let d = succ.data[0].measures;
                    this.bubble.datasets = [];
                    this.bubble.names = [];

                    let max_income = 0;
                    Object.keys(d).forEach(service => {
                        max_income = d[service][0].income > max_income ? d[service][0].income : max_income;
                    });
                    Object.keys(d).forEach(service => {
                        if (!service in d || !d[service]) return;
                        let val = d[service][0];
                        this.bubble.names.push(service);
                        let gm = 'gm' in val ? val['gm'] : null;
                        let income = 'income' in val ? val['income'] : null;
                        let total_service_net_ty = 'total_service_net_ty' in val ? val['total_service_net_ty'] : null;

                        this.bubble.datasets.push(
                            {
                                name: service,
                                values: {
                                    x: total_service_net_ty,
                                    y: gm,
                                    display_size: income / max_income * 150,
                                    size: income
                                }
                            }
                        );
                    });
                    this.bubble.render = true;
                },
                this.$helper.error
            );
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.load_by_market_data();
            this.load_service_net_gm_per_service();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: `${this.location}/by-market-graph`
            });
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.load_by_market_data();
            this.load_service_net_gm_per_service();
        }
    }
}
</script>

