<template>
<div>
    <router-link class="button mb" to="/admin/user-management">Back</router-link>
    <a class="button is-fullwidth mb" @click="save_template">Save Template</a>
    <div class="columns is-gapless">
        <div class="column is-2">
            Name
        </div>
        <div class="column">
            <input class="input mb" v-model="template_name" />
        </div>
    </div>
    <AccessTemplate
        :data="access_template"
        @update="update"
    />
</div>
</template>

<script>
import AccessTemplate from '@/components/admin/access-templates/AccessTemplate.vue';
export default {
    props: {
        template_id: Number
    },
    components: {
        AccessTemplate
    },
    data: function() {
        return {
            access_template: null,
            template_name: null
        }
    },
    methods: {
        save_template: function() {
            // Update access template
            this.$http.post(`/admin/user-management/access-templates/create`, {
                name: this.template_name,
                access_template: this.access_template
            }).then(
                succ => {
                    console.log(succ.data);
                },
                this.$helper.error
            );
        },
        update: function(template) {
            this.access_template = template;
        }
    }
}
</script>

<style>

</style>