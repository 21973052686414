var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { display: "inline-flex" } }, [
    _vm.label
      ? _c("div", { staticClass: "mr" }, [_vm._v(_vm._s(_vm.label))])
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "switch-container cursor-pointer",
        style: _vm.toggle_item
          ? "background-color: #23d160;"
          : "background-color: #d3d3d3",
        on: {
          click: function ($event) {
            return _vm.toggle_switch()
          },
        },
      },
      [_c("div", { staticClass: "switch", attrs: { id: _vm.element_id } })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }