<template>
<div>
    <DropDown
        default="LATEST"
        :options="options"
        :values="values"
        @updateDropDown="update_order_by"
        class="is-fullwidth"
    />
    <input type="textinput" class="input mt" :class="render_style" v-model="filters.search" />
</div>
</template>

<script>
import DropDown from '@/components/ui/DropDown.vue';
export default {
    props: {
        ci: Boolean
    },
    components: {
        DropDown,
    },
    data: function() {
        return {
            options: ['Oldest', 'Latest'],
            values: ["EARLIEST", "LATEST"],
            filters: {
                search: null,
                order_by: null
            }
        }
    },
    methods: {
        update_order_by: function(value) {
            this.filters.order_by = value;
        }
    },
    computed: {
        render_style: function() {
            return {
                'mb': this.ci !== true
            }
        }
    },
    watch: {
        'filters.order_by': function(newVal, oldVal) {
            this.$emit('update', this.filters);
        },
        'filters.search': function(newVal, oldVal) {
            this.$emit('update', this.filters);
        }
    }
}
</script>