<template>
<div>
    <editor-menu-bar class="editor" :editor="editor" v-slot="{ commands, isActive }">
        <div class="editor-menu">
            <button class="button" :class="{ 'is-active': isActive.bold() }" @click="commands.bold">
                <i class="fas fa-bold"></i>
            </button>

            <button class="button" :class="{ 'is-active': isActive.italic() }" @click="commands.italic">
                <i class="fas fa-italic"></i>
            </button>

            <button class="button" :class="{ 'is-active': isActive.underline() }" @click="commands.underline">
                <i class="fas fa-underline"></i>
            </button>

            <button class="button" :class="{ 'is-active': isActive.strike() }" @click="commands.strike">
                <i class="fas fa-strikethrough"></i>
            </button>

            <button class="button" :class="{ 'is-active': isActive.bullet_list() }" @click="commands.bullet_list">
                <i class="fas fa-list-ul"></i>
            </button>

            <button class="button" :class="{ 'is-active': isActive.ordered_list() }" @click="commands.ordered_list">
                <i class="fas fa-list-ol"></i>
            </button>

        </div>
    </editor-menu-bar>
    <editor-content id="editor" class="editor-content" :editor="editor" />
</div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import {
    Blockquote,
    CodeBlock,
    HardBreak,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Link,
    Strike,
    Underline,
    History, 
    Placeholder
} from 'tiptap-extensions'

export default {
    props: {
        content: String
    },
    components: {
        EditorContent,
        EditorMenuBar
    },
    data: function() {
        return {
            editor: new Editor({
                autoFocus: true,
                content: this.content,
                extensions: [
                    new Blockquote(),
                    new BulletList(),
                    new CodeBlock(),
                    new HardBreak(),
                    new Heading(),
                    new HorizontalRule(),
                    new ListItem(),
                    new OrderedList(),
                    new TodoItem(),
                    new TodoList(),
                    new Link(),
                    new Bold(),
                    new Code(),
                    new Italic(),
                    new Strike(),
                    new Underline(),
                    new History(),
                    new Placeholder({
                        showOnlyCurrent: false,
                        emptyNodeText: node => {
                            return 'Write a message here';
                        }
                    })
                ],
                onUpdate: ({getHTML}) => { 
                    this.$emit('textUpdate', getHTML());
                }
            }),
        }
    },
    mounted: function() {
    },
    beforeDestroy() {
        // Always destroy your editor instance when it's no longer needed
        this.editor.destroy();
    },
    methods: {
        clear: function() {
        }
    }
}
</script>

<style>
.editor-content {
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 0.5em;
}

.editor-menu button {
    margin: 0.2em;
}

#editor .ProseMirror {
    text-align: left;
    padding: 1em;
}
.ProseMirror ul {
    list-style: disc;
}
.ProseMirror ul li {
    margin-left: 1rem;
    margin-bottom: 1rem;
}
.ProseMirror ol li {
    margin-left: 1rem;
    margin-bottom: 1rem;
}
</style>