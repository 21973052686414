var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: {
          title: "People + Planet Positive Sales",
          location: _vm.location,
        },
      }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons() },
          }),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: "",
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size,
          staticClass: "section three-quarters-height box ms mb",
        },
        [
          _c("Top8Bar", {
            key: _vm.top_8.render,
            attrs: {
              title: "Sustainability Sales Top 10 Countries",
              showtitle: !_vm.$store.state.global.bot_mode,
              yaxislabel: "Share of Total Sales",
              ymetric: "%",
              xcategories: _vm.top_8.xcategories,
              seriesname1: "YTD",
              seriestype1: "bar",
              color1: _vm.$store.state.colors["yellow"],
              seriesset1: _vm.top_8.seriesset1,
              seriestype2: "scatter",
              seriesname3: "1WK Trend",
              seriestype3: "scatter",
              color3: _vm.$store.state.colors["grey"],
              seriesset3: _vm.top_8.seriesset2,
              seriesname4: "Year Goal",
              seriestype4: "scatter",
              color4: _vm.$store.state.colors.YG,
              seriesset4: _vm.top_8.seriesset4,
              trend: true,
              ranking: _vm.top_8.ranking,
              competitors: [],
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }