var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "title-section" }, [
      _c("div", { staticClass: "columns" }, [
        _c("div", { staticClass: "column insights-title text-left" }, [
          _vm._v(_vm._s(_vm.formatted_country_name)),
        ]),
        _c("div", { staticClass: "column insights-title text-center" }, [
          _vm._v("VCG Insights"),
        ]),
        _c("div", { staticClass: "column" }, [
          _vm.retail_manager.first_name && _vm.retail_manager.last_name
            ? _c("div", { staticClass: "retail-manager" }, [
                _c("b", [_vm._v("Retail Manager:")]),
                _c("br"),
                _vm._v(
                  _vm._s(_vm.retail_manager.first_name) +
                    " " +
                    _vm._s(_vm.retail_manager.last_name)
                ),
              ])
            : _c("div", { staticClass: "retail-manager" }, [
                _vm._v("No retail manager set."),
              ]),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !this.$store.getters.presentation_mode,
              expression: "!this.$store.getters.presentation_mode",
            },
          ],
          staticClass: "columns is-gapless insights-tabs",
        },
        _vm._l(_vm.tabs, function (tab) {
          return _c(
            "div",
            {
              key: tab.link,
              staticClass: "column tab",
              class: _vm.selected_kpi_area === tab.link ? "active" : "",
              on: {
                click: function ($event) {
                  _vm.selected_kpi_area = tab.link
                },
              },
            },
            [_c("div", [_vm._v(_vm._s(tab.name))])]
          )
        }),
        0
      ),
    ]),
    _c("div", { staticClass: "button-list" }, [
      _c("div", { staticClass: "columns is-gapless button-set" }, [
        _c("div", { staticClass: "column" }, [
          _c(
            "div",
            {
              staticClass: "options-button",
              class: { "options-active": _vm.selected_view === "current" },
              on: {
                click: function ($event) {
                  return _vm.view_select_click("current")
                },
              },
            },
            [
              _c("i", { staticClass: "fas fa-solid fa-file" }),
              _vm._v(" Current "),
            ]
          ),
        ]),
        _c("div", { staticClass: "column" }, [
          _c(
            "div",
            {
              staticClass: "options-button",
              class: { "options-active": _vm.selected_view === "new" },
              on: {
                click: function ($event) {
                  return _vm.view_select_click("new")
                },
              },
            },
            [_c("i", { staticClass: "fas fa-solid fa-plus" }), _vm._v(" New ")]
          ),
        ]),
        _c("div", { staticClass: "column" }, [
          _c(
            "div",
            {
              staticClass: "options-button",
              class: { "options-active": _vm.selected_view === "draft" },
              on: {
                click: function ($event) {
                  return _vm.view_select_click("draft")
                },
              },
            },
            [
              _c("i", { staticClass: "fas fa-solid fa-newspaper" }),
              _vm._v(" Draft "),
            ]
          ),
        ]),
        _c("div", { staticClass: "column" }, [
          _c(
            "div",
            {
              staticClass: "options-button",
              class: { "options-active": _vm.selected_view === "scheduled" },
              on: {
                click: function ($event) {
                  return _vm.view_select_click("scheduled")
                },
              },
            },
            [
              _c("i", { staticClass: "fas fa-regular fa-calendar" }),
              _vm._v(" Scheduled "),
            ]
          ),
        ]),
        _c("div", { staticClass: "column" }, [
          _c(
            "div",
            {
              staticClass: "options-button",
              class: { "options-active": _vm.selected_view === "archived" },
              on: {
                click: function ($event) {
                  return _vm.view_select_click("archived")
                },
              },
            },
            [_c("i", { staticClass: "fas fa-archive" }), _vm._v(" Archived ")]
          ),
        ]),
        _vm.selected_view !== "new"
          ? _c("div", { staticClass: "column" }, [
              _c(
                "div",
                {
                  staticClass:
                    "options-button presentation-mode ft-tooltip-bottom",
                  class: { active: _vm.$store.state.global.show_edit },
                  attrs: { tooltiptext: "Edit Mode" },
                  on: {
                    click: function ($event) {
                      return _vm.toggle_presentation_mode()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fas fa-edit" }),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.$store.state.global.show_edit,
                        expression: "!$store.state.global.show_edit",
                      },
                    ],
                    staticClass: "fas fa-slash absolute",
                  }),
                ]
              ),
            ])
          : _vm._e(),
        _vm.selected_view !== "new"
          ? _c("div", { staticClass: "column" }, [
              _c(
                "div",
                {
                  staticClass: "options-button ft-tooltip-bottom",
                  attrs: { tooltiptext: "Print" },
                  on: { click: _vm.toggle_print_options },
                },
                [_c("i", { staticClass: "fas fa-print" })]
              ),
            ])
          : _vm._e(),
      ]),
    ]),
    _c("div", { staticClass: "columns" }, [
      _vm.selected_kpi_area !== "introduction" &&
      _vm.selected_view === "current" &&
      !_vm.edit_mode
        ? _c(
            "div",
            { staticClass: "kpi-areas ui-rounded column is-4" },
            [
              _vm.db_kpi_data
                ? _c("DataBox", {
                    staticClass: "data-box ui-rounded",
                    attrs: {
                      kpi_data: _vm.db_kpi_data,
                      versions: _vm.version_obj,
                      title: _vm.kpi_formatted,
                    },
                  })
                : _c("div", { staticClass: "data-box ui-rounded" }, [
                    _vm._v(
                      " No data has been found for " +
                        _vm._s(_vm.kpi_formatted) +
                        " "
                    ),
                  ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "content column" },
        [
          _c("BrowserNotice"),
          _vm.selected_view !== "new" && !_vm.edit_mode
            ? _c("div", [
                _vm.found_comments
                  ? _c(
                      "div",
                      _vm._l(_vm.comments, function (comment, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            staticClass: "comment-container",
                            class:
                              _vm.comments.length - 1 !== i
                                ? "comment-border"
                                : "",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "columns comment-title" },
                              [
                                _c("div", { staticClass: "column text-left" }, [
                                  comment.title
                                    ? _c("div", [_vm._v(_vm._s(comment.title))])
                                    : _vm._e(),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "column comment-meta text-right",
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(_vm._s(comment.u_display_name)),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { display: "inline-flex" },
                                      },
                                      [
                                        comment.updated_by
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "ft-tooltip",
                                                attrs: {
                                                  tooltiptext:
                                                    comment.e_display_name,
                                                },
                                              },
                                              [_vm._v("|Edited")]
                                            )
                                          : _vm._e(),
                                        comment.author_id
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "ft-tooltip",
                                                attrs: {
                                                  tooltiptext:
                                                    comment.a_display_name,
                                                },
                                              },
                                              [_vm._v("|Posted By")]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          comment.scheduled_date.split("T")[0]
                                        ) +
                                        " - " +
                                        _vm._s(
                                          comment.comment_lifetime.split("T")[0]
                                        )
                                    ),
                                  ]
                                ),
                                _c("CommentRatingButtons", {
                                  staticClass: "flex mt",
                                  attrs: {
                                    id: comment.comment_id,
                                    current: comment.rating,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", {
                              staticClass:
                                "comment-content text-left ProseMirror",
                              domProps: { innerHTML: _vm._s(comment.content) },
                            }),
                            _vm.$store.getters.check_access([
                              "comments",
                              "edit",
                              "*",
                              "w",
                            ]) && _vm.$store.state.global.show_edit
                              ? _c("div", { staticClass: "button-container" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "button edit-button is-half",
                                      on: {
                                        click: function ($event) {
                                          ;(_vm.comment_to_edit =
                                            _vm.comments[i]),
                                            _vm.toggle_edit_mode()
                                        },
                                      },
                                    },
                                    [_vm._v(" Edit ")]
                                  ),
                                  _vm.selected_view !== "archived"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "button edit-button is-half",
                                          on: {
                                            click: function ($event) {
                                              ;(_vm.comment_to_edit =
                                                _vm.comments[i]),
                                                _vm.change_status("ARCHIVED")
                                            },
                                          },
                                        },
                                        [_vm._v(" Archive ")]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    )
                  : _c("div", { staticClass: "mt" }, [
                      _vm._v(
                        " No " +
                          _vm._s(_vm.selected_view) +
                          " insights could be found "
                      ),
                    ]),
              ])
            : _vm._e(),
          _vm.selected_view === "new"
            ? _c(
                "div",
                [
                  _c("New", {
                    attrs: {
                      kpi: _vm.kpi,
                      insights_titles: _vm.insights_titles,
                    },
                    on: {
                      "toggle-edit-mode": _vm.toggle_edit_mode,
                      updated: _vm.edit_updated,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.edit_mode
            ? _c(
                "div",
                { staticClass: "mt" },
                [
                  _c("Edit", {
                    attrs: {
                      "comment-to-edit": _vm.comment_to_edit,
                      status: _vm.selected_view,
                      insights_titles: _vm.insights_titles,
                    },
                    on: {
                      "toggle-edit-mode": _vm.toggle_edit_mode,
                      updated: _vm.edit_updated,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }