<template>
<div>
    <Title
        title="Food Sales"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
        <div class="label-container">
            <p>Market Area</p>
            <DropDown
                :key="stores.values.length"
                :options="stores.options"
                :values="stores.values"
                :default="stores.values[0]"
                @updateDropDown="update_store"
            />
        </div>
        <div class="label-container">
            <p>Index</p>
            <DropDown
                :key="filter.index.state"
                :options="filter.index.options"
                :values="filter.index.values"
                :default="filter.index.state"
                @updateDropDown="update_filter_index"
            />
        </div>
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div class="box ms mb">
        <div class="food-tree-wrapper">
            <div v-if="render" id="food-tree" class="relative">
                <FoodTree :tables="tables" />
            </div>
        </div>
    </div>
    <div v-if="!tables.length">There is no data for <b>{{$store.getters.selected_country_name}}</b> with the <b>{{store}}</b> store selected</div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import FoodTree from '@/components/bespoke/trees/FoodTree.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        FoodTree,
        Title
    },
    props: {
        market: String
    },
    data: function() {
        return {
            filter: {
                index: {
                    state: 'YTD',
                    options: ['YTD', '1 Week', '4 Week'],
                    values: ['YTD', '1WK', '4WK'],
                }
            },
            location: '/business/sales-per-range/food-sales',
            store: null,
            render: false,
            rows_template: [
                'Behind the cash-lines',
                'Bistro',
                'Coffee bar',
                'Co-worker Restaurant',
                'Other',
                'Restaurant',
                'Swedish Food Market',
                'Total',
            ],
            tables: [],
            tables_template: [
                { title: 'Total Food sales (kEuros)', key: 'sales_net', value_dp: 0 },
                { title: 'Total Food Customers', key: 'customers', value_dp: 0 },
                { title: 'Average Check (Euros)', key: 'avg_ticket_value', value_dp: 1 },
                { title: 'Total Store Customers', key: 'total_customers', value_dp: 0 },
                { title: 'Take up Rate (%)', key: 'take_up_rate', value_dp: 1 },
                { title: 'Avg items per check', key: 'avg_ticket_qty', value_dp: 1 },
                { title: 'Avg price per item (LC)', key: 'avg_item_price', value_dp: 1 }
            ],
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.load_market_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
                {
                    name: 'By Market',
                    link: `${this.location}/by-market/${this.$store.getters.market}`
                },
                {
                    name: 'Tree',
                    link: `${this.location}/tree/${this.$store.getters.market}`
                },
            ];
        },
        load_market_data: function() {
            this.render = false;
            this.tables = [];
            this.$http.post('/business/sales-per-range/food-sales/tree', {
                index_filter: this.filter.index.state,
                store_filter: this.store,
                market: this.$store.getters.market,
            }).then(
                succ => {
                    if (!succ.data || !succ.data[0] || !succ.data[0].measures) return;
                    let d = succ.data[0].measures;
                    this.tables_template.forEach(table => {
                        if (table.key === 'total_customers') {
                            let {value, index} = this.map_row(d, table, 'Total');
                            this.tables.push([[table.title], ['TY', value], ['Index', index]]);
                            return;
                        }
                        let temp_rows = this.rows_template.map(row => {
                            let {value, index} = this.map_row(d, table, row);
                            return [row, value, index];
                        });
                        let temp_table = [['WSG', table.title, `${this.filter.index.state} Index`], ...temp_rows];
                        this.tables.push(temp_table);
                    });
                    this.render = true;
                },
                this.$helper.error
            );
        },
        map_row: function(d, table, row) {
            const index_dp = 1;
            let value, index;
            if (!d[row] || !d[row][table.key]) {
                value = '-';
            } else {
                value = this.$helper.round(d[row][table.key], table.value_dp, ' ');
            }
            if (!d[row] || !d[row][`${table.key}_index`]) {
                index = '-';
            } else {
                index = this.$helper.round(d[row][`${table.key}_index`], index_dp);
            }
            return {value, index};
        },
        update_filter_index: function(index_filter) {
            this.filter.index.state = index_filter;
            this.$store.state.global.temp_index_filter = index_filter;
        },
        update_store: function(store) {
            this.store = store;
            this.load_market_data();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: `${this.location}/tree`
            });
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.store = null;
            this.load_market_data();
        },
        'filter.index.state': function() {
            this.load_market_data();
        }
    },
    computed: {
        stores: function() {
            if (this.$store.getters.stores.values.length) {
                return this.$store.getters.stores;
            } else {
                return {
                    all: [],
                    options: [],
                    values: []
                }
            }
        }
    }
}
</script>

<style scoped>
.box {
    padding: 0;
}
#food-tree {
    min-height: 760px;
    min-width: 1200px;
    transform: rotateX(180deg);
}
.food-tree-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    transform: rotateX(180deg);
}
</style>
