<template>
<div>
    <Title
        title="Availability"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container">
            <p>Service Level</p>
            <DropDown
                :key="filter1"
                :options="options1"
                :values="values1"
                :default="filter1"
                @updateDropDown="update_filter1"
            />
        </div>
        <div class="label-container">
            <p>{{filter1}}</p>
            <DropDown
                :key="values2[0]"
                :options="options2"
                :values="values2"
                :default="values2[0]"
                @updateDropDown="update_filter2"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms">
        <Triple
            :key="triple.render"
            v-if="triple.render"

            title="Showing data for Availability Total"
            :showtitle="!$store.state.global.bot_mode"
            subtitle=""

            :xcategories="triple.xcategories"
            yaxislabel="Service Level"
            ymetric="%"
            
            :seriesname1="`${current_year - 2}`"
            seriestype1="line"
            :seriesset1="triple.seriesset1"
            :color1="$store.state.colors['light-grey']"
            
            :seriesname2="`${current_year - 1}`"
            seriestype2="line"
            :seriesset2="triple.seriesset2"
            :color2="$store.state.colors['dark-grey']"
            
            :seriesname3="`${current_year}`"
            seriestype3="line"
            :seriesset3="triple.seriesset3"
            :color3="$store.state.colors['focus-yellow']"

            :show="!$store.state.global.bot_mode"

            min="80"
        />
        <div v-else> No data found for {{$store.getters.market}}</div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Triple from '@/components/visualisations/general/Triple.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Title,
        Triple
    },
    props: {
        market: String
    },
    data: function() {
        return {
            options1: [],
            values1: [],
            filter1: null,

            values2: [],
            options2: [],
            filter2: null,
            
            options_set: [],

            current_year: null,
            triple: {
                title: null,
                subtitle: null,

                xcategories: null,
                yaxislabel: null,
                ymetric: null,
                
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],

                render: false
            },
            location: '/business/availability',
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);

        this.current_year = new Date().getFullYear().toString();

        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'Total',
                    link: this.location
                },
                {
                    name: 'Detail',
                    link: `${this.location}/detail`
                },
                
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'ava-1',
            }).then(
                succ => {
                    let d = succ.data;
                    d.map(filter => {
                        if (filter.level === 'sl') {
                            this.values1.push(filter.value);
                            this.options1.push(filter.option);
                        } else {
                            this.options_set.push(filter)
                        }
                    });
                    this.filter1 = this.$store.getters.filter_check(this.values1)
                    this.options_set.forEach(item => {
                        if (item.filter === this.filter1) {
                            this.values2.push(item.value);
                            this.options2.push(item.option);
                        }
                    });
                    this.filter2 = this.values2[0];
                },
                this.$helper.error
            );
            this.load_market_data();
        },
        load_market_data: function() {
            let url = '/business/availability/total';
            let succ = (succ) => {
                let d = succ.data;
                this.triple.xcategories = Object.keys(d[0].measures);
                
                this.triple.seriesset1 = [];
                this.triple.seriesset2 = [];
                this.triple.seriesset3 = [];

                this.triple.xcategories.map(week => {
                    let val = d[0].measures[week];
                    this.triple.seriesset1.push(this.$helper.maybe(val[`${this.current_year - 2}`]));
                    this.triple.seriesset2.push(this.$helper.maybe(val[`${this.current_year - 1}`]));
                    this.triple.seriesset3.push(val[`${this.current_year}`]);
                });

                this.triple.render = true;
            }
            let error = (error) => console.log(error);
            this.$http.post(url, {filter: this.filter2}).then(succ, error);
        },
        update_filter1: function(filter) {
            this.values2 = [];
            this.options2 = [];
            this.filter1 = filter;
            this.$store.state.global.temp_filter = filter;
            this.options_set.forEach(item => {
                if (item.filter === filter) {
                    this.values2.push(item.value);
                    this.options2.push(item.option);
                }
            });
            this.filter2 = this.values2[0];
            this.triple.render = false;
        },
        update_filter2: function(filter) {
            this.filter2 = filter;
            this.triple.render = false;
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location,
            });
            this.triple.render = false;
            this.load_market_data();
        },
    },
    watch: {
        market: function(old_val, new_val) {
            this.triple.render = false;
            this.load_market_data();
        },
        '$store.getters.countries': function(newCountries, oldCountries) {
            this.$store.commit('update_market', this.market);
        },
        filter1: function() {
            this.load_market_data();
        },
        filter2: function() {
            this.load_market_data();
        } 
    }
}
</script>

<style>

</style>