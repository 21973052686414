var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "navbar-item dropdown is-right is-hoverable" },
    [
      _c("div", { staticClass: "dropdown-trigger" }, [
        _c(
          "button",
          {
            staticClass: "button",
            attrs: {
              "aria-haspopup": "true",
              "aria-controls": "dropdown-menu",
            },
          },
          [
            _c("span", { staticClass: "icon relative" }, [
              _c("i", {
                staticClass: "fas fa-bell",
                attrs: { "aria-hidden": "true" },
              }),
              Object.keys(this.announcements).length > 0 &&
              _vm.number_of_new > 0
                ? _c("div", { staticClass: "main-badge" }, [
                    _c("div", { staticClass: "badge-text" }, [
                      _vm._v(_vm._s(_vm.number_of_new)),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "dropdown-menu",
          attrs: { id: "dropdown-menu", role: "menu" },
        },
        _vm._l(_vm.announcements, function (announcement, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "announcement-content",
              on: {
                mouseover: function ($event) {
                  announcement.seen ? "" : _vm.hover_over(announcement.id)
                },
              },
            },
            [
              _c("div", { staticClass: "announcement-title" }, [
                _vm._v(" " + _vm._s(_vm.kpi_area(index)) + " "),
                !announcement.seen
                  ? _c("div", { staticClass: "green-circle" })
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "announcement-subtitle" }, [
                _vm._v(
                  "Last Updated: " +
                    _vm._s(_vm.date_formatted(announcement.created))
                ),
              ]),
              _c("div", {
                staticClass: "announcement-body",
                domProps: { innerHTML: _vm._s(announcement.content) },
              }),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }