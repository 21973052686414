<template>
<div>
    <Title
        title="Cost Per Service"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container">
            <p>Actuality</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>

        <div class="label-container">
            <p>Service Type</p>
            <DropDown
                :key="service_filter"
                :options="service_options"
                :values="service_values"
                :default="service_filter"
                @updateDropDown="update_service_filter"
            />
        </div>

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>

    <Table
        v-if="table.render"
        
        :headers="table.headers"
        :rows="table.rows"
        :icon-settings="table.icon_settings"
        :decimal-settings="table.decimal_settings"
        :data-settings="table.data_settings"
        :sort-settings="{ 'Country': { asc: true } }"
        :font-settings="{ bold: {row: {regex: 'Total Retail Countries' } } }"

        class="ms1p5"
    />
    <div v-else>No data found for {{$store.getters.selected_country_name}}</div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            table: {
                headers: [],
                render: false,
                rows: [],
                decimal_settings: {},
                data_settings: { nullify_zeros: true },
            },
            options: ['Actual', 'Goal', 'Forecast'],
            values: ['actual', 'goal', 'forecast'],
            filter: 'actual',
            service_options: ['All Service Types', 'Additional Services', 'Assembly', 'Central Delivery Parcel', 'Central Delivery Truck', 'Collect', 'Installation', 'Local Delivery Parcel', 'Local Delivery Truck', 'Picking', 'Planning & Measuring', 'Removal & Recycling', 'Sewing Service', 'Total Delivery & Collect/Picking Services', 'Vehicle Retals', 'Total Other Services'],
            service_values: ['ALL SERVICE TYPES', 'ADDITIONAL SERVICES', 'ASSEMBLY', 'CENTRAL DELIVERY PARCEL', 'CENTRAL DELIVERY TRUCK', 'COLLECT', 'INSTALLATION', 'LOCAL DELIVERY PARCEL', 'LOCAL DELIVERY TRUCK', 'PICKING', 'PLANNING & MEASURING', 'REMOVAL & RECYCLING', 'SEWING SERVICE', 'Total delivery&collect/picking services', 'VEHICLE RENTALS', 'Total other services'],
            service_filter: 'ALL SERVICE TYPES',
            location: '/finance/cost-per-service'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.get_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'By Service',
                    link: `${this.location}/by-service/${this.$store.getters.market}`
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                }
            ];
        },
        get_data: function() {
            this.table.render = false;
            this.$http.post('finance/cost-per-service/by-country', {
                filter: this.filter,
                service_filter: this.service_filter
            }).then(
                succ => {
                    let d = succ.data;
                    if (d[0].measure_metadata) {
                        this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                    }
                    this.table.headers = null;
                    this.table.rows = [];
                    let keys = Object.keys(d[0].measures[0]);

                    this.table.rows = d.map(row => {
                        let temp = [];

                        keys.forEach((key, i) => {
                            if (key === 'commercial_ty' || key === 'index') return;
                            if (!row.measures || !row.measures[0] || !row.measures[0][key]) return temp.push(0);
                            temp.push(this.$helper.maybe(row.measures[0][key]));
                        });
                        
                        return [row.caption].concat(temp);
                    });

                    this.table.headers = [
                        [ 
                            { [this.service_filter]: 17}
                        ],
                        [
                            { '': 1, },
                            { 'Number Of Services': 2},
                            { 'Commercial Cost': 2},
                            { 'After Sales Cost': 2},
                            { 'Cost Per Order': 2}
                        ],
                        [
                            { 'Countries': 1},

                            { 'QTY': 1, alias: 'Number Of Services QTY'}, //Number of Services
                            { 'Index to LY': 1},

                            { 'kEuro': 1},
                            { 'Index': 1, alias: 'Commercial Cost Index'}, //Commercial Cost

                            { 'kEuro': 1, alias: 'After Sales Cost kEuro'}, //After Sales Cost
                            { 'Index to LY': 1},

                            {'excl After Sales': 1},
                            {'incl After Sales': 1}
                        ]
                    ];
                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.get_data();
        },
        update_service_filter: function(filter) {
            this.service_filter = filter;
            this.get_data();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        }
    }
}
</script>
