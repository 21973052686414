var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex key" }, [
    _vm._v(" Live "),
    _c("div", {
      staticClass: "key-badge",
      style: `background-color:${_vm.status_colors["live"]}`,
    }),
    _c("b", { staticClass: "vl" }, [_vm._v("|")]),
    _vm._v(" Scheduled "),
    _c("div", {
      staticClass: "key-badge",
      style: `background-color:${_vm.status_colors["scheduled"]}`,
    }),
    _c("b", { staticClass: "vl" }, [_vm._v("|")]),
    _vm._v(" Draft "),
    _c("div", {
      staticClass: "key-badge",
      style: `background-color:${_vm.status_colors["draft"]}`,
    }),
    _c("b", { staticClass: "vl" }, [_vm._v("|")]),
    _vm._v(" None "),
    _c("div", {
      staticClass: "key-badge",
      style: `background-color:${_vm.status_colors["none"]}`,
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }