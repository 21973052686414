var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("AdminUniversal", {
        attrs: { component: "title", props: _vm.title_props },
      }),
      _c(
        "div",
        { staticClass: "mb text-left" },
        [
          _c(
            "router-link",
            {
              staticClass: "button is-info",
              attrs: { to: "/admin/user-management/" },
            },
            [_vm._v("Back")]
          ),
          _c(
            "button",
            {
              staticClass: "button is-success ml",
              on: { click: _vm.update_template },
            },
            [_vm._v("Update Template")]
          ),
        ],
        1
      ),
      _vm.default_access_template
        ? _c(
            "div",
            [
              _c("AdminUniversal", {
                attrs: { component: "textinput", props: _vm.input_props },
              }),
              _c("AccessTemplate", {
                attrs: { prop_template: _vm.default_access_template.template },
                on: { update: _vm.update },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }