<template>
    <div id="input-field" class="kpi-search navbar-item text-left hidden-on-mobile">
        <input ref="inputField" v-model="kpi_search_string"
            @focus="kpi_search_focus = true"
            @blur="kpi_search_focus = false"
            placeholder="Search for KPI">
        <span class="delete-icon"
            v-if="kpi_search_string"
            @click="clear()"><i class="fas fa-times-circle"></i></span>
        <div class="navbar-dropdown search-dropdown "
           @mouseover="kpi_search_mouseover = true"
           @mouseleave="kpi_search_mouseover = false"
           v-if="kpi_search_string && (kpi_search_focus || kpi_search_mouseover)">
            <div class="nested navbar-item dropdown"
            :class="highlighted_index === i ? 'highlighted' : ''"
            v-for="(kpi, i) of filtered_kpis"
            :key="kpi.path"
            @mouseover="highlighted_index = i"
            >
                <div class="dropdown-trigger">
                    <div  aria-haspopup="false" @click="clear(); $router.push(kpi.path)" v-html="kpi.label"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        menuItems: Array
    },
    data: function() {
        return {
            item_list: [],
            kpi_search_string: "",
            kpi_search_focus: false,
            kpi_search_mouseover: false,
            highlighted_index: 0
        };
    },
    mounted: function() {
        window.addEventListener('keydown', (e) => {
            if (this.item_list.length && this.kpi_search_focus) {
                switch (e.key) {
                    case 'ArrowDown':
                        if (this.highlighted_index < 11 && this.highlighted_index < this.filtered_kpis.length - 1) {
                            this.highlighted_index++;
                        }
                    break;
                    case 'ArrowUp':
                        if (this.highlighted_index > 0 ) {
                            this.highlighted_index--;
                        }
                    break;
                    case 'Enter':
                        this.$router.push(this.filtered_kpis[this.highlighted_index].path);
                        this.clear();
                    break;
                }
            }
        });
    },
    computed: {
        filtered_kpis: function() {
            if (!this.kpi_search_string.length) return [];
            return this.item_list.filter(o => o.label.toLowerCase().includes(this.kpi_search_string.toLowerCase())).slice(0, 12).map(o => {
                let index = -1;
                let matchStr = o.label.toLowerCase();
                let searchStr = this.kpi_search_string.toLowerCase();
                let newStr = o.label;
                while ((index = matchStr.indexOf(searchStr)) !== -1) {
                    let pre = newStr.slice(0, index);
                    let replace = `<span class="matched">${newStr.slice(index, index + searchStr.length)}</span>`
                    let post = newStr.slice(index + searchStr.length);
                    matchStr = matchStr.replace(searchStr, '_'.repeat(replace.length));
                    newStr = `${pre}${replace}${post}`
                }
                return { label: newStr, path: o.path };
            });
        }
    },
    methods: {
        clear: function() {
            this.kpi_search_string = "";
            this.highlighted_index = 0;
        }
    },
    watch: {
        kpi_search_mouseover: function() {
            this.highlighted_index = 0;
        },
        menuItems: {
            deep: true,
            handler: function() {
                this.item_list = [];
                let flatten = (prepend, item, lines) => {
                    if (item.areas) {
                        for (let area of item.areas) {
                            if (prepend.length) {
                                flatten(prepend + " > " + item.name, area, lines);
                            } else {
                                flatten(item.name, area, lines);
                            }
                        }
                    } else {
                        lines.push({ label: prepend + " > " + item.name, path: item.path });
                    }
                }
                for (let kpi of this.menuItems) {
                    flatten("", kpi, this.item_list);
                }
            }
        }
    }
}
</script>

<style scoped>
#input-field {
    position: relative;
}

#input-field input {
    border: solid 1px #585858;
    border-radius: 0.5rem;
    color: #585858;
    height: 2rem;
    outline: none;
    padding-left: 1rem;
    padding-right: 1rem;
}

#input-field .delete-icon {
    position: absolute;
    right: 1.2rem;
    top: 1.1rem;
    cursor: pointer;
}

.highlighted {
    background-color: #e2e2e2;
}

.kpi-search {
    padding-top: 0.8rem;
}

.navbar-dropdown.search-dropdown {
    display: block;
}
</style>
