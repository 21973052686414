var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "title mt" }, [
      _vm._v("4"),
      _c(
        "span",
        {
          on: {
            click: function ($event) {
              _vm.easter = !_vm.easter
            },
          },
        },
        [_vm._v("0")]
      ),
      _vm._v("4 Page not found"),
    ]),
    _c("p", [_vm._v("We could not find the page you were looking for.")]),
    _c(
      "div",
      { staticClass: "mt" },
      [
        _c("router-link", { staticClass: "button", attrs: { to: "/" } }, [
          _vm._v("Take me back"),
        ]),
      ],
      1
    ),
    _c("img", {
      staticClass: "image",
      attrs: {
        src: require("../../public/assets/images/easter/404Wardrobe.jpg"),
      },
    }),
    _vm.easter
      ? _c("img", {
          staticClass: "image easter",
          attrs: {
            src: require("../../public/assets/images/easter/404Travolta.gif"),
          },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }