var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", { attrs: { title: "HFB", location: _vm.location } }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons(), dataset: _vm.table },
          }),
          _c(
            "div",
            {
              staticClass: "label-container",
              class: !_vm.$store.getters.market ? "ft-tooltip" : null,
              attrs: {
                tooltiptext: !_vm.$store.getters.market
                  ? "Select a country from top filter to see stores"
                  : null,
              },
            },
            [
              _c("p", [_vm._v("Market Area")]),
              _c("DropDown", {
                key: _vm.stores.values.length,
                attrs: {
                  options: _vm.stores.options,
                  values: _vm.stores.values,
                  default: _vm.stores.values[0],
                },
                on: { updateDropDown: _vm.update_store },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                key: _vm.$store.getters.market,
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: _vm.$store.getters.market,
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.table.render
        ? _c("TableV2", {
            attrs: {
              id: "hfb",
              headers: _vm.table.headers,
              rows: _vm.table.rows,
              "data-settings": { nullify_zeros: true },
              "font-settings": { bold: { row: { regex: "Total HFB" } } },
              "decimal-settings": _vm.table.decimal_settings,
              "icon-settings": _vm.table.icon_settings,
              "sort-settings": _vm.table.sort_settings,
              metaSettings: { is_selectable: false },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }