<template>
<div>
    <Title
        title="Store Fulfilment (Flows 7-8)"
        :location="location"
    />

    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="is-pulled-left mt2"
        />
        
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms">
        <Triple
            :key="triple.render"
            v-if="triple.render"

            :title="`Showing data for: ${$store.getters.selected_country_name}`"
            :showtitle="!$store.state.global.bot_mode"
            subtitle=""

            :xcategories="triple.xcategories"
            yaxislabel="Store Distribution M3 (markets)"
            ymetric=""
            
            seriesname1="Month Trend"
            seriestype1="bar"
            :seriesset1="triple.seriesset1"
            :color1="$store.state.colors['yellow']"
            label1position="top"
            
            seriesname2="LY Month Trend"
            seriestype2="bar"
            :seriesset2="triple.seriesset2"
            :color2="$store.state.colors['grey']"
            
            seriesname3="Required remaining monthly average"
            seriestype3="line"
            :seriesset3="triple.seriesset3"
            :color3="$store.state.colors.YG"
        />
        <div v-else> No data found for {{$store.getters.selected_country_name}}</div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Triple from '@/components/visualisations/general/Triple.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Title,
        Triple
    },
    props: {
        market: String
    },
    data: function() {
        return {
            triple: {
                title: null,
                subtitle: null,

                xcategories: null,
                yaxislabel: null,
                ymetric: null,
                
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],

                render: false
            },
            location: '/business/store-fulfilment'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.load_market_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: `${this.location}-7-8`
                },
                {
                    name: 'Trend',
                    link: `${this.location}-7-8/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}-7-8/top-8`
                },
            ];
        },
        load_market_data: function() {
            let url = '/business/store-fulfilment/market-7-8';
            let succ = (succ) => {
                let d = succ.data;
                this.triple.xcategories = Object.keys(d[0].measures);
                
                this.triple.seriesset1 = [];
                this.triple.seriesset2 = [];
                this.triple.seriesset3 = [];
                
                this.triple.xcategories.map(month => {
                    let val = d[0].measures[month];
                    this.triple.seriesset1.push(this.$helper.maybe(val['Month']));
                    this.triple.seriesset2.push(this.$helper.maybe(val['Month LY']));
                    this.triple.seriesset3.push(this.$helper.maybe(val['Required remaining monthly average']));
                });
            
                this.triple.render = true;
                
            }

            let error = (error) => console.log(error);
            this.$http.post(url, { market: this.$helper.market_check(this.market) }).then(succ, error);
            
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: `${this.location}-7-8`
            });
        }
    },
    watch: {
        market: function(old_val, new_val) {
            this.triple.render = false;
            this.load_market_data();
        },
        '$store.getters.countries': function(newCountries, oldCountries) {
            this.$store.commit('update_market', this.market);  
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        }
    }
}
</script>
