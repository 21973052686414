var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("small", [_vm._v("Did you find this definition useful? ")]),
      _c(
        "Button",
        {
          staticClass: "comment-rate-button",
          class: { chosen: _vm.rating === true },
          attrs: {
            title: "I found this definition useful",
            disabled: _vm.working,
          },
          on: {
            click: function ($event) {
              return _vm.rate(true)
            },
          },
        },
        [_c("i", { staticClass: "far fa-thumbs-up" })]
      ),
      _c(
        "Button",
        {
          staticClass: "comment-rate-button",
          class: { chosen: _vm.rating === false },
          attrs: {
            title: "I did not find this definition useful",
            disabled: _vm.working,
          },
          on: {
            click: function ($event) {
              return _vm.rate(false)
            },
          },
        },
        [_c("i", { staticClass: "far fa-thumbs-down" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }