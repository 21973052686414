var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "section three-quarters-height box ms mb" },
      [
        _vm.graph
          ? _c(
              "Graph",
              _vm._b(
                {
                  attrs: {
                    title: `Showing data for: ${_vm.$store.getters.selected_country_name}`,
                    "show-title": !_vm.$store.state.global.bot_mode,
                  },
                },
                "Graph",
                _vm.graph,
                false
              )
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }