<template>
<div>
    <AdminUniversal
        component="title"
        :props="title_props"
    />
    <AdminUniversal
        component="button"
        :props="button_props"
    />
    <table class="table is-narrow">
        <thead>
            <tr class="business">
                <th>ID</th>
                <th>Question</th>
                <th>Created At</th>
                <th>Options</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(question, index) of questions" :key="index">
                <td>{{question.id}}</td>
                <td>{{question.question}}</td>
                <td>{{question.created_at}}</td>
                <td>
                    <a class="button" @click="edit(question.id)">Edit</a>
                </td>
            </tr>
        </tbody>
    </table>
</div>
</template>
<script>
import AdminUniversal from '@/components/admin/index.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';

export default {
    components: {
        AdminUniversal,
        Table
    },
    data: function() {
        return {
            title_props: {
                title: 'Current Questions',
                subtitle: 'After enabling a new question, refresh your page.'
            },
            button_props: {
                label: 'New Question',
                classes: 'is-success',
                method: this.navigate
            },
            questions: null,
            render: false,
        }
    },
    mounted: function() {
        this.render = false;
        this.$http.get('force', '/nps/questions').then(
            ({ data }) => {
                this.questions = data;
                    this.questions.forEach( row => {
                        row.created_at = this.$helper.date_formatting(row.created_at, 'DATETIME');
                    });
            },
            err => console.log(err)
        )
    },
    methods: {
        edit: function(question_id) {
            this.$router.push(`/admin/nps/questions/edit/${question_id}`);
        },
        navigate: function() {this.$router.push('/admin/nps/questions/new');}
    }
}
</script>