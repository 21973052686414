<template>
<div class="columns is-gapless">
    <div class="column is-2">
        <i class="fas fa-exclamation-circle"></i>
    </div>
    <div class="column is-8">
        <div>There should <b>not</b> be more than {{commentLimit}} insight live at one time.</div> 
        <div>Please archive one.</div>
    </div>
    <div class="column is-2">
        <i class="fas fa-exclamation-circle"></i>
    </div>
</div>
</template>

<script>
export default {
    props: {
        commentLimit: String
    }
}
</script>

<style scoped>
.columns {
    background-color: #ff0000 !important;
    color: #fff;
    padding: 0.5rem 0 0.5rem 0;
}

.fa-exclamation-circle {
    font-size: 1.5rem;
    padding: 0.5rem;
}
</style>