<template>
<div>
    <AdminUniversal
        component="title"
        :props="title_props"
    />
    <div class="mb text-left">
        <router-link class="button is-info" to="/admin/user-management/">Back</router-link>
        <button class="button is-success ml" @click="update_template">Update Template</button>
    </div>
    <div v-if="default_access_template">
        <AdminUniversal
            component="textinput"
            :props="input_props"
        />
        <AccessTemplate
            :prop_template="default_access_template.template"
            @update="update"
        />
    </div>
</div>
</template>

<script>
import AccessTemplate from '@/components/admin/access-templates/AccessTemplate.vue';
import AdminUniversal from '@/components/admin/index.vue';
export default {
    props: {
        template_id: Number
    },
    components: {
        AccessTemplate,
        AdminUniversal
    },
    data: function() {
        return {
            title_props: {
                title: 'Edit Access Template'
            },
            input_props: {
                input: '',
                label: 'Name',
                placeholder: '',
                callback: this.update_input_name
            },
            input_render: false,
            default_access_template: null
        }
    },
    mounted: function() {
        this.load_access_template();
    },
    methods: {
        load_access_template: function() {
            // Load the access templates
            this.$http.get(`/admin/user-management/access-templates/access-template/${this.template_id}`).then(
                succ => {
                    this.default_access_template = succ.data.pop();
                    this.input_props.input = this.default_access_template.name;
                    this.input_render - true;
                },
                this.$helper.error
            );
        },
        update: function(template) {
            this.default_access_template.template = template;
        },
        update_input_name: function(input) {this.default_access_template.name = input;},
        update_template: function() {
            // Update access template
            this.$http.post(`/admin/user-management/access-templates/access-template/${this.template_id}/update`, {
                updated_name: this.default_access_template.name,
                updated_template: this.default_access_template.template
            }).then(
                succ => {
                    this.$router.push('/admin/user-management/access-templates');
                },
                this.$helper.error
            );
        }
    }
}
</script>

<style>

</style>