<template>
<div>
    <AdminUniversal
        component="title"
        :props="title_props"
    />
    <KPIChooser
        @chosen="kpi_chosen"
        @reset="back"
        class="kpi-chooser"
    />
    <AdminUniversal
        v-show="disable_button_visable"
        component="button"
        :props="button_props"
    />

    <hr/>
    
    <AdminUniversal
        component="table"
        :props="table_props"
    />
</div>
</template>

<script>
import AdminUniversal from '@/components/admin/index.vue';
import KPIChooserDropdowns from '@/components/admin/KPIChooserDropdowns.vue';
import KPIChooser from '@/components/admin/KPIChooser.vue';
export default {
    components: {
        AdminUniversal,
        KPIChooserDropdowns,
        KPIChooser
    },
    data: function() {
        return {
            title_props: {
				title: 'Enable & Disable KPIs'
			},
            button_props: {
                label: 'Disable',
                classes: 'is-danger',
                method: this.disable_kpi
            },
			table_props: {
                buttons: [{
                    label: 'Enable',
                    classes: 'is-success',
                    method: this.enable_kpi
                }],
                headers: ['ID', 'KPI Area', 'KPI', 'Market Area', 'Store Area', 'Actions'],
                data: null,
                render: false
            },
            disable_button_visable: false,
            location: {}
        }
    },
    mounted: function() {
        this.load_kpis();
    },
    methods: {
        disable_kpi: function() {
            this.toggle_kpi(false);
        },
        enable_kpi: function(row) {
            const selected_kpi = this.table_props.data[row];
            this.location = {
                kpi: selected_kpi['kpi'],
                kpi_area: selected_kpi['kpi_area'],
                market_area: selected_kpi['market_area'],
                store_area: selected_kpi['store_area']
            };
            this.toggle_kpi(true);
        },
        load_kpis: function() {
            this.table_props.render = false;
            this.$http.get('/disabled-kpis/get').then(
                succ => {
                    if (succ.data) {
                        this.table_props.data = succ.data;
                    }
                    this.table_props.render = true;
                },
                this.$helper.error
            );
        },
        toggle_kpi: function(enable) {
            this.location.enable = enable;
            this.$http.post('/admin/toggle-kpi/toggle', this.location).then(
                succ => {
                    alert(succ.data.message);
                    this.load_kpis();
                },
                this.$helper.error
            );
        },
        kpi_chosen: function(kpi) {
            this.location = this.$helper.seperate_link_into_areas(kpi.path);
            this.disable_button_visable = true;
        },
        back: function() {
            this.location = {};
            this.disable_button_visable = false;
        }
    }
}
</script>

<style scoped>
.options-bar {
    padding: 0;
}

</style>