var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "title" }, [_vm._v("Access Summary")]),
      _c("div", { staticClass: "access-title" }, [
        _vm._v("Your level of access is: " + _vm._s(_vm.access_template_name)),
      ]),
      _c("div", { staticClass: "box mt ml mr" }, [
        _vm._v(" Click "),
        _c("a", { on: { click: _vm.raise_access } }, [_vm._v("here")]),
        _vm._v(" if you would like to raise your level of access. "),
      ]),
      _c("div", { staticClass: "text-very-large text-left ml" }, [
        _vm._v("Your access at a glance"),
      ]),
      _c("AccessNode", {
        attrs: { node: _vm.$store.state.access.access_template },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }