<template>
    <div :id="title" style="width: 100%; height:100%;"></div>
</template>

<script>
import * as echarts from 'echarts/dist/echarts.min.js';
export default {
    props: {
        title: String, //title of the graph
        showtitle: Boolean,
        subtitle: String, //subtitle of the graph 
        xcategories: Array, //values along the x axis
        yaxislabel: String, //name of the data on the y axis e.g 'After sales delivery share' or 'services sale by month'
        ymetric: String, //'%', '£', 'kg' etc.
        seriesname1: String, //name of the data such as IKEA 
        seriestype1: String, //bar, line, scatter etc.
        seriesset1: Array, //actual data array to fill the graph
        color1:  String, //color of the bar lie or scatter for the paticular data set
        position1: String, // string for the position of the labels
        labelcolor1: String, //string of the color for the label1
        seriesname2: String,
        seriestype2: String,
        seriesset2: Array,
        color2: String, 
        show: Boolean, // true to show the exta features such as 'save graph as image', 'refresh', 'change to bar graph', etc
    },
    mounted: function() {
        let myChart = echarts.init(document.getElementById(this.title));
        let option = {
            title:{
                text: this.title,
                subtext: this.subtitle,
                show: this.showtitle,
                subtextStyle: {
                    color: 'black',
                },
                top: '-2em',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            toolbox: {
                top: '85em',
                right: '30em',
                show: this.show,
                feature: {
                    restore: {},
                    saveAsImage: {}
                }
            },
            grid: {
               top: '100em',
               left: '110em',
               bottom: '70em'
            },
            legend: {
                top: '55em',
                left: '0em',
                data:[this.seriesname1, this.seriesname2],
            },
            textStyle: {
                fontFamily: 'NotoIKEALatin-Regular',
            },
            xAxis: [
                {
                    type: 'category',
                    data: this.xcategories,
                    axisPointer: {
                        type: 'shadow'
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: this.yaxislabel,
                    nameLocation: 'middle',
                    nameGap: 60,
                    axisLabel: {
                        formatter: `{value} ${this.ymetric}`
                    }
                }
            ],
            dataZoom: [
                {
                    show: this.show,
                    type: 'slider',
                    xAxisIndex: 0,
                    filterMode: 'empty',
                    left: '100em' ,
                    bottom: '0em' ,
                    handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                    handleSize: '80%', 
                    handleStyle: {
                        color: '#fff',
                        shadowBlur: 3,
                        shadowColor: 'rgba(0, 0, 0, 0.6)',
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    }
                },
                {
                    show: this.show,
                    type: 'slider',
                    yAxisIndex: 0,
                    filterMode: 'empty',
                    left: '0em' ,
                    bottom: '60em' ,
                    handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                    handleSize: '80%',
                    handleStyle: {
                        color: '#fff',
                        shadowBlur: 3,
                        shadowColor: 'rgba(0, 0, 0, 0.6)',
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    }
                }
            ],
            series: [
                {
                    name: this.seriesname1,
                    type: this.seriestype1,
                    data: this.seriesset1,
                    color: this.color1,
                    barGap: '0',
                    label: {
                        show: this.seriestype1 === 'bar' ? true : false,
                        align: 'center',
                        verticalAlign: 'middle',
                        position: this.position1 ? this.position1 : 'insideTop',
                        distance: 20,
                        color: this.labelcolor1 ? this.labelcolor1 : 'white',
                    },
                },
                {
                    name: this.seriesname2,
                    type: this.seriestype2,
                    data: this.seriesset2,
                    color: this.color2,
                    symbol: this.seriesname2 === 'Year Goal' ? 'none' : 'circle',
                    label: {
                        show: this.seriestype2 === 'bar' ? true : false,
                        align: 'center',
                        verticalAlign: 'middle',
                        position: 'insideTop',
                        distance: 20,
                        color: 'black',
                    },
                },
            ]
        };
        myChart.setOption(option);
    }
}
</script>