<template>
    <div :id="title" style="width: 100%; height:100%;"></div>
</template>

<script>
import * as echarts from 'echarts/dist/echarts.min.js';
export default {
    props: {
        title: String, //title of the graph
        showTitle: Boolean,
        legend: Object,

        sub_title: String, // subtitle of the graph
        x_categories: Array, //data that will be displayed on the x axis
        x_axis_label_interval: String,
        x_start_percent: String, 
        x_end_percent: String,
        x_start_value: String,
        x_end_value:  String,

        y_axis_label: String, //name of the data on the y axis e.g 'After sales delivery share' or 'services sale by month'
        y_axis_name_gap: Number,
        y_metric: String, //'%', '£', 'kg' etc.
        
        
        y_axis_min: Number, //set the minimum value of the the y axis
        y_axis_max: Number, //set the maximum value of the the y axis
        y_start_percent: Number,
        y_end_percent: Number,

        y2_axis_label: String, //name of the data on the y axis e.g 'After sales delivery share' or 'services sale by month'
        y2_metric: String, //'%', '£', 'kg' etc.

        y2_axis_min: Number, //set the minimum value of the the y axis
        y2_axis_max: Number, //set the maximum value of the the y axis
        
        names: Array, //name of the data such as IKEA
        types: Array, //bar, line, scatter etc.
        stack: Array,
        data_sets: Array, //actual data array to fill the graph
        colors: Array, //color of the bar lie or scatter for the paticular data set
        styling: Array, //styles for the types 
        disabled_labels: Array,
        legend_selected: Object, //set the default value of the items in the legend (e.g enabled or disabled)
        yAxisIndexes: Array,
        toolbox: Boolean,
        year_goal_index: Number, //the index of the value which is the year goal, this needs special styling
        grid: Object, //the spacings controling the size of the graph

        y_zoom: Boolean,
        x_zoom: Boolean,

        show: Boolean, // true to show the exta features such as 'save graph as image', 'refresh', 'change to bar graph', etc
    },
    mounted: function() {
        let myChart = echarts.init(document.getElementById(this.title));
        let option = {
            title: {
                text: this.title,
                subtext: this.sub_title,
                show: this.showTitle,
                subtextStyle: {
                    color: 'black',
                },
                top: '-5rem',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            toolbox: {
                top: '0rem',
                right: '70rem',
                show: this.toolbox,
                feature: {
                    restore: {},
                    saveAsImage: {}
                }
            },
            grid: {
               top: '80rem',
               left: this.y_zoom ? '150rem' : '105rem',
               bottom: this.x_zoom ? '70rem' : '30rem',
               right: '60rem'
            },
            legend: this.legend ? this.legend : {
                top: '30rem',
                left: '0rem',
                selected: this.legend_selected ? this.legend_selected : {},
                data: this.names,
                itemGap: 15,
                padding: [-1, 10],
            },
            textStyle: {
                fontFamily: 'NotoIKEALatin-Regular',
            },
            xAxis: [
                {
                    type: 'category',
                    data: this.x_categories,
                    axisPointer: {
                        type: 'shadow'
                    },
                    axisLabel: {
                        interval: this.x_axis_label_interval || '0',
                        hideOverlap: true,
                    },
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: this.y_axis_label,
                    min: this.y_axis_min || null,
                    max: this.y_axis_max || null,
                    nameLocation: 'middle',
                    nameGap: this.y_axis_name_gap || 90,
                    axisLabel: {
                        formatter: `{value} ${this.y_metric}`
                    }
                },
                {
                    type: 'value',
                    name: this.y2_axis_label,
                    min: this.y2_axis_min || null,
                    max: this.y2_axis_max || null,
                    nameLocation: 'middle',
                    nameGap: 70,
                    axisLabel: {
                        formatter: `{value} ${this.y2_metric}`
                    }
                },
            ],
            dataZoom: [
                {
                    show: this.x_zoom,
                    type: 'slider',
                    start: this.x_start_percent || null,
                    end: this.x_end_percent || null,
                    startValue: this.x_start_value || null,
                    endValue: this.x_end_value || null,
                    xAxisIndex: 0,
                    filterMode: 'empty',
                    left:  this.y_zoom ? '145rem' : '100rem',
                    bottom: '10rem',
                    handleSize: '80%', 
                    handleStyle: {
                        color: '#fff',
                        shadowBlur: 3,
                        shadowColor: 'rgba(0, 0, 0, 0.6)',
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    },
                    areaStyle: {
                        color: 'rgba(225,225,225,1)'
                    }
                },
                {
                    show: this.y_zoom,
                    type: 'slider',
                    start: this.y_start_percent || null,
                    end: this.y_end_percent || null,
                    yAxisIndex: 0,
                    filterMode: 'empty',
                    left: '0rem',
                    bottom: this.x_zoom ? '60rem' : '30rem',
                    handleSize: '100%',
                    handleStyle: {
                        color: '#fff',
                        shadowBlur: 3,
                        shadowColor: 'rgba(0, 0, 0, 0.6)',
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    },
                    areaStyle: {
                        color: 'rgba(225,225,225,1)'
                    }
                }
            ],
            series: []
        };
        this.names.forEach((name, i) => {
            let yAxisIndexes = this.yAxisIndexes || [];
            let temp = {
                name: name,
                type: this.types[i],
                data: this.data_sets[i],
                color: this.colors[i],
                yAxisIndex: yAxisIndexes[i] || 0
            }
            if (this.stack && this.stack[i]) temp['stack'] = this.stack[i];
            if (this.styling && this.styling[i] && Object.keys(this.styling[i]).length) {
                if ('areaStyle' in this.styling[i]) temp['areaStyle'] = this.styling[i].areaStyle;
                if ('lineStyle' in this.styling[i]) temp['lineStyle'] = this.styling[i].lineStyle;
                if ('smooth' in this.styling[i]) temp['smooth'] = this.styling[i].smooth;
                if ('z' in this.styling[i]) temp['z'] = this.styling[i].z;
                if ('showSymbol' in this.styling[i]) temp['showSymbol'] = this.styling[i].showSymbol;
            }
            let disabled_labels = this.disabled_labels || [];
            if (this.types[i] === 'bar' && !disabled_labels[i]) {
                temp['label'] = {
                    fontSize: 12,
                    show: true,
                    align: 'center',
                    verticalAlign: 'middle',
                    position: 'insideTop',
                    distance: 20,
                    color: 'black',
                    formatter: params => {
                        return this.$helper.round(params.data, false, ',');
                    }
                }
            }
            if (this.year_goal_index && this.year_goal_index === i) {
                temp['markPoint'] = this.year_goal_label(name, this.data_sets[i]);
                temp['symbol'] = 'none';
            }
            option.series.push(temp);
        });
        if (this.grid && Object.keys(this.grid).length) Object.keys(this.grid).forEach(key => option.grid[key] = this.grid[key]);
        myChart.setOption(option);
    },
    methods: {
        year_goal_label: function(name, set) {
            let length = set.length;
            return {
                symbolSize: 0,
                label: {
                    color: 'black',
                    fontSize: 12,
                    offset: [0, -10],
                },
                data: [{value: `${name}: ${set[length - 1]}`, xAxis: length - 1, yAxis: set[length - 1]}]
            }
        }
    },
    computed: {
        selected_names: function() {
            let temp = {};
            this.names.forEach(name => {
                temp[name] = 'true';
            });
            return temp;
        }
    }
}
</script>
