<template>
<div>
    <Title
        title="Sales Prioritised Cities"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <Table
        v-if="table.render"

        :headers="table.headers"
        :rows="table.rows"

        :decimal-settings="table.decimal_settings"
        :icon-settings="table.icon_settings"
        :sort-settings="table.sort_settings"

        class="ms1p5 no-all"
    />
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: [],
                decimal_settings: {},
                render: false
            },
            location: '/business/total-sales/sales-prioritized-thirty-cities',
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.load_ingka_by_country();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By City',
                    link: this.location
                },
                {
                    name: 'Accumulated',
                    link: `${this.location}/accumulated/null`
                },
                {
                    name: 'Bubble Graph',
                    link: `${this.location}/bubble-graph`
                },
            ];
        },
        load_ingka_by_country: function() {
            this.table.render = false;
            this.$http.get('business/total-sales/sales-prioritized-thirty-cities').then(
                succ => {
                    let d = succ.data;
                    let keys = Object.keys(d[0].measures[Object.keys(d[0].measures)[0]])
                    if (d[0].measure_metadata) {
                        this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                    }

                    let names = ['YTD MEUR City', 'City sales share in the country', 'YTD Index City', 'YTD Index Rest of Country', '4 Weeks Trend City', '4 Weeks Trend Rest of Country', 'City Online Sales Share %', 'Rest of country Online Sales Share %']

                    Object.keys(d[0].measures).forEach(city => {
                        let temp = [];
                        temp.push(city);
                        names.forEach(name =>{
                            temp.push(this.$helper.maybe(d[0].measures[city][name]));
                        });
                        this.table.rows.push(temp);
                    });

                    this.table.icon_settings = {
                        'YTD MEUR City': {
                            comparison: 'YTD MEUR City',
                            icon: 'bar'
                        },
                        'City Sales Share in the Country': {
                            fix: { post: '%' }
                        },
                        '4WK Trend City': {
                            comparison: 'YTD Index City',
                            icon: 'arrow'                            
                        },
                        '4WK Trend Rest of Country': {
                            comparison: 'YTD Index Rest of',
                            icon: 'arrow'
                        },
                        'Online Sales Share City': {
                            fix: { post: '%' }
                        },
                        'Online Sales Share Rest of Country': {
                            fix: { post: '%' }
                        },
                    }
                    this.table.sort_settings = {
                        'YTD MEUR': {
                            asc: false
                        }
                    }
                    this.table.headers = [
                        // Row 1
                        [{'': 1}, {'YTD MEUR': 1}, {'City Sales Share': 1}, {'YTD Index': 2}, {'4WK Trend': 2}, {'Online Sales Share': 2}],
                        // Row 2
                        [
                            {'Cities': 1},

                            {'City': 1, alias: 'YTD MEUR City'},

                            {'in the Country': 1, alias: 'City Sales Share in the Country'},

                            {'City': 1, alias: 'YTD Index City'},
                            {'Rest of': 1, alias: 'YTD Index Rest of'},       

                            {'City': 1, alias: '4WK Trend City'},
                            {'Rest of Country': 1, alias: '4WK Trend Rest of Country'}, 

                            {'City': 1, alias: 'Online Sales Share City'},
                            {'Rest of Country': 1, alias: 'Online Sales Share Rest of Country'}
                        ]
                    ];
                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        }
    }
}
</script>
