var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "button is-success mt button-left",
          on: {
            click: function ($event) {
              return _vm.toggle_new_modal()
            },
          },
        },
        [_vm._v("Add new insights title")]
      ),
      _vm.show_title_modals.new
        ? _c("Modal", {
            class: { "is-active": _vm.show_title_modals.new },
            attrs: { closeable: true, title: "Add new title for insights" },
            on: {
              close: function ($event) {
                return _vm.toggle_new_modal()
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("div", [_vm._v("Add new Title")]),
                      _c("AdminUniversal", {
                        attrs: {
                          component: "Textinput",
                          props: _vm.input_props1,
                        },
                      }),
                      _c("AdminUniversal", {
                        attrs: {
                          component: "dropdown",
                          props: _vm.dropdown_props1,
                        },
                      }),
                      _c("AdminUniversal", {
                        attrs: {
                          component: "dropdown",
                          props: _vm.dropdown_props2,
                        },
                      }),
                      _c("div", { staticClass: "text-left -mt" }, [
                        _c("em", [
                          _vm._v(
                            "1 means the comment with this title will appear at the top and vice versa"
                          ),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c("a", { staticClass: "buttons" }, [
                        _c(
                          "a",
                          {
                            staticClass: "button is-success",
                            on: {
                              click: function ($event) {
                                return _vm.add_new_title()
                              },
                            },
                          },
                          [_vm._v("Add")]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "button is-danger",
                            on: {
                              click: function ($event) {
                                return _vm.toggle_new_modal()
                              },
                            },
                          },
                          [_vm._v("Cancel")]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2870263134
            ),
          })
        : _vm._e(),
      _c("AdminUniversal", {
        attrs: { component: "dropdown", props: _vm.dropdown_props3 },
      }),
      _c(
        "table",
        [
          _c(
            "tr",
            _vm._l(_vm.titles_table.headers, function (header, i) {
              return _c("th", { key: i, staticClass: "capitalize" }, [
                _vm._v(" " + _vm._s(header) + " "),
              ])
            }),
            0
          ),
          _vm._l(_vm.titles_table.rows, function (row, i) {
            return _c(
              "tr",
              { key: i },
              [
                _vm._l(_vm.titles_table.headers, function (header, j) {
                  return _c("td", { key: j }, [
                    _vm._v(" " + _vm._s(row[header]) + " "),
                  ])
                }),
                _c("td", [
                  _c(
                    "div",
                    {
                      staticClass: "button is-info",
                      on: {
                        click: function ($event) {
                          return _vm.toggle_edit_modal(row)
                        },
                      },
                    },
                    [_vm._v("Edit")]
                  ),
                ]),
                _c("td", [
                  _c(
                    "div",
                    {
                      staticClass: "button is-danger",
                      on: {
                        click: function ($event) {
                          return _vm.toggle_remove_modal(row.id)
                        },
                      },
                    },
                    [_vm._v("Delete")]
                  ),
                ]),
              ],
              2
            )
          }),
        ],
        2
      ),
      _vm.show_title_modals.edit
        ? _c("Modal", {
            class: { "is-active": _vm.show_title_modals.edit },
            attrs: { closeable: true, title: "Edit selected title" },
            on: {
              close: function ($event) {
                return _vm.toggle_edit_modal("close")
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("div", [_vm._v("Add new Title")]),
                      _c("AdminUniversal", {
                        attrs: {
                          component: "Textinput",
                          props: _vm.input_props2,
                        },
                      }),
                      _c("AdminUniversal", {
                        attrs: {
                          component: "dropdown",
                          props: _vm.dropdown_props4,
                        },
                      }),
                      _c("AdminUniversal", {
                        attrs: {
                          component: "dropdown",
                          props: _vm.dropdown_props5,
                        },
                      }),
                      _c("div", { staticClass: "text-left -mt" }, [
                        _c("em", [
                          _vm._v(
                            "1 means the comment with this title will appear at the top and vice versa"
                          ),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c("a", { staticClass: "buttons" }, [
                        _c(
                          "a",
                          {
                            staticClass: "button is-success",
                            on: {
                              click: function ($event) {
                                return _vm.commit_edit_title()
                              },
                            },
                          },
                          [_vm._v("Save Changes")]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "button is-danger",
                            on: {
                              click: function ($event) {
                                return _vm.toggle_edit_modal("close")
                              },
                            },
                          },
                          [_vm._v("Cancel")]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2324074124
            ),
          })
        : _vm._e(),
      _vm.show_title_modals.remove
        ? _c("Modal", {
            class: { "is-active": _vm.show_title_modals.remove },
            attrs: { closeable: true, title: "Delete selected title" },
            on: {
              close: function ($event) {
                return _vm.toggle_remove_modal()
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("div", [
                        _vm._v(
                          "Are you sure you want to delete the selected title?"
                        ),
                      ]),
                      _c("div", [
                        _vm._v(
                          "The connections between this title and any responsibles will also be deleted"
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c("a", { staticClass: "buttons" }, [
                        _c(
                          "a",
                          {
                            staticClass: "button is-success",
                            on: {
                              click: function ($event) {
                                return _vm.remove_title()
                              },
                            },
                          },
                          [_vm._v("Confirm Delete")]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "button is-danger",
                            on: {
                              click: function ($event) {
                                return _vm.toggle_remove_modal()
                              },
                            },
                          },
                          [_vm._v("Cancel")]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3591185002
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }