<template>
<div>
    <Title
        title="After Sales Delivery Share Top 10"
        :location="location"
    />

    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container">
            <p>Reason</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                default=""
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section box ms mb half-height">
        <Top8Bar
            v-if="render_top8"
            :key="render_top8"

            title="After Sales Delivery Share Top 10 Countries"
            :showtitle="!$store.state.global.bot_mode"
            subtitle=""
            :xcategories="top_8.xcategories"
            :yaxislabel="this.filter"
            ymetric="%"

            seriesname1="YTD"
            :seriestype1="top_8.seriestype1"
            :seriesset1="top_8.seriesset1"
            :color1="$store.state.colors['yellow']"
            
            seriesname2="LY YTD"
            :seriestype2="top_8.seriestype2"
            :seriesset2="top_8.seriesset2"
            :color2="$store.state.colors['grey']"
            
            seriesname4="Year Goal"
            seriestype4="scatter"
            :color4="$store.state.colors.YG"
            :seriesset4="top_8.seriesset4"
            
            seriestype3="scatter"

        />
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Top8Bar from '@/components/visualisations/bar/Top8Bar.vue';

export default {
    props: {
        market: String,
        option: String
    },
    components: {
        Buttons,
        DropDown,
        Title,
        Top8Bar,
    },
    data: function() {
        return {
            top_8: {
                xcategories: null,
                yaxislabel: "Y Axis Label",
                ymetric: "Y Metric",

                seriesname1: "",
                seriestype1: "bar",
                seriesset1: [],
                color1: "#0F0",
                
                seriesname2: "",
                seriestype2: "bar",
                seriesset2: [],
                color2: "#F00",

                seriesname3: "",
                seriestype3: "bar",
                seriesset3: [],
                color3: "#00F",

                seriesname4: "Year Goal",
                seriestype4: "scatter",
                seriesset4: [],
                color4: "#ADE",

                ranking: [],
                reasons: []
            },
            render_top8: false,
            options: ['Change Of Mind', 'Customer Assistance', 'Product Quality', 'Sales Process', 'Service Process'],
            values: ['Change Of Mind', 'Customer Assistance', 'Product Quality', 'Sales Process', 'Service Process'],
            filter: 'Change Of Mind',
            location: '/finance/after-sales-delivery-share'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.option === "null") {
            this.$router.push(this.filter_url);
        } else {
            this.filter = this.option.replace(/-/g, ' ');
        }
        this.get_top_8();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8/${this.filter_formatted}`
                },
            ];
        },
        get_top_8: function() {
            this.render_top8 = false;
            this.$http.post('/finance/after-sales-delivery-share/top-8', {
                filter: this.filter  
            }).then(
                succ => {
                    this.top_8.xcategories = [];
                    this.top_8.seriesset1 = [];
                    this.top_8.seriesset2 = [];
                    this.top_8.seriesset4 = [];

                    let data = succ.data;
                    this.top_8.xcategories =data.map(d => d.caption);
                    data.map(d => {
                        let set = Object.values(d.measures);
                        this.top_8.ranking.push(d.measures.Rank);

                        this.top_8.seriesset1.push(set[0]);
                        this.top_8.seriesset2.push(set[1]);
                        this.top_8.seriesset4.push(set[2]);
                    });
                    this.render_top8 = true;
                },
                this.$helper.error
            );
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.get_top_8();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        },
        filter: function() {
            this.$router.push(this.filter_url);
            this.get_top_8();
        },
        '$route': function(to, from) {
            if (to.params.option === 'null') {
                this.$router.push(this.filter_url);
            }
        }
    },
    computed: {
        filter_formatted:  function() {
            return this.filter ? this.filter.replace(/ /g, '-').replace(/_/g, '-') : null;
        },
        filter_url: function() {
            return `${this.location}/top-8/${this.filter_formatted}`
        }
    }
}
</script>

<style scoped>
</style>