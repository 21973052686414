<template>
    <div v-if="render" class="tile is-child box">
      <div class="title">{{ order[index] }}</div>
      <div v-for="(area, areaIndex) in menu_items[find_kpi_area(order[index])].areas" :key="areaIndex" class="tile-content"> 
        <div class="flex full-width cursor-pointer kpi-dropdown-item" @click="!auto_expanded_areas.includes(area.name) ? click_kpi(area) : ''" :style="{ backgroundColor:  colors_disabled ? '' :  area.color || '' }">
          <i v-if="area.areas && !mobile_mode" :class="`fas ${!auto_expanded_areas.includes(area.name) ?  `fa-chevron-${active_kpis.includes(area.name)  ? 'up' : 'down'}` : ''}`"></i>
          <div class="mlp5 mbp5" :class="mobile_mode && area.areas ? 'text-bold' : ''">
            {{ area.name }} 
          </div>
        </div>
        <div class="ml" v-if="area.areas && (active_kpis.includes(area.name) || mobile_mode)">
          <div v-for="(sub_area1, i) in area.areas" :key="i">
            <div v-if="!sub_area1.areas" class="cursor-pointer kpi-dropdown-item mlp25" @click="click_kpi(sub_area1)" :style="{ backgroundColor: colors_disabled ? '' :  sub_area1.color || '' }">
              <div class="item-no-area">{{ sub_area1.name }}</div>
            </div>
            <div v-else>
              <div class="flex kpi-dropdown-item cursor-pointer" @click="click_kpi(sub_area1)" :style="{ backgroundColor: sub_area1.color || '' }">
                <i v-if="sub_area1.areas && !mobile_mode" :class="`fas fa-chevron-${active_kpis.includes(sub_area1.name) ? 'up' : 'down'}`"></i>
                <div class="mlp5 mbp5" :class="mobile_mode && sub_area1.areas ? 'text-bold' : ''">
                  {{ sub_area1.name }}
                </div>
              </div>
              <div class="ml" v-if="sub_area1.areas && (active_kpis.includes(sub_area1.name) || mobile_mode)">
                <div v-for="(sub_area2, j) in sub_area1.areas" :key="j">
                  <div v-if="!sub_area2.areas" class="kpi-dropdown-item cursor-pointer mlp25" @click="click_kpi(sub_area2)"  :style="{ backgroundColor:  colors_disabled ? '' :  sub_area2.color || '', }">
                    <div class="item-no-area">{{ sub_area2.name }}</div>
                  </div>
                  <div v-else>
                    <div class="flex kpi-dropdown-item cursor-pointer" @click="click_kpi(sub_area2)" :style="{ backgroundColor:  colors_disabled ? '' :  sub_area2.color || '', }">
                      <i v-if="sub_area2.areas && !mobile_mode" :class="`fas fa-chevron-${active_kpis.includes(sub_area2.name) ? 'up' : 'down'}`"></i>
                      <div class="mlp5 mbp5" :class="mobile_mode && sub_area2.areas ? 'text-bold' : ''">
                        {{ sub_area2.name }}
                      </div>
                    </div>
                    <div class="ml" v-if="sub_area2.areas && (active_kpis.includes(sub_area2.name) || mobile_mode)">
                      <div v-for="(sub_area3, k) in sub_area2.areas" :key="k">
                        <div v-if="!sub_area3.areas" class="kpi-dropdown-item cursor-pointer mlp25" @click="click_kpi(sub_area3)" :style="{ backgroundColor:  colors_disabled ? '' :  sub_area3.color || '', }">
                          <div class="item-no-area">{{ sub_area3.name }}</div>
                        </div>
                        <div v-else>
                          <div class="flex kpi-dropdown-item cursor-pointer" @click="click_kpi(sub_area3)" :style="{ backgroundColor:  colors_disabled ? '' :  sub_area3.color || '', }">
                            <i v-if="sub_area3.areas && !mobile_mode" :class="`fas fa-chevron-${active_kpis.includes(sub_area3.name) ? 'up' : 'down'}`"></i>
                            <div class="mlp5 mbp5" :class="mobile_mode && sub_area3.areas ? 'text-bold' : ''">
                              {{ sub_area3.name }}
                            </div>
                          </div>
                          <div class="ml" v-if="sub_area3.areas && (active_kpis.includes(sub_area3.name) || mobile_mode)">
                            <div v-for="(sub_area4, m) in sub_area3.areas" :key="m">
                              <div v-if="!sub_area4.areas" class="kpi-dropdown-item cursor-pointer mlp25" @click="click_kpi(sub_area4)" :style="{ backgroundColor:  colors_disabled ? '' :  sub_area4.color || '', }">
                                <div class="item-no-area">{{ sub_area4.name }}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
  </template>
  
<script>
import MenuOptions from '@/assets/scripts/value-creation-mapping.js';
export default {
    props: {
        index: Number
    },
    data: function() {
        return {
            active_kpis: [],
            menu_items: [],
            auto_expanded_areas: ["Climate footprint"],
            order: ['Better Homes', 'Better Planet', 'Better Lives', 'Better Company'],
            render: false,
            colors_disabled: process.env.VUE_APP_DISABLE_KPI_COLORS ?  process.env.VUE_APP_DISABLE_KPI_COLORS === "true" : false
        }
    },
    created: function () {
        // this.loadMenuItems();
        this.menu_items =[...MenuOptions]
    },
    mounted: function() {
        this.remove_disabled_kpis();
        this.render = true;
        this.auto_expanded_areas.map((area) => {
          this.toggle_area(area)
        })
        
    },
    methods: {
        loadMenuItems: async function () {
            try {
                const response = await this.$http.get(
                    "kpi-lists/kpi-value-creation"
                );
                this.menu_items = [...response.data];
            } catch (error) {
                console.error("Failed to load KPI data:", error);
            }
        },

        click_kpi: function(area) {
            if (area.areas) {
                if (this.mobile_mode) {
                    this.route_to(area.areas[0].path);
                } else {
                    this.toggle_area(area.name);
                    let height_of_dropdown_item = document.getElementById('navbar-dropdown').offsetHeight;
                    let height_of_window = window.innerHeight - (parseInt(getComputedStyle(document.getElementById('navbar')).fontSize) * 3.25);
                    if (height_of_dropdown_item >= height_of_window) {
                        document.getElementById('navbar-dropdown').style.height = `${height_of_window}px`;
                        document.getElementsByClassName('dropdown-column').forEach(column => {
                            column.style.maxHeight = `${height_of_window - 10}px`;
                            column.style.overflow = 'auto';
                        });
                    } else {
                        document.getElementById('navbar-dropdown').style.height = `max-content`;
                    }
                }
            } else {
                this.route_to(area.path);
                document.getElementById("navbar-dropdown").style.display = 'none';
            }
        },
        remove_disabled_kpis: function() {
            this.$http.get('/disabled-kpis/get').then(
                succ => {
                    if (!succ.data) return;
                    const disabled_kpis = succ.data.map(({ kpi, kpi_area, market_area, store_area }) => {
                        let path = `/${kpi}/${kpi_area}`;
                        if (market_area) path += `/${market_area}`;
                        if (store_area) path += `/${store_area}`;
                        return path;
                    });

                    const includes = (arr, ...items) => {
                        for (let item of items) {
                            if (arr.includes(item)) return true;
                        }
                        return false;
                    };
                    
                    this.menu_items.forEach(kpi => kpi.areas = kpi.areas.filter(area => !disabled_kpis.includes(area.path)));

                    this.menu_items.forEach(kpi => kpi.areas.forEach(area => {
                        if (area.areas) {
                            area.areas = area.areas.filter(market => !includes(disabled_kpis, market.path, market.location));
                        }
                    }));                    
                },
                error => console.log(error)
            );
        },
        route_to: function(location) {
            this.$router.push(location);
        },
        toggle_area: function(name) {
            let index = this.active_kpis.indexOf(name);
            if (index > -1) {
                this.active_kpis.splice(index, 1);
            } else {
                this.active_kpis.push(name);
            }
        },
        find_kpi_area: function(kpi_area) {
            return this.menu_items.findIndex(area => area.name === kpi_area);
        }
    },
    computed: {
        mobile_mode: function() {
            return document.documentElement.clientWidth < 1024;
        }
    }
}
</script>

<style scoped>
@media screen and (max-width: 1024px) {
    .tile, .tile-content {
        width: calc(100vw - 1.5rem) !important;
        word-break: normal;
    }
}


.box {
    box-shadow: none !important;
    padding: 0 !important;
}

.kpi-dropdown-item {
    padding-top: 0.3rem;
    padding-left: 0.5rem;
    border-radius: 0.5rem;
}

.kpi-dropdown-item:hover {
    color: #3273dc;
    background-color: #e2e2e2;
}

.item-no-area {
    padding: 0 0.75rem 0.5rem 0.75rem;
}

.title {
    font-size: 1.1rem;
    margin-bottom: 0.5rem !important;
}

.tile, .tile-content {
    width: calc(20vw - 1.5rem);
}

.tile-content {
    white-space: normal;
}

.fas {
    margin-top: 0.2rem;
}
</style>