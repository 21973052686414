<template>
    <div :id="title" style="width: 100%; height:100%;"></div>
</template>

<script>
import * as echarts from 'echarts/dist/echarts.min.js';
export default {
    props: {
        title: String, //title of the graph
        showtitle: Boolean,
        subtitle: String, // subtitle of the graph
        xcategories: Array, //data that will be displayed on the x axis
        yaxislabel1: String, //name of the data on the y axis e.g 'After sales delivery share' or 'services sale by month'
        ymetric1: String, //'%', '£', 'kg' etc.
        yaxislabel2: String,
        ymetric2: String,
        yaxismin1: Number, //set the minimum value of the the y axis
        yaxismax1: Number, //set the maximum value of the the y axis
        yaxismin2: Number,
        yaxismax2: Number,
        seriesname1: String, //name of the data such as IKEA 
        seriestype1: String, //bar, line, scatter etc.
        seriesset1: Array, //actual data array to fill the graph
        color1: String, //color of the bar lie or scatter for the paticular data set
        seriesname2: String,
        seriestype2: String,
        seriesset2: Array,
        color2: String,
        seriesname3: String,
        seriestype3: String,
        seriesset3: Array,
        color3: String,
        seriesname4: String,
        seriestype4: String,
        seriesset4: Array,
        color4: String,
        seriesname5: String,
        seriestype5: String,
        seriesset5: Array,
        color5: String,
        show: Boolean, // true to show the exta features such as 'save graph as image', 'refresh', 'change to bar graph', etc
        trend: Boolean,
        trendInvert: Boolean,
    },
    mounted: function() {
        let myChart = echarts.init(document.getElementById(this.title));
        let div_width = document.getElementById(`${this.title}`).clientWidth;
        let width_factor = 0.75;
        let offset_factor = 4.55;
        let countries = [];

        this.xcategories.forEach(category => {
            countries.push(category.replace(/ /g, "\n").replace(/Total\nRetail/g, "Total Retail"));
        });

        let above = [];
        let below = [];
        const push_above = (data) => {
            above.push(data);
            below.push(null);
        }
        const push_below = (data) => {
            below.push(data);
            above.push(null);
        }
        if (this.trend) {
            for (let i = 0; i < this.seriesset4.length; i++) {
                if (this.seriesset4[i] >= parseFloat(this.seriesset2[i])) {
                    push_above(this.seriesset4[i]);
                } else {
                    push_below(this.seriesset4[i]);
                }
            }
        }
        
        let option = {
            title:{
                text: this.title,
                subtext: this.subtitle,
                show: this.showtitle,
                subtextStyle: {
                    color: 'black',
                },
                top: '-2em',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            toolbox: {
                top: '50em',
                right: '70em',
                show: this.show,
                feature: {
                    restore: {},
                    saveAsImage: {}
                }
            },
            grid: {
               top: '80em',
               left: '70vh',
               bottom: '40em'
            },
            legend: {
                top: '35em',
                left: '0em',
                itemGap: 15,
                data:[
                    
                    this.seriesname2, 
                    this.seriesname3,
                    this.trend ? null : this.seriesname4,
                    this.trend ? `${this.seriesname4} above ${this.seriesname2}` : null,
                    this.trend ? `${this.seriesname4} below ${this.seriesname2}`: null,
                    this.seriesname1,
                    this.seriesname5,
                ]
            },
            textStyle: {
                fontFamily: 'NotoIKEALatin-Regular',
            },
            xAxis: [
                {
                    type: 'category',
                    data: countries,
                    axisPointer: {
                        type: 'shadow'
                    },
                    axisLabel: {
                        interval: '0',
                    },
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: this.yaxislabel1,
                    nameLocation: 'middle',
                    nameGap: 50,
                    axisLabel: {
                        formatter: `{value} ${this.ymetric1}`
                    }
                },
                {
                    type: 'value',
                    name: this.yaxislabel2,
                    nameLocation: 'middle',
                    nameGap: 70,
                    nameRotate: '270',
                    axisLabel: {
                        formatter: `{value} ${this.ymetric2}`
                    }
                }
            ],
            series: [
                {
                    name: this.seriesname2,
                    type: this.seriestype2,
                    data: this.seriesset2,
                    color: this.color2,
                    barGap: 0,
                    label: {
                        show: this.seriestype2 === 'bar',
                        align: 'center',
                        verticalAlign: 'middle',
                        position: 'insideTop',
                        distance: 20,
                        color: 'black',
                        fontSize: 11
                    },
                },
                {
                    name: this.seriesname1,
                    type: this.seriestype1,
                    data: this.seriesset1,
                    color: this.color1,
                    yAxisIndex: 1,
                    label: {
                        show: this.seriestype2 === 'bar',
                        align: 'center',
                        verticalAlign: 'middle',
                        position: 'top',
                        distance: 20,
                        color: 'black',
                        fontSize: 11
                    },
                },
                {
                    name: this.seriesname3,
                    type: this.seriestype3,
                    data: this.seriesset3,
                    color: this.color3,
                    yAxisIndex: 1,
                    label: {
                        show: this.seriestype3 === 'bar',
                        align: 'center',
                        verticalAlign: 'middle',
                        position: 'top',
                        distance: 20,
                        color: 'black',
                        fontSize: 11
                    },
                },
                this.scatter(false, true, this.seriesset4, div_width, width_factor, offset_factor, this.color4),
                {
                    name: this.seriesname5,
                    type: this.seriestype5,
                    symbol: this.seriestype5 === 'scatter' ? 'rect' : 'none',
                    symbolSize:  this.seriestype5 === 'scatter' ? [(div_width / this.xcategories.length * width_factor), 2] : 10,
                    data: this.seriesset5,
                    color: this.color5
                },
                this.scatter(true, true, above, div_width, width_factor, offset_factor, `${this.trendInvert? 'red' : 'green'}`),
                this.scatter(true, false, below, div_width, width_factor, offset_factor, `${this.trendInvert? 'green' : 'red'}`)
            ]
        };
        myChart.setOption(option);
    },
    methods: {
        scatter: function(comparison, above, dataset, div_width, width_factor, offset_factor, color) {
            if (comparison === this.trend) {
                return  {
                    name: this.trend ? above ? `${this.seriesname4} above ${this.seriesname2}` : `${this.seriesname4} below ${this.seriesname2}`: this.seriesname3,
                    type: this.seriestype4,
                    data: dataset,
                    color: color,
                    symbol: this.seriestype4 === 'scatter' ? 'rect' : 'circle',
                    symbolSize:  this.seriestype4 === 'scatter' ? [(div_width / (this.xcategories.length * width_factor * 3.5)), 6] : 10,
                    symbolOffset: this.seriestype4 === 'scatter' ? [-(div_width / (13 * offset_factor)), 0] : 0,
                    barGap: '0',
                    label: {
                        show: this.seriestype4 === 'bar',
                        align: 'center',
                        verticalAlign: 'middle',
                        position: 'insideTop',
                        distance: 20,
                        color: 'black',
                    },
                }
            } else { 
                return null
            }
            
        }
    }
}
</script>