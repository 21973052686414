<template>
<div>
    <div class="title">Access Summary</div>
    <div class="access-title">Your level of access is: {{access_template_name}}</div>
    <div class="box mt ml mr">
        Click <a @click="raise_access">here</a> if you would like to raise your level of access.
    </div>
    <div class="text-very-large text-left ml">Your access at a glance</div>
    <AccessNode
        :node="$store.state.access.access_template"
    />
</div>
</template>

<script>
import AccessNode from '@/components/bespoke/access-summary/AccessNode.vue';
export default {
    components: {
        AccessNode
    },
    methods: {
        raise_access: function() {
            let reset_access = confirm("This will reset your current access as if you were a new user, are you sure you'd like to continue?");
            if (reset_access) {
                this.$store.commit('set_access_request_show', true);
            }
        }
    },
    mounted: function() {
        this.$store.state.commenting.width = 0;
    },
    destroyed: function() {
        this.$store.state.commenting.width = 4;
    },
    computed: {
        access_template_name: function() {
            return this.$store.getters.access_template_name;
        }
    }
}
</script>

<style scoped>
.access-title {
    color: #ffda1a;
    background-color: #0051ba;
    font-size: 2rem;
    font-weight: 600;
    padding: 1em;
    width: 100%;
}
</style>