<template>
<div>
    <Title
        title="Financial Services"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms">
        <Graph
            v-if="graph.render"

            :title="`Showing data for: ${$store.getters.selected_country_name}`"
            :show-title="!$store.state.global.bot_mode"
            
            v-bind="graph"
        />
        <div v-else> No data found for {{$store.getters.selected_country_name}}</div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Graph from '@/components/visualisations/general/Graph.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Graph,
        Title
    },
    props: {
        market: String
    },
    data: function() {
        return {
            graph: {
                colors: [
                    this.$store.state.colors['light-grey'],
                    this.$store.state.colors['yellow'],
                    this.$store.state.colors.YG
                ],
                data_sets: [],
                names: ['Month', 'YTD', 'Goal'],
                data_names: ['Month', 'YTD', 'Goal'],
                types: ['line', 'line', 'line'],
                x_categories: null,
                y_axis_label: 'Amount spent on Financial Services (Share of sales %)',
                y_metric: '%',

                render: false
            },
            location: '/business/financial-services'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        this.get_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
            ];
        },
        get_data: function() {
            this.graph.render = false;
            this.$http.post('/business/financial-services/market', {
                market: this.$store.getters.market === null ? 'ALL' : this.$store.getters.market
            }).then(
                succ => {
                    let d = succ.data;
                    if (!d[0] || !d[0].measures) return;
                    this.graph.x_categories = Object.keys(d[0].measures);
                    this.graph.data_sets= [[], [], []];
                    this.graph.x_categories.forEach(month => {
                        let val = d[0].measures[month];
                        this.graph.data_names.forEach((data_name, i) => {
                            this.graph.data_sets[i].push(this.$helper.maybe(this.$helper.round(val[data_name], 2), null));
                        });
                    });
                    this.graph.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {	
            this.$store.commit('update_view', {	
                market: market,
                location: this.location
            });	
        }
    },
    watch: {
        '$store.getters.countries': function() {	
            this.$store.commit('update_market', this.market);	
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.get_data();
        }
    }
}
</script>