var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "custom-title", attrs: { id: "title-header-container" } },
    [
      _c("div", { staticClass: "title" }, [
        _vm._v(" " + _vm._s(_vm.page_details.kpi_title) + " "),
        _c(
          "div",
          {
            staticClass: "cursor-pointer inline-block",
            on: { click: _vm.show_kpi },
          },
          [
            _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.$store.getters.presentation_mode,
                  expression: "!$store.getters.presentation_mode",
                },
              ],
              staticClass: "fas fa-info-circle superscript text-ikea-blue",
            }),
          ]
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show_kpi_defintion,
              expression: "show_kpi_defintion",
            },
          ],
        },
        [
          _vm.is_loaded.kpi_definition
            ? _c("div", [
                _vm.kpi_definition
                  ? _c(
                      "div",
                      [
                        _c("b", [_vm._v("Definition:")]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "column ProseMirror",
                          domProps: { innerHTML: _vm._s(_vm.kpi_definition) },
                        }),
                        _c("hr"),
                        this.definition_id
                          ? _c("DefinitionRatingButtons", {
                              attrs: {
                                id: this.definition_id,
                                current: this.rating,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _c("div", [
                _c("b", [_vm._v("Definition:")]),
                _vm._v(" "),
                _c("div", { staticClass: "column ProseMirror" }, [
                  _vm._v(" This KPI definition hasn't been defined. "),
                ]),
              ]),
        ]
      ),
      _c("div", {}, [
        _vm.page_details.kpi_subtitle
          ? _c("div", { staticClass: "subtitle pbp5" }, [
              _c("p", [_vm._v(_vm._s(_vm.page_details.kpi_subtitle))]),
            ])
          : _vm._e(),
        _vm.page_details.kpi_accountable
          ? _c("div", { staticClass: "subtitle" }, [
              _c("b", [_vm._v("KPI Accountable")]),
              _vm._v(": " + _vm._s(_vm.page_details.kpi_accountable) + " "),
            ])
          : _vm._e(),
        _vm.page_details.kpi_last_updated
          ? _c("div", { staticClass: "subtitle" }, [
              _c("b", [_vm._v("Last updated:")]),
              _vm._v(" " + _vm._s(_vm.page_details.kpi_last_updated) + " "),
            ])
          : _vm._e(),
        _vm.page_details.kpi_load_status
          ? _c("div", { staticClass: "subtitle" })
          : _vm._e(),
        _vm.show_view_more
          ? _c("div", [
              _vm.page_details.kpi_frequency
                ? _c("div", { staticClass: "subtitle" }, [
                    _c("b", [_vm._v("Frequency:")]),
                    _vm._v(" " + _vm._s(_vm.page_details.kpi_frequency) + " "),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.page_details.kpi_frequency || _vm.page_details.kpi_next_update
          ? _c(
              "div",
              {
                staticClass: "view-more",
                on: {
                  click: function ($event) {
                    _vm.show_view_more = !_vm.show_view_more
                  },
                },
              },
              [
                _vm.show_view_more
                  ? _c("span", [
                      _vm._v("View less "),
                      _c("i", { staticClass: "fas fa-chevron-up" }),
                    ])
                  : _c("span", [
                      _vm._v("View more "),
                      _c("i", { staticClass: "fas fa-chevron-down" }),
                    ]),
              ]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }