var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("em", { staticClass: "is-size-4" }, [_vm._v("Required content")]),
      _c("Title", {
        attrs: { title: "Admin Kitchen Sink", subtitle: "Optional subtitle" },
      }),
      _c("hr"),
      _c("em", { staticClass: "is-size-4" }, [_vm._v("Variable content")]),
      _c(
        "div",
        { staticClass: "has-text-left" },
        [
          _vm._m(0),
          _c("br"),
          _c("em", [_vm._v("Basic Text input")]),
          _c("div", { staticClass: "field" }, [
            _c("label", { staticClass: "label" }, [_vm._v("Text input label")]),
            _c("div", { staticClass: "control" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.input.basic_input,
                    expression: "input.basic_input",
                  },
                ],
                staticClass: "input",
                attrs: { type: "textinput", placeholder: "Placeholder Text" },
                domProps: { value: _vm.input.basic_input },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.input, "basic_input", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _c("br"),
          _c("em", [_vm._v("Advanced Text input (icons and reactive helper)")]),
          _c("div", { staticClass: "field" }, [
            _c("label", { staticClass: "label" }, [_vm._v("Text input label")]),
            _c(
              "div",
              { staticClass: "control has-icons-left has-icons-right" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.input.advanced_input,
                      expression: "input.advanced_input",
                    },
                  ],
                  staticClass: "input",
                  attrs: { type: "textinput", placeholder: "Placeholder Text" },
                  domProps: { value: _vm.input.advanced_input },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.input, "advanced_input", $event.target.value)
                    },
                  },
                }),
                _vm._m(1),
                _vm._m(2),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "help",
                class: [_vm.input.is_valid ? "is-success" : "is-danger"],
              },
              [_vm._v(_vm._s(_vm.input.helper_message))]
            ),
          ]),
          _c("br"),
          _c("em", [_vm._v("Checkbox")]),
          _c("div", { staticClass: "field" }, [
            _c("div", { staticClass: "control" }, [
              _c("label", { staticClass: "checkbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.checkbox,
                      expression: "checkbox",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.checkbox)
                      ? _vm._i(_vm.checkbox, null) > -1
                      : _vm.checkbox,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.checkbox,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.checkbox = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.checkbox = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.checkbox = $$c
                      }
                    },
                  },
                }),
                _vm._v(" I want this to be true "),
              ]),
            ]),
          ]),
          _c("br"),
          _c("em", [_vm._v("Radio Buttons")]),
          _c("div", { staticClass: "field" }, [
            _c("div", { staticClass: "control" }, [
              _c("label", { staticClass: "radio mr" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.radio,
                      expression: "radio",
                    },
                  ],
                  attrs: { value: "true", type: "radio", name: "radio" },
                  domProps: { checked: _vm._q(_vm.radio, "true") },
                  on: {
                    change: function ($event) {
                      _vm.radio = "true"
                    },
                  },
                }),
                _vm._v(" Yes "),
              ]),
              _c("label", { staticClass: "radio mr" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.radio,
                      expression: "radio",
                    },
                  ],
                  attrs: { value: "false", type: "radio", name: "radio" },
                  domProps: { checked: _vm._q(_vm.radio, "false") },
                  on: {
                    change: function ($event) {
                      _vm.radio = "false"
                    },
                  },
                }),
                _vm._v(" No "),
              ]),
            ]),
          ]),
          _c("br"),
          _c("em", [_vm._v("Dropdown")]),
          _c("div", { staticClass: "field" }, [
            _c("label", { staticClass: "label" }, [_vm._v("Dropdown label")]),
            _c("div", { staticClass: "control" }, [
              _c("div", { staticClass: "select" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.dropdown.selection,
                        expression: "dropdown.selection",
                      },
                    ],
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.dropdown,
                            "selection",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.dropdown_trigger,
                      ],
                    },
                  },
                  _vm._l(_vm.dropdown.options, function (option, i) {
                    return _c(
                      "option",
                      { key: i, domProps: { value: _vm.dropdown.options[i] } },
                      [_vm._v(" " + _vm._s(option) + " ")]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
          _c("em", [
            _vm._v("or alternatively use existing dropdown component"),
          ]),
          _c("br"),
          _c("DropDown", {
            attrs: {
              options: _vm.dropdown.options,
              values: _vm.dropdown.options,
            },
            on: { updateDropDown: _vm.dropdown_trigger },
          }),
          _vm._m(3),
          _c("div", { staticClass: "field has-addons" }, [
            _c("div", { staticClass: "control has-icons-left" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.input.search,
                    expression: "input.search",
                  },
                ],
                staticClass: "input",
                attrs: { type: "textinput", placeholder: "Search for..." },
                domProps: { value: _vm.input.search },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.input, "search", $event.target.value)
                  },
                },
              }),
              _vm._m(4),
            ]),
            _c("div", { staticClass: "control" }, [
              _c(
                "button",
                { staticClass: "button business", on: { click: _vm.search } },
                [_vm._v("Search")]
              ),
            ]),
          ]),
          _vm._m(5),
          _c("div", { staticClass: "table-container" }, [
            _vm.table.render
              ? _c("table", { staticClass: "table is-narrow" }, [
                  _c("thead", [
                    _c(
                      "tr",
                      { staticClass: "business" },
                      _vm._l(_vm.table.headers, function (header, i) {
                        return _c("th", { key: i }, [_vm._v(_vm._s(header))])
                      }),
                      0
                    ),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.table.rows, function (row, j) {
                      return _c("tr", { key: j }, [
                        _c("td", [_vm._v(_vm._s(row.name))]),
                        _c("td", [_vm._v(_vm._s(row.age))]),
                        _c("td", [_vm._v(_vm._s(row.colour))]),
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "button is-danger",
                              on: {
                                click: function ($event) {
                                  return _vm.delete_row(j)
                                },
                              },
                            },
                            [_vm._v(" Delete ")]
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "is-size-5" }, [
      _c("em", [_vm._v("Form elements")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small is-left" }, [
      _c("i", { staticClass: "fas fa-user" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small is-right" }, [
      _c("i", { staticClass: "fas fa-globe" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "is-size-5 mt" }, [
      _c("em", [_vm._v("Search field")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon is-small is-left" }, [
      _c("i", { staticClass: "fas fa-search" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "is-size-5 mt" }, [
      _c("em", [_vm._v("Table (alternatively TableV2)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }