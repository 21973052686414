<template>
    <div class="options-bar">
        <div class="dropdown">
            <div class="dropdown-trigger">
                <div class="field">
                    <p class="control is-expanded has-icons-right">
                        <input class="input" type="search" placeholder="Search..." v-model="searched" />
                        <span class="icon is-small is-right"><i class="fas fa-search"></i></span>
                    </p>
                </div>
            </div>
            <div class="dropdown-menu" id="dropdown-menu" role="menu" v-if="search_render && searched_list.length > 0">
                <div class="dropdown-content">
                    <div
                        class="search-items"
                        v-for="(item, index) in searched_list"
                        :key="index"
                        @click="set_name(item)"
                    >
                        {{ item.display_name }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        display_name: String,
        user_id: Number
    },
    data: function () {
        return {
            searched: null,
            search_render: false,
            should_search: true,
            searched_list: []
        };
    },
    mounted: function () {
        if (this.display_name && this.user_id) {
            this.should_search = false;
            this.searched = this.display_name;
        }
    },
    methods: {
        set_name: function (item) {
            this.searched = item ? item.display_name : '';
            this.search_render = false;
            this.should_search = false;
            this.$emit('SelectedUser', item || null);
        }
    },
    watch: {
        searched: function () {
            if (this.should_search && this.searched.length > 2) {
                this.$http.post('/user/user-search', { name: this.searched }).then(
                    succ => {
                        let d = succ.data;
                        this.search_render = false;
                        this.searched_list = [];
                        d.map(user => {
                            this.searched_list.push(user);
                        });
                        this.search_render = true;
                    },
                    error => console.log(error)
                );
            } else {
                this.searched_list = [];
                if (this.searched.length === 0) {
                    this.set_name(null);
                }
            }
            this.should_search = true;
        }
    }
};
</script>

<style scoped>
.search-items:hover {
    cursor: pointer;
    background-color: lightgrey;
    color: rgb(80, 80, 228);
}
.input {
    width: auto;
}
.dropdown-menu {
    display: block;
    max-height: 20vh;
    overflow-y: scroll;
    /* overflow-x: hidden; */
}
.dropdown-label {
    padding-top: 0.5em;
}
</style>
