var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", { attrs: { title: "Sales Tree", location: _vm.location } }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons() },
          }),
          _c(
            "div",
            {
              staticClass: "label-container",
              class: !_vm.$store.getters.market ? "ft-tooltip" : null,
              attrs: {
                tooltiptext: !_vm.$store.getters.market
                  ? "Select a country from top filter to see stores"
                  : null,
              },
            },
            [
              _c("p", [_vm._v("Market Area")]),
              _c("DropDown", {
                key: _vm.stores.values.length,
                attrs: {
                  options: _vm.stores.options,
                  values: _vm.stores.values,
                  default: _vm.stores.values[0],
                },
                on: { updateDropDown: _vm.update_store },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "label-container" },
            [
              _c("p", [_vm._v("Channel")]),
              _c("DropDown", {
                key: _vm.filter,
                attrs: {
                  options: _vm.options,
                  values: _vm.values,
                  default: _vm.filter,
                },
                on: { updateDropDown: _vm.update_filter },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "label-container" },
            [
              _c("p", [_vm._v("Period")]),
              _c("DropDown", {
                key: _vm.filter2,
                attrs: {
                  options: _vm.options2,
                  values: _vm.values2,
                  default: _vm.filter2,
                },
                on: { updateDropDown: _vm.update_filter2 },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                key: _vm.$store.getters.market,
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: _vm.$store.getters.market,
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size,
          staticClass: "section three-quarters-height box ms",
        },
        [
          _vm.graph.render
            ? _c(
                "Graph",
                _vm._b(
                  {
                    attrs: {
                      title: `Showing data for: ${_vm.$store.getters.selected_country_name}`,
                      "show-title": !_vm.$store.state.global.bot_mode,
                    },
                  },
                  "Graph",
                  _vm.graph,
                  false
                )
              )
            : _c("div", [
                _vm._v("There is no data for "),
                _c("b", [
                  _vm._v(_vm._s(_vm.$store.getters.selected_country_name)),
                ]),
                _vm._v(" with the "),
                _c("b", [
                  _vm._v(_vm._s(_vm.options[_vm.values.indexOf(_vm.filter)])),
                ]),
                _vm._v(" and "),
                _c("b", [
                  _vm._v(
                    _vm._s(_vm.options2[_vm.values2.indexOf(_vm.filter2)])
                  ),
                ]),
                _vm._v(" filters selected"),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }