var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "field" }, [
    _c("div", { staticClass: "control" }, [
      _c(
        "button",
        {
          staticClass: "button",
          class: _vm.classes,
          on: { click: _vm.method },
        },
        [_vm._v(_vm._s(_vm.label))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }