<template>
<div id="svgMap"></div>
</template>

<script>
import svgMap from 'svgmap';
import 'svgmap/dist/svgMap.min.css';
export default {
    props:{
        values: Object,
        settings: Object,
        colors: Object,
        //example
        // YTD: {
        //     name: 'YTD (Most Recent)',
        //     format: '{0} %',
        //     thousandSeparator: ',',
        //     thresholdMax: 50000,
        //     thresholdMin: 1000,
        // },
        // 'Yearly Goal': {
        //     name: 'Yearly Goal',
        //     format: '{0} %'
        // },
        mainDataName: String
    },
    mounted: function() {
        let key = Object.keys(this.values)[0];
        new svgMap({
            targetElementID: 'svgMap',
            data: {
                data: this.settings,
                applyData: this.mainDataName || Object.keys(this.values[key])[0],
                values: this.values
            },
            colorMax: this.colors && this.colors.max || '#0051ba',
            colorMin: this.colors && this.colors.min || '#FDDB2A'
        });
    }
}
</script>

<style>

</style>