<template>
<div class="has-text-left">
	<label class="label">
		Select KPI Area
	</label>
	<DropDown
		v-if="kpi_areas"

		:key="kpi_areas[0]"
		:options="kpi_areas"
		:values="kpi_areas"
		:default="kpi_areas[0]"
		@updateDropDown="updated_areas_dropdown"

		class="float-left"
	/>
	
	<DropDown
		v-if="kpi_lvl1"

		:key="kpi_lvl1[0]"
		:options="kpi_lvl1"
		:values="kpi_lvl1"
		:default="kpi_lvl1[0]"
		@updateDropDown="updated_lvl1_dropdown"

		class="float-left"
	/>
	
	<DropDown
		v-if="selected_kpi_lvl2"

		:key="kpi_lvl2[0]"
		:options="kpi_lvl2"
		:values="kpi_lvl2"
		:default="kpi_lvl2[0]"
		@updateDropDown="updated_lvl2_dropdown"

		class="float-left"
	/>
</div>
</template>

<script>
import DropDown from '@/components/ui/DropDown.vue';
import MenuOptions from '@/assets/scripts/kpi-data-mapping.js';
export default {
    components: {
		DropDown
    },
    data: function() {
		return {
			selected: 'Exisiting',
			options: MenuOptions,
			search_kpi_areas: [],
			search_kpi: 'ALL',
			kpi_areas: [],
			kpi_lvl1: [],
			kpi_lvl2: [],
			selected_kpi_area: null,
			selected_kpi_lvl1: null,
			selected_kpi_lvl2: null,
			url_kpi_area: null,
			url_kpi_lvl1: null,
			url_kpi_lvl2: null,
			link: null
		}
	},
	mounted: function() {
		this.options.map( kpi_area => {
			this.kpi_areas.push(kpi_area.name);
			this.search_kpi_areas.push(kpi_area.name);
		});
		this.options[0].areas.map( kpi_lvl1 => {
			this.kpi_lvl1.push(kpi_lvl1.name)
		});
		this.updated_areas_dropdown(this.kpi_areas[0]);
	},
	methods: {
		updated_areas_dropdown: function(kpi_area) {
			this.kpi_lvl1 = [];
			this.kpi_lvl2 = [];
			this.selected_kpi_area = kpi_area;

			for (let i = 0; i < this.options.length; i++) {
				if (this.options[i].name === this.selected_kpi_area) {
					for (let j = 0; j < this.options[i].areas.length; j++) {
						this.kpi_lvl1.push(this.options[i].areas[j].name);
					};
					this.link = this.options[i].areas[0].path;
					if (this.options[i].areas[0].areas) {
							for (let k = 0; k < this.options[i].areas[0].areas.length; k++) {
								this.kpi_lvl2.push(this.options[i].areas[0].areas[k].name);
							};
							this.selected_kpi_lvl2 = this.kpi_lvl2[0];
							this.link = this.options[i].areas[0].areas[0].path;
						}
						else {
							this.selected_kpi_lvl2 = null;
						}
					this.selected_kpi_lvl1 = this.kpi_lvl1[0];			
				};
			};
		},
		updated_lvl1_dropdown: function(kpi_lvl1) {
			this.selected_kpi_lvl1 = kpi_lvl1;
			this.kpi_lvl2 = [];
			for (let i = 0; i < this.options.length; i++) {
				if (this.options[i].name === this.selected_kpi_area) {
					for (let j = 0; j < this.options[i].areas.length; j++) {
						if (this.options[i].areas[j].name === this.selected_kpi_lvl1) {
							if (this.options[i].areas[j].areas) {
								for (let k = 0; k < this.options[i].areas[j].areas.length; k++) {
									this.kpi_lvl2.push(this.options[i].areas[j].areas[k].name);
								}
								this.link = this.options[i].areas[j].areas[0].path;
								this.selected_kpi_lvl2 = this.kpi_lvl2[0];
							}
							else {
								this.link = this.options[i].areas[j].path;
								this.selected_kpi_lvl2 = null;
							};
						};
					};					
				};
			};
		},
		updated_lvl2_dropdown: function(kpi_lvl2) {
			this.selected_kpi_lvl2 = kpi_lvl2;
			for (let i = 0; i < this.options.length; i++) {
				if (this.options[i].name === this.selected_kpi_area) {
					for (let j = 0; j < this.options[i].areas.length; j++) {
						if (this.options[i].areas[j].name === this.selected_kpi_lvl1) {
							if (this.options[i].areas[j].areas) {
								for (let k = 0; k < this.options[i].areas[j].areas.length; k++) {
									if (this.selected_kpi_lvl2 === this.options[i].areas[j].areas[k].name) {
										this.link = this.options[i].areas[j].areas[k].path;
									}
								}
							}
						};
					};					
				};
			};
		},
		send_location: function() {
			if (this.link) {
				let location_keys = ['kpi', 'kpi_area', 'market_area'];
				let route = this.link.split('/');
				let location_formatted = {};
				route.shift();
				location_keys.map((loc, index) => {
					if (route[index]) {
						location_formatted[loc] = route[index];
					} else {
						location_formatted[loc] = null;
					}
				});
				this.$emit('update', [
					location_formatted.kpi,
					location_formatted.kpi_area,
					location_formatted.market_area
				]);
			} else {
				this.$emit('update', [
                this.selected_kpi_area,
                this.selected_kpi_lvl1,
                this.selected_kpi_lvl2
			]);
			}
		}
    },
    watch: {
        selected_kpi_lvl1: function() {
            this.send_location();
        },
        selected_kpi_lvl2: function() {
            this.send_location();
        }
    }
}
</script>