<template>
<div>
    <Title
        title="Market Share Test"
        :location="location"
    />
    <div :key="$store.getters.page_size" id="market-share-trend" class="section half-height box ms">
        <Graph
            
            title="Sales per Week"
            :show-title="!$store.state.global.bot_mode"
            
            v-bind="graph"
        />
        
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Graph from '@/components/visualisations/general/Graph.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Graph,
        Title
    },
    data: function() {
        return {
            graph: {
                x_categories: [
                    '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47',
                    '48', '49', '50', '51', '52', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
                    '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24',
                    '25', '26', '27', '28', '29', '30', '31', '32', '33', '34'
                ],
                x_axis_label_interval: 1,
                y_axis_label: 'MEUR',
                y_metric: '',
                disabled_labels: [false, false, true, true, false],
                names: ['Store LY', 'Online + Remote LY', 'Store TY', 'Online + Remote TY', 'Goal TY'],
                data_sets: [
                    [620, 640, 630, 660, 620, 640, 630, 660,620, 640, 630, 660,620, 640, 630, 660,620, 640, 630, 660,620, 640, 630, 660,620, 640, 630, 660,620, 640, 630, 660,620, 640, 630, 660,620, 640, 630, 660,620, 640, 630, 660,620, 640, 630, 660,620, 640, 630, 660,],
                    [20, 40, 30, 60, 20, 40, 30, 60,20, 40, 30, 60,20, 40, 30, 60,20, 40, 30, 60,20, 40, 30, 60,20, 40, 30, 60,20, 40, 30, 60,20, 40, 30, 60,20, 40, 30, 60,20, 40, 30, 60,20, 40, 30, 60,20, 40, 30, 60,20, 40, 30, 60,],
                    [550, 540, 530, 560],
                    [120, 140, 130, 160],
                    [720, 740, 730, 760, 720, 740, 730, 760,720, 740, 730, 760,720, 740, 730, 760,720, 740, 730, 760,720, 740, 730, 760,720, 740, 730, 760,720, 740, 730, 760,720, 740, 730, 760,720, 740, 730, 760,720, 740, 730, 760,720, 740, 730, 760, 740, 730, 760, 740, 730, 760,],
                ],
                styling: [
                    {
                        areaStyle: {},
                        showSymbol: false,
                        z: 1,
                        lineStyle: {
                            width: 0
                        }
                    },
                    {
                        areaStyle: {},
                        showSymbol: false,
                        z: 1,
                        lineStyle: {
                            width: 0
                        }
                    },
                    {
                        z: 2,
                    },
                    {
                        z: 2,
                    },
                    {
                        smooth: true,
                        z: 3,
                        showSymbol: false,
                    }
                ],
                types: ['line', 'line', 'bar', 'bar', 'line'],
                stack: [2, 2, 1, 1, 0],
                colors: [
                    '#929292',
                    '#bcbcbc',
                    '#FDDB2A',
                    '#fecc0082',
                    '#0011BA'
                ],
            },

            location: '/business/total-sales/channel-contribution-per-country-fs'
        }
    }
}
</script>
