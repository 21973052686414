<template>
<div id="kitchensink">
    <Title
        title="Index of Power BI pages"
    />
    <div>An Index of prototype pages using an embedded Power BI report</div>
    <div class="section box">
        <ol>
            <li><a @click="$router.push(`/testing/power-bi/gross-margin`)">Gross Margin</a></li>
            <!-- <li><a @click="$router.push(`/testing/power-bi/brand-desire`)">Brand Desire</a></li> -->
            <!-- <li><a @click="$router.push(`/testing/power-bi/brand-trust`)">Brand Desire</a></li> -->
            <!-- <li><a @click="$router.push(`/testing/power-bi/furniture-that-fits`)">Furniture that fits my budget</a></li> -->
            <!-- <li><a @click="$router.push(`/testing/power-bi/penetration`)">Penetration</a></li> -->
            <!-- <li><a @click="$router.push(`/testing/power-bi/positive-impact`)">Positive Impact</a></li> -->
        </ol>
    </div>
</div>
</template>

<script>
import Title from '@/components/ui/Title.vue';
export default {
    components: {
        Title,
    },
    data: function() {
        return{
            location: '/testing/power-bi'
        }
    },
    mounted: function() {
        this.$store.state.commenting.width = 0;
    },
    destroyed: function() {
        this.$store.state.commenting.width = 4;
    },
}
</script>

<style scoped>
</style>