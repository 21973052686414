<template>
<div class="navbar-item dropdown is-right is-hoverable">
    <div class="dropdown-trigger">
        <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
            <span class="icon relative">
                <i class="fas fa-bell" aria-hidden="true"></i>
                <div class="main-badge" v-if="Object.keys(this.announcements).length > 0 && number_of_new > 0">
                    <div class="badge-text">{{number_of_new}}</div>
                </div>
            </span>
        </button>
    </div>
    <div class="dropdown-menu" id="dropdown-menu" role="menu">
        <div class="announcement-content"
            v-for="(announcement, index) in announcements"
            :key="index"
            @mouseover="announcement.seen ? '' : hover_over(announcement.id)"   
        >
            <div class="announcement-title">
                {{kpi_area(index)}}
                <div class="green-circle" v-if="!announcement.seen"></div>
            </div>
            <div class="announcement-subtitle">Last Updated: {{date_formatted(announcement.created)}}</div>
            <div class="announcement-body" v-html="announcement.content"></div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data: function() {
        return {
            announcements: {},
            number_of_new: 0
        }
    },
    mounted: function() {
        this.get_announcements();
    },
    methods: {
        count_number_of_new: function() {
            Object.keys(this.announcements).forEach(key => {
                if (this.announcements[key].seen === false) {
                    this.number_of_new ++;
                }
            });
        },
        remove_notification: function(id) {
            this.$http.post('/admin/announcements/seen', {
                announcement_id: id
            }).then(
                succ => {},
                error => console.log(error)
            );
            if (this.number_of_new > 0) this.number_of_new --;
        },
        get_announcements: function() {
            this.$http.get('/admin/announcements/get').then(
                succ => {
                    let announcement_list = {};
                    if (succ.data.length < 2) {return;}
                    succ.data[0].forEach( entry => {
                        announcement_list[entry.kpi ? entry.kpi : 'global'] = {
                            id: entry.id,
                            author: entry.display_name,
                            created: entry.created_at,
                            content: entry.content,
                            seen: false
                        };
                    });
                    this.announcements = announcement_list;
                
                    succ.data[1].forEach(row => {
                        this.announcements[row.kpi ? row.kpi : 'global'].seen = true
                    });
                    this.count_number_of_new();
                },
                error => console.log(error)
            );
        },
        kpi_area: function(area) {
            let kpi_area = area
                .replace(/-/g, " ")
                .replace(/three/g, "3");

            kpi_area = kpi_area.split(" ");

            for (let i = 0, x = kpi_area.length; i < x; i++) {
                kpi_area[i] = kpi_area[i][0].toUpperCase() + kpi_area[i].substr(1);
            }

            return kpi_area.join(" ");
        },
        date_formatted: function(date) {
            return date.split('T')[0];
        },
        hover_over: function(id) {
            this.remove_notification(id);
        }
    }
}
</script>

<style scoped>
@media screen and (min-width: 1024px) {
    .navbar-item {
        margin-left: 1rem;
    }
}

@media screen and (max-width: 1024px) {
    .navbar-item {
        padding: 0;
    }

    .dropdown {
        padding: 0.5rem;
    }

    .dropdown-trigger {
        padding: 0.5rem;
    }

    .dropdown-menu {
       min-width: 0 !important;
    }

    .dropdown-menu {
        display: block !important;
        position: relative !important;
    }

    .dropdown-menu {
        min-width: 20rem;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        box-shadow: none !important;
        background-color: #ffffff;
    }
}

.accordion-body:last-child {
    background-color: #fafafa;
    border: 0 !important;
}

.accordion-content {
    max-height: 40vh;
    overflow-y: scroll;
}

.accordion-header {
    background-color: #FFF !important;
    border-bottom: 1px solid #ececec;
    border-radius: 0 !important;
    color: #4a4a4a !important;
}

.announcement-content {
    padding: 1rem;
    color: #4a4a4a;
    text-align: left;
}

.announcement-title {
    font-size: 1.2rem;
    font-weight: bold;
    position: relative;
}

.announcement-subtitle {
    font-size: 0.9rem;
    color: #959595;
}

.author {
    color: #5d5d5d;
    font-size: small;
    margin-top: 0.5rem;
}

.badge {
    background-color: #7fffd4;
    border-radius: 100%;
    color: grey;
    height: 0.5rem;
    margin: 0.45rem 0 0 0.3rem;
    width: 0.5rem;
}

.badge-text {
    font-size: 0.9rem;
    margin-top: -0.2rem;
}

.button {
    background-color: transparent !important;
    border: 0 !important;
}

.date {
    color: #5d5d5d;
    font-size: small;
    margin-top: -0.3rem;
    padding-bottom: 0.3rem;
}

.dropdown-item {
    padding: 0 !important;
}

.dropdown-menu {
    min-width: 20rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fafafa;
}

.fa-bell {
    font-size: 1.9rem;
}

.main-badge {
    background-color: #7fffd4;
    border-radius: 100%;
    height: 1rem;
    position: absolute;
    right: -0.25rem;
    top: -0.5rem;
    width: 1rem;
}

.green-circle {
    background-color: #7fffd4;
    border-radius: 100%;
    height: 1rem;
    position: absolute;
    right: 0rem;
    top: 0rem;
    width: 1rem;
}

.navbar-item:hover {
    background-color: #fafafa;
    color: #3273dc !important;
}

.new {
    font-size: 0.7rem;
    margin: -0.3rem 0 0 0.8rem;
    position: absolute;
}
</style>