import PlanetaryFootprint from '@/views/sustainability/PlanetaryFootprint.vue';
import PlanetaryFootprintTop8View from '@/views/sustainability/PlanetaryFootprintTop8View.vue';
import PlanetaryFootprintMarketView from '@/views/sustainability/PlanetaryFootprintMarketView.vue';

import SustainableSales from '@/views/sustainability/SustainableSales.vue';
import SustainableSalesMarketView from '@/views/sustainability/SustainableSalesMarketView.vue';
import SustainableSaleTop8View from '@/views/sustainability/SustainableSalesTop8View.vue';

import PerceptionView from '@/views/sustainability/SustainabilityPerception.vue';
import PerceptionTop8View from '@/views/sustainability/SustainabilityPerceptionTop8.vue';

export default [
    // {
    //     path: '/sustainability/climate-footprint',
    //     component: PlanetaryFootprint,
    // },
    // {
    //     path: '/sustainability/climate-footprint/top-8',
    //     component: PlanetaryFootprintTop8View,
    // },
    // {
    //     path: '/sustainability/climate-footprint/:market',
    //     component: PlanetaryFootprintMarketView,
    //     props: true
    // },
    // {
    //     path: '/sustainability/sustainable-sales',
    //     component: SustainableSales,
    // },
    // {
    //     path: '/sustainability/sustainable-sales/top-8',
    //     component: SustainableSaleTop8View
    // },
    // {
    //     path: '/sustainability/sustainable-sales/:market',
    //     component: SustainableSalesMarketView,
    //     props: true
    // },
    {
        path: '/sustainability/mentions',
        component: PerceptionView,
    },
    {
        path: '/sustainability/mentions/top-8',
        component: PerceptionTop8View,
    },
    // {
    //     path: '/sustainability/zero-emissions',
    //     props: {template: './sustainability/ZeroEmissions/by_country.js'}
    // },
    // {
    //     path: '/sustainability/zero-emissions/:market',
    //     props: route => ({
    //         template: './sustainability/ZeroEmissions/by_trend.js',
    //         market: route.params.market
    //     })
    // },
    // {
    //     path: '/sustainability/zero-waste',
    //     props: {template: './sustainability/ZeroWaste/by_country.js'}
    // },
    // {
    //     path: '/sustainability/zero-waste/:market',
    //     props: route => ({
    //         template: './sustainability/ZeroWaste/by_trend.js',
    //         market: route.params.market
    //     })
    // },
    // {
    //     path: '/sustainability/climate-footprint-food-ingredients',
    //     props: {template: './sustainability/FoodIngredients/by_country.js'}
    // },
    // {
    //     path: '/sustainability/climate-footprint-food-ingredients/:market',
    //     props: route => ({
    //         template: './sustainability/FoodIngredients/by_trend.js',
    //         market: route.params.market
    //     })
    // },
];