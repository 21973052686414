<template>
<div style="display: inline-flex;">
	<div v-if="label" class="mr">{{label}}</div>
	<div 
		class="switch-container cursor-pointer"
		:style="toggle_item ? 'background-color: #23d160;' : 'background-color: #d3d3d3'" 
		@click="toggle_switch()"
	>
		<div :id="element_id" class="switch"></div>
	</div>
</div>
</template>

<script>
export default {
	props: {
		element_id: String,
		boolean_item: Boolean,
		label: String,
		tooltip_label: String
	},
	data: function() {
		return {
			toggle_item: false
		}
	},
	mounted: function() {
		if (this.boolean_item) {
			this.toggle_item = true;
			document.getElementById(this.element_id).style = 'left: 1.75rem !important; ';
		}
	},
	methods: {
		toggle_switch: function() {
			this.toggle_item = !this.toggle_item;
			if (this.toggle_item) {
				document.getElementById(this.element_id).style.transform += 'translateX(1.5rem)';
			} else {
				document.getElementById(this.element_id).style.transform += 'translateX(-1.5rem)';
			}
			this.$emit('switch_toggle', this.toggle_item);
		}
	}
}
</script>

<style scoped>
.switch-container {
	height: 1.5rem;
	width: 3rem;
	border-radius: 0.75rem;
	position: relative;
}
.switch {
	height: 1rem;
	width: 1rem;
	border-radius: 100%;
	background-color: #ffffff;
	position: absolute;
	top: 0.25rem;
	left: 0.25rem; 
	transition: transform 0.5s;
}

</style>