<template>
<div class="custom-title">
    <div class="title">
        {{title}}
    </div>
    <div class="subtitle">
        {{subtitle}}
    </div>
</div>
</template>

<script>
export default {
    props: {
        subtitle: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style scoped>
.custom-title {
    margin-bottom: 2.5rem;
    margin-top: 1.5rem;
    text-align: left;
}

.custom-title .title {
    text-transform: uppercase;
}

.custom-title .subtitle {
    font-size: 0.9em;
    padding-left: 0.5em;
}
</style>