var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.page_data && _vm.api_url
        ? _c(
            "KpiHeader",
            _vm._b(
              {
                attrs: {
                  tabs: _vm.tabs,
                  filters: _vm.filters,
                  filter_render: _vm.filter_render,
                  version_api_url: _vm.api_url,
                },
                on: {
                  update_filter: _vm.update_filter,
                  update_measure: _vm.update_measure,
                },
              },
              "KpiHeader",
              _vm.page_data,
              false
            )
          )
        : _vm._e(),
      _vm.render
        ? _c(
            _vm.page_data.type,
            _vm._b(
              { tag: "component", staticClass: "mx-4" },
              "component",
              _vm.formatted_data,
              false
            )
          )
        : _c("div", [
            _vm.$store.state.loading !== 0
              ? _c("div", [_vm._v("Loading Data")])
              : _c("div", [
                  _vm.page_data
                    ? _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.no_data_message) },
                      })
                    : _vm._e(),
                ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }