export default {
    props: Object,
    data: {},
    logic: function (props, data) {
        this.data = { ...this.data, ...data, props};
        let headers = this.data.parsed_headers;
        let rows = this.data.parsed_rows;
        for (let key in props) {
            let col_index = undefined;
            let comp_index = undefined;
            let row_index = undefined;

            if (props[key].row) {
                rows.forEach((row, i) => {
                    row.findIndex(cell => cell.value === key) > -1 ? row_index = i : null;
                });
                if (props[key].fix && row_index > -1) {
                    rows = this.render_fix(props[key].fix, col_index, row_index);
                }
            } else {
                /* Find the index of the current column + the comparison column */
                headers.forEach(row => {
                    let colspan = 0;
                    row.forEach(cell => {
                        let k = Object.keys(cell).shift();
                        let v = Object.values(cell).shift();
                        if (key === k || key === cell.alias) {
                            col_index = colspan;
                        }
                        
                        if (typeof(props[key].comparison) === 'number') {
                            comp_index = false;
                        } else if (!!props[key].comparison && (props[key].comparison === k || props[key].comparison === cell.alias)) {
                            comp_index = colspan;
                        }
                        colspan += v;
                    });
                });

                /* ICONS - If both columns exist */
                if ('icon' in props[key]) {
                    rows = this.render_icon(props[key], props[key].icon, props[key].reversed, col_index, comp_index, key, props[key].comparison);
                }
                if (props[key].fix) {
                    rows = this.render_fix(props[key].fix, col_index, row_index);
                }
                if (props[key].tooltip) {
                    rows = this.render_tooltip(props[key].tooltip, props[key].column_width, col_index);
                }
            }
        }

        return rows;
    },
    render: { 
        arrow: (is_higher, invisible, comparison) => {
            let color = is_higher ? 'success' : 'danger';
            let direction = is_higher ? 'up' : 'down';
            let tooltip_text = comparison ? `Compares to ${comparison}` : 'Compares to YTD';
            return `
            <span class="icon has-text-${color} tooltip ${invisible ? 'invisible' : ''}" data-tooltip="${tooltip_text}">
                <i class="fas fa-arrow-${direction}"></i>
            </span>`;
        },
        arrow_only: (flag, header) => {
            let icon = 'fa-arrow-up';
            let color = 'success';
            switch (flag) {
                case 'down':
                    color = 'danger';
                    icon = 'fa-arrow-down';
                    break;
                case 'equal':
                    color = 'info';
                    icon = 'fa-minus';
                    break;
            }
            return `
            <span class="icon has-text-${color} tooltip" data-tooltip="${header}">
                <i class="fas ${icon}"></i>
            </span>
            `;
        },
        circle_only: (flag, header) => {
            let icon = 'fa-circle';
            let color = 'success';
            switch (flag) {
                case 'down':
                    color = 'danger';
                    break;
                case 'equal':
                    color = 'info';
                    icon = 'fa-minus';
                    break;
            }
            return `
            <span class="icon has-text-${color} tooltip" data-tooltip="${header}">
                <i class="fas ${icon}"></i>
            </span>
            `;
        },
        bar: (reversed, lowest, highest, current) => {
            let lower = 0, upper = 0;
            let max = Math.max;
            let abs = Math.abs;

            let normalised = max(abs(highest), abs(lowest));

            if (current > 0) {
                upper = (current/ normalised) * 100;
            } else {
                lower = (abs(current) / normalised) * 100;
            }

            let lower_width = 50, upper_width = 50;
            if (lowest >= 0) {
                upper_width = 100;
                lower_width = 0;
            } else if (highest <= 0) {
                upper_width = 0;
                lower_width = 100;
            }

            let red = '#f80202', green = '#1a8245';

            return `
            <div id="wrapper" style="display: flex; width: 6rem;">
                <div style="display: inline-block; height: 1.5rem; width: ${!reversed ? lower_width : upper_width}%;">
                    <div class="pb-bar" style="background-color: ${red}; float: right; height: 100%; width: ${!reversed ? lower : upper}%"></div>
                </div>
                <div style="display: inline-block; height: 1.5rem; width: ${!reversed ? upper_width : lower_width}%;">
                    <div class="pb-bar" style="background-color: ${green}; height: 100%; width: ${!reversed ? upper : lower}%"></div>
                </div>
            </div>`;
        },
        circle: (is_higher, invisible, comparison) => {
            let color = is_higher ? 'success' : 'danger';
            let tooltip_text = comparison ? `Compares to ${comparison}` : 'Compares to Goal';
            return `
            <span class="icon has-text-${color} tooltip ${invisible ? 'invisible' : ''}" data-tooltip="${tooltip_text}">
                <i class="fas fa-circle"></i>
            </span>`;
        },
        main: (icon, { html, value }, type = null) => {
            // Return value in the middle of the HTML
            if (html) {
                value = html.replace('<span>', '').replace('</span>', '');
            }
            
            let row = `<div id="val">${value}</div>${icon}`;
            
            if (value == null) value = '';

            if (type === 'bar') {
                return `<div class="${!icon ? 'flex-end' : 'flex'}">
                    <div>${icon}</div>
                    <div>${value}</div>
                </div>`;
            }

            // If value is null or empty string, don't show anything, weak evaluation on purpose
            if (type !== 'arrow-only' && value == '') row = `<div id="val"></div>`;
            
            return `<div class="flex flex-end">${row}</div>`;
        }
    },
    // Prefix, postfix, column index
    render_fix: function({pre, post}, col_index, row_index) {
        let rows = this.data.parsed_rows;
        if (col_index) {
            rows.forEach(row => {
                let cell = row[col_index];
                cell.html = this.fix_logic(cell, pre, post);
            });
        } else if (row_index) {
            rows[row_index].forEach((cell, i) => {
                if (i === 0) return;
                cell.html = this.fix_logic(cell, pre, post);
            });
        }
        
        return rows;
    },
    fix_logic: function(cell, pre, post) {
        if (cell.html === undefined) { // If html doesn't exist, add it
            cell.html = this.render.main('', cell);
        }
        let match = [...cell.html.matchAll(/>([^<>\n]+?)</gm)].pop();
        if (!match) {
            cell.html = this.render.main('', cell);
            return;
        }
        let val = match[1];
        if (!val) return;
        return cell.html.replace(val, `${pre ? pre : ''}${val}${post ? post : ''}`);
    },
    render_icon: function(props, icon, reversed, col_index, comp_index, col_header, raw_comp_value) { // Icon type, Active Column Index, Comparison Index
        let rows = this.data.parsed_rows;
        rows.forEach(row => {
            let cell = row[col_index];
            if (cell.value === null || cell.value === undefined) return;
            let compare = !comp_index ? {value: raw_comp_value.toString()} : row[comp_index];

            let render_html = (icon, is_higher = false, invisible = false) => {
                let flag;
                switch (icon) {
                    case 'arrow':
                        return this.render.arrow(reversed ? !is_higher : is_higher, invisible, props.comparison);
                    case 'arrow-only':
                        if (cell.value > compare.value) flag = 'down';
                        if (cell.value === compare.value) flag = 'equal';
                        cell.html = this.render.arrow_only(flag, col_header);
                        return '';
                    case 'circle-only':
                        if (cell.value > compare.value) flag = 'down';
                        if (cell.value === compare.value) flag = 'equal';
                        cell.html = this.render.circle_only(flag, col_header);
                        return '';
                    case 'bar':
                        let lowest = Infinity, highest = -Infinity;
                        if (!('ignore' in props)) props.ignore = [];

                        // Filter out any rows that are to be ignored
                        rows.filter(r => !props.ignore.includes(r[0].value))
                        .forEach(r => {
                            let value = r[col_index].value;
                            if (value < lowest) lowest = value;
                            if (value > highest) highest = value;
                        });
                        if (props.ignore.includes(row[0].value)) {
                            return '';
                        } else {
                            return this.render.bar(reversed, lowest, highest, cell.value);
                        }
                    case 'circle':
                        return this.render.circle(reversed ? !is_higher : is_higher, invisible, props.comparison);
                }
            }
            if (cell && cell.value && !compare.value) {
                cell.html = this.render.main(render_html(icon, false, true), cell, icon);
            }
            if (cell && cell.value && compare && compare.value) {
                let is_higher = parseFloat(cell.value) >= parseFloat(compare.value);
                cell.html = this.render.main(render_html(icon, is_higher), cell, icon);              
            }
        });
        return rows;
    },
    render_tooltip: function(tooltip, column_width = 15, col_index) {
        let rows = this.data.parsed_rows;
        rows.forEach(row => {
            let cell = row[col_index];
            let str = cell.value.join(' ');
            switch (tooltip) {
                case 'list':
                    cell.html = `
                        <span class=" ${str.length > column_width ? 'tooltip' : ''}" data-tooltip="${str}">
                            ${str.length > column_width ? str.slice(0, column_width) + '...' : str}
                        </span>
                    `
                    break;
            }
        });
    }
}
