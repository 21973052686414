var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: {
          title: "After Sales Delivery Share Top 10",
          location: _vm.location,
        },
      }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons() },
          }),
          _c(
            "div",
            { staticClass: "label-container" },
            [
              _c("p", [_vm._v("Reason")]),
              _c("DropDown", {
                key: _vm.filter,
                attrs: {
                  options: _vm.options,
                  values: _vm.values,
                  default: _vm.filter,
                },
                on: { updateDropDown: _vm.update_filter },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: "",
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size,
          staticClass: "section box ms mb half-height",
        },
        [
          _vm.render_top8
            ? _c("Top8Bar", {
                key: _vm.render_top8,
                attrs: {
                  title: "After Sales Delivery Share Top 10 Countries",
                  showtitle: !_vm.$store.state.global.bot_mode,
                  subtitle: "",
                  xcategories: _vm.top_8.xcategories,
                  yaxislabel: this.filter,
                  ymetric: "%",
                  seriesname1: "YTD",
                  seriestype1: _vm.top_8.seriestype1,
                  seriesset1: _vm.top_8.seriesset1,
                  color1: _vm.$store.state.colors["yellow"],
                  seriesname2: "LY YTD",
                  seriestype2: _vm.top_8.seriestype2,
                  seriesset2: _vm.top_8.seriesset2,
                  color2: _vm.$store.state.colors["grey"],
                  seriesname4: "Year Goal",
                  seriestype4: "scatter",
                  color4: _vm.$store.state.colors.YG,
                  seriesset4: _vm.top_8.seriesset4,
                  seriestype3: "scatter",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }