<template>
    <div :id="title" style="width: 100%; height:100%;"></div>
</template>

<script>
import * as echarts from 'echarts/dist/echarts.min.js';
export default {
    props: {
        title: String, //title of the graph
        showtitle: Boolean,
        subtitle: String, //subtitle of the graph
        yaxislabel: String, //name of the data on the y axis e.g 'After sales delivery share' or 'services sale by month'
        ymetric: String, //'%', '£', 'kg' etc.
        seriesname: String, //name of the data such as IKEA 
        seriestype: String, //bar, line, scatter etc.
        seriesset: Object, //actual data array to fill the graph
        totals: Array,
        remove: Array,
        hidebarlabel: Boolean,
        show: Boolean, // true to show the exta features such as 'save graph as image', 'refresh', 'change to bar graph', etc
    },
    mounted: function() {
        /** 
         * The purpose of the following logic is to re organise the seriesset object into a 4 arrays that echarts (the graph library) can understand
         * These arrays are;
         * - Totals (the overarching values on either end of the graph)
         * - Decrease (the negative numbers)
         * - Increase (the positive numbers)
         * - Gaps (the calculated empty space beneath each bar to give it its desired height in the graph)
         * 
         * This takes the object of data and turns it into an array of arrays ex. [[Key, Val], ...]
         * After that if orders that array by the number values in acending order,
         * Then removes the two totals entries in the array and adds one at the beginning and one at the end of the array,
         * The main array is then split into three, the total values mentioned earlier, all the positive numbers and all of the negative numbers,
         * Then it proceeds to calculate the gaps which is the desired space underneath the bars of the graph
        */

        let sortable = [];
        for (let item in this.seriesset) { //convert the object into an array of arrays so it can be sorted
            if (this.remove && this.remove.includes(item)) continue; //removes the undesired entries
            sortable.push([item, this.seriesset[item]]);
        }
        sortable.sort((a, b) => b[1] - a[1]);
        let first_total = [this.totals[0], 0];
        let last_total = [this.totals[1], 0];
        let sorted = sortable.filter(item => { //filter out the totals values from the array
            if (item[0] === this.totals[0]) {
                first_total = item;
            } else if (item[0] === this.totals[1]) {
                last_total = item;
            } else {
                return item;
            }
        });
        //then add them back in first at the front and last at the end
        sorted.unshift(first_total);
        sorted.push(last_total); 

        let x_categories = [];
        let data_set = [];

        sorted.forEach(item => { //seperate the values out into their names and values
            x_categories.push(item[0]);
            data_set.push(item[1]);
        });

        let total = [];
        let increase = [];
        let decrease = [];

        data_set.forEach((item, i) => { //create 3 same length arrays for the 3 datasets
            if (i === 0 || i === data_set.length - 1) {
                total.push(Math.abs(item) * -1);
                increase.push('-');
                decrease.push('-');
                return;
            }
            if (item >= 0) {
                total.push('-');
                increase.push(item * -1);
                decrease.push('-');
            } else {
                total.push('-');
                increase.push('-');
                decrease.push(item);
            }
        });
        let gaps = [];

        let negative_total = total[0];

        data_set.forEach((item, i) => {
            if (i === data_set.length - 1 || i === 0) {
                gaps.push(0);
            } else {
                if (item < 0) {
                    gaps.push(negative_total);
                    negative_total += item;
                } else {
                    negative_total += item;
                    gaps.push(negative_total);
                }
            }
        });

        let myChart = echarts.init(document.getElementById(this.title));
        let option = {
           title:{
                text: this.title,
                show: this.showtitle,
                subtextStyle: {
                    color: 'black',
                },
                top: '-2em',
            },
            tooltip: {
                trigger: 'axis',
                formatter: function (params) {
                    params.forEach(param => {
                        if (param.data !== '-') {
                            return `${param.axisValue}<br/>${param.marker}${param.seriesName}: ${Number(data_set[param.dataIndex]).toFixed(1)}%`;
                        }
                    });
                },
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            toolbox: {
                top: '30em',
                right: '30em',
                show: this.show,
                feature: {
                    restore: {},
                    saveAsImage: {}
                }
            },
            grid: {
                top: '80em',
                left: '40em',
                right: '10em',
                bottom: '-15em',
                containLabel: true
            },
            legend: {
                top: '30em',
                left: '0em',
                itemGap: 20,
                data:['Total', 'Increase', 'Decrease'],
            },
            textStyle: {
                fontFamily: 'NotoIKEALatin-Regular',
            },
            xAxis: {
                type: 'category',
                splitLine: {show: false},
                axisPointer: {
                    type: 'shadow'
                },
                data: x_categories,
                axisLabel: {
                    interval: '0',
                    rotate: '40'
                }
            },
            yAxis: [
                {
                    type: 'value',
                    name: this.yaxislabel,
                    nameLocation: 'middle',
                    nameGap: 50,
                    axisLabel: {
                        formatter: `{value} ${this.ymetric}`
                    }
                }
            ],
            dataZoom: [
                {
                    show: this.show,
                    type: 'slider',
                    yAxisIndex: 0,
                    filterMode: 'none',
                    left: '-3em' ,
                    handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                    handleSize: '80%',
                    handleStyle: {
                        color: '#fff',
                        shadowBlur: 3,
                        shadowColor: 'rgba(0, 0, 0, 0.6)',
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    }
                }
            ],
            series: [
                {
                    name: '',
                    type: 'bar',
                    data: gaps,
                    stack: 'true',
                    animation: false,
                    itemStyle: {
                        barBorderColor: 'rgba(0,0,0,0)',
                        color: 'rgba(0,0,0,0)'
                    },
                    emphasis: {
                        itemStyle: {
                            barBorderColor: 'rgba(0,0,0,0)',
                            color: 'rgba(0,0,0,0)'
                        }
                    },
                    label: {
                        color: 'black',
                        show: true,
                        position: 'bottom',
                        distance: -7.25,
                        formatter: function (param) {
                            let num = Number(data_set[param.dataIndex]).toFixed(1);
                            if (num === '0.0' || num === '-0.0') {
                                return `${num}%`;
                            }
                            return '';
                        }
                    }
                },
                {
                    name: 'Total',
                    type: 'bar',
                    data: total,
                    color: '#FDDB2A',
                    stack: 'true',
                    label: {
                        color: 'black',
                        show: true,
                        position: 'inside',
                        formatter: function (param) {
                            return `${Number(data_set[param.dataIndex]).toFixed(1)}%`;
                        }
                    }
                },
                {
                    name: 'Increase',
                    type: 'bar',
                    data: increase,
                    color: 'green',
                    stack: 'true',
                    label: {
                        color: 'black',
                        show: true,
                        position: 'inside',
                        formatter: function (param) {
                            let num = Number(data_set[param.dataIndex]).toFixed(1);
                            return num !== '0.0' ? `${num}%` : '';
                        }
                    }
                },
                {
                    name: 'Decrease',
                    type: 'bar',
                    data: decrease,
                    color: 'red',
                    stack: 'true',
                    label: {
                        color: 'black',
                        show: true,
                        position: 'inside',
                        formatter: function (param) {
                            let num = Number(data_set[param.dataIndex]).toFixed(1);
                            return num !== '-0.0' ? `${num}%` : '';
                        }
                    }
                }
            ]
            
        };
        myChart.setOption(option);
    }
}
</script>