<template>
<div>
    <Title
        title="Market Share Prioritised Cities"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :dataset="table"
            class="is-pulled-left"  
        />
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <Table
        v-if="table.render"

        :headers="table.headers"
        :rows="table.rows"
        :decimal-settings="table.decimal_settings"
        :icon-settings="table.icon_settings"
        :data-settings="{ nullify_zeros: true }"
        :font-settings="{ bold: {row: {regex: 'Total Retail Countries' } } }"
        :sort-settings="{ 'Country': { asc: true } }"

        class="ms1p5"
    />
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: [],
                icon_settings: {},
                decimal_settings: {},
                render: false
            },
            location: '/business/market-share/market-share-prioritized-thirty-cities'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.load_ingka_by_country();
    },
    methods: {
        load_ingka_by_country: function() {
            this.table.render = false;
            this.$http.get('/business/market-share/market-prioritized-thirty-cities').then(
                succ => {
                    let d = succ.data;
                    // country, city, city market share, country market share
                    let keys = Object.keys(d[0].measures[Object.keys(d[0].measures)[0]]);
                    if (d[0].measure_metadata) {
                        this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                    }
                    this.table.headers = [['Country', 'City'].concat(keys).map(c => {
                        return {
                            [c]: 1
                        }
                    })];
                    d.map( row => {
                        let cities = row.measures;
                        Object.keys(cities).map(city => {
                            let temp = [];
                            temp.push(row.caption, city)
                            Object.keys(cities[city]).map( data => {
                                temp.push(this.$helper.maybe(cities[city][data]));
                            });
                            this.table.rows.push(temp)
                        });
                    });
                    this.table.icon_settings = {
                        'City Market Share': {
                            fix: { post: '%' }
                        },
                        'Country Market Share': {
                            fix: { post: '%' }
                        }
                    }
                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        }
    }
}
</script>
