<template>
<div class="new-form">
    <AdminUniversal
        component="title"
        :props="title_props"
    />
    <AdminUniversal
        component="textinput"
        :props="input_props"
    />

    <div v-for="(question, index) in form" :key="index" class="text-left">
        <div class="mbp5">
            <b>{{index + 1}}:</b> {{question.question}}
        </div>
        <b>Answer Type: </b>{{question.answer_type}}
        <div>|{{question.optional}}|</div>
        <hr>
    </div>

    <div class="box text-left">
        <div><b>Select a question</b></div>
        <div class="select mb is-fullwidth">
            <select v-model="selected">
                <option v-for="({ id, question }, index) in questions" :value="id" :key="index">
                    ({{ id }}) {{ question }}
                </option>
            </select>
        </div>
        
        <div class="text-bold">Select an answer type</div>
        <div v-for="(question, index) in questions" :key="index">
            <div v-if="question.id === selected" class="is-gapless">
                <div class="select is-fullwidth mbp5">
                    <select v-model="question.answer_type">
                        <option v-for="(answer, index) in answers" :value="answer.description" :key="index">
                            ({{answer.id}}) {{answer.description}}
                        </option>
                    </select>
                </div>
                
                <input type="checkbox" id="optional" name="optional" v-model="question.optional" />
                <label class="cursor-pointer" for="optional"> Optional?</label>

                <div class="button is-fullwidth is-link is-square mtp5" @click="add_question(question)">Add</div>
            </div>
        </div>
    </div>
    
    <AdminUniversal
        component="button"
        :props="button_props1"
    />
    <AdminUniversal
        component="button"
        :props="button_props2"
    />
</div>
</template>

<script>
import AdminUniversal from '@/components/admin/index.vue';

export default {
    components: {
        AdminUniversal
    },
    data: function() {
        return {
            title_props: {
                title: 'New Form',
            },
            input_props: {
                input: '',
                label: 'Name',
                placeholder: 'Questionnaire Name',
                callback: this.update_input_name
            },
            button_props1: {
                label: 'Save Form',
                classes: 'is-success',
                method: this.add_form
            },
            button_props2: {
                label: 'Cancel',
                classes: 'is-danger',
                method: this.back
            },
            answers: [
                {
                    id: 1,
                    description: "0-10"
                },
                {
                    id: 2,
                    description: "comment"
                }
            ],
            form: [],
            name: null,
            questions: null,
            selected: 1,
        }
    },
    mounted: function() {
        this.$http.get('force', '/nps/questions').then(
            ({ data }) => this.questions = data,
            err => console.log(err)
        );
    },
    methods: {
        add_form: function() {
            if (!this.name || this.name.trim() === '') {
                return alert('Please enter a name for the questionnaire.');
            }
            if (this.form.length < 1) {
                return alert('There must be at least 1 question in the questionnaire.');
            }

            this.$http.post('force', '/nps/forms/form/new', {
                name: this.name,
                questions: this.form.map(({ id, answer_type, optional }) => {
                    return {
                        id,
                        answer_type,
                        optional
                    }
                })
            }).then(
                succ => {
                    this.$router.push('/admin/nps/forms')
                },
                this.$helper.error
            );
            
        },
        add_question: function(question) {
            if (!question) return;
            if (!question.answer_type) {
                alert("Please select an answer type for the question.");
                return;
            }
            this.form.push(question);
            this.questions.splice(this.questions.findIndex(q => q.id === question.id), 1);
        },
        back: function() {
            this.$router.push('/admin/nps/forms')
        },
        update_input_name: function(input) {
            this.name = input;
        }
    }
}
</script>

<style scoped>
.new-form {
    max-width: 768px;
}
</style>