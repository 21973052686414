var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: {
          title: "Channel Contribution per Country",
          location: _vm.location,
        },
      }),
      _c("div", { staticClass: "options-bar" }, [
        _c(
          "div",
          { staticClass: "label-container hidden-on-pc" },
          [
            _c("p", [_vm._v("Country")]),
            _c("DropDown", {
              key: _vm.$store.getters.market,
              attrs: {
                options: _vm.$store.state.countries.options,
                values: _vm.$store.state.countries.values,
                default: _vm.$store.getters.market,
              },
              on: { updateDropDown: _vm.update_view },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "options-bar mt" }, [
          _c(
            "div",
            { staticClass: "buttons has-addons" },
            [
              _vm._l(_vm.available_list, function (country, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "button is-success",
                    class: `${
                      _vm.remove_list.indexOf(country) !== -1 ? "is-danger" : ""
                    }`,
                    on: {
                      click: function ($event) {
                        return _vm.choose(country)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(country) + " ")]
                )
              }),
              _c(
                "div",
                {
                  staticClass: "button is-warning",
                  on: {
                    click: function ($event) {
                      return _vm.remove_all()
                    },
                  },
                },
                [_vm._v(" Remove all ")]
              ),
              _c(
                "div",
                {
                  staticClass: "button is-warning",
                  on: {
                    click: function ($event) {
                      return _vm.add_all()
                    },
                  },
                },
                [_vm._v(" Add all ")]
              ),
              _c(
                "div",
                {
                  staticClass: "button is-warning",
                  on: {
                    click: function ($event) {
                      return _vm.top_8()
                    },
                  },
                },
                [_vm._v(" Top 10 Countries ")]
              ),
            ],
            2
          ),
        ]),
      ]),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size,
          staticClass: "section three-quarters-height box ms mb",
        },
        [
          _vm.stack_line_flex.render
            ? _c("StackLineFlex", {
                key: _vm.stack_line_flex.render,
                attrs: {
                  title: "Showing the Channel Contribution for each country",
                  showtitle: !_vm.$store.state.global.bot_mode,
                  subtitle:
                    "The height of the bars represents the value in TEUR and the values showing in the bars represents the YTD Index.",
                  xcategories: _vm.stack_line_flex.xcategories,
                  yaxislabel: "Growth contribution KEUR",
                  ymetric: "",
                  type: "bar",
                  color1: _vm.$store.state.colors["yellow"],
                  color2: "#0051ba",
                  color3: "#787878",
                  color4: "#008000",
                  seriesname1: "Store Comp",
                  seriesset1: _vm.stack_line_flex.seriesset1,
                  seriesname2: "Store non-Comp",
                  seriesset2: _vm.stack_line_flex.seriesset2,
                  seriesname3: "Online",
                  seriesset3: _vm.stack_line_flex.seriesset3,
                  seriesname4: "Remote",
                  seriesset4: _vm.stack_line_flex.seriesset4,
                  seriesname5: "Total Growth",
                  seriesset5: _vm.stack_line_flex.seriesset5,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }