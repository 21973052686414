var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("AdminUniversal", {
        attrs: { component: "title", props: _vm.title_props },
      }),
      _c(
        "div",
        { staticClass: "columns is-gapless" },
        [
          _c("div", { staticClass: "column is-2" }, [_vm._v("Search User:")]),
          _c("UserSearch", {
            staticClass: "column is-3",
            on: { SelectedUser: _vm.user_selected },
          }),
          _c(
            "button",
            {
              staticClass: "button is-danger column is-1",
              on: {
                click: function ($event) {
                  return _vm.cancel_search()
                },
              },
            },
            [_vm._v("Cancel")]
          ),
          _c("p", { staticClass: "column is-2" }),
          _c("p", { staticClass: "column is-1" }, [_vm._v("Access:")]),
          _c(
            "div",
            { staticClass: "column is-3" },
            [
              _c("DropDown", {
                key: _vm.values.length,
                attrs: {
                  options: _vm.options,
                  values: _vm.values,
                  default: _vm.filter,
                },
                on: { updateDropDown: _vm.update_filter },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("table", { staticClass: "table" }, [
        _c("thead", [
          _c(
            "tr",
            [
              _vm._l(_vm.table.headers, function (header, index) {
                return _c("th", { key: index, staticClass: "ikea-blue" }, [
                  _vm._v(_vm._s(header)),
                ])
              }),
              _c("th", { staticClass: "ikea-blue" }, [_vm._v("Actions")]),
            ],
            2
          ),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.table.rows, function (row, index) {
            return _c(
              "tr",
              { key: index },
              [
                _vm._l(row, function (dimension, i) {
                  return _c("td", { key: i }, [
                    _vm._v(" " + _vm._s(dimension) + " "),
                  ])
                }),
                _c("td", [
                  _c(
                    "a",
                    {
                      staticClass: "button",
                      on: {
                        click: function ($event) {
                          return _vm.edit_user(index)
                        },
                      },
                    },
                    [_vm._v("Edit")]
                  ),
                ]),
              ],
              2
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticClass: "buttons block" },
        [
          _c(
            "a",
            { staticClass: "button is-pulled-left", on: { click: _vm.back } },
            [_vm._v("Back")]
          ),
          _vm._l(_vm.page_range, function (index, i) {
            return _c(
              "a",
              {
                key: i,
                staticClass: "button",
                class: { "ikea-blue": _vm.page === index },
                on: {
                  click: function ($event) {
                    return _vm.update_page(index)
                  },
                },
              },
              [_vm._v(_vm._s(index + 1))]
            )
          }),
          _c(
            "a",
            { staticClass: "button is-pulled-right", on: { click: _vm.next } },
            [_vm._v("Next")]
          ),
        ],
        2
      ),
      _vm.user
        ? _c("Modal", {
            class: { "is-active": _vm.is_active },
            attrs: { title: "Edit User", closeable: true },
            on: {
              close: function ($event) {
                _vm.is_active = false
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "columns" }, [
                        _c(
                          "div",
                          { staticClass: "column is-narrow" },
                          [
                            _vm._l(Object.keys(_vm.user), function (key) {
                              return _c("div", { key: key }, [
                                _c(
                                  "div",
                                  { staticClass: "text-left user-row" },
                                  [_vm._v(_vm._s(key))]
                                ),
                                _c("hr"),
                              ])
                            }),
                            _c("div", { staticClass: "text-left user-row" }, [
                              _vm._v("Access Levels"),
                            ]),
                            _c("hr"),
                          ],
                          2
                        ),
                        _c(
                          "div",
                          { staticClass: "column" },
                          [
                            _vm._l(Object.values(_vm.user), function (key) {
                              return _c("div", { key: key }, [
                                _c(
                                  "div",
                                  { staticClass: "text-left user-row" },
                                  [_vm._v(_vm._s(key))]
                                ),
                                _c("hr"),
                              ])
                            }),
                            _c("div", { staticClass: "text-left user-row" }, [
                              _c("div", { staticClass: "select" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.updated_user.access,
                                        expression: "updated_user.access",
                                      },
                                    ],
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.updated_user,
                                          "access",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("option", [_vm._v("ADMIN")]),
                                    _c("option", [_vm._v("ADVANCED")]),
                                    _c("option", [_vm._v("BASIC")]),
                                    _c("option", [_vm._v("CONTRIBUTOR")]),
                                    _c("option", [_vm._v("EXECUTIVE SUMMARY")]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("hr"),
                          ],
                          2
                        ),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "button is-fullwidth",
                          on: { click: _vm.load_logs },
                        },
                        [_vm._v("See Logs")]
                      ),
                      _vm.can_see_logs
                        ? _c("div", [
                            _c("table", { staticClass: "table is-fullwidth" }, [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [_vm._v("Timestamp")]),
                                  _c("th", [_vm._v("Details")]),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                _vm._l(
                                  _vm.updated_user.logs,
                                  function (log, index) {
                                    return _c("tr", { key: index }, [
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.render_date(log.created_at)
                                          )
                                        ),
                                      ]),
                                      _c("td", [_vm._v(_vm._s(log.details))]),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "buttons" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button is-danger",
                            on: { click: _vm.close_modal },
                          },
                          [_vm._v("Cancel")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "button ikea-blue text-white",
                            on: { click: _vm.update_user },
                          },
                          [_vm._v("Confirm")]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3763428868
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }