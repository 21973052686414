<template>
<div>
    <Title
        title="Result Per Country Vs Goal"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
        <div class="label-container">
            <p>Filter</p>
            <DropDown
                :key= "filter"
                :options="options"
                :values="values"
                :default= "filter"
                @updateDropDown="update_filter"
            />
        </div>
    </div>
    <Table
        v-if="data_found"
        id="result-per-country-vs-goal-trend"

        :headers="table.headers"
        :rows="table.rows"
        :icon-settings="table.icon_settings"
        :decimal-settings="table.decimal_settings"
        :font-settings="{ bold: {row: {regex: 'Total' } } }"
        :sort-settings="{ 'Market-Area ': { asc: true } }"
        :metaSettings="{is_selectable: false}"
        
        class="ms1p5"
    />
    <div v-else>No data for {{$store.state.selected.country}}, please select another country</div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';


export default {
    props: {
        market: String,
    },
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            data_found: false,
            filter: null,
            table: {
                headers: [],
                rows: [],
                decimal_settings: {},
            },
            options: [],
            location: '/finance/result-per-country-vs-goal',
            values: [],
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'By Market',
                    link: `${this.location}/${this.$store.getters.market}`
                }
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'totsal-sales-cntry-vsgoal',
                disable_sort: true
            }).then(
                succ => {
                    succ.data.map(filter => {
                        this.values.push(filter.value);
                        this.options.push(filter.option);
                    });
                    if (this.option && this.option !== 'null') {
                        this.filter = this.option.replace(/-/g, ' ');
                    } else {
                        let retail_index = this.values.findIndex(row => row.includes('Retail Country'));
                        if (retail_index >= 0) this.filter = this.values[retail_index];
                        else this.filter = this.$store.getters.filter_check(this.values);
                    }
                    this.load_ingka_by_country();
                },
                this.$helper.error
            );
        },
        load_ingka_by_country: function() {
            this.data_found = false;
            if (!this.$store.getters.market) return;
            this.$http.post(`/finance/result-per-country-vs-goal/market`, {
                market: this.$store.getters.market,
                filter: this.filter
            }).then(
                succ => {
                    if (!succ.data) return;
                    let d = succ.data;
                    if (!d[0].measures) return this.data_found = false;
                    this.data_found = true;
                    if (d[0].measure_metadata) {
                        this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                    }
                    let keys = Object.keys(d[0].measures);
                    this.table.headers = [['Market-Area'].concat(keys).map(c => ({[c]: 1}))];
                    this.table.rows = d.map(row => {
                        let temp = [];
                        keys.map(key => {
                            if (!row.measures[key]) row.measures[this.key] = {};
                            if (
                                typeof (row.measures[key]) === 'number' ||
                                typeof (row.measures[key]) === 'string'
                            ) temp.push(String(this.$helper.maybe(row.measures[key])) + '%');
                            else temp.push('');
                        });
                        return [row.caption].concat(temp);
                    });
                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.load_ingka_by_country();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.load_ingka_by_country();
        }
    }
}
</script>