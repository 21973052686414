var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "has-text-left" }, [
    _c("div", { staticClass: "field" }, [
      _vm.label
        ? _c("label", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))])
        : _vm._e(),
      _c("div", { staticClass: "control" }, [
        _c("div", { staticClass: "select" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.modelled_selection,
                  expression: "modelled_selection",
                },
              ],
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.modelled_selection = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.callback(_vm.modelled_selection)
                  },
                ],
              },
            },
            _vm._l(_vm.options, function (option, i) {
              return _c(
                "option",
                { key: i, domProps: { value: _vm.options[i] } },
                [_vm._v(" " + _vm._s(option) + " ")]
              )
            }),
            0
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }