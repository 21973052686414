<template>
<div class="has-text-left">
    <div class="field">
        <label v-if="label" class="label">{{label}}</label>
        <div class="control">
            <div class="select">
                <select v-model="modelled_selection" @change="callback(modelled_selection)">
                    <option
                        v-for="(option, i) in options"
                        :key="i"
                        :value="options[i]"
                    >
                        {{option}}
                    </option>
                </select>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        label: String,
        options: Array,
        selection: String,
        callback: Function
    },
    data() {
        return {
            modelled_selection: this.selection
        }
    }
}
</script>

<style scoped>
.field {
    max-width: 768px;
}
</style>