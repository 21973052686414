<template>
<div>
    <Title
        title="Sales Trend Total"
        :location="location"
    />

    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
        
        <div class="label-container" 
            :class="!$store.getters.market ? 'ft-tooltip': null" 
            :tooltiptext="!$store.getters.market ? 'Select a country from top filter to see stores' : null"

            v-show="!this.$store.getters.presentation_mode"
        >
            <p>Market Area</p>
            <DropDown
                :key="stores.values.length"
                :options="stores.options"
                :values="stores.values"
                :default="stores.values[0]"
                @updateDropDown="update_store"                
            />
        </div>
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" id="sales-trend-total-summary" class="section half-height box ms">
        <SummaryTop
            v-if="summary_top.render"

            :title="`Showing data for: ${$store.getters.selected_country_name}`"
            :showtitle="!$store.state.global.bot_mode"

            subtitle="The height of the bars represents the value in Growth Contribution and the values showing in the bars represents the Sales Index."

            :xcategories="summary_top.xcategories"
            yaxislabel="Growth Contribution"
            ymetric=""
            
            type="bar"
            :color1="$store.state.colors['yellow']"
            :color2="$store.state.colors['grey']"
            color3="#787878"

            seriesname1="Year Goal"
            :seriesset1="summary_top.seriesset1"
            
            seriesname2="Forecast"
            :seriesset2="summary_top.seriesset2"
            
            seriesname3="YTD"
            :seriesset3="summary_top.seriesset3"

            seriesname4="13WK Trend"
            :seriesset4="summary_top.seriesset4"
            
            seriesname5="8WK Trend"
            :seriesset5="summary_top.seriesset5"
            
            seriesname6="4WK Trend"
            :seriesset6="summary_top.seriesset6"

            seriesname7="1WK Trend"
            :seriesset7="summary_top.seriesset7"
            
            seriesname8="Remaining (to FC)"
            :seriesset8="summary_top.seriesset8"
            
        />
    </div>
    <div class="section half-height box ms mb">
        <SummaryBottom
            v-if="summary_bottom.render"

            :title="`Showing data for: ${$store.getters.selected_country_name}.`"
            :showtitle="!$store.state.global.bot_mode"
            subtitle=""

            :xcategories="summary_bottom.xcategories"
            :xvalue="1"
            yaxislabel="Growth Contribution"
            ymetric="%"

            type="bar"
            :color1="$store.state.colors['yellow']"
            color2="#787878"
            color3="#0051ba"
            color4="#008000"

            seriesname1="Year Goal"
            :seriesset1="summary_bottom.seriesset1"

            seriesname2="Forecast"
            :seriesset2="summary_bottom.seriesset2"

            seriesname3="Year To Date"
            :seriesset3="summary_bottom.seriesset3"

            seriesname4="13 Weeks"
            :seriesset4="summary_bottom.seriesset4"

            seriesname5="8 Weeks"
            :seriesset5="summary_bottom.seriesset5"

            seriesname6="4 Weeks"
            :seriesset6="summary_bottom.seriesset6"

            seriesname7="1 Week"
            :seriesset7="summary_bottom.seriesset7"

            seriesname8="Remaining"
            :seriesset8="summary_bottom.seriesset8"
            
        />
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import SummaryTop from '@/components/visualisations/general/SummaryTop.vue';
import SummaryBottom from '@/components/visualisations/general/SummaryBottom.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        SummaryTop,
        SummaryBottom,
        Title
    },
    props: {
        market: String
    },
    data: function() {
        return {
            // remaining_temp: { keeping incase need to remove remaining again at a later date - Ben 09/10/2020
            //     "Online": { "label": null, "value": null },
            //     "Store comp": { "label": null, "value": null },
            //     "Store non-comp": { "label": null, "value": null }
            // },
            summary_top: {
                title: null,
                subtitle: null,

                xcategories: null,
                yaxislabel: null,
                ymetric: null,
                
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],
                seriesset4: [],
                seriesset5: [],
                seriesset6: [],
                seriesset7: [],
                seriesset8: [],
                
                render: false
            },
            summary_bottom: {
                title: null,
                subtitle: null,

                xcategories: null,
                yaxislabel: null,
                ymetric: null,
                
                seriesset1: null,
                seriesset2: null,
                seriesset3: null,
                seriesset4: null,
                seriesset5: null,
                seriesset6: null,
                seriesset7: null,
                seriesset8: null,
                
                render: false
            },
            store: null,
            location: '/business/total-sales/sales-trend-total'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (!this.$helper.market_check(this.market) && this.$store.getters.market) {
            this.$router.push(`${this.location}/summary/${this.$store.getters.market}`);
        }
        this.load_market_data();
        this.load_growth_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'Summary',
                    link: `${this.location}/summary/${this.$store.getters.market}`
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'By Market',
                    link: `${this.location}/by-market/${this.$store.getters.market}`
                },
            ];
        },
        load_market_data: function() {

            if (this.$store.getters.presentation_mode > 0) { // TEMP SOLUTION
                this.store = this.stores.values[0];
            }

            let market = this.store;
            let parent = this.$store.getters.market;
            if (!this.$store.getters.market && !this.store) {
                market = 'ALL';
                parent = null;
            } else if (!this.store) {
                market = this.$store.getters.market;
                parent = 'ALL'
            }
            this.$http.post('/business/total-sales/sales-trend-total/summary', {
                market,
                parent
            }).then(
                succ => {
                    let d = succ.data;
                    this.summary_top.xcategories = 1;
                    let val = d[0].measures;
                    
                    this.summary_top.seriesset1 = this.$helper.maybe(val['Year Goal']);
                    this.summary_top.seriesset2 = this.$helper.maybe(val['Forecast']);
                    this.summary_top.seriesset3 = this.$helper.maybe(val['YTD Index']);
                    this.summary_top.seriesset4 = this.$helper.maybe(val['13 Weeks']);
                    this.summary_top.seriesset5 = this.$helper.maybe(val['8 Weeks']);
                    this.summary_top.seriesset6 = this.$helper.maybe(val['4 Weeks']);
                    this.summary_top.seriesset7 = this.$helper.maybe(val['1 Week']);
                    this.summary_top.seriesset8 = this.$helper.maybe(val['Remaining']);

                    this.summary_top.render = true;
                },
                this.$helper.error
            );
        },
        load_growth_data: function() {
            if (this.$store.getters.presentation_mode > 0) { // TEMP SOLUTION
                this.store = this.stores.values[0];
            }

            let market = this.store;
            let parent = this.$store.getters.market;
            if (!this.$store.getters.market && !this.store) {
                market = 'ALL';
                parent = null;
            } else if (!this.store) {
                market = this.$store.getters.market;
                parent = 'ALL'
            }

            this.$http.post('/business/total-sales/sales-trend-total/growth-contribution', {
                market,
                parent
            }).then(
                succ => {
                    let d = succ.data;
                    if (d.length === 0) return;

                    this.summary_bottom.xcategories = Object.keys(d[0].measures);

                    let val = d[0].measures;

                    this.summary_bottom.seriesset1 = this.$helper.maybe(val['Year Goal']);
                    this.summary_bottom.seriesset2 = this.$helper.maybe(val['Forecast']);
                    this.summary_bottom.seriesset3 = this.$helper.maybe(val['YTD Index']);
                    this.summary_bottom.seriesset4 = this.$helper.maybe(val['13 Weeks']);
                    this.summary_bottom.seriesset5 = this.$helper.maybe(val['8 Weeks']);
                    this.summary_bottom.seriesset6 = this.$helper.maybe(val['4 Weeks']);
                    this.summary_bottom.seriesset7 = this.$helper.maybe(val['1 Week']);
                    this.summary_bottom.seriesset8 = this.$helper.maybe(val['Remaining']);
                    this.summary_bottom.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: `${this.location}/summary`,
            });
        },
        update_store: function(store) {
            this.store = store;
            this.summary_top.render = false;
            this.summary_bottom.render = false;
            this.load_market_data();
            this.load_growth_data();
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.store = null;
            this.summary_top.render = false;
            this.summary_bottom.render = false;
            this.load_market_data();
            this.load_growth_data();
        }
    },
    computed: {
        stores: function() {
            if (this.$store.getters.stores.values.length) {
                return this.$store.getters.stores;
            } else {
                return {
                    all: [],
                    options: [],
                    values: []
                }
            }
        }
    }
}
</script>

<style>

</style>
