<template>
<div class="node text-left" :key="render">
    <div v-for="(obj, name, index) in node" :key="index">

        <div v-if="!is_leaf(obj)">
            <div>{{name}}</div>
            <AccessNode :node="obj" @update="update"/>
        </div>

        <div v-else :class="is_first(index)" class="buttons">
            <div
                class="button is-small"
                :class="render_button(obj[n])"
                v-for="(o, n, i) in obj"
                :key="`${i}${n}`"
                @click="update(path.concat([name, n]))"
                disabled
            >
                {{n === 'r' ? 'Read' : 'Write'}}
            </div>
        </div>

    </div>
</div>
</template>

<script>
import AccessNode from '@/components/bespoke/access-summary/AccessNode.vue';
export default {
    name: "AccessNode",
    props: {
        node: Object
    },
    data: function() {
        return {
            render: true
        }
    },
    methods: {
        check_type: function() {
            return typeof this.node === 'object';
        },
        is_first: function(index) {
            return {
                'remove-margin': index === 0
            }
        },
        is_leaf: function(node) {
            // If the values aren't objects or lists, it's a leaf node!
            for (let item of Object.values(node)) {
                if (typeof item === 'array' || typeof item === 'object') {
                    return false;
                }
            }

            return true;
        },
        get_path: function(item) {

        },
        render_button: function(value) {
            let obj = {};
            if (value) {
                obj['is-success'] = true;
            } else {
                obj['is-danger'] = true;
            }
            return obj;
        },
        render_name: function(name) {
            if (name === 'r' || name === 'w' || name === '*') {
                return '';
            } else {
                return name;
            }
        },
        update: function(path) {
            this.render = false;
            this.$emit('update', path);
            this.render = true;
        }
    },
}
</script>

<style>

.node {
    padding-left: 5em;
}

.remove-margin {
    margin-left: -5em;
}

</style>