import helper from '@/helper';

export default (data, {graph_settings}, applied_filters, market, id) => {
    let default_graph = {
        title: null, // title of the graph
        showTitle: false,

        sub_title: null, // subtitle of the graph
        x_categories: [], // x-series as array
        y_axis_label: null,
        y_metric: null, //'%', '£', 'kg' etc.
        
        y_axis_min: null, // set the minimum value of the the y axis
        y_axis_max: null, // set the maximum value of the the y axis
        y_start_percent: 0,
        y_end_percent: 100,

        y2_axis_label: null, // name of the data on the y axis e.g 'After sales delivery share' or 'services sale by month'
        y2_metric: null, // '%', '£', 'kg' etc.

        y2_axis_min: null, //set the minimum value of the the y axis
        y2_axis_max: null, //set the maximum value of the the y axis
        
        names: [], // names of y-series datasets
        types: [], // type of each y-series, e.g. bar, line, scatter etc.
        stack: null,
        data_sets: [], // Array of y-series data sets as arrays
        colors: [], // color of y-series data sets
        disabled_labels: null,
        yAxisIndexes: null,
        toolbox: null,
        year_goal_index: null, // the index of the value which is the year goal, this needs special styling

        y_zoom: true,
        x_zoom: true,

        show: true, // true to show the exta features such as 'save graph as image', 'refresh', 'change to bar graph', etc
    };

    if (applied_filters.length) {
        applied_filters.forEach(filter => data = data[filter]);
    } else {
        data = data[Object.keys(data)[0]];
    }
    let graph = {...default_graph, ...graph_settings};

    const data_array = Object.values(data);
    if (!market) market = 'ALL';
    const country_data = data_array.find(country => country.country_code === market);
    if (!country_data) throw new Error(`No data for market: ${market}`);
    const measures = country_data.measures;

    // Measures array order not guarenteed - sort all arrays by x_series
    let series_map = {};
    for (const series_key in country_data.measures) {
        let series_data = country_data.measures[series_key].data;
        if (series_data) {
            if (typeof(series_data) === 'string') series_data = JSON.parse(series_data);
            series_map[series_key] = series_data;
        }
    }
    let series = sortArrays(series_map);
    
    graph.x_categories = series['x_series'];
    delete series['x_series'];

    // Order series by measures key
    const ordered_series_keys = graph.series_order || Object.keys(series).sort();
    ordered_series_keys.forEach((series_key, i) => {
        graph.names.push(format_name(measures[series_key].series_label));
        graph.data_sets.push(format_series(series[series_key], measures[series_key].decimal));
        graph.types[i] = graph.types[i] || measures[series_key].type || 'line';
        graph.colors[i] = graph.colors[i] || format_color(measures[series_key].color);
    });

    // Take general series properties from first series in order
    graph.y_metric = graph.y_metric || measures[ordered_series_keys[0]].metric || '';

    // Calculate and set y-axis range & zoom
    Object.assign(graph, compute_axes(graph));

    return {graph};
};

/**
 * Computes the range and zoom of the y axis based on the range of data and type of data (e.g. %)
 * @param {Object} graph The entire graph config object so that func has access to data and metric. Should not be mutated. 
 * @returns {Object} The 4 axis scale properties to be assigned tot he graph config object.
 */
const compute_axes = graph => {
    let min = Infinity, max = -Infinity;
    let zoom_start = 0, zoom_end = 100;

    /**
     * Rounds value up or down to the nearest 10 but if the rouded value is closer than 5 points to the val, extends by an extra 10.
     * @param {Number} val Value to be rounded
     * @param {String} [dir] ['up'|'down'] Direction to round
     * @returns {Number} val rounded up or down to the nearest 10, if unrecognised 'dir' passed, returns null
     */
    const round_to_x = (val, x, dir = 'up') => {
        let rounded_val = null;
        let precision = null;
        switch (dir) {
            case 'up':
                rounded_val = Math.ceil(val / x) * x;
                precision = !!rounded_val.toString().split('.')[1]
                    ? rounded_val.toString().split('.')[1].length
                    : 0; // Logic to counteract floating point issues
                if (rounded_val - val < (x / 2)) rounded_val += x; // Ensure largest value is more than x/2% from y_axis_max
                rounded_val = parseFloat(rounded_val.toFixed(precision)); // Logic to counteract floating point issues
                break;
            case 'down':
                rounded_val = Math.floor(val / x) * x;
                precision = !!rounded_val.toString().split('.')[1]
                    ? rounded_val.toString().split('.')[1].length
                    : 0; // Logic to counteract floating point issues
                if (val - rounded_val < (x / 2)) { // Ensure smallest value is more than x/2 from y_axis_min unless that would make y_axis_min negative when val is poitive
                    if (val > x || val < 0) rounded_val -= x;
                }
                rounded_val = parseFloat(rounded_val.toFixed(precision)); // Logic to counteract floating point issues
                break;
            default: console.error(`Compute_axes is unable to round in direction: ${dir}`);
        }
        return rounded_val;
    };

    graph.data_sets.forEach(set => {
        if (set.filter(val => val !== '').length) {
            const max_in_set = Math.max(...set.filter(val => val !== ''));
            max = max_in_set > max ? max_in_set : max;
            const min_in_set = Math.min(...set.filter(val => val !== ''));
            min = min_in_set < min ? min_in_set : min;
        }
    });

    // granularity of scale ticks (fixed to 10 if using reasonable sized percentages)
    const data_scale = graph.y_metric === '%' && max < 200
        ? 10
        : Math.pow(10, Math.round(Math.log10(max)) - 1); // Find order of magnitude and then go 1 lower, eg. 600 -> 1000 -> 100 or 40 -> 100 -> 10 or 20 -> 10 -> 1

    const data_min = round_to_x(min, data_scale, 'down');
    const data_max = round_to_x(max, data_scale, 'up');
    min = min < 0 ? data_min : 0;
    max = max > 100 || graph.y_metric !== '%'
        ? data_max
        : 100;

    const range = max - min;
    zoom_start = ((data_min - min) / range) * 100;
    zoom_end = ((data_max - min) / range) * 100;
    
    return {
        y_axis_min: min,
        y_axis_max: max,
        y_start_percent: zoom_start,
        y_end_percent: zoom_end
    };
};

/**
 * Big query doesnt support spaces in column names so underscores used instead and replaced here.
 * @param String series name as read from series key
 * @returns Human readable series name
 */
 const format_color = color => {
    if (!color) return '#000000';
    switch (color) {
        case 'yellow':  return '#E5B700';
        case 'grey':    return '#727272';
        case 'black':   return '#000000';
        default:        return color;
    }
};

/**
 * Big query doesnt support spaces in column names so underscores used instead and replaced here.
 * @param String series name as read from series key
 * @returns Human readable series name
 */
const format_name = name_from_key => {
    let formatted_name = name_from_key.replace(/_/g, ' ');
    return formatted_name;
};

/**
 * Cleans each data point in a series by removing non-numeric chars and rounding. Sets falsey data points to empty strings.
 * @param {Array} series A data series that the graph will plot 
 * @returns {Array} formatted series
 */
const format_series = (series, precision = 2) => {
    precision = parseInt(precision);
    const format_data_point = datum => {
        if (datum) {
            datum = datum.toString();
            datum = datum === '-' ? '' : datum.replace(/[^\d.-]/g, '');
            datum = helper.round(datum, precision);
        } else {
            datum = '';
        }
        return datum;
    }

    const new_series = series.map(datum => format_data_point(datum));
    return new_series;
};

/**
 *  Sorts all arrays together with the first. Pass either a list of arrays, or a map. Any key is accepted.
 *  Source: https://stackoverflow.com/a/57197878 - modified by Alex Stefanou to always sort on array with key "x_series" if availible.
 *  @param {Array|Object arrays} arrays [sortableArray, ...otherArrays]; {sortableArray: [], secondaryArray: [], ...}
 *  @param {Function} [comparator] (?,?) -> int   optional compareFunction, compatible with Array.sort(compareFunction)
 */
const sortArrays = (arrays, comparator = (a, b) => (a < b) ? -1 : (a > b) ? 1 : 0) => {
    let arrayKeys = Object.keys(arrays);
    let sortableArray = arrayKeys.includes('x_series') ? Object.values(arrays['x_series']) : Object.values(arrays)[0];
    let indexes = Object.keys(sortableArray);
    let sortedIndexes = indexes.sort((a, b) => comparator(sortableArray[a], sortableArray[b]));

    let sortByIndexes = (array, sortedIndexes) => sortedIndexes.map(sortedIndex => array[sortedIndex]);

    if (Array.isArray(arrays)) {
        return arrayKeys.map(arrayIndex => sortByIndexes(arrays[arrayIndex], sortedIndexes));
    } else {
        let sortedArrays = {};
        arrayKeys.forEach((arrayKey) => {
            sortedArrays[arrayKey] = sortByIndexes(arrays[arrayKey], sortedIndexes);
        });
        return sortedArrays;
    }
};
