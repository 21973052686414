var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "title" }, [_vm._v("Access Denied")]),
    _c("p", [
      _vm._v("You do not have sufficient access level to view this page."),
    ]),
    _c(
      "p",
      [
        _c(
          "span",
          {
            on: {
              click: function ($event) {
                _vm.easter = true
              },
            },
          },
          [_vm._v("Access")]
        ),
        _vm._v(
          " is determined by your job role. If you believe you need to have your access elevated, please visit the "
        ),
        _c(
          "router-link",
          { attrs: { to: "/access-summary", target: "_blank" } },
          [_vm._v("access management page")]
        ),
        _vm._v(' and click "raise level of access".'),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mt" },
      [
        _c("router-link", { staticClass: "button", attrs: { to: "/" } }, [
          _vm._v("Take me back"),
        ]),
      ],
      1
    ),
    _vm.easter
      ? _c("div", { staticClass: "easter mt", style: _vm.image })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }