var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("AdminUniversal", {
        attrs: { component: "title", props: _vm.title_props },
      }),
      _vm.table_props.render
        ? _c("AdminUniversal", {
            attrs: { component: "table", props: _vm.table_props },
          })
        : _c("div", [_vm._v("No Retail Managers to display")]),
      _c("hr"),
      _c("AdminUniversal", {
        attrs: { component: "title", props: _vm.title_props2 },
      }),
      _c(
        "div",
        { staticClass: "rm-form" },
        [
          _c("label", { staticClass: "label" }, [_vm._v("Select Country")]),
          _vm.$store.state.countries.options
            ? _c("DropDown", {
                key: _vm.$store.state.selected.country,
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: _vm.$store.state.countries.values[0],
                },
                on: { updateDropDown: _vm.update_selected_country },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "rm-form" },
        [
          _c("label", { staticClass: "label" }, [_vm._v("Search User")]),
          _c("UserSearch", {
            staticClass: "user-search",
            on: { SelectedUser: _vm.update_selected_user },
          }),
        ],
        1
      ),
      _c("AdminUniversal", {
        attrs: { component: "button", props: _vm.button_props },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }