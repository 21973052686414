import SalesPlanningPrecision from '@/views/process/SalesPlanningPrecision.vue';
import SalesPlanningPrecisionTop10HighestPrecision from '@/views/process/SalesPlanningPrecisionTop10HighestPrecision.vue';
import SalesPlanningPrecisionTop10LowestPrecision from '@/views/process/SalesPlanningPrecisionTop10LowestPrecision.vue';
import SalesPlanningPrecisionTop8 from '@/views/process/SalesPlanningPrecisionTop8.vue';

import Procurement from '@/views/process/Procurement.vue';

export default [
    {
        path: '/process/sales-planning-precision',
        component: SalesPlanningPrecision,
    },
    {
        path: '/process/sales-planning-precision/top-10-highest-precision',
        component: SalesPlanningPrecisionTop10HighestPrecision,
    },
    {
        path: '/process/sales-planning-precision/top-10-lowest-precision',
        component: SalesPlanningPrecisionTop10LowestPrecision,
    },
    {
        path: '/process/sales-planning-precision/top-8',
        component: SalesPlanningPrecisionTop8,
    },
    // {
    //     path: '/process/procurement',
    //     component: Procurement,
    // },
    // {
    //     path: '/process/noslo',
    //     props: {template: './process/NOSLO/by_country.js'}
    // },
    // {
    //     path: '/process/noslo/:market',
    //     props: route => ({
    //         template: './process/NOSLO/by_trend.js',
    //         market: route.params.market
    //     })
    // },
];
