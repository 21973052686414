var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dropdown", class: { "is-active": _vm.isActive } },
    [
      _c("div", { staticClass: "dropdown-trigger" }, [
        _c(
          "button",
          {
            staticClass: "button",
            attrs: {
              "aria-haspopup": "true",
              "aria-controls": "dropdown-menu",
            },
            on: {
              mouseenter: _vm.openDropdown,
              click: _vm.toggleDropdown,
              mouseleave: _vm.leaving_dropdown,
            },
          },
          [_vm._m(0)]
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "dropdown-menu",
          attrs: { id: "dropdown-menu", role: "menu" },
          on: {
            mouseenter: _vm.openDropdown,
            mouseleave: _vm.leaving_dropdown,
          },
        },
        [
          _c(
            "div",
            { staticClass: "dropdown-content" },
            _vm._l(_vm.help_links, function (link, i) {
              return _c(
                "a",
                {
                  key: i,
                  staticClass: "dropdown-item",
                  attrs: {
                    href: link.link,
                    target: link.link.includes(_vm.url_origin)
                      ? "_self"
                      : "_blank",
                  },
                  on: { click: _vm.closeDropdown },
                },
                [_vm._v(" " + _vm._s(link.name) + " ")]
              )
            }),
            0
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("i", {
        staticClass: "fas fa-question-circle",
        attrs: { "aria-hidden": "true" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }