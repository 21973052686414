<template>
<div id="table">
    <div class="table-container">
        <table class="custom table desktop">
            <thead>
                <tr v-for="(row, header_row) in headers" :key="header_row">
                    <th v-for="(cell, header_index) in row" :key="header_index"
                        @click="(( headers.length - 1) == header_row ) ? sort(header_index) : ''"
                        :colspan="Object.values(cell).shift()"
                        class="ikea-yellow text-black"
                    >
                        {{Object.keys(cell).shift()}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, row_index) in rows" :key="row_index" @click="select_row(row, row_index)" :class="row_index === selected_row ? 'is-selected' : ''">
                    <td v-for="(cell, column_index) in row" :key="column_index" class="no-text-wrap">
                       <div v-html="cell.html"></div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</template>

<script>

export default {
    props: {
        headers: Array,
        rows: Array
    },
    data: function() {
        return {
            sort_data: null,
            selected_row: null
        }
    },
    mounted: function() {
        this.initialise_sort_data();
        this.check_for_selected_row();
    },
    methods: {
        check_for_selected_row: function() {
            this.rows.forEach((row, i) => {
                if (row[0].display_value.toUpperCase() === this.$store.getters.selected_country_name.toUpperCase()) {
                    this.selected_row = i;
                }
            });
        },
        sort: function(index) {
            let sort_value = this.sort_data[index].sort === 1 ? -1 : 1;
            this.initialise_sort_data();
            this.sort_data[index].sort = sort_value;
            this.rows = this.$helper.table.apply_sort_settings(this.rows, sort_value, index);
            this.check_for_selected_row();
        },
        select_row: function(row, row_index) {
            this.selected_row = row_index;
            if (row[0].display_value) {
                let selected_country = row[0].display_value.toUpperCase();
                if (selected_country !== this.$store.getters.selected_country_name.toUpperCase()) {
                    let new_selected_country_index = this.$store.getters.countries.options.findIndex(i => i === selected_country);
                    this.$store.commit('update_market', this.$store.getters.countries.values[new_selected_country_index]);
                }
            }
        },
        initialise_sort_data: function() {
            this.sort_data = [...this.headers[this.headers.length-1]].map(header => {
                header.sort = 0;
                return header;
            });
        }
    },
    computed: {
        countries: function() {
            return this.$store.getters.countries;
        },
        market: function() {
            return this.$store.getters.market;
        }
    },
    watch: {
        // If the filter in the menu is updated
        '$store.getters.market': function() {
            //console.log('country_change');
        }
    }
}
</script>

<style lang="scss">
#table {
    margin-left: 1rem;
    width: calc(100% - 2rem);

    .bridge-row {
        background-color: #F0F0F0;
        font-weight: bold;
    }

    .border-left {
        border-left: 1px solid #b4b4b4 !important;
    }

    .border-right {
        border-right: 1px solid #b4b4b4 !important;
    }

    .custom.table thead td, .custom.table thead th {
        /* color: #f2eded !important; */
        cursor: pointer;
        text-align: center;
    }

    .scroll-notification {
        background-color: #f5f5f5
    }

    table {
        td:first-child {
            text-align: left !important;
        }

        td {
            padding: 0rem 0.3rem 0rem 0rem !important;
            text-align: right !important;
        }

        thead * {
            z-index: 1;
        }

        &.table {
            position: relative;

            td.left-gap, th.left-gap {
                border-left: 1rem solid #000;
            }

            div.flex > div {
                flex-grow: 1;
            }

            thead tr th {
                border-left: 1px solid #FFF;
                border-right: 1px solid #FFF;
                color: #000;
                max-width: 8rem;
                min-width: 4rem;
                padding: 0.3rem !important;
            }

            thead tr:first-of-type {
                font-weight: bold;
            }

            tbody tr td:first-of-type {
                padding-left: 0.4rem !important;
            }

            tbody tr td {
                padding-right: 0.2rem;
            }

            tbody tr.is-selected td {
                border-left: 1px solid #FFF;
                border-right: 1px solid #FFF;
            }

            th:not([align]) {
                text-align: center;

                &:first-child {
                    text-align: left;
                }
            }

            tr {
                cursor: pointer;

                &.is-selected {
                    background-color: #FDDB2A !important;
                    color: #000;
                }
            }
        }
    }
}

.mobile {
    display: none;
}

@media only screen and (max-width: 768px) {
    .desktop.table {
        display: none;
    }

    .mobile {
        display: block;
    }

    table tbody tr:last-child {
        font-weight: initial;
    }
}
</style>
