<template>
<div>
    <Title
        title="Product Availability"
        :location="location"
    />
    
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
        
        <div class="label-container">
            <p>Status</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms">
        <Sextuple
            v-if="sextuple.render"

            title="Showing Total Retail Countries"

            :showtitle="!$store.state.global.bot_mode"
            subtitle=""

            :xcategories="sextuple.xcategories"
            yaxislabel="Product Availability"
            ymetric="%"
            :yaxismin="60"

            seriesname1="OSA"
            seriestype1="line"
            :seriesset1="sextuple.seriesset1"
            :color1="$store.state.colors['focus-yellow']"
            
            seriesname2="CDC"
            seriestype2="line"
            :seriesset2="sextuple.seriesset2"
            :color2="$store.state.colors['yellow']"
            
            seriesname3="Goal"
            seriestype3="line"
            :seriesset3="sextuple.seriesset3"
            :color3="$store.state.colors.YG"
            symbol3='none'

            seriestype4="line"
            
            seriestype5="line"
            seriestype6="line"

            :show="!$store.state.global.bot_mode"
        />
        <div v-else> No data found for {{$store.getters.market}}</div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Sextuple from '@/components/visualisations/general/Sextuple.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Title,
        Sextuple
    },
    props: {
        market: String
    },
    data: function() {
        return {
            options: [],
            values: [],
            filter: null,
            sextuple: {
                title: null,
                subtitle: null,

                xcategories: null,
                yaxislabel: null,
                ymetric: null,
                
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],

                render: false
            },
            graph_names: ['cdc', 'osa'],
            location: '/business/product-availability',
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'Total',
                    link: this.location
                },
                {
                    name: 'Detail',
                    link: `${this.location}/detail`
                },
                
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'ava-type-2',
            }).then(
                succ => {
                    let d = succ.data;
                    d.forEach(filter => {
                        if (filter) {
                            this.values.push(filter.value);
                            this.options.push(filter.option);
                        }
                    });
                    this.filter = this.$store.getters.filter_check(this.values);
                    this.load_market_data();
                },
                this.$helper.error
            );
            
        },
        load_market_data: function() {
            this.$http.post('/business/product-availability/total', {
                names: this.graph_names,
                filter: this.filter
            }).then(
                succ => {
                    let d = succ.data;
                    this.sextuple.xcategories = Object.keys(d[0].measures);
                    
                    this.sextuple.seriesset1 = [];
                    this.sextuple.seriesset2 = [];
                    this.sextuple.seriesset3 = [];

                    this.sextuple.xcategories.forEach(week => {
                        let val = d[0].measures[week];
                        this.sextuple.seriesset1.push(this.$helper.maybe(val['osa']));
                        this.sextuple.seriesset2.push(this.$helper.maybe(val['cdc']));
                        this.sextuple.seriesset3.push(this.$helper.maybe(val['osa-goal']));
                    });

                    this.sextuple.render = true;
                },
                this.$helper.error
            );
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.sextuple.render = false;
            this.load_market_data();
            
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location,
            });
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.sextuple.render = false;
            this.update_view(newMarket);
            this.load_market_data();
        }
    }
}
</script>
