<template>
<div>
    <Title
        title="Cost of Internal Damages"
        :location="location"
    />

    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container">
            <p>Filter</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms mb">
        <Sextuple
            :key="sextuple.render"
            v-if="sextuple.render"

            title="Stock Accuracy Top 10 Countries"
            :showtitle="!$store.state.global.bot_mode"
            subtitle=""

            :xcategories="sextuple.xcategories"
            
            yaxislabel1="Stock Accuracy %"
            
            ymetric1=""
            
            yaxislabel2="Number of Checks"
            
            ymetric2=""

            seriesname1="YTD Total Stock Checks"
            seriestype1="bar"
            :seriesset1="sextuple.seriesset1"
            :color1="$store.state.colors['light-grey']"
            
            seriesname2="YTD %"
            seriestype2="bar"
            :seriesset2="sextuple.seriesset2"
            :color2="$store.state.colors['yellow']"
            
            seriestype3="scatter"

            seriesname4="4 WK %"
            seriestype4="scatter"
            :seriesset4="sextuple.seriesset4"
            :color4="$store.state.colors['dark-grey']"

            :seriesname5="this.goal"
            seriestype5="line"
            :seriesset5="sextuple.seriesset5"
            :color5="$store.state.colors.YG"

            :show="!$store.state.global.bot_mode"
            :trend="true"
            :trendInvert="false"
        />
        <!-- seriesname3="Week %" this is the data for the week % data which was decided to not be included - Ben 22-10-2020
            seriestype3="scatter"
            :seriesset3="sextuple.seriesset3"
            :color3="$store.state.colors['light-grey']" -->
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Sextuple from '@/components/visualisations/bar/Top8DeviationWith4Lines.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Title,
        Sextuple
    },
    data: function() {
        return {
            sextuple: {
                title: null,
                subtitle: null,

                xcategories: [],
                yaxislabel: null,
                ymetric: null,
                
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],
                seriesset4: [],
                seriesset5: [],
                seriesset6: [],

                render: false
            },
            goal: 'Goal',
            location: '/finance/cost-of-internal-damages',
            options: ['SM0 - Market Hall', 'SM1 - Self Serve Furniture Area', 'SM2 - Full Serve'],
            values: ['0', '1', '2'],
            filter: '0'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.filter = this.$store.getters.filter_check(this.values);
        this.load_market_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}/0`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
            ];
        },
        load_market_data: function() {
            if (this.filter) {
                this.sextuple.render = false;
                let url = '/finance/cost-of-internal-damages/top-8';
                let succ = (succ) => {
                    let d = succ.data;
                    this.sextuple.seriesset1 = [];
                    this.sextuple.seriesset2 = [];
                    this.sextuple.seriesset3 = [];
                    this.sextuple.seriesset4 = [];
                    this.sextuple.seriesset5 = [];
                    this.sextuple.xcategories = [];
                    d.map(country => {
                        let val = country.measures;
                        this.sextuple.xcategories.push(country.caption)
                        this.sextuple.seriesset1.push(this.$helper.maybe(val['YTD Total Stock Checks']));
                        this.sextuple.seriesset2.push(this.$helper.maybe(val['YTD %']));
                        this.sextuple.seriesset3.push(this.$helper.maybe(val['Week %']));
                        this.sextuple.seriesset4.push(this.$helper.maybe(val['4 Week %']));
                        this.sextuple.seriesset5.push(val['Goal']);
                    });
                    this.goal = this.sextuple.seriesset5.findIndex( goal => goal) ?  null : 'Goal';
                    this.sextuple.render = true;
                }

                let error = (error) => console.log(error);
                this.$http.post(url, {filter: this.filter}).then(succ, error);
            }
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.sextuple.render = false;
            this.load_market_data();
        }
    },
    watch: {
        filter: function() {
            this.load_market_data();
        }
    }
}
</script>

<style>

</style>