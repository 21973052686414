<template>
    <div :id="title" style="width: 100%; height:100%;"></div>
</template>

<script>
import * as echarts from 'echarts/dist/echarts.min.js';
export default {
    props: {
        title: String, //title of the graph
        showtitle: Boolean,
        subtitle: String, //subtitle of the graph
        xcategories: Array, //data that will be displayed on the x axis
        yaxislabel: String, //name of the data on the y axis e.g 'After sales delivery share' or 'services sale by month'
        ymetric: String, //'%', '£', 'kg' etc.
        type: String, //bar
        xvalue: Number,

        seriesname1: String, //name of the data such as IKEA 
        seriesset1: Array,
        color1: String, //color of the bar lie or scatter for the paticular data set
        seriestype1: String,

        seriesname2: String,
        seriesset2: Array,
        color2: String,
        seriestype2: String,
        
        seriesname3: String,
        seriesset3: Array,
        color3: String,
        seriestype3: String,
    
        seriesname4: String,
        seriesset4: Array,
        color4: String,
        seriestype4: String,
        
        show: Boolean, // true to show the exta features such as 'save graph as image', 'refresh', 'change to bar graph', etc
        gridbottom: Number,
        xaxisrotation: Number,
        xaxisfontsize: Number,
        hidebarlabel: Boolean
    },
    mounted: function() {
		let myChart = echarts.init(document.getElementById(this.title));
        //loop through to find the highest and lowest combination then set those for the upper and lower limits for the y axis also append the labels on the lowest value
        
        let option = {
           title:{
                text: this.title,
                subtext: this.subtitle,
                show: this.showtitle,
                subtextStyle: {
                    color: 'black',
                },
                top: '-5em',
            },
            tooltip: {
                show: true,
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            toolbox: {
                top: '85em',
                right: '30em',
                show: this.show,
                feature: {
                    restore: {},
                    saveAsImage: {}
                }
            },
            grid: {
               top: '80em',
               left: '70em',
               right: '10em',
               bottom: this.gridbottom ? `${this.gridbottom}em` : '5em',
               containLabel: true
			},
			legend: {
                top: '45em',
                left: '0em',
                itemGap: 15,
                data: [this.seriesname1, this.seriesname2, this.seriesname3, this.seriesname4],
			},
			textStyle: {
                fontFamily: 'NotoIKEALatin-Regular',
            },
            xAxis: [
                {
                    type: 'category',
                    data: this.xcategories,
                    axisPointer: {
                        type: 'shadow'
                    },
                    axisLabel: {
                        rotate: this.xaxisrotation ? `${this.xaxisrotation}` : null,
                        fontSize: this.xaxisfontsize ? this.xaxisfontsize : null
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: this.yaxislabel,
                    nameLocation: 'middle',
                    nameGap: 60,
                    axisLabel: {
                        formatter: `{value} ${this.ymetric}`
                    }
                }
            ],
            series: [
                {
                    name: this.seriesname1,
                    type: this.seriestype1,
                    stack: 'true',
                    data: this.seriesset1,
                    color: this.color1,
                    label: {
                        show: this.hidebarlabel ? false : true,
                        position: 'inside'
                    },
                    
                },
                {
                    name: this.seriesname2,
                    type: this.seriestype2,
                    stack: 'true',
                    data: this.seriesset2,
                    color: this.color2,
                    label: {
                        show: this.hidebarlabel ? false : true,
                        position: 'inside'
                    },
                },
                {
                    name: this.seriesname3,
                    type: this.seriestype3,
                    stack: 'true',
                    data: this.seriesset3,
                    color: this.color3,
                    label: {
                        show: this.hidebarlabel ? false : true,
                        position: 'inside'
                    },
                },
                {
                    name: this.seriesname4,
                    type: this.seriestype4,
                    data: this.seriesset4,
                    color: this.color4,
                },
            ]
            // series: this.all_data(),
        };
        myChart.setOption(option);
    }
}
</script>