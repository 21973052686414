<template>
<div id="grid">
    <!-- 1 -->
    <div class=""></div>
    <!-- 2 -->
    <div class="item mrp5 ft-tooltip cursor-pointer" tooltiptext="Follow the flow of a customer bringing an item home from a store.">
        <div class="title underline">
            <div class="icon"><i class="fas fa-taxi"></i></div>
            <b>Customer</b> to home
        </div>
        <SubGrid
            :values="matrix[1]"
        />
    </div>
    <!-- 3 -->
    <div class="item ft-tooltip cursor-pointer" tooltiptext="Follow the flow of IKEA delivering an item home for a customer.">
        <div class="title underline">
            <div class="icon"><i class="fas fa-shipping-fast"></i></div>
            <b>IKEA</b> to home
        </div>
        <SubGrid
            :values="matrix[2]"
        />
    </div>

    <!-- 4 -->
    <div class="item ft-tooltip cursor-pointer" tooltiptext="Follow the flow of purchasing in the store.">
        <div class="title underline">
            <div class="icon"><i class="fas fa-shopping-basket"></i></div>
            <b>Store</b>
        </div>
        
        <SubGrid
            :values="matrix[3]"
        />
    </div>
    <!-- 5 -->
    <div class="item ft-tooltip cursor-pointer" tooltiptext="Customer bought an item in the store and has taken it home by themselves.">
        <div class="title"><i>Cash and Carry</i></div>
        <SubGrid
            :values="matrix[4]"
            :showLabels="false"
        />
    </div>
    <!-- 6 -->
    <div class="item ft-tooltip cursor-pointer" tooltiptext="Customer bought an item in the store and has it delivered home by IKEA.">
        <div class="title"><i>Cash and Deliver</i></div>
        <SubGrid
            :values="matrix[5]"
            :showLabels="false"
        />
    </div>

    <!-- 7 -->
    <div class="item mtp5 ft-tooltip cursor-pointer" tooltiptext="Follow the flow of purchasing online.">
        <div class="title underline">
            <div class="icon"><i class="fas fa-desktop"></i></div>
            <b>Online</b>
        </div>
        <SubGrid
            :values="matrix[6]"
        />
    </div>
    <!-- 8 -->
    <div class="item ft-tooltip cursor-pointer" tooltiptext="Customer bought an item online and collected in store.">
        <div class="title"><i>Click and Collect</i></div>
        <SubGrid
            :values="matrix[7]"
            :showLabels="false"
        />
    </div>
    <!-- 9 -->
    <div class="item ft-tooltip cursor-pointer" tooltiptext="Customer bought an item online and got it delivered home by IKEA.">
        <div class="white-square"></div>
        <div class="title"><i>Click and Deliver</i></div>
        <SubGrid
            :values="matrix[8]"
            :showLabels="false"
        />
    </div>

    <!-- 10 -->
    <div class="item mtp5 ft-tooltip cursor-pointer" tooltiptext="Follow the flow of purchasing remotely.">
        <div class="title underline">
            <div class="icon"><i class="fas fa-phone-volume"></i></div>
            <b>Remote</b>
        </div>
        <SubGrid
            :values="matrix[9]"
        />
    </div>
    <!-- 11 -->
    <div class="item ft-tooltip cursor-pointer" tooltiptext="Customer bought an item remotely and collected in store.">
        <div class="title"><i>Call and Collect</i></div>
        <SubGrid
            :values="matrix[10]"
            :showLabels="false"
        />
    </div>
    <!-- 12 -->
    <div class="item ft-tooltip cursor-pointer" tooltiptext="Customer bought an item remotely and got it delivered home by IKEA.">
        <div class="white-square"></div>
        <div class="title"><i>Call and Deliver</i></div>
        <SubGrid
            :values="matrix[11]"
            :showLabels="false"
        />
    </div>
</div>
</template>

<script>
import SubGrid from './SubGrid.vue';
export default {
    props: {
        matrix: Array
    },
    components: {
        SubGrid
    },
    data: function() {
        return {
            horizontal: true,
            horizontal2: false,
            vertical: false,
            vertical2: false
        }
    }
}
</script>

<style lang="scss">
#grid {
    color: #000;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(3, auto);
    margin: 0 auto;
    max-width: #{"max(66vw, 1000px)"};
    padding: 1rem;

    .item {
        background-color: #e6eefb;
        box-shadow: 2px 2px 1px #717171;
        padding: 1rem;
        position: relative;

        .icon {
            color: #000;
            font-size: 2rem;
            width: 3rem;
        }

        .title {
            color: #000;
            font-size: 1.4rem;
            font-weight: normal;
            line-height: 2rem;
            min-height: 2rem;
            text-align: left;

            &.underline {
                border-bottom: 1px solid #000;
            }
        }

        .title:not(.underline) {
            font-size: 1.2rem;
            height: 4rem;
            margin-top: 5rem;
            margin-bottom: -5rem;
        }

        .white-square {
            background-color: #FFF;
            box-shadow: inset 2px 2px 1px #717171;
            height: 0.5rem;
            left: -0.5rem;
            position: absolute;
            top: 0;
            width: 0.5rem;
        }
    }
}

.ft-tooltip:hover::before  {
    max-width: 30rem !important;
}
</style>