var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "field" }, [
    _c("input", {
      staticClass: "switch is-rounded is-normal is-success",
      attrs: { id: "switchRounded", type: "checkbox" },
      on: {
        click: function ($event) {
          return _vm.toggle_edit()
        },
      },
    }),
    _c("label", { attrs: { for: "switchRounded" } }, [
      !_vm.disable_icon ? _c("i", { staticClass: "fas fa-edit" }) : _vm._e(),
      this.$store.state.global.show_edit === false && !_vm.disable_icon
        ? _c("i", { staticClass: "fas fa-slash" })
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }