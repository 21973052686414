var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "button is-success mt button-left",
          on: {
            click: function ($event) {
              return _vm.toggle_new_modal()
            },
          },
        },
        [_vm._v("Add new Reminder")]
      ),
      _c("div", [_vm._v("* All Reminders are sent at 8am CET*")]),
      _c("div", [
        _vm._v(
          "* Global Users are the ones responsible for the Total Ingka Retail Insights*"
        ),
      ]),
      _vm.show_reminder_modal
        ? _c("Modal", {
            class: { "is-active": _vm.show_reminder_modal },
            attrs: { closeable: true, title: "Add new Reminder" },
            on: {
              close: function ($event) {
                return _vm.toggle_new_modal()
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("DateTimePicker", {
                        attrs: { singleDate: true },
                        on: { updated: _vm.update_reminder_date },
                      }),
                      _c("label", { staticClass: "checkbox mt" }, [
                        _c("input", {
                          attrs: { type: "checkbox" },
                          on: {
                            click: function ($event) {
                              return _vm.check_global_user()
                            },
                          },
                        }),
                        _vm._v(" Reminder for Global Insights "),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c("a", { staticClass: "buttons" }, [
                        _c(
                          "a",
                          {
                            staticClass: "button is-success",
                            on: {
                              click: function ($event) {
                                return _vm.add_new_reminder()
                              },
                            },
                          },
                          [_vm._v("Add")]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "button is-danger",
                            on: {
                              click: function ($event) {
                                return _vm.toggle_new_modal()
                              },
                            },
                          },
                          [_vm._v("Cancel")]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2739461315
            ),
          })
        : _vm._e(),
      _c("AdminUniversal", {
        staticClass: "mt",
        attrs: { component: "dropdown", props: _vm.dropdown_props1 },
      }),
      _vm.reminders_rows.length
        ? _c(
            "table",
            [
              _c(
                "tr",
                _vm._l(_vm.reminders_headers, function (header, i) {
                  return _c("th", { key: i, staticClass: "capitalize" }, [
                    _vm._v(" " + _vm._s(_vm.formatted_header(header)) + " "),
                  ])
                }),
                0
              ),
              _vm._l(_vm.reminders_rows, function (row, i) {
                return _c(
                  "tr",
                  { key: i },
                  [
                    _vm._l(_vm.reminders_headers, function (header, i) {
                      return _c("td", { key: i }, [
                        header === "reminder_date"
                          ? _c("div", [
                              _vm._v(_vm._s(row[header].split("T")[0])),
                            ])
                          : _c("div", [_vm._v(_vm._s(row[header]))]),
                      ])
                    }),
                    _c("td", [
                      _c(
                        "div",
                        {
                          staticClass: "button is-danger",
                          on: {
                            click: function ($event) {
                              return _vm.remove_reminder(row.id)
                            },
                          },
                        },
                        [_vm._v("Remove")]
                      ),
                    ]),
                  ],
                  2
                )
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }