<template>
<div>
    <div class="notification is-danger mt" v-if="show">
        <button class="delete" @click="hide"></button>
        {{ message }}
    </div>
</div>
</template>

<script>
export default {
    props: {
        message: String
    },
    data: function() {
        return {
            show: true
        }
    },
    methods: {
        hide: function() {
            this.show = false;
        }
    }
}
</script>

<style>

</style>