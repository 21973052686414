<template>
<div>
    <Title
        title="Food Sales"
        :location="location"
    />

    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container">
            <p>Work station</p>
            <DropDown
                :key="filter.work_station.state"
                :options="filter.work_station.options"
                :values="filter.work_station.values"
                :default="filter.work_station.state"
                @updateDropDown="update_filter_work_station"
            />
        </div>

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms mb">
        <Top8BarDoubleYAxis
            v-if="top_8.render"

            title="Food Sales Top 10 Countries"
            :showtitle="!$store.state.global.bot_mode"

            subtitle=""
            :xcategories="top_8.xcategories"
            yaxislabel1="Food Sales TY/LY"
            ymetric1=""
            yaxislabel2="Growth(MEUR)"
            ymetric2=""

            seriesname1="YTD Growth MEURO"
            seriesname2="YTD Index"
            seriesname3="4WK Index"
            seriesname4="Goal"

            seriestype2="bar"
            seriestype1="line"
            seriestype3="scatter"
            seriestype4="scatter"

            :seriesset1="top_8.seriesset1"
            color1="#787878"
            
            :seriesset2="top_8.seriesset2"
            :color2="$store.state.colors['yellow']"

            :seriesset3="top_8.seriesset3"
            :color3="$store.state.colors['grey']"

            :seriesset4="top_8.seriesset4"
            :color4="$store.state.colors.YG"

            :trend="true"
            :ranking="[]"
            :competitors="[]"
        />
        <div v-else>No data for {{$store.state.selected.country}}, with filter {{filter.work_station.state}}</div>
        
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Top8BarDoubleYAxis from '@/components/visualisations/bar/Top8DoubleYAxis.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Title,
        Top8BarDoubleYAxis
    },
    props: {
        market: String
    },
    data: function() {
        return {
            filter: {
                work_station: {
                    state: null,
                    options: [],
                    values: []
                }
            },
            top_8: {
                render: false,

                xcategories: [],

                seriesset1: [],                
                seriesset2: [],
                seriesset3: [],
                seriesset4: []
            },
            location: '/business/sales-per-range/food-sales'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.get_filters();
    },
    methods: {
         generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
                {
                    name: 'By Market',
                    link: `${this.location}/by-market/${this.$store.getters.market}`
                },
                {
                    name: 'Tree',
                    link: `${this.location}/tree/${this.$store.getters.market}`
                },
            ];
        },
        empty_previous_data: function() {
            this.top_8 = {
                render: false,

                xcategories: [],

                seriesset1: [],                
                seriesset2: [],
                seriesset3: [],
                seriesset4: []
            }
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'food-sales-work-station',
                disable_sort: true
            }).then(
                succ => {
                    succ.data.forEach(filter => {
                        this.filter.work_station.values.push(filter.value);
                        this.filter.work_station.options.push(filter.option);
                    });
                    this.filter.work_station.state = this.$store.getters.filter_check(this.filter.work_station.values); 
                },
                this.$helper.error
            );
        },
        get_data: function() {
            this.top_8.render = false;
            if (!this.filter.work_station.state) return;
            this.empty_previous_data();
            this.$http.post('/business/sales-per-range/food-sales/top-8', {
                work_station_filter: this.filter.work_station.state
            }).then(
                succ => {
                    let d = succ.data;
                    if (!d || !d[0] || !d[0].measures) return;
                    let decimal_settings = 1;
                    if (d[0].measure_metadata) {
                        decimal_settings = d[0].measure_metadata.decimal_settings || 0;
                    }
                    d.map(row => {
                        this.top_8.xcategories.push(this.$helper.maybe(row.caption));
                        this.top_8.seriesset1.push(this.$helper.maybe(row.measures['ytd_growth_meuro']));
                        this.top_8.seriesset2.push(this.$helper.round(this.$helper.maybe(row.measures['ytd_index']), decimal_settings));
                        this.top_8.seriesset3.push(this.$helper.maybe(row.measures['wk4_index']));
                        this.top_8.seriesset4.push(this.$helper.maybe(row.measures['goal']));
                    });

                    this.top_8.render = true;
                },
                this.$helper.error
            );
        },
        update_filter_work_station: function(work_station_filter) {
            this.filter.work_station.state = work_station_filter;
            this.$store.state.global.temp_work_station_filter = work_station_filter;
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        market: function(old_val, new_val) {
            this.triple.render = false;
            this.get_data();
        },
        '$store.getters.countries': function(newCountries, oldCountries) {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        },
        'filter.work_station.state': function() {
            this.get_data();
        }
    }
}
</script>

<style>

</style>