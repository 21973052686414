<template>
<div>
    <Title
        title="Availability"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
        
        <div class="label-container">
            <p>Service Level</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms">
            <Triple
                :key="triple.render"
                v-if="triple.render"

                title="Showing data for Availability Detail"
                :showtitle="!$store.state.global.bot_mode"
                subtitle=""

                :xcategories="triple.xcategories"
                yaxislabel="Service Level"
                ymetric="%"
                
                :seriesname1="data_name[0]"
                seriestype1="line"
                :seriesset1="triple.seriesset1"
                :color1="$store.state.colors['yellow']"
                
                :seriesname2="data_name[1]"
                seriestype2="line"
                :seriesset2="triple.seriesset2"
                :color2="$store.state.colors['grey']"
                
                :seriesname3="data_name[2]"
                seriestype3="line"
                :seriesset3="triple.seriesset3"
                :color3="$store.state.colors.YG"

                :show="!$store.state.global.bot_mode"
            />
            <div v-else> No data found for {{$store.getters.market}}</div>
        </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Triple from '@/components/visualisations/general/Triple.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Title,
        Triple
    },
    props: {
        market: String
    },
    data: function() {
        return {
            options: [],
            values: [],
            filter: null,
            options_set: [],
            values2: [],
            current_year: null,
            triple: {
                title: null,
                subtitle: null,

                xcategories: null,
                yaxislabel: null,
                ymetric: null,
                
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],

                render: false
            },
            location: '/business/availability',
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'Total',
                    link: this.location
                },
                {
                    name: 'Detail',
                    link: `${this.location}/detail`
                },
                
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'ava-1',
            }).then(
                succ => {
                    let d = succ.data;
                    d.map(filter => {
                        if (filter.level === 'sl') {
                            this.values.push(filter.value);
                            this.options.push(filter.option);
                        } else {
                            this.options_set.push(filter)
                        }
                    });
                    this.filter = this.$store.getters.filter_check(this.values)
                },
                this.$helper.error
            );
            this.load_market_data();
        },
        load_market_data: function() {
            let url = '/business/availability/detail';
            let succ = (succ) => {
                
                let d = succ.data;
                this.values2 = [];
                this.options_set.forEach(item => {
                    if (item.filter === this.filter) {
                        this.values2.push(item.value);
                    }
                });
                
                this.triple.xcategories = Object.keys(d[0].measures);
                
                this.triple.seriesset1 = [];
                this.triple.seriesset2 = [];
                this.triple.seriesset3 = [];

                this.triple.xcategories.map(week => {
                    let val = d[0].measures[week];
                    this.triple.seriesset1.push(this.$helper.maybe(val[`${this.data_name[0]}`]));
                    this.triple.seriesset2.push(this.$helper.maybe(val[`${this.data_name[1]}`]));
                    this.triple.seriesset3.push(this.$helper.maybe(val[`${this.data_name[2]}`]));
                });

                this.triple.render = true;
            }
            let error = (error) => console.log(error);
            this.$http.post(url, {filter: this.filter === 'Total' ? 'SL Total' : this.filter}).then(succ, error);
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.triple.render = false;
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location,
            });
            this.triple.render = false;
            this.load_market_data();
        }
    },
    computed : {
        data_name:function() {
            return this.values2
        }
    },
    watch: {
        market: function(old_val, new_val) {
            this.triple.render = false;
            this.load_market_data();
        },
        '$store.getters.countries': function(newCountries, oldCountries) {
            this.$store.commit('update_market', this.market);
        },
        filter: function() {
            this.load_market_data();
        } 
    }
}
</script>