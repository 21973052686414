<template>
<div>
    <Title
        title="Stock Levels"
        :location="location"
    />
    <div class="options-bar">
        <div class="label-container">
            <p>Filter</p>
            <DropDown
                :key="values[0]"
                :options="options"
                :values="values"
                :default="values[0]"
                @updateDropDown="update_filter"
            />
        </div>
    </div>
    <div v-if="filter == 'Summary'" :key="$store.getters.page_size" class="section half-height box ms">
        <Quad
            v-if="summary.render"

            :title="`Showing data for: ${$store.getters.selected_country_name}`"
            :showtitle="!$store.state.global.bot_mode"
            :xcategories="summary.xcategories"

            yaxislabel="Stock Level DCs, CDCs and stores (tm3)"
            ymetric=""
            
            seriesname1="Total"
            seriestype1="line"
            :seriesset1="summary.seriesset1"
            :color1="$store.state.colors['yellow']"

            seriesname2="CDC"
            seriestype2="line"
            :seriesset2="summary.seriesset2"
            :color2="$store.state.colors['grey']"

            seriesname3="Store"
            seriestype3="line"
            :seriesset3="summary.seriesset3"
            color3="black"

            seriesname4="CW"
            seriestype4="line"
            :seriesset4="summary.seriesset4"
            color4="purple"

            :show="!$store.state.global.bot_mode"
        />
        <div v-else> No data found for {{$store.getters.selected_country_name}}</div>
    </div>
    <div v-if="filter != 'Summary'" :key="$store.getters.page_size" class="section three-quarters-height box ms">
        <DeviationWith3Lines
            v-if="quad.render"

            :title="`Stock Levels for: ${$store.getters.selected_country_name}.`"
            :showtitle="!$store.state.global.bot_mode"
            subtitle=""

            :xcategories="quad.xcategories"
            
            yaxislabel1="Stock Level DCs, CDCs and stores (tm3)"
            
            ymetric1=""
            
            yaxislabel2="Change Week TY-LY"
            :yaxismin2="-800"
            :yaxismax2="800"
            ymetric2=""

            seriesname1="Change 1WK TY-LY"
            seriestype1="bar"
            :seriesset1="quad.seriesset1"
            :color1="$store.state.colors['yellow']"
            
            seriestype2="line"
            
            :seriesname3="`FY${last_year.substring(2)}`"
            seriestype3="line"
            :seriesset3="quad.seriesset3"
            :color3="$store.state.colors['dark-grey']"

            :seriesname4="`FY${current_year.substring(2)}`"
            seriestype4="line"
            :seriesset4="quad.seriesset4"
            :color4="$store.state.colors['focus-yellow']"

            :show="!$store.state.global.bot_mode"
        />
    </div>
</div>
</template>
       
<script>
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import DeviationWith3Lines from '@/components/visualisations/general/DeviationWith3Lines.vue';
import Quad from '@/components/visualisations/general/Quad.vue';

export default {
    components: {
        DropDown,
        Title,
        DeviationWith3Lines,
        Quad
    },
    props: {
        market: String
    },
    data: function() {
        let date = new Date();
        return {
            quad: {
                title: null,
                subtitle: null,

                xcategories: null,
                yaxislabel: null,
                ymetric: null,
                
                seriesset1: [],
                seriesset3: [],
                seriesset4: [],

                render: false
            },
            summary: {
                title: null,
                subtitle: null,

                xcategories: null,

                seriesset1: [],
                seriesset2: [],
                seriesset3: [],
                seriesset4: [],

                render: false,
            },
            options: ['Summary', 'Total (No CW)', 'CDC', 'Store'],
            values: ['Summary', 'Total (No CW)', 'CDC', 'Store'],
            filter: 'Summary',
            current_year: date.getFullYear().toString(),
            last_year: (date.getFullYear() - 1).toString(),
            location: '/business/stock-levels',
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.check_filter_options(this.$store.getters.market);
        this.load_market_data();
    },
    methods: {
        check_filter_options: function (newMarket) {
            let market = this.$helper.market_check(newMarket);
            if (!market || market === undefined) {
                this.options = ['Summary', 'Total (No CW)', 'CDC', 'CW', 'Store'];
                this.values = ['Summary', 'Total (No CW)', 'CDC', 'CW', 'Store'];
            } else {
                this.options = ['Summary', 'Total (No CW)', 'CDC', 'Store'];
                this.values = ['Summary', 'Total (No CW)', 'CDC', 'Store'];
            }
        },
        get_filter: function () {
            if (this.filter.includes("Total")) {
                return "Total";
            } else {
                return this.filter;
            }
        },
        load_quad_data: function () {
            this.$http.post('/business/stock-levels', {
                filter: this.get_filter(),
                market: this.$store.getters.market,
            }).then(
                succ => {
                    let d = succ.data;
                    this.quad.xcategories = [...Array(52).keys()].map(i => ("0" + (i + 1)).slice(-2));

                    let rest_of_year = this.quad.xcategories.slice(35);
                    let year_gone_by = this.quad.xcategories.slice(0, 35);
                    this.quad.xcategories = rest_of_year.concat(year_gone_by);

                    this.quad.seriesset1 = [];
                    this.quad.seriesset3 = [];
                    this.quad.seriesset4 = [];

                    this.quad.xcategories.forEach(week => {
                        let val = d[0].measures[week] || {};
                        this.quad.seriesset3.push(this.$helper.maybe(val[this.last_year], null));
                        this.quad.seriesset4.push(this.$helper.maybe(val[this.current_year], null));
                        this.quad.seriesset1.push(this.$helper.maybe(val['CY-LY'], null));
                    });
                
                    this.quad.render = true;
                },
                this.$helper.error
            );
        },
        load_summary_data: function() {
            this.$http.post('/business/stock-levels', {
                market: this.$store.getters.market,
            }).then(
                succ => {
                    let d = succ.data;
                    let currentWeek = (new Date()).getWeek();
                    let weeks = [...Array(52).keys()].map(i => ("0" + (i + 1)).slice(-2));
                    let rest_of_year = weeks.slice(35);
                    let year_gone_by = weeks.slice(0, 35);
                    if (currentWeek < 35) {
                        year_gone_by = year_gone_by.slice(0, currentWeek);
                    } else {
                        rest_of_year = rest_of_year.slice(0, currentWeek - 35);
                        year_gone_by = [];
                    }
                    this.summary.xcategories = rest_of_year.concat(year_gone_by);

                    this.summary.seriesset1 = [];
                    this.summary.seriesset2 = [];
                    this.summary.seriesset3 = [];
                    this.summary.seriesset4 = [];

                    let total = d[0].measures['Total'] || {};
                    let cdc = d[0].measures['CDC'] || {};
                    let store = d[0].measures['Store'] || {};
                    let cw = d[0].measures['CW'] || {};
                    let maybe = this.$helper.maybe;
                    this.summary.xcategories.forEach(week => {
                        let total_week = total[week] || {};
                        let cdc_week = cdc[week] || {};
                        let store_week = store[week] || {};
                        let cw_week = cw[week] || {};
                        let total_val = maybe(total_week[this.current_year], 0);
                        this.summary.seriesset1.push(maybe(total_val, null));
                        this.summary.seriesset2.push(maybe(cdc_week[this.last_year] || cdc_week[this.current_year], null));
                        this.summary.seriesset3.push(maybe(store_week[this.last_year] || store_week[this.current_year], null));
                        this.summary.seriesset4.push(maybe(cw_week[this.last_year] || cw_week[this.current_year], null));
                    });
                
                    this.summary.render = true;
                },
                this.$helper.error
            );
        },
        load_market_data: function() {
            this.summary.render = false;
            this.quad.render = false;
            if (this.filter == 'Summary') {
                this.load_summary_data();
            } else {
                this.load_quad_data();
            }
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.load_market_data();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.quad.render = false;
            this.summary.render = false;
            this.update_view(newMarket);
            this.check_filter_options(newMarket);
            this.load_market_data();
        },
    }
}
</script>

<style>

</style>
