<template>
    <div :id="title" style="width: 100%; height:100%;"></div>
</template>

<script>
import * as echarts from 'echarts/dist/echarts.min.js';
export default {
    props: {
        title: String, //title of the graph
        subtitle: String, //subtitle of the graph
        xcategories: Array, //data that will be displayed on the x axis
        xaxisrotation: Number, 
        xaxisfontsize:Number,
        yaxislabel: String, //name of the data on the y axis e.g 'After sales delivery share' or 'services sale by month'
        ymetric: String, //'%', '£', 'kg' etc.
        seriesname1: String, //name of the data such as IKEA 
        seriestype1: String, //bar, line, scatter etc.
        seriesset1: Array, //actual data array to fill the graph
        color1: String, //color of the bar lie or scatter for the paticular data set
        show: Boolean, // true to show the exta features such as 'save graph as image', 'refresh', 'change to bar graph', etc.
        showlabel: {
            type: Boolean,
            default: true
        },
        gridbottom: Number
    },
    mounted: function() {
        let myChart = echarts.init(document.getElementById(this.title));
        let option = {
            
            title:{ 
                text: this.title,
                top: '-6em',
            },
            tooltip: {
                trigger: 'axis', //when the user hovers over the axis
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            toolbox: {
                top: '30em',
                right: '10em',
                show: this.show,
                feature: {
                    restore: {},
                    saveAsImage: {}
                }
            },
            grid: {
               top: '70em',
               left: '70em',
               right: '10em',
               bottom: this.gridbottom ? `${this.gridbottom}em` : '80em'
            },
            legend: {
                top: '25em',
                left: '0em',
                data:[this.seriesname1],
            },
            textStyle: {
                fontFamily: 'NotoIKEALatin-Regular',
            },
            xAxis: [
                {
                    type: 'category',
                    data: this.xcategories,
                    axisPointer: {
                        type: 'shadow'
                    },
                    axisLabel: {
                        rotate: `${this.xaxisrotation}`,
                        fontSize: this.xaxisfontsize
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: this.yaxislabel,
                    nameLocation: 'middle',
                    nameGap: 50,
                    axisLabel: {
                        formatter: `{value} ${this.ymetric}`
                    }
                }
            ],
            series: [
                {
                    name: this.seriesname1,
                    type: this.seriestype1,
                    data: this.seriesset1,
                    color: this.color1,
                    label: {
                        fontSize: 12,
                        show: this.seriestype1 === 'bar' && this.showlabel ? true : false,
                        align: 'center',
                        verticalAlign: 'middle',
                        position: 'top',
                        color: 'black',
                    },
                }
            ]
        };
        myChart.setOption(option);
    }
}
</script>