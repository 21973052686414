<template>
<div>
    <div class="table-container">
        <table class="table is-fullwidth">
            <thead>
                <tr class="business">
                    <th v-for="(header, i) in headers" :key="i">{{header}}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, j) in data" :key="j">
                    <td v-for="(property, k) in Object.keys(row)" :key="k">{{row[property]}}</td>
                    <td v-if="buttons.length > 0">
                        <span v-for="(button, m) in buttons" :key="m">
                            <button @click="button.method(j)" :class="button.classes" class="button mr">
                                {{button.label}}
                            </button>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</template>

<script>
export default {
    props: {
        buttons: {
            type: Array,
            default: () => []
        },
        data: Array,
        headers: Array,
        render: Boolean
    }
    /** Example prop:
     * {
     *   buttons: [
     *     {
     *       label: 'Delete',
     *       classes: 'is-danger',
     *       method: () => {myFunc}
     *     }
     *   ],
     *   data: [
     *     {...},
     *     {...}
     *   ],
     *   headers: ['a', 'b', 'c'],
     *   render: true //unused, should be handled at parent level
     * }
     */
}
</script>

<style scoped>
.table-container {
    max-width: 768px;
}
</style>
