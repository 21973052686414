var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.render_graph
      ? _c(
          "div",
          {
            staticClass: "section half-height box ms mb",
            attrs: { id: _vm.id },
          },
          [
            _vm.graphs && _vm.selected_graph
              ? _c(
                  "Graph",
                  _vm._b(
                    {
                      attrs: {
                        title: `Showing data for: ${_vm.$store.getters.selected_country_name}`,
                        "show-title": !_vm.$store.state.global.bot_mode,
                      },
                    },
                    "Graph",
                    _vm.selected_graph,
                    false
                  )
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }