var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "has-text-left" }, [
    _c("div", { staticClass: "field" }, [
      _c(
        "label",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.label,
              expression: "label",
            },
          ],
          staticClass: "label",
        },
        [_vm._v(_vm._s(_vm.label))]
      ),
      _c("div", { staticClass: "control" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.modelled_input,
              expression: "modelled_input",
            },
          ],
          staticClass: "input",
          attrs: { type: "textinput", placeholder: _vm.placeholder || "" },
          domProps: { value: _vm.modelled_input },
          on: {
            change: function ($event) {
              return _vm.callback(_vm.modelled_input)
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.modelled_input = $event.target.value
            },
          },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }