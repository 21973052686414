<template>
<div>
    <Title title="Sales Tree" :location="location" />
    <div class="options-bar">
        <Buttons :buttons="generate_buttons()" class="mt1p5 is-pulled-left" />
		<div class="label-container" 
            :class="!$store.getters.market ? 'ft-tooltip': null" 
            :tooltiptext="!$store.getters.market ? 'Select a country from top filter to see stores' : null"

            v-show="!this.$store.getters.presentation_mode"
        >
            <p>Market Area</p>
            <DropDown
                :key="stores.values.length"
                :options="stores.options"
                :values="stores.values"
                :default="stores.values[0]"
                @updateDropDown="update_store"                
            />
        </div>
		<div class="label-container">
            <p>Filter</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>
    </div>
	<div class="box ms mb">
		<div class="sales-tree-wrapper">
			<div v-if="render" id="sales-tree" class="section relative">
				<div v-for="(box, i) in data_boxes" :key="`box${i}`">
					<svg width="100%" height="100%" font-size="0.8rem">
						<rect
							:x="`${box.x}%`"
							:y="`${box.y}%`"
							width="15%"
							height="7.5%"
							stroke="#dddddd"
							stroke-width="1"
							fill="#dddddd"
						/>
						<text
							:x="`${box.x + 7.5}%`"
							:y="`${box.title2 ? box.y + 3.5 : box.y + 4.4}%`"
							text-anchor="middle"
							fill="black"
						>
							{{box.title1}}
						</text>
						<text
							:x="`${box.x + 7.5}%`"
							:y="`${box.y + 5.5}%`"
							text-anchor="middle"
							fill="black"
						>
							{{box.title2}}
						</text>
						<rect
							:x="`${box.x}%`"
							:y="`${box.y + 7.5}%`"
							width="15%"
							height="7.7%"
							stroke="#dddddd"
							stroke-width="1"
							fill="white"
						/>
						<text 
							:x="`${box.x + 1}%`"
							:y="`${box.y + 10.5}%`"
							text-anchor="start"
							fill="black"
						>
							YTD
						</text>
						<text
							:x="`${box.x + 1}%`"
							:y="`${box.y + 13.5}%`"
							text-anchor="start"
							fill="black"
						>
							Index
						</text>
						<text
							:x="`${box.x + 14}%`"
							:y="`${box.y + 10.5}%`"
							text-anchor="end"
							fill="black"
						>
							{{box.YTD}}
						</text>
						<text
							:x="`${box.x + 14}%`"
							:y="`${box.y + 13.5}%`"
							text-anchor="end"
							fill="black"
						>
							{{box.index}}
						</text>
					</svg>
				</div>
				<div v-for="(line, i) in lines" :key="`line${i}`">
					<svg width="100%" height="100%">
						<line
							:x1="`${line.x1}%`"
							:y1="`${line.y1}%`"
							:x2="`${line.x2}%`"
							:y2="`${line.y2}%`"
							stroke="black"
							stroke-width="2"
							:stroke-dasharray="line.dashAarray ? line.dashAarray : ''"
						/>
						<line
							v-if="line.arrow === 'l' || line.arrow === 'r'"
							:x1="`${line.arrow === 'r' ? line.x2 - 0.1 : line.x2 + 0.1}%`"
							:y1="`${line.y2}%`"
							:x2="`${line.arrow === 'r' ? line.x2 - 0.5 : line.x2 + 0.5}%`"
							:y2="`${line.arrow === 'r' ? line.y2 + 0.5 : line.y2 - 0.5}%`"
							stroke="black"
							stroke-width="2"
						/>
						<line
							v-if="line.arrow === 'l' || line.arrow === 'r'"
							:x1="`${line.arrow === 'r' ? line.x2 - 0.1 : line.x2 + 0.1}%`"
							:y1="`${line.y2}%`"
							:x2="`${line.arrow === 'r' ? line.x2 - 0.5 : line.x2 + 0.5}%`"
							:y2="`${line.arrow === 'r' ? line.y2 - 0.5 : line.y2 + 0.5}%`"
							stroke="black"
							stroke-width="2"
						/>
						<line
							v-if="line.arrow === 'u' || line.arrow === 'd'"
							:x1="`${line.x2}%`"
							:y1="`${line.arrow === 'u' ? line.y2 + 0.1 : line.y2 - 0.1}%`"
							:x2="`${line.x2 + 0.4}%`"
							:y2="`${line.arrow === 'u' ? line.y2 + 0.7 : line.y2 - 0.7}%`"
							stroke="black"
							stroke-width="2"
						/>
						<line
							v-if="line.arrow === 'u' || line.arrow === 'd'"
							:x1="`${line.x2}%`"
							:y1="`${line.arrow === 'u' ? line.y2 + 0.1 : line.y2 - 0.1}%`"
							:x2="`${line.x2 - 0.4}%`"
							:y2="`${line.arrow === 'u' ? line.y2 + 0.7 : line.y2 - 0.7}%`"
							stroke="black"
							stroke-width="2"
						/>
					</svg>
				</div>
				<div v-for="(text, i) in texts" :key="`text${i}`">
					<svg width="100%" height="100%">
						<text
							:x="`${text.x}%`"
							:y="`${text.y}%`"
							:fill="text.fill ? text.fill : 'black'"
							:font-size="text.fontSize ? `${text.fontSize}rem` : '0.8rem'"
							:font-weight="text.fontWeight ? text.fontWeight : 'normal'"
						>
							{{text.text}}
						</text>
					</svg>
				</div>
			</div>
		</div>
	</div>
	<div v-if="!data_boxes.length">There is no data for <b>{{$store.getters.selected_country_name}}</b> with the <b>{{options[values.indexOf(filter)]}}</b> filter selected</div>
</div>
</template>

<script>
import Buttons from "@/components/ui/Buttons.vue";
import DropDown from '@/components/ui/DropDown.vue';
import Title from "@/components/ui/Title.vue";

export default {
	components: {
		Buttons,
		DropDown,
		Title
	},
	props: {
		market: String
	},
	data: function() {
		return {
			data_boxes: [],
			lines: [
				{
					x1: 50,
					y1: 15,
					x2: 50,
					y2: 30,
					arrow: "d"
				},
				{
					x1: 62.5,
					y1: 22.5,
					x2: 50,
					y2: 22.5,
					arrow: "l",
					dashAarray: '4 1'
				},
				{
					x1: 50,
					y1: 45,
					x2: 50,
					y2: 48.75,
					arrow: null
				},
				{
					x1: 24,
					y1: 48.75,
					x2: 76,
					y2: 48.75,
					arrow: null
				},
				{
					x1: 24,
					y1: 48.75,
					x2: 24,
					y2: 52.5,
					arrow: 'd'
				},
				{
					x1: 76,
					y1: 48.75,
					x2: 76,
					y2: 52.5,
					arrow: 'd'
				},
				{
					x1: 24,
					y1: 67.5,
					x2: 24,
					y2: 71,
					arrow: null
				},
				{
					x1: 12.5,
					y1: 71,
					x2: 35,
					y2: 71,
					arrow: null
				},
				{
					x1: 12.5,
					y1: 71,
					x2: 12.5,
					y2: 75,
					arrow: 'd'
				},
				{
					x1: 35,
					y1: 71,
					x2: 35,
					y2: 75,
					arrow: 'd'
				},

				{
					x1: 76,
					y1: 67.5,
					x2: 76,
					y2: 71,
					arrow: null
				},
				{
					x1: 65,
					y1: 71,
					x2: 87.5,
					y2: 71,
					arrow: null
				},
				{
					x1: 65,
					y1: 71,
					x2: 65,
					y2: 75,
					arrow: 'd'
				},
				{
					x1: 87.5,
					y1: 71,
					x2: 87.5,
					y2: 75,
					arrow: 'd'
				}
			],
			texts: [],
			options: ['Total Store','Store (Comp)', 'Online', 'Remote'],
			values: ['Store', 'Store(Comp)', 'Online', 'Remote'],
			filter: 'Store',
			store: null,
			render: false,
			location: "/business/sales-tree"
		};
	},
	mounted: function() {
		this.$store.commit("set_routing", true);
		if (this.market === 'Online') {
			this.filter = this.market;
			this.$store.state.global.temp_filter = 'Online';
		} else {
			this.filter = this.$store.getters.filter_check(this.values);
		} 
		this.load_market_data();
	},
	methods: {
		generate_buttons: function() {
			return [
				{
					name: "By Country",
					link: this.location
				},
				{
                    name: 'By Market',
                    link: `${this.location}/by-market/${this.$store.getters.market}`
                },
				{
					name: "Trend",
					link: `${this.location}/${this.$store.getters.market}`
				},
				{
					name: "Tree",
					link: `${this.location}/tree/${this.$store.getters.market}`
				},
				{
					name: "Top 10",
					link: `${this.location}/top-8/Online`
				}
			];
		},
		load_market_data: function() {
            if (!this.filter) return;
			let market = 'ALL';
            if (this.store && this.$store.getters.market) {
                market = this.store;
            } else if (!this.store && this.$store.getters.market) {
                market = this.$store.getters.market;
            }
            this.$http.post('/business/sales-tree/detailed', {
                market: market,
                filter: this.filter,
            }).then(
                succ => {
                    let d = succ.data;					
					if (this.filter === 'Store' && !d[0].measures) {
						this.options.shift();
						this.values.shift();
						this.filter = this.values[0];
						this.load_market_data();
						return;
					}
					if (!d[0] || !d[0].measures) return;
					let val = succ.data[0].measures;
					this.data_boxes = [
						{
							x: 42.5,
							y: 0,
							title1: "Created Sales, net",
							title2: '(MEUR)',
							YTD: this.formatter(val.cre_sales_net_val || null),
							index: this.formatter(val.cre_sales_net_index || null)
						},
						{
							x: 62.5,
							y: 15,
							title1: "Returns",
							title2: '(MEUR)',
							YTD: this.formatter(val.return_sales_val || null),
							index: this.formatter(val.returns_index || null)
						},
						{
							x: 78,
							y: 15,
							title1: "Cancellations",
							title2: '(MEUR)',
							YTD: this.formatter(val.cancelled_val || null),
							index: this.formatter(val.cancelled_index || null)
						},
						{
							x: 42.5,
							y: 30,
							title1: "Created Sales, gross",
							title2: '(MEUR)',
							YTD: this.formatter(val.cre_sales_gross_val || null),
							index: this.formatter(val.cre_sales_gross_index || null)
						},
						{
							x: 16.5,
							y: 52.5,
							title1: "Customers",
							title2: "(Gross in Millions)",
							YTD: this.formatter(val.cre_no_of_trans_val || null),
							index: this.formatter(val.cre_no_of_trans_index || null)
						},
						{
							x: 68.5,
							y: 52.5,
							title1: "Average Ticket",
							title2: '(EUR)',
							YTD: this.formatter(val.avg_ticket_val || null),
							index: this.formatter(val.avg_ticket_index || null)
						},
						{
							x: 5,
							y: 75,
							title1: "Number of Visits",
							title2: '(Millions)',
							YTD: this.formatter(val.no_of_visits_val || null),
							index: this.formatter(val.no_of_visits_index || null)
						},
						{
							x: 27.5,
							y: 75,
							title1: "Conversion Rate",
							title2: '(%)',
							YTD: this.formatter(val.conversion_rate_val || null),
							index: this.formatter(val.conversion_rate_index || null)
						},
						{
							x: 57.5,
							y: 75,
							title1: "Average Items per Trans",
							title2: null,
							YTD: this.formatter(val.avg_items_per_trans_val || null),
							index: this.formatter(val.avg_items_per_trans_index || null)
						},
						{
							x: 80,
							y: 75,
							title1: "Average Sales per Qty",
							title2: '(EUR)',
							YTD: this.formatter(val.avg_sale_per_qty_val || null),
							index: this.formatter(val.avg_sale_per_qty_index || null)
						}
					]
					this.texts = [
						{
							x: 51,
							y: 21,
							text: 'Gap'
						},
						{
							x: 58,
							y: 37,
							text: 'Gross',
							fontWeight: 'bold'
						},
						{
							x: 58,
							y: 39,
							text: `Include ${this.filter} sales that later get returned or canceled`,
							fill: '#6d6d6d'
						}
					],
					this.render = true;
                },
                this.$helper.error
            );
        },
		formatter: function(n) {
			if (n) {
				if (n % 1 !== 0) {
					n = Math.round(n * 10) / 10;
				}
				if (n >= 1000 || n <= -1000) {
					n = n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
				}
			}
			return n
		},
		update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.load_market_data();
        },
		update_store: function(store) {
            this.store = store;
            this.render = false;
            this.load_market_data();
        },
		update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: `${this.location}/tree`,
            });
        }
	},
	watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.store = null;
            this.render = false;
            this.load_market_data();
        }
    },
    computed: {
        stores: function() {
            if (this.$store.getters.stores.values.length) {
                return this.$store.getters.stores;
            } 
			return {
				all: [],
				options: [],
				values: []
			}
        }
    }
};
</script>

<style scoped>
.box {
    padding: 0;
}
#sales-tree {
	min-height: 760px;
    min-width: 900px;
	transform: rotateX(180deg);
}
.sales-tree-wrapper {
	overflow-x: auto;
	overflow-y: hidden;
	transform: rotateX(180deg);
}
svg {
  	background: #ffffff00;
	left: 0;
	position: absolute;
	top: 2rem;
}
</style>
