<template>
    <div :id="title" style="width: 100%; height:100%;"></div>
</template>

<script>
import * as echarts from 'echarts/dist/echarts.min.js';
export default {
    props: {
        title: String, //title of the graph
        showtitle: Boolean,
        subtitle: String, //subtitle of the graph
        ycategories: Array, //values that will be displayed on the y axis 
        xaxislabel: String, //name of the data on the x axis
        xmetric: String, //'%', '£', 'kg' etc.
        seriesname1: String, //name of the data such as IKEA 
        seriesset1: Array, //actual data array to fill the graph
        color1: String, //color of the bar lie or scatter for the paticular data set
        color2: String,
        show: Boolean, // true to show the exta features such as 'save graph as image', 'refresh', 'change to bar graph', etc
    },
    mounted: function() {
        let myChart = echarts.init(document.getElementById(this.title));
        let option = {
            title:{
                text: this.title,
                subtext: this.subtitle,
                show: this.showtitle,
                subtextStyle: {
                    color: 'black',
                },
                top: '0em',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            toolbox: {
                top: '55em',
                right: '73em',
                show: this.show,
                feature: {
                    restore: {},
                    saveAsImage: {}
                }
            },
            grid: {
               top: '50em',
               left: '120em',
               right: '85em',
               bottom: '30em',
            },
            legend: {
                top: '25em',
                left: '0em',
                data: [this.seriesname1],
            },
            textStyle: {
                fontFamily: 'NotoIKEALatin-Regular',
            },
            yAxis: [
                {
                    type: 'category',
                    data: this.ycategories,
                    nameGap: 0,
                    axisPointer: {
                        type: 'shadow'
                    },
                    axisLabel: {
                        rotate: '35',
                        fontSize: 12
                    }
                }
            ],
            xAxis: [
                {
                    type: 'value',
                    name: this.xaxislabel,
                    nameLocation: 'middle',
                    nameGap: 50,
                    axisLabel: {
                        formatter: `{value} ${this.xmetric}`
                    }
                }
            ],
            series: [
                {
                    type: 'bar',
                    name: this.seriesname1,
                    color: this.color2,
                    data: this.call_series_data()
                }                
            ]
        };
        myChart.setOption(option);
    },
    methods: {
        call_series_data() {
            let temp = [];
            this.seriesset1.forEach((series_set, index) => {
                temp.push({
                    value: series_set,
                    itemStyle: {color: (this.seriesset1.length -1) === index ? this.color1 : this.color2},
                    label: {
                        show: series_set ? true : false,
                        position: 'insideRight',
                        color: (this.seriesset1.length -1) === index ? 'white': 'black'
                    }
                });
            });
            return temp
        }
    }
}
</script>