var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: {
          title: "Services Gross Margin deviation By Service",
          location: _vm.location,
        },
      }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons() },
          }),
          _c(
            "div",
            { staticClass: "label-container" },
            [
              _c("p", [_vm._v("After Sales")]),
              _c("DropDown", {
                key: _vm.filter.after_sales.state,
                attrs: {
                  options: _vm.filter.after_sales.options,
                  values: _vm.filter.after_sales.values,
                  default: _vm.filter.after_sales.state,
                },
                on: { updateDropDown: _vm.update_filter_after_sales },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "label-container" },
            [
              _c("p", [_vm._v("Actual vs Goal in")]),
              _c("DropDown", {
                key: _vm.filter.goal_units.state,
                attrs: {
                  options: _vm.filter.goal_units.options,
                  values: _vm.filter.goal_units.values,
                  default: _vm.filter.goal_units.state,
                },
                on: { updateDropDown: _vm.update_filter_goal_units },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                key: _vm.$store.getters.market,
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: _vm.$store.getters.market,
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size,
          staticClass: "section three-quarters-height box ms mb",
        },
        [
          _vm.single.render
            ? _c("Single", {
                attrs: {
                  title: `GM Deviation in ${
                    _vm.filter.goal_units.options[
                      _vm.filter.goal_units.values.indexOf(
                        _vm.filter.goal_units.state
                      )
                    ]
                  } - Actual vs Goal`,
                  xcategories: _vm.single.xcategories,
                  xaxisrotation: 35,
                  xaxisfontsize: 10,
                  yaxislabel:
                    _vm.filter.goal_units.options[
                      _vm.filter.goal_units.values.indexOf(
                        _vm.filter.goal_units.state
                      )
                    ],
                  ymetric: "",
                  seriesname1: "GM Deviation",
                  seriestype1: "bar",
                  seriesset1: _vm.single.seriesset1,
                  color1: _vm.$store.state.colors["yellow"],
                  gridbottom: 150,
                  show: true,
                  showlabel: false,
                },
              })
            : _c("div", [
                _vm._v(
                  " No data found for " +
                    _vm._s(_vm.$store.getters.selected_country_name)
                ),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }