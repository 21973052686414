<template>
<div>
    <Title
        title="Product Quality Costs"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>

    <!-- <Table
        v-if="table.render"
        
        :headers="table.headers"
        :rows="table.rows"
        
        :icon-settings="table.icon_settings"
        :decimal-settings="table.decimal_settings"
        :data-settings="table.data_settings"
        :sort-settings="{ 'Country': { asc: true } }"
        :font-settings="{ bold: {row: {regex: 'Total Retail Countries' } } }"
        
    />
    <div v-else>No data found for {{$store.getters.selected_country_name}}</div> -->
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            table: {
                headers: [],
                render: false,
                rows: [],
                decimal_settings: {},
                data_settings: { nullify_zeros: true }
            },
            options: [],
            values: [],
            location: '/finance/product-quality'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);

        this.load_ingka_by_country();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                }
            ];
        },
        load_ingka_by_country: function() {
            this.$http.post('finance/product-quality/total-ingka-by-country', {
                market: 'ALL'
            }).then(
                succ => {
                    if (!succ.data) return;
                    let d = succ.data;
                    if (d[0].measure_metadata) {
                        this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                    }
                    this.table.headers = null;
                    this.table.rows = null;
                    let keys = Object.keys(d[0].measures);

                    // Reorder this also needs to be handled by the data sitde, but fixed for now here
                    let temp_ordering = [
                        'First Time Right',
                        'Durability',
                        'Product Packaging',
                        'Safety/Recall',
                        'Total Product Quality Cost'
                    ];
                    this.table.rows = d.map(row => {
                        let temp = [];
                        // If the key/values don't exist, set them to 0
                        temp_ordering.map(key => {
                            // Idealy this should be done in the backend, we need - reordering & removal of redundant data
                            temp.push(this.$helper.maybe(row.measures[key]['YTD']));
                            temp.push(this.$helper.maybe(row.measures[key]['Month Trend']));
                            if (key === 'First Time Right') {
                                temp.push(this.$helper.maybe(row.measures[key]['Goal']));
                            }
                        });
                        return [row.caption].concat(temp);
                    });

                    this.table.icon_settings = {
                        'Total Product Quality Cost YTD': {
                            fix: { post: '%' }
                        },
                        'Total Product Quality Cost Month Trend': {
                            icon: 'arrow',
                            comparison: 'Total Product Quality Cost YTD',
                            fix: { post: '%' }
                        },
                        'First Time Right YTD': {
                            fix: { post: '%' }
                        },
                        'First Time Right Month Trend': {
                            icon: 'arrow',
                            comparison: 'First Time Right YTD',
                            fix: { post: '%' }
                        },
                        'First Time Right Year Goal': {
                            fix: { post: '%' }
                        },
                        'Durability YTD': {
                            fix: { post: '%' }
                        },
                        'Durability Month Trend': {
                            icon: 'arrow',
                            comparison: 'Durability YTD',
                            fix: { post: '%' }
                        },
                        'Product Packaging YTD': {
                            fix: { post: '%' }
                        },
                        'Product Packaging Month Trend': {
                            icon: 'arrow',
                            comparison: 'Product Packaging YTD',
                            fix: { post: '%' }
                        },
                        'Safety/Recall YTD': {
                            fix: { post: '%' }
                        },
                        'Safety/Recall Month Trend': {
                            icon: 'arrow',
                            comparison: 'Safety/Recall YTD',
                            fix: { post: '%' }
                        }
                    }

                    this.table.headers = [
                        [ 
                            { '': 1, },
                            { 'First Time Right': 3, },
                            { 'Durability': 2, },      
                            { 'Product Packaging': 2, },
                            { 'Safety/Recall': 2, },
                            { 'Total Product Quality Cost': 2, },
                        ],
                        // Row 2
                        [
                            // Country
                            { 'Country': 1, },
                            // First Time Right
                            { 'YTD': 1, alias: 'First Time Right YTD' },
                            { 'Month Trend': 1, alias: 'First Time Right Month Trend' },
                            { 'Year Goal': 1, },
                            // Durablity
                            { 'YTD': 1, alias: 'Durability YTD' },
                            { 'Month Trend': 1, alias: 'Durability Month Trend' },
                            // Product Packaging
                            { 'YTD': 1, alias: 'Product Packaging YTD' },
                            { 'Month Trend': 1, alias: 'Product Packaging Month Trend' },
                            // Safety / Recall
                            { 'YTD': 1, alias: 'Safety/Recall Month Trend' },
                            { 'Month Trend': 1, alias: 'Safety/Recall Month Trend' },
                            // Total Product Quality Cost
                            { 'YTD': 1, alias: 'Total Product Quality Cost YTD' },
                            { 'Month Trend': 1, alias: 'Total Product Quality Cost Month Trend' },
                        ],
                    ];

                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        }
    }
}
</script>
