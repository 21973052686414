<template>
  <div id="title-header-container" class="custom-title">
    <div class="title">
      {{ page_details.kpi_title }}
      <div class="cursor-pointer inline-block" @click="show_kpi">
        <i v-show="!$store.getters.presentation_mode" class="fas fa-info-circle superscript text-ikea-blue"></i>
      </div>
    </div>

    <div v-show="show_kpi_defintion">
      <div v-if="is_loaded.kpi_definition">
          <div v-if="kpi_definition">
              <b>Definition:</b> <div class="column ProseMirror" v-html="kpi_definition"/>
              <hr>
              <DefinitionRatingButtons v-if="this.definition_id" :id="this.definition_id" :current="this.rating"/>
          </div>
      </div>
      <div v-else>
          <b>Definition:</b> <div class="column ProseMirror">
              This KPI definition hasn't been defined.
          </div>
      </div>
  </div>

  <div class="">
          <div class="subtitle pbp5" v-if="page_details.kpi_subtitle ">
              <p>{{ page_details.kpi_subtitle }}</p>
          </div>
          
          <div class="subtitle" v-if="page_details.kpi_accountable">
              <b>KPI Accountable</b>: {{ page_details.kpi_accountable }}
          </div>
          
          <div class="subtitle" v-if="page_details.kpi_last_updated">
              <b>Last updated:</b> {{ page_details.kpi_last_updated }}
          </div>
  
          <div class="subtitle" v-if="page_details.kpi_load_status">
              <!-- <b>Load status: </b>
               <span :class="[load_tracker_data.status_indicator]"><i class="fas fa-circle"></i></span> 
              {{ page_details.kpi_load_status }} -->
              <!-- <span v-if="page_details.kpi_load_comment">, <span class="load-comment-wrapper">{{ page_details.kpi_load_comment }}</span></span> -->
          </div>
  
          <div v-if="show_view_more">
              <!-- <div class="subtitle" v-if="load_tracker_data && load_tracker_data.processing_end">
                  <b>Next update by:</b> {{load_tracker_data.processing_end.toUTCString().slice(0,16) || 'N/A'}}
              </div> -->
              
              <div class="subtitle" v-if="page_details.kpi_frequency">
                  <b>Frequency:</b> {{ page_details.kpi_frequency }}
              </div> 
          </div>
          
          <div v-if="page_details.kpi_frequency || page_details.kpi_next_update"
              @click="show_view_more = !show_view_more"
              class="view-more">
              <span v-if="show_view_more">View less <i class="fas fa-chevron-up"></i></span>
              <span v-else>View more <i class="fas fa-chevron-down"></i></span>
          </div>
      
      </div>
  
  </div>
  </template>

<script>
import DefinitionRatingButtons from '@/components/ui/DefinitionRatingButtons.vue';

export default {
  components: {
    DefinitionRatingButtons
  },
  props: {
    page_details: Object,
    location: Object
  },
  data: function() {
      return {
          excluded_locations: [
              'by-market',
              'detail',
              'summary',
              'top-8',
              'tree',
              'trend'
          ],
          rating: null,
          location_id: null,
          load_tracker_data: null,
          kpi_definition: false,
          kpi_owner: null,
          is_bookmarked: false,
          is_loaded: {
              subtitle: false,
              kpi_definition: false,
              kpi_owner: false
          },
          last_updated: 'N/A',
          show_kpi_defintion: null,
          show_kpi_owner: null,
          show_view_more: false,
      }
  },
  mounted: function() {
      this.load_definition(this.location);
  },
  methods: {
    show_kpi() {
      this.show_kpi_defintion = !this.show_kpi_defintion;
    },

    load_definition(location) {
    const definition_location = this.format_location(location);
    if (definition_location.kpi_area === 'gross-margin-per-country') definition_location.market_area = null;
    this.$http.post('/admin/kpi-definition/load', {
      location: definition_location
    }).then(
      succ => {
        let d = succ.data;
        if (!d || d.length === 0) return;
        const definition = succ.data.pop();
        this.rating = definition.rating
        this.kpi_definition = definition.definition;
        this.definition_id = definition.id;
        this.is_loaded.kpi_definition = true;
      },
      this.$helper.error
    );
  },

  format_location(location) {
    let new_location = {
      kpi: location.kpi,
      kpi_area: location.kpi_area,
      market_area: !this.excluded_locations.includes(location.market_area) ? location.market_area : null,
      store_area: null
    };
    if (new_location.kpi_area === 'sales-planning-precision') new_location.market_area = null;
    return new_location;
  },
  }
}
</script>
   

<style scoped>
hr {
padding: 0em;
margin: 1em;
}
.custom-title {
color: #000;
margin: 1rem;
text-align: left;
width: calc(100% - 2em);
}
.custom-title .title {
text-transform: uppercase;
}
.custom-title .subtitle {
color: #000;
font-size: 1rem;
margin: 0;
}
.load-comment-wrapper {
color: #484848;
}
.title {
margin-bottom: 1rem !important;
}
.view-more {
color: #0051ba;
cursor: pointer;
}
.view-more:hover {
color: #014297;
}

</style>
