<template>
<div>
    <AdminUniversal
        component="title"
        :props="title_props"
    />
    <AdminUniversal
        component="button"
        :props="button_props"
    />
    <table class="table is-narrow">
        <thead>
            <tr class="business">
                <th class="text-white">ID</th>
                <th>User</th>
                <th>Name</th>
                <th>Created At</th>
                <th>Options</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(row, index) in table.rows" :key="index">
                <td>{{row.id}}</td>
                <td>{{row.user_id}}</td>
                <td>{{row.name}}</td>
                <td>{{row.created_at}}</td>
                <td>
                    <a @click="update_form_status(row.id, row.enabled)" class="button" :class="{'is-danger': row.enabled, 'is-success': !row.enabled}">
                        {{row.enabled ? 'Disable' : 'Enable'}}
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>
</template>
<script>
import AdminUniversal from '@/components/admin/index.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';

export default {
    components: {
        AdminUniversal,
        Table
    },
    data: function() {
        return {
            title_props: {
                title: 'Current Forms',
                subtitle: 'After enabling a new form, refresh your page.'
            },
            button_props: {
                label: 'New Form',
                classes: 'is-success',
                method: this.navigate
            },

            table: {
                render: false,
                
                headers: null,
                rows: null
            }
        }
    },
    mounted: function() {
        this.load_forms();
    },
    methods: {
        load_forms: function() {
            this.table.render = false;
            this.$http.get('force', '/nps/forms').then(
                ({ data }) => {
                    this.table.headers = [Object.keys(data[0])];
                    this.table.rows = data;
                    this.table.rows.forEach( row => {
                        row.created_at = this.$helper.date_formatting(row.created_at, 'DATETIME');
                    });
                    this.table.render = true;
                },
                err => console.log(err)
            );
        },
        navigate: function() {this.$router.push('/admin/nps/forms/new');},
        update_form_status: function(form_id, enabled) {
            let enabled_list = [];
            let url =  '/nps/forms/form/disable';
            if (!enabled) {
                if (this.table.rows.map(row => row.enabled).indexOf(true) > -1) {
                    alert('Please disable the current Questionnaire before enabling another one');
                    return;
                } else {
                    url = '/nps/forms/form/enable';
                }
            }
            this.$http.post('force', url, { form_id }).then(
                succ => this.load_forms(),
                this.$helper.error
            );
        }
    }
}
</script>
<style scoped>
table.table thead tr th {
    color: #FFF;
}
</style>