<template>
<div>
    <Title
        title="Cost Per Country"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container">
            <p>Filter</p>
            <DropDown
                :key="filter"
                :options="primary_options"
                :values="primary_values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>
        <div class="label-container">
            <p>Cost Type</p>
            <DropDown
                :key="secondary_filter"
                :options="secondary_options"
                :values="secondary_values"
                :default="secondary_filter"
                @updateDropDown="update_secondary_filter"
            />
        </div>
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <Table
        v-if="table.render"
        :headers="table.headers"
        :rows="table.rows"
        :decimal-settings="table.decimal_settings"
        :icon-settings="table.icon_settings"
        :data-settings="{ nullify_zeros: true }"
        :font-settings="{ bold: {row: {regex: 'Total' } } }"
        :sort-settings="{ 'Market-Area': { asc: true } }"
        :meta-settings="{ is_selectable: false }"
        
        class="ms1p5"
    />
    <div v-else>No data for {{$store.state.selected.country}}, please select another country</div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    props: {
        market: String
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: [],
                icon_settings: {},
                decimal_settings: {},
                render: false
            },
            location: '/finance/cost-per-country',
            primary_options: [],
            primary_values: [],
            filter: null,
            secondary_filter: null,
            secondary_options: [],
            secondary_values: []
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: `${this.location}`
                },
                {
                    name: 'By Market',
                    link: `${this.location}/trend/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'totsal-sales-cntry-vsgoal'
            }).then(
                succ => {
                    succ.data.map(filter => {
                        this.primary_values.push(filter.value);
                        this.primary_options.push(filter.option);
                    });
                    let retail_index = this.primary_values.findIndex(row => row.includes('Retail Country'));
                    if (retail_index >= 0) this.filter = this.primary_values[retail_index];
                    else this.filter = this.$store.getters.filter_check(this.primary_values);
                    if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
                    this.load_ingka_by_country();
                },
                this.$helper.error
            );
        },
        load_ingka_by_country: function() {
            this.table.render = false;
            if (!this.$store.getters.market) return;
            this.$http.post('/finance/cost-per-country/market', {
                filter: this.filter,
                market: this.$store.getters.market
            }).then(
                succ => {
                    let d = succ.data;
                    if (!d || !d[0].measures) {
                        this.table.render = false;
                        return this.$helper.error;
                    };
                    if (d[0].measure_metadata) {
                        this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                    }
                    if (!this.secondary_options.length || !this.secondary_values.length && Object.keys(d[0].measures).length) {
                        for (const key of Object.keys(d[0].measures)) {
                            this.secondary_options = [ ...this.secondary_options, key ];
                            this.secondary_values = [ ...this.secondary_values, key ];
                        }
                        if (!this.secondary_filter) this.secondary_filter = this.secondary_values[0];
                    }
                    let keys = Object.keys(d[0].measures[this.secondary_filter]) || [];
                    this.table.headers = [['Market-Area'].concat(keys).map(c => {
                        return {
                            [c]: 1
                        }
                    })];
                    this.table.rows = d.map(row => {
                        if (!row.measures[this.secondary_filter]) row.measures[this.secondary_filter] = {};
                        let temp = [];
                        // If the key/values don't exist, set them to 0
                        keys.map(key => {
                            if (
                                typeof (row.measures[this.secondary_filter][key]) === 'number' ||
                                typeof (row.measures[this.secondary_filter][key]) === 'string'
                            ) temp.push(this.$helper.maybe(row.measures[this.secondary_filter][key]) + '%');
                            else temp.push('');
                        });
                        return [row.caption].concat(temp);
                    });
                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.load_ingka_by_country();
        },
        update_secondary_filter: function (filter) {
            this.secondary_filter = filter;
            this.load_ingka_by_country();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: `${this.location}/trend`
            });
        }
    },
    watch: {
        '$store.getters.countries': function(newCountries, oldCountries) {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.table.render = false;
            this.update_view(newMarket);
            this.load_ingka_by_country();
        },
        filter: function() {
            this.load_ingka_by_country();
        },
        secondary_filter: function() {
            this.load_ingka_by_country();
        }
    }
}
</script>
