var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "kitchensink" } },
    [
      _c("Title", {
        attrs: {
          title: "Example Embedded Power BI Report",
          subtitle:
            "A Prototype embedded Power BI report that reacts to market filter",
        },
      }),
      _c("div", [
        _vm._v(
          "Currently selected market: " +
            _vm._s(_vm.$store.getters.selected_country_name)
        ),
      ]),
      _c("div", { staticClass: "section box" }, [
        _c("iframe", {
          attrs: {
            title: "FlightTowerTest",
            src: `https://app.powerbi.com/reportEmbed?${_vm.report_src_filters}reportId=937e5e82-9f73-4051-a5ec-544505e155e7&appId=e663d921-84f7-4b88-a3f0-e128bbf3fc6b&autoAuth=true&ctid=720b637a-655a-40cf-816a-f22f40755c2c`,
            frameborder: "0",
            allowFullScreen: "true",
          },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }