var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("AdminUniversal", {
        attrs: { component: "title", props: _vm.title_props },
      }),
      _c("KPIChooser", {
        staticClass: "kpi-chooser",
        on: {
          chosen: _vm.kpi_chosen,
          reset: function ($event) {
            _vm.chosen_kpi = null
          },
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.chosen_kpi,
              expression: "chosen_kpi",
            },
          ],
          staticClass: "editor-wrapper",
        },
        [
          _vm.chosen_kpi
            ? _c("Title", {
                attrs: {
                  title: _vm.chosen_kpi.name,
                  subtitle: "KPI Definition",
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "text-title-left" }, [
            _vm._v(" Current Definition "),
          ]),
          _vm.current_definition
            ? _c("div", {
                staticClass: "current-definition",
                domProps: { innerHTML: _vm._s(_vm.current_definition) },
              })
            : _vm._e(),
          _c("div", { staticClass: "text-title-left" }, [
            _vm._v(" New Definition "),
          ]),
          _vm.current_definition
            ? _c("WYSIWYG", {
                staticClass: "new-definition",
                attrs: { content: _vm.current_definition },
                on: { textUpdate: _vm.update_text },
              })
            : _vm._e(),
          _c(
            "a",
            {
              staticClass: "button is-success",
              on: { click: _vm.update_definition },
            },
            [_vm._v("Update Definition")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }