<template>
    <div class="label-container">
        <p v-if="label">{{label}}</p>
        <div class="select">
            <select v-model="current_selection" @change="update">
                <option
                    v-for="(option, index) in options"
                    :key="index"
                    :value="values[index]"
                >
                    {{option}}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        default: String,
        label: String,
        name: String,
        options: Array,
        values: Array
    },
    data: function() {
        return {
            current_selection: null
        }
    },
    mounted: function() {
        if (!this.values || !this.values.length ||
            !this.options || !this.options.length) return;
        if (!this.$route.query) return;
        const query = this.$route.query;
        if (this.name in query) { // Explicit filter setting first
            this.apply_filter(query[this.name]);
        } else if ('filter' in query) {
            this.check_filter_relevence(query.filter);
        } else {
            this.current_selection = this.default;
        }
    },
    methods: {
        apply_filter: function(option) {
            if (!this.options.includes(option)) return;
            this.current_selection = this.values[this.options.indexOf(option)];
            this.update();
        },
        check_filter_relevence: function(filter_query) {
            const values = filter_query.split(',');
            let found = false;
            values.forEach(value => {
                if (this.options.includes(value)) {
                    found = true;
                    this.apply_filter(value);
                }
            });
            if (!found) this.current_selection = this.default;
        },
        update: function() {
            if (this.$route.query && this.name in this.$route.query) {
                this.$router.replace({ query: { [this.name]: '' }});
            }
            if (this.$route.query && 'filter' in this.$route.query) {
                this.$router.replace({ 'query.filter': '' });
            }
            this.$emit('updateDropDown', this.name, this.current_selection);
        }
    }
}
</script>

<style>
.select select {
    border-radius: 0 !important;
}
</style>