<template>
<div>
    <Title
        title="Goods value connected to a service"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
        <div class="label-container">
            <p>HFB</p>
            <DropDown
                v-if="hfbs.all.length > 0"
                :key="hfbs.all.length"
                :options="hfbs.options"
                :values="hfbs.values"
                :default="hfbs.values[0]"
                @updateDropDown="update_hfb"
            />
        </div>
    
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms">
        <Triple
            :key="triple.render"
            v-if="triple.render"

            :title="`Showing data for: ${$store.getters.selected_country_name}`"
            :showtitle="!$store.state.global.bot_mode"

            subtitle=""

            :xcategories="triple.xcategories"
            yaxislabel="Goods Value connected to a service Index"
            ymetric=""
            
            seriesname1="YTD"
            seriestype1="line"
            :seriesset1="triple.seriesset1"
            :color1="$store.state.colors['focus-yellow']"
            
            seriesname2="Total HFO Sales"
            seriestype2="line"
            :seriesset2="triple.seriesset2"
            :color2="$store.state.colors['light-grey']"

            seriesname3="4WK"
            seriestype3="line"
            :seriesset3="triple.seriesset3"
            :color3="$store.state.colors['dark-grey']"

            :show="!$store.state.global.bot_mode"
        />
        <div v-else> No data found for {{$store.getters.market}}</div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Triple from '@/components/visualisations/general/Triple.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Title,
        Triple
    },
    props: {
        market: String
    },
    data: function() {
        return {
            triple: {
                title: null,
                subtitle: null,

                xcategories: null,
                yaxislabel: null,
                ymetric: null,
                
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],

                render: false
            },
            hfb: 'Total HFB',
            store: null,
            location: '/business/sales-per-range/goods-value-connected'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.load_market_data();
    },
    methods: {
         generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
                {
                    name: 'By HFB',
                    link: `${this.location}/by-hfb/${this.$store.getters.market}`
                },
            ];
        },
        load_market_data: function() {
            this.$http.post('/business/sales-per-range/goods-value-connected/market', {
                market: this.$store.getters.market,
                hfb: this.hfb
            }).then(
                succ => {
                    let d = succ.data;
                    this.triple.xcategories = Object.keys(d[0].data);
                    
                    this.triple.seriesset1 = [];
                    this.triple.seriesset2 = [];
                    this.triple.seriesset3 = [];
                    
                    this.triple.xcategories.map(month => {
                        let val = d[0].data[month];
                        this.triple.seriesset1.push(this.$helper.maybe(val['YTD Index']));
                        this.triple.seriesset2.push(this.$helper.maybe(val['Total HFO Sales Index']));
                        this.triple.seriesset3.push(this.$helper.maybe(val['4wk Index']));
                    });

                    this.triple.render = true;
                },
                this.$helper.error
            );
        },
        update_hfb: function(hfb) {
            this.hfb = hfb;
            this.triple.render = false;
            this.load_market_data();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.countries': function(newCountries, oldCountries) {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.triple.render = false;
            this.load_market_data();
        }
    },
    computed: {
        hfbs: function() {
            return this.$store.getters.hfbs;
        }
    }
}
</script>

<style>

</style>