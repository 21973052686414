<template>
    <div class="dropdown" :class="{ 'is-active': isActive }">
        <div class="dropdown-trigger">
            <button
                class="button"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
                @mouseenter="openDropdown"
                @click="toggleDropdown"
                @mouseleave="leaving_dropdown"
            >
                <span class="icon">
                    <i class="fas fa-question-circle" aria-hidden="true"></i>
                </span>
            </button>
        </div>
        <div
            class="dropdown-menu"
            id="dropdown-menu"
            role="menu"
            @mouseenter="openDropdown"
            @mouseleave="leaving_dropdown"
        >
            <div class="dropdown-content">
                <a
                    v-for="(link, i) in help_links"
                    :key="i"
                    class="dropdown-item"
                    :href="link.link"
                    :target="link.link.includes(url_origin) ? '_self' : '_blank'"
                    @click="closeDropdown"
                >
                    {{ link.name }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            help_links: [],
            url_origin: window.location.origin,
            isActive: false,
            dropdown: 'help',
            timeoutId: null
        };
    },
    mounted: function () {
        this.$http.get('/help-links/get').then(
            succ => {
                if (succ.data.length > 0) {
                    this.help_links = succ.data;
                }
            },
            error => {
                console.log(error);
            }
        );
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy: function () {
        document.removeEventListener('click', this.handleClickOutside);
    },
    watch: {
        '$store.getters.dropdown_open': function (newValue) {
            if (this.isActive && newValue !== this.dropdown) {
                this.closeDropdown();
            }
        }
    },
    methods: {
        toggleDropdown() {
            this.isActive = !this.isActive;
            this.$store.commit('set_dropdown_open', this.isActive ? this.dropdown : '');
            if (this.isActive) {
                clearTimeout(this.timeoutId);
            }
        },
        openDropdown() {
            this.isActive = true;
            this.$store.commit('set_dropdown_open', this.dropdown);
            clearTimeout(this.timeoutId);
        },
        leaving_dropdown: function () {
            this.timeoutId = setTimeout(() => {
                this.closeDropdown();
            }, 800);
        },
        closeDropdown() {
            this.isActive = false;
        },
        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.closeDropdown();
            }
        }
    }
};
</script>

<style scoped>
.nested.dropdown .dropdown-trigger button::after {
    content: '';
}
.dropdown-menu {
    right: auto; /* Prevent it from extending beyond the right edge */
    transform: translateX(-80%); /* Center the dropdown */
}

.navbar-dropdown {
    overflow-y: scroll;
    overflow-x: hidden;
}
.navbar-item {
    padding: 0.5rem 0.5rem !important;
    user-select: none;
}
.navbar-item:hover {
    background-color: rgba(0, 174, 255, 0.192);
}
.first {
    padding-top: 0.1em;
}
.fa-question {
    font-size: 1.2em;
    float: right;
    padding-right: 0.65em;
    padding-top: 0.2em;
}
@media only screen and (max-width: 1024px) {
    .dropdown-content {
        box-shadow: 0 0 0 !important;
    }
    a.dropdown-item {
        padding-left: -1rem !important;
        text-align: center !important;
        width: 100vw !important;
    }
    .dropdown-trigger {
        visibility: hidden;
        width: 0;
        height: 0;
    }
}
button.button {
    width: auto;
    height: auto;
    border-radius: 0;
    border: 0;
    font: inherit;
}
button.button:hover {
    background-color: #fafafa;
}
.dropdown-menu {
    padding: 0;
}
.dropdown-content {
    border-radius: 0;
    padding: 1rem 0;
    text-align: left;
    width: auto;
}
.dropdown-item {
    height: 2.5rem;
    font: inherit;
}
a.dropdown-item {
    text-align: center !important;
    padding: 0.5rem 0.5rem 0rem 0.5rem !important;
}
a.dropdown-item:hover {
    background-color: #d3d3d31f !important;
    color: #3273dc !important;
}
.dropdown-item:hover {
    background-color: rgba(181, 229, 245, 0.788);
}
.fa-question-circle {
    color: #0051ba;
    font-size: 2em;
}
</style>
