<template>
<div>
    <div class="button is-success mt button-left" @click="toggle_new_modal()">Add new insights title</div>
    <Modal
        v-if="show_title_modals.new"
        :closeable="true"
        title="Add new title for insights"
        
        :class="{'is-active': show_title_modals.new}"
        @close="toggle_new_modal()"
    >
        <template v-slot:content>
            <div>Add new Title</div>
            <AdminUniversal
                component="Textinput"
                :props="input_props1"
            />
            <AdminUniversal
                component="dropdown"
                :props="dropdown_props1"
            />
            <AdminUniversal
                component="dropdown"
                :props="dropdown_props2"
            />
            <div class="text-left -mt"><em>1 means the comment with this title will appear at the top and vice versa</em></div>
        </template>
        <template v-slot:buttons>
            <a class="buttons">
                <a class="button is-success" @click="add_new_title()">Add</a>
                <a class="button is-danger" @click="toggle_new_modal()">Cancel</a>
            </a>
        </template>
    </Modal>
    <AdminUniversal
        component="dropdown"
        :props="dropdown_props3"
    />
    <table>
        <tr>
            <th v-for="(header, i) in titles_table.headers" :key="i" class="capitalize">
                {{header}}
            </th>
        </tr>
        <tr v-for="(row, i) in titles_table.rows" :key="i">
            <td v-for="(header, j) in titles_table.headers" :key="j">
                {{row[header]}}
            </td>
            <td>
                <div class="button is-info" @click="toggle_edit_modal(row)">Edit</div>
            </td>
            <td>
                <div class="button is-danger" @click="toggle_remove_modal(row.id)">Delete</div>
            </td>
        </tr>
    </table>
    <Modal
        v-if="show_title_modals.edit"
        :closeable="true"
        title="Edit selected title"
        
        :class="{'is-active': show_title_modals.edit}"
        @close="toggle_edit_modal('close')"
    >
        <template v-slot:content>
            <div>Add new Title</div>
            <AdminUniversal
                component="Textinput"
                :props="input_props2"
            />
            <AdminUniversal
                component="dropdown"
                :props="dropdown_props4"
            />
            <AdminUniversal
                component="dropdown"
                :props="dropdown_props5"
            />
            <div class="text-left -mt">
                <em>1 means the comment with this title will appear at the top and vice versa</em>
            </div>
        </template>
        <template v-slot:buttons>
            <a class="buttons">
                <a class="button is-success" @click="commit_edit_title()">Save Changes</a>
                <a class="button is-danger" @click="toggle_edit_modal('close')">Cancel</a>
            </a>
        </template>
    </Modal>
    <Modal
        v-if="show_title_modals.remove"
        :closeable="true"
        title="Delete selected title"
        
        :class="{'is-active': show_title_modals.remove}"
        @close="toggle_remove_modal()"
    >
        <template v-slot:content>
            <div>Are you sure you want to delete the selected title?</div>
            <div>The connections between this title and any responsibles will also be deleted</div>
        </template>
        <template v-slot:buttons>
            <a class="buttons">
                <a class="button is-success" @click="remove_title()">Confirm Delete</a>
                <a class="button is-danger" @click="toggle_remove_modal()">Cancel</a>
            </a>
        </template>
    </Modal>
</div>
</template>

<script>
import AdminUniversal from '@/components/admin/index.vue';
import Modal from '@/components/ui/Modal.vue';

export default {
    components: {
        AdminUniversal,
        Modal
    },
    data: function() {
        return {
            new_title: {
                title: '',
                area: 'Introduction',
                ordering: 1
            },
            show_title_modals: {
                new: false,
                edit: false,
                remove: false
            },
            input_props1: {
                input: '',
                label: 'Title',
                placeholder: 'Enter a title for new comment',
                callback: this.update_input_name1
            },
            input_props2: {
                input: '',
                label: 'Title',
                placeholder: 'Enter a title for a comment',
                callback: this.update_input_name2
            },
            dropdown_props1: {
                selection: 'Introduction',
                options: ['Introduction', 'Better Homes', 'Better Company', 'Better Lives', 'Better Planet'],
                label: 'Area',
                callback: this.update_dropdown_selection1
            },
            dropdown_props2: {
                selection: '1',
                options: ['1', '2', '3', '4'],
                label: 'Ordering',
                callback: this.update_dropdown_selection2
            },
            dropdown_props3: {
                selection: 'All',
                options: ['All', 'Introduction', 'Better Homes', 'Better Company', 'Better Lives', 'Better Planet'],
                label: 'Filter By Area',
                callback: this.update_dropdown_selection3
            },
            dropdown_props4: {
                selection: 'Introduction',
                options: ['Introduction', 'Better Homes', 'Better Company', 'Better Lives', 'Better Planet'],
                label: 'Filter By Area',
                callback: this.update_dropdown_selection4
            },
            dropdown_props5: {
                selection: '1',
                options: Array.from({length: 20}, (_, i) => (i + 1).toString()),
                label: 'Ordering',
                callback: this.update_dropdown_selection5
            },
            titles_table: {
                headers: ['title', 'area', 'ordering'],
                rows: [],
            },
            edit_title: {
                id: null,
                title: null,
                area: null,
                ordering: null
            },
            title_id_to_be_removed: null
        }
    },
    mounted: function() {
        this.search_and_get_titles();
    },
    methods: {
        toggle_new_modal: function() {
            this.show_title_modals.new = !this.show_title_modals.new;
        },
        toggle_edit_modal: function(title) {
            if (title === 'close') {
                this.show_title_modals.edit = false;
                return;
            }
            this.edit_title = {...title};
            this.input_props2.input = this.edit_title.title;
            this.dropdown_props4.selection = this.edit_title.area;
            this.dropdown_props5.selection = this.edit_title.ordering.toString();
            this.show_title_modals.edit = true;
        },
        toggle_remove_modal: function(id) {
            if (id) this.title_id_to_be_removed = id;
            this.show_title_modals.remove = !this.show_title_modals.remove;
        },
        add_new_title: function() {
            if (!this.new_title.title || !this.new_title.area || !this.new_title.ordering) {
                alert(`Missing: 
                    ${!this.new_title.title ? 'Title' : ''}
                    ${!this.new_title.area ? 'Area' : ''}
                    ${!this.new_title.ordering ? 'Ordering' : ''}
                `);
                return;
            } 
            this.$http.post('/admin/insights-templates/add', this.new_title).then(
                succ => {
                    this.toggle_new_modal();
                    this.search_and_get_titles();
                },
                this.$helper.error
            );
        },
        commit_edit_title: function() {
            this.$http.post('/admin/insights-templates/edit', this.edit_title).then(
                succ => {
                    this.toggle_edit_modal('close');
                    this.search_and_get_titles();
                },
                this.$helper.error
            )
        },
        search_and_get_titles: function() {
            this.$http.post('force', '/admin/insights-templates/search', {
                area: this.dropdown_props3.selection
            }).then(
                succ => {
                    let d = succ.data;
                    if (!succ || !d.length) {
                        this.titles_table.rows = [];
                        return;
                    }
                    this.titles_table.rows = d;
                },
                this.$helper.error
            );
        },
        remove_title:function() {
            this.$http.post('/admin/insights-templates/remove', {
                id: this.title_id_to_be_removed
            }).then(
                succ => {
                    this.toggle_remove_modal();
                    this.search_and_get_titles();
                },
                this.$helper.error
            );
        },
        update_input_name1: function(input) {
            this.new_title.title = input;
        },
        update_input_name2: function(input) {
            this.edit_title.title = input;
        },
        update_dropdown_selection1: function(selection) {
            this.new_title.area = selection;
        },
        update_dropdown_selection2: function(selection) {
            this.new_title.ordering = String(selection);
        },
        update_dropdown_selection3: function(selection) {
            this.dropdown_props3.selection = selection;
            this.search_and_get_titles();
        },
        update_dropdown_selection4: function(selection) {
            this.edit_title.area = selection;
        },
        update_dropdown_selection5: function(selection) {
            this.edit_title.ordering = String(selection);
        }
    }
}
</script>

<style scoped>
.button
.button-left {
    text-align: left;
    display: block;
    width: fit-content;
}

th {
    padding-right: 1rem;
}

td {
    padding: 0.5rem 0.75rem;
}

tr {
    height: 3rem;
}

tr:nth-child(even) {
    background-color: #f5f5f5;
}
</style>