var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bubble-options" }, [
    _c("div", { staticClass: "field has-addons text-large text-bold title" }, [
      _c("p", { staticClass: "control three-quarters" }, [
        _c("a", { staticClass: "button full-width" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ]),
      ]),
      _c("p", { staticClass: "control one-quarter" }, [
        _c(
          "a",
          {
            staticClass: "button ikea-blue text-white full-width",
            on: {
              click: function ($event) {
                return _vm.$emit("reset")
              },
            },
          },
          [_vm._v(" Reset ")]
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "options" },
      _vm._l(_vm.options, function (option, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "button",
            class: { active: _vm.selected_options.includes(option) },
            on: {
              click: function ($event) {
                return _vm.select(option)
              },
            },
          },
          [_vm._v(" " + _vm._s(option) + " ")]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }