var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "executive-summary" } },
    [
      _c(
        "div",
        {
          staticClass:
            "columns is-gapless buttons has-addons edit-buttons inline-block ui-rounded no-left-margin is-mobile overflow-visible",
        },
        [
          _c("div", { staticClass: "column" }, [
            _c(
              "button",
              { staticClass: "button retail-manager is-pulled-left ml" },
              [
                _vm.display_name
                  ? _c("div", [
                      _vm._v("Retail Business Navigation Team:"),
                      _c("br"),
                      _vm._v(_vm._s(_vm.display_name)),
                    ])
                  : _c("div", [_vm._v("No author set.")]),
              ]
            ),
          ]),
          _vm._m(0),
          _c("div", { staticClass: "column is-narrow is-offset" }, [
            _vm.$store.getters.check_access(["executive-summary", "*", "r"])
              ? _c(
                  "button",
                  {
                    staticClass: "button",
                    class: { "is-active": _vm.view === "current" },
                    on: {
                      click: function ($event) {
                        return _vm.update_view("current")
                      },
                    },
                  },
                  [
                    _vm._v(" Current "),
                    _c("i", {
                      staticClass: "far fa-comments hidden-on-mobile ml",
                    }),
                  ]
                )
              : _vm._e(),
            _vm.$store.getters.check_access(["executive-summary", "*", "w"]) &&
            _vm.edit_mode
              ? _c(
                  "button",
                  {
                    staticClass: "button",
                    class: { "is-active": _vm.view === "new" },
                    on: {
                      click: function ($event) {
                        return _vm.update_view("new")
                      },
                    },
                  },
                  [_vm._v(" New ")]
                )
              : _vm._e(),
            _vm.$store.getters.check_access(["executive-summary", "*", "w"]) &&
            _vm.edit_mode
              ? _c(
                  "button",
                  {
                    staticClass: "button",
                    class: { "is-active": _vm.view === "draft" },
                    on: {
                      click: function ($event) {
                        return _vm.update_view("draft")
                      },
                    },
                  },
                  [_vm._v(" Draft ")]
                )
              : _vm._e(),
            _vm.$store.getters.check_access(["executive-summary", "*", "w"]) &&
            _vm.edit_mode
              ? _c(
                  "button",
                  {
                    staticClass: "button",
                    class: { "is-active": _vm.view === "scheduled" },
                    on: {
                      click: function ($event) {
                        return _vm.update_view("scheduled")
                      },
                    },
                  },
                  [_vm._v(" Scheduled ")]
                )
              : _vm._e(),
            _vm.$store.getters.check_access(["executive-summary", "*", "r"])
              ? _c(
                  "button",
                  {
                    staticClass: "button",
                    class: { "is-active": _vm.view === "archived" },
                    on: {
                      click: function ($event) {
                        return _vm.update_view("archived")
                      },
                    },
                  },
                  [
                    _vm._v(" Archived"),
                    _c("i", {
                      staticClass: "fas fa-archive hidden-on-mobile ml",
                    }),
                  ]
                )
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "button presentation-mode ft-tooltip-bottom mr",
                class: { active: _vm.$store.state.global.show_edit },
                on: { click: _vm.toggle_edit_mode },
              },
              [
                _c("i", {
                  staticClass: "fas fa-edit",
                  attrs: {
                    tooltiptext: _vm.$store.state.global.show_edit
                      ? "Finish Editing"
                      : "Edit",
                  },
                }),
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.edit_mode,
                      expression: "edit_mode",
                    },
                  ],
                  staticClass: "fas fa-slash absolute",
                }),
              ]
            ),
          ]),
        ]
      ),
      _c("BrowserNotice"),
      _vm.view === "new"
        ? _c(
            "div",
            { staticClass: "ms" },
            [
              _c("New", {
                on: {
                  "toggle-edit-mode": function ($event) {
                    _vm.view = "current"
                  },
                  updated: _vm.edit_updated,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.view === "edit"
        ? _c(
            "div",
            { staticClass: "ms" },
            [
              _c("Edit", {
                attrs: {
                  "comment-to-edit": _vm.selected_comment,
                  status: _vm.view,
                },
                on: {
                  change_status: _vm.change_status,
                  "toggle-edit-mode": function ($event) {
                    _vm.view = "current"
                  },
                  updated: _vm.edit_updated,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      ["current", "draft", "archived", "scheduled"].includes(_vm.view)
        ? _c(
            "div",
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.comments,
                      expression: "!comments",
                    },
                  ],
                  staticClass: "comment-container",
                },
                [
                  _c("p", [
                    _vm._v("Sorry, there are no "),
                    _c("b", [_vm._v(_vm._s(_vm.view))]),
                    _vm._v(" summaries."),
                  ]),
                ]
              ),
              _vm._l(_vm.comments, function (comment, i) {
                return _c("div", { key: i, staticClass: "comment-container" }, [
                  _c("div", { staticClass: "comment-title" }, [
                    _c("div", { staticClass: "summary" }, [_vm._v("Summary")]),
                    _c("div", { staticClass: "comment-meta" }, [
                      _vm._v(
                        " From " +
                          _vm._s(comment.scheduled_date.split("T")[0]) +
                          ", to " +
                          _vm._s(comment.comment_lifetime.split("T")[0]) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", {
                    staticClass: "full-width ProseMirror",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.seperated_comment(comment.content)[0]
                      ),
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.has_footer(comment.content),
                          expression: "has_footer(comment.content)",
                        },
                      ],
                      staticClass: "columns",
                    },
                    [
                      _c("div", { staticClass: "column is-6" }, [
                        _c("div", { staticClass: "strengthen text-left" }, [
                          _vm._v("To Further Strengthen"),
                        ]),
                        _c("div", {
                          staticClass: "ProseMirror",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.seperated_comment(comment.content)[1]
                            ),
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "column is-6" }, [
                        _c("div", { staticClass: "action text-left" }, [
                          _vm._v("Call for action"),
                        ]),
                        _c("div", {
                          staticClass: "ProseMirror",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.seperated_comment(comment.content)[2]
                            ),
                          },
                        }),
                      ]),
                    ]
                  ),
                  _vm.$store.getters.check_access([
                    "comments",
                    "edit",
                    "*",
                    "w",
                  ]) && _vm.edit_mode
                    ? _c("div", { staticClass: "button-container" }, [
                        _c(
                          "div",
                          {
                            staticClass: "button edit-button is-half",
                            on: {
                              click: function ($event) {
                                _vm.view = "edit"
                                _vm.selected_comment = comment
                              },
                            },
                          },
                          [_vm._v(" Edit ")]
                        ),
                        _vm.selected !== "archived"
                          ? _c(
                              "div",
                              {
                                staticClass: "button edit-button is-half",
                                on: {
                                  click: function ($event) {
                                    ;(_vm.comment_to_edit = comment),
                                      _vm.change_status("ARCHIVED")
                                  },
                                },
                              },
                              [_vm._v(" Archive ")]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ])
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column is-centered text-left" }, [
      _c("div", { staticClass: "text-very-large text-black" }, [
        _vm._v("Executive Summary"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }