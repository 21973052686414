<template>
<div class="bubble-options">
    <div class="field has-addons text-large text-bold title">
        <p class="control three-quarters">
            <a class="button full-width">
                {{ title }}
            </a>
        </p>
        <p class="control one-quarter">
            <a class="button ikea-blue text-white full-width" @click="$emit('reset')">
                Reset
            </a>
        </p>
    </div>
    
    
    <div class="options">
        <div
            v-for="(option, index) in options"
            :key="index"
            @click="select(option)"

            class="button"
            :class="{'active': selected_options.includes(option) }"
        >
            {{ option }}
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        options: Array,
        title: String,
        selectedOptions: Array
    },
    data: function() {
        return {
            selected_options: []
        }
    },
    mounted: function() {
        if (this.selected_options.length) {
            this.selected_options = [...this.selectedOptions];
        } else {
            this.selected_options = [];
        }
    },
    methods: {
        select: function(name) {
            if (this.selected_options.includes(name)) {
                this.selected_options.splice(
                    this.selected_options.indexOf(name),
                    1
                );
            } else {
                this.selected_options.push(name);
            }
            this.$emit('selected', this.selected_options);
        }
    },
    watch: {
        selectedOptions: function(new_options, old_options) {
            this.selected_options = [...new_options];
        }
    }
}
</script>

<style scoped>
.bubble-options {
    background-color: #f8fbff;
}
.bubble-options .title {
    font-size: 1.35rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
}
.options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: auto;
    padding: 0.25rem;
}
.options .button {
    border: none;
    display: flex;
    height: 1.5rem;
    padding: 1rem;
    width: calc(50%);
    border-radius: 0%;
}
.options .button:hover {
    background-color: #4d96f5;
    border-color: #DDD;
    box-shadow: 0 0 3px #DDD;
    color: #FFF;
}
.options .button.active {
    background-color: #4d96f5;
    color: #FFF;
}
</style>