var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "full-width" },
    _vm._l(_vm.buttons, function (option, i) {
      return _c("div", { key: i, staticClass: "mbp5" }, [
        _c("div", { staticClass: "has-addons" }, [
          _c("div", { staticClass: "control full-width" }, [
            option.type === "post"
              ? _c(
                  "button",
                  {
                    staticClass: "button full-width",
                    on: {
                      click: function ($event) {
                        return _vm.update(option.operation)
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.calculated_status === "SCHEDULED"
                            ? "Schedule"
                            : "Post"
                        ) +
                        " " +
                        _vm._s(option.button) +
                        " "
                    ),
                  ]
                )
              : _c(
                  "button",
                  {
                    staticClass: "button full-width",
                    class: option.type === "delete" ? "is-danger" : "",
                    on: {
                      click: function ($event) {
                        option.type === "delete"
                          ? _vm.delete_button()
                          : _vm.update(option.operation)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(option.button) + " ")]
                ),
          ]),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }