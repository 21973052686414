var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show === true
    ? _c("div", { staticClass: "notice columns is-gapless" }, [
        _c("i", { staticClass: "fas fa-exclamation column is-1" }),
        _vm.name
          ? _c("div", { staticClass: "column is-10" }, [
              _vm._v(
                " This application is built for Edge, Google Chrome and Firefox. Please switch now as some features on this site may not work "
              ),
            ])
          : _c("div", { staticClass: "column is-10" }, [
              _vm._v(
                " We cannot detect the browser you are using. This application is optimised for Edge, Google Chrome and Firefox. Please switch now if possible "
              ),
            ]),
        _c("div", { staticClass: "column is-1" }, [
          _c("i", {
            staticClass: "fas fa-times-circle cursor-pointer",
            on: {
              click: function ($event) {
                return _vm.toggle_browser_notice()
              },
            },
          }),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }