<template>
    <div>
        <AdminUniversal component="title" :props="title_props" />
        <div class="options-bar text-left">
            <label class="label">Search KPI</label>
            <DropDown
                v-if="search_kpi_areas"
                :key="search_kpi_areas[0]"
                :options="search_kpi_areas"
                :values="search_kpi_areas"
                :default="search_kpi_areas[0]"
                @updateDropDown="updated_search_areas_dropdown"
            />
        </div>
        <AdminUniversal v-if="table_props.render" component="table" :props="table_props" />
        <div v-else>There are no owners for this KPI yet</div>

        <hr />

        <AdminUniversal component="title" :props="title_props2" />
        <div class="options-bar">
            <KPIChooser @chosen="kpi_chosen" @reset="back" class="kpi-chooser" />
        </div>
        <div v-if="Object.keys(location).length" class="options-bar text-left mt">
            <label class="label">Search User</label>
            <UserSearch @SelectedUser="user_selected" class="user-search" />
            <div class="options-bar">
                <button class="button is-success float-left" @click="find_location_id()">Update</button>
            </div>
        </div>
    </div>
</template>

<script>
import AdminUniversal from '@/components/admin/index.vue';
import DropDown from '@/components/ui/DropDown.vue';
import KPIChooser from '@/components/admin/KPIChooser.vue';
import MenuOptions from '@/assets/scripts/kpi-data-mapping.js';
import UserSearch from '@/components/admin/UserSearch.vue';

export default {
    components: {
        AdminUniversal,
        DropDown,
        KPIChooser,
        UserSearch
    },
    data: function () {
        return {
            title_props: {
                title: 'KPI Accountables'
            },
            table_props: {
                headers: ['KPI', 'KPI Area', 'Market Area', 'First Name', 'Last Name'],
                data: null,
                render: false
            },
            title_props2: {
                title: 'Add or Edit KPI Accountables'
            },

            selected: 'Exisiting',
            options: MenuOptions,
            search_kpi_areas: null,
            search_kpi: 'ALL',
            force_update: false,
            kpi_areas: [],
            kpi_lvl1: [],
            kpi_lvl2: [],
            selected_kpi_area: null,
            selected_kpi_lvl1: null,
            selected_kpi_lvl2: null,
            full_location: null,
            found_location: null,
            found: null,
            table: {
                render: false,
                data: [],
                headers: [],
                rows: []
            },
            location: {}
        };
    },
    mounted: function () {
        this.search_kpi_areas = [];
        this.search_kpi_areas.push('ALL');

        this.options.map(kpi_area => {
            this.kpi_areas.push(kpi_area.name);
            this.search_kpi_areas.push(kpi_area.name);
        });

        this.selected_kpi_area = this.kpi_areas[0];

        this.options[0].areas.map(kpi_lvl1 => {
            this.kpi_lvl1.push(kpi_lvl1.name);
        });
        this.selected_kpi_lvl1 = this.kpi_lvl1[0];
        this.load_table_data();
    },
    methods: {
        load_table_data: function () {
            this.table.data = [];
            this.table.headers = [];
            let search_variable = this.search_kpi.replace(' ', '-').replace('3', 'three');

            if (this.force_update) {
                this.$http.get('force', `/admin/kpi-owners/load/${search_variable}`).then(succ => {
                    let d = succ.data;
                    this.table_props.data = d;
                    this.table.headers = Object.keys(d[0]);
                    this.table_props.render = true;
                }, this.$helper.error);
            } else {
                this.$http.get(`/admin/kpi-owners/load/${search_variable}`).then(succ => {
                    let d = succ.data;
                    this.table_props.data = d;
                    this.table.headers = Object.keys(d[0]);
                    this.table_props.render = true;
                }, this.$helper.error);
            }
        },
        kpi_chosen: function (kpi) {
            this.location = this.$helper.seperate_link_into_areas(kpi.path);
        },
        back: function () {
            this.location = {};
        },
        updated_search_areas_dropdown: function (search_kpi) {
            this.search_kpi = search_kpi;
        },
        find_location_id: function () {
            this.found_location = null;
            this.$http
                .post('force','/admin/kpi-location/add', {
                    kpi: this.location['kpi'],
                    kpi_area: this.location['kpi_area'],
                    market_area: this.location['market_area'],
                    user: this.selected_user
                })
                .then(() => {
                    this.force_update = true;
                    this.load_table_data();
                }, this.$helper.error);
        },
        user_selected: function (user) {
            this.selected_user = user;
        }
    },
    watch: {
        search_kpi: function () {
            this.table.render = false;
            this.load_table_data();
        }
    }
};
</script>

<style scoped>
.kpio-form {
    margin-top: 1em;
    text-align: left;
}
table {
    width: 100%;
}
.user-search {
    margin-left: -16px;
    max-width: 500px;
}
</style>
