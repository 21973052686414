<template>
    <div :id="title" style="height: 100%; width: 100%;"></div>
</template>
<script>
/**
 * This graph component displays a top 10 graph that should look identical to Top8Bar.vue
 * however it's above/below trend is precalculated.
 */
import * as echarts from 'echarts/dist/echarts.min.js';
export default {
    data: function() {
        return {}
    },
    props: {
        title: String, //title of the graph
        showTitle: Boolean,

        sub_title: String, // subtitle of the graph
        series: Array, //actual data array to fill the graph
        x_categories: Array, //data that will be displayed on the x axis
        y_axis_label: String, //name of the data on the y axis e.g 'After sales delivery share' or 'services sale by month'
        y_metric: String, //'%', '£', 'kg' etc.
        
        y_axis_min: Number, //set the minimum value of the the y axis
        y_axis_max: Number, //set the maximum value of the the y axis
        y_start_percent: Number,
        y_end_percent: Number,

        y2_axis_label: String, //name of the data on the y axis e.g 'After sales delivery share' or 'services sale by month'
        y2_metric: String, //'%', '£', 'kg' etc.

        y2_axis_min: Number, //set the minimum value of the the y axis
        y2_axis_max: Number, //set the maximum value of the the y axis
        
        names: Array, //name of the data such as IKEA
        stack: Array,
        colors: Array, //color of the bar lie or scatter for the paticular data set
        disabled_labels: Array,
        yAxisIndexes: Array,
        toolbox: Boolean,
        year_goal_index: Number, //the index of the value which is the year goal, this needs special styling

        y_zoom: Boolean,
        x_zoom: Boolean,

        show: Boolean, // true to show the exta features such as 'save graph as image', 'refresh', 'change to bar graph', etc
    },
    mounted: function() {
        let myChart = echarts.init(document.getElementById(this.title));
        let div_width = document.getElementById(this.title).clientWidth;
        let width_factor = 1.3;

        // Preset generic settings
        let option = {
            title: {
                text: this.title,
                subtext: this.sub_title,
                show: this.showtitle,
                subtextStyle: {
                    color: 'black',
                },
                top: '-2em',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            toolbox: {
                top: '85em',
                left: '0em',
                show: this.show,
                feature: {
                    restore: {},
                    saveAsImage: {}
                }
            },
            grid: {
               top: '100em',
               left: '75em',
               width: 'auto',
               bottom: '30em'
            },
            legend: {
                top: '25em',
                left: '5em',
                itemGap: 30,
                data:[]
            },
            textStyle: {
                fontFamily: 'NotoIKEALatin-Regular',
                fontSize: 14,
            },
            xAxis: [{
                type: 'category',
                data: this.formatted_x_categories,
                axisLabel: {
                    interval: '0',
                },
                axisPointer: {
                    type: 'shadow'
                },
            }],
            yAxis: [
                {
                    type: 'value',
                    nameGap: 60,
                    name: this.y_axis_label,
                    min: this.y_axis_min,
                    max: this.y_axis_max,
                    nameLocation: 'middle',
                    axisLabel: {
                        formatter: `{value} ${this.y_metric}`
                    }
                }
            ],
            dataZoom: [
                {
                    show: this.y_zoom,
                    type: 'slider',
                    start: this.y_start_percent,
                    end: this.y_end_percent,
                    yAxisIndex: 0,
                    filterMode: 'empty',
                    left: '0rem',
                    bottom: this.x_zoom ? '60rem' : '30rem',
                    handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                    handleSize: '80%',
                    handleStyle: {
                        color: '#fff',
                        shadowBlur: 3,
                        shadowColor: 'rgba(0, 0, 0, 0.6)',
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    }
                }
            ],
            series: [],
        };

        // Populate preset options with data
        option.legend.data = this.series.map(series => series.name);

        this.series.forEach(series => {
            let series_template = {};
            if (series.key.toLowerCase().includes('trend_')) {
                series_template = {
                    ...series,
                    type: 'scatter',
                    symbol: 'rect',
                    symbolSize: [(div_width / (this.x_categories.length * width_factor * 3)), 6],
                    barGap: '0',
                    label: {
                        show: false,
                        align: 'center',
                        verticalAlign: 'middle',
                        position: 'insideTop',
                        distance: 20,
                        color: 'black',
                    }
                }
            } else if (series.name.toLowerCase().includes('goal')) {
                series_template = {
                    ...series,
                    // ** The following 4 props can be commented out to return goal series to a line **
                    type: 'scatter',
                    symbol: 'rect',
                    symbolSize: [(div_width / (this.x_categories.length * width_factor)), 2],
                    hoverAnimation: false,
                    label: {
                        show: false,
                        align: 'center',
                        verticalAlign: 'middle',
                        position: 'insideTop',
                        distance: 20,
                        color: 'black',
                    },
                };
            } else {
                series_template = {
                    ...series,
                    barGap: '0',
                    label: {
                        show: true,
                        align: 'center',
                        verticalAlign: 'middle',
                        z: 2,
                        position: 'insideTop',
                        distance: 20,
                        color: 'black',
                    },
                };
            }
            option.series.push(series_template);            
        });
        myChart.setOption(option);
    },
    computed: {
        // New line on last occurence of space (only last occurence so not more than 2 lines + total retail splits longest word separately)
        formatted_x_categories: function () {
            return this.x_categories.map(country => {
                const last_space_index = country.lastIndexOf(' ');
                return last_space_index < 0
                    ? country
                    : country.substring(0, last_space_index) + '\n' + country.substring(last_space_index + 1);
            });
        }
    }
}
</script>
<style scoped>
.overall-container {
    position: relative;
}
.ranking-container {
    height: 2.5vw;
    margin-right: 10%;
    position: absolute;
    top: 12vh;
    width: 100%;
    margin-left: 0;
    font-size: 0.8vw;
}
.ranking-label {
    width: 3em;
    height: 3em;
    padding-top: 0.75em;
    margin-left: 0;
    border-radius: 100%;
    float: left;
    font-weight: bold;
}
.ranking-label:first-child{
    margin-left: 76%;
}
.ranking-box {
    width: calc((90% - 3.5vw)/9);
    height: 2.5vw;
    float: left;
    position: relative;
}
.name-section {
    margin-left: 3.5vw;
    height: 4em;
    margin-right: 10%
}
.name-container {
    width: calc((100%)/9);
    float: left;
    position: relative;
    top: 1vh;
}
.competitor-1 {
    color: lightgreen;
}
.competitor-2 {
    color: grey
}
</style>
