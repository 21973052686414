<template>
    <div :id="title" style="width: 100%; height:100%;"></div>
</template>

<script>
import * as echarts from 'echarts/dist/echarts.min.js';
export default {
    props: {
        title: String, //title of the graph
        showtitle: Boolean,
        subtitle: String, //subtitle of the graph
        xcategories: Array, //data that will be displayed on the x axis
        yaxislabel: String, //name of the data on the y axis e.g 'After sales delivery share' or 'services sale by month'
        ymetric: String, //'%', '£', 'kg' etc.
        type: String, //bar
        xvalue: Number,

        color1: String, //color of the bar lie or scatter for the paticular data set
        color2: String,
        color3: String,
        color4: String,

        seriesname1: String, //name of the data such as IKEA 
        seriesset1: Object,
        
        seriesname2: String,
        seriesset2: Object,
        
        seriesname3: String,
        seriesset3: Object,
    
        seriesname4: String,
        seriesset4: Object,

        seriesname5: String,
        seriesset5: Object,

        seriesname6: String,
        seriesset6: Object,

        seriesname7: String,
        seriesset7: Object,

        seriesname8: String,
        seriesset8: Object,

        show: Boolean, // true to show the exta features such as 'save graph as image', 'refresh', 'change to bar graph', etc
    },
    mounted: function() {
		let myChart = echarts.init(document.getElementById(this.title));

        let empty_data = { 
                Online: {
                    value: null,
                    label: null
                },
                Remote: {
                    value: null,
                    label: null
                },
                'Store non-comp': {
                    value: null,
                    label: null
                },
                'Store comp': {
                    value: null,
                    label: null
                }
            }
        
        let NumberSet = [this.seriesset1, this.seriesset2, empty_data, this.seriesset3, this.seriesset4, this.seriesset5, this.seriesset6, empty_data, this.seriesset7, this.seriesset8]
        let option = {
           title:{
                text: this.title,
                subtext: this.subtitle,
                show: this.showtitle,
                subtextStyle: {
                    color: 'black',
                },
                top: '-5em',
            },
            tooltip: {
                show: true,
                trigger: 'axis',
                
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            toolbox: {
                top: '85em',
                right: '30em',
                show: this.show,
                feature: {
                    restore: {},
                    saveAsImage: {}
                }
            },
            grid: {
               top: '65em',
               left: '70vh',
               bottom: '7em',
               containLabel: true
			},
			legend: {
                top: '25em',
                left: '0em',
                itemGap: 15,
                data:['Comp. Cash & Carry', 'Non-Comp. Cash & Carry', 'Online', 'Remote']
			},
			textStyle: {
                fontFamily: 'NotoIKEALatin-Regular',
            },
            xAxis: [
                {
                    type: 'category',
                    data: ['Year Goal', 'Forecast', '', 'YTD Index', '13 Weeks', '8 Weeks', '4 Weeks', '1 Weeks'],
                    axisPointer: {
                        type: 'shadow'
                    },
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: this.yaxislabel,
                    nameLocation: 'middle',
                    nameGap: 50,
                    axisLabel: {
                        formatter: `{value} ${this.ymetric}`
                    }
                }
            ],
            series: this.call_data(NumberSet)
        };
        myChart.setOption(option);
    },
    methods: {
        call_data: function(NumberSet) {
            let data_values = { 
                Online: {
                    values: [],
                    labels:[]
                },
                Remote: {
                    values: [],
                    labels:[]
                },
                'Store non-comp': {
                    values: [],
                    labels:[]
                },
                'Store comp': {
                    values: [],
                    labels:[]
                }
            }
            NumberSet.forEach(set => {
                ['Online', 'Remote', 'Store non-comp', 'Store comp'].forEach(item => {
                    data_values[item].values.push(set[item].value);
                    data_values[item].labels.push(set[item].label);
                });
            });
            return [
                {
                    name: 'Comp. Cash & Carry',
                    type: this.type,
                    data: data_values['Store comp'].values,
                    color: this.color1,
                    stack: 'true',
                    label: {
                        show: true,
                        formatter: param => {
                            return data_values['Store comp'].labels[param.dataIndex];
                        },
                        color: 'black'
                    }
                },
                {
                    name: 'Non-Comp. Cash & Carry',
                    type: this.type,
                    data: data_values['Store non-comp'].values,
                    color: this.color2,
                    stack: 'true',
                },
                {
                    name: 'Online',
                    type: this.type,
                    data: data_values['Online'].values,
                    color: this.color3,
                    stack: 'true',
                    label: {
                        show: true,
                        formatter: param => {
                            return data_values['Online'].labels[param.dataIndex];
                        },
                        color: 'black'
                    }
                },
                {
                    name: 'Remote',
                    type: this.type,
                    data: data_values['Remote'].values,
                    color: this.color4,
                    stack: 'true',
                    label: {
                        show: true,
                        formatter: param => {
                            return data_values['Remote'].labels[param.dataIndex];
                        },
                        color: 'black'
                    }
                }
            ]
        }
    }
}
</script>
