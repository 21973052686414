var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "button-container" },
    [
      _c(
        "Button",
        {
          class: [
            "total-ingka-button ikea-yellow",
            { disabled: !_vm.isButtonEnabled("Retail Flight Tower") },
          ],
          attrs: { disabled: !_vm.isButtonEnabled("Retail Flight Tower") },
          on: {
            click: function ($event) {
              return _vm.route_to(`/country-overview`)
            },
          },
        },
        [_vm._v(" Retail Flight Tower ")]
      ),
      _c(
        "Button",
        {
          class: [
            "total-ingka-button ikea-yellow",
            { disabled: !_vm.isButtonEnabled("Centres Flight Tower") },
          ],
          attrs: { disabled: !_vm.isButtonEnabled("Centres Flight Tower") },
          on: {
            click: function ($event) {
              return _vm.handleClick(
                "https://app.powerbi.com/Redirect?action=OpenReport&appId=f2efbd11-fac2-4eea-b6a7-faf691aa7b1a&reportObjectId=d22f797c-4d65-4d80-a7a4-3ca2352204d6&ctid=720b637a-655a-40cf-816a-f22f40755c2c&reportPage=ReportSectiondd96fe2368bc690a66a1&pbi_source=appShareLink&portalSessionId=8e1ff0ca-3ec5-4ee9-b610-33079cfc2da1"
              )
            },
          },
        },
        [_vm._v(" Centres Flight Tower ")]
      ),
      _c(
        "Button",
        {
          class: [
            "total-ingka-button ikea-yellow",
            { disabled: !_vm.isButtonEnabled("Investments Flight Tower") },
          ],
          attrs: { disabled: !_vm.isButtonEnabled("Investments Flight Tower") },
        },
        [_vm._v(" Investments Flight Tower ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }