<template>
<div>
    <Title title="Profit & Loss" :location="location" />
    <div class="options-bar">
        <Buttons
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />
        <!-- Add Market Area dropdown if country is selected, use same query as in CPDP -->
        <div class="label-container">
            <p>Profit and Loss View</p>
            <DropDown
                :default="filters.business_area.values[0]"
                :key="filters.business_area.values[0]"
                :options="filters.business_area.options"
                :values="filters.business_area.values"
                @updateDropDown="update_business_area_filter"
            />
        </div>

        <div class="label-container">
            <p>Comparable</p>
            <DropDown
                :default="filters.is_comp.values[0]"
                :key="filters.is_comp.values[0]"
                :options="filters.is_comp.options"
                :values="filters.is_comp.values"
                @updateDropDown="update_is_comp_filter"
            />
        </div>

        <div class="label-container">
            <p>Currency</p>
            <DropDown
                :default="filters.currency.values[0]"
                :key="filters.currency.values[0]"
                :options="filters.currency.options"
                :values="filters.currency.values"
                @updateDropDown="update_currency"
            />
        </div>

        <div class="label-container" 
            :class="!$store.getters.market ? 'ft-tooltip': null" 
            :tooltiptext="!$store.getters.market ? 'Select a country from top filter to see stores' : null"
            v-show="filters.stores.enabled"
        >
            <p>Market Area</p>
            <DropDown
                :key="stores.values.length"
                :options="stores.options"
                :values="stores.values"
                :default="stores.values[0]"
                @updateDropDown="update_store_filter"
            />
        </div>
    </div>

    <Table
        v-if="table.render"
        id="profit-and-loss"

        :headers="table.headers"
        :rows="table.rows"
        
        :bridge-settings="table.bridge_settings"
        :meta-settings="{ is_selectable: false , isSubtotalHighlight: true }"
        :sort-settings="{ enabled: false }"
        :decimal-settings="table.decimal_settings"

        class="ms1p5 no-all ikea-yellow"
    />
    <div v-else class="box ms">
        <p>No data could be found.</p>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: [],
                render: false,
                currency_label: 'MEUR',
                bridge_settings: {	
                    rows: [	
                        {
                            'Total Revenue': {	
                                hide: [	
                                    'Sales of Goods',	
                                    'Sales of Product Related Services',
                                    'Other Service Income Intercompany',
                                    'Other Service Income External'
                                ],
                                bgcolor: true	
                            }	
                        },
                        {
                            'Total Gross Profit': {	
                                hide: [
                                    'Gross Profit of Goods',	
                                    'Gross Profit of Services',
                                    'Customer Distribution Cost'	
                                ],
                                bgcolor: true
                            }	
                        },
                        { 
                            'Operational Cost before global allocations': {	
                                hide: [	'Travelling', 'Staff', 'Operation', 'Depreciation on equipment', 'Rental premises', 'Other income', 'Marketing', 'Other operational costs' ],
                                bgcolor: true
                            }	
                        },
                        {
                            'Operational result before global allocations': { 
                                bgcolor: true	
                            }
                        },
                        { 'Operational Result before global allocations': {} },	
                        { 'Total Global Allocations': {} },	
                        { 'Total Bonus & Tack': {} },	
                        { 'Grand total costs': {} },	
                        { 'Operational Result including Bonus & Tack': {} }	
                    ],
                    columns: {
                        'P&L': { hide: true },
                        'FC': { hide: true },
                        'YTD': { hide: true },
                        'Goal': { hide: true },
                        'Month': { hide: true },
                        'Remaining': { hide: true },
                        /* -- Wasting too much time on this, commenting it out for now - 09/02/21 MM
                        'Month - Idx vs LY': { hide: ['Month - Diff vs Goal', 'Month - Idx vs Goal'], parent_span: 3, parent: 'Month' },
                        'YTD - Idx vs LY': { hide: ['YTD - Diff vs Goal', 'YTD - Idx vs Goal'], parent_span: 3, parent: 'YTD' },
                        'Forecast - Idx vs LY': { hide: ['Forecast - Diff vs Goal', 'Forecast - Idx vs Goal'], parent_span: 3, parent: 'Forecast' },
                        'Remaining - Idx vs LY': { hide: ['Remaining - Diff vs Goal', 'Remaining - Idx vs Goal'], parent_span: 3, parent: 'Remaining' },
                        'Goal - Idx vs LY': { hide: ['Goal - Diff vs Goal', 'Goal - Idx vs Goal'], parent_span: 2, parent: 'Goal' },
                        */
                    }
                },
                decimal_settings: {
                    'Month - Ratio (%)': { precision: 1 },
                    'Month - Idx vs LY': { precision: 1 },
                    'Month - Idx vs Goal': { precision: 1 },

                    'YTD - Ratio (%)': { precision: 1 },
                    'YTD - Idx vs LY': { precision: 1 },
                    'YTD - Idx vs Goal': { precision: 1 },

                    'Forecast - Ratio (%)': { precision: 1 },
                    'Forecast - Idx vs LY': { precision: 1 },
                    'Forecast - Idx vs Goal': { precision: 1 },

                    'Remaining - Ratio (%)': { precision: 1 },
                    'Remaining - Idx vs LY': { precision: 1 },
                    'Remaining - Idx vs Goal': { precision: 1 },

                    'Goal - Ratio (%)': { precision: 1 },
                    'Goal - Idx vs LY': { precision: 1 },
                    'Goal - Idx vs Goal': { precision: 1 },
                }
            },
            filters: {
                business_area: {
                    options: [],
                    values: [],
                    value: null
                },
                currency: {
                    options: [],
                    values: [],
                    value: null
                },
                is_comp: {
                    options: [],
                    values: [],
                    value: null
                },
                stores: {
                    enabled: false,
                    value: null
                }
            },
            location: '/finance/profit-and-loss-poc',
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.get_filters();
        if (this.$store.getters.market) {
            this.filters.stores.enabled = true;
        }
    },
    methods: {
        get_filters: function() {
            // Only access market when Retail Country is selected
            // Hide stores when Total and CFF
            // MEUR on market, but when Store != Total Country => KEUR
            const order = {
                'Total Retail Excl. PORT&AO': 1,
                'Retail Country Excl. PORT&AO': 2,
                'Central FF Excl. PORT&AO': 3,
                'Total Retail Incl. PORT&AO': 4,
                'Retail Country Incl. PORT&AO': 5,
                'Central FF Incl. PORT&AO': 6,
                'Food - Country Level': 7
            }

            const currency_order = {
                'EUR': 1,
                'LOC': 2
            }

            this.$http.get('/finance/profit-and-loss/poc/filters').then(
                ({ data: { business_area, is_comp, currency } }) => {
                    if (!business_area && !is_comp && !currency) return;
                    let temp = [];
                    business_area.forEach(item => {
                        let val = Object.values(item).pop();
                        temp.push(val);
                    });
                    temp.sort((a, b) => order[a] - order[b]);
                    this.filters.business_area.options = temp;
                    this.filters.business_area.values = temp;
                    
                    temp = [];
                    is_comp.forEach(item => {
                        let val = Object.values(item).pop();
                        temp.push(val);
                    });
                    this.filters.is_comp.options = temp;
                    this.filters.is_comp.values = temp;

                    temp = [];
                    currency.forEach(item => temp.push(Object.values(item).pop()));
                    temp.sort((a, b) => currency_order[a] - currency_order[b]);
                    this.filters.currency.options = temp;
                    this.filters.currency.values = temp;

                    this.load_ingka_by_country();
                },
                this.$helper.error
            );
        },
        load_ingka_by_country: function() {
            this.table.render = false;
            const keys = obj => Object.keys(obj);
            const key = obj => keys(obj).shift();
            const vals = obj => Object.values(obj);
            const entries = obj => keys(obj).filter(key => key !== 'sort').map(key => [key, obj[key]]);
            const rm_sort = lst => lst.filter(i => i !== 'sort');

            const headers_sort_keys = {
                'Month': 1, 'YTD': 2,
                'Forecast': 3, 'Remaining': 4,
                'Goal': 5
            }

            const headers_sort_keys_level_2 = {
                'Actual': 0, 'Forecast FY': 1,
                'Forecast - YTD': 2, 'Goal': 3,
                'Ratio (%)': 4, 'Idx vs LY': 5,
                'Diff vs Goal': 6, 'Idx vs Goal': 7,
            }

            const months = {
                0: 'Jan', 1: 'Feb', 2: 'Mar',
                3: 'Apr', 4: 'May', 5: 'Jun',
                6: 'Jul', 7: 'Aug', 8: 'Sep',
                9: 'Oct', 10: 'Nov', 11: 'Dec',
            }
            
            let has_business_area = this.filters.business_area.value;
            let has_is_comp = this.filters.is_comp.value;
            let has_currency = this.filters.currency.value;

            this.$http.post('/finance/profit-and-loss/poc', {
                business_area: has_business_area || this.filters.business_area.values[0],
                is_comp: has_is_comp || this.filters.is_comp.values[0],
                market: this.filters.stores.value || this.$store.getters.market,
                currency: has_currency || this.filters.currency.values[0]
            }).then(({ data }) => {
                if (!data || !data.length) return;
                this.table.headers, this.table.rows = [];

                let rows = data[0].data;
                for (row in rows) { // For Sebastian 01/11/2023 data was added to P&L for home page that should not be displayed on detailed page
                    delete rows[row]['Goal']['Idx vs LY YTD'];
                    delete rows[row]['Goal']['Ratio (%) YTD'];
                    delete rows[row]['Remaining']['Ratio (%)'];
                }
                let first_row = [], second_row = [];

                /** Work out headers **/
                let row = rows[key(rows)]; // Just get the first row to calculate headers
                rm_sort(keys(row)).forEach(k => {
                    let second_level_keys = rm_sort(keys(row[k]));
                    let second_level_len = second_level_keys.length;
                    first_row.push({
                        [k]: second_level_len
                    });
                    second_level_keys.forEach(k2 => {
                        second_row.push({
                            [k2]: 1,
                            alias: `${k} - ${k2}`
                        });
                    });
                });

                let temp = [];
                let count = 0;
                first_row.forEach(col => {
                    let bredth = vals(col).pop();
                    let slice = second_row.slice(count, count + bredth);
                    slice.sort((a, b) => {
                        let aa = key(a);
                        let bb = key(b);
                        return headers_sort_keys_level_2[aa] - headers_sort_keys_level_2[bb];
                    });
                    temp.push({
                        slice,
                        title: key(col)
                    });
                    count += bredth;
                });
                temp.sort((a, b) => {
                    return headers_sort_keys[a.title] - headers_sort_keys[b.title];
                });
                second_row = temp.map(a => a.slice).flat();
                first_row.sort((a, b) => headers_sort_keys[key(a)] - headers_sort_keys[key(b)]);

                // Sort all the rows by their sort key
                let row_entries = entries(rows);
                row_entries.sort(([a, aa], [b, bb]) => aa.sort - bb.sort);

                this.table.rows = [];
                row_entries.forEach(([name, row]) => {
                    let count = 0;
                    let t = [name];
                    first_row.forEach(col => {
                        let k = key(col);
                        let bredth = vals(col).pop();
                        let current_col = row[k];
                        second_row.slice(count, count + bredth).forEach(sub_col => {
                            let _k = key(sub_col);
                            if (current_col[_k]) {
                                t.push(current_col[_k].value);
                            } else {
                                t.push(null);
                            }
                        });
                        count += bredth;
                    });
                    this.table.rows.push(t);
                });

                this.table.headers = [
                    [{'P&L': 1}].concat(first_row),
                    [{[this.table.currency_label]: 1}].concat(second_row) // KEUR when you are on the store level
                ];

                let cur_month = this.$store.state.global.last_updated;
                cur_month = cur_month === 'N/A' ?
                    months[new Date().getMonth()] :
                    cur_month.split(' ').pop();

                // Repace the 'Month' column with the name of the current month
                this.table.headers[0].forEach((header, index) => {
                    let val = vals(header).pop();
                    if (keys(header).pop() === 'Month') {
                        this.table.headers[0][index] = {
                            [cur_month]: val,
                            alias: 'Month'
                        }
                    }
                });

                this.table.render = true;
            }, this.$helper.error);
        },
        update_business_area_filter: function(filter) {
            this.filters.business_area.value = filter;
            this.filters.stores.enabled = (filter.includes('Retail Country') || filter.includes('Food')) && !!this.$store.getters.market;
            this.load_ingka_by_country();
        },
        update_currency: function(filter) {
            this.table.currency_label = this.is_retail_country ? `k${filter}` : `M${filter}`;
            this.filters.currency.value = filter;
            this.load_ingka_by_country();
        },
        update_is_comp_filter: function(filter) {
            this.filters.is_comp.value = filter;
            this.load_ingka_by_country();
        },
        update_store_filter: function(filter) {
            this.table.currency_label = !filter ? 'MEUR' : 'kEUR';
            this.filters.stores.value = filter;
            this.filters.stores.enabled = !!this.$store.getters.market && this.is_retail_country;
            this.load_ingka_by_country();
        },
        update_view: function (market) {
            this.$store.commit('update_view', {
                market,
                location: this.location
            });
            this.load_ingka_by_country();
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.table.render = false;
            this.filters.stores.enabled = !!newMarket && this.is_retail_country; // Easier way to check for not being null/undefined
            this.update_view(newMarket);
            this.load_ingka_by_country();
        }
    },
    computed: {
        is_retail_country: function() {
            return this.filters.business_area.value && 
            (this.filters.business_area.value.includes('Retail Country') || this.filters.business_area.value.includes('Food'));
        },
        stores: function() {
            if (this.$store.getters.stores.values.length > 0) {
                const stores = this.$store.getters.stores;
                this.filters.stores.value = stores.values[0];
                return stores;
            }
            return {
                all: [],
                options: [],
                values: []
            }
        }
    }
}
</script>
<style>
.financial-poc table thead tr:first-of-type th {
    font-weight: 600 !important;
}
.subtotal-row {
        background-color: #FDDB2A;
        color: #000;
}
</style>