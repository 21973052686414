import helper from '@/helper';
/**
 * @param data Response from the api
 * @param page_data Hardcoded template information that page is constructed from
 * @param applied_filters array of each applied filters' values in order of application
 * @returns {table} An object with property table containing the populated table object above
 */
export default (data, page_data, applied_filters, other_options) => {
    
    let table = {
        headers: [],
        rows: []
    };
    
    data = apply_filters(data, applied_filters);
    if (other_options && other_options.remove_rows) data = remove_rows(data, other_options.remove_rows)
    // Convert response object into array
    if (!data) throw new Error(`No data found for table`);
    let data_array = Object.values(data);
    
    let measure_ids;
    let measure_dropdown_prop;
    // Use first row's measures as blueprint for columns
    let measures = data_array[0].measures;
    let needs_first_column = true;
    if ('measure_details' in measures) {
        measure_ids = 
        Object.keys(measures.measure_details)
        .map(measure_key => measures.measure_details[measure_key].measure_id)
        .sort();
        measure_dropdown_prop = generate_measure_dropdown_prop(measures);
        let selected_country_code;
        if (localStorage['saved-countries'] && JSON.parse(localStorage['saved-countries']).market) {
            selected_country_code = JSON.parse(localStorage['saved-countries']).market;
        } else {
            selected_country_code = 'ALL';
        }
        
        const [country_data] = data_array.filter(entry => entry.country_code === selected_country_code);
        measure_ids.forEach(measure_id => {
            const measure = country_data.measures[measure_id];
            const first_column_display_values = measure[Object.keys(measure)[0]].display_value;
            data_array = first_column_display_values.map((row, index) => {
                let measures2 = {};
                Object.keys(measure).forEach(column_index => {
                    measures2[column_index] = {...measure[column_index]};
                    if (measures2[column_index].display_value) {
                        measures2[column_index].display_value = measures2[column_index].display_value[index];
                    }
                    if (measures2[column_index].icon_value) {
                        measures2[column_index].icon_value = measures2[column_index].icon_value[index];
                    }
                });
                return {
                    'measures': measures2
                }
            });
        });
        measures = data_array[0].measures;
        needs_first_column = false;
    }
    const master_measures = measures;
    const {ordered_column_keys, ordered_column_headers} = order_columns(master_measures);
    const first_column_name = page_data.table_settings ? page_data.table_settings.first_column_name : null;
    table.headers = format_column_headers(ordered_column_headers, first_column_name, needs_first_column);

    // Construct cells row by row
    const has_override_settings = !!page_data.table_settings;
    if (!needs_first_column) {
        ordered_column_keys.shift();
    }
    data_array.forEach(data_row => {
        let table_row = new Row(needs_first_column ? data_row.kpi_subject : data_row.measures['00'].display_value);
        ordered_column_keys.forEach((column_key, column_index) => {
            const cell = data_row.measures[column_key];
            let table_cell = new Cell(cell.display_value);
            if (has_override_settings) table_cell.configure_settings('override', page_data.table_settings[column_index]);
            table_cell.configure_settings('data', cell);

            table_row.add(table_cell.export());
        });

        table.rows.push(table_row.export());
    });
    
    table.rows = helper.table.apply_sort_settings(table.rows);
    return {table};
}

/**
 * If there are filters, applies them to reach correct data, otherwise digs one level down to obtain rows
 * @param {Object} data The raw data object passed to the table parser
 * @param {Array} filters Array of filter values passed from Universal component
 * @returns {Object} The data object at the level of rows of countries
 */
const apply_filters = (data, filters) => {
    if (filters.length) {
        filters.forEach(filter => data = data[filter]);
    } else {
        data = data[Object.keys(data)[0]];
    }
    return data;
};

/**
 * Turns array of column headers (which may be compond with # to denote a multi row header) into an array that the table object can use to render headers
 * @param {Array} column_headers Array of column headers in order they should be displayed
 * @param {String} [first_column_name="Country"] Name of first column read from page_data.table_settings
 * @returns {Array} Array that the table object uses to render headers
 */
const format_column_headers = (column_headers, first_column_name, needs_first_column = true) => {
    first_column_name = first_column_name || 'Country';
    const is_multi_row_header = !column_headers.every(column_header => !column_header.includes('#')); // true if any header contains '#'
    
    if (needs_first_column) {
        if (!is_multi_row_header) return [ [first_column_name, ...column_headers].map(header => ({ [header]: 1 })) ];
    } else {
        if (!is_multi_row_header) return [ [...column_headers].map(header => ({ [header]: 1 })) ];
    }
    
    
    let split_column_headers = column_headers.map(column_header => column_header.split('#'));

    /**
     * Recursive function that builds header rows from bottom (span: 1 headers) to top (parent headers, span > 1)
     * @param {Array} input split_column_headers -array of arrays, where top level array represents columns and child arrays are list of headers from top to bottom 
     * @param {Array} output header_rows - array of arrays that represents the headers and their spans to be passed back to the table object.
     * @param {String} first_column Since first column of 'Country' is unique, the label is set for the bottom header row and then empty for subsequent rows above
     * @returns {Array} The array of arrays that describes the table headers (column names and spans)
     */
    const generate_parent_header_row = (input, output, first_column) => {
        if (input.every(split_header => split_header.length < 1)) return; // End recusion condition

        output.unshift( [{ [first_column]: 1 }] );

        let span = 1;
        
        input.forEach((column_headers_array, i) => {
            if (column_headers_array.length < 1) {
                output[0].push( { '': 1 } );
                return;
            }

            const lowest_child_header = column_headers_array.pop();
            const is_last_column = input.length == i+1;

            const lowest_child_header_of_next_column = !is_last_column ? input[i+1][(input[i+1].length -1)] : null;
            
            if (lowest_child_header === lowest_child_header_of_next_column) {
                span++;
            } else {
                output[0].push( { [lowest_child_header]: span } );
                span = 1;
            }
        });

        generate_parent_header_row(input, output, '');
        return output;
    };
    
    let header_rows = [];
    generate_parent_header_row(split_column_headers, header_rows, first_column_name);
    if (!needs_first_column) {
        header_rows.forEach(header_row => {
            header_row.shift();
        });
    }
    return header_rows;
};

/**
 * Extracts keys and column names from measures object as arrays in the order that columns are to be displayed.
 * Special measures' keys 'first' and 'last' are always set to the first and last columns respectively.
 * @param {Object} columns An abitrary set of measures extracted from any row of the response data 
 * @returns {Object} 
 *     @property {Array of Strings} ordered_column_keys An array of column object keys for reading measures in the correct order.
 *     @property {Array of Strings} ordered_column_headers An array of column names in an order determined by the measures' keys (a.k.a. columns' keys).
 */const generate_measure_dropdown_prop = (measures) => {
    const measure_keys = Object.keys(measures.measure_details).sort();
    if (measure_keys.length <= 1) return null;

    const dropdown_prop = {
        default: measures.measure_details[measure_keys[0]].measure_id,
        label: 'Measure',
        name: 'measures',
        options: [],
        values: []
    };

    measure_keys.forEach(measure_key => {
        dropdown_prop.options.push(measures.measure_details[measure_key].measure_name);
        dropdown_prop.values.push(measures.measure_details[measure_key].measure_id);
    });

    return dropdown_prop;
};

const order_columns = columns => {
    const {first = null, last = null, ...indexed_columns} = columns; //extract first/last columns if they exist
    
    let ordered_column_keys = Object.keys(indexed_columns).sort();
    let ordered_column_headers = ordered_column_keys.map(column => columns[column].column_name);

    if (first) {
        ordered_column_keys.unshift('first');
        ordered_column_headers.unshift(first.column_name);
    }
    if (last) {
        ordered_column_keys.push('last');
        ordered_column_headers.push(last.column_name);
    }

    return {ordered_column_keys, ordered_column_headers};
};

/**
 * If there is the remove rows option filled in in the other options object then loop 
 * through and remove all the rows where all the conditions are met
 * @param {Object} data The raw data object passed to the table parser
 * @param {Array} remove_rows Array of criteria to remove specific rows
 * @returns {Object} The data object at the level of rows of countries
 */
const remove_rows = (data, remove_rows) => {
    let temp = {};
    Object.keys(data).forEach(row_key => {
        let removal = true;
        remove_rows.forEach(row_removal_data => {
            Object.keys(row_removal_data).forEach(removal_key => {
                if (removal_key in data[row_key] && row_removal_data[removal_key] !== data[row_key][removal_key]) removal = false;
            });
        });
        if (removal) return;
        temp[row_key] = data[row_key];
    });
    return temp;
};

class Row {
    cells = [];

    constructor(row_header = '') {
        if (!row_header) return;
        const first_cell = new Cell(row_header);
        this.cells.push(first_cell.export_header());
    }

    export() {
        return this.cells;
    }

    add(cell) {
        this.cells.push(cell);
    }
};

class Cell {
    // Default cell formatting
    _decimal_setting = 1;
    _icon_value = null;

    // Expected settings types
    _data_settings = {};
    _override_settings = {};

    constructor(display_value){
        // this.display_value = helper.is_truthy_or_zero(display_value) ? display_value : ''; // use if 0s should be rendered
        this.display_value = display_value || display_value;
    }

    configure_settings(settings_type, settings = {}) {
        const {decimal, append_value, icon, icon_value, icon_label, max} = settings; //All possible settings
        this[`_${settings_type}_settings`] = {decimal, append_value, icon, icon_value, icon_label, max};
    }

    export() {
        return {
            display_value: this.display_value,
            html: this.html()
        };
    }

    export_header() {
        return {
            display_value: this.display_value,
            html: `<div>${this.display_value}</div>`
        };
    }

    html() {
        this._html = '';
        this._apply_settings();
        const icon = this._pick_setting([this._override_settings.icon, this._data_settings.icon]);
        const scale_multiplier = 100/this._pick_setting([this._override_settings.max, this._data_settings.max]);
        if (icon==='bar')
        {
            this._html = ` 
                        <div class="pb-bar" style="background:linear-gradient(90deg, transparent, transparent 0%, #23d160 0%, #23d160 ${this.display_value*scale_multiplier}%, transparent ${this.display_value*scale_multiplier}%)">${this._html}</div>
                    `
        }
        else
            this._html = `<div>${this._html}</div>`;            
        return this._html;
    }

    _apply_settings() {
        this._format_value();
        if (!this._html) return;
        this._format_decimal();
        this._format_append();
        this._format_icon();
    }

    _format_append() {
        const append_value = this._pick_setting([this._override_settings.append_value, this._data_settings.append_value]);
        if (this._html && append_value) this._html += append_value;
    }

    _format_decimal() {
        let decimal_setting = this._pick_setting([this._override_settings.decimal, this._data_settings.decimal, this._decimal_setting]);

        if (typeof(decimal_setting === 'string')) decimal_setting = parseInt(decimal_setting, 10);

        if (this._html && helper.is_truthy_or_zero(decimal_setting) && helper.isInt(decimal_setting)) {
            this._html = helper.round(this._html, decimal_setting, ',');
        }
    }

    _format_icon() {
        const icon = this._pick_setting([this._override_settings.icon, this._data_settings.icon]);
        if ((!icon) || (icon=='bar')) return;
        const icon_label = this._pick_setting([this._override_settings.icon_label, this._data_settings.icon_label]);
        const icon_value = this._pick_setting([this._override_settings.icon_value, this._data_settings.icon_value, this._icon_value]);

        const {icon_class, icon_color} = this._translate_to_css(icon, parseInt(icon_value));

        this._html +=
        `<span class="icon ${icon_value !== null ? `has-text-${icon_color}` : 'is-invisible'} ${icon_label ? 'ft-tooltip' : ''}" tooltiptext="${icon_label || ''}">
            <i class="fas fa-${icon_class || 'minus'}"></i>
        </span>`;
    }

    _format_value() {
        if (this.display_value && typeof(this.display_value) === 'string') {
            this._html = this.display_value === '-' ? '' : this.display_value.replace(/[^\d.-]/g, '');
        } else if (this.display_value || this.display_value === 0) {
            this._html = this.display_value.toString();
        }
    }

    /**
     * @param {Array} array_of_options pass each of the settings you may want to apply as an array
     * @returns The first option in the array which passes the is_truthy_or_zero check, if none pass, return null.
     */
    _pick_setting(array_of_options) {
        for (const option of array_of_options) {
            if (helper.is_truthy_or_zero(option)) return option;
        }
        return null;
    }

    _translate_to_css(icon, icon_value) {
        let icon_class = null;
        let icon_color = null;

        switch (icon_value) {
            case 1:
                icon_color = 'success';
                break;
            case 0:
                icon_color = 'info';
                icon_class = 'minus';
                break;
            case -1:
                icon_color = 'danger';
                break;
            default: break;
        }

        switch (icon_class || icon) {
            case 'circle':
                icon_class = 'circle'
                break;
            case 'arrow':
                if (icon_value === 1) {
                    icon_class = 'arrow-up';
                } else if (icon_value === -1) {
                    icon_class = 'arrow-down';
                }
                break;
            case 'bar':
                // icon_class = 'circle'
                // let lowest = Infinity, highest = -Infinity;
                // if (!('ignore' in props)) props.ignore = [];

                // // Filter out any rows that are to be ignored
                // rows.filter(r => !props.ignore.includes(r[0].value))
                // .forEach(r => {
                //     let value = r[col_index].value;
                //     if (value < lowest) lowest = value;
                //     if (value > highest) highest = value;
                // });
                // if (props.ignore.includes(row[0].value)) {
                //     return '';
                // } else {
                //     return this.render.bar(reversed, lowest, highest, cell.value);
                // }
                break;
            default: break;
        }

        return {icon_class, icon_color};
    }

}
