export default {
    props: Object,
    logic: function (data) {
        this.data = { ...data };
        let rows = this.data.parsed_rows;
        const add_commas = value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        rows.forEach((row, i) => {
            row.forEach((cell, j) => {
                if (!cell.value) return;
                if (cell.html) {
                    let match = cell.html.match(/>(-?[\d\.]+)/);
                    if (!match) return;
                    let value = match[1];
                    rows[i][j].html = rows[i][j].html.replace(value, add_commas(value));
                } else {
                    rows[i][j].html = `<span>${add_commas(rows[i][j].value)}</span>`;
                }
            });
        });
        return rows;
    }
}
