<template>
<div>    
    <div class="title-section">
        <div class="columns">
            <div class="column insights-title text-left">{{formatted_country_name}}</div>
            <div class="column insights-title text-center">VCG Insights</div>
            <div class="column">
                <div v-if="retail_manager.first_name && retail_manager.last_name" class="retail-manager"><b>Retail Manager:</b><br/>{{retail_manager.first_name}} {{retail_manager.last_name}}</div>
                <div v-else class="retail-manager">No retail manager set.</div>
            </div>
        </div>

        <div class="columns is-gapless insights-tabs" v-show="!this.$store.getters.presentation_mode">
            <div
                v-for="tab in tabs"
                :key="tab.link"
                class="column tab"
                :class="selected_kpi_area === tab.link ? 'active' : ''"
                @click="selected_kpi_area = tab.link"
            >
                <div>{{tab.name}}</div>
            </div>
        </div>
    </div>
    <div class="button-list">
        <div class="columns is-gapless button-set">
            <div class="column">
                <div class="options-button" @click="view_select_click('current')" :class="{'options-active': selected_view === 'current'}">
                    <!-- v-if="$store.getters.check_access(['country-insights', kpi, '*', 'r'])" -->
                <i class="fas fa-solid fa-file"></i> Current
                </div>
            </div>
            <div class="column">
                <div class="options-button" @click="view_select_click('new')" :class="{'options-active': selected_view === 'new'}">
                    <!-- v-if="$store.getters.check_access(['country-insights', kpi, '*', 'w'])" -->
                    <i class="fas fa-solid fa-plus"></i> New
                </div>
            </div>
            <div class="column">
                <div class="options-button" @click="view_select_click('draft')" :class="{'options-active': selected_view === 'draft'}">
                    <!-- v-if="$store.getters.check_access(['country-insights', kpi, '*', 'w'])" -->
                <i class="fas fa-solid fa-newspaper"></i> Draft
                </div>
            </div>
            <div class="column">
                <div class="options-button" @click="view_select_click('scheduled')" :class="{'options-active': selected_view === 'scheduled'}">
                    <!-- v-if="$store.getters.check_access(['country-insights', kpi, '*', 'w'])" -->
                    <i class="fas fa-regular fa-calendar"></i> Scheduled
                </div>
            </div>
            <div class="column">
                <div class="options-button" @click="view_select_click('archived')" :class="{'options-active': selected_view === 'archived'}">
                    <!-- v-if="$store.getters.check_access(['country-insights', kpi, '*', 'r'])" -->
                    <i class="fas fa-archive"></i> Archived
                </div>
            </div>
            <div class="column" v-if="selected_view !== 'new'">
                <div
                    class="options-button presentation-mode ft-tooltip-bottom"
                    
                    @click="toggle_presentation_mode()" :class="{'active': $store.state.global.show_edit}"
                    tooltiptext="Edit Mode"
                >
                    <!-- v-if="$store.getters.check_access(['country-insights', kpi, '*', 'w'])" -->
                    <i class="fas fa-edit"></i>
                    <i class="fas fa-slash absolute" v-show="!$store.state.global.show_edit"></i>
                </div>
            </div>
            <div class="column" v-if="selected_view !== 'new'">
                <div class="options-button ft-tooltip-bottom" @click="toggle_print_options" tooltiptext="Print">
                    <i class="fas fa-print"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="columns">
        <div v-if="selected_kpi_area !== 'introduction' && selected_view === 'current' && !edit_mode" class="kpi-areas ui-rounded column is-4" >
            <DataBox
                v-if="db_kpi_data"
                :kpi_data="db_kpi_data"
                :versions="version_obj"
                :title="kpi_formatted"
                class="data-box ui-rounded"
            />
            <div v-else class="data-box ui-rounded">
                No data has been found for {{kpi_formatted}}
            </div>
        </div>
        <div class="content column">
            <BrowserNotice />
            <div v-if="selected_view !== 'new' && !edit_mode">
                <div v-if="found_comments">
                    <!-- <CommentWarning
                        v-if="(kpi === 'introduction' ? comments.length > 2 : comments.length > 1) && $store.getters.check_access(['comments', '*', 'w']) && selected_view === 'current'"
                        :commentLimit="kpi === 'introduction' ? 'two' : 'one'"
                    /> -->
                    <div class="comment-container" :class="comments.length - 1 !== i ? 'comment-border' : ''" v-for="(comment, i) in comments" :key="i">
                        <div class="columns comment-title">
                            <div class="column text-left">
                                <div v-if="comment.title">{{comment.title}}</div>
                            </div>
                            
                            <div class="column comment-meta text-right">
                                <div>{{comment.u_display_name}}</div>
                                <div style="display: inline-flex;">
                                    <div v-if="comment.updated_by" class="ft-tooltip" :tooltiptext="comment.e_display_name">|Edited</div>
                                    <div v-if="comment.author_id" class="ft-tooltip" :tooltiptext="comment.a_display_name">|Posted By</div>
                                </div>
                                {{comment.scheduled_date.split('T')[0]}} - {{comment.comment_lifetime.split('T')[0]}}</div>
                                <CommentRatingButtons class="flex mt" :id="comment.comment_id" :current="comment.rating" />
                        </div>
                        
                        <div class="comment-content text-left ProseMirror" v-html="comment.content"></div>
                        <div class="button-container" v-if="$store.getters.check_access(['comments', 'edit', '*', 'w']) &&  $store.state.global.show_edit">
                            <div class="button edit-button is-half" @click="comment_to_edit = comments[i], toggle_edit_mode()">
                                Edit
                            </div>
                            <div v-if="selected_view !== 'archived'" class="button edit-button is-half" @click="comment_to_edit = comments[i], change_status('ARCHIVED')">
                                Archive
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="mt">
                    No {{selected_view}} insights could be found
                </div>
            </div>
            <div v-if="selected_view === 'new'">
                <New
                    :kpi="kpi"
                    :insights_titles="insights_titles"
                    @toggle-edit-mode="toggle_edit_mode"
                    @updated="edit_updated"
                />
            </div>
            <div v-if="edit_mode" class="mt">
                <Edit
                    :comment-to-edit="comment_to_edit"
                    :status="selected_view"
                    :insights_titles="insights_titles"
                    @toggle-edit-mode="toggle_edit_mode"
                    @updated="edit_updated"
                />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Alert from '@/components/ui/Alert.vue';
import BrowserNotice from '@/components/bespoke/home/BrowserNotice.vue';
import CommentRatingButtons from '@/components/ui/CommentRatingButtons.vue';
import CommentWarning from '@/components/bespoke/commenting/CommentWarning.vue';
import DataBox from '@/components/bespoke/market-insights/DataBox.vue';
import Edit from '@/components/bespoke/country-insights/Edit.vue';
import New from '@/components/bespoke/country-insights/New.vue';
import kpi_area_mapping from '@/assets/scripts/kpi-area-mapping.js';
import kpi_data_mapping from '@/assets/scripts/kpi-data-mapping.js';

export default {
    components: {
        Alert,
        BrowserNotice,
        CommentRatingButtons,
        CommentWarning,
        DataBox,
        Edit,
        New
    },
    props: {
        kpi: String,
        market: String
    },
    data: function() {
        return {
            retail_manager: {
                first_name: null,
                last_name: null
            },
            selected_kpi_area: 'introduction',

            kpi_data: null,

            tabs: [
                {name: 'Introduction', link: 'introduction'},
                {name: 'Better Homes', link: 'better-homes'},
                {name: 'Better Company', link: 'better-company'},
                {name: 'Better Lives', link: 'better-lives'},
                {name: 'Better Planet', link: 'better-planet'}
            ],
            
            render: false,
            selected_view: 'current',
            filters: {
				order_by: null,
				search: null
            },
            print_options_visible: false,
            version_obj: {},
            edit_mode: false,
            comments: [],
            comment_to_edit: null,

            name_list: [],
            name_obj: {},
            temp_path: null,
            temp_parent_path: null,
            found_comments: true,

            insights_titles: {
                options: [],
                values: [],
                all: []
            },
        }
    },
    mounted: function() {
        this.$store.state.commenting.width = 0;
        this.get_insights_titles();
        this.load_kpi_data();
        this.get_retail_manager();
        this.load_comments();
        
        this.tabs.forEach(tab => {
            if (tab.link === this.kpi) this.selected_kpi_area = this.kpi;
        });
    },
    destroyed: function() {
        this.$store.state.commenting.width = 4;
    },
    methods: {
        add_filter_and_links: function() {
            Object.keys(this.kpi_data).forEach(kpi_datum => {
                Object.keys(this.kpi_data[kpi_datum]).forEach(kpi => {
                    this.empty_temps();

                    const matching_mapped_kpi = kpi_data_mapping.find(mapped_kpi => mapped_kpi.name === kpi_datum);
                    if (matching_mapped_kpi === undefined) return;

                    this.scan_mapped_areas(matching_mapped_kpi, kpi_datum, kpi);
                    if (this.temp_path) this.kpi_data[kpi_datum][kpi].path = this.temp_path;
                    this.add_version(kpi_datum, this.temp_path);
                });
            });
            if (!this.name_list.length) return;
            this.$http.post(`/last-updated/list`, {kpi_areas: this.name_list}).then(
                succ => {
                    let d = succ.data;
                    d.forEach( name => {
                        if (name.version) {
                            this.version_obj[this.name_obj[name.view]] = name.version;
                        }
                    });
                    this.kpi_render = true;
                },
                this.$helper.error
            );
        },
        add_version: function(kpi_area, path) {
            if (!path) return;
            return new Promise((resolve, reject) => {
                let kpi_name = kpi_area_mapping.find(kpi => path.includes(kpi.path));
                if (kpi_name) {
                    this.name_list.push(kpi_name.name);
                    this.name_obj[kpi_name.name] = path;
                }
            });
        },
        change_status: function(status) { 
            this.$chttp.post(`/comments/edit`, {
                id: this.comment_to_edit.comment_id, 
                status: status
            }).then(
                succ => {
                    this.edit_updated(status);
                },
                this.$helper.error
            );
        },
        edit_updated: function(status) {
            this.edit_mode = false;
            if (status === 'LIVE') {
                this.selected_view = 'current';
            } else if (status !== 'DELETED' &&status !== 'ARCHIVED') {
                this.selected_view = status.toLowerCase();
            }            
            this.load_comments();
        },
        empty_temps: function() {
            this.temp_path = null;
            this.temp_parent_path = null;
        },
        get_insights_titles: function() {
            this.$http.post('/comments/insights-templates/search', {
                area: this.formatted_selected_area
            }).then(
                succ => {
                    let d = succ.data;
                    if (!succ || !d.length) {
                        this.insights_titles.options = ['No Title Selected'];
                        this.insights_titles.values = [null];
                        return;
                    }
                    this.insights_titles.all = d;
                    this.insights_titles.options = ['No Title Selected'];
                    this.insights_titles.values = [null];
                    d.forEach(title => {
                        this.insights_titles.options.push(title.title);
                        this.insights_titles.values.push(title.id);
                    });
                },
                this.$helper.error
            );
        },
        get_retail_manager: function() {
            this.retail_manager = {
                first_name: null,
                last_name: null
            }
            this.$http.get(`/comments/retail-managers/load/${this.$helper.location().market_area}`).then(
                succ => {
                    if (!succ.data || !succ.data[0]) return;
                    const [{ first_name, last_name }] = succ.data;
                    this.retail_manager = {
                        first_name: first_name,
                        last_name: last_name
                    }
                },
                this.$helper.error
            );
        },
        load_comments: function() {
			this.comments = [];
            let to_load = this.$helper.location();
            to_load.status = (this.selected_view === 'current' ? 'live' : this.selected_view).toUpperCase();
			to_load.filters = {
				order_by: this.selected_view === 'archived' ? this.filters.order_by : null,
				search: this.selected_view === 'archived' ? this.filters.search : null
            };
			this.$chttp.post('/comments/load',
				to_load
			).then(
				succ => {
					if (succ.data) {
                        let d = succ.data;
                        let temp = [];
                        if (d.length > 0) {
                            this.comments = [];
                            d.forEach( comment => {
                                if (comment.title === 'Retail Manager Introduction') {
                                    this.comments.unshift(comment);
                                } else {
                                    this.comments.push(comment);
                                }
                            });
                            this.comments.sort((a,b) => (a.ordering ? a.ordering : 5) - (b.ordering ? b.ordering : 5));
                            this.found_comments = true;
                        } else {
                            this.found_comments = false;
                        } 
					}
				},
				error => {}
			);
        },
        load_kpi_data: function() {
            this.$http.post('/landing-page/vgc-landing', {market: this.$store.getters.market}).then(
                succ => {
                    if (!succ.data || !Object.keys(succ.data).length) {
                        this.kpi_data = null;
                    } else {
                        this.kpi_data = succ.data;
                    }
                },
                this.$helper.error
            );
            
        },
        market_or_kpi_change: function() {
            this.$router.push(`/country-insights/${this.selected_kpi_area}/${this.$store.getters.market}`);
        },
        scan_mapped_areas: function(mapped_kpi, kpi_datum, kpi, use_parent_path = false) {
            if ('areas' in mapped_kpi) {
                mapped_kpi.areas.forEach(mapped_kpi_child => this.scan_mapped_areas(mapped_kpi_child, kpi_datum, kpi));
            } else if ('alt' in mapped_kpi) {
                this.temp_parent_path = mapped_kpi.location || mapped_kpi.path;
                mapped_kpi.alt.forEach(alt => this.scan_mapped_areas(alt, kpi_datum, kpi, true));
            } else if (mapped_kpi.name.toLowerCase() === kpi.toLowerCase()) {
                if (this.kpi_data[kpi_datum][kpi]) this.temp_path = use_parent_path ? this.temp_parent_path : mapped_kpi.location || mapped_kpi.path;
            }
        },
        toggle_edit_mode: function() {
            if (this.selected_kpi_area === 'new') {
                this.edit_mode = false;
                this.selected_kpi_area = 'current';
            } else {
                this.edit_mode = !this.edit_mode;
            }
        },
        toggle_presentation_mode: function() {
            this.$store.state.global.show_edit = !this.$store.state.global.show_edit;
            if (this.edit_mode) {
                this.edit_mode = false;
            } 
        },
        toggle_print_options: function() {
            this.$router.push('/exports');
        },
        view_select_click: function(view) {
            this.edit_mode = false;
            this.selected_view = view;
        }
    },
    computed: {
        db_kpi_data: function() {
            if (this.kpi_data) {
                return this.kpi_data[this.kpi_formatted];
            }
            return false;
        },
        formatted_country_name: function() {
            let word = this.$store.getters.selected_country_name.toLowerCase();
            word = word.split('');
            word[0] = word[0].toUpperCase();
            return word.join('');
        },
        formatted_selected_area: function() {
            let seperated_array = this.selected_kpi_area.split('-');
            return seperated_array.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        },
        kpi_formatted: function() {
            return this.kpi
                .replace(/-/g, " ")
                .replace(/three/g, "3")
                .replace(/financial/, "finance")
                .replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
        },
        kpi_options: function() {
            let kpi_options = [];
            this.tabs.forEach(tab => kpi_options.push(tab.link));
            return kpi_options;
        },
        print_options: function() {
            return {
                country: `${process.env.VUE_APP_BASE_URL}/download/pdf/output-${this.market}`,
                all: `${process.env.VUE_APP_BASE_URL}/download/pdf/output-all`,

                scheduled: {
                    country: `${process.env.VUE_APP_BASE_URL}/download/pdf/output-${this.market}-scheduled`,
                    all: `${process.env.VUE_APP_BASE_URL}/download/pdf/output-all-scheduled`
                }
            };
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.market_or_kpi_change();
            this.get_retail_manager();
            this.load_kpi_data();
            this.load_comments();
        },
        selected_view: function() {
            this.load_kpi_data();
            this.load_comments();
            this.get_insights_titles();
        },
        selected_kpi_area: function() {
            this.selected_view = 'current';
            this.get_insights_titles();
            this.market_or_kpi_change();
            this.load_comments();
        }
    }
}

</script>

<style scoped>
@media only screen and (max-width: 769px) {
    .tab {
        border-radius: 14px !important;
    }
}
.active {
    background-color: white !important;
    color: #0158a3;
}
.comment-container {
    box-shadow: none;
}
.button-list {
    width: 100%;
    padding: 1rem 0 0 2rem;
}
.button-set {
    width: 0%;
}
.comment-meta {
    line-height: 1rem;
    font-weight: bold;
    font-size: 0.9rem;
}
.comment-title {
    font-weight: bold;
    font-size: 1.1rem;
}
.columns {
    margin-right: 0 !important;
    margin-left: 0 !important;
}
.fa-slash {
    font-size: 1.1rem;
    left: 0.75rem;
    top: 0.75rem;
}
.tab {
    border-radius: 14px 14px 0 0;
    height: 2.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}
.tab:hover {
    background-color: whitesmoke;
    border-bottom-color: #dbdbdb;
}
.kpi-areas {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    margin: 2rem 0 0 1.5rem !important;
}
.insights-tabs {
    margin-top: 1rem !important;
}
.title-section {
    background-color: #ebebeb;
    padding: 2rem 3rem 0rem 3rem;
    height: 8rem;
}
.insights-title {
    font-size: 1.2rem;
    font-weight: bold;
}
.retail-manager {
    position: absolute;
    right: 3rem;
    text-align: right;
}
.options-button {
    width: max-content;
    border-radius: 18px;
    border: solid 1px #c7c6c6;
    padding: 0.5rem 1rem;
    min-width: 3rem;
    margin-left: 1rem;
    cursor: pointer;
}
.options-button:hover {
    background-color: whitesmoke;
}
.options-active {
    color: #0158a3;
    border-color: #0158a3;
}
</style>