var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "button comment-rate-button ft-tooltip",
        class: { chosen: _vm.rating === true },
        attrs: {
          tooltiptext: "I found this useful",
          title: "I found this observation useful",
          disabled: _vm.working,
        },
        on: {
          click: function ($event) {
            return _vm.rate(true)
          },
        },
      },
      [_c("i", { staticClass: "fas fa-thumbs-up" })]
    ),
    _c(
      "div",
      {
        staticClass: "button comment-rate-button ft-tooltip-left",
        class: { chosen: _vm.rating === false },
        attrs: {
          tooltiptext: "I did not find this useful",
          title: "I did not find this observation useful",
          disabled: _vm.working,
        },
        on: {
          click: function ($event) {
            return _vm.rate(false)
          },
        },
      },
      [_c("i", { staticClass: "fas fa-thumbs-down" })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }