import LeadershipIndex from '@/views/people/LeadershipIndex.vue';
import IKEAValues from '@/views/people/IKEAValues.vue';
import EngagementIndex from '@/views/people/EngagementIndex.vue';
import BalancedStaffTurnover from '@/views/people/BalancedStaffTurnover.vue';
import FemaleManagerShare from '@/views/people/FemaleManagerShare.vue';

export default [
    // {
    //     path: '/people/leadership-index',
    //     component: LeadershipIndex,
    // },
    // {
    //     path: '/people/ikea-values',
    //     component: IKEAValues,
    // }, 
    // {
    //     path: '/people/engagement-index',
    //     component: EngagementIndex,
    // },
    // {
    //     path: '/people/balanced-co-worker-turnover',
    //     props: {template: './people/Co-WorkerTurnover/by_country.js'}
    // },
    // {
    //     path: '/people/balanced-co-worker-turnover/:market',
    //     props: route => ({
    //         template: './people/Co-WorkerTurnover/by_trend.js',
    //         market: route.params.market
    //     })
    // },
    {
        path: '/people/gender-manager-share',
        component: FemaleManagerShare,
    },
    // {
    //     path: '/people/e-nps',
    //     props: {template: './people/eNPS/by_country.js'}
    // },
    // {
    //     path: '/people/e-nps/:market',
    //     props: route => ({
    //         template: './people/eNPS/by_trend.js',
    //         market: route.params.market
    //     })
    // },
    // {
    //     path: '/people/change-makers',
    //     props: {template: './people/ChangeMakers/by_country.js'}
    // },
    // {
    //     path: '/people/change-makers/:market',
    //     props: route => ({
    //         template: './people/ChangeMakers/by_trend.js',
    //         market: route.params.market
    //     })
    // },
    // {
    //     path: '/people/social-impact',
    //     props: {template: './people/SocialImpact/by_country.js'}
    // },
    // {
    //     path: '/people/social-impact/:market',
    //     props: route => ({
    //         template: './people/SocialImpact/by_trend.js',
    //         market: route.params.market
    //     })
    // },
    {
        path: '/people/inspired-and-enabled-people',
        props: {template: './people/InspiredandEnabledPeople/by_country.js'}
    },
    {
        path: '/people/inspired-and-enabled-people/:market',
        props: route => ({
            template: './people/InspiredandEnabledPeople/by_trend.js',
            market: route.params.market
        })
    },
];
