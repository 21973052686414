var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("AdminUniversal", {
        attrs: { component: "title", props: _vm.title_props },
      }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [_c("KPIChooserDropdowns", { on: { update: _vm.update_kpi } })],
        1
      ),
      _c("CommentKey", {
        staticStyle: { "margin-top": "-1rem" },
        attrs: { status_colors: _vm.status_colors },
      }),
      _vm.render
        ? _c(
            "section",
            { staticClass: "accordions accordians-height vertical-scroll mt" },
            _vm._l(_vm.locations, function (location, index) {
              return _c(
                "article",
                {
                  key: index,
                  staticClass: "accordion",
                  class: `${
                    _vm.active_accordian === location.id ? "is-active" : ""
                  }`,
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "accordion-header relative",
                      on: {
                        click: function ($event) {
                          return _vm.toggle_active(location.id)
                        },
                      },
                    },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: `/${location.kpi}/${location.kpi_area}${
                              location.market_area !== null
                                ? "/" + location.market_area
                                : ""
                            }${
                              location.store_area !== null
                                ? "/" + location.store_area
                                : ""
                            }`,
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(location.kpi_area) +
                              _vm._s(
                                location.market_area !== null
                                  ? "/" + location.market_area
                                  : ""
                              ) +
                              _vm._s(
                                location.store_area !== null
                                  ? "/" + location.store_area
                                  : ""
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _vm._l(
                        location.observations,
                        function (observation, index) {
                          return _c("div", { key: index }, [
                            _c("div", {
                              staticClass: "status-badges",
                              style: `background-color:${
                                _vm.status_colors[
                                  _vm.status(observation.status)
                                ]
                              }; left:${25 + index * 1.5 + index / 2}rem`,
                            }),
                          ])
                        }
                      ),
                      location.observations.length === 0
                        ? _c("div", [
                            _c("div", {
                              staticClass: "status-badges",
                              style: `background-color:${_vm.status_colors["none"]}; left:25rem`,
                            }),
                          ])
                        : _vm._e(),
                      _c("i", {
                        class: `${
                          _vm.active_accordian === location.id
                            ? "fas fa-chevron-up"
                            : "fas fa-chevron-down"
                        }`,
                      }),
                    ],
                    2
                  ),
                  _c("div", { staticClass: "accordion-body cursor-default" }, [
                    location.observations.length > 0
                      ? _c(
                          "div",
                          { staticClass: "accordion-content bg-white" },
                          _vm._l(
                            location.observations,
                            function (observation, i) {
                              return _c(
                                "div",
                                {
                                  key: i,
                                  staticClass: "insight columns is-gapless",
                                },
                                [
                                  _c("div", { staticClass: "column" }, [
                                    _vm._v(
                                      "Status: " + _vm._s(observation.status)
                                    ),
                                  ]),
                                  _c("div", { staticClass: "column" }, [
                                    _vm._v(
                                      "Author: " +
                                        _vm._s(observation.display_name)
                                    ),
                                  ]),
                                  _c("div", { staticClass: "column" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          observation.status === "LIVE"
                                            ? "Started: "
                                            : "Starting: "
                                        ) +
                                        _vm._s(
                                          _vm.display_date(
                                            observation.scheduled_date
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("br"),
                                    _c("b", {}, [_vm._v("-")]),
                                    _c("br"),
                                    _vm._v(
                                      " Ending: " +
                                        _vm._s(
                                          _vm.display_date(
                                            observation.comment_lifetime
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("div", { staticClass: "column" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "button is-success content-button",
                                        on: {
                                          click: function ($event) {
                                            return _vm.open_modal(
                                              observation.content_id
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Content")]
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "modal",
                                      class: `${
                                        _vm.active_modal ===
                                        observation.content_id
                                          ? "is-active"
                                          : ""
                                      }`,
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "modal-background",
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "modal-content relative",
                                        },
                                        [
                                          _c("button", {
                                            staticClass: "delete top-right",
                                            attrs: { "aria-label": "close" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.close_modal()
                                              },
                                            },
                                          }),
                                          _c("div", {
                                            staticClass: "mt mb",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                observation.content
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _c(
                          "div",
                          { staticClass: "accordion-content bg-white" },
                          [_vm._v("No Observations Found")]
                        ),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }