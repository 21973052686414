export default {
    props: Object,
    logic: function(props, data) {
        this.data = { ...data, ...props };
        let headers = this.data.parsed_headers;
        let rows = this.data.parsed_rows;
        return this.render.bold(props.bold, headers, rows);
    },
    render: {
        bold: function(props, headers, rows) {
            const match = ({cell, value}, reg) => {
                return (cell === reg) || (value === reg); // TODO: Change this to regex
            }
            
            let hs = document.querySelectorAll('.custom.table thead tr');
            let rs = document.querySelectorAll('.custom.table tbody tr');
            
            /** Column based bold **/
            // Search over all of the headers, find the index of the row that corresponds to the column
            if (props.bold && props.bold.col) {
                let col_index;
                for (let [index, row] of headers.entries()) {
                    col_index = 0;
                    for (let [i, cell] of row.entries()) {
                        let c = Object.keys(cell).pop();
                        let v = Object.values(cell).pop();
                        if (match({cell: c, value: undefined}, props.col.regex)) {
                            break;
                        }
                        col_index += v;
                    }
                }
                hs.forEach(row => row.childNodes[col_index].classList.add('text-bold'));
                rs.forEach(row => row.childNodes[col_index].classList.add('text-bold'));
            }

            /** Row based bolding **/
            for (const [index, row] of rows.entries()) {
                for (let cell of row) {
                    // If a match, add class to the row
                    if (match(cell, props.row.regex)) {
                        // return index;
                        if (rs[index]) {
                            rs[index].classList.add('text-bold');
                        }
                        break;
                    }
                }
            }
        }
    }
}