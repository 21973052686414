var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "kpi-search navbar-item text-left hidden-on-mobile",
      attrs: { id: "input-field" },
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.kpi_search_string,
            expression: "kpi_search_string",
          },
        ],
        ref: "inputField",
        attrs: { placeholder: "Search for KPI" },
        domProps: { value: _vm.kpi_search_string },
        on: {
          focus: function ($event) {
            _vm.kpi_search_focus = true
          },
          blur: function ($event) {
            _vm.kpi_search_focus = false
          },
          input: function ($event) {
            if ($event.target.composing) return
            _vm.kpi_search_string = $event.target.value
          },
        },
      }),
      _vm.kpi_search_string
        ? _c(
            "span",
            {
              staticClass: "delete-icon",
              on: {
                click: function ($event) {
                  return _vm.clear()
                },
              },
            },
            [_c("i", { staticClass: "fas fa-times-circle" })]
          )
        : _vm._e(),
      _vm.kpi_search_string &&
      (_vm.kpi_search_focus || _vm.kpi_search_mouseover)
        ? _c(
            "div",
            {
              staticClass: "navbar-dropdown search-dropdown",
              on: {
                mouseover: function ($event) {
                  _vm.kpi_search_mouseover = true
                },
                mouseleave: function ($event) {
                  _vm.kpi_search_mouseover = false
                },
              },
            },
            _vm._l(_vm.filtered_kpis, function (kpi, i) {
              return _c(
                "div",
                {
                  key: kpi.path,
                  staticClass: "nested navbar-item dropdown",
                  class: _vm.highlighted_index === i ? "highlighted" : "",
                  on: {
                    mouseover: function ($event) {
                      _vm.highlighted_index = i
                    },
                  },
                },
                [
                  _c("div", { staticClass: "dropdown-trigger" }, [
                    _c("div", {
                      attrs: { "aria-haspopup": "false" },
                      domProps: { innerHTML: _vm._s(kpi.label) },
                      on: {
                        click: function ($event) {
                          _vm.clear()
                          _vm.$router.push(kpi.path)
                        },
                      },
                    }),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }