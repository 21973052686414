<template>
<div>
    <AdminUniversal
        component="title"
        :props="title_props"
    />
    <AdminUniversal
        component="textinput"
        :props="input_props"
    />
    <AdminUniversal
        component="button"
        :props="button_props"
    />
    <AdminUniversal
        v-if="table_props.render"
        component="table"
        :props="table_props"
    />
</div>
</template>

<script>
import AdminUniversal from '@/components/admin/index.vue';

export default {
    components: {
        AdminUniversal
    },
    data: function() {
        return {
            title_props: {
                title: 'Known Issues',
                subtitle: ''
            },
            input_props: {
                input: '',
                label: '',
                placeholder: 'Add known Issue',
                callback: this.update_input
            },
            button_props: {
                label: 'Add Issue',
                classes: 'is-success',
                method: this.add_issue
            },
            table_props: {
                buttons: [{
                    label: 'Delete',
                    classes: 'is-danger',
                    method: this.delete_issue
                }],
                headers: null,
                data: null,
                render: false
            },
            issue: ''
        }
    },
    mounted: function() {
        this.load_issues();
    },
    methods: {
        add_issue: function() {
            if (!this.issue || this.issue.trim() === '') {
                alert('Please enter an issue');
                return;
            }
            this.$http.post('/admin/known-issues/add', {
                    issue: this.issue
                }).then(
                succ => {
                    alert(succ.data.message);
                    this.issue = null;
                    this.load_issues();
                },
                this.$helper.error
            );
        },
        delete_issue: function(row) {
            const id = this.table_props.data[row].id;            
            this.$http.post('/admin/known-issues/delete', {
                id: id,
            }).then(
                succ => {
                    alert(succ.data.message);
                    this.load_issues();
                },
                this.$helper.error
            );
        },
        load_issues: function() {
            this.table_props.render = false;
            this.$http.get('/known-issues/get').then(
                succ => {
                    if (succ.data) {
                        this.table_props.data = succ.data.map(row => {
                            row.created_at = new Date(row.created_at).toLocaleString();
                            return row;
                        });
                        this.table_props.headers = [...Object.keys(succ.data[0]), 'Actions'];
                    }
                    this.table_props.render = true;
                },
                this.$helper.error
            );
        },
        update_input: function(input) {this.issue = input;}
    }
}
</script>

<style scoped>
</style>