<template>
<div>
    <Title
        title="Cost Per Country"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container">
            <p>Cost Type</p>
            <DropDown
                :key="filter"
                :options="primary_options"
                :values="primary_values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>
        <div class="label-container">
            <p>Cost Type</p>
            <DropDown
                :key="secondary_filter"
                :options="secondary_options"
                :values="secondary_values"
                :default="secondary_filter"
                @updateDropDown="update_secondary_filter"
            />
        </div>
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms mb">
        <Top8Bar
            :key="top_8.render"

            title="Cost Per Country Top 10 Countries"
            :showtitle="!$store.state.global.bot_mode"
            subtitle=""
            :xcategories="top_8.xcategories"
            :yaxislabel="`${filter} %`"
            ymetric="%"

            seriesname1="YTD ACT"
            seriesname2="Year FC"
            seriesname3="Year Goal"
            seriesname4=""

            seriestype1="bar"
            seriestype2="bar"
            seriestype3="bar"
            seriestype4="line"

            :seriesset1="top_8.seriesset1"
            :color1="$store.state.colors['yellow']"
            
            :seriesset2="top_8.seriesset2"
            :color2="$store.state.colors['grey']"

            :seriesset3="top_8.seriesset3"
            color3="#787878"

            :seriesset4="top_8.seriesset4"
            :color4="$store.state.colors.YG"

            :ranking="[]"
            :competitors="[]"
        />
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Top8Bar from '@/components/visualisations/bar/Top8Bar.vue';
import Title from '@/components/ui/Title.vue';


export default {
    components: {
        Buttons,
        DropDown,
        Top8Bar,
        Title
    },
    data: function() {
        return {
            top_8: {
                render: false,

                xcategories: [],

                seriesset1: [],                
                seriesset2: [],
                seriesset3: [],
                seriesset4: []
            },
            location: '/finance/cost-per-country',
            primary_options: [],
            primary_values: [],
            filter: null,
            secondary_filter: null,
            secondary_options: [],
            secondary_values: []
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: '/finance/cost-per-country'
                },
                {
                    name: 'By Market',
                    link: `/finance/cost-per-country/trend/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: '/finance/cost-per-country/top-8'
                },
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'totsal-sales-cntry-vsgoal'
            }).then(
                succ => {
                    succ.data.map(filter => {
                        this.primary_values.push(filter.value);
                        this.primary_options.push(filter.option);
                    });
                    this.filter = this.$store.getters.filter_check(this.primary_values)
                },
                this.$helper.error
            );
            this.load_ingka_by_country();
        },
        load_ingka_by_country: function() {
            if (!this.filter) return;
            this.$http.post(`/finance/cost-per-country/top-8`, {filter: this.filter}).then(
                succ => {
                    let d = succ.data;
                    if (!d) return;
                    if (!this.secondary_options.length || !this.secondary_values.length) {
                        for (const key of Object.keys(d[0].measures)) {
                            this.secondary_options = [ ...this.secondary_options, key];
                            this.secondary_values = [ ...this.secondary_values, key];
                        }
                        this.secondary_filter = this.secondary_values[0];
                    }
                    this.top_8.xcategories = [];
                    this.top_8.seriesset1 = [];
                    this.top_8.seriesset2 = [];
                    this.top_8.seriesset3 = [];
                    d.map(row => {
                        this.top_8.xcategories.push(row.caption);
                        this.top_8.seriesset1.push(row.measures[this.secondary_filter]['YTD ACT']);
                        this.top_8.seriesset2.push(row.measures[this.secondary_filter]['Year FC']);
                        this.top_8.seriesset3.push(row.measures[this.secondary_filter]['Year Goal']);
                    });                    
                    this.top_8.render = true;
                },
                this.$helper.error
            );
        },
        update_secondary_filter: function(filter) {
            this.secondary_filter = filter;
            this.top_8.render = false;
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.top_8.render = false;
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        },
        filter: function() {
            this.load_ingka_by_country();
        },
        secondary_filter: function() {
            this.load_ingka_by_country();
        }
    }
}
</script>