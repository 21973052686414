var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "custom-title" }, [
    _c("div", { staticClass: "title" }, [
      _vm._v(" " + _vm._s(_vm.title) + " "),
      _c(
        "div",
        {
          staticClass: "cursor-pointer inline-block",
          on: { click: _vm.show_kpi },
        },
        [
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !this.$store.getters.presentation_mode,
                expression: "!this.$store.getters.presentation_mode",
              },
            ],
            staticClass: "fas fa-info-circle superscript text-ikea-blue",
          }),
        ]
      ),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show_kpi_defintion,
            expression: "show_kpi_defintion",
          },
        ],
      },
      [
        _vm.is_loaded.kpi_definition
          ? _c("div", [
              _vm.kpi_definition
                ? _c(
                    "div",
                    [
                      _c("b", [_vm._v("Definition:")]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "column ProseMirror",
                        domProps: { innerHTML: _vm._s(_vm.kpi_definition) },
                      }),
                      _c("hr"),
                      this.definition_id
                        ? _c("DefinitionRatingButtons", {
                            attrs: {
                              id: this.definition_id,
                              current: this.rating,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _c("div", [
              _c("b", [_vm._v("Definition:")]),
              _vm._v(" "),
              _c("div", { staticClass: "column ProseMirror" }, [
                _vm._v(" This KPI definition hasn't been defined. "),
              ]),
            ]),
      ]
    ),
    _c("div", {}, [
      _vm.subtitle
        ? _c("div", { staticClass: "subtitle pbp5" }, [
            _c("p", [_vm._v(_vm._s(_vm.subtitle))]),
          ])
        : _vm._e(),
      _vm.is_loaded.kpi_owner && this.title !== "Market View"
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.hide_contact_info,
                  expression: "!hide_contact_info",
                },
              ],
              staticClass: "subtitle",
            },
            [
              _c("b", [_vm._v("KPI Accountable")]),
              _vm._v(": " + _vm._s(_vm.kpi_owner) + " "),
            ]
          )
        : _vm._e(),
      _vm.is_loaded.kpi_contact && this.title !== "Market View"
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.hide_contact_info,
                  expression: "!hide_contact_info",
                },
              ],
              staticClass: "subtitle",
            },
            [
              _c("b", [_vm._v("KPI Contact")]),
              _vm._v(": " + _vm._s(_vm.kpi_contact) + " "),
            ]
          )
        : _vm._e(),
      !_vm.from_pbi && _vm.last_updated
        ? _c("div", { staticClass: "subtitle" }, [
            _c("b", [_vm._v("Last updated:")]),
            _vm._v(" " + _vm._s(_vm.last_updated) + " "),
          ])
        : _vm._e(),
      _vm.load_tracker_data && _vm.load_tracker_data.status_indicator
        ? _c("div", { staticClass: "subtitle" })
        : _vm._e(),
      _vm.show_view_more
        ? _c("div", [
            _vm.load_tracker_data && _vm.load_tracker_data.frequency_label
              ? _c("div", { staticClass: "subtitle" }, [
                  _c("b", [_vm._v("Frequency:")]),
                  _vm._v(
                    " " + _vm._s(_vm.load_tracker_data.frequency_label) + " "
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.load_tracker_data
        ? _c(
            "div",
            {
              staticClass: "view-more",
              on: {
                click: function ($event) {
                  _vm.show_view_more = !_vm.show_view_more
                },
              },
            },
            [
              _vm.show_view_more
                ? _c("span", [
                    _vm._v("View less "),
                    _c("i", { staticClass: "fas fa-chevron-up" }),
                  ])
                : _c("span", [
                    _vm._v("View more "),
                    _c("i", { staticClass: "fas fa-chevron-down" }),
                  ]),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }