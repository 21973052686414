<template>
<div class="container">
    Edit Question
    <input class="input mt" type="textinput" placeholder="Enter a question" v-model="question">
    <a class="button mt mr is-success" @click="update_question">Update Question</a>
    <a class="button mt" @click="back">Cancel</a>
</div>
</template>
<script>
export default {
    props: {
        id: {
            type: String,
            required: true
        }
    },
    data: function() {
        return {
            question: null
        }
    },
    mounted: function() {
        this.$store.state.commenting.width = 0;
        this.load_question();
    },
    methods: {
        load_question: function() {
            this.$http.post('force', '/nps/questions/question/load', {
                question_id: this.id
            })
            .then(
                succ => {
                    this.question = succ.data.question;
                },
                err => console.log(err)
            );
        },
        update_question: function() {
            if (!this.question || this.question.trim() === '') {
                alert('Cannot update a question to a blank field.');
                return;
            }
            this.$http.post('force', '/nps/questions/question/edit', {
                question_id: this.id,
                question: this.question
            }).then(
                succ => {
                    this.$router.push('/admin/nps/questions');
                }
            );
            
        },
        back: function() {
            this.$router.push('/admin/nps/questions')
        }
    }
}
</script>