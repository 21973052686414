<template>
    <div>
        <AdminUniversal
            component="title"
            :props="title_props"
        />
        <AdminUniversal
            component="textinput"
            :props="input_props1"
        />
        <AdminUniversal
            component="textinput"
            :props="input_props2"
        />
        <AdminUniversal
            component="dropdown"
            :props="dropdown_props"
        />
        <AdminUniversal
            component="button"
            :props="button_props"
        />
        <AdminUniversal
            v-if="table_props.render"
            component="table"
            :props="table_props"
        />
    </div>
</template>

<script>
import AdminUniversal from '@/components/admin/index.vue';

export default {
    components: {
        AdminUniversal
    },
    data: function() {
        return {
            title_props: {
                title: 'Help Links',
            },
            input_props1: {
                input: '',
                label: 'Name',
                placeholder: 'Link name',
                callback: this.update_input_name
            },
            input_props2: {
                input: '',
                label: 'Link',
                placeholder: 'Link url',
                callback: this.update_input_link
            },
            dropdown_props: {
                selection: '1',
                options: ['1', '2', '3', '4'],
                label: 'Priority',
                callback: this.update_dropdown_selection
            },
            button_props: {
                label: 'Add Help Link',
                classes: 'is-success',
                method: this.add_help_link
            },
            table_props: {
                buttons: [{
                    label: 'Delete',
                    classes: 'is-danger',
                    method: this.delete_help_link
                }],
                headers: ['ID', 'Name', 'Link', 'Priority', 'Actions'],
                data: null,
                render: false
            },

            form: {
                name: '',
                link: '',
                priority: ''
            }
        }
    },
    mounted: function() {
        this.load_kpis();
    },
    methods: {
        add_help_link: function() {
            if (!this.form.name || this.form.name.trim() === '') {
                alert('Please enter a name');
                return;
            }
            if (!this.form.link || this.form.link.trim() === '') {
                alert('Please enter a link');
                return;
            }
            if (!this.form.priority) {
                alert('Please select a priority level');
                return;
            }
            this.$http.post('/admin/help-links/add', {
                name: this.form.name,
                link: this.form.link,
                priority: parseInt(this.form.priority)
            }).then(
                succ => {
                    alert(succ.data.message);
                    this.name = this.link = null;
                    this.priority = '1'
                    this.load_kpis();
                },
                this.$helper.error
            );
        },
        delete_help_link: function(row) {
            const id = this.table_props.data[row].id;
            this.$http.post('/admin/help-links/delete', {
                link_id: id,
            }).then(
                succ => {
                    alert(succ.data.message);
                    this.load_kpis();
                },
                this.$helper.error
            );
        },
        load_kpis: function() {
            this.table_props.render = false;
            this.$http.get('/help-links/get').then(
                succ => {
                    if (succ.data) {
                        this.table_props.data = succ.data;
                    }
                    this.table_props.render = true;
                },
                this.$helper.error
            );
        },
        update_input_name: function(input) {this.form.name = input;},
        update_input_link: function(input) {this.form.link = input;},
        update_dropdown_selection: function(selection) {this.form.priority = selection;}
    }
}
</script>

<style>

</style>