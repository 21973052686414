var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("editor-menu-bar", {
        staticClass: "editor",
        attrs: { editor: _vm.editor },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ commands, isActive }) {
              return [
                _c("div", { staticClass: "editor-menu" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      class: { "is-active": isActive.bold() },
                      on: { click: commands.bold },
                    },
                    [_c("i", { staticClass: "fas fa-bold" })]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      class: { "is-active": isActive.italic() },
                      on: { click: commands.italic },
                    },
                    [_c("i", { staticClass: "fas fa-italic" })]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      class: { "is-active": isActive.underline() },
                      on: { click: commands.underline },
                    },
                    [_c("i", { staticClass: "fas fa-underline" })]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      class: { "is-active": isActive.strike() },
                      on: { click: commands.strike },
                    },
                    [_c("i", { staticClass: "fas fa-strikethrough" })]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      class: { "is-active": isActive.bullet_list() },
                      on: { click: commands.bullet_list },
                    },
                    [_c("i", { staticClass: "fas fa-list-ul" })]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      class: { "is-active": isActive.ordered_list() },
                      on: { click: commands.ordered_list },
                    },
                    [_c("i", { staticClass: "fas fa-list-ol" })]
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("editor-content", {
        staticClass: "editor-content",
        attrs: { id: "editor", editor: _vm.editor },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }