<template>
    <div>
        <nav id="navbar" class="navbar is-fixed-top" role="navigation" aria-label="main navigation">
            <div class="navbar-brand">
                <router-link class="navbar-item home-button" to="/">
                    <img src="/assets/images/home/IKEA-Text.svg" />
                    <div v-if="$store.state.selected.market === 'TI'" class="ml small-screen-disable">
                        Ingka Flight Tower
                    </div>
                    <div v-else class="ml small-screen-disable">Retail Flight Tower</div>
                </router-link>
                <a
                    role="button"
                    class="navbar-burger burger"
                    :class="{ 'is-active': mobile_menu_active }"
                    aria-label="menu"
                    aria-expanded="false"
                    data-target="navbarBasicExample"
                    @click="mobile_menu_active = !mobile_menu_active"
                >
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div class="navbar-menu" :class="{ 'is-active': mobile_menu_active }">
                <div
                    class="navbar-start"
                    v-if="$store.state.selected.market != 'TI'"
                    @click="mobile_menu_active = false"
                >
                    <KpiDropdown />

                    <KpiSearchField :menuItems="options" />

                    <router-link class="navbar-item" to="/sitemap" v-if="$store.state.global.legacy_menu">
                        KPIs
                    </router-link>

                    <MenuExtras class="extras-pc-disable" />

                    <div class="display-contents extras-mobile-disable">
                        <a class="navbar-item" @click="route_to(`/country-overview`)">
                            <div>Country Overview</div>
                        </a>
                        <a
                            class="navbar-item"
                            v-if="$store.getters.check_access(['country-insights', '*', 'r'])"
                            @click="route_to(`/country-insights/introduction/${$store.state.selected.market}`)"
                        >
                            <div>Insights</div>
                        </a>
                        <!-- <a class="navbar-item" v-if="$store.getters.check_access(['executive-summary', '*', 'r'])" @click="route_to(`/executive-summary`)">
                    <div>
                        Executive Summary
                    </div>
                </a> -->
                        <a
                            class="navbar-item"
                            v-if="false && $store.state.global.is_ift_user"
                            href="https://ingka.flight-tower.com/"
                        >
                            Ingka Flight Tower
                        </a>
                    </div>

                    <div class="navbar-item item-hover" @click="route_to('/exports')">Exports</div>
                </div>

                <!-- Navbar End -->
                <div class="navbar-end">
                    <div
                        class="navbar-item market-view-button ikea-blue"
                        v-if="
                            $store.state.selected.market != null &&
                            $store.state.selected.market != 'TI' &&
                            $store.getters.access_super_user != 0 &&
                            $store.getters.access_super_user != null
                        "
                    >
                        <a style="color: white" :href="getMarketViewUrl()">Market View</a>
                    </div>

                    <MainFilter />

                    <NotificationsAccordion />

                    <div class="navbar-item">
                        <HelpDropDown />
                    </div>
                </div>
            </div>
        </nav>

        <div v-if="$store.state.global.show_observations" :key="renderkey">
            <div
                class="observations-tab"
                v-show="this.commenting_width === 0 && can_see_observations_tab()"
                @click="toggle_comment_widget"
            >
                <i class="far fa-comment-dots"></i>
                Insights
            </div>
        </div>
    </div>
</template>

<script>
import { Bus } from '@/event-bus';
import KpiDropdown from '@/components/ui/KpiDropdown.vue';
import MainFilter from '@/components/bespoke/home/MainFilter.vue';
import MenuExtras from '@/components/ui/MenuExtras.vue';
import MenuOptions from '@/assets/scripts/value-creation-mapping.js';
import NotificationsAccordion from '@/components/ui/NotificationsAccordion.vue';
import HelpDropDown from '@/components/ui/HelpDropDown.vue';
import KpiSearchField from '@/components/ui/KpiSearchField.vue';
import TotalIngka from '@/components/ui/Total_Ingka.vue'

export default {
    components: {
        KpiDropdown,
        MenuExtras,
        MainFilter,
        NotificationsAccordion,
        HelpDropDown,
        KpiSearchField,
        TotalIngka
    },
    data: function() {
        return {
            bg_color: {
                'background-color': null,
                'border-color': null
            },
            location: null,
            mobile_menu_active: false,
            kpi: 'introduction',
            menu_items: null,
            renderkey: 0,
            black_list : [
                "null",
                "/",
                "/admin",
                "/access-summary",
                "/country-insights",
                "/executive-summary",
                "/country-overview",
                "/exports"
            ]
        }
    },
    created: function() {
        this.menu_items = [...MenuOptions];
    },
    mounted: function() {
        this.set_bg_color();
        // this.get_bookmarks();
        // Bus.$on('bookmarked', this.get_bookmarks); // Reactive bookmark list updating
    },
    methods: {
        getMarketViewUrl() {
      return '/business/market-view';
    },
        can_see_observations_tab: function() {
            let page = this.$helper.location().kpi;
            let can_see = true;
            this.black_list.forEach(item => {
                if (item.includes(page)) {
                    can_see = false;
                }
            });
            return can_see;
        },
        get_bookmarks: function() {
            this.$http.get('force', '/bookmarks').then(
                succ => {
                    if (!succ.data) return;
                    if (!succ.data.length) {
                        this.menu_items = [...MenuOptions, {
                            name: 'Favourites',
                            disabled: true,
                            areas: [{
                                name: 'Nothing yet added'
                            }]
                        }];
                        return;
                    }
                    let areas = succ.data.map(path => ({
                        name: this.$helper.page_name_lookup(path) || path,
                        path
                    }));
                    areas = areas.sort((a, b) => a.name < b.name ? -1 : 1);
                    this.menu_items = [...MenuOptions, {
                        name: 'Favourites',
                        areas
                    }];
                },
                this.$helper.error
            );
        },
        route_to: function(location) {
            this.$router.push(location);
        },
        set_bg_color: function() {
            let temp = this.$store.getters.location;
            if (!this.can_see_observations_tab()) return;

            let kpi = this.$helper.location().kpi;
            if (kpi in this.$store.state.colors) {
                let color = this.$store.state.colors['yellow'];
                this.bg_color = {
                    'background-color': color,
                    'border-color': color
                }
            }
        },
        toggle_comment_widget: function() {
            let black_list = [
                "null",
                "/",
                "/country-insights",
                "/executive-summary"
            ];

            let page = this.$helper.location().kpi;
            let black_listed = false;
            black_list.forEach(item => {
                if (item.includes(`${page}`)) {
                    black_listed = true;
                }
            });

            if (black_listed) {
                alert("You can only see observations on KPI pages.");
            } else {
                this.$store.state.commenting.width = localStorage['comment_width'] ?  localStorage['comment_width'] : 4;
            }
        },
        remove_disabled_kpis: function() {
            this.$http.get('/disabled-kpis/get').then(
                succ => {
                    if (!succ.data) return;
                    const disabled_kpis = succ.data.map(({ kpi, kpi_area, market_area, store_area }) => {
                        let path = `/${kpi}/${kpi_area}`;
                        if (market_area) path += `/${market_area}`;
                        if (store_area) path += `/${store_area}`;
                        return path;
                    });

                    const includes = (arr, ...items) => {
                        for (let item of items) {
                            if (arr.includes(item)) return true;
                        }
                        return false;
                    };

                    this.menu_items.forEach(kpi => kpi.areas = kpi.areas.filter(area => !disabled_kpis.includes(area.path)));

                    this.menu_items.forEach(kpi => kpi.areas.forEach(area => {
                        if (area.areas) {
                            area.areas = area.areas.filter(market => !includes(disabled_kpis, market.path, market.location));
                        }
                    }));
                },
                error => console.log(error)
            );
        }
    },
    computed: {
        options: function() {
            let order = {
                '3 Roads': 0,
                'Brand': 1,
                'Business': 2,
                'Customer': 3,
                'Finance': 4,
                'People': 5,
                'Sustainability': 6,
                'Process': 7
            };
            this.remove_disabled_kpis();
            if (this.menu_items) {
                this.menu_items.sort((a, b) => order[a.name] > order[b.name] ? 1 : -1);
            }
            return this.menu_items;
        },
        commenting_width: function() {
            return this.$store.state.commenting.width;
        },
        get_pbi_url: function() {
            return `${process.env.VUE_APP_PBI_URL}`+'\''+ this.$store.getters.market+'\'';
        }
    },
    watch: {
        '$route': function(n, o) {
            this.set_bg_color();
            this.$store.state.global.show_observations = this.can_see_observations_tab();
            this.renderkey++;
        }
    }
}
</script>

<style scoped>
@media screen and (max-width: 1228px) {
    .small-screen-disable {
        display: none;
    }
    .navbar-brand {
        width: 11rem !important;
    }
}

@media screen and (max-width: 1520px) {
    .extras-mobile-disable {
        display: none !important;
    }
}

@media screen and (min-width: 1521px) {
    .extras-pc-disable {
        display: none !important;
    }
}

@media screen and (max-width: 1024px) {
    #countries-select.navbar-dropdown {
        width: 100% !important;
    }

    .dropdown-menu {
        display: block !important;
        position: relative !important;
    }

    .dropdown-text {
        height: fit-content !important;
        padding-top: 0.6rem !important;
    }

    .dropdown-trigger {
        font-size: 1rem;
    }

    .globe-outline {
        border-radius: 1em;
        background-color: rgb(5, 214, 5);
        float: right;
        height: 1.9em;
        margin-right: 0.25em;
        margin-top: -0.2rem;
        width: 1.9em;
    }

    .mobile-bold {
        font-weight: bold;
    }

    .navbar-link:not(.is-arrowless)::after {
        border: none !important;
    }

    .navbar-link {
        padding: 0 0.5rem 0 0.5rem !important;
    }

    .navbar-dropdown .navbar-item {
        padding: 0.75rem !important;
    }

    .nested.navbar-item.dropdown {
        border: solid 1px #ccc;
        margin-bottom: 1em;
    }

    .nested.navbar-item.dropdown * {
        border: none;
        margin-bottom: 0;
    }

    .nested.navbar-item.dropdown #dropdown-menu * {
        font-size: 1rem;
    }

    .nested.dropdown .dropdown-menu {
        margin-left: 0;
        top: 0;
    }

    .pl-mobile {
        padding-left: 1.5rem !important;
    }
}

@media screen and (min-width: 1024px) {
    .navbar-dropdown {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        padding: 0 !important;
    }
    .navbar-dropdown .navbar-item {
        padding: 0.6rem 1rem !important;
    }
    .navbar-item.is-hoverable:hover .navbar-dropdown {
        display: block;
    }
    .navbar-item.is-hoverable .navbar-dropdown {
        display: none;
    }
    .nested.dropdown .dropdown-menu {
        margin-left: 100%;
        top: -15px;
    }
}

.item-hover:hover {
    background-color: #fafafa;
    color: #3273dc;
}
.navbar-burger {
    position: absolute;
    right: 0;
}

.navbar {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.navbar-brand {
    width: 21rem;
}

.home-button {
    background-color: #0051ba !important;
    color: #fff !important;
}

.market-view-button {
    border: none;
    border-radius: 0;
    color: #0051ba;
    height: 2rem;
    top: 0.8rem;
    color: white;
}

.market-view-button a {
    color: #fff !important;
}

.market-view-button a:hover {
    color: #fff !important;
}

#navbar {
    max-width: 100vw;
}

.IKEA-logo-text {
    background: src('public/assets/images/home/IKEA-Text.svg');
}

.color-inherit {
    color: inherit !important;
}

.disabled {
    background-color: #e2e2e2;
    color: #7a7a7a;
}

.dropdown-content {
    border-radius: 0px !important;
    width: fit-content;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.dropdown-item {
    background-color: inherit;
    color: inherit;
    font: inherit;
    padding: 0.57rem 1rem;
}

.dropdown-item:hover {
    background-color: #e2e2e2 !important;
}

.dropdown-menu {
    background-color: #fff !important;
    padding-top: 0 !important;
}

.dropdown-text {
    height: 2.5rem;
    padding-top: 0.6rem !important;
}

.mtp95 {
    margin-top: 0.95rem !important;
}

.navbar-item {
    cursor: pointer !important;
}

.navbar-item.router-link-exact-active.router-link-active {
    background-color: #fff;
    color: #0051ba;
}

.nested.dropdown:hover > .dropdown-menu {
    display: block;
}

.nested.dropdown .dropdown-trigger button {
    border: 0px;
    font-size: 14px;
    font-weight: 400;
    height: 2em;
    padding: 0px 0px;
}

.nested.navbar-item.dropdown:hover {
    background-color: #e2e2e2 !important;
    display: block;
}

.nested.navbar-item.dropdown .router-link-exact-active.router-link-active {
    background-color: inherit;
    color: inherit;
}

.no-background {
    background-color: inherit !important;
}

.observations:hover {
    background-color: #fafafa;
    color: #3273dc;
}

.observations-tab {
    background-color: #0051ba;
    border-left: 0;
    border-radius: 0px 5px 5px 0px;
    color: #fff;
    padding: 0.5rem 0;
    position: fixed;
    right: 0rem;
    transform: rotate(180deg);
    transition: all 0.2s;
    top: calc(50% - 1rem);
    writing-mode: vertical-lr;
    z-index: 999;
}

.observations-tab i {
    transform: rotate(90deg);
}

.observations-tab:hover {
    cursor: pointer;
    padding-left: 1rem;
}

.matched {
    color: #0051ba;
}
</style>
