var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "buttons" }, [
        _c(
          "a",
          {
            staticClass: "button",
            class: {
              "is-success": _vm.set_read_all,
              "is-danger": !_vm.set_read_all,
            },
            on: { click: _vm.read_all },
          },
          [_vm._v("Read All")]
        ),
        _c(
          "a",
          {
            staticClass: "button",
            class: {
              "is-success": _vm.set_write_all,
              "is-danger": !_vm.set_write_all,
            },
            on: { click: _vm.write_all },
          },
          [_vm._v("Write All")]
        ),
      ]),
      _c("Node", {
        key: _vm.render,
        attrs: { node: _vm.template, path: [] },
        on: { update: _vm.update },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }