<template>
<div class="kpi-box">
    <div v-for="(update_frequency, i) in frequency" :key="i">
        <table class="table is-striped is-fullwidth is-narrow" v-if="kpi_data && kpi_data[update_frequency]">
            <thead>
                <tr class="ikea-yellow">
                    <th
                        v-for="(header, key) in [{label: update_frequency, value: 1}, ...headers(update_frequency)[0]]"
                        :key="key"
                        :colspan="header.value"
                    >
                        {{header.label}}
                    </th>
                </tr>
            </thead>
            
            <tbody>
                <template v-for="data_grouped_by_headers in chunk_data(update_frequency)">
                    <tr v-if="data_grouped_by_headers[0].headers[1]">
                        <td></td>
                        <td v-for="(sub_header, key) in data_grouped_by_headers[0].headers[1]" :key="key"
                            class="sub-header"
                        >
                            {{sub_header.label}}
                        </td>
                    </tr>
                    <tr
                        v-for="(row, key) in data_grouped_by_headers"
                        :key="key"
                    >
                        <td 
                            class="name-column"
                            :class="`${row.version ? 'ft-tooltip' : '' }`"
                            :tooltiptext="row.version || ''"
                        >
                            {{row.name}}
                        </td>
                        <td v-for="(value, index) in row.data_keys"
                            :key="index"
                            class="data-column text-left"
                        >
                            <div v-if="`icon_${value}` in row.icon_values" class="inline-flex">
                                <div>{{$helper.round(row.measures[value], null, ',')}}{{row.measures[value] ? row.append : ''}}</div>
                                <div v-html="icon_logic(row.icon_values, value)"></div>
                            </div>
                            <div v-else>
                                {{$helper.round(row.measures[value], null, ',')}}{{row.measures[value] ? row.append : ''}}
                            </div>
                        </td>
                    </tr>
                </template>

            </tbody>
        </table>
    </div>
</div>
</template>

<script>
export default {
    props: {
        kpi_data: undefined,
        versions: Object,
        title: String
    },
    data: function() {
        return {
            frequency: ['Weekly', 'Monthly', 'Tertial', 'Yearly'],
        }
    },
    methods: {
        chunk_data: function(period) {
            if (!this.kpi_data[period]) return this.kpi_data[period];
            let headers_buckets = [];
            let data_by_headings = [];
            this.kpi_data[period].forEach(row => {
                const headers_str = JSON.stringify(row.headers[1]);
                if (!headers_buckets.includes(headers_str)) headers_buckets.push(headers_str);
                const bucket_index = headers_buckets.indexOf(headers_str);
                data_by_headings[bucket_index] = data_by_headings[bucket_index] ? [...data_by_headings[bucket_index], row] : [row];
            });
            return data_by_headings;
        },
        headers: function(update_frequency) {
            if (this.kpi_data && this.kpi_data[update_frequency]) {
                return this.kpi_data[update_frequency][Object.keys(this.kpi_data[update_frequency])[0]].headers;
            } else {
                return [[],[]];
            }
        },
        icon_logic: function(icon_values, value_name) {
            if (!icon_values) return;
            let color;
            let direction;
            let icon_type = icon_values[`icon_${value_name}`];
            let icon_value = icon_values[`icon_value_${value_name}`];
            let icon_label = icon_values[`icon_label_${value_name}`];
            let label = icon_label || null;

            switch (icon_value) {
                case 1:
                    color = 'success';
                    direction = 'up';
                    break;
                case -1:
                    color = 'danger';
                    direction = 'down';
                    break;
                case 0: 
                    return `
                        <span class="icon has-text-info ${label ? 'ft-tooltip' : ''}" ${`tooltiptext="${label}"`}>
                            <i class="fas fa-minus"></i>
                        </span>
                    `
                default:
                    return `
                        <span class="icon invisible">
                            <i class="fas fa-circle"></i>
                        </span>
                    `;
            }

            return `
                <span class="icon has-text-${color} ${label ? 'ft-tooltip' : ''}" ${`tooltiptext="${label}"`}>
                    <i class="fas fa-${icon_type}${icon_type === 'arrow' ? `-${direction}` : ''}"></i>
                </span>
            `;
        }
    }
}
</script>

<style scoped>
.bigger-icon {
    font-size: 1.6em;
}

.kpi-box .icon {
    background-color: #FFF;
    border-radius: 100%;
    height: 3em;
    left: calc(50% - 1.5em);
    position: absolute;
    width: 3em;
}

.kpi-box .header {
    height: 3em;
    position: relative;
}

.kpi-box .header .title {
    font-size: 1.2em;
    letter-spacing: 1px;
    margin: auto 0.2em;
    padding: 0.1em;
    text-transform: uppercase;
}

.kpi-box .body {
    background-color: #FFF;
    height: calc(100% - 3em);
}

.kpi-names {
    text-align: left !important;
}

.set-width {
    width: 4em;
}

.table td {
    border: none !important;
}

tbody tr:nth-child(odd) {
    background-color: #f5f5f5;
}

.white-background {
    background-color: #FFF;
}

th {
    border: none !important;
    font-weight: bold;
}
.sub-header {
    font-size: 0.9rem;
    font-weight: bold !important;
    text-align: center;
}
.data-column {
    text-align: right;
}
</style>
