<template>
<div>
    <Title
        title="Food Sales"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container">
            <p>Work station</p>
            <DropDown
                :key="filter.work_station.state"
                :options="filter.work_station.options"
                :values="filter.work_station.values"
                :default="filter.work_station.state"
                @updateDropDown="update_filter_work_station"
            />
        </div>

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <Table
        v-if="table.render"

        :headers="table.headers"
        :rows="table.rows"
        :decimal-settings="table.decimal_settings"
        :icon-settings="table.icon_settings"
        :font-settings="{ bold: {row: {regex: 'Total' } } }"
        :sort-settings="{ 'Market-Area ': { asc: true } }"
        :metaSettings="{is_selectable: false}"
        class="ms1p5"
    />
    <div v-else>The is no data for {{$store.getters.selected_country_name}}</div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';


export default {
    props: {
        market: String
    },
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            filter: {
                work_station: {
                    state: null,
                    options: [],
                    values: []
                }
            },
            table: {
                headers: [],
                rows: [],
                decimal_settings: {},
                render: false
            },
            location: '/business/sales-per-range/food-sales'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
                {
                    name: 'By Market',
                    link: `${this.location}/by-market/${this.$store.getters.market}`
                },
                {
                    name: 'Tree',
                    link: `${this.location}/tree/${this.$store.getters.market}`
                },
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'food-sales-work-station',
                disable_sort: true
            }).then(
                succ => {
                    succ.data.forEach(filter => {
                        this.filter.work_station.values.push(filter.value);
                        this.filter.work_station.options.push(filter.option);
                    });
                    this.filter.work_station.state = this.$store.getters.filter_check(this.filter.work_station.values); 
                },
                this.$helper.error
            );
        },
        get_data: function() {
            this.table.render = false;
            if (!this.filter.work_station.state || !this.$store.getters.market) return;
            this.$http.post('/business/sales-per-range/food-sales/by-market', {
                market: this.$store.getters.market,
                work_station_filter: this.filter.work_station.state
            }).then(
                succ => {
                    let d = succ.data;
                    if (!d) return;
                    let keys = Object.keys(d[0].measures);
                    if (d[0].measure_metadata) {
                        this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                    }
                    this.table.headers = [['Market-Area'].concat(keys).map(c => {
                        return {
                            [c]: 1
                        }
                    })];
                    this.table.rows = d.map(row => {
                        let temp = [];
                        // If the key/values don't exist, set them to 0
                        keys.map(key => {
                            temp.push(this.$helper.maybe(row.measures[key], null));
                        });
                        return [row.caption].concat(temp);
                    });
                    this.table.icon_settings = {
                        'YTD Index': {
                            icon: 'circle',
                            comparison: 'Year Goal',
                        },
                        '4WK Index': {
                            icon: 'arrow',
                            comparison: 'YTD Index'
                        },
                        'Take-up rate': {
                            fix: {
                                post: '%'
                            }
                        }
                    }
                    
                    this.$helper.header_replace(this.table.headers, {
                        'sales_meuro_ytd': 'YTD MEUR',
                        'ytd_index': 'YTD Index',
                        'wk4_index': '4WK Index',
                        'ytd_growth_meuro': 'YTD Growth MEUR',
                        'goal': 'Year Goal',
                        'food_share': 'Sales Share',
                        'take_up_rate': 'Take-up rate'
                    });

                    if ('R4 Index' in this.table.decimal_settings) {
                        this.table.decimal_settings['4WK Index'] = this.table.decimal_settings['R4 Index'];
                    }
                    if ('Index YTD' in this.table.decimal_settings) {
                        this.table.decimal_settings['YTD Index'] = this.table.decimal_settings['Index YTD'];
                    }
                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        update_filter_work_station: function(work_station_filter) {
            this.filter.work_station.state = work_station_filter;
            this.$store.state.global.temp_work_station_filter = work_station_filter;
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: `${this.location}/by-market`
            });
        }
    },
    watch: {
        '$store.getters.countries': function(newCountries, oldCountries) {
            this.$store.commit('update_market', this.market);	
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.get_data();
        },
        'filter.work_station.state': function() {
            this.get_data();
        }
    }
}
</script>
