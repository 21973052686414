<template>
<div>
    <TableV2
        :headers="[
            [
                { 'A': 2 },
                { 'B': 6 },
                { 'C': 2 },
            ],
            [
                { 'AA': 2 },
                
                { 'BA': 2 },
                { 'BB': 2 },
                { 'BC': 2 },

                { 'CA': 1 },
                { 'CB': 1 },
            ],
            [
                { 'AAA': 1},
                { 'AAB': 1},

                { 'BAA': 1},
                { 'BAA': 1},
                { 'BBA': 1},
                { 'BBB': 1},
                { 'BCA': 1},
                { 'BCB': 1},

                { 'CAA': 1},
                { 'CBA': 1},
            ],
            [
                { 'AAAA': 1},
                { 'AABA': 1},

                { 'BAAA': 1},
                { 'BAAA': 1},
                { 'BBAA': 1},
                { 'BBBA': 1},
                { 'BCAA': 1},
                { 'BCBA': 1},

                { 'CAAA': 1},
                { 'CBAA': 1},
            ],
            [
                { 'Country': 1},
                { 'AABA': 1},

                { 'BAAA': 1},
                { 'BAAA': 1},
                { 'BBAA': 1},
                { 'BBBA': 1},
                { 'BCAA': 1},
                { 'BCBA': 1},

                { 'CAAA': 1},
                { 'CBAA': 1},
            ]
        ]"

        :rows="[
            ['Australia', 2.312, 3, 10, 2.312, 3, 10, 2.312, -1, 10],
            ['UK', 5.1, 6, 4.21, 5.1, 16, 4.21, 5.1, 1, 9],
            ['Denmark', 8, 9, 1, 2, 3, 4, 5, 2, 1],
            ['Total Retail Countries', 8, 9, 1, 2, 3, 4, 5, 2, 1],
        ]"

        :decimalSettings="{
            global: 2,
            ignore: [0],
            'B': {
                precision: 8
            }
        }"

        :fontSettings="{
            bold: {
                row: {
                    regex: 'Total Retail Countries'
                },
                col: {
                    regex: 'Country'
                }
            }
        }"

        :iconSettings="{
            'AABA': {
                fix: {
                    pre: '£',
                    post: 'p'
                }
            },
            'BBBA': {
                comparison: 'BCBA',
                icon: 'circle',
                inverted: false,
                fix: {
                    pre: '-',
                    post: '*'
                },
                colors: {
                    positive: '#0F0',
                    equal: '#00F',
                    negative: '#F00',
                }
            },
            'CAAA': {
                icon: 'bar',
            }
        }"

        :sortSettings="{
            'AAB': {
                asc: true
            }
        }"

        :spaceSettings="{
            'A': {
                side: 'right'
            },
            'B': {
                side: 'left'
            },
            'C': {
                side: 'left'
            }
        }"

        :wrapSettings="{
            global: 5, // Rem size
        }"
    />

    <Tree />
</div>
</template>

<script>
import TableV2 from '@/components/visualisations/table/tablev2/TableV2.vue';
import Tree from '@/components/bespoke/trees/SalesTree.vue';

export default {
    components: {
        TableV2,
        Tree
    },
    data: function() {
        return {
            document: document
        }
    },
    methods: {
    }
}
</script>

<style>
.spacing-left {
    border-left: 1rem solid #FFF !important;
}
</style>    
