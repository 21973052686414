import CountrySelectView from '@/views/country-select/CountrySelectView.vue';
import CountrySelectOnly from '@/views/country-select/CountrySelectOnly.vue';
export default [
    {
        path: '/country-select/:path',
        component: CountrySelectView,
        props: true
    },
    {
        path: '/country-select-only/:path',
        component: CountrySelectOnly,
        props: true
    }
];