<template>
<div>
    <Title
        title="Sales and Quantity per Country"
        :location="location"
    />

    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
        
        <div class="label-container" 
            :class="!$store.getters.market ? 'ft-tooltip': null" 
            :tooltiptext="!$store.getters.market ? 'Select a country from top filter to see stores' : null"
        >
            <p>Market Area</p>
            <DropDown
                :key="stores.values.length"
                :options="stores.options"
                :values="stores.values"
                :default="stores.values[0]"
                @updateDropDown="update_store"
            />
        </div>
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" id="sales-and-quantity-trend" class="section three-quarters-height box ms">
        <DeviationWith3Lines
            v-if="triple.render"

            :title="`Showing data for: ${$store.getters.selected_country_name}`"
            :showtitle="!$store.state.global.bot_mode"

            subtitle=""
            :xcategories="triple.xcategories"
            yaxislabel1="Sales & Sales Quantity index"
            ymetric1=""
            yaxislabel2="Gap Qty and sales YTD Index "
            ymetric2=""
            :yaxismin1="-150"
            :yaxismax1="150"

            :yaxismin2="-15"
            :yaxismax2="15"

            seriesname1="Gap Qty and sales YTD Index"
            seriestype1="bar"
            :seriesset1="triple.seriesset3"
            :color1="$store.state.colors['dark-grey']"

            seriesname2="Sales YTD Index"
            seriestype2="line"
            :seriesset2="triple.seriesset1"
            :color2="$store.state.colors['focus-yellow']"
           
            seriesname3="Qty YTD Index"
            seriestype3="line"
            :seriesset3="triple.seriesset2"
            :color3="$store.state.colors['light-grey']"

            seriesname4="Goal"
            seriestype4="line"
            :seriesset4="triple.seriesset4"
            symbol4="none"
            :color4="$store.state.colors.YG"
            
            :show="!$store.state.global.bot_mode"
        />                                        
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import DeviationWith3Lines from '@/components/visualisations/general/DeviationWith3Lines.vue'
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        DeviationWith3Lines,
        Title
    },
    props: {
        market: String
    },
    data: function() {
        return {
            triple: {
                title: null,
                subtitle: null,

                xcategories: null,
                yaxislabel: null,
                ymetric: null,
                
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],
                seriesset4: [],
                
                render: false
            },
            store: null,
            location: '/business/total-sales/sales-and-quantity-per-country'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.load_market_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
            ];
        },
        load_market_data: function() {
            let market = this.store;
            let parent = this.$store.getters.market;
            if (!this.$store.getters.market && !this.store) {
                market = 'ALL';
                parent = null;
            } else if (!this.store) {
                market = this.$store.getters.market;
                parent = 'ALL'
            }

            this.$http.post('/business/total-sales/sales-and-pieces-per-country/market', {
                market,
                parent
            }).then(
                succ => {
                    let d = succ.data;
                    this.triple.xcategories = Object.keys(d[0].measures);
                    this.triple.seriesset1 = [];
                    this.triple.seriesset2 = [];
                    this.triple.seriesset3 = [];
                    this.triple.seriesset4 = [];
                    this.triple.xcategories.map(item => {
                        let val = d[0].measures[item];
                        this.triple.seriesset1.push(this.$helper.maybe(val['Sales YTD Index']));
                        this.triple.seriesset2.push(this.$helper.maybe(val['Qty YTD Index']));
                        this.triple.seriesset3.push(this.$helper.maybe(val['Diff YTD Index (Qty-Sales)']));
                        if (val['Goal'] != null) {
                            this.triple.seriesset4.push(this.$helper.maybe(val['Goal']));
                        }
                    });
                    this.triple.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location,
            });
        },
        update_store: function(store) {
            this.store = store;
            this.triple.render = false;
            this.load_market_data();
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.store = null;
            this.triple.render = false;
            this.load_market_data();
        }
    },
    computed: {
        stores: function() {
            if (this.$store.getters.stores.values.length) {
                return this.$store.getters.stores;
            } else {
                return {
                    all: [],
                    options: [],
                    values: []
                }
            }
        }
    }
}
</script>

<style>

</style>
