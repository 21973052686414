var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "full-page" },
    [
      _c("div", { staticClass: "title mt" }, [_vm._v("Announcements")]),
      _vm._l(_vm.announcements, function (announcment, i) {
        return _c(
          "div",
          { key: i, staticClass: "box", staticStyle: { margin: "1rem" } },
          [
            _c("div", { staticClass: "ms pbp5" }, [
              _vm._v(_vm._s(announcment.main)),
            ]),
            _c("img", {
              staticClass: "image-resizing",
              attrs: { src: announcment.image },
            }),
            announcment.sub
              ? _c("div", { staticClass: "ms" }, [
                  _vm._v(_vm._s(announcment.sub)),
                ])
              : _vm._e(),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }