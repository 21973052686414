<template>
<div>
    <div class="table-container">
    <input class="input mb" placeholder="Search KPIs" v-model="search_str"/>
        <table class="table is-fullwidth">
            <thead>
                <tr class="business">
                    <th v-for="(header, i) in headers" :key="i">{{header}}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, j) in filtered_table_data" :key="j">
                    <td v-for="(property, k) in keys" :key="k">{{row[property]}}</td>
                    <td v-if="buttons.length > 0">
                        <span v-for="(button, m) in buttons" :key="m">
                            <button @click="button.method(row.id)" :class="button.classes" class="button mr">
                                {{button.label}}
                            </button>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</template>

<script>
export default {
    props: {
        buttons: {
            type: Array,
            default: () => []
        },
        data: Array,
        headers: Array,
        keys: Array,
        render: Boolean
    },
    /** Example prop:
     * {
     *   buttons: [
     *     {
     *       label: 'Delete',
     *       classes: 'is-danger',
     *       method: () => {myFunc}
     *     }
     *   ],
     *   data: [
     *     {...},
     *     {...}
     *   ],
     *   headers: ['a', 'b', 'c'],
     *   keys: ['id', 'name', 'date'],
     *   render: true //unused, should be handled at parent level
     * }
     */
    data: function() {
        return {
            search_str: '',
        }
    },
    computed: {
        filtered_table_data: function() {
            if (!this.data) return [];
            let rows = this.data.sort((a, b) => a.id > b.id ? 1 : -1);
            if (!!this.search_str) rows = rows.filter(kpi => kpi.report.toLowerCase().includes(this.search_str.toLowerCase()));
            return rows;
        }
    },
}
</script>

<style scoped>
.table-container {
    max-width: 768px;
}
</style>
