<template>
<div>
    <Title
        title="Sales Trend Total"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" id="sales-trend-total-trend" class="section three-quarters-height box ms">
        <Graph
            v-if="graph.render"
            title="Sales per Week"
            :show-title="!$store.state.global.bot_mode"
            v-bind="graph"
        />
        <div v-else> No data found for {{$store.getters.market}}</div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Graph from '@/components/visualisations/general/Graph.vue';
import Title from '@/components/ui/Title.vue';
import Triple from '@/components/visualisations/general/Triple.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Graph,
        Title,
        Triple
    },
    props: {
        market: String
    },
    data: function() {
        return {
            store: null,
            filter: null,
            options: [],
            values: [],
            location: '/business/total-sales/sales-trend-total',
            graph: {
                x_categories: [ ],
                x_axis_label_interval: "1",
                y_axis_label: 'MEUR',
                y_metric: '',
                disabled_labels: [false, false, true, true, false],
                legend: {  
                    top: '30rem',
                    left: '0rem',
                    selected: this.legend_selected ? this.legend_selected : {},
                    data: [
                        {
                            name: 'Online + Remote LY', icon: 'square'
                        },
                        {
                            name: 'Store LY', icon: 'square'
                        },
                        {
                            name: 'Online + Remote TY', icon: 'square'
                        },
                        {
                            name: 'Store TY', icon: 'square'
                        },
                        {
                            name: 'Goal', icon: 'square'
                        }
                    ],
                    itemGap: 15,
                    itemWidth: 14,
                    itemHeight: 14,
                    padding: [-1, 10],
                },
                names: ['Online + Remote LY', 'Store LY', 'Online + Remote TY', 'Store TY', 'Goal'],
                data_sets: [
                ],
                styling: [
                    {
                        areaStyle: {},
                        showSymbol: false,
                        z: 1,
                        lineStyle: {
                            width: 0
                        }
                    },
                    {
                        areaStyle: {},
                        showSymbol: false,
                        z: 1,
                        lineStyle: {
                            width: 0
                        }
                    },
                    {
                        z: 2,
                    },
                    {
                        z: 2,
                    },
                    {
                        smooth: true,
                        z: 3,
                        showSymbol: true,
                    }
                ],
                types: ['line', 'line', 'bar', 'bar', 'line'],
                stack: [2, 2, 1, 1, 0],
                colors: [
                    '#929292',
                    '#bcbcbc',
                    '#FDDB2A',
                    '#fecc0082',
                    '#000000'
                ],
                render: false
            },
            location: `/business/total-sales/sales-trend-total`,
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        this.load_market_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'Summary',
                    link: `${this.location}/summary/${this.$store.getters.market}`
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'By Market',
                    link: `${this.location}/by-market/${this.$store.getters.market}`
                },
            ];
        },
        load_market_data: function() {
            let market = this.store;
            if (!market) 
                market = (!this.$store.getters.market && !this.store) ? 'ALL' : this.$store.getters.market;

            this.$http.post('/business/total-sales/sales-trend-total/trend', {
                market,
            }).then(
                succ => {       
                    let d = succ.data;
                    if (!d) return;
                    this.graph.x_categories = Object.keys(d[0].data);

                    let seriesset1 = [];
                    let seriesset2 = [];
                    let seriesset3 = [];
                    let seriesset4 = [];
                    let seriesset5 = [];

                    this.graph.x_categories.map(week => {
                        let val = d[0].data[week];
                        seriesset1.push(this.$helper.maybe(val['Online + Remote LY']));
                        seriesset2.push(this.$helper.maybe(val['Store LY']));
                        seriesset3.push(this.$helper.maybe(val['Online + Remote TY']));
                        seriesset4.push(this.$helper.maybe(val['Store TY']));
                        seriesset5.push(this.$helper.maybe(val['Goal']));
                        let tmp = [];
                    });

                    this.graph.data_sets = [ seriesset1, seriesset2, seriesset3, seriesset4, seriesset5 ];

                    this.graph.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location,
            });
        },
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.graph.render = false;
            this.load_market_data();
        }
    },
    computed: {
        stores: function() {
            if (this.$store.getters.stores.values.length) {
                return this.$store.getters.stores;
            }
            return {
                all: [],
                options: [],
                values: []
            };
        }
    }
}
</script>

<style>

</style>
