var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.render
    ? _c(
        "div",
        [
          _vm._l(_vm.boxes, function (box, i) {
            return _c(
              "div",
              { key: i },
              [
                i === 3
                  ? _c("svg", { attrs: { width: "100%", height: "100%" } }, [
                      _c("rect", {
                        attrs: {
                          x: `${box.x}%`,
                          y: `${box.y}%`,
                          width: `${box.width}%`,
                          height: "3%",
                          stroke: "#dddddd",
                          "stroke-width": "1",
                          fill: "#dddddd",
                        },
                      }),
                      _c("rect", {
                        attrs: {
                          x: `${box.x}%`,
                          y: `${box.y + 3}%`,
                          width: `${box.width}%`,
                          height: `${box.height - 3}%`,
                          stroke: "#dddddd",
                          "stroke-width": "1",
                          fill: "white",
                        },
                      }),
                    ])
                  : _c("svg", { attrs: { width: "100%", height: "100%" } }, [
                      _c("rect", {
                        attrs: {
                          x: `${box.x}%`,
                          y: `${box.y}%`,
                          width: `${box.width}%`,
                          height: "3%",
                          stroke: "#dddddd",
                          "stroke-width": "1",
                          fill: "#dddddd",
                        },
                      }),
                      _c("rect", {
                        attrs: {
                          x: `${box.x}%`,
                          y: `${box.y + 3}%`,
                          width: `${box.width}%`,
                          height: `${box.height - 3}%`,
                          stroke: "#dddddd",
                          "stroke-width": "1",
                          fill: "white",
                        },
                      }),
                    ]),
                _vm._l(box.data, function (row, j) {
                  return _c("div", { key: j }, [
                    i === 3
                      ? _c(
                          "svg",
                          {
                            attrs: {
                              width: "100%",
                              height: "100%",
                              "font-size": "0.8rem",
                            },
                          },
                          [
                            _c(
                              "text",
                              {
                                attrs: {
                                  x: `${box.x + 0.5}%`,
                                  y: `${box.y + 2.2 + 2.5 * j}%`,
                                  "text-anchor": "start",
                                  "font-weight": j === 0 ? "bold" : "normal",
                                  fill: "black",
                                },
                              },
                              [_vm._v(" " + _vm._s(row[0]) + " ")]
                            ),
                            _c(
                              "text",
                              {
                                attrs: {
                                  x: `${box.x + box.width - 0.5}%`,
                                  y: `${box.y + 2.2 + 2.5 * j}%`,
                                  "font-weight": j === 0 ? "bold" : "normal",
                                  "text-anchor": "end",
                                  fill: "black",
                                },
                              },
                              [_vm._v(" " + _vm._s(row[1]) + " ")]
                            ),
                          ]
                        )
                      : _c(
                          "svg",
                          {
                            attrs: {
                              width: "100%",
                              height: "100%",
                              "font-size": "0.8rem",
                            },
                          },
                          [
                            _c(
                              "text",
                              {
                                attrs: {
                                  x: `${box.x + 0.5}%`,
                                  y: `${box.y + 2.3 + 2.5 * j}%`,
                                  "text-anchor": "start",
                                  "font-weight": j === 0 ? "bold" : "normal",
                                  fill: "black",
                                },
                              },
                              [_vm._v(" " + _vm._s(row[0]) + " ")]
                            ),
                            _c(
                              "text",
                              {
                                attrs: {
                                  x: `${box.x + 0.765 * box.width}%`,
                                  y: `${box.y + 2.3 + 2.5 * j}%`,
                                  "font-weight": j === 0 ? "bold" : "normal",
                                  "text-anchor": "end",
                                  fill: "black",
                                },
                              },
                              [_vm._v(" " + _vm._s(row[1]) + " ")]
                            ),
                            _c(
                              "text",
                              {
                                attrs: {
                                  x: `${box.x + box.width - 0.5}%`,
                                  y: `${box.y + 2.3 + 2.5 * j}%`,
                                  "font-weight": j === 0 ? "bold" : "normal",
                                  "text-anchor": "end",
                                  fill: "black",
                                },
                              },
                              [_vm._v(" " + _vm._s(row[2]) + " ")]
                            ),
                          ]
                        ),
                  ])
                }),
                _c("svg", { attrs: { width: "100%", height: "100%" } }, [
                  i < 3
                    ? _c("line", {
                        attrs: {
                          x1: `${box.x + box.width / 2}%`,
                          y1: `${box.y + _vm.box_height}%`,
                          x2: `${box.x + box.width / 2}%`,
                          y2: `${
                            box.y +
                            _vm.box_height / 2 +
                            _vm.tree_level_heights[1] / 2
                          }%`,
                          stroke: "black",
                          "stroke-width": "2",
                        },
                      })
                    : _vm._e(),
                  i > 0
                    ? _c("line", {
                        attrs: {
                          x1: `${box.x + box.width / 2}%`,
                          y1: `${
                            box.y +
                            _vm.box_height / 2 -
                            _vm.tree_level_heights[1] / 2
                          }%`,
                          x2: `${box.x + box.width / 2}%`,
                          y2: `${box.y}%`,
                          stroke: "black",
                          "stroke-width": "2",
                        },
                      })
                    : _vm._e(),
                  i > 0
                    ? _c("line", {
                        attrs: {
                          x1: `${box.x + box.width / 2 - 0.5}%`,
                          y1: `${box.y - 1}%`,
                          x2: `${box.x + box.width / 2}%`,
                          y2: `${box.y}%`,
                          stroke: "black",
                          "stroke-width": "2",
                        },
                      })
                    : _vm._e(),
                  i > 0
                    ? _c("line", {
                        attrs: {
                          x1: `${box.x + box.width / 2 + 0.5}%`,
                          y1: `${box.y - 1}%`,
                          x2: `${box.x + box.width / 2}%`,
                          y2: `${box.y}%`,
                          stroke: "black",
                          "stroke-width": "2",
                        },
                      })
                    : _vm._e(),
                ]),
              ],
              2
            )
          }),
          _c("svg", { attrs: { width: "100%", height: "100%" } }, [
            _c("line", {
              attrs: {
                x1: `${_vm.boxes[1].x + _vm.box_width / 2}%`,
                y1: `${
                  _vm.boxes[0].y +
                  _vm.box_height / 2 +
                  _vm.tree_level_heights[1] / 2
                }%`,
                x2: `${_vm.boxes[2].x + _vm.box_width / 2}%`,
                y2: `${
                  _vm.boxes[0].y +
                  _vm.box_height / 2 +
                  _vm.tree_level_heights[1] / 2
                }%`,
                stroke: "black",
                "stroke-width": "2",
              },
            }),
            _c("line", {
              attrs: {
                x1: `${_vm.boxes[3].x + _vm.box_4_width / 2}%`,
                y1: `${
                  _vm.boxes[3].y +
                  _vm.box_height / 2 -
                  _vm.tree_level_heights[1] / 2
                }%`,
                x2: `${_vm.boxes[4].x + _vm.box_width / 2}%`,
                y2: `${
                  _vm.boxes[3].y +
                  _vm.box_height / 2 -
                  _vm.tree_level_heights[1] / 2
                }%`,
                stroke: "black",
                "stroke-width": "2",
              },
            }),
            _c("line", {
              attrs: {
                x1: `${_vm.boxes[5].x + _vm.box_width / 2}%`,
                y1: `${
                  _vm.boxes[3].y +
                  _vm.box_height / 2 -
                  _vm.tree_level_heights[1] / 2
                }%`,
                x2: `${_vm.boxes[6].x + _vm.box_width / 2}%`,
                y2: `${
                  _vm.boxes[3].y +
                  _vm.box_height / 2 -
                  _vm.tree_level_heights[1] / 2
                }%`,
                stroke: "black",
                "stroke-width": "2",
              },
            }),
          ]),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }