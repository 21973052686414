export default [
    {
        path: '/brand/desire',
        name: 'desire'
    },
    {
        path: '/brand/desire/top-8',
        name: 'desire'
    },
    {
        path: '/brand/desire/:market',
        name: 'desire'
    },
    {
        path: '/brand/trust',
        name: 'trust'
    },
    {
        path: '/brand/trust/top-8',
        name: 'trust'
    },
    {
        path: '/brand/trust/:market',
        name: 'trust'
    },
    {
        path: '/business/availability',
        name: 'availability'
    },
    {
        path: '/business/availability/detail',
        name: 'availability'
    },
    {
        path: '/business/product-availability',
        name: 'ava-2'
    },
    {
        path: '/business/product-availability/detail',
        name: 'ava-2'
    },
    {
        path: '/business/stock-levels/:market',
        name: 'stock-levels'
    },
    {
        path: '/business/ikea-business-share-of-sales',
        name: 'ikea-business-share-of-sales'
    },
    {
        path: '/business/ikea-business-share-of-sales/top-8',
        name: 'ikea-business-share-of-sales'
    },
    {
        path: '/business/ikea-business-share-of-sales/:market',
        name: 'ikea-business-share-of-sales'
    },
    {
        path: '/business/market-share',
        name: 'market-share'
    },
    {
        path: '/business/market-share/market-share-prioritized-thirty-cities',
        name: 'market-share-prioritized-thirty-cities'
    },
    {
        path: '/business/store-fulfilment',
        name: 'store-fulfilment-m3'
    },
    {
        path: '/business/store-fulfilment-7-8',
        name: 'store-fulfilment-m3'
    },
    {
        path: '/business/store-fulfilment-7-8/top-8',
        name: 'store-fulfilment-m3'
    },
    {
        path: '/business/store-fulfilment-7-8/:market',
        name: 'store-fulfilment-m3'
    },
    {
        path: '/business/sales-per-range/hf-accessories',
        name: 'Sales-hfb'
    },
    {
        path: '/business/sales-per-range/hf-accessories/by-pa',
        name: 'Sales-hfb'
    },
    {
        path: '/business/sales-per-range/hf-accessories/top-8',
        name: 'Sales-hfb'
    },
    {
        path: '/business/sales-per-range/hf-accessories/:market',
        name: 'Sales-hfb'
    },
    {
        path: '/business/sales-tree',
        name: 'sales-tree'
    },
    {
        path: '/business/sales-tree/summary/:market',
        name: 'sales-tree'
    },
    {
        path: '/business/sales-tree/detailed-old',
        name: 'sales-tree'
    },
    {
        path: '/business/sales-tree-hidden',
        name: 'sales-tree-hidden'
    },
    {
        path: '/business/sales-tree/by-market/:market',
        name: 'sales-tree'
    },
    {
        path: '/business/sales-tree/top-8/:option',
        name: 'sales-tree'
    },
    {
        path: '/business/sales-tree/:market',
        name: 'sales-tree'
    },

    {
        path: '/business/stock-accuracy',
        name: 'stock-accuracy'
    },
    {
        path: '/business/stock-accuracy/top-8',
        name: 'stock-accuracy'
    },
    {
        path: '/business/stock-accuracy/:market/:option?',
        name: 'stock-accuracy'
    },

    {
        path: '/business/sales-per-range/goods-value-connected',
        name: 'goodsvalue-services'
    },
    {
        path: '/business/sales-per-range/goods-value-connected/top-8',
        name: 'goodsvalue-services'
    },
    {
        path: '/business/sales-per-range/goods-value-connected/by-hfb/:market',
        name: 'goodsvalue-services'
    },
    {
        path: '/business/sales-per-range/goods-value-connected/:market',
        name: 'goodsvalue-services'
    },
    {
        path: '/business/sales-per-range/food-sales',
        name: 'food-tree'
    },
    {
        path: '/business/sales-per-range/food-sales/top-8',
        name: 'food-tree'
    },
    {
        path: '/business/sales-per-range/food-sales/by-market/:market',
        name: 'food-tree'
    },
    {
        path: '/business/sales-per-range/food-sales/:market',
        name: 'food-tree'
    },
    {
        path: '/business/sales-per-range/food-sales/tree/:market',
        name: 'food-tree'
    },
    {
        path: '/business/sales-per-range/hfb',
        name: 'Sales-hfb'
    },
    {
        path: '/business/sales-per-range/hfb/top-8',
        name: 'Sales-hfb'
    },
    {
        path: '/business/sales-per-range/hfb/by-hfb',
        name: 'Sales-hfb'
    },
    {
        path: '/business/sales-per-range/hfb/by-hfb/:market',
        name: 'Sales-hfb'
    },
    {
        path: '/business/sales-per-range/hfb/channel-contribution/:market',
        name: 'Sales-hfb'
    },
    {
        path: '/business/sales-per-range/hfb/:market',
        name: 'Sales-hfb'
    },
    {
        path: '/business/sales-per-range/core-areas/:option',
        name: 'gwi'
    },
    {
        path: '/business/sales-per-range/core-areas/top-8/:option',
        name: 'gwi'
    },
    {
        path: '/business/sales-per-range/core-areas/top-families/:option/:market',
        name: 'gwi'
    },
    {
        path: '/business/sales-per-range/core-areas/growth-contribution/:market',
        name: 'gwi'
    },
    {
        path: '/business/sales-per-range/core-areas/sales-in-meur/:market',
        name: 'gwi'
    },
    {
        path: '/business/sales-per-range/core-areas/index/:market',
        name: 'gwi'
    },
    {
        path: '/business/sales-per-range/core-areas/trend/:market',
        name: 'gwi'
    },
    {
        path: '/business/sales-per-range/top-families-growth/top-10-turnover',
        name: 'top-families-growth'
    },
    {
        path: '/business/sales-per-range/top-families-growth/top-10-quantity',
        name: 'top-families-growth'
    },
    {
        path: '/business/lead-times',
        name: 'lead-times'
    },
    {
        path: '/business/lead-times/top-8/:option',
        name: 'lead-times'
    },
    {
        path: '/business/lead-times/:option/:market',
        name: 'lead-times'
    },
    {
		path: '/business/total-sales/flow-matrix/:market',
        name: 'click-and-collect'
    },
    {
        path: '/business/total-sales/sales-trend-total',
        name: 'sales-trend-total'
    },
    {
        path: '/business/total-sales/sales-trend-total/summary/:market',
        name: 'sales-trend-total'
    },
    {
        path: '/business/total-sales/sales-trend-total/by-market/:market',
        name: 'sales-trend-total'
    },
    {
        path: '/business/total-sales/sales-trend-total/:market',
        name: 'sales-trend-total'
    },
    {
        path: '/business/total-sales/sales-contribution-per-country',
        name: 'sales-contribution-per-country'
    },
    {
        path: '/business/total-sales/sales-contribution-per-country/:market',
        name: 'sales-contribution-per-country'
    },
    {
        path: '/business/total-sales/sales-per-country-vs-goal',
        name: 'sales-per-country-vs-goal-fy24'
    },
    {
        path: '/business/total-sales/sales-per-country-vs-goal/:market',
        name: 'sales-per-country-vs-goal-fy24'
    },
    {
        path: '/business/total-sales/sales-and-quantity-per-country',
        name: 'sales-and-pieces-per-country'
    },
    {
        path: '/business/total-sales/sales-and-quantity-per-country/top-8',
        name: 'sales-and-pieces-per-country'
    },
    {
        path: '/business/total-sales/sales-and-quantity-per-country/:market',
        name: 'sales-and-pieces-per-country'
    },
    {
        path: '/business/total-sales/sales-prioritized-thirty-cities',
        name: 'sales-prioritized-thirty-cities'
    },
    {
        path: '/business/total-sales/channel-contribution-per-country',
        name: 'channel-contribution-per-country'
    },
    {
        path: '/business/total-sales/channel-contribution-per-country/top-8',
        name: 'channel-contribution-per-country'
    },
    {
        path: '/business/financial-services',
        name: 'fin-serv'
    },
    {
        path: '/customer/online-buyability',
        name: 'online-buyability'
    },
    {
        path: '/customer/online-buyability/top-8',
        name: 'online-buyability'
    },
    {
        path: '/customer/online-buyability/:market',
        name: 'online-buyability'
    },
    {
        path: '/customer/promised-delivery-precision',
        name: 'promised-delivery-precision-2'
    },
    {
        path: '/customer/promised-delivery-precision/top-8',
        name: 'promised-delivery-precision-2'
    },
    {
        path: '/customer/promised-delivery-precision/by-market/:market',
        name: 'promised-delivery-precision-2'
    },
    {
        path: '/customer/promised-delivery-precision/:market',
        name: 'promised-delivery-precision-2'
    },
    {
        path: '/customer/non-value-contacts',
        name: 'non-value-contacts'
    },
    {
        path: '/customer/non-value-contacts/top-8',
        name: 'non-value-contacts'
    },
    {
        path: '/customer/non-value-contacts/:market',
        name: 'non-value-contacts'
    },
    {
        path: '/customer/first-contact-resolution',
        name: 'csc-pfcr'
    },
    {
        path: '/customer/first-contact-resolution/top-8',
        name: 'csc-pfcr'
    },
    {
        path: '/customer/first-contact-resolution/:market',
        name: 'csc-pfcr'
    },
    {
        path: '/customer/happy-customer',
        name: 'hcs'
    },
    {
        path: '/customer/happy-customer/top-8',
        name: 'hcs'
    },
    {
        path: '/customer/happy-customer/summary/:market',
        name: 'hcs'
    },
    {
        path: '/customer/happy-customer/:market',
        name: 'hcs'
    },
    {
        path: '/finance/cost-per-country-vs-goal',
        name: 'cost-per-country-vs-goal'
    },
    {
        path: '/finance/cost-per-country-vs-goal/:market',
        name: 'cost-per-country-vs-goal'
    },
    {
        path: '/finance/cost-per-country-ex',
        name: 'cost-per-country'
    },
    {
        path: '/finance/cost-per-country/top-8',
        name: 'cost-per-country'
    },
    {
        path: '/finance/cost-per-country/trend/:market',
        name: 'cost-per-country'
    },
    {
        path: '/finance/cost-per-country',
        name: 'cost-per-country'
    },
    {
        path: '/finance/gross-margin-per-country/by-country/:option',
        name: 'gross-margin-per-country'
    },
    {
        path: '/finance/gross-margin-per-country/top-8/:option',
        name: 'gross-margin-per-country'
    },
    {
        path: '/finance/gross-margin-per-country/:market',
        name: 'gross-margin-per-country'
    },
    {
        path: '/finance/operational-gross-margin-forecast-vs-goal',
        name: 'operational-gross-margin-forecast-vs-goal'
    },
    {
        path: '/finance/operational-gross-margin-forecast-vs-goal/:market',
        name: 'operational-gross-margin-forecast-vs-goal'
    },
    {
        path: '/finance/operational-gross-margin',
        name: 'operational-gross-margin'
    },
    {
        path: '/finance/operational-gross-margin/trend/:market',
        name: 'operational-gross-margin'
    },
    {
        path: '/finance/profit-and-loss',
        name: 'pl-2'
    },
    {
        path: '/finance/profit-and-loss-per-country',
        name: 'profit-and-loss-per-country'
    },
    {
        path: '/finance/profit-and-loss-total-ingka-retail',
        name: 'profit-and-loss-total-ingka-retail'
    },
    {
        path: '/finance/result-per-country-vs-goal',
        name: 'result-per-country-vs-goal'
    },
    {
        path: '/finance/result-per-country-vs-goal/:market',
        name: 'result-per-country-vs-goal'
    },
    {
        path: '/finance/result-contribution-per-country/:market',
        name: 'result-contribution-per-country'
    },
    {
        path: '/finance/result-contribution-per-country',
        name: 'result-contribution-per-country'
    },
    {
        path: '/finance/transformational-cost',
        name: 'transformational-cost'
    },
    {
        path: '/finance/after-sales-delivery-share',
        name: 'after-sales-delivery-share'
    },
    {
        path: '/finance/after-sales-delivery-share/top-8/:option',
        name: 'after-sales-delivery-share'
    },
    {
        path: '/finance/after-sales-delivery-share/:market',
        name: 'after-sales-delivery-share'
    },
    {
        path: '/finance/product-quality',
        name: 'product-quality'
    },
    {
        path: '/finance/product-quality/top-8',
        name: 'product-quality'
    },
    {
        path: '/finance/product-quality/:market',
        name: 'product-quality'
    },
    {
        path: '/finance/service-net/services-gross-margin',
        name: 'service-net'
    },
    {
        path: '/finance/service-net/services-gross-margin/by-market/:market',
        name: 'service-net'
    },
    {
        path: '/finance/service-net/services-gross-margin/by-market-graph/:market',
        name: 'service-net'
    },
    {
        path: '/finance/service-net/gm-deviation',
        name: 'service-net'
    },
    {
        path: '/finance/service-net/gm-deviation/by-market/:market',
        name: 'service-net'
    },
    {
        path: '/finance/service-net/gm-deviation/graph',
        name: 'service-net'
    },
    {
        path: '/finance/service-net/gm-deviation/by-market-graph/:market',
        name: 'service-net'
    },
    {
        path: '/finance/cost-per-flow',
        name: 'cost-ol-pa'
    },
    {
        path: '/finance/cost-per-flow/:market',
        name: 'cost-ol-pa'
    },
    {
        path: '/people/leadership-index',
        name: 'leadership-index'
    },
    {
        path: '/people/ikea-values',
        name: 'ikea-values'
    }, 
    {
        path: '/people/engagement-index',
        name: 'engagement-index'
    },
    {
        path: '/people/balanced-co-worker-turnover',
        name: 'balanced-turnover'
    },
    {
        path: '/people/gender-manager-share',
        name: 'gender-share'
    },
    {
        path: "/people/e-nps",
        name: "enps"
    },
    {
        path: '/process/sales-planning-precision',
        name: 'sales-planning-precision'
    },
    {
        path: '/process/sales-planning-precision/top-10-highest-precision',
        name: 'sales-planning-precision'
    },
    {
        path: '/process/sales-planning-precision/top-10-lowest-precision',
        name: 'sales-planning-precision'
    },
    {
        path: '/process/sales-planning-precision/top-8',
        name: 'sales-planning-precision'
    },
    {
        path: '/process/procurement',
        name: 'procurement'
    },
    {
        path: '/sustainability/climate-footprint',
        name: 'climate-footprint'
    },
    {
        path: '/sustainability/climate-footprint/top-8',
        name: 'climate-footprint'
    },
    {
        path: '/sustainability/climate-footprint/:market',
        name: 'climate-footprint'
    },
    {
        path: '/sustainability/sustainable-sales',
        name: 'sustainable-sales'
    },
    {
        path: '/sustainability/sustainable-sales/top-8',
        name: 'sustainable-sales'
    },
    {
        path: '/sustainability/sustainable-sales/:market',
        name: 'sustainable-sales'
    },
    {
        path: '/sustainability/mentions',
        name: 'mentions'
    },
    {
        path: '/sustainability/mentions/top-8',
        name: 'mentions'
    },
    {
        path: '/three-roads/furniture-that-fits-my-budget',
        name: 'furniture-that-fits-my-budget'
    },
    {
        path: '/three-roads/furniture-that-fits-my-budget/top-8',
        name: 'furniture-that-fits-my-budget'
    },
    {
        path: '/three-roads/furniture-that-fits-my-budget/:market',
        name: 'furniture-that-fits-my-budget'
    },
    {
        path: '/three-roads/low-price-in-reality',
        name: 'low-price-in-reality'
    },
    {
        path: '/three-roads/low-price-in-reality/top-8',
        name: 'low-price-in-reality'
    },
    {
        path: '/three-roads/low-price-in-reality/:market',
        name: 'low-price-in-reality'
    },
    {
        path: '/three-roads/penetration',
        name: 'penetration'
    },
    {
        path: '/three-roads/penetration/top-8',
        name: 'penetration'
    },
    {
        path: '/three-roads/penetration/:market',
        name: 'penetration'
    },
    {
        path: '/three-roads/positive-impact',
        name: 'positive-impact'
    },
    {
        path: '/three-roads/positive-impact/top-8',
        name: 'positive-impact'
    },
    {
        path: '/three-roads/positive-impact/:market',
        name: 'positive-impact'
    }
]