<template>
    <div>
        <Button class="full-width mb mt" @click="back()">Cancel</Button>

        <BackModal
            :back_clicked="back_clicked"
            @clear-storage="clear_storage"
            @toggle-edit-mode="$emit('toggle-edit-mode')"
            @cancel="back_clicked = false"
        />

        <div class="schedule mb">
            <SwitchBoxUniversal
                class="mb"
                label="Posting on someones behalf?"
                element_id="new-switch"
                @switch_toggle="switch_toggle"
            />
            <UserSearch v-if="posting_on_behalf" @SelectedUser="selected_user" />

            <WYSIWYGEditor @textUpdate="update_content" :content="new_comment.content" />
            <div class="character-count" :class="character_alert ? 'character-alert' : ''">
                {{ character_count }}/2250
                <div v-if="character_alert">Text may have to be cut off when put into the Powerpoint extract</div>
            </div>
            <p>Select the start and end dates for the comment:</p>
            <DateTimePicker @updated="update_scheduled_date" />
        </div>

        <InfoButtons :buttons="buttons" :calculated_status="calculated_status" @update="create_new" />

        <div v-if="definitionType !== 'global'">
            <h3><strong>Insights (Global)</strong></h3>
            <div
                class="comment-container"
                :class="comments.length - 1 !== i ? 'comment-border' : ''"
                v-for="(comment, i) in global_comments"
                :key="i"
            >
                <div class="comment-meta">
                    <div class="ikea-blue-text">
                        {{ comment.scheduled_date.split('T')[0] }} -
                        {{ comment.comment_lifetime.split('T')[0] }}
                    </div>
                    <div class="columns is-gapless">
                        <div class="column">
                            <div
                                v-if="!audio_hash || !(comment.comment_id in audio_hash)"
                                class="button tts-button ikea-blue-text ft-tooltip"
                                tooltiptext="Text to Speech"
                                @click="load_audio(comment.content, comment.comment_id)"
                            >
                                <i v-if="!loading" class="fas fa-volume-up"></i>
                                <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                            </div>
                        </div>
                        <div class="column is-8">
                            <div
                                v-if="comment.updated_by || comment.a_display_name"
                                class="ft-tooltip-bottom"
                                :tooltiptext="`
                                        ${
                                            comment.updated_by
                                                ? `Edited by: ${comment.updated_by} ${
                                                      comment.a_display_name ? '|' : ''
                                                  }`
                                                : ''
                                        }
                                    `"
                            >
                                {{ comment.u_display_name }}
                            </div>
                            <div v-else>{{ comment.u_display_name }}</div>
                        </div>
                    </div>
                    <div>
                        <div v-if="comment.updated_by" class="ft-tooltip" :tooltiptext="comment.e_display_name">
                            |Edited
                        </div>
                        <div v-if="comment.a_display_name" class="ft-tooltip" :tooltiptext="comment.a_display_name">
                            |Posted By
                        </div>
                    </div>
                </div>
                <div v-if="audio_hash && comment.comment_id in audio_hash" class="mt">
                    <audio class="active-audio" controls="controls" autobuffer="autobuffer">
                        <source :src="api_get_url + audio_hash[comment.comment_id]" />
                    </audio>
                </div>
                <div class="comment-content ProseMirror" v-html="comment.content"></div>
            </div>
        </div>
    </div>
</template>

<script>
import BackModal from '@/components/bespoke/commenting/BackModal.vue';
import Button from '@/components/ui/Button.vue';
import DateTimePicker from '@/components/bespoke/commenting/DateTimePicker.vue';
import DropDown from '@/components/ui/DropDown.vue';
import InfoButtons from '@/components/ui/InfoButtons.vue';
import SwitchBoxUniversal from '@/components/ui/SwitchBoxUniversal.vue';
import UserSearch from '@/components/admin/UserSearch.vue';
import WYSIWYGEditor from '@/components/bespoke/commenting/QuillWYSIWYGEditor.vue';

export default {
    props: {
        definitionType: String
    },
    components: {
        BackModal,
        Button,
        DateTimePicker,
        DropDown,
        InfoButtons,
        SwitchBoxUniversal,
        UserSearch,
        WYSIWYGEditor
    },
    data: function () {
        return {
            new_comment: {
                status: null,
                content: localStorage['new_comment'],
                scheduled_date: null,
                comment_lifetime: null,
                author_id: null
            },
            posting_on_behalf: false,
            character_count: 0,
            character_alert: false,
            back_clicked: false,
            global_comments: null,
            loading: false,
            audio_hash: null,
            buttons: [
                {
                    type: 'post',
                    clicked: false,
                    button: 'Insight',
                    info: `This button saves the insight to the`,
                    operation: null
                },
                {
                    type: 'draft',
                    clicked: false,
                    button: 'Save to Draft',
                    info: 'This button saves the insight to the Draft tab',
                    operation: 'DRAFT'
                }
            ]
        };
    },
    mounted() {
        if (this.definitionType !== 'global') {
            this.load_global_comments();
        }
    },
    methods: {
        load_global_comments: function () {
            if (this.$store.state.commenting.width === 0) return;
            this.comments = [];
            let to_load = this.$helper.location();
            to_load.status = 'LIVE';
            to_load.retail_unit = 'GET_ALL';

            to_load.filters = {
                order_by: this.selected === 'archived' ? this.filters.order_by : null,
                search: this.selected === 'archived' ? this.filters.search : null
            };
            this.$chttp.post('/comments/load', to_load).then(
                succ => {
                    if (succ.data) {
                        this.global_comments = succ.data;
                    }
                },
                error => {}
            );
        },
        load_audio: function (content, id) {
            this.loading = true;
            this.$chttp
                .post('/tts/create', {
                    text: content
                })
                .then(succ => {
                    if (succ.data) {
                        if (this.audio_hash === null) {
                            this.audio_hash = { [id]: succ.data.hash };
                        } else {
                            this.audio_hash[id] = succ.data.hash;
                        }
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        back: function () {
            if (localStorage['new_comment']) {
                this.back_clicked = true;
            } else {
                this.$emit('toggle-edit-mode');
            }
        },
        clear_storage: function () {
            delete localStorage['new_comment'];
        },
        create_new: function (status) {
            if (!this.new_comment.scheduled_date || !this.new_comment.comment_lifetime) {
                alert('Please select and start and end date');
            } else {
                if (this.new_comment.scheduled_date && !status) {
                    //check if first date selected is todays date
                    let today = new Date().getTime();
                    let input_date = this.new_comment.scheduled_date.split('-');

                    let input_timestemp = new Date(input_date[1] + '/' + input_date[2] + '/' + input_date[0]).getTime();

                    if (input_timestemp < today) {
                        this.new_comment.status = 'LIVE'; //if post date is today make it live
                    } else {
                        this.new_comment.status = 'SCHEDULED'; //if post date in the future make it scheduled
                    }
                } else if (status) {
                    this.new_comment.status = status;
                }
                if (Object.keys(this.new_comment).length > 0) {
                    let postData = {
                        comment: this.new_comment,
                        location: this.$helper.location(),
                        retail_unit: this.$store.getters.market
                    };
                    this.$http.post('force', `/comments/new`, postData).then(
                        succ => {
                            this.$emit('updated', this.new_comment.status);
                            delete localStorage['new_comment'];
                        },
                        error => {
                            console.log(error);
                        }
                    );
                }
            }
        },
        update_content: function (text) {
            this.new_comment.content = text;
            this.character_counter(text);
            localStorage['new_comment'] = text;
        },
        character_counter: function (text) {
            let result = text.replace(/(<([^>]+)>)/gi, '').length;
            this.character_count = result;
            this.character_alert = result > 2250 ? true : false;
        },
        update_scheduled_date: function (date) {
            this.new_comment.scheduled_date = date[0];
            this.new_comment.comment_lifetime = date[1];
        },
        selected_user: function (user) {
            this.new_comment.author_id = user.id;
        },
        switch_toggle: function () {
            this.posting_on_behalf = !this.posting_on_behalf;
            if (!this.posting_on_behalf) this.new_comment.author_id = null;
        }
    },
    watch: {
        '$store.getters.market': function () {
            if (this.definitionType !== 'global') {
                this.load_global_comments();
            }
        }
    },
    computed: {
        api_get_url: function () {
            return `${process.env.VUE_APP_BASE_URL}/tts/get/`;
        },
        calculated_status: function () {
            if (this.new_comment.scheduled_date && this.new_comment.comment_lifetime) {
                let today = new Date().getTime();
                let input_date = this.new_comment.scheduled_date.split('-');
                let input_timestemp = new Date(input_date[1] + '/' + input_date[2] + '/' + input_date[0]).getTime();
                if (input_timestemp < today) {
                    return 'LIVE'; //if post date is today make it live
                } else {
                    return 'SCHEDULED'; //if post date in the future make it scheduled
                }
            } else {
                return null;
            }
        }
    }
};
</script>

<style scoped>
.is-centered {
    justify-content: space-between !important;
}
.buttons.is-centered:not(.has-addons) .button:not(.is-fullwidth) {
    margin: 0;
}
.character-alert {
    background-color: #ff0000;
    color: #f5f5f5;
}
.section {
    background-color: rgb(245, 245, 245);
}
.schedule {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.5em;
    background-color: #fbfbfb;
}

.comment-meta {
    font-size: 0.85rem;
    border-bottom: 1px solid #0051ba;
}

.comment-container {
    margin: 1rem 1rem 0 1rem;
}

.comment-content {
    text-align: left;
}

.comment-border {
    border-bottom: 1px solid #a7a4a4;
    padding-bottom: 1rem;
}

.delete {
    right: 1rem;
    top: 0.9rem;
}

.edit-button {
    width: calc(50% - 1rem);
}

i.far.fa-comment-alt {
    font-size: 1.5em;
}

#comment-widget {
    background-color: white;
    border-left: 0;
    box-shadow: 0 0 1px #575757;
    display: block;
    height: calc(100vh - 3.5rem);
    overflow-y: auto;
    position: fixed;
    top: 3.5rem;
    width: inherit;
    z-index: 2;
}

.observations-title {
    font-size: 1.2rem;
}

.tts-button {
    border: none;
    background-color: none;
    font-size: 1.1rem;
}

.tts-button:hover {
    background-color: rgb(235, 235, 235);
    color: #0051ba;
}

.tts-delete {
    margin-top: -0.2rem;
    margin-left: 1.5rem;
}
</style>
