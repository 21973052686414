<template>
<div>
    <small>Did you find this definition useful? </small>
    <Button @click="rate(true)"
        class="comment-rate-button"
        :class="{ chosen: rating === true }"
        title="I found this definition useful"
        :disabled="working">
        <i class="far fa-thumbs-up"></i>
    </Button> 
    <Button
        @click="rate(false)"
        class="comment-rate-button"
        :class="{ chosen: rating === false }"
        title="I did not find this definition useful"
        :disabled="working">
        <i class="far fa-thumbs-down"></i>
    </Button>
</div>
</template>
<script>
import Button from '@/components/ui/Button.vue';

export default {
	components: {
		Button,
	},
    props: {
        id: Number,
        current: Boolean
    },
    data: function() {
        return {
            rating: this.current,
            working: false,
        }
    },
    methods: {
        rate: function(rating) {
            this.working = true;
            this.$chttp.post('/admin/kpi-definition/rate', {
                definition_id: this.id,
                rating
            }).then(({ data }) => {
                if (data) {
                    this.rating = data.rating;
                }
            }).finally(() => this.working = false);
        }
    }
}
</script>
<style scoped>
.comment-rate-button {
    border-color: #F1F1F1;
    color: #333;
    font-size: 0.8rem;
}

.comment-rate-button:hover {
    color: #000;
}

.comment-rate-button:first-child {
    margin-right: 0.2rem;
}

.comment-rate-button.chosen {
    background-color: #006FFF;
    color: #FFF;
}
</style>
