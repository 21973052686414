import KitchenSink from '@/views/testing/KitchenSink.vue';
import EmbeddedPowerBI from '@/views/testing/EmbeddedPowerBI.vue';

import PowerBIIndex from '@/views/testing/PowerBI/index.vue';

import PowerBIBrandDesire from '@/views/testing/PowerBI/BrandDesire/BrandDesire.vue';
import PowerBIBrandDesireTrend from '@/views/testing/PowerBI/BrandDesire/BrandDesireTrend.vue';
import PowerBIGrossMargin from '@/views/testing/PowerBI/GrossMargin/GrossMargin.vue';
import PowerBIGrossMarginTrend from '@/views/testing/PowerBI/GrossMargin/GrossMarginTrend.vue';
import PowerBIGrossMarginTop from '@/views/testing/PowerBI/GrossMargin/GrossMarginTop.vue';

export default [
    {
        path: '/testing/kitchensink',
        name: 'kitchensink',
        component: KitchenSink,
        props: true
    },
    {
        path: '/testing/embedded-power-bi/:market',
        name: 'powerbitest',
        component: EmbeddedPowerBI,
        props: true
    },
    {
        path: '/testing/power-bi',
        name: 'powerbiindex',
        component: PowerBIIndex,
    },
    {   //Standard By country
        path: '/testing/power-bi/brand-desire',
        name: 'powerbibranddesire',
        component: PowerBIBrandDesire,
    },
    {   //Standard trend
        path: '/testing/power-bi/brand-desire/:market',
        name: 'powerbibranddesiretrend',
        component: PowerBIBrandDesireTrend,
        props: true
    },
    {
        path: '/testing/power-bi/gross-margin',
        name: 'powerbigrossmargin',
        component: PowerBIGrossMargin,
    },
    {
        path: '/testing/power-bi/gross-margin/top',
        name: 'powerbigrossmargintop',
        component: PowerBIGrossMarginTop,
    },
    {
        path: '/testing/power-bi/gross-margin/:market',
        name: 'powerbigrossmargintrend',
        component: PowerBIGrossMarginTrend,
        props: true
    },

];