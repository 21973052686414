<template>
<div v-if="render">
    <div v-for="(box, i) in boxes" :key="i">
        <!-- Tree Boxes -->
        <svg v-if="i===3" width="100%" height="100%">
            <rect
                :x="`${box.x}%`"
                :y="`${box.y}%`"
                :width="`${box.width}%`"
                height="3%"
                stroke="#dddddd"
                stroke-width="1"
                fill="#dddddd"
            />
            <rect
                :x="`${box.x}%`"
                :y="`${box.y + 3}%`"
                :width="`${box.width}%`"
                :height="`${box.height - 3}%`"
                stroke="#dddddd"
                stroke-width="1"
                fill="white"
            />
        </svg>
        <svg v-else width="100%" height="100%">
            <rect
                :x="`${box.x}%`"
                :y="`${box.y}%`"
                :width="`${box.width}%`"
                height="3%"
                stroke="#dddddd"
                stroke-width="1"
                fill="#dddddd"
            />
            <rect
                :x="`${box.x}%`"
                :y="`${box.y + 3}%`"
                :width="`${box.width}%`"
                :height="`${box.height - 3}%`"
                stroke="#dddddd"
                stroke-width="1"
                fill="white"
            />
        </svg>
        
        <!-- Table data -->
        <div v-for="(row, j) in box.data" :key="j">
            <svg v-if="i===3" width="100%" height="100%" font-size="0.8rem">
                <text
                    :x="`${box.x + 0.5}%`"
                    :y="`${box.y + 2.2 + (2.5*j)}%`"
                    text-anchor="start"
                    :font-weight="j === 0 ? 'bold' : 'normal'"
                    fill="black"
                >
                    {{row[0]}}
                </text>
                <text
                    :x="`${box.x + box.width - 0.5}%`"
                    :y="`${box.y + 2.2 + (2.5*j)}%`"
                    :font-weight="j === 0 ? 'bold' : 'normal'"
                    text-anchor="end"
                    fill="black"
                >
                    {{row[1]}}
                </text>
            </svg>
            <svg v-else width="100%" height="100%" font-size="0.8rem">
                <text
                    :x="`${box.x + 0.5}%`"
                    :y="`${box.y + 2.3 + (2.5*j)}%`"
                    text-anchor="start"
                    :font-weight="j === 0 ? 'bold' : 'normal'"
                    fill="black"
                >
                    {{row[0]}}
                </text>
                <text
                    :x="`${box.x + (0.765*box.width)}%`"
                    :y="`${box.y + 2.3 + (2.5*j)}%`"
                    :font-weight="j === 0 ? 'bold' : 'normal'"
                    text-anchor="end"
                    fill="black"
                >
                    {{row[1]}}
                </text>
                <text
                    :x="`${box.x + box.width - 0.5}%`"
                    :y="`${box.y + 2.3 + (2.5*j)}%`"
                    :font-weight="j === 0 ? 'bold' : 'normal'"
                    text-anchor="end"
                    fill="black"
                >
                    {{row[2]}}
                </text>
            </svg>
        </div>

        <!-- Inter-box arrow segments (vertical segments and arrowhead) -->
        <svg width="100%" height="100%">
            <line
                v-if="i<3"
                :x1="`${box.x + (box.width/2)}%`"
                :y1="`${box.y + box_height}%`"
                :x2="`${box.x + (box.width/2)}%`"
                :y2="`${box.y + (box_height/2) + (tree_level_heights[1]/2)}%`"
                stroke="black"
                stroke-width="2"
            />
            <line
                v-if="i>0"
                :x1="`${box.x + (box.width/2)}%`"
                :y1="`${box.y + (box_height/2) - (tree_level_heights[1]/2)}%`"
                :x2="`${box.x + (box.width/2)}%`"
                :y2="`${box.y}%`"
                stroke="black"
                stroke-width="2"
            />
            <line
                v-if="i>0"
                :x1="`${box.x + (box.width/2) - 0.5}%`"
                :y1="`${box.y - 1}%`"
                :x2="`${box.x + (box.width/2)}%`"
                :y2="`${box.y}%`"
                stroke="black"
                stroke-width="2"
            />
            <line
                v-if="i>0"
                :x1="`${box.x + (box.width/2) + 0.5}%`"
                :y1="`${box.y - 1}%`"
                :x2="`${box.x + (box.width/2)}%`"
                :y2="`${box.y}%`"
                stroke="black"
                stroke-width="2"
            />
        </svg>
    </div>

    <!-- Horizontal arrow segments -->
    <svg width="100%" height="100%">
        <line
            :x1="`${boxes[1].x + (box_width/2)}%`"
            :y1="`${boxes[0].y + (box_height/2) + (tree_level_heights[1]/2)}%`"
            :x2="`${boxes[2].x + (box_width/2)}%`"
            :y2="`${boxes[0].y + (box_height/2) + (tree_level_heights[1]/2)}%`"
            stroke="black"
            stroke-width="2"
        />
        <line
            :x1="`${boxes[3].x + (box_4_width/2)}%`"
            :y1="`${boxes[3].y + (box_height/2) - (tree_level_heights[1]/2)}%`"
            :x2="`${boxes[4].x + (box_width/2)}%`"
            :y2="`${boxes[3].y + (box_height/2) - (tree_level_heights[1]/2)}%`"
            stroke="black"
            stroke-width="2"
        />
        <line
            :x1="`${boxes[5].x + (box_width/2)}%`"
            :y1="`${boxes[3].y + (box_height/2) - (tree_level_heights[1]/2)}%`"
            :x2="`${boxes[6].x + (box_width/2)}%`"
            :y2="`${boxes[3].y + (box_height/2) - (tree_level_heights[1]/2)}%`"
            stroke="black"
            stroke-width="2"
        />
    </svg>
</div>
</template>

<script>
export default {
    props: {
        tables: Array
    },
    data: function() {
        return {
            /**
             * Only these first 5 variables need to be altered to adjust spacing.
             * Everything else is derived from them in compute_box_dimensions() and the html.
             */
            box_height: 24,
            box_4_height: 8.5,
            box_width: 27,
            box_4_width: 12.89, //Specifically set to cause boxes 2 and 5 to align
            tree_level_heights: [0, 32.5, 65],
            
            boxes: [],
            render: false
        }
    },
    mounted: function() {
        this.compute_box_dimensions();
        this.populate_boxes();
    },
    methods: {
        compute_box_dimensions: function() {
            //Tree level 1
            let gap_size = (100 - this.box_width) / 2;
            this.boxes.push({
                height: this.box_height,
                width: this.box_width,
                x: gap_size,
                y: this.tree_level_heights[0],
            });

            //Tree level 2
            gap_size = (100 - (2*this.box_width)) / 3;
            for (let i = 0; i < 2; i++) {
                this.boxes.push({
                    height: this.box_height,
                    width: this.box_width,
                    x: (i*this.box_width) + ((i+1) * gap_size),
                    y: this.tree_level_heights[1],
                });
            }

            //Tree level 3
            gap_size = (100 - (3*this.box_width) - this.box_4_width) / 5;
            this.boxes.push({
                height: this.box_4_height,
                width: this.box_4_width,
                x: gap_size,
                y: this.tree_level_heights[2],
            });
            for (let i = 0; i < 3; i++) {
                this.boxes.push({
                    height: this.box_height,
                    width: this.box_width,
                    x: this.box_4_width + (i*this.box_width) + ((i+2) * gap_size),
                    y: this.tree_level_heights[2],
                });
            }
        },
        populate_boxes: function() {
            this.render = false;
            for (let i = 0; i < this.boxes.length; i++) {
                this.boxes[i].data = this.tables[i];
            }
            this.render = true;
        }
    }
}
</script>

<style scoped>
svg {
  	background: #ffffff00;
	left: 0;
    position: absolute;
	top: 2rem;
}
</style>
