<template>
<div class="mb">
    <component :is="uppercase" v-bind="props" v-on="$listeners"/>
</div>
</template>

<script>
import Button from '@/components/admin/Button.vue';
import Dropdown from '@/components/admin/Dropdown.vue';
import Table from '@/components/admin/Table.vue';
import Table2 from '@/components/admin/Table2.vue';
import Textinput from '@/components/admin/TextInput.vue';
import Title from '@/components/admin/Title.vue';

export default {
    props: {
        component: String,
        props: Object
    },
    components: {
        Button,
        Dropdown,
        Table,
        Table2,
        Textinput,
        Title
    },
    computed: {
        uppercase: function() {
            return this.component[0].toUpperCase() + this.component.slice(1);
        }
    }
}
</script>
