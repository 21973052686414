var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "overflow-x-scroll", attrs: { id: "table" } },
    [
      _c("div", { staticClass: "scroll-notification" }, [
        _vm._v(
          "Horizontal Scrolling is available at the bottom if the table overflows the page"
        ),
      ]),
      _c(
        "table",
        {
          staticClass: "table table-component is-narrow is-striped",
          style: _vm.render_font,
        },
        [
          _c(
            "thead",
            _vm._l(_vm.nestSettings.headers, function (row, index) {
              return _c(
                "tr",
                { key: index },
                _vm._l(row, function (cell, i) {
                  return _c(
                    "th",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.hidden_columns.indexOf(
                              Object.values(cell).pop() * i
                            ) < 0,
                          expression:
                            "hidden_columns.indexOf((Object.values(cell).pop() * i)) < 0",
                        },
                      ],
                      key: i,
                      class: [
                        _vm.render_colors(),
                        _vm.render_gap(i, Object.values(cell).pop()),
                      ],
                      staticStyle: { "min-width": "9rem" },
                      attrs: { colspan: Object.values(cell).pop() },
                      on: {
                        click: function ($event) {
                          return _vm.sort_row_by(i)
                        },
                      },
                    },
                    [
                      _vm._v(" " + _vm._s(Object.keys(cell).pop()) + " "),
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.collapseSettings &&
                                index === 0 &&
                                i === 0 &&
                                _vm.bridgeIcon,
                              expression:
                                "collapseSettings && index === 0 && i === 0 && bridgeIcon",
                            },
                          ],
                          staticClass: "bridge button ft-tooltip-right",
                          class: { "is-selected": !_vm.collapsed },
                          attrs: {
                            tooltiptext: _vm.collapsed
                              ? "Show bridge"
                              : "Hide bridge",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.toggle_collapsed.apply(null, arguments)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fas fa-archway" })]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                i !== _vm.last_col_clicked &&
                                i !== 0 &&
                                index !== 0,
                              expression:
                                "i !== last_col_clicked && i !== 0 && index !== 0",
                            },
                          ],
                          staticClass: "icon has-text-white",
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.asc,
                                expression: "!asc",
                              },
                            ],
                            staticClass: "fas invisible fa-arrow-down",
                          }),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                i === _vm.last_col_clicked &&
                                i !== 0 &&
                                index !== 0,
                              expression:
                                "i === last_col_clicked && i !== 0 && index !== 0",
                            },
                          ],
                          staticClass: "icon has-text-white",
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.asc,
                                expression: "asc",
                              },
                            ],
                            staticClass: "fas fa-sort-up",
                          }),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.asc,
                                expression: "!asc",
                              },
                            ],
                            staticClass: "fas fa-sort-down",
                          }),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                i === _vm.last_col_clicked &&
                                i === 0 &&
                                index !== 0,
                              expression:
                                "i === last_col_clicked && i === 0 && index !== 0",
                            },
                          ],
                          staticClass: "icon has-text-white",
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.asc,
                                expression: "asc",
                              },
                            ],
                            staticClass: "fas fa-sort-up",
                          }),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.asc,
                                expression: "!asc",
                              },
                            ],
                            staticClass: "fas fa-sort-down",
                          }),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            }),
            0
          ),
          _c(
            "tbody",
            _vm._l(_vm.sorted_rows, function (row, index) {
              return _c(
                "tr",
                {
                  key: index,
                  class: _vm.render_row(row[0]),
                  on: {
                    click: function ($event) {
                      return _vm.clickedTable(row[0])
                    },
                  },
                },
                _vm._l(row, function (col, i) {
                  return _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.collapsed || _vm.hidden_columns.indexOf(i) < 0,
                          expression:
                            "!collapsed || hidden_columns.indexOf(i) < 0",
                        },
                      ],
                      key: i,
                      class: _vm.render_gap(i),
                    },
                    [
                      typeof col === "string"
                        ? _c("span", { domProps: { innerHTML: _vm._s(col) } })
                        : _c("span", [_vm._v(_vm._s(col))]),
                    ]
                  )
                }),
                0
              )
            }),
            0
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }