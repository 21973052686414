var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nudge
    ? _c(
        "div",
        { staticClass: "nps" },
        [
          _c(
            "div",
            { staticClass: "nps-button", on: { click: _vm.open_survey } },
            [_vm._v(_vm._s(_vm.opened ? "X" : "Survey"))]
          ),
          _c("Modal", {
            class: { "is-active": _vm.opened },
            attrs: { title: _vm.meta.name, closeable: true },
            on: { close: _vm.close_modal },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "content is-medium" },
                        _vm._l(_vm.questions, function (question, index) {
                          return _c("div", { key: index }, [
                            _c(
                              "div",
                              { staticClass: "text-left text-bold mb" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(index + 1) +
                                    ") " +
                                    _vm._s(question.question) +
                                    " "
                                ),
                                _c(
                                  "div",
                                  { staticClass: "inline-block text-normal" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        question.optional ? "(Optional)" : ""
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            question.answer_range.type === "comment"
                              ? _c("div", [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: question.answer,
                                        expression: "question.answer",
                                      },
                                    ],
                                    staticClass: "textarea",
                                    attrs: { rows: "1" },
                                    domProps: { value: question.answer },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          question,
                                          "answer",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ])
                              : question.answer_range.type === "0-10"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "inline-block pr" },
                                      [_vm._v("Bad")]
                                    ),
                                    _vm._l(11, function (num, i) {
                                      return _c(
                                        "div",
                                        {
                                          key: i,
                                          staticClass: "inline-block pr",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: question.answer,
                                                expression: "question.answer",
                                              },
                                            ],
                                            staticClass: "mrp5 cursor-pointer",
                                            attrs: {
                                              type: "radio",
                                              id: `${index} ${num}`,
                                              name: index,
                                            },
                                            domProps: {
                                              value: i,
                                              checked: _vm._q(
                                                question.answer,
                                                i
                                              ),
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.$set(
                                                  question,
                                                  "answer",
                                                  i
                                                )
                                              },
                                            },
                                          }),
                                          _c(
                                            "label",
                                            {
                                              staticClass: "cursor-pointer",
                                              attrs: { for: `${index} ${num}` },
                                            },
                                            [_vm._v(_vm._s(i))]
                                          ),
                                        ]
                                      )
                                    }),
                                    _c("div", { staticClass: "inline-block" }, [
                                      _vm._v("Good"),
                                    ]),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _c("hr"),
                          ])
                        }),
                        0
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "flex-space-between full-width" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "button is-danger text-white",
                              on: { click: _vm.dont_show },
                            },
                            [_vm._v("Don't show this questionnaire again")]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "button ikea-blue text-white",
                              on: { click: _vm.submit },
                            },
                            [_vm._v("Submit")]
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              777036695
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }