<template>
<div>
    <Title
        title="Sales Tree"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
        <div class="label-container">
            <p>Channel Conversion / Visitors</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>

    </div>
    <div :key="$store.getters.page_size" class="section box three-quarters-height ms1p5 mb">
        <Top8Bar
            :key="graph.render"

            title="Sales Tree Top 10 Countries"
            :showtitle="!$store.state.global.bot_mode"
            subtitle=""
            :xcategories="graph.xcategories"
            :yaxislabel="`${filter_split[0]} ${filter_split[1]} ${filter_split[1] === 'Conversion' ? '%' : 'Index'}`"
            ymetric=""

            :seriesname1="`${filter_split[1]} YTD`"
            seriesname3="4WK"
            seriesname4="Goal"

            seriestype1="bar"
            seriestype2="scatter"
            seriestype3="scatter"
            seriestype4="scatter"

            :seriesset1="graph.seriesset1"
            :color1="$store.state.colors['yellow']"
            
            :seriesset3="graph.seriesset2"
            :color3="$store.state.colors['grey']"

            :seriesset4="graph.seriesset3"
            :color4="$store.state.colors.YG"
            
            :trend="true"
            :ranking="[]"
            :competitors="[]"
        />
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Top8Bar from '@/components/visualisations/bar/Top8Bar.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Title,
        Top8Bar
    },
    props: {
        option: String
    },
    data: function() {
        return {
            graph: {
                xcategories: [],
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],
                render: false
            },
            options: ['Online Conversion', 'Online Visitors', 'Store Conversion', 'Store Visitors'],
			values: ['Online-Conversion', 'Online-Visitors', 'Store-Conversion', 'Store-Visitors'],
			filter: 'Online-Conversion',

            location: '/business/sales-tree'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        this.filter = this.$store.getters.filter_check(this.values);
        this.load_market_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'By Market',
                    link: `${this.location}/by-market/${this.$store.getters.market}`
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
					name: "Tree",
					link: `${this.location}/tree/${this.$store.getters.market}`
				},
                {
                    name: 'Top 10',
                    link: this.filter_url
                },
            ];
        },
        load_market_data: function() {
            if (this.filter) {
                this.$http.post('/business/sales-tree/top-8', {filter: this.filter_split[0]}).then(
                    succ => {
                        let d = succ.data;
                        this.graph.xcategories = [];
                        this.graph.seriesset1 = [];
                        this.graph.seriesset2 = [];
                        this.graph.seriesset3 = [];
                        d.map(row => {
                            let val = row.measures;
                            this.graph.xcategories.push(row.caption);
                            if (this.filter_split[1] === 'Visitors') {
                                this.graph.seriesset1.push(this.$helper.round(val['visitors_ytd_index'], 2));
                                this.graph.seriesset2.push(this.$helper.round(val['visitors_4wk_index'], 2));
                                this.graph.seriesset3.push(this.$helper.round(val['visits_goal_index'], 2));
                            } else {
                                this.graph.seriesset1.push(this.$helper.round(val['conversion_ytd'], 2));
                                this.graph.seriesset2.push(this.$helper.round(val['conversion_4wk'], 2));
                                this.graph.seriesset3.push(this.$helper.round(val['conversion_goal_index'], 2));
                            }
                        });
                        this.graph.render = true;
                    },
                    this.$helper.error
                );
            }
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = this.filter_split[0];
            this.graph.render = false;
            this.$router.push(this.filter_url);
            this.load_market_data();
        },
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.load_market_data();
        },
        '$route': function(to, from) {
            if (to.params.option === 'null') {
                this.$router.push(this.filter_url);
            }
        }
    },
    computed: {
        filter_formatted:  function() {
            return this.filter_split[0] ? this.filter_split[0] : null;
        },
        filter_url: function() {
            return `${this.location}/top-8/${this.filter_formatted}`
        },
        filter_split: function() {
            return this.filter.split('-')
        }
    }
}
</script>