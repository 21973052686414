<template>
<div>
    <Title
        title="Product Quality Costs"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container">
            <p>Filter</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <!-- <div :key="$store.getters.page_size" class="section three-quarters-height box ms">
        <Triple
            :key="triple.render"
            v-if="triple.render"

            :title="`Showing data for: ${$store.getters.selected_country_name}`"
            :showtitle="!$store.state.global.bot_mode"
            subtitle=""

            :xcategories="triple.xcategories"
            :yaxislabel="`${filter}`"
            ymetric=""
            
            seriesname1="YTD"
            seriestype1="line"
            :seriesset1="triple.seriesset1"
            :color1="$store.state.colors['focus-yellow']"
            
            seriesname2="Month Trend"
            seriestype2="line"
            :seriesset2="triple.seriesset2"
            :color2="$store.state.colors['dark-grey']"

            seriesname3="Year Goal"
            seriestype3="line"
            :seriesset3="triple.seriesset3"
            :color3="$store.state.colors.YG"

            :show="!$store.state.global.bot_mode"

        />
        <div v-else> No data found for {{$store.getters.selected_country_name}}</div>
    </div> -->
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Triple from '@/components/visualisations/general/Triple.vue';


export default {
    components: {
        Buttons,
        DropDown,
        Title,
        Triple
    },
    props: {
        market: String
    },
    data: function() {
        return {
            triple: {
                title: null,
                subtitle: null,

                xcategories: null,
                yaxislabel: null,
                ymetric: null,
                
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],

                render: false
            },
            location: '/finance/product-quality',
            options: [],
            values: [],
            filter: null,
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'pq-cost-type-1'
            }).then(
                succ => {
                    succ.data.map(filter => {
                        this.values.push(filter.value);
                        this.options.push(filter.option);
                    });
                    //first time right filter selecting - ben 29/06/2020
                    let index = this.values.indexOf('First Time Right');
                    this.filter = this.values.indexOf(this.$store.state.global.temp_filter) > 0 ?
                        this.$store.state.global.temp_filter :
                        this.values[index !== -1 ? index : 0 ];
                    this.load_market_data();
                },
                this.$helper.error
            );
        },
        load_market_data: function() {
            if (!this.filter) return;
            this.$http.post('/finance/product-quality/market', {
                market: this.$store.getters.market,
                filter: this.filter
            }).then(
                succ => {
                    let d = succ.data;
                    if (!d || !d[0]) return;
                    this.triple.xcategories = Object.keys(d[0].measures);
                    this.triple.seriesset1 = [];
                    this.triple.seriesset2 = [];
                    this.triple.seriesset3 = [];
                    Object.values(d[0].measures).map(val => {
                        this.triple.seriesset1.push(this.$helper.maybe(val['YTD']));
                        this.triple.seriesset2.push(this.$helper.maybe(val['Month Trend']));
                        this.triple.seriesset3.push(this.$helper.maybe(val['Goal']));
                    });
                    this.triple.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        },
        update_filter: function(filter) {
            this.triple.render = false;
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.load_market_data();
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.triple.render = false;
            if (!this.filter) {
                this.get_filters();
            } else {
                this.update_view(newMarket);
                this.load_market_data();
            }
        },
    }
}
</script>

<style>

</style>