<template>
<div>
    <Title
        title="Operational Climate Footprint"
        :location="location"
    />
    <div class="options-bar">
        
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms">
        <Deviation
            :key="graph.render"
            v-if="graph.render"

            :title="`Showing data for: ${$store.getters.selected_country_name}`"
            :showtitle="!$store.state.global.bot_mode"

            :xcategories="graph.xcategories"
            yaxislabel1="Ton CO2e"
            ymetric1=""
            yaxislabel2="Ton CO2e"
            ymetric2=""
            
            :datanames="graph.names"
            :datatypes="graph.types"
            :dataset="graph.datasets"
            :colors="graph.colors"

            :yaxisindex="[1, 4]"
            :show="!$store.state.global.bot_mode"
        />
        <div v-else> No data found for {{$store.getters.selected_country_name}}</div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Deviation from '@/components/visualisations/general/Deviation.vue';

export default {
    props: {
        market: String
    },
    components: {
        Buttons,
        DropDown,
        Title,
        Deviation
    },
    data: function() {
        return {
            graph: {
                title: null,

                xcategories: null,
                yaxislabel: null,
                ymetric: null,
                datasets: null,
                names: ['YTD', 'YTD Index', 'YTD Goal','Month', 'Month Index', 'Year Goal'],
                types: ['line', 'line', 'line', 'line', 'line', 'line'],
                colors: [
                    this.$store.state.colors['focus-yellow'],
                    this.$store.state.colors['light-grey'],
                    this.$store.state.colors['dark-grey'],
                    '#0051BA',
                    '#49880c',
                    this.$store.state.colors.YG
                ],

                render: false
            },
            location: '/sustainability/climate-footprint'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.load_market_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
                // {
                //     name: '🔗 Climate Footprint - Food Ingredients',
                //     link: `/sustainability/climate-footprint-food-ingredients`
                // }
            ];
        },
        load_market_data: function() {
            this.$http.post('/sustainability/footprint/market', {
                market: this.$store.getters.market
            }).then(
                succ => {
                    let d = succ.data;
                    this.graph.xcategories = Object.keys(d[0].measures);
                    this.graph.datasets = [[], [], [], [], [], []];
                    Object.values(d[0].measures).map(val => {
                        this.graph.datasets[0].push(this.$helper.maybe(val['YTD'], null));
                        this.graph.datasets[1].push(this.$helper.maybe(val['YTD Index'], null));
                        this.graph.datasets[2].push(this.$helper.maybe(val['YTD Goal'], null));
                        this.graph.datasets[3].push(this.$helper.maybe(val['Month'], null));
                        this.graph.datasets[4].push(this.$helper.maybe(val['Month Index'], null));
                        this.graph.datasets[5].push(this.$helper.maybe(val['Year Goal'], null));
                    });
                    this.graph.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.graph.render = false;
            this.update_view(newMarket);
            this.load_market_data();
        }
    }
}
</script>

<style>

</style>
