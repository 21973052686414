<template>
<div>
    <Title
        title="Operational Climate Footprint"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                default=""
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms mb">
        <Top8Bar
            :key="top_8.render"

            title="Operational Climate Footprint Top 10 Countries"
            :showtitle="!$store.state.global.bot_mode"
            subtitle=""
            yaxislabel="Ton CO2e"
            ymetric=""

            :xcategories="top_8.xcategories"

            seriesname1="YTD"
            seriestype1="bar"
            :color1="$store.state.colors['yellow']"
            :seriesset1="top_8.seriesset1"

            seriestype2="scatter"
            
            seriestype3="scatter"
            
            seriesname4="YTD Goal"
            seriestype4="scatter"
            :color4="$store.state.colors.YG"
            :seriesset4="top_8.seriesset3"

        />
        <!-- Commented out as data may return at a later date Ben - 10/10/2020
            seriesname3="Month Index"
            :color3="$store.state.colors['grey']"
            :seriesset3="top_8.seriesset2"
        -->
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Top8Bar from '@/components/visualisations/bar/Top8Bar.vue';

export default {
    props: {
        market: String
    },
    components: {
        Buttons,
        DropDown,
        Title,
        Top8Bar
    },
    data: function() {
        return {
            top_8: {
                render: false,

                xcategories: [],

                seriesset1: [],                
                seriesset2: [],
                seriesset3: [],
                seriesset4: [],

                ranking: [],
                competitors: []
            },
            location: '/sustainability/climate-footprint'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.load_market_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
                // {
                //     name: '🔗 Climate Footprint - Food Ingredients',
                //     link: `/sustainability/climate-footprint-food-ingredients`
                // }
            ];
        },
        load_market_data: function() {
            this.$http.get('/sustainability/footprint/top-8')
            .then(
                (succ) => {
                    let d = succ.data;
                    d.map(row => {
                        this.top_8.xcategories.push(row.caption);
                        this.top_8.seriesset1.push(this.$helper.maybe(row.measures['YTD']));
                        // this.top_8.seriesset2.push(this.$helper.maybe(row.measures['Month Index']));
                        this.top_8.seriesset3.push(this.$helper.maybe(row.measures['YTD Goal']));
                    });
                    this.top_8.render = true;
                },
                (error) => console.log(error)
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        }
    }
}
</script>

<style>

</style>