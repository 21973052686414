var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Button",
        {
          staticClass: "full-width mb mt",
          on: {
            click: function ($event) {
              return _vm.back()
            },
          },
        },
        [_vm._v("Cancel")]
      ),
      _c("BackModal", {
        attrs: { back_clicked: _vm.back_clicked },
        on: {
          "clear-storage": _vm.clear_storage,
          "toggle-edit-mode": function ($event) {
            return _vm.$emit("toggle-edit-mode")
          },
          cancel: function ($event) {
            _vm.back_clicked = false
          },
        },
      }),
      _c(
        "div",
        { staticClass: "schedule mb" },
        [
          _c("SwitchBoxUniversal", {
            staticClass: "mb",
            attrs: {
              label: "Posting on someones behalf?",
              element_id: "new-switch",
            },
            on: { switch_toggle: _vm.switch_toggle },
          }),
          _vm.posting_on_behalf
            ? _c("UserSearch", { on: { SelectedUser: _vm.selected_user } })
            : _vm._e(),
          _c("WYSIWYGEditor", {
            attrs: { content: _vm.new_comment.content },
            on: { textUpdate: _vm.update_content },
          }),
          _c(
            "div",
            {
              staticClass: "character-count",
              class: _vm.character_alert ? "character-alert" : "",
            },
            [
              _vm._v(" " + _vm._s(_vm.character_count) + "/2250 "),
              _vm.character_alert
                ? _c("div", [
                    _vm._v(
                      "Text may have to be cut off when put into the Powerpoint extract"
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _c("p", [_vm._v("Select the start and end dates for the comment:")]),
          _c("DateTimePicker", { on: { updated: _vm.update_scheduled_date } }),
        ],
        1
      ),
      _c("InfoButtons", {
        attrs: {
          buttons: _vm.buttons,
          calculated_status: _vm.calculated_status,
        },
        on: { update: _vm.create_new },
      }),
      _vm.definitionType !== "global"
        ? _c(
            "div",
            [
              _vm._m(0),
              _vm._l(_vm.global_comments, function (comment, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "comment-container",
                    class:
                      _vm.comments.length - 1 !== i ? "comment-border" : "",
                  },
                  [
                    _c("div", { staticClass: "comment-meta" }, [
                      _c("div", { staticClass: "ikea-blue-text" }, [
                        _vm._v(
                          " " +
                            _vm._s(comment.scheduled_date.split("T")[0]) +
                            " - " +
                            _vm._s(comment.comment_lifetime.split("T")[0]) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "columns is-gapless" }, [
                        _c("div", { staticClass: "column" }, [
                          !_vm.audio_hash ||
                          !(comment.comment_id in _vm.audio_hash)
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "button tts-button ikea-blue-text ft-tooltip",
                                  attrs: { tooltiptext: "Text to Speech" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.load_audio(
                                        comment.content,
                                        comment.comment_id
                                      )
                                    },
                                  },
                                },
                                [
                                  !_vm.loading
                                    ? _c("i", {
                                        staticClass: "fas fa-volume-up",
                                      })
                                    : _vm._e(),
                                  _vm.loading
                                    ? _c("i", {
                                        staticClass: "fas fa-spinner fa-spin",
                                      })
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "column is-8" }, [
                          comment.updated_by || comment.a_display_name
                            ? _c(
                                "div",
                                {
                                  staticClass: "ft-tooltip-bottom",
                                  attrs: {
                                    tooltiptext: `
                                    ${
                                      comment.updated_by
                                        ? `Edited by: ${comment.updated_by} ${
                                            comment.a_display_name ? "|" : ""
                                          }`
                                        : ""
                                    }
                                `,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(comment.u_display_name) + " "
                                  ),
                                ]
                              )
                            : _c("div", [
                                _vm._v(_vm._s(comment.u_display_name)),
                              ]),
                        ]),
                      ]),
                      _c("div", [
                        comment.updated_by
                          ? _c(
                              "div",
                              {
                                staticClass: "ft-tooltip",
                                attrs: { tooltiptext: comment.e_display_name },
                              },
                              [_vm._v(" |Edited ")]
                            )
                          : _vm._e(),
                        comment.a_display_name
                          ? _c(
                              "div",
                              {
                                staticClass: "ft-tooltip",
                                attrs: { tooltiptext: comment.a_display_name },
                              },
                              [_vm._v(" |Posted By ")]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm.audio_hash && comment.comment_id in _vm.audio_hash
                      ? _c("div", { staticClass: "mt" }, [
                          _c(
                            "audio",
                            {
                              staticClass: "active-audio",
                              attrs: {
                                controls: "controls",
                                autobuffer: "autobuffer",
                              },
                            },
                            [
                              _c("source", {
                                attrs: {
                                  src:
                                    _vm.api_get_url +
                                    _vm.audio_hash[comment.comment_id],
                                },
                              }),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _c("div", {
                      staticClass: "comment-content ProseMirror",
                      domProps: { innerHTML: _vm._s(comment.content) },
                    }),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", [_c("strong", [_vm._v("Insights (Global)")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }