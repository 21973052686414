var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: {
          title: _vm.title,
          location: _vm.location,
          version_api_url: _vm.version_api_url,
        },
      }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.tabs, dataset: _vm.table },
          }),
          _vm.filter_render
            ? _c(
                "div",
                _vm._l(_vm.filter_keys, function (filter, i) {
                  return _c(
                    "div",
                    { key: i },
                    [
                      _c("DropDown2", {
                        attrs: {
                          label: filter[0],
                          name: filter[1],
                          options: _vm.filters[filter[1]].options,
                          values: _vm.filters[filter[1]].values,
                          default: _vm.filters[filter[1]].state,
                        },
                        on: { updateDropDown: _vm.update_filter },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.measure_filter
            ? _c(
                "div",
                [
                  _c(
                    "DropDown2",
                    _vm._b(
                      { on: { updateDropDown: _vm.update_measure } },
                      "DropDown2",
                      _vm.measure_filter,
                      false
                    )
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                key: _vm.$store.getters.market,
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: _vm.$store.getters.market,
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }