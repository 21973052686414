<template>
<div>
    <Title
        title="People + Planet Positive Sales"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                default=""
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms mb">
        <Top8Bar
            :key="top_8.render"

            title="Sustainability Sales Top 10 Countries"
            :showtitle="!$store.state.global.bot_mode"

            yaxislabel="Share of Total Sales"

            ymetric="%"

            :xcategories="top_8.xcategories"

            seriesname1="YTD"
            seriestype1="bar"
            :color1="$store.state.colors['yellow']"
            :seriesset1="top_8.seriesset1"
            
            seriestype2="scatter"
            
            seriesname3="1WK Trend"
            seriestype3="scatter"
            :color3="$store.state.colors['grey']"
            :seriesset3="top_8.seriesset2"
            
            seriesname4="Year Goal"
            seriestype4="scatter"
            :color4="$store.state.colors.YG"
            :seriesset4="top_8.seriesset4"

            :trend="true"
            :ranking="top_8.ranking"
            :competitors="[]"
        />
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Top8Bar from '@/components/visualisations/bar/Top8Bar.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Title,
        Top8Bar
    },
    data: function() {
        return {
            top_8: {
                render: false,

                xcategories: null,
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],
                seriesset4: [],
                ranking: []
            },
            location: '/sustainability/sustainable-sales'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.load_top_8();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
            ];
        },
        load_top_8: function() {
            this.$http.get('/sustainability/sales/top-8').then(
                succ => {
                    let d = succ.data;
                    if (!d) return;
                    this.top_8.xcategories = d.map(row => row.caption);
                    d.map(row => {
                        this.top_8.seriesset1.push(
                            this.$helper.maybe(row.measures['YTD Sustainable sales share'])
                        );
                        this.top_8.seriesset2.push(
                            this.$helper.maybe(row.measures['Week Sustainable sales share'])
                        )
                        this.top_8.seriesset4.push(
                            this.$helper.maybe(row.measures['Year Goal'])
                        );
                    });

                    this.top_8.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        }
    }
}
</script>

<style>

</style>
