<template>
<div>
    <Title
        title="Cost Per Country"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container">
            <p>Cost Type</p>
            <DropDown
                :key="values[0]"
                :options="options"
                :values="values"
                :default="values[0]"
                @updateDropDown="update_filter"
            />
        </div>
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <Table
        :headers="table.headers"
        :rows="table.rows"
        :icon-settings="table.icon_settings"
        @clickedTable="table_update"
        
        class="ms1p5 no-all"
    />
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/Table.vue';
import Title from '@/components/ui/Title.vue';


export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: []
            },
            location: '/finance/cost-per-country',
            options: [],
            values: [],
            filter: null,
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'By Market',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                }
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'fin-cost-1'
            }).then(
                succ => {
                    succ.data.map(filter => {
                        this.values.push(filter.value);
                        this.options.push(filter.option);
                    });
                    this.filter = this.values[1];
                    
                },
                this.$helper.error
            );
            this.load_ingka_by_country();
        },
        load_ingka_by_country: function() {
            this.$http.post(`/finance/cost-per-country/total-ingka-by-country`, {filter: this.filter}).then(
                succ => {
                    let d = succ.data;
                    let keys = Object.keys(d[0].measures);
                    this.table.headers = ['Country'].concat(keys);
                    this.table.rows = d.map(row => {
                        let temp = [];
                        // If the key/values don't exist, set them to 0
                        keys.map(key => {
                            temp.push(this.$helper.maybe(row.measures[key]));
                        });
                        return [row.caption].concat(temp);
                    });
                    this.table.icon_settings = {
                        'YTD': {
                            append: '%',
                            comparison: 'Goal',
                            icon: 'circle'
                        },
                        'FC': {
                            append: '%'
                        },
                        'Goal': {
                            append: '%'
                        }                        
                    }
                },
                this.$helper.error
            );
        },
        table_update: function(country_name) {
            let market_index = this.$store.getters.countries.options.findIndex(country => country === country_name);
            if (market_index > -1) {
                let market = this.$store.getters.countries.values[market_index];
                this.update_view(market);
            } else {
                this.update_view(null);
            }
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        },
        filter: function() {
            this.load_ingka_by_country();
        } 
    }
}
</script>