<template>
<div>
    <Title
        title="Goods value connected to a service"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <Table
        :key="table.render"

        :headers="table.headers"
        :rows="table.rows"
        :decimal-settings="table.decimal_settings"
        :icon-settings="table.icon_settings"
        :sort-settings="{ 'YTD MEUR': { asc: false }}"
        :font-settings="{ bold: { row: { regex: 'Total Retail Countries' } } }"
        class="ms1p5"
    />
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';


export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: [],
                decimal_settings: {},
            },
            location: '/business/sales-per-range/goods-value-connected'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.load_ingka_by_country();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
                {
                    name: 'By HFB',
                    link: `${this.location}/by-hfb/${this.$store.getters.market}`
                },
            ];
        },
        load_ingka_by_country: function() {
            this.table.render = false;
            this.$http.get('/business/sales-per-range/goods-value-connected/total-ingka-by-country').then(
                succ => {
                    if (!succ.data) return;
                    let { headers, rows } = this.$helper.table.parse(succ.data);
                    if (succ.data[0].measure_metadata) {
                        this.table.decimal_settings = succ.data[0].measure_metadata.decimal_settings || {};
                    }
                    this.table.headers = headers;
                    this.table.rows = rows;
                    this.table.icon_settings = {
                        '4WK Index': {
                            comparison: 'YTD Index',
                            icon: 'arrow'                       
                        },
                        'Growth Contribution YTD': {
                            comparison: 'Growth Contribution YTD',
                            icon: 'bar',
                            ignore: ['Total Retail Countries']
                        }
                    };
                    this.$helper.header_replace(this.table.headers, {
                        '4wk Index': '4WK Index'
                    });

                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    }
}
</script>
