<template>
<div>
    <div class="icon" v-if="icon === 'Better Homes'">
        <img src="assets/images/icons/homes.svg" alt="Better Homes"/>
        <div class="tagline">Better Homes</div>
    </div>
    <div class="icon" v-if="icon === 'Better Company'">
        <img src="assets/images/icons/company.svg" alt="For Now and Future Generations"/>
        <div class="tagline">Better Company</div>
    </div>
    <div class="icon" v-if="icon === 'Better Planet'">
        <img src="assets/images/icons/planet.svg" alt="Better Company"/>
        <div class="tagline">Better Planet</div>
    </div>
    <div class="icon" v-if="icon === 'Better Lives'">
        <img src="assets/images/icons/lives.svg" alt="Better Planet"/>
        <div class="tagline">Better Lives</div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        icon: String
    },
}
</script>
<style scoped>
.icon {
    width: 4rem;
    margin: 0.375rem 0 0 0.5rem;
    color: black;
    position: absolute;
    left: 1rem;
}
.tagline {
    font-size: 0.7rem;
    max-width: 3.8rem;
    overflow-wrap: break-word;
    position: absolute;
}
</style>
