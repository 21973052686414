<template>
    <div
        class="navbar-item navbar-link text-left"
        v-if="!$store.state.global.legacy_menu"
        @mouseenter="entering_dropdown()"
        @mouseleave="leaving_dropdown()"
        @click="toggle($event.target)"
    >
        <div class="mobile-title" @mouseover="open_dropdown()">KPIs</div>
        <div id="navbar-dropdown" class="navbar-dropdown">
            <div class="nested navbar-item dropdown dropdown-text">
                <div class="dropdown-trigger mobile-bold">
                    <div aria-haspopup="true" aria-controls="dropdown-menu">
                        <div class="tile is-ancestor">
                            <div class="tile is-vertical is-parent dropdown-column">
                                <KpiDropdownItem :index="0" />
                            </div>
                            <div class="tile is-vertical is-parent dropdown-column">
                                <KpiDropdownItem :index="1" />
                            </div>
                            <div class="tile is-vertical is-parent dropdown-column">
                                <KpiDropdownItem :index="2" />
                            </div>
                            <div class="tile is-vertical is-parent dropdown-column">
                                <KpiDropdownItem :index="3" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KpiDropdownItem from '@/components/ui/KpiDropdownItem.vue';
export default {
    components: {
        KpiDropdownItem
    },
    data() {
        return {
            dropdown: 'kpi',
            timeoutId: null,
            isActive: false
        };
    },
    mounted: function () {
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy: function () {
        document.removeEventListener('click', this.handleClickOutside);
    },
    watch: {
        '$store.getters.dropdown_open': function (newValue) {
            if (newValue !== this.dropdown) {
                this.close_dropdown();
            }
        }
    },
    methods: {
        toggle: function (target) {
            console.log(target);
            if (
                !target.classList.contains('kpi-dropdown-item') &&
                !target.classList.contains('fas') &&
                !target.classList.contains('mlp5')
            ) {
                this.toggleDropdown();
            }
        },
        open_dropdown: function () {
            // Hide specific menu items from kpi menu dropdwon
            const items = this.$el.querySelectorAll('.kpi-dropdown-item .mlp5');
            items.forEach(item => {
                if (['Market View', 'Country Overview'].includes(item.textContent.trim())) {
                    item.parentElement.style.display = 'none';
                }
            });

            document.getElementById('navbar-dropdown').style.display = 'block';
            this.$store.commit('set_dropdown_open', this.dropdown);
        },
        leaving_dropdown: function () {
            this.timeoutId = setTimeout(() => {
                this.close_dropdown();
            }, 800);
        },
        entering_dropdown: function () {
            clearTimeout(this.timeoutId);
        },
        close_dropdown: function () {
            document.getElementById('navbar-dropdown').style.display = 'none';
        },
        toggleDropdown() {
            this.isActive = !this.isActive;
            if (this.isActive) {
                this.open_dropdown();
            } else {
                this.close_dropdown();
            }
        },
        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.close_dropdown();
            }
        }
    }
};
</script>

<style scoped>
@media screen and (max-width: 1228px) {
    .navbar-dropdown {
        left: -11rem !important;
    }
}

@media screen and (max-width: 1024px) {
    .navbar-link:hover {
        background-color: #ffffff;
    }
    .mobile-title {
        color: #4a4a4a;
        text-align: center !important;
        font-weight: bold;
    }
}
@media screen and (min-width: 1024px) {
    .tile.is-vertical {
        width: 25vw;
    }
}

.navbar-dropdown {
    cursor: default;
    width: 100vw;
    left: -21rem;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1rem 0 2rem;
}

.tile.is-vertical > .tile.is-child:not(:last-child) {
    margin-bottom: 0rem !important;
}
</style>
