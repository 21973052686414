<template>
<div>
    <Title
        title="Operational Gross Margin"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
        
        <div class="label-container" 
            :class="!$store.getters.market ? 'ft-tooltip': null" 
            :tooltiptext="!$store.getters.market ? 'Select a country from top filter to see stores' : null"
        >
            <p>Market Area</p>
            <DropDown
                :key="stores.values.length"
                :options="stores.options"
                :values="stores.values"
                :default="stores.values[0]"
                @updateDropDown="update_store"
            />
        </div>
        <div class="label-container">
            <p>HFB</p>
            <DropDown
                v-if="hfbs.all.length > 0"
                :key="hfbs.all.length"
                :options="hfbs.options"
                :values="hfbs.values"
                :default="hfbs.values[0]"
                @updateDropDown="update_hfb"
            />
        </div>
        <div class="label-container">
            <p>Gross Margin</p>
            <DropDown
                :key="filter_gross_margin"
                :options="options_gross_margin"
                :values="values_gross_margin"
                :default="filter_gross_margin"
                @updateDropDown="update_filter_gross_margin"
            />
        </div>
        <div class="label-container">
            <p>Week</p>
            <DropDown
                :key="values_week[0]"
                :options="options_week"
                :values="values_week"
                :default="values_week[0]"
                @updateDropDown="update_filter_week"
            />
        </div>

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
        
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms">
        <Quad
            :key="quad.render"
            v-if="quad.render"

            :title="`Showing data for: ${$store.getters.selected_country_name}`"
            :showtitle="!$store.state.global.bot_mode"
            subtitle=""

            :xcategories="quad.xcategories"
            :yaxislabel="this.filter_gross_margin"
            ymetric="%"
            
            :seriesname1="`TY ${this.filter_week}`"
            seriestype1="line"
            :seriesset1="quad.seriesset1"
            :color1="$store.state.colors['focus-yellow']"
            
            :seriesname2="`LY ${this.filter_week}`"
            seriestype2="line"
            :seriesset2="quad.seriesset2"
            :color2="$store.state.colors['light-grey']"
            
            seriesname3=""
            seriestype3="line"
            :seriesset3="quad.seriesset3"
            color3="#ADE"

            seriesname4=""
            seriestype4="line"
            :seriesset4="quad.seriesset4"
            :color4="$store.state.colors.YG"

            :show="!$store.state.global.bot_mode"
        />
        <div v-else> No data found for {{$store.getters.selected_country_name}} </div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Quad from '@/components/visualisations/general/Quad.vue';
import Title from '@/components/ui/Title.vue';


export default {
    props: {
        market: String
    },
    components: {
        Buttons,
        DropDown,
        Quad,
        Title
    },
    data: function() {
        return {
            quad: {
                title: null,
                subtitle: null,

                xcategories: null,
                yaxislabel: null,
                ymetric: null,
                
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],
                seriesset4: [],

                render: false
            },
            store: null,
            hfb: 'Total HFB',
            location: '/finance/operational-gross-margin',

            options_gross_margin: [],
            values_gross_margin: [],
            filter_gross_margin: null,

            options_week: [],
            values_week: [],
            filter_week: null,
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: `${this.location}`
                },
                {
                    name: 'Trend',
                    link: `${this.location}/trend/${this.$store.getters.market}`
                }
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'fin-gm-2'
            }).then(
                succ => {
                    succ.data.map(filter => {
                        this.values_gross_margin.push(filter.value);
                        this.options_gross_margin.push(filter.option);
                    });
                    this.filter_gross_margin = this.$store.getters.filter_check(this.values_gross_margin);
                },
                this.$helper.error
            );

            this.$http.post('/filters/load', {
                filter: '1'
            }).then(
                succ => {
                    succ.data.map(filter => {
                        this.values_week.push(filter.value);
                        this.options_week.push(filter.option);
                    });
                    this.filter_week = this.values_week[0];
                },
                this.$helper.error
            );
            this.load_ingka_by_country();
        },
        load_ingka_by_country: function() {
            let url = '/finance/operational-gross-margin/trend';
            let succ = (succ) => {
                let d = succ.data;
                if (!d || !d[0] || !d[0].measures) return;
                this.quad.xcategories = Object.keys(d[0].measures);
                this.quad.seriesset1 = [];
                this.quad.seriesset2 = [];
                this.quad.seriesset3 = [];
                this.quad.seriesset4 = [];

                Object.values(d[0].measures).map(val => {
                    this.quad.seriesset1.push(this.$helper.maybe(val[`${this.filter_week}`]));
                    this.quad.seriesset2.push(this.$helper.maybe(val[`${this.filter_week} LY`]));
                });

                this.quad.render = true;
            }

            let error = (error) => console.log(error);

            if (this.store === null) {
                if (this.$helper.market_check(this.market) === null) {
                    this.$http.post(url,
                        {
                            parent: 'TOP',
                            market: 'ALL',
                            hfb: this.hfb,
                            week_view: this.filter_week,
                            gross_margin: this.filter_gross_margin,
                        }
                    ).then(succ, error);
                } else {
                    this.$http.post(url,
                        {
                            parent: 'ALL',
                            market: this.$helper.market_check(this.market),
                            hfb: this.hfb,
                            week_view: this.filter_week,
                            gross_margin: this.filter_gross_margin,
                        }
                    ).then(succ, error);
                }
            } else {
                this.$http.post(url,
                    {
                        parent: this.$helper.market_check(this.market),
                        market: this.store,
                        hfb: this.hfb,
                        week_view: this.filter_week,
                        gross_margin: this.filter_gross_margin,
                    }
                ).then(succ, error);
            }
            
        },
        update_filter_gross_margin: function(filter) {
            this.filter_gross_margin = filter;
            this.$store.state.global.temp_filter = filter;
            this.quad.render = false;
        },
        update_filter_week: function(filter) {
            this.filter_week = filter;
            this.quad.render = false;
        },
        update_hfb: function(hfb) {
            this.hfb = hfb;
            this.quad.render = false;
            this.load_ingka_by_country();
        },
        update_store: function(store) {
            this.store = store;
            this.quad.render = false;
            this.load_ingka_by_country();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: `${this.location}/trend`
            });
        }
    },
    watch: {
        market: function(old_val, new_val) {
            this.quad.render = false;
            this.load_ingka_by_country();
        },
        '$store.getters.countries': function(newCountries, oldCountries) {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        },
        filter_gross_margin: function() {
            this.load_ingka_by_country();
        },
        filter_week: function() {
            this.load_ingka_by_country();
        }
    },
    computed: {
        hfbs: function() {
            return this.$store.getters.hfbs;
        },
        stores: function() {
            if (this.$store.getters.stores.values.length) {
                return this.$store.getters.stores;
            }
            return {
                all: [],
                options: [],
                values: []
            }
        }
    }
}
</script>