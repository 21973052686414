<template>
<div>
	<AdminUniversal
		component="title"
		:props="title_props"
	/>
	<div class="text-left mb">
		<p>KPI Definitions that have received feedback on their perceived usefulness since last updated will appear in the table below.</p>
		<p>Positive feedback represents the number of people who perceived definition useful, and negative feedback represents the number of people who did not.</p>
		<p>Rating is the ratio of positive to negative feedback, a higher ratio meaning the definition was found more useful.</p>
	</div>
	<AdminUniversal
		v-if="table_props.render"
		component="table"
		:props="table_props"
	/>
</div>
</template>

<script>
import AdminUniversal from '@/components/admin/index.vue';

export default {
	components: {
		AdminUniversal
	},
	data: function() {
		return {
			title_props: {
				title: 'KPI Definitions Feedback'
			},
			table_props: {
                headers: ['KPI', 'KPI Area', 'Rating', 'Positive feedback', 'Negative feedback'],
                data: null,
                render: false
            },
		}
	},
	mounted: function() {
		this.load_table_data();
	},
	methods: {
		load_table_data: function() {
			this.table_props.data = [];
			this.$http.get('/admin/kpi-definition/ratings')
				.then(
					succ => {
						let d = succ.data;
						d.forEach(kpi => {
							const rating = this.$helper.round((Number.parseFloat(kpi.rating) * 100), 0);
							this.table_props.data.push({
								kpi: kpi.kpi,
								kpi_area: kpi.kpi_area,
								rating: `${rating}%`,
								up: kpi.up,
								down: kpi.down
							});
						});
						this.table_props.data.sort((a, b) => Number.parseFloat(a.rating.slice(0,-1)) - Number.parseFloat(b.rating.slice(0,-1)));
						this.table_props.render = true;
					},
					this.$helper.error
				);
		}
	}
}
</script>
