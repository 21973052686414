<template>
<div class="sub-grid">
    <!-- Index -->
    <div class="sub-item left first-row">{{showLabels ? labels[0] : ''}}</div>
    <div class="sub-item right first-row" :class="render_classes()">{{vals[0]}}</div>

    <!-- Year goal -->
    <div class="sub-item left light">{{showLabels ? labels[1] : ''}}</div>
    <div class="sub-item right light">{{vals[1]}}</div>

    <!-- Year goal share -->
    <div class="sub-item left light">{{showLabels ? labels[2] : ''}}</div>
    <div class="sub-item right light">{{vals[2]}}{{vals[2] !== 'n/a' ? '%' : ''}}</div>

    <!-- YTD sales share -->
    <div class="sub-item left">{{showLabels ? labels[3] : ''}}</div>
    <div class="sub-item right">{{vals[3]}}{{vals[3] !== 'n/a' ? '%' : ''}}</div>

    <!-- YTD sales -->
    <div class="sub-item left">{{showLabels ? labels[4] : ''}}</div>
    <div class="sub-item right">{{vals[4]}}</div>
</div>
</template>
<script>
export default {
    props: {
        values: Object,
        showLabels: {
            type: Boolean,
            default: true
        }
    },
    data: function() {
        return {
            labels: [
                'YTD (Index)',
                'Year Goal (Index)',
                'Year Goal Share',
                'YTD Share',
                'YTD Sales (MEUR)'
            ]
        }
    },
    methods: {
        render_classes: function() {
            if (this.values.year_goal_index) {
                if (this.values.ytd_index < this.values.year_goal_index) {
                    return {
                        'text-red': true
                    }
                }
                return {
                    'text-green': true
                }
            } else {
                if (this.values.ytd < 100) {
                    return {
                        'text-red': true
                    }
                }
                return {
                    'text-green': true
                }
            }
        }
    },
    computed: {
        vals: function() {
            return [
                this.values.ytd                || 'n/a', // YTD Index
                this.values.year_goal          || 'n/a',
                this.values.year_goal_share    || 'n/a',
                this.values.ytd_share          || 'n/a',
                this.values.ytd_sales          || 'n/a',
            ];
        }
    }
}
</script>
<style lang="scss">
.sub-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);

    .sub-item {
        line-height: 1.5rem;
        font-weight: bold;

        &.text-red {
            color: #b5072a;
        }
        &.text-green {
            color: #049035;
        }
        
        &.light { font-weight: normal; font-style: italic; }
        &.left { text-align: left; }
        &.right { text-align: right; }
        &.first-row {
            &.left { align-self: end; }
            &.right { font-size: 2rem; }
        }
    }
}
</style>
