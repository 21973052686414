<template>
<div>
<Title
    title="Sales Prioritised Cities"
    :location="location"
/>
<div class="options-bar">
    <Buttons
        :buttons="generate_buttons()"
    />
</div>
<div class="columns three-quarters-height">
    <div class="column is-3">
        <BubbleGraphOptions
            :options="bubble.options"
            title="Select Cities"
            :selected-options="bubble.selected_options"

            @reset="reset_options"
            @selected="city_selected"
        />
    </div>
    <div class="column overflow-visible">
        <div :key="$store.getters.page_size" class="section half-height box ms">
            <Bubble
                v-if="bubble.render"

                title="Store Sales MEUR vs Online Sales MEUR"
                sub-title="The size of the bubble doesnt represent any data"
                :show-title="!$store.state.global.bot_mode"

                x-axis-label="Store Sales MEUR"
                y-axis-label="Online Sales MEUR"
                sizeLabel="Sales MEUR"
                :yMin="0"
                y-metric=""
                :colors="[]"

                :names="bubble2.selected_options"
                :datasets="bubble2.datasets"
            />
        </div>
        <div :key="$store.getters.page_size + 1" class="section half-height box ms">
            <Bubble
                v-if="bubble.render"

                title="City sales index vs Rest of country index"
                sub-title="Size based on combination of all sales in MEUR"
                :show-title="!$store.state.global.bot_mode"

                x-axis-label="Rest of Country Index"
                y-axis-label="City Sales Index"
                size-label="Online and Store Sales MEUR"
                :yMin="0"
                y-metric=""
                :colors="[]"

                :names="bubble.selected_options"
                :datasets="bubble.datasets"
            />
        </div>
    </div>
</div>
</div>
</template>

<script>
import Bubble from '@/components/visualisations/general/Bubble.vue';
import BubbleGraphOptions from '@/components/ui/BubbleGraphOptions.vue';
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Bubble,
        BubbleGraphOptions,
        Buttons,
        DropDown,
        Title
    },
    data: function() {
        return {
            bubble: {
                all_measures: null,
                datasets: null,
                options: null,
                render: false,
                selected_cities: null,
                top_10: null
            },
            bubble2: {
                all_measures: null,
                datasets: null,
                options: null,
                render: false,
                selected_cities: null,
                top_10: null
            },
            location: '/business/total-sales/sales-prioritized-thirty-cities',
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.load_bubble_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By City',
                    link: this.location
                },
                {
                    name: 'Accumulated',
                    link: `${this.location}/accumulated/null`
                },
                {
                    name: 'Bubble Graph',
                    link: `${this.location}/bubble-graph`
                },
            ];
        },
        load_bubble_data: function() {
            this.bubble.render = false;
            this.$http.get('business/total-sales/sales-prioritized-thirty-cities/bubble').then(
                succ => {
                    let d = succ.data;
                    const measures = d[0].measures;
                    const measures_keys = Object.keys(measures);
                    this.bubble.all_measures = measures;

                    /* City Sales Index vs Rest of Country Index */
                    this.bubble.all_measures = measures_keys.map(key => {
                        const measure = measures[key];
                        return {
                            name: key,
                            values: {
                                x: measure['City Sales Index'],
                                y: measure['Rest of Country Index'],
                                size: measure['Store Sales MEUR'] + measure['Online Sales MEUR'],
                                display_size: (measure['Store Sales MEUR'] + measure['Online Sales MEUR']) * 0.7
                            }
                        };
                    });

                    /* Store Sales MEUR vs Online Sales MEUR */
                    this.bubble2.all_measures = measures_keys.map(key => {
                        const measure = measures[key];
                        return {
                            name: key,
                            values: {
                                x: measure['Store Sales MEUR'],
                                y: measure['Online Sales MEUR'],
                                display_size: 10
                            }
                        };
                    });

                    this.bubble.options = Object.keys(measures);

                    this.bubble.top_10 = this.bubble.all_measures.sort((a, b) => 
                        (b.values.x + b.values.y) - (a.values.x + a.values.y)
                    ).map(city => city.name).slice(0, 10);

                    this.bubble.selected_options = [...this.bubble.top_10];
                    this.filter_cities();
                    this.bubble.render = true;
                },
                this.$helper.error
            );
        },
        city_selected: function(selected_options) {
            this.bubble.render = false;
            this.bubble.selected_options = [...selected_options];
            this.filter_cities();
            this.$nextTick(() => this.bubble.render = true);
        },
        filter_cities: function() {
            this.bubble.datasets = this.bubble.all_measures.filter(
                measure => this.bubble.selected_options.includes(measure.name)
            );
            this.bubble2.datasets = this.bubble2.all_measures.filter(
                measure => this.bubble.selected_options.includes(measure.name)
            );
        },
        reset_options: function() {
            this.bubble.render = false;
            this.bubble.selected_options = [...this.bubble.top_10];
            this.$nextTick(() => this.bubble.render = true);
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        }
    }
}
</script>

