<template>
<div>
    <Title
        title="IKEA Business Share of Sales"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
        
        <div class="label-container hidden-on-pc">
                <p>Country</p>
                <DropDown
                    :key="$store.getters.market"
                    :options="$store.state.countries.options"
                    :values="$store.state.countries.values"
                    :default="$store.getters.market"
                    @updateDropDown="update_view"
                />
            </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms">
        <Triple
            v-if="triple.render"

            :title="`Showing data for: ${$store.getters.selected_country_name}`"

            :xcategories="triple.xcategories"
            yaxislabel="IKEA Business sales share %"
            ymetric="%"
            
            seriesname1="YTD"
            seriestype1="line"
            :seriesset1="triple.seriesset1"
            :color1="$store.state.colors['focus-yellow']"
            
            seriesname2="4WK Trend"
            seriestype2="line"
            :seriesset2="triple.seriesset2"
            :color2="$store.state.colors['dark-grey']"
            
            seriesname3="Year Goal"
            seriestype3="line"
            :seriesset3="triple.seriesset3"
            :color3="$store.state.colors.YG"

            :show="!$store.state.global.bot_mode"
        />
        <div v-else> No data found for {{$store.getters.selected_country_name}}</div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Triple from '@/components/visualisations/general/Triple.vue';
export default {
    components: {
        Buttons,
        DropDown,
        Title,
        Triple
    },
    props: {
        market: String
    },
    data: function() {
        return {
            triple: {
                title: null,
                subtitle: null,

                xcategories: null,
                yaxislabel: null,
                ymetric: null,
                
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],

                render: false
            },
            location: '/business/ikea-business-share-of-sales'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.load_market_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
            ];
        },
        load_market_data: function() {
            this.$http.post('/business/ikea-business-share-of-sales/market', {
                market: this.$store.getters.market
            }).then(
                succ => {
                    let d = succ.data;
                    this.triple.xcategories = Object.keys(d[0].measures);
                    
                    this.triple.seriesset1 = [];
                    this.triple.seriesset2 = [];
                    this.triple.seriesset3 = [];
                    
                    this.triple.xcategories.map(month => {
                        let val = d[0].measures[month];
                        this.triple.seriesset1.push(this.$helper.maybe(val['YTD']));
                        this.triple.seriesset2.push(this.$helper.maybe(val['4 Weeks Trend']));
                        this.triple.seriesset3.push(this.$helper.maybe(val['Goal']));
                    });
                
                    this.triple.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.triple.render = false;
            this.update_view(newMarket);
            this.load_market_data();
        }
    }
}
</script>

<style>

</style>
