var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Button",
        {
          staticClass: "full-width mb mt",
          on: {
            click: function ($event) {
              return _vm.back()
            },
          },
        },
        [_vm._v("Cancel")]
      ),
      _c("BackModal", {
        attrs: { back_clicked: _vm.back_clicked },
        on: {
          "clear-storage": _vm.clear_storage,
          "toggle-edit-mode": function ($event) {
            return _vm.$emit("toggle-edit-mode")
          },
          cancel: function ($event) {
            _vm.back_clicked = false
          },
        },
      }),
      _c(
        "div",
        { staticClass: "schedule mb" },
        [
          _c("SwitchBoxUniversal", {
            staticClass: "mb",
            attrs: {
              label: "Posting on someones behalf?",
              element_id: "new-switch",
            },
            on: { switch_toggle: _vm.switch_toggle },
          }),
          _vm.posting_on_behalf
            ? _c("UserSearch", { on: { SelectedUser: _vm.selected_user } })
            : _vm._e(),
          _c("div", { staticClass: "summary text-left" }, [_vm._v("Summary")]),
          _c("WYSIWYGEditor", {
            key: _vm.seperated_content[0],
            attrs: { content: _vm.seperated_content[0] },
            on: { textUpdate: _vm.update_summary },
          }),
          _c("div", { staticClass: "columns mtp5" }, [
            _c(
              "div",
              { staticClass: "column is-6 pr" },
              [
                _c("div", { staticClass: "strengthen text-left" }, [
                  _vm._v("To Further Strengthen"),
                ]),
                _c("WYSIWYGEditor", {
                  key: _vm.seperated_content[1],
                  attrs: { content: _vm.seperated_content[1] },
                  on: { textUpdate: _vm.update_strengthen },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "column is-6" },
              [
                _c("div", { staticClass: "action text-left" }, [
                  _vm._v("Call for action"),
                ]),
                _c("WYSIWYGEditor", {
                  key: _vm.seperated_content[2],
                  attrs: { content: _vm.seperated_content[2] },
                  on: { textUpdate: _vm.update_action },
                }),
              ],
              1
            ),
          ]),
          _c("p", [_vm._v("Select the start and end dates for the comment:")]),
          _c("DateTimePicker", { on: { updated: _vm.update_scheduled_date } }),
        ],
        1
      ),
      _c("InfoButtons", {
        attrs: {
          buttons: _vm.buttons,
          calculated_status: _vm.calculated_status,
        },
        on: { update: _vm.create_new },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }