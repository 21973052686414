<template>
<div>
    <Title
        title="Services Gross Margin deviation By Service"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
        <div class="label-container">
            <p>After Sales</p>
            <DropDown
                :key="filter.after_sales.state"
                :options="filter.after_sales.options"
                :values="filter.after_sales.values"
                :default="filter.after_sales.state"
                @updateDropDown="update_filter_after_sales"
            />
        </div>

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>

    <Table
        v-if="table.render"
        
        :headers="table.headers"
        :rows="table.rows"
        :bridge-settings="table.bridge_settings"
        :icon-settings="table.icon_settings"
        :decimal-settings="table.decimal_settings"
        :data-settings="table.data_settings"
        :sort-settings="{static: ['Total delivery&collect/picking services','Total other services','ALL SERVICE TYPES']}"

        :font-settings="{ bold: {row: {regex: /all service|total/ } } }"
        :metaSettings="{is_selectable: false}"
        class="ms1p5"
    />
    <div v-else>No data found for {{$store.getters.selected_country_name}}</div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    props: {
        market: String
    },
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            filter: {
                after_sales: {
                    state: null,
                    options: [],
                    values: []
                }
            },
            table: {
                headers: [],
                render: false,
                rows: [],
                decimal_settings: {},
                data_settings: { nullify_zeros: true }
            },
            location: '/finance/service-net/gm-deviation'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'By Service',
                    link: `${this.location}/by-market/${this.$store.getters.market}`
                },
                {
                    name: 'Waterfall',
                    link: `${this.location}/waterfall/${this.$store.getters.market}`
                },
                {
                    name: 'By Country (Graph)',
                    link: `${this.location}/graph`
                },
                {
                    name: 'By Service (Graph)',
                    link: `${this.location}/by-market-graph/${this.$store.getters.market}`
                }
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'service-net-after-sales',
                disable_sort: true
            }).then(
                succ => {
                    succ.data.forEach(filter => {
                        this.filter.after_sales.values.push(filter.value);
                        this.filter.after_sales.options.push(filter.option);
                    });
                    this.filter.after_sales.state = this.$store.getters.filter_check(this.filter.after_sales.values); 
                },
                this.$helper.error
            );
        },
        load_ingka_by_country: function() {
            this.table.render = false;
            if (this.filter.after_sales.state === null) return;
            this.$http.post('finance/service-net-dashboard/gm-by-service', {
                after_sales_filter: this.filter.after_sales.state,
                market: this.$store.getters.market
            }).then(
                succ => {
                    if (!succ.data) return;
                    let d = succ.data;
                    if (d[0].measure_metadata) {
                        this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                    }
                    this.table.headers = null;
                    this.table.rows = null;
                    let keys = Object.keys(d[0].measures);

                    this.table.icon_settings = {
                        'GM Goal': {
                            fix: { post: '%' }
                        },
                        'GM FC': {
                            fix: { post: '%' }
                        },
                        'GM Actual': {
                            fix: { post: '%' }
                        },
                        'GM LY': {
                            fix: { post: '%' }
                        },
                        'Actual vs Goal Points': {
                            fix: { post: '%' }
                        },
                        'Actual vs Goal kEuro': {
                            icon: 'bar',
                            comparison: 'Actual vs Goal kEuro',
                            ignore: ['ALL SERVICE TYPES']
                        },
                        'Actual vs FC Points': {
                            fix: { post: '%' }
                        },
                        'Actual vs FC kEuro': {
                            icon: 'bar',
                            comparison: 'Actual vs FC kEuro',
                            ignore: ['ALL SERVICE TYPES']
                        },
                        'Goal vs FC Points': {
                            fix: { post: '%' }
                        },
                        'Goal vs FC Euro': {
                            icon: 'bar',
                            comparison: 'Goal vs FC Euro',
                            ignore: ['ALL SERVICE TYPES']
                        },
                        'Actual vs LY Points': {
                            fix: { post: '%' }
                        },
                        'Actual vs LY Euro': {
                            icon: 'bar',
                            comparison: 'Actual vs LY Euro',
                            ignore: ['ALL SERVICE TYPES']
                        },
                    }

                    this.table.headers = [
                        [
                            { [this.$store.getters.selected_country_name]: 1, },
                            { [`GM (${this.filter.after_sales.state.toLowerCase().substring(12,16)}. After Sales)`]: 4, },
                            { 'GM Deviation': 8, },
                        ],
                        [
                            { '': 1 },
                            { '': 4 },
                            { 'Actual vs Goal': 2},
                            { 'Actual vs FC': 2},
                            { 'Goal vs FC': 2 },
                            { 'Actual vs LY': 2 },
                        ],
                        [
                            { 'Service type': 1},
                            { 'Goal': 1, alias: 'GM Goal'},
                            { 'FC': 1, alias: 'GM FC'},
                            { 'Actual': 1, alias: 'GM Actual'},
                            { 'LY': 1, alias: 'GM LY'},
                            { '% points': 1, alias: 'Actual vs Goal Points'},
                            { 'kEuro': 1, alias: 'Actual vs Goal kEuro'},
                            { '% points': 1, alias: 'Actual vs FC Points'},
                            { 'kEuro': 1, alias: 'Actual vs FC kEuro'},
                            { '% points': 1, alias: 'Goal vs FC Points'},
                            { 'kEuro': 1, alias: 'Goal vs FC Euro'},
                            { '% points': 1, alias: 'Actual vs LY Points'},
                            { 'kEuro': 1, alias: 'Actual vs LY Euro'},
                        ]
                    ];

                    this.table.bridge_settings = {
                        columns: {
                            'GM (incl.After Sales)': { hide: true },
                            'GM Deviation': { hide: true }                        
                        }
                    }
                    this.table.rows = d[0].measures.map(row => {
                        let val = row;
                        let t = [];
                        // Push the measures
                        t.push(
                            val["service_type"],
                            val["gm_goal"],
                            val["gm_fc"],
                            val["gm_actual"],
                            val["gm_ly"],
                            val["actual_vs_goal_points"],
                            val["actual_vs_goal_keuro"],
                            val["actual_vs_fc_points"],
                            val["actual_vs_fc_keuro"],
                            val["goal_vs_fc_points"],
                            val["goal_vs_fc_keuro"],
                            val["actual_vs_ly_points"],
                            val["actual_vs_ly_keuro"]
                        )
                        return t;
                    });

                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        update_filter_after_sales: function(after_sales_filter) {
            this.filter.after_sales.state = after_sales_filter;
            this.$store.state.global.temp_after_sales_filter = after_sales_filter;
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: `${this.location}/by-market`
            });
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.load_ingka_by_country();
        },
        'filter.after_sales.state': function() {
            this.load_ingka_by_country();
        }
    }
}
</script>
