<template>
<div>
    <Title
        title="Profit & Loss Per Country"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />
        <div class="label-container" 
            :class="!$store.getters.market ? 'ft-tooltip': null" 
            :tooltiptext="!$store.getters.market ? 'Select a country from top filter to see stores' : null"
        >
            <p>Market Area</p>
            <DropDown
                :key="stores.values.length"
                :options="stores.options"
                :values="stores.values"
                :default="stores.values[0]"
                @updateDropDown="update_store"
            />
        </div>
    
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <Table
        v-if="table.render"
        :headers="table.headers"
        :rows="table.rows"
        :icon-settings="table.icon_settings"
        :metaSettings="{is_selectable: false}"
        :font-settings="{ bold: {row: {regex: 'Total Revenue' } } }"
        :sort-settings="{ 'MEUR (GFAR20)': { asc: true } }"
        
        class="ms1p5"
    />
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: [],
                icon_settings: {},
                render: false
            },
            store: null,
            location: '/finance/profit-and-loss-per-country',
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.load_ingka_by_country();
    },
    methods: {
        load_ingka_by_country: function() {
            this.table.render = false;
            let url = '/finance/profit-and-loss-per-country/total-ingka-by-country';
            let succ = (succ) => {
                let d = succ.data;
                this.table.headers, this.table.rows = [];

                let keys = Object.keys(d[0].measures[Object.keys(d[0].measures)[0]]);
                this.table.headers = [['MEUR (GFAR20)'].concat(keys).map(c => {
                    let h = {};
                    h[c] = 1;
                    return h;
                })];
                Object.keys(d[0].measures).forEach(row => {
                    let temp = [];
                    // If the key/values don't exist, set them to 0
                    temp.push(row);
                    keys.map(key => {
                        temp.push(this.$helper.maybe(d[0].measures[row][key]));
                    });
                    this.table.rows.push(temp);
                });

                this.table.icon_settings = {
                    ignore: [ // Temporary hack around to ignore rows
                        'Total Revenue'
                    ],
                    'YTD Index or Ratio': {
                        fix: { post: '%' }
                    },
                    'Goal Index or Ratio': {
                        fix: { post: '%' }
                    },
                    'FC Index or Ratio': {
                        fix: { post: '%' }
                    }
                }
                this.table.render = true;
            }
            let error = (error) => console.log(error);
            
            if (this.store === null) {
                if (this.$store.getters.market === null) {
                    this.$http.post(url, {parent: 'TOP', market: 'ALL'}).then(succ, error);
                } else {
                    this.$http.post(url, {parent: 'ALL', market: this.$store.getters.market}).then(succ, error);
                }
            } else {
                this.$http.post(url, {parent: `${this.$store.getters.market}`, market: `${this.store}`}).then(succ, error);
            }
        },
        update_store: function(store) {
            this.store = store;
            this.load_ingka_by_country();
        },
        update_view: function(market) {
            this.store = null
            this.load_ingka_by_country();
            
            if (market != null) {
                this.$store.commit('update_view', {
                    market: market,
                    location: this.location
                });
            } else {
                this.$store.commit('update_view', {
                    market: market,
                    location: `${this.location}/null`
                });
            }
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        },
        '$store.getters.stores': function(newStores, oldStores) {
            return this.$store.getters.stores;
        }
    },
    computed: {
        stores: function() {
            if (this.$store.getters.stores.values.length) {
                return this.$store.getters.stores;
            } else {
                return {
                    all: [],
                    options: [],
                    values: []
                }
            }
        }
    }
}
</script>