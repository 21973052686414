<template>
<div id="kitchensink">
    <Title
        title="Gross Margin"
    />

    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="is-pulled-left"  
        />
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>

    <div class="section box">
        <iframe
            title="FlightTowerTest"
            :src="`https://app.powerbi.com/reportEmbed?${report_params}reportId=3db25195-6a3e-4bb8-8c29-6ada9ab40893&autoAuth=true&ctid=720b637a-655a-40cf-816a-f22f40755c2c`"
            frameborder="0"
            allowFullScreen="true">
        </iframe>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
export default {
    components: {
        Buttons,
        DropDown,
        Title,
    },
    data: function() {
        return{
            location: '/testing/power-bi/gross-margin',
            report_page_name: 'ReportSection59f36ceb99434f32f2d9'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
    },
    destroyed: function() {
    },
    computed: {
        report_params: function() {
            let params_string = ''
            // if (this.$store.getters.selected_country_name && this.$store.getters.market) {
            //     params_string += `$filter=Query1/Retail_x0020_Unit eq '${this.$store.getters.selected_country_name}'&`;
            // }
            if (this.report_page_name) {
                params_string += `pageName=${this.report_page_name}&`
            }
            return params_string;
        }
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'By Market',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top`
                }
            ];
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        },
    }
}
</script>

<style scoped>
iframe {
    height: 100%;
    width: 100%;
}
.section{
    float: left;
    margin-left: 1em;
    margin-top: -1em;
}
.box{
    padding: 0;
    width: 1100px;
    height: 600px;
}
</style>