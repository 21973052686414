var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.kpi_trail.length > 0,
            expression: "kpi_trail.length > 0",
          },
        ],
        staticClass: "button float-left is-info mr",
        on: {
          click: function ($event) {
            return _vm.back()
          },
        },
      },
      [_vm._v("Back")]
    ),
    _c("div", { staticClass: "text-left is-size-4 is-capitalized" }, [
      _vm._v(_vm._s(_vm.kpi_trail_bread_crumb)),
    ]),
    _vm.filtered_options.length
      ? _c(
          "div",
          [
            _vm.kpi_trail.length === 0 && _vm.global_location
              ? _c(
                  "div",
                  {
                    staticClass: "kpi-choice",
                    on: {
                      click: function ($event) {
                        return _vm.choose({ name: "Global", path: "/" })
                      },
                    },
                  },
                  [_vm._v(" Global ")]
                )
              : _vm._e(),
            _vm._l(_vm.filtered_options, function (kpi, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "kpi-choice",
                  on: {
                    click: function ($event) {
                      return _vm.choose(kpi)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(kpi.name) + " ")]
              )
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }