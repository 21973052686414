var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: _vm.render, staticClass: "node text-left" },
    _vm._l(_vm.node, function (obj, name, index) {
      return _c("div", { key: index }, [
        !_vm.is_leaf(obj)
          ? _c(
              "div",
              [
                _c("div", [_vm._v(_vm._s(name))]),
                _c("AccessNode", {
                  attrs: { node: obj },
                  on: { update: _vm.update },
                }),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "buttons", class: _vm.is_first(index) },
              _vm._l(obj, function (o, n, i) {
                return _c(
                  "div",
                  {
                    key: `${i}${n}`,
                    staticClass: "button is-small",
                    class: _vm.render_button(obj[n]),
                    attrs: { disabled: "" },
                    on: {
                      click: function ($event) {
                        _vm.update(_vm.path.concat([name, n]))
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(n === "r" ? "Read" : "Write") + " ")]
                )
              }),
              0
            ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }