<template>
<div>
    <Title
        title="Goods value connected to a service"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <Table
        v-if="table.render"

        :headers="table.headers"
        :rows="table.rows"
        :decimal-settings="table.decimal_settings"
        :icon-settings="table.icon_settings"
        :font-settings="{ bold: {row: {regex: 'Total HFB' } } }"
        :sort-settings="table.sort_settings"
        :metaSettings="{is_selectable: false}"
        class="ms1p5"
    />
    <div  v-else>The is no data for {{$store.getters.selected_country_name}}</div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    props: {
        market: String
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: [],
                decimal_settings: {},
                icon_settings: {},
                render: false
            },
            hfb: 'Total HFB',
            location: '/business/sales-per-range/goods-value-connected'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.load_ingka_by_country();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
                {
                    name: 'By HFB',
                    link: `${this.location}/by-hfb/${this.$store.getters.market}`
                }
            ];
        },
        load_ingka_by_country: function() {
            this.$http.post('/business/sales-per-range/goods-value-connected/by-market', {
                market: this.$store.getters.market
            }).then(
                succ => {
                    if (!succ.data || !succ.data.length) return;
                    let { headers, rows } = this.$helper.table.parse_by_hfb(succ.data, this.$store.getters.selected_country_name);

                    if (succ.data[0].measure_metadata) {
                        this.table.decimal_settings = succ.data[0].measure_metadata.decimal_settings || {};
                    }
                    this.table.headers = headers;
                    this.table.rows = rows;

                    this.table.icon_settings = {
                        '4WK Index': {
                            comparison: 'YTD Index',
                            icon: 'arrow'                       
                        },
                        'Growth Contribution YTD': {
                            comparison: 'Growth Contribution YTD',
                            icon: 'bar',
                            ignore: ['Total HFB']                     
                        }
                    }
                    
                    this.table.sort_settings = {
                        [this.$store.getters.selected_country_name]: { asc: true }
                    };

                    this.$helper.header_replace(this.table.headers, {
                        '4wk Index': '4WK Index'
                    });

                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: `${this.location}/by-hfb`
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.table.render = false;
            this.update_view(newMarket);
            this.load_ingka_by_country();
        },
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);	
        },
    }
}
</script>
