<template>
<div>
    <Title
        title="Operational Gross Margin Forecast Vs Goal"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>

        <div class="label-container">
            <p>Filter</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>
    </div>
    <Table
        :key="table.render"

        :headers="table.headers"
        :rows="table.rows"

        :data-settings="{ nullify_zeros: true }"
        :decimal-settings="table.decimal_settings"
        :icon-settings="table.icon_settings"
        :font-settings="{ bold: {row: {regex: 'Total Retail Countries' } } }"
        :sort-settings="{ 'Country': { asc: true } }"
        class="ms1p5"
    />
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: [],
                icon_settings: {},
                decimal_settings: {},
                render: false
            },
            location: '/finance/operational-gross-margin-forecast-vs-goal',
            filter: null,
            options: [],
            values: []
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'By Market',
                    link: `${this.location}/${this.$store.getters.market}`
                }
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'totsal-sales-cntry-vsgoal'
            }).then(
                succ => {
                    succ.data.map(filter => {
                        this.values.push(filter.value);
                        this.options.push(filter.option);
                    });
                    if (this.option && this.option !== 'null') {
                        this.filter = this.option.replace(/-/g, ' ');
                    } else {
                        this.filter = this.$store.getters.filter_check(this.values);
                    }
                    this.load_ingka_by_country();
                },
                this.$helper.error
            );
        },
        load_ingka_by_country: function() {
            this.table.render = false;

            this.$http.post(`/finance/operational-gross-margin-forecast-vs-goal/total-ingka-by-country`,
            {
                filter: this.filter
            }).then(
                succ => {
                    let d = succ.data;
                    let keys = Object.keys(d[0].measures);
                    if (d[0].measure_metadata) {
                        this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                    }
                    if (keys.indexOf('FC-YTD') > -1) { //remove column if it exists 
                        keys.splice(keys.indexOf('FC-YTD'), 1);
                    }
                    this.table.headers = [['Country'].concat(keys).map(h => {let c = {}; c[h] = 1; return c;})];

                    this.table.rows = d.map(row => {
                        let temp = [];
                        // If the key/values don't exist, set them to 0
                        keys.map(key => {
                            if (
                                typeof (row.measures[key]) === 'number' ||
                                typeof (row.measures[key]) === 'string'
                            ) temp.push(this.$helper.maybe(row.measures[key]) + '%');
                            else temp.push('');
                        });
                        
                        return [row.caption].concat(temp);
                    });
                    this.$helper.header_replace(this.table.headers, {
                        'FC-Goal': 'FC vs Year goal',
                        'FC-Goal (TEUR)': 'FC vs Year goal (kEuro)'
                    });
                    if ('FC-Goal' in this.table.decimal_settings) {
                        this.table.decimal_settings['FC vs Year goal'] = this.table.decimal_settings['FC-Goal'];
                    }
                    if ('FC-Goal (TEUR)' in this.table.decimal_settings) {
                        this.table.decimal_settings['FC vs Year goal (kEuro)'] = this.table.decimal_settings['FC-Goal (TEUR)'];
                    }
                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.load_ingka_by_country();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        }
    }
}
</script>
