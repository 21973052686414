var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Button",
        {
          staticClass: "full-width mb mt",
          on: {
            click: function ($event) {
              return _vm.back()
            },
          },
        },
        [_vm._v("Cancel")]
      ),
      _c("BackModal", {
        attrs: { back_clicked: _vm.back_clicked },
        on: {
          "clear-storage": _vm.clear_storage,
          "toggle-edit-mode": function ($event) {
            return _vm.$emit("toggle-edit-mode")
          },
          cancel: function ($event) {
            _vm.back_clicked = false
          },
        },
      }),
      _c(
        "div",
        { staticClass: "schedule mb" },
        [
          _c("SwitchBoxUniversal", {
            staticClass: "center-flex mb",
            attrs: {
              label: "Posting on someones behalf?",
              element_id: "new-switch",
            },
            on: { switch_toggle: _vm.switch_toggle },
          }),
          _vm.posting_on_behalf
            ? _c("UserSearch", {
                staticClass: "center-flex",
                on: { SelectedUser: _vm.selected_user },
              })
            : _vm._e(),
          _c("br"),
          _vm.title_options.options.length > 1
            ? _c("DropDown", {
                key: _vm.title_options.options[0],
                staticClass: "center-flex mb",
                attrs: {
                  options: _vm.title_options.options,
                  values: _vm.title_options.values,
                  default: _vm.title_options.values[0],
                },
                on: { updateDropDown: _vm.select_title },
              })
            : _vm._e(),
          _c("WYSIWYGEditor", {
            attrs: { content: _vm.new_comment.content },
            on: { textUpdate: _vm.update_content },
          }),
          _c("p", [_vm._v("Select the start and end dates for the comment:")]),
          _c("DateTimePicker", { on: { updated: _vm.update_scheduled_date } }),
        ],
        1
      ),
      _c("InfoButtons", {
        attrs: {
          buttons: _vm.buttons,
          calculated_status: _vm.calculated_status,
        },
        on: { update: _vm.create_new },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }