<template>
<div>
    <Title
        title="Cost Per Service"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container">
            <p>Actuality</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>

        <div class="label-container">
            <p>After Sales</p>
            <DropDown
                :key="filter2"
                :options="options2"
                :values="values2"
                :default="filter2"
                @updateDropDown="update_filter2"
            />
        </div>

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms">
        <Graph
            v-if="graph.render"

            :title="`Showing data for: ${$store.getters.selected_country_name}`"
            :show-title="!$store.state.global.bot_mode"
            
            v-bind="graph"
        />
        <div v-else> No data found for {{$store.getters.selected_country_name}}</div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Graph from '@/components/visualisations/general/Graph.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Title,
        Graph
    },
    props: {
        market: String
    },
    data: function() {
        return {
            options: ['Actual', 'Goal', 'Forecast'],
            values: ['actual', 'goal', 'forecast'],
            filter: 'actual',
            options2: ['Incl After Sales', 'Excl After Sales'],
            values2: ['with_after_sales', 'without_after_sales'],
            filter2: 'with_after_sales',
            graph: {
                colors: [],
                data_sets: [],
                names: ['CENTRAL DELIVERY PARCEL', 'LOCAL DELIVERY TRUCK', 'CENTRAL DELIVERY TRUCK', 'LOCAL DELIVERY PARCEL', 'INSTALLATION', 'ASSEMBLY', 'PICKING', 'COLLECT', 'REMOVAL & RECYCLING', 'SEWING SERVICE', 'PLANNING & MEASURING', 'ADDITIONAL SERVICES', 'VEHICLE RENTALS'],
                types: ['line', 'line', 'line', 'line', 'line', 'line', 'line', 'line', 'line', 'line', 'line', 'line', 'line'],
                legend_selected: {
                    'INSTALLATION': false,
                    'ASSEMBLY': false,
                    'PICKING': false,
                    'CENTRAL DELIVERY PARCEL': true,
                    'COLLECT': false,
                    'CENTRAL DELIVERY TRUCK': true,
                    'LOCAL DELIVERY TRUCK': true,
                    'REMOVAL & RECYCLING': false,
                    'SEWING SERVICE': false,
                    'PLANNING & MEASURING': false,
                    'ADDITIONAL SERVICES': false,
                    'VEHICLE RENTALS': false,
                    'LOCAL DELIVERY PARCEL': true
                },
                x_categories: null,
                y_axis_label: 'Average cost for a service',
                year_goal_index: null,
                y_metric: '€',
                grid: {
                    top: '110rem'
                },

                render: false
            },
            location: '/finance/cost-per-service'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        this.get_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'By Service',
                    link: `${this.location}/by-service/${this.$store.getters.market}`
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                }
            ];
        },
        get_data: function() {
            this.$http.post('finance/cost-per-service/trend', {
                filter: this.filter,
                filter2: this.filter2,
                market: this.$store.getters.market
            }).then(
                succ => {
                    let d = succ.data;
                    if (!d[0] || !d[0].measures) return;
                    this.graph.x_categories = Object.keys(d[0].measures);
                    this.graph.data_sets = [[], [], [], [], [], [], [], [], [], [], [], [], []];
                    this.graph.x_categories.forEach(month => {
                        let val = d[0].measures[month];
                        this.graph.names.forEach((name, i) => {
                            this.graph.data_sets[i].push(this.$helper.maybe(val[name]));
                        });
                    });
                    this.graph.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location,
            });
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.graph.render = false;
            this.get_data();
        },
        update_filter2: function(filter) {
            this.filter2 = filter;
            this.graph.render = false;
            this.get_data();
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.graph.render = false;
            this.get_data();
        }
    }
}
</script>
