<template>
<div>
    <Title
        title="Hf Accessories"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container">
            <p>Filter</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <Table
        v-if="table.render"
        
        :key="table.render"
        :headers="table.headers"
        :rows="table.rows"

        :data-settings="table.data_settings"
        :decimal-settings="table.decimal_settings"
        :icon-settings="table.icon_settings"
        :sort-settings="{ 'Product Area': { asc: true } }"
        :font-settings="{ bold: { row: { regex: 'Total HFA' } } }"
        :metaSettings="{ is_selectable: false }"
        class="ms1p5"
    />
    <div v-else-if="table.rows.length === 0">There is no data or {{$store.getters.selected_country_name}}</div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: [],
                decimal_settings: {},
            },
            options: ['Sales', 'Quantity'],
            values: ['Sales', 'Quantity'],
            filter: null,
            location: '/business/sales-per-range/hf-accessories'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.filter = this.$store.getters.filter_check(this.values)
        if (this.filter === 'Sales') {
            this.load_ingka_by_country_sales();
        } else {
            this.load_ingka_by_country_quantity();
        }
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'By Product Area',
                    link: `${this.location}/by-pa`
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                }
            ];
        },
        load_ingka_by_country_sales: function() {
            this.$http.post('/business/sales-per-range/hf-accessories/total-ingka-by-country-pa-sales', {
                market: this.$store.getters.market
            }).then(
                succ => {
                    if (!succ.data || !succ.data.length) return;
                    let { headers, rows } = this.$helper.table.parse_by_hfb(succ.data, 'Product Area');

                    if (succ.data[0].measure_metadata) {
                        this.table.decimal_settings = succ.data[0].measure_metadata.decimal_settings || {};
                    }
                    this.table.headers = headers;
                    this.table.rows = rows;

                    this.table.data_settings = {
                        nullify_zeros: true
                    };

                    this.table.icon_settings = {
                        'YTD Index': {
                            icon: 'circle',
                            comparison: 'Goal'
                        },
                        '4WK Trend': {
                            icon: 'arrow',
                            comparison: 'YTD Index'
                        },
                        'Growth Contribution': {
                            icon: 'bar',
                            comparison: 'Growth Contribution',
                            ignore: ['Total HFA']
                        }
                    }
                    this.$helper.header_replace(this.table.headers, {
                        '4 Weeks': '4WK Trend'
                    });
                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        load_ingka_by_country_quantity: function() {
            this.$http.post('/business/sales-per-range/hf-accessories/total-ingka-by-country-pa-quantity', {
                market: this.$store.getters.market
            }).then(
                succ => {
                    let d = succ.data;
                    this.table.headers, this.table.rows = [];
                    if (succ.data[0].measure_metadata) {
                        this.table.decimal_settings = succ.data[0].measure_metadata.decimal_settings || {};
                    }
                    let keys = Object.keys(d[0].measures[Object.keys(d[0].measures)[0]]);
                    this.table.headers = [['Product Area'].concat(keys).map(h => { let t = {}; t[h] = 1; return t; })];

                    Object.keys(d[0].measures).forEach(row => {
                        let temp = [];
                        // If the key/values don't exist, set them to 0
                        temp.push(row);
                        keys.map(key => {
                            temp.push(this.$helper.maybe(d[0].measures[row][key]));
                        });
                        this.table.rows.push(temp);
                    });

                    this.table.icon_settings = {
                        'Growth Contribution': {
                            icon: 'bar',
                            comparison: 'Growth Contribution',
                            ignore: ['Total HFA']
                        },
                        '4 Weeks': {
                            icon: 'arrow',
                            comparison: 'YTD Index'
                        }
                    }
                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.table.icon_settings = null;
            this.table.render = false;
            this.filter === 'Sales' ? this.load_ingka_by_country_sales() : this.load_ingka_by_country_quantity();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
            this.table.icon_settings = null;
            this.table.render = false;
            this.filter === 'Sales' ? this.load_ingka_by_country_sales() : this.load_ingka_by_country_quantity();
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        }
    }
}
</script>
<style scoped>
.notice {
    text-align: left;
    margin-left: 1rem;
}
</style>
