<template>
<div>
    <Title
        title="Lead Times"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container">
            <p>Filter</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <Table
        v-if="table.render"

        :headers="table.headers"
        :rows="table.rows"
        
        :decimal-settings="table.decimal_settings"
        :icon-settings="table.icon_settings"
        :data-settings="{ nullify_zeros: true }"
        :sort-settings="{ 'Country': { asc: true } }"
        :font-settings="{ bold: {row: {regex: 'Total Retail Countries' } } }"
        class="ms1p5"
    />
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },

    data: function() {
        return {
            table: {
                headers: [],
                rows: [],
                decimal_settings: {},
                render: false
            },
            location: '/business/lead-times',
            options: ['Store fulfilment', 'Central fulfilment', 'Parcel'],
            values: ['Store fulfilment', 'Central fulfilment', 'Parcel'],
            filter: null,
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.filter = this.$store.getters.filter_check(this.values)
        this.load_ingka_by_country();
    },
    methods: {
        generate_buttons: function() {
            if (!this.filter) return;
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.filter.replace(/ /g, '-')}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8/null`
                },
            ];
        },
        load_ingka_by_country: function() {
            this.table.render = false;
            this.$http.post('/business/lead-times/total-ingka-by-country', {filter: this.filter}).then(
                succ => {
					let d = succ.data;
                    let keys = Object.keys(d[0].measures);
                    if (d[0].measure_metadata) {
                        this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                    }
                    this.table.headers = [['Country'].concat(keys).map(c => {
                        return {
                            [c]: 1
                        }
                    })];
                    this.table.rows = d.map(row => {
                        let temp = [];
                        // If the key/values don't exist, set them to 0
                        keys.map(key => {
                            if (row.measures) {
                                temp.push(this.$helper.maybe(row.measures[key]));
                            } else {
                                temp.push('');
                            }
                        });
                        this.table.icon_settings = {
                            'YTD': {
                                icon: 'circle',
                                comparison: 'Year Goal',
                                reversed: true
                            },
                            '1WK Trend': {
                                icon: 'arrow',
                                comparison: 'YTD',
                                reversed: true
                            }
                        }
                        return [row.caption].concat(temp);
                    });
                    this.$helper.header_replace(this.table.headers, {
                        'Week': '1WK Trend'
                    });
                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.load_ingka_by_country();
        },
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        }
    }
}
</script>
