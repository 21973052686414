var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("AdminUniversal", {
        attrs: { component: "title", props: _vm.title_props },
      }),
      _c("KPIChooser", {
        staticClass: "kpi-chooser",
        attrs: { global_location: true },
        on: {
          chosen: _vm.kpi_chosen,
          reset: function ($event) {
            _vm.chosen_kpi = null
          },
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.chosen_kpi,
              expression: "chosen_kpi",
            },
          ],
          staticClass: "editor-wrapper",
        },
        [
          _c("div", { staticClass: "text-title-left mt" }, [
            _vm._v(" Current announcement "),
          ]),
          _vm.current_announcement
            ? _c("div", {
                staticClass: "current-announcement",
                domProps: { innerHTML: _vm._s(_vm.current_announcement) },
              })
            : _vm._e(),
          _c("div", { staticClass: "text-title-left" }, [
            _vm._v(" New announcement "),
          ]),
          _vm.current_announcement
            ? _c("WYSIWYG", {
                staticClass: "new-announcement",
                attrs: { content: _vm.current_announcement },
                on: { textUpdate: _vm.update_text },
              })
            : _vm._e(),
          _c("div", [
            _c(
              "button",
              {
                staticClass: "button is-success mb",
                attrs: { disabled: !_vm.update_available },
                on: { click: _vm.update_announcement },
              },
              [_vm._v("Update announcement")]
            ),
          ]),
          _c("div", [
            _c(
              "button",
              {
                staticClass: "button is-danger",
                on: { click: _vm.remove_announcement },
              },
              [_vm._v("Remove announcement")]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }