export default {
    props: Object,
    logic: function(props, data) {
        let global = props.global ? props.global : 3;
        let {parsed_headers, parsed_rows} = data;

        let thead = document.querySelectorAll('table.custom.table thead tr');
        let thead_span = parsed_headers.map(pheader => 
            pheader.map(header => Object.values(header))
        );

        thead.forEach((row, i) => {
            row.childNodes.forEach((th, index) => {
                th.style = `width: ${global * thead_span[i][index]}rem;`;
            });
        });
    }
}