<template>
<div>
    <Title title="Flow Matrix" :location="location" />
    <div class="options-bar">
        <div class="label-container">
            <p>Filter by HFB</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.getters.hfbs.options"
                :values="$store.getters.hfbs.values"
                :default="hfb || 'Total HFB'"
                @updateDropDown="update_hfb"
            />
        </div>
    </div>

    <div class="section p0 box ms">
        <FlowMatrix
            v-if="render"
            :matrix="matrix"
        />
        <div v-else>
            No data found for HFB {{hfb}} for the country: {{$store.getters.selected_country_name}}
        </div>
    </div>
</div>
</template>

<script>
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

import FlowMatrix from '@/components/bespoke/flow-matrix/FlowMatrix.vue';

export default {
    props: {
        market: String
    },
    components: {
        DropDown,
        FlowMatrix,
        Table,
        Title
    },
    data: function() {
        return {
            matrix: null,
            render: false,
            hfb: null,
            location: '/business/total-sales/flow-matrix',
        };
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.load_data();
    },
    methods: {
        load_data: function() {
            this.$http.post('/business/total-sales/flow-matrix/all', {
                market: this.$store.getters.market,
                hfb: this.hfb
            }).then(
                succ => {
                    if (!succ.data) return;
                    let data = succ.data[0].measures;
                    
                    let customer_to_home = {
                        ytd: data.ALL['Customer to Home'].ytd_index,
                        ytd_share: data.ALL['Customer to Home'].ytd_sales_share,
                        ytd_sales: data.ALL['Customer to Home'].ytd,
                        compare_val: data.ALL['Customer to Home'].compare_val,
                        year_goal: data.ALL['Customer to Home'].year_goal_index,
                        year_goal_share: data.ALL['Customer to Home'].year_goal_share
                    }

                    let ikea_to_home = {
                        ytd: data.ALL['IKEA to Home'].ytd_index,
                        ytd_share: data.ALL['IKEA to Home'].ytd_sales_share,
                        ytd_sales: data.ALL['IKEA to Home'].ytd,
                        compare_val: data.ALL['IKEA to Home'].compare_val,
                        year_goal: data.ALL['IKEA to Home'].year_goal_index,
                        year_goal_share: data.ALL['IKEA to Home'].year_goal_share
                    }

                    let store = {
                        ytd: data.Store.ALL.ytd_index,
                        ytd_share: data.Store.ALL.ytd_sales_share,
                        ytd_sales: data.Store.ALL.ytd,
                        compare_val: data.Store.ALL.compare_val,
                        year_goal: data.Store.ALL.year_goal_index,
                        year_goal_share: data.Store.ALL.year_goal_share
                    }

                    let store_cth = {
                        ytd: data.Store['Customer to Home'].ytd_index,
                        ytd_share: data.Store['Customer to Home'].ytd_sales_share,
                        ytd_sales: data.Store['Customer to Home'].ytd,
                        compare_val: data.Store['Customer to Home'].compare_val,
                        year_goal: data.Store['Customer to Home'].year_goal_index,
                        year_goal_share: data.Store['Customer to Home'].year_goal_share
                    }

                    let store_ith = {
                        ytd: data.Store['IKEA to Home'].ytd_index,
                        ytd_share: data.Store['IKEA to Home'].ytd_sales_share,
                        ytd_sales: data.Store['IKEA to Home'].ytd,
                        compare_val: data.Store['IKEA to Home'].compare_val,
                        year_goal: data.Store['IKEA to Home'].year_goal_index,
                        year_goal_share: data.Store['IKEA to Home'].year_goal_share
                    }

                    let online = {
                        ytd: data.Online.ALL.ytd_index,
                        ytd_share: data.Online.ALL.ytd_sales_share,
                        ytd_sales: data.Online.ALL.ytd,
                        compare_val: data.Online.ALL.compare_val,
                        year_goal: data.Online.ALL.year_goal_index,
                        year_goal_share: data.Online.ALL.year_goal_share
                    }

                    let online_cth = {
                        ytd: data.Online['Customer to Home'].ytd_index,
                        ytd_share: data.Online['Customer to Home'].ytd_sales_share,
                        ytd_sales: data.Online['Customer to Home'].ytd,
                        compare_val: data.Online['Customer to Home'].compare_val,
                        year_goal: data.Online['Customer to Home'].year_goal_index,
                        year_goal_share: data.Online['Customer to Home'].year_goal_share
                    }

                    let online_ith = {
                        ytd: data.Online['IKEA to Home'].ytd_index,
                        ytd_share: data.Online['IKEA to Home'].ytd_sales_share,
                        ytd_sales: data.Online['IKEA to Home'].ytd,
                        compare_val: data.Online['IKEA to Home'].compare_val,
                        year_goal: data.Online['IKEA to Home'].year_goal_index,
                        year_goal_share: data.Online['IKEA to Home'].year_goal_share
                    }

                    let remote = {
                        ytd: data.Remote.ALL.ytd_index,
                        ytd_share: data.Remote.ALL.ytd_sales_share,
                        ytd_sales: data.Remote.ALL.ytd,
                        compare_val: data.Remote.ALL.compare_val,
                        year_goal: data.Remote.ALL.year_goal_index,
                        year_goal_share: data.Remote.ALL.year_goal_share
                    }

                    let remote_cth = {
                        ytd: data.Remote['Customer to Home'].ytd_index,
                        ytd_share: data.Remote['Customer to Home'].ytd_sales_share,
                        ytd_sales: data.Remote['Customer to Home'].ytd,
                        compare_val: data.Remote['Customer to Home'].compare_val,
                        year_goal: data.Remote['Customer to Home'].year_goal_index,
                        year_goal_share: data.Remote['Customer to Home'].year_goal_share
                    }

                    let remote_ith = {
                        ytd: data.Remote['IKEA to Home'].ytd_index,
                        ytd_share: data.Remote['IKEA to Home'].ytd_sales_share,
                        ytd_sales: data.Remote['IKEA to Home'].ytd,
                        compare_val: data.Remote['IKEA to Home'].compare_val,
                        year_goal: data.Remote['IKEA to Home'].year_goal_index,
                        year_goal_share: data.Remote['IKEA to Home'].year_goal_share
                    }

                    this.matrix = [
                        '',         customer_to_home,   ikea_to_home,
                        store,      store_cth,          store_ith,  
                        online,     online_cth,         online_ith,
                        remote,     remote_cth,         remote_ith
                    ];

                    this.render = true;
                },
                this.$helper.error
            );
        },
        update_hfb: function(hfb) {
            this.render = false;
            this.hfb = hfb;
            this.load_data();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function (newMarket, oldMarket) {
            this.render = false;
            this.update_view(newMarket);
            this.load_data();
        }
    }
}

</script>
