var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: { title: "Happy Customer", location: _vm.location },
      }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons() },
          }),
          _vm.values.length > 0
            ? _c(
                "div",
                { staticClass: "label-container" },
                [
                  _c("p", [_vm._v("CMP")]),
                  _c("DropDown", {
                    key: _vm.values[0],
                    attrs: {
                      options: _vm.options,
                      values: _vm.values,
                      default: _vm.values[0],
                    },
                    on: { updateDropDown: _vm.update_filter },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                key: _vm.$store.getters.market,
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: _vm.$store.getters.market,
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size,
          staticClass: "section box one-quarter-height ms1p5 mb",
        },
        [
          _vm.render.horizontal
            ? _c("HorizontalSingleBar", {
                attrs: {
                  title: _vm.get_feedback_score_title,
                  showtitle: !_vm.$store.state.global.bot_mode,
                  ycategories: [""],
                  yaxislabel: "Feedback\nVolume",
                  xaxismin: Math.min(_vm.score_data.values),
                  xinterval: 5,
                  xmetric: "",
                  seriesset1: [_vm.score_data.values[0]],
                  seriestype1: "bar",
                  color1: "#00802b",
                  seriesname1: "Positive",
                  seriesset2: [_vm.score_data.values[1]],
                  seriestype2: "bar",
                  color2: "#6a6a6a",
                  seriesname2: "Neutral",
                  seriesset3: [_vm.score_data.values[2]],
                  seriestype3: "bar",
                  color3: "#b32d00",
                  seriesname3: "Negative",
                },
              })
            : _c("div", [
                _vm._v(
                  " No data found for " +
                    _vm._s(_vm.$store.getters.selected_country_name)
                ),
              ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section three-quarters-height box ms" },
        [
          _vm.render.chart
            ? _c("QuintupleStack", {
                attrs: {
                  title: `Showing data for: ${_vm.$store.getters.selected_country_name}`,
                  showtitle: !_vm.$store.state.global.bot_mode,
                  subtitle: "",
                  xcategories: _vm.triple.xcategories,
                  yaxislabel1: `Happy Customer Score ${
                    _vm.filter === "total"
                      ? "Topline"
                      : _vm.options[_vm.values.indexOf(_vm.filter)]
                  }`,
                  ymetric1: "",
                  yaxislabel2: "Feedback Volume",
                  ymetric2: "",
                  seriesname1: "Total",
                  seriestype1: "bar",
                  seriesset1: _vm.triple.seriesset1,
                  color1: _vm.$store.state.colors["grey"],
                  seriestype2: "bar",
                  seriestype3: "bar",
                  seriestype4: "line",
                  seriesname5: "CEM phase HCS (sentiment)",
                  seriestype5: "line",
                  seriesset5: _vm.triple.seriesset5,
                  color5: _vm.$store.state.colors["focus-yellow"],
                  hidebarlabel: true,
                },
              })
            : _c("div", [
                _vm._v(
                  " No data found for " +
                    _vm._s(_vm.$store.getters.selected_country_name)
                ),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }