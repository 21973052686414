<template>
<div>
    <Title
        title="Services Gross Margin By Country"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container">
            <p>Service Types</p>
            <DropDown
                :key="service_filter"
                :options="service_filter_options"
                :values="service_filter_values"
                :default="service_filter"
                @updateDropDown="update_service_filter"
            />
        </div>
        <div class="label-container">
            <p>Actuality</p>
            <DropDown
                :key="act_filter"
                :options="act_filter_options"
                :values="act_filter_values"
                :default="act_filter"
                @updateDropDown="update_act_filter"
            />
        </div>
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>

    <Table
        v-if="table.render"
        
        :headers="table.headers"
        :rows="table.rows"
        :icon-settings="table.icon_settings"
        :decimal-settings="table.decimal_settings"
        :data-settings="table.data_settings"
        :sort-settings="{ 'Country': { asc: true } }"
        :font-settings="{ bold: {row: {regex: 'Total Retail Countries' } } }"

        class="ms1p5"
    />
    <div v-else>No data found for {{$store.getters.selected_country_name}}</div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            table: {
                headers: [],
                render: false,
                rows: [],
                decimal_settings: {},
                data_settings: { nullify_zeros: true },
            },
            service_filter_options: [],
            service_filter_values: [],
            service_filter: null,
            act_filter_options: [],
            act_filter_values: [],
            act_filter: null,
            location: '/finance/service-net/services-gross-margin'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'By Service',
                    link: `${this.location}/by-market/${this.$store.getters.market}`
                },
                {
                    name: 'Waterfall',
                    link: `${this.location}/waterfall/${this.$store.getters.market}`
                },
                {
                    name: 'By Service (Graph)',
                    link: `${this.location}/by-market-graph/${this.$store.getters.market}`
                }
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'service-net-service',
                disable_sort: true
            }).then(
                succ => {
                    succ.data.map(filter => {
                        this.service_filter_values.push(filter.value);
                        this.service_filter_options.push(filter.option);
                    });

                    let all_index = this.service_filter_values.indexOf('ALL SERVICE TYPES');
                    if (all_index > -1) {
                        this.service_filter_values.splice(all_index, 1);
                        this.service_filter_options.splice(all_index, 1);
                        this.service_filter_values.unshift('ALL SERVICE TYPES');
                        this.service_filter_options.unshift('ALL SERVICE TYPES');
                    }
                    
                    this.service_filter = this.$store.getters.filter_check(this.service_filter_values);
                },
                this.$helper.error
            );
            this.$http.post('/filters/load', {
                filter: 'service-net-actuality'
            }).then(
                succ => {
                    succ.data.map(filter => {
                        this.act_filter_values.push(filter.value);
                        this.act_filter_options.push(filter.option);
                    });
                    this.act_filter = this.$store.getters.filter_check(this.act_filter_values);
                },
                this.$helper.error
            );
        },
        load_ingka_by_country: function() {
            this.table.render = false;
            this.$http.post('finance/service-net-dashboard/income-by-service-and-country', {
                actuality: this.act_filter, service_type: this.service_filter
            }).then(
                succ => {
                    let d = succ.data;
                    if (d[0].measure_metadata) {
                        this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                    }
                    this.table.headers = null;
                    this.table.rows = null;
                    let keys = Object.keys(d[0].measures[0]);

                    this.table.rows = d.map(row => {
                        let temp = [];

                        keys.forEach((key, i) => {
                            if (!row.measures || !row.measures[0] || !row.measures[0][key]) return temp.push(0);
                            if (key === 'service_qty' || key === 'service_index') return temp.push(0); //hiding number_of_services data
                            if (this.service_filter === 'ALL SERVICE TYPES'
                                    && key === 'gm_comp'
                                    && row.measures[0]['gm']
                                    && row.measures[0][key] !== Math.round(row.measures[0]['gm'])
                                ) {
                                    return temp.push(row.measures[0]['gm'] + 1);
                                } else if (this.service_filter !== 'ALL SERVICE TYPES' && key === 'gm_comp'){
                                    return;
                                }
                                temp.push(this.$helper.maybe(row.measures[0][key]));
                            }
                        );
                        
                        return [row.caption].concat(temp);
                    });
                    this.table.icon_settings = {
                        'Number Of Services QTY': {
                            icon: 'bar',
                            comparison: 'Number Of Services QTY',
                            ignore: ['Total Retail Countries']
                        },
                        'Services Income kEuro': {
                            icon: 'bar',
                            comparison: 'Services Income kEuro',
                            ignore: ['Total Retail Countries']
                        },
                        'Commercial Cost Euro': {
                            icon: 'bar',
                            comparison: 'Commercial Cost Euro',
                            ignore: ['Total Retail Countries']
                        },
                        'After Sales Cost Euro': {
                            icon: 'bar',
                            comparison: 'After Sales Cost Euro',
                            ignore: ['Total Retail Countries']
                        },
                        'Total Cost Euro': {
                            icon: 'bar',
                            comparison: 'Total Cost Euro',
                            ignore: ['Total Retail Countries']
                        },
                        'Services Net Euro': {
                            icon: 'bar',
                            comparison: 'Services Net Euro',
                            ignore: ['Total Retail Countries']
                        },
                        'GM': {
                            fix: { post: '%' }
                        },
                        'GM (incl.After Sales)': {
                            fix: { post: '%' }
                        },
                        'Services Net Euro': {
                            icon: 'bar',
                            comparison: 'Services Net Euro',
                            ignore: ['Total Retail Countries']
                        }
                    }

                    this.table.headers = [
                        [ 
                            { [this.service_filter]: 1, },
                            { [this.act_filter.toUpperCase()]: 16, },
                        ],
                        [
                            { '': 1, },
                            { 'Number Of Services': 2, },
                            { 'Services Income': 2, },
                            { 'Commercial Cost': 2, },
                            { 'After Sales Cost': 2, },
                            { 'Total Cost': 2, },
                            { 'Service Net': 2, },
                            { 'Service Net (incl.After Sales)': 2, },
                            { '': 2, }
                        ],
                        [
                            { 'Countries': 1, },
                            { 'QTY': 1, alias: 'Number Of Services QTY'},
                            { 'Index to LY': 1, },
                            { 'kEuro': 1, alias: 'Services Income kEuro'},
                            { 'Index to LY': 1, },
                            { 'kEuro': 1, alias: 'Commercial Cost Euro'},
                            { 'Index to LY': 1, },
                            { 'kEuro': 1, alias: 'After Sales Cost Euro'},
                            { 'Index to LY': 1, },
                            { 'kEuro': 1, alias: 'Total Cost Euro'},
                            { 'Index to LY': 1, },
                            { 'kEuro': 1, alias: 'Services Net Euro'},
                            { 'Index to LY': 1, },
                            { 'kEuro': 1, alias: 'Services Net Euro Incl'},
                            { 'Index to LY': 1, },
                            { 'GM': 1, },
                            { 'GM (incl.After Sales)': 1, },
                        ]
                    ];
                    if (this.service_filter === 'ALL SERVICE TYPES') {
                        this.table.icon_settings['P&L Ratio Compared'] = {
                            icon: 'circle-only',
                            comparison: 'GM (incl.After Sales)'
                        };
                        this.table.headers[0][1][this.act_filter.toUpperCase()] = 17;
                        this.table.headers[1][8][''] = 3;
                        this.table.headers[2].push({ 'P&L Ratio Compared': 1, alias: 'P&L Ratio Compared'});
                    }
                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        update_service_filter: function(service_filter) {
            this.service_filter = service_filter;
            this.$store.state.global.temp_service_filter = service_filter;
        },
        update_act_filter: function(act_filter) {
            this.act_filter = act_filter;
            this.$store.state.global.temp_act_filter = act_filter;
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        },
        service_filter: function() {
            this.load_ingka_by_country();
        },
        act_filter: function() {
            this.load_ingka_by_country();
        } 
    }
}
</script>
