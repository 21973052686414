<template>
<div>
    <Title
        title="Affordability"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <Table
        v-if="table.render"
        :headers="table.headers"
        :rows="table.rows"
        :icon-settings="table.icon_settings"
        :decimal-settings="table.decimal_settings"
        :data-settings="{ nullify_zeros: true }"
        :font-settings="{ bold: {row: { regex: 'Total Retail Countries' } } }"
        :sort-settings="{ 'Country ': { asc: true } }"

        class="ms1p5"
    />
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: [],
                render: false,
                icon_settings: {},
                decimal_settings: {},
            },
            location: '/three-roads/furniture-that-fits-my-budget'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.get_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
            ];
        },
        get_data: function() {
            this.table.render = false;
            this.$http.get('/three-roads/furniture-that-fits-my-budget/total-ingka-by-country').then(
                succ => {
                    let d = succ.data;
                    if (!d) return;

                    if (d[0].measure_metadata) {
                        // this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                        this.table.decimal_settings = {global: 1};
                    }

                    let keys = Object.keys(d[0].measures);
                    const has_goals = keys[keys.length-1].toLowerCase().includes('goal') //check if api is returning goals

                    let last_header = null;
                    if (has_goals) last_header = keys.pop();

                    const date_headers = keys.map(date => {return {[date]: 2}});
                    
                    let sub_headers = [];
                    keys.forEach(key => sub_headers.push(
                        {'4M': 1, alias: `${key} 4M`},
                        {'12M': 1, alias: `${key} 12M`}
                    ));

                    this.table.headers = has_goals ? [
                            [{'': 1}, ...date_headers, {'': 1}],
                            [{'Country': 1}, ...sub_headers, {[last_header]: 1}]
                        ]
                        :
                        [
                            [{'': 1}, ...date_headers],
                            [{'Country': 1}, ...sub_headers]
                        ]
                    ;
                    
                    this.table.bridge_settings = {columns: {}};
                    keys.forEach(key => {
                        this.table.bridge_settings.columns[key] = {hide : true};
                    });
                    if (has_goals) this.table.bridge_settings.columns['Yearly Goal'] = {hide : true};

                    this.table.icon_settings = {};
                    keys.forEach(key => {
                        let tmp = {};
                        if (has_goals && key === keys[keys.length - 1]) {
                            tmp[`${key} 4M`] = {
                                fix: { post: '%' }
                            };
                            tmp[`${key} 12M`] = {
                                icon: 'circle',
                                comparison: 'Yearly Goal',
                                fix: { post: '%' }
                            };
                        } else {
                            tmp[`${key} 4M`] = {
                                fix: { post: '%' }
                            };
                            tmp[`${key} 12M`] = {
                                fix: { post: '%' }
                            };
                        }
                        this.table.icon_settings = Object.assign(this.table.icon_settings, tmp);
                    });
                    if (has_goals) this.table.icon_settings['Yearly Goal'] = {fix: { post: '%' }};
                    
                    this.table.rows = d.map(row => {
                        let temp = [];
                        keys.forEach(date => {
                            if (!row.measures[date]) {
                                temp.push(0,0);
                            } else {
                                temp.push(
                                    this.$helper.maybe(row.measures[date]['4M'] || null),
                                    this.$helper.maybe(row.measures[date]['12M'] || null)
                                )
                            }
                        });
                        if (has_goals) temp.push(this.$helper.maybe(row.measures[last_header]));

                        return [row.caption].concat(temp);
                    });

                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        }
    }
}
</script>
