<template>
<div>
    <AdminUniversal
        component="title"
        :props="title_props"
    />
    <AdminUniversal
        component="button"
        :props="button_props"
    />

    <hr/>
    
    <AdminUniversal
        component="table2"
        v-if="table_props.render"
        :props="table_props"
    />

    <div :class="{'is-active': modal.visible}" class="modal">
        <div @click="modal.visible = false" class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">{{modal.title}}</p>
                <button @click="modal.visible = false" class="delete" aria-label="close"></button>
            </header>

            <section class="modal-card-body">
                <div class="field">
                    <label class="label">KPI Name</label>
                    <div class="control">
                        <input v-model="modal.data.report" class="input" type="text">
                        <p class="help">Required - will appear as written</p>
                    </div>
                </div>

                <div class="field">
                    <label class="label">Type</label>
                    <div class="control">
                        <div class="select">
                            <select v-model="modal.data.load_type">
                                <option disabled value="">Please select one</option>
                                <option>manual</option>
                                <option>automated</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="field">
                    <label class="label">KPI code</label>
                    <div class="control">
                        <input v-model="modal.data.kpi_code" class="input" type="text" placeholder="e.g. goodsvalue-services">
                        <p class="help">Required - string used to fetch version, speak to a developer if unsure.</p>
                    </div>
                </div>

                <div class="field">
                    <label class="label">Processing Frequency as Cron</label>
                    <div class="control">
                        <input v-model="modal.data.processing_frequency" class="input" type="text" placeholder="e.g. 0 6 * * 1">
                        <p class="help">Required - <a href="https://crontab.guru/">Cron expression</a> that defines load frequency</p>
                    </div>
                </div>

                <div class="field">
                    <label class="label">Processing Duration (seconds)</label>
                    <div class="control">
                        <input v-model="modal.data.processing_duration" class="input" type="number" placeholder="">
                        <p class="help">Required - interval of time for processing</p>
                    </div>
                </div>

                <div class="field">
                    <label class="label">Lag (seconds)</label>
                    <div class="control">
                        <input v-model="modal.data.lag" class="input" type="number" placeholder="">
                        <p class="help">Required - time interval data lags behind real time (typically one cycle)</p>
                    </div>
                </div>

                <div class="field">
                    <label class="label">Comment</label>
                    <div class="control">
                        <textarea v-model="modal.data.load_comment" rows="2" class="textarea" placeholder="Add Notes here"></textarea>
                    </div>
                </div>
            </section>

            <footer class="modal-card-foot">
                <button @click="write_kpi" class="button is-success">Save changes</button>
                <button @click="modal.visible = false" class="button">Cancel</button>
            </footer>
        </div>
    </div>
</div>
</template>

<script>
import AdminUniversal from '@/components/admin/index.vue';
import KPIChooserDropdowns from '@/components/admin/KPIChooserDropdowns.vue';
export default {
    components: {
        AdminUniversal,
        KPIChooserDropdowns
    },
    data: function() {
        return {
            title_props: {
				title: 'Edit Load Tracker KPIs'
			},
            button_props: {
                label: 'Create',
                classes: 'ikea-blue text-white',
                method: this.add_kpi_modal
            },
			table_props: {
                buttons: [{
                    label: 'Edit',
                    classes: 'is-success is-small',
                    method: this.edit_kpi_modal
                }],
                headers: ['ID', 'KPI', 'Load type', 'Edit'],
                keys: ['id', 'report', 'load_type'],
                data: null,
                render: false
            },
            modal: {
                data: {
                    id: null,
                    kpi_name: '',
                    report: '',
                    kpi_code: '',
                    load_type: '',
                    processing_frequency: '',
                    processing_duration: '',
                    lag: '',
                    load_comment: ''
                },
                title: '',
                visible: false,
            }
        }
    },
    mounted: function() {
        this.load_rows();
    },
    methods: {
        add_kpi_modal: function() {
            this.empty_modal_data();
            this.modal.title = 'Add KPI to Load Tracker';
            this.modal.visible = true;
        },
        edit_kpi_modal: function(id) {
            this.empty_modal_data();
            const kpi = this.table_props.data.find(kpi => kpi.id === id);
            this.modal.title = `Edit KPI: ${kpi.report}`;
            this.modal.data = {...this.modal.data, ...kpi};
            this.modal.visible = true;
        },
        empty_modal_data: function() {
            this.modal.data = {
                id: null,
                kpi_name: '',
                report: '',
                kpi_code: '',
                load_type: '',
                processing_frequency: '',
                processing_duration: '',
                lag: '',
                load_comment: ''
            };
        },
        load_rows: function() {
            this.table_props.render = false;
            this.$http.get('/admin/data-load-tracker').then(
                succ => {
                    if (!succ.data) return;
                    this.table_props.data = succ.data;
                    this.table_props.render = true;
                },
                this.$helper.error
            );
        },
        write_kpi: function() {
            this.modal.visible = false;
            this.modal.data.load_comment.trim();
            if (this.modal.data.id) {
                this.$http.post('/admin/data-load-tracker/edit', {kpi: this.modal.data}).then(
                    succ => {
                        this.load_rows();
                    },
                    this.$helper.error
                );
            } else {
                this.$http.post('/admin/data-load-tracker/add', {kpi: this.modal.data}).then(
                    succ => {
                        this.load_rows();
                    },
                    this.$helper.error
                );
            }
        },
    }
}
</script>

<style scoped>

.modal {
    text-align: left;
}

</style>