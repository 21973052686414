<template>
    <div v-show="$store.state.global.show_observations" id="comment-widget" class="commenting-widget">
        <!--  Title Box  -->
        <div
            class="titlebox"
            :style="{ 'background-color': this.$store.state.global.is_pink_mode ? 'pink' : '' }"
            id="titlebox"
            :key="render_key"
        >
            <div>
                <SwitchBoxUniversal
                    v-if="$store.getters.check_access(['comments', '*', 'w']) && !this.$store.getters.presentation_mode"
                    :boolean_item="this.$store.state.global.show_edit ? true : false"
                    element_id="comments-switch"
                    @switch_toggle="switch_toggle"
                    class="switch-placement absolute"
                />
                <i class="fas fa-edit edit-placement absolute"></i>
                <i class="fas fa-slash slash-placement absolute" v-show="!$store.state.global.show_edit"></i>
                <b class="observations-title">
                    Insights {{ definitionType === 'global' ? '(Global)' : '(By Country)' }}
                </b>
                <div class="hidden-on-mobile arrows absolute">
                    <div class="arrow-left mr cursor-pointer" @click="change_width(1)"></div>
                    <div class="arrow-right cursor-pointer" @click="change_width(-1)"></div>
                </div>
                <a class="delete is-medium absolute" @click="toggle_comment_widget()"></a>
            </div>
        </div>

        <!--  Loading Spinner  -->
        <Spinner v-show="$store.state.commenting.loading !== 0" />

        <!--  Tabs  -->
        <div class="tabs is-boxed is-fullwidth" v-show="!this.$store.getters.presentation_mode">
            <ul class="ms">
                <!-- can be looped using object, look into in the future - Ben 16 Jul 2020 -->
                <li
                    v-if="$store.getters.check_access(['comments', 'load', '*', 'r'])"
                    class="tab"
                    @click="selected = 'current'"
                    :class="{ active: selected === 'current' }"
                >
                    <b class="tab-text">Current</b>
                </li>
                <li
                    v-if="$store.getters.check_access(['comments', 'new', '*', 'w']) && $store.state.global.show_edit"
                    class="tab"
                    @click="selected = 'new'"
                    :class="{ active: selected === 'new' }"
                >
                    <b class="tab-text">New</b>
                </li>
                <li
                    v-if="$store.getters.check_access(['comments', 'edit', '*', 'w']) && $store.state.global.show_edit"
                    class="tab"
                    @click="selected = 'draft'"
                    :class="{ active: selected === 'draft' }"
                >
                    <b class="tab-text">Draft</b>
                </li>
                <li
                    v-if="$store.getters.check_access(['comments', 'edit', '*', 'w']) && $store.state.global.show_edit"
                    class="tab"
                    @click="selected = 'scheduled'"
                    :class="{ active: selected === 'scheduled' }"
                >
                    <b class="tab-text">Scheduled</b>
                </li>
                <li
                    v-if="$store.getters.check_access(['comments', 'load', '*', 'r'])"
                    class="tab"
                    @click="selected = 'archived'"
                    :class="{ active: selected === 'archived' }"
                >
                    <b class="tab-text">Archived</b>
                </li>
            </ul>
        </div>

        <!--  Notification  -->
        <Notification
            v-if="$store.state.commenting.notification.show"
            :message="$store.state.commenting.notification.message"
        />

        <!--  Comments Display & Edit  -->
        <Filters class="mt ml mr" v-if="selected === 'archived'" @update="update_filters" />

        <div v-if="!edit_mode">
            <div v-if="selected !== 'new'">
                <div v-if="comments.length > 0">
                    <CommentWarning
                        v-if="
                            comments.length > 1 &&
                            $store.getters.check_access(['comments', '*', 'w']) &&
                            selected === 'current'
                        "
                        commentLimit="one"
                    />
                    <div
                        class="comment-container"
                        :class="comments.length - 1 !== i ? 'comment-border' : ''"
                        v-for="(comment, i) in comments"
                        :key="i"
                    >
                        <div class="comment-meta">
                            <div class="ikea-blue-text">
                                {{ comment.scheduled_date.split('T')[0] }} -
                                {{ comment.comment_lifetime.split('T')[0] }}
                            </div>
                            <div class="columns is-gapless">
                                <div class="column">
                                    <div
                                        v-if="!audio_hash || !(comment.comment_id in audio_hash)"
                                        class="button tts-button ikea-blue-text ft-tooltip"
                                        tooltiptext="Text to Speech"
                                        @click="load_audio(comment.content, comment.comment_id)"
                                    >
                                        <i v-if="!loading" class="fas fa-volume-up"></i>
                                        <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                                    </div>
                                </div>
                                <div class="column is-8">
                                    <div
                                        v-if="comment.updated_by || comment.a_display_name"
                                        class="ft-tooltip-bottom"
                                        :tooltiptext="`
                                        ${
                                            comment.updated_by
                                                ? `Edited by: ${comment.updated_by} ${
                                                      comment.a_display_name ? '|' : ''
                                                  }`
                                                : ''
                                        }
                                    `"
                                    >
                                        {{ comment.u_display_name }}
                                    </div>
                                    <div v-else>{{ comment.u_display_name }}</div>
                                </div>
                                <div class="column is">
                                    <CommentRatingButtons
                                        class="flex"
                                        :id="comment.comment_id"
                                        :current="comment.rating"
                                    />
                                </div>
                            </div>
                            <div>
                                <div v-if="comment.updated_by" class="ft-tooltip" :tooltiptext="comment.e_display_name">
                                    |Edited
                                </div>
                                <div
                                    v-if="comment.a_display_name"
                                    class="ft-tooltip"
                                    :tooltiptext="comment.a_display_name"
                                >
                                    |Posted By
                                </div>
                            </div>
                        </div>
                        <div v-if="audio_hash && comment.comment_id in audio_hash" class="mt">
                            <audio class="active-audio" controls="controls" autobuffer="autobuffer">
                                <source :src="api_get_url + audio_hash[comment.comment_id]" />
                            </audio>
                        </div>
                        <div class="comment-content ProseMirror" v-html="comment.content"></div>
                        <div
                            class="button-container"
                            v-if="
                                $store.getters.check_access(['comments', 'edit', '*', 'w']) &&
                                $store.state.global.show_edit
                            "
                        >
                            <div
                                class="button edit-button"
                                @click="(comment_to_edit = comments[i]), toggle_edit_mode()"
                            >
                                Edit
                            </div>
                            <div
                                v-if="selected !== 'archived'"
                                class="button edit-button"
                                @click="(comment_to_edit = comments[i]), change_status('ARCHIVED')"
                            >
                                Archive
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="mt">No {{ selected }} insights could be found</div>
            </div>
            <div v-else>
                <New @toggle-edit-mode="toggle_edit_mode" @updated="edit_updated" :definitionType="definitionType" />
            </div>
        </div>
        <div v-else class="mt">
            <Edit
                :comment-to-edit="comment_to_edit"
                :status="selected"
                @toggle-edit-mode="toggle_edit_mode"
                @updated="edit_updated"
                @change_status="change_status"
            />
        </div>
    </div>
</template>

<script>
import CommentWarning from '@/components/bespoke/commenting/CommentWarning.vue';
import Edit from '@/components/bespoke/commenting/Edit.vue';
import Filters from '@/components/bespoke/commenting/Filters.vue';
import New from '@/components/bespoke/commenting/New.vue';
import Notification from '@/components/ui/Notification.vue';
import Spinner from '@/components/bespoke/commenting/Spinner.vue';
import SwitchBoxUniversal from '@/components/ui/SwitchBoxUniversal.vue';
import CommentRatingButtons from '@/components/ui/CommentRatingButtons.vue';

export default {
    components: {
        CommentRatingButtons,
        CommentWarning,
        Edit,
        Filters,
        New,
        Notification,
        Spinner,
        SwitchBoxUniversal
    },
    data: function () {
        return {
            comments: [],
            selected: 'current',
            filters: {
                order_by: null,
                search: null
            },
            render_key: 0,
            edit_mode: false,
            comment_to_edit: null,
            audio_hash: null,
            loading: false,
            should_show_comments: true,
            definitionType: 'global'
        };
    },
    mounted: function () {
        this.load_comments();
    },
    methods: {
        fetchKpiObservationsSettings() {
            this.$http
                .post('force', '/kpidata/get-kpi-observations', { location: this.$helper.location() })
                .then(response => {
                    this.definitionType = response.data.type;
                })
                .catch(error => {
                    console.error('Error fetching KPI observations:', error);
                    this.definitionType = 'global';
                });
        },
        change_status: function (status) {
            this.$chttp
                .post(`/comments/edit`, {
                    id: this.comment_to_edit.comment_id,
                    status: status
                })
                .then(succ => {
                    this.edit_updated(status);
                }, this.$helper.error);
        },
        change_width: function (n) {
            this.$store.commit('edit_commenting_width', n);
        },
        edit_updated: function (status) {
            this.edit_mode = false;
            if (status === 'LIVE') {
                this.selected = 'current';
            } else if (status !== 'DELETED' && status !== 'ARCHIVED') {
                this.selected = status.toLowerCase();
            }
            this.load_comments();
        },
        toggle_edit_mode: function () {
            if (this.selected === 'new') {
                this.edit_mode = false;
                this.selected = 'current';
            } else {
                this.edit_mode = !this.edit_mode;
            }
        },
        toggle_comment_widget: function () {
            this.$store.state.commenting.width = 0;
        },
        load_comments: function () {
            if (this.$store.state.commenting.width === 0) return;
            this.comments = [];
            let to_load = this.$helper.location();
            to_load.status = (this.selected === 'current' ? 'live' : this.selected).toUpperCase();
            to_load.retail_unit = this.$store.getters.market;

            to_load.filters = {
                order_by: this.selected === 'archived' ? this.filters.order_by : null,
                search: this.selected === 'archived' ? this.filters.search : null
            };
            this.$chttp.post('/comments/load', to_load).then(
                succ => {
                    if (succ.data) {
                        this.comments = succ.data;
                    }
                },
                error => {}
            );
            this.fetchKpiObservationsSettings();
        },
        load_audio: function (content, id) {
            this.loading = true;
            this.$chttp
                .post('/tts/create', {
                    text: content
                })
                .then(succ => {
                    if (succ.data) {
                        if (this.audio_hash === null) {
                            this.audio_hash = { [id]: succ.data.hash };
                        } else {
                            this.audio_hash[id] = succ.data.hash;
                        }
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        remove_audio: function (id) {
            delete this.audio_hash[id];
        },
        switch_toggle: function () {
            this.$store.state.global.show_edit = !this.$store.state.global.show_edit;
        },
        update_filters: function (filters) {
            this.filters = filters;
            this.load_comments();
        }
    },
    watch: {
        selected: function () {
            this.edit_mode ? this.toggle_edit_mode() : null;
            this.audio_hash = null;
            this.selected !== 'new' ? this.load_comments() : null;
        },
        $route: function (to, from) {
            this.render_key++;
            this.load_comments();
        },
        '$store.getters.market': function() {
            this.load_comments();
        }
    },
    computed: {
        api_get_url: function () {
            return `${process.env.VUE_APP_BASE_URL}/tts/get/`;
        },
        number_of_audio_clips: function () {
            return this.audio_hash ? Object.keys(this.audio_hash).length : 0;
        }
    }
};
</script>

<style scoped>
.arrows {
    display: flex;
    right: 3rem;
    top: 1rem;
}

.arrow-right {
    border-top: 0.75rem solid transparent;
    border-bottom: 0.75rem solid transparent;
    border-left: 0.75rem solid #ffff;
    height: 0;
    width: 0;
}

.arrow-left {
    border-top: 0.75rem solid transparent;
    border-bottom: 0.75rem solid transparent;
    border-right: 0.75rem solid #ffff;
    height: 0;
    width: 0;
}
.comment-meta {
    font-size: 0.85rem;
    border-bottom: 1px solid #0051ba;
}

.comment-container {
    margin: 1rem 1rem 0 1rem;
}

.comment-content {
    text-align: left;
}

.comment-border {
    border-bottom: 1px solid #a7a4a4;
    padding-bottom: 1rem;
}

.delete {
    right: 1rem;
    top: 0.9rem;
}

.edit-button {
    width: calc(50% - 1rem);
}

i.far.fa-comment-alt {
    font-size: 1.5em;
}

#comment-widget {
    background-color: white;
    border-left: 0;
    box-shadow: 0 0 1px #575757;
    display: block;
    height: calc(100vh - 3.5rem);
    overflow-y: auto;
    position: fixed;
    top: 3.5rem;
    width: inherit;
    z-index: 2;
}

.observations-title {
    font-size: 1.2rem;
}

.titlebox {
    color: white;
    padding: 2vh;
    position: relative;
    width: 100%;
    background-color: #0051ba;
}

@media screen and (max-width: 768px) {
    #comment-widget {
        position: inherit;
        margin-top: 1em;
        width: 100%;
    }
}

.fa-times-circle {
    cursor: pointer;
    font-size: 1.5em;
}

.tabs {
    color: #fff;
    background-color: #0051ba;
}

.tab {
    height: 2.5rem;
    position: relative;
    border-radius: 14px 14px 0 0;
}

.tab-text {
    position: absolute;
    padding: 0.5rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.tab:hover {
    background-color: #4677b6;
}

.tabs ul {
    border: 0;
}

.active {
    background-color: #fff !important;
    color: #0051ba !important;
}

.switch-placement {
    left: 1rem;
}

.edit-placement {
    left: 4.5rem;
    top: 1.35rem;
}

.slash-placement {
    left: 4.35rem;
    top: 1.35rem;
}

.tts-button {
    border: none;
    background-color: none;
    font-size: 1.1rem;
}

.tts-button:hover {
    background-color: rgb(235, 235, 235);
    color: #0051ba;
}

.tts-delete {
    margin-top: -0.2rem;
    margin-left: 1.5rem;
}
</style>
