<template>
<div>
    <Title
        title="Sales and Quantity per Country"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms">
        <Base100Top8Triple
            :key="top_8.render"
            v-if="top_8.render"

            title="Sales and Quantity per Country Top 10 Countries"
            :showtitle="!$store.state.global.bot_mode"

            subtitle=""
            
            :xcategories="top_8.xcategories"
            yaxislabel="Sales & Sales Quantity index"
            ymetric=""
            
            type="bar"
            :color1="$store.state.colors['yellow']"
            :color2="$store.state.colors['grey']"
            :color3="$store.state.colors.YG"

            seriesname1="Sales YTD Index"
            seriesname2="Qty YTD Index"
            seriesname3="Goal"

            :seriesset1="top_8.seriesset1"
            :seriesset2="top_8.seriesset2"
            :seriesset3="top_8.seriesset3"

            seriestype1="bar"
            seriestype2="bar"
            seriestype3="bar"
        />
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Base100Top8Triple from '@/components/visualisations/bar/Base100Top8Triple.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Base100Top8Triple,
        Title
    },
    data: function() {
        return {
            top_8: {
                title: null,
                subtitle: null,
                legend: null,
                xcategories: null,
                yaxislabel: null,
                ymetric: null,
                
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],
                render: false
            },
            location: '/business/total-sales/sales-and-quantity-per-country'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.load_top_8();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
            ];
        },
        load_top_8: function() {
             this.$http.get(`/business/total-sales/sales-and-pieces-per-country/top-8`).then(
                succ => {
                let d = succ.data;
                this.top_8.xcategories = [];
                this.legend = Object.keys(d[0].measures);
                this.top_8.seriesset1 = [];
                this.top_8.seriesset2 = [];
                this.top_8.seriesset3 = [];
                d.forEach(country => {
                    this.top_8.xcategories.push(country.caption.replace(/ /g, "\n"));
                    this.top_8.seriesset1.push(country.measures[this.legend[0]]);
                    this.top_8.seriesset2.push(country.measures[this.legend[1]]);
                    this.top_8.seriesset3.push(country.measures[this.legend[2]]);
                });
                this.top_8.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        }
    }
}
</script>
