<template>
<div>
    <Title
        title="Core Areas"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
        
        <div v-if="values.length > 0" class="label-container">
            <p>Filter</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>
        
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms mb">
        <Top8Bar
            :key="top_8.render"

            v-if="top_8.seriesset1.length > 0"

            title="GWI Top 10 Countries"
            :showtitle="!$store.state.global.bot_mode"

            subtitle=""
            :xcategories="top_8.xcategories"
            :yaxislabel="`${options[values.indexOf(filter)]} TY/LY`"
            ymetric=""

            seriesname1="YTD"
            
            seriesname3="4WK Trend"
            seriesname4="Year Goal"

            seriestype1="bar"
            seriestype2="scatter"
            seriestype3="scatter"
            seriestype4="scatter"

            :seriesset1="top_8.seriesset1"
            :color1="$store.state.colors['yellow']"
            
            :seriesset3="top_8.seriesset2"
            :color3="$store.state.colors['grey']"

            :seriesset4="top_8.seriesset3"
            :color4="$store.state.colors.YG"

            :trend="true"
            :ranking="[]"
            :competitors="[]"
        />
        <span v-else>No data found for  {{$store.getters.selected_country_name}}</span>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Top8Bar from '@/components/visualisations/bar/Top8Bar.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Title,
        Top8Bar
    },
    props: {
        option: String
    },
    data: function() {
        return {
            top_8: {
                render: false,

                xcategories: [],

                seriesset1: [],                
                seriesset2: [],
                seriesset3: []
            },
            options: ['Living Room', 'Bedroom', 'Kitchen and Bathroom', 'Remaining Range'],
            values: ['Living room', 'Bedroom', 'Kitchen and Bathroom', 'Other'], //need to return to this and change to filtersets table - Ben 16 Jul 2020
            filter: null,
            location: '/business/sales-per-range/core-areas'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        if (this.option && this.option !== 'null') {
            this.filter = this.option.replace(/-/g, ' ');
        } else {
            this.filter = this.$store.getters.filter_check(this.values);
        }
        this.load_market_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: `${this.location}/null`
                },
                {
                    name: 'Trend',
                    link: `${this.location}/trend/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: this.filter_url
                },
                {
                    name: 'Top Family',
                    link: `${this.location}/top-families/null/${this.$store.getters.market}`
                },
                {
                    name: 'Growth Contribution',
                    link: `${this.location}/growth-contribution/${this.$store.getters.market}`
                },
                {
                    name: 'Sales in MEUR per week',
                    link: `${this.location}/sales-in-meur/${this.$store.getters.market}`
                },
                {
                    name: 'Index per week',
                    link: `${this.location}/index/${this.$store.getters.market}`
                }
            ];
        },
        load_market_data: function() {
            let url = '/business/sales-per-range/core-areas/top-8'
                let succ = (succ) => {
                    let d = succ.data;
                    this.top_8.xcategories = [];
                    this.top_8.seriesset1 = [];
                    this.top_8.seriesset2 = [];
                    this.top_8.seriesset3 = [];         
                    d.map(row => {
                        this.top_8.xcategories.push(row.caption);
                        this.top_8.seriesset1.push(row.data['YTD Index']);
                        this.top_8.seriesset2.push(row.data['4 Weeks Trend']);
                        this.top_8.seriesset3.push(row.data['Year Goal']);
                    });

                    this.top_8.render = true;
                }
            let error = (error) => console.log(error);
            this.$http.post(url, 
                {
                    filter: this.filter
                }
            ).then(succ, error);
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'core-areas'
            }).then(
                succ => {
                    succ.data.map(filter => {
                        this.values.push(filter.value);
                        this.options.push(filter.option);
                    });
                    this.filter = this.$store.getters.filter_check(this.values)
                },
                this.$helper.error
            );
            this.top_8.render = false;
            this.load_market_data();
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.top_8.render = false;
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        },
        '$route': function(to, from) {
            if (to.params.option === 'null') {
                this.$router.push(this.filter_url);
            }
        },
        filter: function() {
            this.$router.push(this.filter_url);
            this.load_market_data();
        }
    },
    computed: {
        filter_formatted:  function() {
            return this.filter ? this.filter.replace(/ /g, '-').replace(/_/g, '-') : null;
        },
        filter_url: function() {
            return `${this.location}/top-8/${this.filter_formatted}`
        }
    }
}
</script>

<style>

</style>
