<template>
<div>
    <Title
        title="Lead Times"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container">
            <p>Filter</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms">
        <Double
            v-if="double.render"

            :title="`Showing data for: ${$store.getters.selected_country_name}`"
            :showtitle="!$store.state.global.bot_mode"
            subtitle=""

            :xcategories="double.xcategories"
            :yaxislabel="`${filter} offered Lead-Time (days)`"
            ymetric="days"
            
            seriesname1="1WK Trend"
            seriestype1="line"
            :seriesset1="double.seriesset1"
            :color1="$store.state.colors['light-grey']"
            
            seriesname2="Year Goal"
            seriestype2="line"
            :seriesset2="double.seriesset2"
            :color2="$store.state.colors.YG"

            seriestype3="line"
            seriesset3=""

            :show="!$store.state.global.bot_mode"

        />
        <div v-else> No data found for {{$store.getters.selected_country_name}}</div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Double from '@/components/visualisations/general/Double.vue';


export default {
    components: {
        Buttons,
        DropDown,
        Title,
        Double
    },
    props: {
        market: String,
        option: String
    },
    data: function() {
        return {
            double: {
                title: null,
                subtitle: null,

                xcategories: null,
                yaxislabel: null,
                ymetric: null,
                
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],

                render: false
            },
            location: '/business/lead-times',
            options: ['Store fulfilment', 'Central fulfilment', 'Parcel'],
            values: ['Store fulfilment', 'Central fulfilment', 'Parcel'],
            filter: null
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.option && this.option !== 'null') {
            this.filter = this.option.replace(/-/g, ' ');
        } else {
            this.filter = this.$store.getters.filter_check(this.values);
        }
        
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.load_market_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.option}/${this.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8/null`
                },
            ];
        },
        load_market_data: function() {
            this.$http.post('/business/lead-times/market', {
                market: this.$store.getters.market,
                filter: this.filter
            }).then(
                succ => {
                    let d = succ.data;
                    this.double.xcategories = Object.keys(d[0].measures);
                    this.double.seriesset1 = [];
                    this.double.seriesset2 = [];
                    Object.values(d[0].measures).map(val => {
                        this.double.seriesset1.push(this.$helper.maybe(val['Week']));
                        this.double.seriesset2.push(this.$helper.maybe(val['Year Goal']));
                    });
                    this.double.render = true;
                },
                this.$helper.error
            );
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.update_view(this.$helper.market_check(this.market));
            this.double.render = false;
            this.load_market_data();
        },
        update_view: function(market) {	
            this.$store.commit('update_view', {	
                market: market,
                location: `${this.location}/${this.filter_formatted}`	
            });	
        }
    },
    watch: {
        '$store.getters.countries': function() {	
            this.$store.commit('update_market', this.market);	
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.double.render = false;
            this.update_view(newMarket);
            this.load_market_data();
        },
        '$route': function(to, from) {
            if (to.params.option === 'null') {
                this.update_view(this.$helper.market_check(this.market));
            }
        }
    },
    computed: {
        filter_formatted:  function() {
            return this.filter ? this.filter.replace(/ /g, '-').replace(/_/g, '-') : null;
        },
        filter_url: function() {
            return `${this.location}/${this.filter_formatted}/${this.$helper.market_check(this.market)}`
        }
    }
}
</script>

<style>

</style>