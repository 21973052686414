<template>
<div>
    <Title
        title="Customer Promised Service Precision"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
        <div class="label-container" 
            :class="!$store.getters.market ? 'ft-tooltip': null" 
            :tooltiptext="!$store.getters.market ? 'Select a country from top filter to see stores' : null"
        >
            <p>Market Area</p>
            <DropDown
                :key="stores.values.length"
                :options="stores.options"
                :values="stores.values"
                :default="stores.values[0]"
                @updateDropDown="update_store"

                
            />
        </div>
        <div class="label-container">
            <p>Filter</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms">
        <Quad
            :key="quad.render"
            v-if="quad.render"

            :title="`Showing data for: ${$store.getters.selected_country_name}`"
            :showtitle="!$store.state.global.bot_mode"
            subtitle=""

            :xcategories="quad.xcategories"
            :yaxislabel="options[values.indexOf(filter)]"
            ymetric="%"
            
            seriesname1="YTD"
            seriestype1="line"
            :seriesset1="quad.seriesset1"
            :color1="$store.state.colors['focus-yellow']"
            
            seriesname2="1WK"
            seriestype2="line"
            :seriesset2="quad.seriesset2"
            :color2="$store.state.colors['light-grey']"
            
            seriesname3="4WK"
            seriestype3="line"
            :seriesset3="quad.seriesset3"
            :color3="$store.state.colors['dark-grey']"

            seriesname4="Year Goal"
            seriestype4="line"
            :seriesset4="quad.seriesset4"
            :color4="$store.state.colors.YG"

            :show="!$store.state.global.bot_mode"
        />
        <div v-else> No data found for {{$store.getters.selected_country_name}}</div>
    </div>
    <div :key="$store.getters.page_size + 1" class="section box half-height ms1p5 mb">
        <PieChart
            :key="pie.render"
            v-if="pie.render"

            :title="$store.getters.selected_country_name"
            :subtitle="null"
            :names="pie.names"
            :values="pie.values"
        />
        <div v-else> No data found for {{$store.getters.selected_country_name}}</div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Quad from '@/components/visualisations/general/Quad.vue';
import PieChart from '@/components/visualisations/pie/PieChart.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Title,
        Quad,
        PieChart
    },
    props: {
        market: String
    },
    data: function() {
        return {
            quad: {
                title: null,
                subtitle: null,

                xcategories: null,
                yaxislabel: null,
                ymetric: null,
                
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],
                seriesset4: [],

                render: false
            },
            pie: {
                names: [],
                values: [],
                render: false
            },
            location: '/customer/promised-delivery-precision',
            filter: 'IKEA',
            options: ['Breach IKEA', 'Breach Customer', 'Breach Service Provider', 'No Breach'],
            values: ['IKEA', 'CUST', 'SP', 'NB']
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.load_market_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                }
            ];
        },
        load_market_data: function() {
            this.$http.post('customer/customer-promised-delivery-precision/market', {
                market: this.$store.getters.market, filter: this.filter
            }).then(
                succ => {
                    let d = succ.data.trend[0].measures[this.filter];
                    this.quad.xcategories = Object.keys(d);
                    this.quad.seriesset1 = [];
                    this.quad.seriesset2 = [];
                    this.quad.seriesset3 = [];
                    this.quad.seriesset4 = [];
                    Object.values(d).map(val => {
                        this.quad.seriesset1.push(this.$helper.maybe(val['YTD']));
                        this.quad.seriesset2.push(this.$helper.maybe(val['Week 1']));
                        this.quad.seriesset3.push(this.$helper.maybe(val['Week 4']));
                        this.quad.seriesset4.push(this.$helper.maybe(val['Goal']));
                    });

                    let pie_d = succ.data.pie[0].measures;
                    this.pie.names = Object.keys(pie_d);
                    this.pie.values = Object.values(pie_d);
                    this.quad.render = this.pie.render = true;
                },
                this.$helper.error
            );
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.quad.render = this.pie.render = false;
            this.load_market_data();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.quad.render = false;
            this.update_view(newMarket);
            this.load_market_data();
        },
        '$store.getters.stores': function() {
            return this.$store.getters.stores;
        },
    },
    computed: {
        stores: function() {
            if (this.$store.getters.stores.values.length) {
                return this.$store.getters.stores;
            } else {
                return {
                    all: [],
                    options: [],
                    values: []
                }
            }
        }
    }
}
</script>

<style>

</style>