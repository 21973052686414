var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", { attrs: { title: "Food Sales", location: _vm.location } }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons() },
          }),
          _c(
            "div",
            { staticClass: "label-container" },
            [
              _c("p", [_vm._v("Work station")]),
              _c("DropDown", {
                key: _vm.filter.work_station.state,
                attrs: {
                  options: _vm.filter.work_station.options,
                  values: _vm.filter.work_station.values,
                  default: _vm.filter.work_station.state,
                },
                on: { updateDropDown: _vm.update_filter_work_station },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                key: _vm.$store.getters.market,
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: _vm.$store.getters.market,
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size,
          staticClass: "section three-quarters-height box ms mb",
        },
        [
          _vm.top_8.render
            ? _c("Top8BarDoubleYAxis", {
                attrs: {
                  title: "Food Sales Top 10 Countries",
                  showtitle: !_vm.$store.state.global.bot_mode,
                  subtitle: "",
                  xcategories: _vm.top_8.xcategories,
                  yaxislabel1: "Food Sales TY/LY",
                  ymetric1: "",
                  yaxislabel2: "Growth(MEUR)",
                  ymetric2: "",
                  seriesname1: "YTD Growth MEURO",
                  seriesname2: "YTD Index",
                  seriesname3: "4WK Index",
                  seriesname4: "Goal",
                  seriestype2: "bar",
                  seriestype1: "line",
                  seriestype3: "scatter",
                  seriestype4: "scatter",
                  seriesset1: _vm.top_8.seriesset1,
                  color1: "#787878",
                  seriesset2: _vm.top_8.seriesset2,
                  color2: _vm.$store.state.colors["yellow"],
                  seriesset3: _vm.top_8.seriesset3,
                  color3: _vm.$store.state.colors["grey"],
                  seriesset4: _vm.top_8.seriesset4,
                  color4: _vm.$store.state.colors.YG,
                  trend: true,
                  ranking: [],
                  competitors: [],
                },
              })
            : _c("div", [
                _vm._v(
                  "No data for " +
                    _vm._s(_vm.$store.state.selected.country) +
                    ", with filter " +
                    _vm._s(_vm.filter.work_station.state)
                ),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }