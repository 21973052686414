<template>
<div>
    <Title title="HFB" :location="location" />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container">
            <p>HFB</p>
            <DropDown
                v-if="hfbs.all.length > 0" 
                :key="hfbs.all.length"
                :options="hfbs.options"
                :values="hfbs.values"
                :default="hfbs.values[0]"
                @updateDropDown="update_hfb"
            />
        </div>

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown 
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>

    <Table
        :key="table.render"

        :headers="table.headers"
        :rows="table.rows"
        
        :decimal-settings="table.decimal_settings"
        :data-settings="{ nullify_zeros: true }"
        :font-settings="{ bold: {row: {regex: 'Total Retail Countries' } } }"
        :icon-settings="table.icon_settings"
        :sort-settings="{ 'YTD MEUR': { asc: false } }"
        
        class="ms1p5"
    />
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: [],
                decimal_settings: {},
                render: false
            },
            hfb: 'Total HFB',
            location: '/business/sales-per-range/hfb'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.load_ingka_by_country();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By HFB',
                    link: `${this.location}/by-hfb/${this.$store.getters.market}`
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Channel Contribution',
                    link: `${this.location}/channel-contribution/${this.$store.getters.market}`
                },
            ];
        },
        load_ingka_by_country: function() {
            this.table.render = false;

            let url = 'business/sales-per-range/sales-per-hfb/total-ingka-by-country';
            let succ = (succ) => {
                let d = succ.data;
                this.table.headers = [];
                this.table.rows = [];

                if (d[0].data === null) {
                    return;
                }

                let keys = Object.keys(d[0].data);
                if (d[0].measure_metadata) {
                    this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                }
                this.table.headers = [['Country'].concat(keys).map(header => { let t = {}; t[header] = 1; return t; })];

                this.table.rows = d.map(row => {
                    let temp = [];
                    // If the key/values don't exist, set them to 0
                    
                    keys.map(key => {
                        if (row.data && key in row.data) {
                            temp.push(this.$helper.maybe(row.data[key]));
                        } else {
                            temp.push(0);
                        }
                    });
                    return [row.caption].concat(temp);
                });
                
                this.table.icon_settings = {
                    'YTD Index': {
                        icon: 'circle',
                        comparison: 'Year Goal',
                    },
                    '4WK Index': {
                        icon: 'arrow',
                        comparison: 'YTD Index'
                    },
                    'YTD Growth MEUR': {
                        icon: 'bar',
                        comparison: 'YTD Growth MEUR',
                        ignore: ['Total Retail Countries']
                    }
                }
                this.$helper.header_replace(this.table.headers, {
                    'R4 Index': '4WK Index',
                    'Index YTD': 'YTD Index'
                });
                if ('R4 Index' in this.table.decimal_settings) {
                    this.table.decimal_settings['4WK Index'] = this.table.decimal_settings['R4 Index'];
                }
                if ('Index YTD' in this.table.decimal_settings) {
                    this.table.decimal_settings['YTD Index'] = this.table.decimal_settings['Index YTD'];
                }
                this.table.render = true;                
            }

            let error = (error) => console.log(error);
            this.$http.post(url, {
                market: 'ALL',
                hfb: this.hfb
            }).then(succ, error);

        },
        update_hfb: function (hfb) {
            this.hfb = hfb;
            this.load_ingka_by_country();
        },
        update_view: function (market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function (newMarket, oldMarket) {
            this.update_view(newMarket);
        }
    },
    computed: {
        hfbs: function() {
            return this.$store.getters.hfbs;
        }
    }
}
</script>
