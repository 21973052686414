<template>
<div class="kpi-box">
    <div class="body">
        <table class="table is-striped is-fullwidth is-narrow" v-if="data">
            <thead>
                <tr class="grey-background">
                    <th
                        v-for="(header, header_key) in [{label:title, value: 1}, ...chunked_data[0][0].headers[0]]"
                        :key="header_key"
                        :colspan="header.value"
                        :class="{'header': header_key !== 0}"
                    >
                       {{header.label}}
                    </th>
                </tr>
            </thead>
            
            <tbody>
                <template v-for="(data_grouped_by_headers, i) in chunked_data">
                    <tr v-if="data_grouped_by_headers[0].headers[1]">
                        <td></td>
                        <td v-for="(sub_header, subheader_key) in data_grouped_by_headers[0].headers[1]" :key="`${i}-${subheader_key}-1`"
                            class="sub-header"
                        >
                            <span :class="[sub_header.label === 'blank' ? 'is-invisible' : '']">{{sub_header.label}}</span>
                        </td>
                    </tr>
                    <tr
                        v-for="(row, j) in data_grouped_by_headers"
                        :key="`${i}-${j}-2`"
                        @click="page_link(row.name)"
                        class="cursor-pointer"
                    >
                        <td 
                            class="name-column"
                            :class="`${row.version_tooltip ? 'ft-tooltip' : ''}`"
                            :tooltiptext="row.version_tooltip || ''"
                        >
                            <span v-if="!row.parent" class="bold-kpi-name">{{row.name}}</span>
                            <span v-else>- {{row.name}}</span>
                        </td>
                        <td v-for="(value, k) in row.data_keys"
                            :key="`${i}-${k}-3`"
                            class="data-column text-left"
                        >
                            <div v-if="`icon_${value}` in row.icon_values" class="inline-flex">
                                <div>{{$helper.round(row.measures[value], null, ',')}}{{row.measures[value] ? row.append : ''}}</div>
                                <div v-html="icon_logic(row.icon_values, value)"></div>
                            </div>
                            <div v-else>
                                {{$helper.round(row.measures[value], null, ',')}}{{row.measures[value] ? row.append : ''}}
                            </div>
                        </td>
                    </tr>
                </template>

            </tbody>
        </table>
        <div v-else class="pt mt">
            <div v-if="!$store.getters.check_access([title.toLowerCase(), '*', 'r'])">You do not have access to this KPI</div>
            <div v-else>No data is available for this KPI.</div>
        </div>
    </div>
</div>
</template>

<script>
import value_creation_mapping from '@/assets/scripts/value-creation-mapping.js';

export default {
    props: {
        box_name: String,
        data: Array,
        title: String
    },
    computed: {
        // Handles kpis having different subheadings
        chunked_data: function() {
            if (!this.data) return this.data;
            let headers_buckets = [];
            let data_by_headings = [];
            this.data.forEach(row => {
                const headers_str = JSON.stringify(row.headers[1]);
                if (!headers_buckets.includes(headers_str)) headers_buckets.push(headers_str);
                const bucket_index = headers_buckets.indexOf(headers_str);
                data_by_headings[bucket_index] = data_by_headings[bucket_index] ? [...data_by_headings[bucket_index], row] : [row];
            });
            return data_by_headings;
        }
    },
    methods: {
        page_link: function(selected_kpi_name) {
            if (selected_kpi_name === 'Services' || selected_kpi_name === 'Physical' || selected_kpi_name === 'Digital' || selected_kpi_name === 'Support') selected_kpi_name = 'Happy Customer';
            else if (selected_kpi_name.toLowerCase().includes('zero waste') || selected_kpi_name.toLowerCase().includes('recycling rate')) selected_kpi_name = 'Waste';
            else if (selected_kpi_name === 'HF') selected_kpi_name = 'Quantity';
            else if (selected_kpi_name.toLowerCase().includes('operational result')) selected_kpi_name = 'Operational Result';
            else if (selected_kpi_name.toLowerCase().includes('choos') || selected_kpi_name.toLowerCase().includes('worker')) selected_kpi_name = 'Retention';
            else if (selected_kpi_name.toLowerCase().includes('gross margin')) selected_kpi_name = 'Operational Gross Margin Forecast vs Goal';

            let path = '';
            for (let i = 0; i<4; i++) {
                path = this.find_in_areas(value_creation_mapping[i], selected_kpi_name);
                if (path) break;
            }
            if (path)
                this.$router.push(path);
        },
        find_in_areas: function(area, selected_kpi) {
            if (area.name.toLowerCase().includes(selected_kpi.toLowerCase()) && area.path) {
                this.$router.push(area.path);
                return;
            } else {
                if ('alt' in area) {
                    if (area.alt.find(kpi => kpi.name.toLowerCase().includes(selected_kpi.toLowerCase()))) {
                        this.$router.push(area.path);
                        return;
                    }
                }
                if ('areas' in area) {
                    area.areas.forEach(kpi_in_area => {
                        this.find_in_areas(kpi_in_area, selected_kpi);
                    });
                }
            }
        },
        icon_logic: function(icon_values, value_name) {
            if (!icon_values) return;
            let color;
            let direction;
            let icon_type = icon_values[`icon_${value_name}`];
            let icon_value = icon_values[`icon_value_${value_name}`];
            let icon_label = icon_values[`icon_label_${value_name}`];
            let label = icon_label || null;

            switch (icon_value) {
                case 1:
                    color = 'success';
                    direction = 'up';
                    break;
                case -1:
                    color = 'danger';
                    direction = 'down';
                    break;
                case 0: 
                    return `
                        <span class="icon has-text-info ${label ? 'ft-tooltip' : ''}" ${`tooltiptext="${label}"`}>
                            <i class="fas fa-minus"></i>
                        </span>
                    `
                default:
                    return `
                        <span class="icon invisible">
                            <i class="fas fa-circle"></i>
                        </span>
                    `;
            }

            return `
                <span class="icon has-text-${color} ${label ? 'ft-tooltip' : ''}" ${`tooltiptext="${label}"`}>
                    <i class="fas fa-${icon_type}${icon_type === 'arrow' ? `-${direction}` : ''}"></i>
                </span>
            `;
        }
    }
}
</script>

<style lang="scss" scoped>
.bold-kpi-name {
    font-weight: 800;
}
.data-column {
    text-align: right;
}

.header {
    text-align: center !important;
    border-left: 1px solid white;
}

.kpi-box {
    background-color: #FFF;
    height: 100%;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .body {
        background-color: #FFF;
    }
}

.kpi-box .table th {
    border-bottom: 2px solid #CCE3FF;
    font-weight: bold;
    white-space: nowrap;
}

.kpi-box .table tr td {
    font-weight: 400;
}

.meta-desc {
    font-size: 0.8rem;
    padding-left: 0.5rem;
    text-align: left;
}

.name-column {
    width: 42%;
}

.set-width {
    width: 4em;
}

.sub-header {
    font-size: 0.9rem;
    font-weight: bold !important;
    text-align: center;
}

.grey-background {
    font-size: 0.8rem;
    background-color: #e3e3e1;
}

table.table thead tr th {
    color: black !important;
}
</style>
