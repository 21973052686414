var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: { title: "Positive Impact Perception", location: _vm.location },
      }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons() },
          }),
          _c(
            "div",
            { staticClass: "label-container" },
            [
              _c("p", [_vm._v("Rolling period")]),
              _c("DropDown", {
                key: _vm.filter.rolling_period.state,
                attrs: {
                  options: _vm.filter.rolling_period.options,
                  values: _vm.filter.rolling_period.values,
                  default: _vm.filter.rolling_period.state,
                },
                on: { updateDropDown: _vm.update_filter_rolling_period },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: "",
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size,
          staticClass: "section three-quarters-height box ms mb",
        },
        [
          _vm.top_8.render
            ? _c("Top8BarCompetitor", {
                attrs: {
                  title: "Positive Impact Perception Top 10 Countries",
                  showtitle: !_vm.$store.state.global.bot_mode,
                  subtitle: "",
                  xcategories: _vm.top_8.xcategories,
                  yaxislabel: _vm.top_8.yaxislabel,
                  ymetric: "%",
                  seriesname1: _vm.top_8.seriesname1,
                  seriestype1: _vm.top_8.seriestype1,
                  seriesset1: _vm.top_8.seriesset1,
                  color1: _vm.$store.state.colors["yellow"],
                  seriesname2: _vm.top_8.seriesname2,
                  seriestype2: _vm.top_8.seriestype2,
                  seriesset2: _vm.top_8.seriesset2,
                  color2: _vm.$store.state.colors["grey"],
                  seriesname3: _vm.top_8.seriesname3,
                  seriestype3: _vm.top_8.seriestype3,
                  seriesset3: _vm.top_8.seriesset3,
                  color3: "#787878",
                  seriesname4: _vm.top_8.seriesname4,
                  seriestype4: _vm.top_8.seriestype4,
                  seriesset4: _vm.top_8.seriesset4,
                  color4: _vm.$store.state.colors.YG,
                  ranking: _vm.top_8.ranking,
                  competitors: _vm.top_8.competitors,
                },
              })
            : _c("div", [_vm._v(" No top 10 data availible")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }