export default [
    {
        "name": "Home",
        "path": "/home",
        areas: [
            {
                "name": "all",
                "path": "/home/all"
            }
        ]
    },
    {
        "name": "Landing Page",
        "path": "/landing-page"
    },
    {
        "name": "Get HFB",
        "path": "/hfb"
    },
    {
        "name": "Get Help Links",
        "path": "/help-links/get"
    },
    {
        "name": "Known Issues",
        "path": "/known-issues/get"
    },
    {
        "name": "Get Disabled KPIs",
        "path": "/disabled-kpis/get"
    },
    {
        "name": "Get Stores",
        "path": "/stores"
    },
    {
        "name": "Country Overview",
        "path": "/country-overview",
        "areas": [
            {
                "name": "by country",
                "path": "/country-overview/country"
            },
            {
                "name": "by kpi",
                "path": "/country-overview/kpi"
            },
            {
                "name": "benchmark",
                "path": "/country-overview/benchmark"
            },
        ]
    },
    {
        "name": "Country Insights",
        "path": "/country-insights",
        "areas": [
            {
                "name": "Introduction",
                "path": "/country-insights/introduction"
            },
            {
                "name": "Three Roads",
                "path": "/country-insights/three-roads"
            },
            {
                "name": "Brand",
                "path": "/country-insights/brand"
            },
            {
                "name": "Business",
                "path": "/country-insights/business"
            },
            {
                "name": "Customer",
                "path": "/country-insights/customer"
            },
            {
                "name": "Financial",
                "path": "/country-insights/financial"
            },
            {
                "name": "People",
                "path": "/country-insights/people"
            },
            {
                "name": "Process",
                "path": "/country-insights/process"
            },
            {
                "name": "Sustainability",
                "path": "/country-insights/sustainability"
            }
        ]
    },
    {
        "name": "Executive Summary",
        "path": "/executive-summary"
    },
    {
        "name": "Admin Dashboard",
        "path": "/admin",
        "areas": [
            {
                "name": "Announcements",
                "path": "/admin/announcements",
            },
            {
                "name": "Help Links Add & Delete",
                "path": "/admin/help-links",
            },
            {
                "name": "Country Insights Tracker",
                "path": "/admin/country-insights-tracker",
            },
            {
                "name": "Insights Templates",
                "path": "/admin/insights-templates",
            },
            {
                "name": "Insights Templates",
                "path": "/admin/insights-responsibles",
            },
            {
                "name": "Known Issues Add & Delete",
                "path": "/admin/known-issues",
            },
            {
                "name": "KPI Definitions",
                "path": "/admin/kpi-definition",
                "areas": [
                    {
                        "name": "Load KPI Definitions",
                        "path": "/admin/kpi-definition/load"
                    },
                    {
                        "name": "Rate KPI Definitions",
                        "path": "/admin/kpi-definition/rate"
                    }
                ]
            },
            {
                "name": "KPI Owners",
                "path": "/admin/kpi-owners",
                "areas": [
                    {
                        "name": "Load KPI Owners",
                        "path": "/admin/kpi-owners/load"
                    }
                ]
            },
            {
                "name": "Toggle KPI Enable/Disable",
                "path": "/admin/toggle-kpi",
            },
            {
                "name": "Retail Managers",
                "path": "/admin/retail-managers",
                "areas": [
                    {
                        "name": "Load KPI Owners",
                        "path": "/admin/kpi-owners/load"
                    }
                ]
            },
        ]
    },
    {
        "name": "Additional Commenting Features",
        "path": "/commenting",
        "areas": [
            {
                "name": "See Current Comments",
                "path": "/commenting/current/load",
            },
            {
                "name": "See Archived Comments",
                "path": "/commenting/archived/load",
            },
            {
                "name": "See Draft Comments",
                "path": "/commenting/draft/load",
            },
            {
                "name": "See Scheduled Comments",
                "path": "/commenting/scheduled/load",
            },
            {
                "name": "Post New Comments",
                "path": "/commenting/new",
            },
            {
                "name": "See Draft Comments",
                "path": "/commenting/draft/load",
            },
            {
                "name": "Other",
                "path": "/commenting/*",
                "areas": [
                    {
                        "name": "Edit Comment",
                        "path": "/commenting/*/edit",
                    },
                    {
                        "name": "Archive Comment",
                        "path": "/commenting/*/archive",
                    }
                ]
            },
        ]
    },
    {
        "name": "Commenting V3 Features",
        "path": "/comments",
        "areas": [
            {
                "name": "Load Comments",
                "path": "/comments/load",
            },
            {
                "name": "Create New Comments",
                "path": "/comments/new",
            },
            {
                "name": "Edit Comments",
                "path": "/comments/edit",
            }
        ]
    },
    {
        "name": "Announcements",
        "path": "/announcements",
    },
    {
        "name": "Powerpoint",
        "path": "/pptx",
    },
    {
        "name": "Excel",
        "path": "/excel",
    },
    {
        "name": "Feedback",
        "path": "/feedback",
    },
    {
        "name": "Download",
        "path": "/download"
    },
    {
        "name": "Get Filters",
        "path": "/filters"
    },
    {
        "name": "NPS",
        "path": "/nps"
    },
    {
        "name": "TTS",
        "path": "/tts"
    },
    {
        "name": "Bookmarks",
        "path": "/bookmarks"
    },
    {
        "name": "Version",
        "path": "/version"
    },
    {
        "name": "Data Load Tracker",
        "path": "/data-load-tracker"
    }
]