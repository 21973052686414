<template>
    <div :id="title" style="width: 100%; height:100%;"></div>
</template>

<script>
import * as echarts from 'echarts/dist/echarts.min.js';
export default {
    props: {
        title: String, //title of the graph
        showtitle: Boolean,
        xcategories: Array, //data that will be displayed on the x axis
        yaxislabel1: String, //name of the data on the y axis e.g 'After sales delivery share' or 'services sale by month'
        ymetric1: String, //'%', '£', 'kg' etc.
        yaxislabel2: String,
        ymetric2: String,
        yinterval1: Number,
        yinterval2: Number,
        yaxismin1: Number, //set the minimum value of the the y axis
        yaxismax1: Number, //set the maximum value of the the y axis
        yaxismin2: Number,
        yaxismax2: Number,
        yaxisindex: Array,

        datanames: Array, //name of the data such as IKEA 
        datatypes: Array, //bar, line, scatter etc.
        dataset: Array, //actual data array to fill the graph
        colors: Array, //color of the bar lie or scatter for the paticular data set
        
        show: Boolean, // true to show the exta features such as 'save graph as image', 'refresh', 'change to bar graph', etc
    },
    mounted: function() {
        let myChart = echarts.init(document.getElementById(this.title));
        let option = {
            title:{
                text: this.title,
                show: this.showtitle,
                subtextStyle: {
                    color: 'black',
                },
                top: '-5em',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            toolbox: {
                top: '0em',
                right: '70em',
                show: this.show,
                feature: {
                    restore: {},
                    saveAsImage: {}
                }
            },
            grid: {
               top: '80em',
               left: '105em',
               bottom: '80em',
               right: '105em'
            },
            legend: {
                top: '20em',
                left: '0em',
                data: this.datanames,
            },
            textStyle: {
                fontFamily: 'NotoIKEALatin-Regular',
            },
            xAxis: [
                {
                    type: 'category',
                    data: this.xcategories,
                    axisPointer: {
                        type: 'shadow'
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: this.yaxislabel1,
                    min: this.yaxismin1,
                    max: this.yaxismax1,
                    nameLocation: 'middle',
                    nameGap: 50,
                    axisLabel: {
                        formatter: `{value} ${this.ymetric1}`
                    }
                },
                {
                    type: 'value',
                    name: this.yaxislabel2,
                    min: this.yaxismin2,
                    max: this.yaxismax2,
                    nameLocation: 'middle',
                    nameGap: 57.5,
                    nameRotate: '270',
                    axisLabel: {
                        formatter: `{value} ${this.ymetric2}`
                    }
                }
            ],
            dataZoom: [
                {
                    show: this.show,
                    type: 'slider',
                    xAxisIndex: 0,
                    filterMode: 'empty',
                    left: '100em',
                    bottom: '10em',
                    handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                    handleSize: '80%',
                    handleStyle: {
                        color: '#fff',
                        shadowBlur: 3,
                        shadowColor: 'rgba(0, 0, 0, 0.6)',
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    }
                },
                {
                    show: this.show,
                    type: 'slider',
                    yAxisIndex: 0,
                    filterMode: 'empty',
                    left: '0em',
                    bottom: '70em',
                    handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                    handleSize: '80%',
                    handleStyle: {
                        color: '#fff',
                        shadowBlur: 3,
                        shadowColor: 'rgba(0, 0, 0, 0.6)',
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    }
                },
                {
                    show: this.show,
                    type: 'slider',
                    yAxisIndex: 1,
                    filterMode: 'empty',
                    right: '0em',
                    bottom: '70em',
                    handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                    handleSize: '80%',
                    handleStyle: {
                        color: '#fff',
                        shadowBlur: 3,
                        shadowColor: 'rgba(0, 0, 0, 0.6)',
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    }
                }
            ],
            series: []
        };
        this.datanames.forEach((name, i) => {
            option.series.push({
                name: name,
                type: this.datatypes[i],
                data: this.dataset[i],
                yAxisIndex: this.yaxisindex.includes(i) ? 1 : 0,
                color: this.colors[i]
            })
        });
        myChart.setOption(option);
    }

}
</script>