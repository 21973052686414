var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("b", [_vm._v(_vm._s(_vm.dates_picked))]),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.dates_picked,
          expression: "dates_picked",
        },
      ],
      staticClass: "calendar-input",
      attrs: {
        id: "DateTimePicker",
        type: "textinput",
        placeholder: "Pick a date...",
        "data-input": "",
      },
      domProps: { value: _vm.dates_picked },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.dates_picked = $event.target.value
        },
      },
    }),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "button calendar-button is-info",
        attrs: { id: "CalendarButton" },
      },
      [_c("i", { staticClass: "far fa-calendar-alt" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }