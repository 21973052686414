<template>
    <div class="kpi-box">
        <div class="body">
            <table class="table is-striped is-fullwidth is-narrow">
                <thead>
                    <tr class="grey-background">
                        <th></th>
                        <th class="header">Week</th>
                        <th>4 Week</th>
                        <th class="header">YTD</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="sub-header">Actual</td>
                        <td class="data-column text-left">
                            <div class="inline-flex">
                                <div>{{ data.actuals }}</div>
                            </div>
                        </td>
                        <td class="data-column text-left">
                            <div class="inline-flex">
                                <div>{{ data.actuals_r4 }}</div>
                            </div>
                        </td>
                        <td class="data-column text-left">
                            <div class="inline-flex bold_ytd_value">
                                <div>{{ data.actuals_fytd }}</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="sub-header">Index</td>
                        <td class="data-column text-left">
                            <div class="inline-flex">
                                <div>{{ data.actuals_index }}</div>
                            </div>
                        </td>
                        <td class="data-column text-left">
                            <div class="inline-flex">
                                <div>{{ data.r4_index }}</div>
                                <!-- <div><span class="icon has-text-danger ft-tooltip" tooltiptext="Compares to YTD Goal">
                    <i class="fas fa-circle"></i> </span>
                 </div> -->
                            </div>
                        </td>
                        <td class="data-column text-left">
                            <div class="inline-flex bold_ytd_value">
                                <div>{{ data.ytd_index }}</div>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td class="sub-header">Goal</td>
                        <td
                            class="data-column text-left"
                            v-bind="showMsfrToolTipText(data.goals) ? { tooltiptext: getMsfrToolTipText('Week') } : {}"
                        >
                            <div class="inline-flex">
                                <div>{{ data.goals }}</div>
                            </div>
                        </td>
                        <td
                            class="data-column text-left"
                            v-bind="
                                showMsfrToolTipText(data.goals_r4) ? { tooltiptext: getMsfrToolTipText('4Week') } : {}
                            "
                        >
                            <div class="inline-flex">
                                <div>{{ data.goals_r4 }}</div>
                            </div>
                        </td>
                        <td
                            class="data-column text-left"
                            v-bind="
                                showMsfrToolTipText(data.goals_fytd) ? { tooltiptext: getMsfrToolTipText('YTD') } : {}
                            "
                        >
                            <div class="inline-flex bold_ytd_value">
                                <div>{{ data.goals_fytd }}</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="sub-header">Index</td>
                        <td
                            class="data-column text-left"
                            v-bind="
                                showMsfrToolTipText(data.goals_index) ? { tooltiptext: getMsfrToolTipText('Week') } : {}
                            "
                        >
                            <div class="inline-flex">
                                <div>{{ data.goals_index }}</div>
                            </div>
                        </td>
                        <td
                            class="data-column text-left"
                            v-bind="
                                showMsfrToolTipText(data.goals_r4_index)
                                    ? { tooltiptext: getMsfrToolTipText('4Week') }
                                    : {}
                            "
                        >
                            <div class="inline-flex">
                                <div>{{ data.goals_r4_index }}</div>
                                <!-- <div><span class="icon has-text-danger ft-tooltip" tooltiptext="Compares to YTD Goal">
                    <i class="fas fa-circle"></i> </span>
                 </div> -->
                            </div>
                        </td>
                        <td
                            class="data-column text-left"
                            v-bind="
                                showMsfrToolTipText(data.goals_fytd_index)
                                    ? { tooltiptext: getMsfrToolTipText('YTD') }
                                    : {}
                            "
                        >
                            <div class="inline-flex bold_ytd_value">
                                <div>{{ data.goals_fytd_index }}</div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GlobalBox',
    data() {
        return {
            msfrToolTipTextWeek: 'Estimated Weekly Goal and Index, Calculated using the Weekly Shares from MSFR Goal',
            msfrToolTipText4Week:
                'Estimated 4Week Goal and Index, Calculated using the weekly sales Shares from MSFR Goal',
            msfrToolTipTextYtd: 'Estimated YTD Goal and Index, Calculated using the weekly sales Shares from MSFR Goal'
        };
    },
    props: {
        data: {
            type: Object,
            required: true
        },
        name: {
            type: String,
            required: true
        }
    },
    methods: {
        showMsfrToolTipText(value) {
            if (value === '-' || value === '- / -') {
                return false;
            }

            if (this.name !== 'Sales') {
                return true;
            }
        },
        getMsfrToolTipText(period) {
            switch (period) {
                case 'Week':
                    return this.msfrToolTipTextWeek;
                case '4Week':
                    return this.msfrToolTipText4Week;
                case 'YTD':
                    return this.msfrToolTipTextYtd;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.data-column {
    text-align: right;
}

.header {
    text-align: center !important;
    border-left: 1px solid white;
}

.kpi-box {
    background-color: #fff;
    height: 100%;
    position: relative;
    z-index: 1;

    .body {
        background-color: #fff;
    }
}

.kpi-box .table th {
    border-bottom: 2px solid #cce3ff;
    font-weight: bold;
    white-space: nowrap;
    text-align: center;
}

.kpi-box .table tr td {
    font-weight: 400;
    text-align: center;
}

.meta-desc {
    font-size: 0.8rem;
    padding-left: 0.5rem;
    text-align: left;
}

.name-column {
    width: 42%;
}

.set-width {
    width: 4em;
}

.sub-header {
    font-size: 0.9rem;
    font-weight: bold !important;
    text-align: left !important;
}

.grey-background {
    font-size: 0.8rem;
    background-color: #e3e3e1;
}

table.table thead tr th {
    color: black !important;
}

.kpi-box .table tr .bold_ytd_value {
    font-weight: 600;
    font-size: 1em;
}

.ft-tooltip:after,
[tooltiptext]:after {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    width: 200px;
}
</style>
