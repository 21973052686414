var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.icon === "Better Homes"
      ? _c("div", { staticClass: "icon" }, [
          _c("img", {
            attrs: {
              src: "assets/images/icons/homes.svg",
              alt: "Better Homes",
            },
          }),
          _c("div", { staticClass: "tagline" }, [_vm._v("Better Homes")]),
        ])
      : _vm._e(),
    _vm.icon === "Better Company"
      ? _c("div", { staticClass: "icon" }, [
          _c("img", {
            attrs: {
              src: "assets/images/icons/company.svg",
              alt: "For Now and Future Generations",
            },
          }),
          _c("div", { staticClass: "tagline" }, [_vm._v("Better Company")]),
        ])
      : _vm._e(),
    _vm.icon === "Better Planet"
      ? _c("div", { staticClass: "icon" }, [
          _c("img", {
            attrs: {
              src: "assets/images/icons/planet.svg",
              alt: "Better Company",
            },
          }),
          _c("div", { staticClass: "tagline" }, [_vm._v("Better Planet")]),
        ])
      : _vm._e(),
    _vm.icon === "Better Lives"
      ? _c("div", { staticClass: "icon" }, [
          _c("img", {
            attrs: {
              src: "assets/images/icons/lives.svg",
              alt: "Better Planet",
            },
          }),
          _c("div", { staticClass: "tagline" }, [_vm._v("Better Lives")]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }