<template>
<div id="tree-canvas">
    <div
        v-for="(node, index) in nodes"
        :id="index"
        :key="index"
        class="node"
        style="text-align: center;"
    >
        <!-- <div>Node {{index}}</div> -->
        <div v-html="node.content" />
    </div>
</div>
</template>

<script>
import { SVG } from '@svgdotjs/svg.js';
export default {
    data: function() {
        return {
            draw: null,
            x: 0,
            y: 0,
            nodes: [
				{
					name: "node 0",
					out: "right",
					content: "<table class=\"table\"><thead><th colspan=\"2\">Delivered Sales, net (Online+CSC)</th></thead><tbody><tr><td>YTD</td><td>100 000</td></tr><tr><td>Index</td><td>120</td></tr></tbody></table>"
                },
                {
					name: "node 1",
					in: "left",
					out: "bottom",
					content: "<table class=\"table\"><thead><th colspan=\"2\">Created Sales, net (Online+CSC)</th></thead><tbody><tr><td>YTD</td><td>100 000</td></tr><tr><td>Index</td><td>120</td></tr></tbody></table>"
				},
				{
					name: "node 2",
					in: "top",
					out: "bottom",
					content: "<table class=\"table\"><thead><th colspan=\"2\">Created Sales, gross (Online)</th></thead><tbody><tr><td>YTD</td><td>100 000</td></tr><tr><td>Index</td><td>120</td></tr></tbody></table>"
				},
				{
					name: "node 3",
					in: "top",
					out: "bottom",
					content: "<table class=\"table\"><thead><th colspan=\"2\">Created Nr of Trans (gross)</th></thead><tbody><tr><td>YTD</td><td>100 000</td></tr><tr><td>Index</td><td>120</td></tr></tbody></table>"
				},
				{
					name: "node 4",
					in: "top",
					content: "<table class=\"table\"><thead><th colspan=\"2\">Nr of Visits</th></thead><tbody><tr><td>YTD</td><td>100 000</td></tr><tr><td>Index</td><td>120</td></tr></tbody></table>"    
				},
            ],
        }
    },
    mounted: function() {
        let padding = 30;
        let tree_canvas = (document.getElementById('tree-canvas')).getBoundingClientRect();
        let nodes = document.getElementsByClassName('node');

        let draw = SVG().addTo('#tree-canvas').size(tree_canvas.width, tree_canvas.height);

        let last_height = 0;
            
        let last_size = null;
        let last_node = null;
        let distance_from_top = tree_canvas.y;
        // Line
        let calculate_coords = ({ x, y, width, height }, direction) => {
            switch (direction) {
                case 'top':
                    return {
                        x: x + width / 2,
                        y: y
                    }
                case 'bottom':
                    return {
                        x: x + width / 2,
                        y: y,
                    }
                case 'right':
                    return {
                        x: x + width,
                        y: y + height / 2
                    }
                case 'left':
                    return {
                        x: x,
                        y: y + height / 2
                    }
            }
        }

        // Node
        let calculate_position = (
            { x, y, width, height },
            cur_width,
            cur_height,
            last_out_direction,
            padding
        ) => {
            let height_diff = (height - cur_height) / 2;
            let width_diff = (width - cur_width) / 2;
            let pos = { x: x + width_diff, y: y + height_diff };
            
            switch (last_out_direction) {
                case 'top':
                    pos.y = pos.y - height - padding;
                    break;
                case 'bottom':
                    pos.y = pos.y + height + padding;
                    break;
                case 'left':
                    pos.x = pos.x - cur_width - padding;
                    break;
                case 'right':
                    pos.x = pos.x + width + padding;
                    break;
            }
            pos.x = pos.x > 0 ? pos.x : 0;
            pos.y = pos.y > 0 ? pos.y : 0;

            return pos;
		}

        /** Loop over the nodes in the HTML */
        for (let i = 0; i < nodes.length; i++) {
            let node = nodes[i];

            if (last_node) {
                let { width, height } = node.getBoundingClientRect();
                let pos = calculate_position(
                    last_size,
                    width, height,
                    this.nodes[i - 1].out, padding
                );

                node.style.left = `${pos.x}px`;
                node.style.top = `${pos.y}px`;

                let size = node.getBoundingClientRect();
                size.y -= distance_from_top;

                let start = calculate_coords(last_size, this.nodes[i - 1].out);
                let end   = calculate_coords(size, this.nodes[i].in);

                let line = draw.line(
                    start.x, start.y,
                    end.x, end.y
                );

                line.stroke({ color: '#F00', width: 1, linecap: 'square' });

                last_size = { x: pos.x, y: pos.y, height: size.height, width: size.width };
            }

            last_node = node;
            if (i === 0) {
                last_size = node.getBoundingClientRect();
                last_size.y -= distance_from_top;
            }
        }
    }
}
</script>

<style scoped>
.node {
    background-color: #FFF;
    border: 1px solid #000;
    margin: 0 !important;
    padding: 0 !important;
    position: absolute;
}

#tree-canvas {
    background-color: #CCC;
    position: relative;
    height: 100vh;
    width: 100%;
}

#tree-canvas svg {
    left: 0;
    position: absolute;
}
</style>