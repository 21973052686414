var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "assembly-component" }, [
      _c("h1", [
        _vm._v(
          "Hej! I will be assembled, for now I’m just a picture. Please stay tuned!"
        ),
      ]),
      _c("img", {
        attrs: {
          src: "assets/images/home/IngkaFlightTower.jpg",
          alt: "Total Ingka Home",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }