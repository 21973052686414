<template>
<div class="modal">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head pap5">
      <p class="modal-card-title">{{title}}</p>
      <button v-if="closeable" class="delete" aria-label="close" @click="close_modal"></button>
    </header>
    <section class="modal-card-body">
      <!-- Content ... -->
      <slot name="content"/>
    </section>
    <footer class="modal-card-foot pap5">
        <slot name="buttons"></slot>
    </footer>
  </div>
</div>
</template>

<script>
export default {
    props: {
        title: String,
        active: Boolean,
        closeable: Boolean,
    },
    data: function() {
        return {
            is_active: false
        }
    },
    methods: {
        close_modal: function() {
            this.$emit('close');
        },
        show_modal: function() {
            return {
                'is-active': this.is_active
            }
        }
    }
}
</script>

<style scoped>
.modal {
    z-index: 999 !important;
}

.modal-card {
    width: 66%;
}

.modal-card-body {
    min-height: 16rem;
}

.modal-card-head {
    padding: 1em;
}

.modal-card-foot {
    justify-content: flex-end !important;
}
</style>