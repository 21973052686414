export default {
	props: Object,
	data: {
		multiplyer: 1,
		static: ['Total Retail Countries', 'Total', 'Total HFB', 'Total Revenue', 'Global', 'ALL SERVICE TYPES']
	},
	logic: function(props, data) {
		this.props = props;
		if (!props || props && 'enabled' in props && !props.enabled) return;
		this.data = { ...this.data, ...data};
		this.data.meta.sort.asc = true;

		if (props.static) {
			if (typeof(props.static) === 'string') {
				if (!this.data.static.includes(props.static)) this.data.static.push(props.static);
			} else if (typeof(props.static) === 'object' && props.static.length) {
				props.static.forEach(static_row => {
					if (!this.data.static.includes(static_row)) this.data.static.push(static_row);
				});
			} else {
				throw new Error("Unexpected 'static' prop, expects string or array of strings");
			}
			delete props.static;
		}

		let headers = this.data.parsed_headers;
		let rows = this.data.parsed_rows;
		
		for (let key in props) {
			let i = 0; // The index that needs to be sorted
			headers.forEach(row => {
				let colspan = 0;
				row.forEach(cell => {
					let k = Object.keys(cell).shift();
					let v = Object.values(cell).shift();
					if (key === k) {
						i = colspan;
					}

					colspan += v;
				});
			});
			let multiplyer = props[key].asc ? 1 : -1;
			rows.sort((a, b) => 
				a[i].value < b[i].value ? -1 * multiplyer : 1 * multiplyer
			);
		}
		this.sort_trc(rows);

		data.parsed_rows = rows;
	},
	sort: function(row, column, headers, rows) {
		if (this.props.enabled === false) return;
		let i = 0;
		let temp = headers[row].slice(0, column);

		if (this.data.last_row === row && this.data.last_col === column) {
			this.data.multiplyer *= -1;
			this.data.meta.sort.asc = !this.data.meta.sort.asc;
		}

		this.data.last_row = row;
		this.data.last_col = column;
		
		// Count the span width
		temp.forEach(cell => {
			let v = Object.values(cell).shift();
			i += v;
		});

		let m = this.data.multiplyer;
		rows.sort((a, b) => {
			let av = a[i].value;
			let bv = b[i].value;
			let pf = parseFloat;
			av = pf(av) || av;
			bv = pf(bv) || bv;
			return av < bv ? -1 * m : 1 * m
		});
		this.sort_trc(rows);
		
		let s_row = this.data.meta.selected_row;
		if (s_row < 0 ) return;		
		let s_market = rows[s_row][0];
		this.data.meta.sort.c = i;
		this.data.meta.selected_row = rows.findIndex(row => row[0] === s_market);
	},
	sort_trc: function(rows) {
		rows.sort((a, b) => {
			const is_a_trc = this.data.static.indexOf(a[0].value);
			const is_b_trc = this.data.static.indexOf(b[0].value);
			if (is_a_trc < 0 && is_b_trc < 0) return 0;
			return is_a_trc > is_b_trc ? 1 : -1;
		});
	}
}