var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: { title: "Top Families Growth", location: _vm.location },
      }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons(), dataset: _vm.table },
          }),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                key: _vm.$store.getters.market,
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: _vm.$store.getters.market,
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "text-left ml" }, [
        _vm._v(
          "Showing data for: " +
            _vm._s(_vm.$store.getters.selected_country_name)
        ),
      ]),
      _vm.table.render
        ? _c("Table", {
            staticClass: "ms1p5 no-all",
            attrs: {
              headers: _vm.table.headers,
              rows: _vm.table.rows,
              "decimal-settings": _vm.table.decimal_settings,
              "icon-settings": _vm.table.icon_settings,
              "sort-settings": _vm.table.sort_settings,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }