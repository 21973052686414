<template>
    <div>
        <em class="is-size-4">Required content</em>
        <Title
            title="Admin Kitchen Sink"
            subtitle="Optional subtitle"
        />

        <hr/>
        <em class="is-size-4">Variable content</em>
        <div class="has-text-left">
            <div class="is-size-5"><em>Form elements</em></div>

            <br/><em>Basic Text input</em>
            <div class="field">
                <label class="label">Text input label</label>
                <div class="control">
                    <input v-model="input.basic_input" class="input" type="textinput" placeholder="Placeholder Text">
                </div>
            </div>

            <br/><em>Advanced Text input (icons and reactive helper)</em>
            <div class="field">
                <label class="label">Text input label</label>
                <div class="control has-icons-left has-icons-right">
                    <input v-model="input.advanced_input" class="input" type="textinput" placeholder="Placeholder Text">
                    <span class="icon is-small is-left">
                        <i class="fas fa-user"></i>
                    </span>
                    <span class="icon is-small is-right">
                        <i class="fas fa-globe"></i>
                    </span>
                </div>
                <p class="help" :class="[input.is_valid ? 'is-success' : 'is-danger']">{{input.helper_message}}</p>
            </div>

            <br/><em>Checkbox</em>
            <div class="field">
                <div class="control">
                    <label class="checkbox">
                        <input v-model="checkbox" type="checkbox">
                        I want this to be true
                    </label>
                </div>
            </div>

            <br/><em>Radio Buttons</em>
            <div class="field">
                <div class="control">
                    <label class="radio mr">
                        <input v-model="radio" value="true" type="radio" name="radio">
                        Yes
                    </label>
                    <label class="radio mr">
                        <input v-model="radio" value="false" type="radio" name="radio">
                        No
                    </label>
                </div>
            </div>

            <br/><em>Dropdown</em>
            <div class="field">
                <label class="label">Dropdown label</label>
                <div class="control">
                    <div class="select">
                        <select v-model="dropdown.selection" @change="dropdown_trigger">
                            <option
                                v-for="(option, i) in dropdown.options"
                                :key="i"
                                :value="dropdown.options[i]"
                            >
                                {{option}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <em>or alternatively use existing dropdown component</em><br/>
            <DropDown
                :options="dropdown.options"
                :values="dropdown.options"
                @updateDropDown="dropdown_trigger"
            />

            <div class="is-size-5 mt"><em>Search field</em></div>
            <div class="field has-addons">
                <div class="control has-icons-left">
                    <input v-model="input.search" class="input" type="textinput" placeholder="Search for...">
                    <span class="icon is-small is-left">
                        <i class="fas fa-search"></i>
                    </span>
                </div>
                <div class="control">
                    <button @click="search" class="button business">Search</button>
                </div>
            </div>

            <div class="is-size-5 mt"><em>Table (alternatively TableV2)</em></div>
            <div class="table-container">
                <table class="table is-narrow" v-if="table.render">
                    <thead>
                        <tr class="business">
                            <th v-for="(header, i) in table.headers" :key="i">{{header}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, j) in table.rows" :key="j">
                            <td>{{row.name}}</td>
                            <td>{{row.age}}</td>
                            <td>{{row.colour}}</td>
                            <td>
                                <button @click="delete_row(j)" class="button is-danger">
                                    Delete
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</template>

<script>
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/admin/Title.vue';

export default {
    components: {
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            checkbox: false,
            dropdown: {
                options: ['Apple', 'Banana', 'Cherry', 'Dragon Fruit'],
                selection: 'Placeholder selection'
            },
            input: {
                search: '',
                basic_input: '',
                advanced_input: '',
                is_valid: false,
                helper_message: 'Field requires text'
            },
            radio: true,
            table: {
                render: true,
                headers: ['Name', 'Age', 'Favourite colour', 'Remove'],
                rows: [
                    { name: 'John Doe', age: 40, colour: 'Green' },
                    { name: 'Terry Smith', age: 30, colour: 'Blue' },
                    { name: 'Jane Doe', age: 41, colour: 'Purple' },
                    { name: 'Mr. Smith', age: 27, colour: 'Red' },
                ]
                
            },
        }
    },
    mounted: function() {
    },
    methods: {
        delete_row: function(i) {
            this.table.rows.splice(i, 1);
        },
        dropdown_trigger: function() {
            console.info('The dropdown selection has changed');
        },
        search: function() {
            console.info('Search triggered.');
        },
        validate_input: function() {
            this.input.is_valid = !!this.input.advanced_input;
            this.input.helper_message = this.input.is_valid ? 'Field is valid' : 'Field is invalid';
        }
    },
    watch: {
        'input.advanced_input': function() {
            this.validate_input();
        }
    }
}
</script>

<style>
.field {
    max-width: 500px;
}
</style>