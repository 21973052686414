<template>
<div>
    <Title
        title="Services Gross Margin By Service"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />
        <div class="label-container">
            <p>Actuality</p>
            <DropDown
                :key="act_filter"
                :options="act_filter_options"
                :values="act_filter_values"
                :default="act_filter"
                @updateDropDown="update_act_filter"
            />
        </div>
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>

    <Table
        v-if="table.render"
        
        :headers="table.headers"
        :rows="table.rows"
        
        :icon-settings="table.icon_settings"
        :decimal-settings="table.decimal_settings"
        :data-settings="table.data_settings"
        :sort-settings="{static: ['Total delivery&collect/picking services','Total other services','ALL SERVICE TYPES']}"

        :font-settings="{ bold: {row: {regex: /all service|total/ } } }"
        :metaSettings="{is_selectable: false}"
    />
    <div v-else>No data found for {{$store.getters.selected_country_name}}</div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    props: {
        market: String
    },
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            table: {
                headers: [],
                render: false,
                rows: [],
                decimal_settings: {},
                data_settings: { nullify_zeros: true }
            },
            act_filter_options: [],
            act_filter_values: [],
            act_filter: null,
            location: '/finance/service-net/services-gross-margin'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'By Service',
                    link: `${this.location}/by-market/${this.$store.getters.market}`
                },
                {
                    name: 'Waterfall',
                    link: `${this.location}/waterfall/${this.$store.getters.market}`
                },
                {
                    name: 'By Service (Graph)',
                    link: `${this.location}/by-market-graph/${this.$store.getters.market}`
                }
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'service-net-actuality'
            }).then(
                succ => {
                    succ.data.map(filter => {
                        this.act_filter_values.push(filter.value);
                        this.act_filter_options.push(filter.option);
                    });
                    this.act_filter = this.$store.getters.filter_check(this.act_filter_values);
                    if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
                    this.load_ingka_by_country();
                },
                this.$helper.error
            );
        },
        load_ingka_by_country: function() {
            this.table.render = false;
            this.$http.post('finance/service-net-dashboard/income-by-service', {
                market: this.$store.getters.market,
                actuality: this.act_filter
            }).then(
                succ => {
                    if (!succ.data || !succ.data[0] || !succ.data[0].measures) return;
                    let d = succ.data;
                    if (d[0].measure_metadata) {
                        this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                    }
                    this.table.headers = null;
                    this.table.rows = null;
                    let keys = Object.keys(d[0].measures[0]);

                    this.table.rows = d[0].measures.map(row => {
                        let temp = [];

                        keys.map(key => {
                            if (!row[key]) {
                                temp.push(0);
                            } else {
                                temp.push(this.$helper.maybe(row[key]));
                            }
                        });
                        return temp;
                    });

                    this.table.icon_settings = {
                        'Number Of Services QTY': {
                            icon: 'bar',
                            comparison: 'Number Of Services QTY',
                            ignore: ['ALL SERVICE TYPES']
                        },
                        'Services Income kEuro': {
                            icon: 'bar',
                            comparison: 'Services Income kEuro',
                            ignore: ['ALL SERVICE TYPES']
                        },
                        'Commercial Cost Euro': {
                            icon: 'bar',
                            comparison: 'Commercial Cost Euro',
                            ignore: ['ALL SERVICE TYPES']
                        },
                        'After Sales Cost Euro': {
                            icon: 'bar',
                            comparison: 'After Sales Cost Euro',
                            ignore: ['ALL SERVICE TYPES']
                        },
                        'Total Cost Euro': {
                            icon: 'bar',
                            comparison: 'Total Cost Euro',
                            ignore: ['ALL SERVICE TYPES']
                        },
                        'Services Net Euro': {
                            icon: 'bar',
                            comparison: 'Services Net Euro',
                            ignore: ['ALL SERVICE TYPES']
                        },
                        'exclud. After Sales': {
                            fix: { post: '%' }
                        },
                        'incl.After Sales': {
                            fix: { post: '%' }
                        }
                    }

                    this.table.headers = [
                        [ 
                            { [this.$store.getters.selected_country_name]: 1, },
                            { [this.act_filter.toUpperCase()]: 16, },
                        ],
                        [
                            { '': 1, },
                            { 'Number Of Services': 2, },
                            { 'Services Income': 2, },
                            { 'Commercial Cost': 2, },
                            { 'After Sales Cost': 2, },
                            { 'Total Cost': 2, },
                            { 'Service Net': 2, },
                            { 'Service Net (incl.After Sales)': 2, },
                            { 'Gross Margin': 2, }
                        ],
                        [
                            { 'Service type': 1},
                            { 'QTY': 1, alias: 'Number Of Services QTY'},
                            { 'Index to LY': 1, },
                            { 'kEuro': 1, alias: 'Services Income kEuro'},
                            { 'Index to LY': 1, },
                            { 'kEuro': 1, alias: 'Commercial Cost Euro'},
                            { 'Index to LY': 1, },
                            { 'kEuro': 1, alias: 'After Sales Cost Euro'},
                            { 'Index to LY': 1, },
                            { 'kEuro': 1, alias: 'Total Cost Euro'},
                            { 'Index to LY': 1, },
                            { 'kEuro': 1, alias: 'Services Net Euro'},
                            { 'Index to LY': 1, },
                            { 'kEuro': 1, alias: 'Services Net Euro Incl'},
                            { 'Index to LY': 1, },
                            { 'exclud. After Sales': 1, },
                            { 'incl.After Sales': 1, }
                        ]
                    ];
                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        update_act_filter: function(act_filter) {
            this.act_filter = act_filter;
            this.$store.state.global.temp_act_filter = act_filter;
            this.load_ingka_by_country();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: `${this.location}/by-market`
            });
            this.load_ingka_by_country();
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        }
    }
}
</script>
