var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", { attrs: { title: "Stock Levels", location: _vm.location } }),
      _c("div", { staticClass: "options-bar" }, [
        _c(
          "div",
          { staticClass: "label-container" },
          [
            _c("p", [_vm._v("Filter")]),
            _c("DropDown", {
              key: _vm.values[0],
              attrs: {
                options: _vm.options,
                values: _vm.values,
                default: _vm.values[0],
              },
              on: { updateDropDown: _vm.update_filter },
            }),
          ],
          1
        ),
      ]),
      _vm.filter == "Summary"
        ? _c(
            "div",
            {
              key: _vm.$store.getters.page_size,
              staticClass: "section half-height box ms",
            },
            [
              _vm.summary.render
                ? _c("Quad", {
                    attrs: {
                      title: `Showing data for: ${_vm.$store.getters.selected_country_name}`,
                      showtitle: !_vm.$store.state.global.bot_mode,
                      xcategories: _vm.summary.xcategories,
                      yaxislabel: "Stock Level DCs, CDCs and stores (tm3)",
                      ymetric: "",
                      seriesname1: "Total",
                      seriestype1: "line",
                      seriesset1: _vm.summary.seriesset1,
                      color1: _vm.$store.state.colors["yellow"],
                      seriesname2: "CDC",
                      seriestype2: "line",
                      seriesset2: _vm.summary.seriesset2,
                      color2: _vm.$store.state.colors["grey"],
                      seriesname3: "Store",
                      seriestype3: "line",
                      seriesset3: _vm.summary.seriesset3,
                      color3: "black",
                      seriesname4: "CW",
                      seriestype4: "line",
                      seriesset4: _vm.summary.seriesset4,
                      color4: "purple",
                      show: !_vm.$store.state.global.bot_mode,
                    },
                  })
                : _c("div", [
                    _vm._v(
                      " No data found for " +
                        _vm._s(_vm.$store.getters.selected_country_name)
                    ),
                  ]),
            ],
            1
          )
        : _vm._e(),
      _vm.filter != "Summary"
        ? _c(
            "div",
            {
              key: _vm.$store.getters.page_size,
              staticClass: "section three-quarters-height box ms",
            },
            [
              _vm.quad.render
                ? _c("DeviationWith3Lines", {
                    attrs: {
                      title: `Stock Levels for: ${_vm.$store.getters.selected_country_name}.`,
                      showtitle: !_vm.$store.state.global.bot_mode,
                      subtitle: "",
                      xcategories: _vm.quad.xcategories,
                      yaxislabel1: "Stock Level DCs, CDCs and stores (tm3)",
                      ymetric1: "",
                      yaxislabel2: "Change Week TY-LY",
                      yaxismin2: -800,
                      yaxismax2: 800,
                      ymetric2: "",
                      seriesname1: "Change 1WK TY-LY",
                      seriestype1: "bar",
                      seriesset1: _vm.quad.seriesset1,
                      color1: _vm.$store.state.colors["yellow"],
                      seriestype2: "line",
                      seriesname3: `FY${_vm.last_year.substring(2)}`,
                      seriestype3: "line",
                      seriesset3: _vm.quad.seriesset3,
                      color3: _vm.$store.state.colors["dark-grey"],
                      seriesname4: `FY${_vm.current_year.substring(2)}`,
                      seriestype4: "line",
                      seriesset4: _vm.quad.seriesset4,
                      color4: _vm.$store.state.colors["focus-yellow"],
                      show: !_vm.$store.state.global.bot_mode,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }