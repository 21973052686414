var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: { title: "Profit & Loss", location: _vm.location },
      }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { dataset: _vm.table },
          }),
          _c(
            "div",
            { staticClass: "label-container" },
            [
              _c("p", [_vm._v("Profit and Loss View")]),
              _c("DropDown", {
                key: _vm.filters.business_area.values[0],
                attrs: {
                  default: _vm.filters.business_area.values[0],
                  options: _vm.filters.business_area.options,
                  values: _vm.filters.business_area.values,
                },
                on: { updateDropDown: _vm.update_business_area_filter },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "label-container" },
            [
              _c("p", [_vm._v("Comparable")]),
              _c("DropDown", {
                key: _vm.filters.is_comp.values[0],
                attrs: {
                  default: _vm.filters.is_comp.values[0],
                  options: _vm.filters.is_comp.options,
                  values: _vm.filters.is_comp.values,
                },
                on: { updateDropDown: _vm.update_is_comp_filter },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "label-container" },
            [
              _c("p", [_vm._v("Currency")]),
              _c("DropDown", {
                key: _vm.filters.currency.values[0],
                attrs: {
                  default: _vm.filters.currency.values[0],
                  options: _vm.filters.currency.options,
                  values: _vm.filters.currency.values,
                },
                on: { updateDropDown: _vm.update_currency },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.filters.stores.enabled,
                  expression: "filters.stores.enabled",
                },
              ],
              staticClass: "label-container",
              class: !_vm.$store.getters.market ? "ft-tooltip" : null,
              attrs: {
                tooltiptext: !_vm.$store.getters.market
                  ? "Select a country from top filter to see stores"
                  : null,
              },
            },
            [
              _c("p", [_vm._v("Market Area")]),
              _c("DropDown", {
                key: _vm.stores.values.length,
                attrs: {
                  options: _vm.stores.options,
                  values: _vm.stores.values,
                  default: _vm.stores.values[0],
                },
                on: { updateDropDown: _vm.update_store_filter },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.table.render
        ? _c("Table", {
            staticClass: "ms1p5 no-all ikea-yellow",
            attrs: {
              id: "profit-and-loss",
              headers: _vm.table.headers,
              rows: _vm.table.rows,
              "bridge-settings": _vm.table.bridge_settings,
              "meta-settings": {
                is_selectable: false,
                isSubtotalHighlight: true,
              },
              "sort-settings": { enabled: false },
              "decimal-settings": _vm.table.decimal_settings,
            },
          })
        : _c("div", { staticClass: "box ms" }, [
            _c("p", [_vm._v("No data could be found.")]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }