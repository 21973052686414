<template>
<div>
    <Title
        title="Operational Gross Margin Forecast Vs Goal"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>

        <div class="label-container">
            <p>Filter</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>
    </div>
    <Table
        v-if="data_found"
        :headers="table.headers"
        :rows="table.rows"
        :decimal-settings="table.decimal_settings"
        :icon-settings="table.icon_settings"
        :data-settings="{ nullify_zeros: true }"
        :font-settings="{ bold: {row: {regex: 'Total' } } }"
        :sort-settings="{ 'Market-Area': { asc: true } }"
        :meta-settings="{ is_selectable: false }"
        
        class="ms1p5"
    />
    <div v-else>No data for {{$store.state.selected.country}}, please select another country</div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    props: {
        market: String
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: [],
                icon_settings: {},
                decimal_settings: {},
                render: false
            },
            location: '/finance/operational-gross-margin-forecast-vs-goal',
            data_found: true,
            filter: null,
            options: [],
            values: []
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'By Market',
                    link: `${this.location}/${this.$store.getters.market}`
                }
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'totsal-sales-cntry-vsgoal'
            }).then(
                succ => {
                    succ.data.map(filter => {
                        this.values.push(filter.value);
                        this.options.push(filter.option);
                    });
                    if (this.option && this.option !== 'null') {
                        this.filter = this.option.replace(/-/g, ' ');
                    } else {
                        let retail_index = this.values.findIndex(row => row.includes('Retail Country'));
                        if (retail_index >= 0) this.filter = this.values[retail_index];
                        else this.filter = this.$store.getters.filter_check(this.values);
                    }
                    this.load_ingka_by_country();
                },
                this.$helper.error
            );
        },
        load_ingka_by_country: function() {
            this.data_found = false;
            if (!this.$store.getters.market) return;
                this.$http.post(`/finance/operational-gross-margin-forecast-vs-goal/market`, {
                market: this.$helper.market_check(this.market),
                filter: this.filter
            }).then(
                succ => {
                    let d = succ.data;
                    if (!d || !d[0]) return;
                    let keys = Object.keys(d[0].measures);
                    if (d[0].measure_metadata) {
                        this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                    }
                    this.table.headers = [['Market-Area'].concat(keys).map(c => {
                        return {
                            [c]: 1
                        }
                    })];
                    this.$helper.header_replace(this.table.headers, {
                        'FC-Goal': 'FC vs Year goal',
                        'FC-Goal (TEUR)': 'FC vs Year goal (kEuro)'
                    });
                    if ('FC-Goal' in this.table.decimal_settings) {
                        this.table.decimal_settings['FC vs Year goal'] = this.table.decimal_settings['FC-Goal'];
                    }
                    if ('FC-Goal (TEUR)' in this.table.decimal_settings) {
                        this.table.decimal_settings['FC vs Year goal (kEuro)'] = this.table.decimal_settings['FC-Goal (TEUR)'];
                    }
                    this.table.rows = d.map(row => {
                        let temp = [];
                        // If the key/values don't exist, set them to 0
                        keys.map(key => {
                            if (
                                    typeof (row.measures[key]) === 'number' ||
                                    typeof (row.measures[key]) === 'string'
                            ) temp.push(this.$helper.maybe(row.measures[key]) + '%');
                            else temp.push('');                        });
                            let caption = row.level !== 'country' ? row.caption : "Total";
                            return [caption].concat(temp);
                        });

                    this.data_found = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.load_ingka_by_country();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        market: function(old_val, new_val) {
            this.load_ingka_by_country();
        },
        '$store.getters.countries': function(newCountries, oldCountries) {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        }
    }
}
</script>
