var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.$store.state.global.show_observations,
          expression: "$store.state.global.show_observations",
        },
      ],
      staticClass: "commenting-widget",
      attrs: { id: "comment-widget" },
    },
    [
      _c(
        "div",
        {
          key: _vm.render_key,
          staticClass: "titlebox",
          style: {
            "background-color": this.$store.state.global.is_pink_mode
              ? "pink"
              : "",
          },
          attrs: { id: "titlebox" },
        },
        [
          _c(
            "div",
            [
              _vm.$store.getters.check_access(["comments", "*", "w"]) &&
              !this.$store.getters.presentation_mode
                ? _c("SwitchBoxUniversal", {
                    staticClass: "switch-placement absolute",
                    attrs: {
                      boolean_item: this.$store.state.global.show_edit
                        ? true
                        : false,
                      element_id: "comments-switch",
                    },
                    on: { switch_toggle: _vm.switch_toggle },
                  })
                : _vm._e(),
              _c("i", { staticClass: "fas fa-edit edit-placement absolute" }),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.$store.state.global.show_edit,
                    expression: "!$store.state.global.show_edit",
                  },
                ],
                staticClass: "fas fa-slash slash-placement absolute",
              }),
              _c("b", { staticClass: "observations-title" }, [
                _vm._v(
                  " Insights " +
                    _vm._s(
                      _vm.definitionType === "global"
                        ? "(Global)"
                        : "(By Country)"
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "hidden-on-mobile arrows absolute" }, [
                _c("div", {
                  staticClass: "arrow-left mr cursor-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.change_width(1)
                    },
                  },
                }),
                _c("div", {
                  staticClass: "arrow-right cursor-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.change_width(-1)
                    },
                  },
                }),
              ]),
              _c("a", {
                staticClass: "delete is-medium absolute",
                on: {
                  click: function ($event) {
                    return _vm.toggle_comment_widget()
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("Spinner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.$store.state.commenting.loading !== 0,
            expression: "$store.state.commenting.loading !== 0",
          },
        ],
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !this.$store.getters.presentation_mode,
              expression: "!this.$store.getters.presentation_mode",
            },
          ],
          staticClass: "tabs is-boxed is-fullwidth",
        },
        [
          _c("ul", { staticClass: "ms" }, [
            _vm.$store.getters.check_access(["comments", "load", "*", "r"])
              ? _c(
                  "li",
                  {
                    staticClass: "tab",
                    class: { active: _vm.selected === "current" },
                    on: {
                      click: function ($event) {
                        _vm.selected = "current"
                      },
                    },
                  },
                  [_c("b", { staticClass: "tab-text" }, [_vm._v("Current")])]
                )
              : _vm._e(),
            _vm.$store.getters.check_access(["comments", "new", "*", "w"]) &&
            _vm.$store.state.global.show_edit
              ? _c(
                  "li",
                  {
                    staticClass: "tab",
                    class: { active: _vm.selected === "new" },
                    on: {
                      click: function ($event) {
                        _vm.selected = "new"
                      },
                    },
                  },
                  [_c("b", { staticClass: "tab-text" }, [_vm._v("New")])]
                )
              : _vm._e(),
            _vm.$store.getters.check_access(["comments", "edit", "*", "w"]) &&
            _vm.$store.state.global.show_edit
              ? _c(
                  "li",
                  {
                    staticClass: "tab",
                    class: { active: _vm.selected === "draft" },
                    on: {
                      click: function ($event) {
                        _vm.selected = "draft"
                      },
                    },
                  },
                  [_c("b", { staticClass: "tab-text" }, [_vm._v("Draft")])]
                )
              : _vm._e(),
            _vm.$store.getters.check_access(["comments", "edit", "*", "w"]) &&
            _vm.$store.state.global.show_edit
              ? _c(
                  "li",
                  {
                    staticClass: "tab",
                    class: { active: _vm.selected === "scheduled" },
                    on: {
                      click: function ($event) {
                        _vm.selected = "scheduled"
                      },
                    },
                  },
                  [_c("b", { staticClass: "tab-text" }, [_vm._v("Scheduled")])]
                )
              : _vm._e(),
            _vm.$store.getters.check_access(["comments", "load", "*", "r"])
              ? _c(
                  "li",
                  {
                    staticClass: "tab",
                    class: { active: _vm.selected === "archived" },
                    on: {
                      click: function ($event) {
                        _vm.selected = "archived"
                      },
                    },
                  },
                  [_c("b", { staticClass: "tab-text" }, [_vm._v("Archived")])]
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm.$store.state.commenting.notification.show
        ? _c("Notification", {
            attrs: {
              message: _vm.$store.state.commenting.notification.message,
            },
          })
        : _vm._e(),
      _vm.selected === "archived"
        ? _c("Filters", {
            staticClass: "mt ml mr",
            on: { update: _vm.update_filters },
          })
        : _vm._e(),
      !_vm.edit_mode
        ? _c("div", [
            _vm.selected !== "new"
              ? _c("div", [
                  _vm.comments.length > 0
                    ? _c(
                        "div",
                        [
                          _vm.comments.length > 1 &&
                          _vm.$store.getters.check_access([
                            "comments",
                            "*",
                            "w",
                          ]) &&
                          _vm.selected === "current"
                            ? _c("CommentWarning", {
                                attrs: { commentLimit: "one" },
                              })
                            : _vm._e(),
                          _vm._l(_vm.comments, function (comment, i) {
                            return _c(
                              "div",
                              {
                                key: i,
                                staticClass: "comment-container",
                                class:
                                  _vm.comments.length - 1 !== i
                                    ? "comment-border"
                                    : "",
                              },
                              [
                                _c("div", { staticClass: "comment-meta" }, [
                                  _c("div", { staticClass: "ikea-blue-text" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          comment.scheduled_date.split("T")[0]
                                        ) +
                                        " - " +
                                        _vm._s(
                                          comment.comment_lifetime.split("T")[0]
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "columns is-gapless" },
                                    [
                                      _c("div", { staticClass: "column" }, [
                                        !_vm.audio_hash ||
                                        !(comment.comment_id in _vm.audio_hash)
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "button tts-button ikea-blue-text ft-tooltip",
                                                attrs: {
                                                  tooltiptext: "Text to Speech",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.load_audio(
                                                      comment.content,
                                                      comment.comment_id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                !_vm.loading
                                                  ? _c("i", {
                                                      staticClass:
                                                        "fas fa-volume-up",
                                                    })
                                                  : _vm._e(),
                                                _vm.loading
                                                  ? _c("i", {
                                                      staticClass:
                                                        "fas fa-spinner fa-spin",
                                                    })
                                                  : _vm._e(),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "column is-8" },
                                        [
                                          comment.updated_by ||
                                          comment.a_display_name
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "ft-tooltip-bottom",
                                                  attrs: {
                                                    tooltiptext: `
                                    ${
                                      comment.updated_by
                                        ? `Edited by: ${comment.updated_by} ${
                                            comment.a_display_name ? "|" : ""
                                          }`
                                        : ""
                                    }
                                `,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        comment.u_display_name
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _c("div", [
                                                _vm._v(
                                                  _vm._s(comment.u_display_name)
                                                ),
                                              ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "column is" },
                                        [
                                          _c("CommentRatingButtons", {
                                            staticClass: "flex",
                                            attrs: {
                                              id: comment.comment_id,
                                              current: comment.rating,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c("div", [
                                    comment.updated_by
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "ft-tooltip",
                                            attrs: {
                                              tooltiptext:
                                                comment.e_display_name,
                                            },
                                          },
                                          [_vm._v(" |Edited ")]
                                        )
                                      : _vm._e(),
                                    comment.a_display_name
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "ft-tooltip",
                                            attrs: {
                                              tooltiptext:
                                                comment.a_display_name,
                                            },
                                          },
                                          [_vm._v(" |Posted By ")]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                                _vm.audio_hash &&
                                comment.comment_id in _vm.audio_hash
                                  ? _c("div", { staticClass: "mt" }, [
                                      _c(
                                        "audio",
                                        {
                                          staticClass: "active-audio",
                                          attrs: {
                                            controls: "controls",
                                            autobuffer: "autobuffer",
                                          },
                                        },
                                        [
                                          _c("source", {
                                            attrs: {
                                              src:
                                                _vm.api_get_url +
                                                _vm.audio_hash[
                                                  comment.comment_id
                                                ],
                                            },
                                          }),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _c("div", {
                                  staticClass: "comment-content ProseMirror",
                                  domProps: {
                                    innerHTML: _vm._s(comment.content),
                                  },
                                }),
                                _vm.$store.getters.check_access([
                                  "comments",
                                  "edit",
                                  "*",
                                  "w",
                                ]) && _vm.$store.state.global.show_edit
                                  ? _c(
                                      "div",
                                      { staticClass: "button-container" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "button edit-button",
                                            on: {
                                              click: function ($event) {
                                                ;(_vm.comment_to_edit =
                                                  _vm.comments[i]),
                                                  _vm.toggle_edit_mode()
                                              },
                                            },
                                          },
                                          [_vm._v(" Edit ")]
                                        ),
                                        _vm.selected !== "archived"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "button edit-button",
                                                on: {
                                                  click: function ($event) {
                                                    ;(_vm.comment_to_edit =
                                                      _vm.comments[i]),
                                                      _vm.change_status(
                                                        "ARCHIVED"
                                                      )
                                                  },
                                                },
                                              },
                                              [_vm._v(" Archive ")]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }),
                        ],
                        2
                      )
                    : _c("div", { staticClass: "mt" }, [
                        _vm._v(
                          "No " +
                            _vm._s(_vm.selected) +
                            " insights could be found"
                        ),
                      ]),
                ])
              : _c(
                  "div",
                  [
                    _c("New", {
                      attrs: { definitionType: _vm.definitionType },
                      on: {
                        "toggle-edit-mode": _vm.toggle_edit_mode,
                        updated: _vm.edit_updated,
                      },
                    }),
                  ],
                  1
                ),
          ])
        : _c(
            "div",
            { staticClass: "mt" },
            [
              _c("Edit", {
                attrs: {
                  "comment-to-edit": _vm.comment_to_edit,
                  status: _vm.selected,
                },
                on: {
                  "toggle-edit-mode": _vm.toggle_edit_mode,
                  updated: _vm.edit_updated,
                  change_status: _vm.change_status,
                },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }