var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", { attrs: { title: "Availability", location: _vm.location } }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons() },
          }),
          _c(
            "div",
            { staticClass: "label-container" },
            [
              _c("p", [_vm._v("Service Level")]),
              _c("DropDown", {
                key: _vm.filter1,
                attrs: {
                  options: _vm.options1,
                  values: _vm.values1,
                  default: _vm.filter1,
                },
                on: { updateDropDown: _vm.update_filter1 },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "label-container" },
            [
              _c("p", [_vm._v(_vm._s(_vm.filter1))]),
              _c("DropDown", {
                key: _vm.values2[0],
                attrs: {
                  options: _vm.options2,
                  values: _vm.values2,
                  default: _vm.values2[0],
                },
                on: { updateDropDown: _vm.update_filter2 },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size,
          staticClass: "section three-quarters-height box ms",
        },
        [
          _vm.triple.render
            ? _c("Triple", {
                key: _vm.triple.render,
                attrs: {
                  title: "Showing data for Availability Total",
                  showtitle: !_vm.$store.state.global.bot_mode,
                  subtitle: "",
                  xcategories: _vm.triple.xcategories,
                  yaxislabel: "Service Level",
                  ymetric: "%",
                  seriesname1: `${_vm.current_year - 2}`,
                  seriestype1: "line",
                  seriesset1: _vm.triple.seriesset1,
                  color1: _vm.$store.state.colors["light-grey"],
                  seriesname2: `${_vm.current_year - 1}`,
                  seriestype2: "line",
                  seriesset2: _vm.triple.seriesset2,
                  color2: _vm.$store.state.colors["dark-grey"],
                  seriesname3: `${_vm.current_year}`,
                  seriestype3: "line",
                  seriesset3: _vm.triple.seriesset3,
                  color3: _vm.$store.state.colors["focus-yellow"],
                  show: !_vm.$store.state.global.bot_mode,
                  min: "80",
                },
              })
            : _c("div", [
                _vm._v(
                  " No data found for " + _vm._s(_vm.$store.getters.market)
                ),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }