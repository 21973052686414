import AccessDenied from '@/views/access/AccessDenied.vue';
import AccessSummary from '@/views/access/AccessSummary.vue';
export default [
    {
        path: '/access-denied',
        name: 'Access Denied',
        component: AccessDenied
    },
    {
        path: '/access-summary',
        name: 'Access Summary',
        component: AccessSummary
    }
];