<template>
<div>
    <Title
        title="Profit & Loss Total Ingka Retail"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />
        <div class="label-container">
            <p>Filter</p>
            <DropDown
                :key="values[0]"
                :options="options"
                :values="values"
                :default="values[0]"
                @updateDropDown="update_filter"
            />
        </div>        
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <Table
        :headers="table.headers"
        :rows="table.rows"
        :icon-settings="table.icon_settings"
        :sort-settings="table.sort_settings"
        
        class="ms1p5 no-all"
    />
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/Table.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: []
            },
            options: [],
            values: [],
            filter: null,
            location: '/finance/profit-and-loss-total-ingka-retail',
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.get_filters();
    },
    methods: {
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'fin-version-1'
            }).then(
                succ => {
                    succ.data.map(filter => {
                        this.values.push(filter.value);
                        this.options.push(filter.option);
                    });
                    this.filter = this.values[1];
                    
                },
                this.$helper.error
            );
            this.load_ingka_by_country();
        },
        load_ingka_by_country: function() {
            let url = '/finance/profit-and-loss-total-ingka-retail/summary';
            let succ = (succ) => {
                let d = succ.data;
                this.table.headers, this.table.rows = [];

                let keys = Object.keys(d[0].measures[Object.keys(d[0].measures)[0]]);
                this.table.headers = ['MEUR (GFAR20)'].concat(keys);

                Object.keys(d[0].measures).forEach(row => {
                    let temp = [];
                    // If the key/values don't exist, set them to 0
                    temp.push(row);
                    keys.map(key => {
                        temp.push(this.$helper.maybe(d[0].measures[row][key]));
                    });
                    this.table.rows.push(temp);
                });

                this.table.sort_settings = {
                    unsorted: true
                }
            }
            let error = (error) => console.log(error);
            
            this.$http.post(url, {filter: `${this.filter}`}).then(succ, error);
            
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
        },
        update_view: function(market) {
            this.load_ingka_by_country();
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        },
        filter: function() {
            this.load_ingka_by_country();
        } 
    }
}
</script>