<template>
<div id="table">
    <div class="table-container">
        <table class="custom table desktop">
            <!-- Multi-row headers -->
            <thead>
                <tr v-for="(row, index) in parsed_headers" :key="index">
                    <th v-for="(cell, i) in row" :key="i"
                        :colspan="Object.values(cell).shift()"
                        @click="sort.sort(index, i, parsed_headers, parsed_rows);"

                        :class="[/*render_colors(),*/ render_td_classes(cell.classes)]"
                        class="ikea-yellow"
                    >
                        {{Object.keys(cell).shift()}}
                        <!-- <i v-show="sortSettings.enabled !== false" -- Mel 29/01/20 - Commented out as not working properly atm
                            :class="{
                                fas: true,
                                'opacity-0': !(meta.sort.r === index && meta.sort.c == i),
                                'fa-sort-up': meta.sort.asc,
                                'fa-sort-down': !meta.sort.asc,
                                'text-white': true,
                                prp5: true
                            }">
                        </i> -->
                    </th>
                </tr>
            </thead>

            <!-- Render the rows -->
            <tbody :key="meta.selected_row">
                <tr v-for="(row, index) in parsed_rows" :key="index"
                    @click="select_row(row, index); bridge.unfold_bridge(row[0])" :class="render_classes(index, row[0] && row[0].classes ? row[0].classes : [], row[0])"
                    v-show="!bridge.hidden_rows.includes(row[0].value)"
                >
                    <td
                        v-for="(cell, i) in row" :key="i" :class="render_td_classes(cell.classes)"
                    >
                        <i
                            v-if="i === 0 && row[0].classes && row[0].classes.includes('bridge-row')" class="fas float-left mr"
                            :class="{
                                'fa-minus': bridge.clicked_rows.includes(row[0].value),
                                'fa-plus': !bridge.clicked_rows.includes(row[0].value)
                            }"
                        ></i>
                        <div v-if="cell.html" v-html="cell.html"></div>
                        <div v-else>{{cell.value}}</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="mobile text-left">
        <table v-for="(row, i) in parsed_rows" :key="i" class="table is-fullwidth is-striped is-bordered">
            <tbody>
                <tr v-for="(header, j) in [...parsed_headers].pop()" :key="j" :class="render_mobile_classes(i, j)">
                    <td style="width: 50%;" @click="sort.sort(headers.length - 1, j, parsed_headers, parsed_rows)">{{header.alias || Object.keys(header).shift()}}</td>
                    <td>
                        <div v-if="parsed_rows[i][j].html" v-html="parsed_rows[i][j].html"></div>
                        <div v-else class="text-left">{{parsed_rows[i][j].value}}</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</template>

<script>
import bridgeSettings from './modules/bridge-settings';
import commaSettings from './modules/comma-settings';
import dataSettings from './modules/data-settings';
import decimalSettings from './modules/decimal-settings';
import fontSettings from './modules/font-settings';
import iconSettings from './modules/icon-settings';
import sortSettings from './modules/sort-settings';
import spaceSettings from './modules/space-settings';
import wrapSettings from './modules/wrap-settings';

export default {
    props: {
        headers: Array,
        rows: Array,
        metaSettings: {
            type: Object,
            default: function() {
                return {
                    is_selectable: true,
                    isSubtotalHighlight: false
                }
            }
        },
        bridgeSettings: bridgeSettings.props,
        dataSettings: dataSettings.props,
        decimalSettings: decimalSettings.props,
        fontSettings: fontSettings.props,
        iconSettings: iconSettings.props,
        sortSettings: sortSettings.props,
        spaceSettings: spaceSettings.props,
        wrapSettings: wrapSettings.props,
        
        // Overrides
        colorClassOverride: String
    },
    data: function() {
        return {
            bridge: bridgeSettings,
            decimal: decimalSettings,
            icon: iconSettings,
            sort: sortSettings,
            
            meta: {
                bold_row: null,
                selected_row: null,
                sort: {
                    asc: true,
                    c: 0,
                    r: 1,
                }
            },
            parsed_headers: [],
            parsed_rows: [],
        }
    },
    mounted: function() {
        if (!(this.headers && this.rows)) return;
        this.parsed_headers = this.headers.map(row => {
            return row.map(cell => {
                if (typeof cell === 'string') return {[cell]: 1};
                else return cell;
            });
        });

        this.parsed_rows = this.rows.map(row =>
            row.map(cell => {
                if (cell && cell.html) {
                    cell.value = cell.html;
                    return cell;
                }
                
                if (cell && cell.value) {
                    return cell;
                }
                
                return {
                    value: cell, // Leaves space for html entity,
                }
            })
        );

        setTimeout(() => {
            sortSettings.logic(this.sortSettings, this.$data);
            this.render();
            commaSettings.logic(this.$data);
        }, 500);
    },
    methods: {
        render: function() {
            if (this.bridgeSettings) bridgeSettings.logic(this.bridgeSettings, this.$data);
            if (this.dataSettings) dataSettings.logic(this.dataSettings, this.$data);
            if (this.decimalSettings) decimalSettings.logic(this.decimalSettings, this.$data);
            
            if (this.iconSettings) iconSettings.logic(this.iconSettings, this.$data);
            if (this.spaceSettings) spaceSettings.logic(this.spaceSettings, this.$data);

            if (this.wrapSettings) wrapSettings.logic(this.wrapSettings, this.$data);
            this.update_selected_row();
        },
        render_td_classes: function(classes) {
            if (!classes) return;
            // Classes is a list of classes to add to the cell
            return Object.fromEntries(classes.map(c => [c, true]));
        },
        render_classes: function(index, classes, cell) {
            let is_selected = this.meta.selected_row === index;
            /** Temporary setup for fontSettings module */
            let is_bold = false;
            let font_settings = { ...this.fontSettings };
            if (font_settings &&
                font_settings.bold &&
                font_settings.bold.row &&
                font_settings.bold.row.regex &&
                this.parsed_rows[index][0].value
            ) {
                if (typeof(font_settings.bold.row.regex) === 'string') {
                    is_bold = font_settings.bold.row.regex.toLowerCase() === this.parsed_rows[index][0].value.toLowerCase();
                } else {
                    is_bold = this.parsed_rows[index][0].value.toLowerCase().match(font_settings.bold.row.regex);
                }
            }
            
            let _classes = {
                'is-selected': is_selected,
                'text-bold': is_bold,
            };
            
            //P&L subtotal highlight with yellow
            if (this.metaSettings.isSubtotalHighlight && this.bridgeSettings.rows) 
            {
                const key = obj => Object.keys(obj).pop();
                let row_title = cell.value;
                let row_idx = this.bridgeSettings.rows.findIndex(row => {
                    let k = key(row);
                    return row_title === k;
                });
                if ((row_idx >= 0 && this.bridgeSettings.rows[row_idx][row_title].bgcolor))
                { 
                    let _bgclass= {
                    'subtotal-row': 'subtotal-row'
                    }
                    _classes = { ..._classes, ..._bgclass };
                }
            }
            if (classes) {
                classes.forEach(c => _classes[c] = true);
            }

            // _classes[this.$helper.location().kpi] = is_selected;
            return _classes;
        },
        render_colors: function() {
            let kpi = this.colorClassOverride ? this.colorClassOverride : this.$helper.location().kpi;
            return {
                [kpi]: true
            }
        },
        render_mobile_classes: function(index) {
            let country = this.parsed_rows[index][0].value;
            let is_selected = country === this.$store.getters.selected_country_name;
            let classes = {
                'is-selected': is_selected,
                'text-bold': this.meta.bold_row === index,
                [this.$helper.location().kpi]: is_selected
            }
            return classes;
        },
        select_row: function(row, index) {
            this.meta.selected_row = index;
            if (!this.metaSettings.is_selectable) return;
            let country = this.parsed_rows[index][0].value;
            let i = this.$store.getters.countries.options.findIndex(i => i === country);
            this.$store.commit('update_market', this.$store.getters.countries.values[i]);
        },
        update_selected_row: function() {
            if (!this.$store.getters.countries.values) return;
            let i = this.$store.getters.countries.values.findIndex(m => m === this.market);
            let country = this.$store.getters.countries.options[i];
            
            // Update the selected row
            this.meta.selected_row = i === -1 ? this.parsed_rows.length - 1 : this.parsed_rows.findIndex(row => row[0].value === country);
        }
    },
    computed: {
        countries: function() {
            return this.$store.getters.countries;
        },
        market: function() {
            return this.$store.getters.market;
        }
    },
    watch: {
        // If the filter in the menu is updated
        '$store.getters.market': function() {
            this.update_selected_row();
        }
    }
}
</script>

<style lang="scss">
#table {
    margin-left: 1rem;
    width: calc(100% - 2rem);

    .bridge-row {
        background-color: #F0F0F0;
        font-weight: bold;
    }
    
    .border-left {
        border-left: 1px solid #b4b4b4 !important;
    }

    .border-right {
        border-right: 1px solid #b4b4b4 !important;
    }

    .custom.table thead td, .custom.table thead th {
        /* color: #f2eded !important; */
        cursor: pointer;
        text-align: center;
    }

    .scroll-notification {
        background-color: #f5f5f5
    }

    table {
        td:first-child {
            text-align: left !important;
        }

        td {
            padding: 0rem 0.3rem 0rem 0rem !important;
            text-align: right !important;
        }

        thead * {
            z-index: 1;
        }

        &.table {
            position: relative;

            td.left-gap, th.left-gap {
                border-left: 1rem solid #000;
            }

            div.flex > div {
                flex-grow: 1;
            }

            thead tr th {
                border-left: 1px solid #FFF;
                border-right: 1px solid #FFF;
                color: #000;
                max-width: 8rem;
                min-width: 4rem;
                padding: 0.3rem !important;
            }

            thead tr:first-of-type {
                font-weight: bold;
            }

            tbody tr td:first-of-type {
                padding-left: 0.4rem !important;
            }

            tbody tr td {
                padding-right: 0.2rem;
            }

            tbody tr.is-selected td {
                border-left: 1px solid #FFF;
                border-right: 1px solid #FFF;
            }

            th:not([align]) {
                text-align: center;

                &:first-child {
                    text-align: left;
                }
            }

            tr {
                cursor: pointer;

                &.is-selected {
                    background-color: #FDDB2A !important;
                    color: #000;
                }
            }
        }
    }
}

.mobile {
    display: none;
}

@media only screen and (max-width: 768px) {
    .desktop.table {
        display: none;
    }

    .mobile {
        display: block;
    }

    table tbody tr:last-child {
        font-weight: initial;
    }
}
</style>
