<template>
<div id="kitchensink">
    <Title
        title="Example Embedded Power BI Report"
        subtitle="A Prototype embedded Power BI report that reacts to market filter"
    />
    <div>Currently selected market: {{$store.getters.selected_country_name}}</div>
    <div class="section box">
        <iframe
            title="FlightTowerTest"
            :src="`https://app.powerbi.com/reportEmbed?${report_src_filters}reportId=937e5e82-9f73-4051-a5ec-544505e155e7&appId=e663d921-84f7-4b88-a3f0-e128bbf3fc6b&autoAuth=true&ctid=720b637a-655a-40cf-816a-f22f40755c2c`"
            frameborder="0"
            allowFullScreen="true">
        </iframe>
    </div>
</div>
</template>

<script>
import Title from '@/components/ui/Title.vue';
export default {
    components: {
        Title,
    },
    data: function() {
        return{
            location: '/testing/embedded-power-bi'
        }
    },
    mounted: function() {
        this.$store.state.commenting.width = 0;
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
    },
    destroyed: function() {
        this.$store.state.commenting.width = 4;
    },
    computed: {
        report_src_filters: function() {
            if (this.$store.getters.selected_country_name && this.$store.getters.market) {
                return `$filter=Query1/Retail_x0020_Unit eq '${this.$store.getters.selected_country_name}'&`;
            }
            return ''
        }
    },
    methods: {

        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        },
    }
}
</script>

<style scoped>
iframe {
    height: 100%;
    width: 100%;
}
.section{
    float: left;
    margin-left: 1em;
    width: calc(33% - 1em);
    height: 25em;
    border: 1px solid green;
    margin-top: 1em;
}
.long{
    width: calc(2 * (33% - 0.5em));
    height: 25em;
}
.high{
    width: calc(2 * (33% - 0.5em));
    height: 100vh;
}
.box{
    padding: 0;
    width: 50%;
    height: 70vh;
}
</style>