var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _vm._v(" Edit Question "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.question,
          expression: "question",
        },
      ],
      staticClass: "input mt",
      attrs: { type: "textinput", placeholder: "Enter a question" },
      domProps: { value: _vm.question },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.question = $event.target.value
        },
      },
    }),
    _c(
      "a",
      {
        staticClass: "button mt mr is-success",
        on: { click: _vm.update_question },
      },
      [_vm._v("Update Question")]
    ),
    _c("a", { staticClass: "button mt", on: { click: _vm.back } }, [
      _vm._v("Cancel"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }