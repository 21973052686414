import Home from '../views/Home.vue';

export default [
    {
        path: '/country/:market',
        component: Home,
        props: true,
    },
    {
        path: '/',
        component: Home
    },
]