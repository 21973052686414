<template>
<div>
    <Title
        title="Services Gross Margin deviation By Service"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
        <div class="label-container">
            <p>After Sales</p>
            <DropDown
                :key="filter.after_sales.state"
                :options="filter.after_sales.options"
                :values="filter.after_sales.values"
                :default="filter.after_sales.state"
                @updateDropDown="update_filter_after_sales"
            />
        </div>
        <div class="label-container">
            <p>Actual vs Goal in</p>
            <DropDown
                :key="filter.goal_units.state"
                :options="filter.goal_units.options"
                :values="filter.goal_units.values"
                :default="filter.goal_units.state"
                @updateDropDown="update_filter_goal_units"
            />
        </div>
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms mb">
        <Single
            v-if="single.render"

            :title="`GM Deviation in ${filter.goal_units.options[filter.goal_units.values.indexOf(filter.goal_units.state)]} - Actual vs Goal`"
            :xcategories="single.xcategories"
            :xaxisrotation="35"
            :xaxisfontsize="10"
            :yaxislabel="filter.goal_units.options[filter.goal_units.values.indexOf(filter.goal_units.state)]"
            ymetric=""
            seriesname1="GM Deviation"
            seriestype1="bar"
            :seriesset1="single.seriesset1"
            :color1="$store.state.colors['yellow']"
            :gridbottom="150"
            :show="true"
            :showlabel="false"
        />
        <div v-else> No data found for {{$store.getters.selected_country_name}}</div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Single from '@/components/visualisations/general/Single.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Single,
        Title
    },
    props: {
        market: String
    },
    data: function() {
        return {
            filter: {
                after_sales: {
                    state: null,
                    options: [],
                    values: []
                },
                goal_units: {
                    state: null,
                    options: [],
                    values: []
                }
            },
            single: {
                title: null,
                subtitle: null,

                xcategories: null,
                yaxislabel: null,
                ymetric: null,
                
                seriesset1: [],

                render: false
            },

            location: '/finance/service-net/gm-deviation'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'By Service',
                    link: `${this.location}/by-market/${this.$store.getters.market}`
                },
                {
                    name: 'Waterfall',
                    link: `${this.location}/waterfall/${this.$store.getters.market}`
                },
                {
                    name: 'By Country (Graph)',
                    link: `${this.location}/graph`
                },
                {
                    name: 'By Service (Graph)',
                    link: `${this.location}/by-market-graph/${this.$store.getters.market}`
                }
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'service-net-after-sales',
                disable_sort: true
            }).then(
                succ => {
                    succ.data.forEach(filter => {
                        this.filter.after_sales.values.push(filter.value);
                        this.filter.after_sales.options.push(filter.option);
                    });
                    this.filter.after_sales.state = this.$store.getters.filter_check(this.filter.after_sales.values);
                },
                this.$helper.error
            );

            this.$http.post('/filters/load', {
                filter: 'service-net-goal-units',
                disable_sort: true
            }).then(
                succ => {
                    succ.data.forEach(filter => {
                        this.filter.goal_units.values.push(filter.value);
                        this.filter.goal_units.options.push(filter.option);
                    });
                    this.filter.goal_units.state = this.$store.getters.filter_check(this.filter.goal_units.values);
                },
                this.$helper.error
            );
        },
        load_by_market_data: function() {
            this.single.render = false;
            if (this.filter.after_sales.state === null) return;
            this.$http.post('finance/service-net-dashboard/gm-deviation-actual-vs-goal', {
                after_sales_filter: this.filter.after_sales.state,
                market: this.$store.getters.market
            }).then(
                succ => {
                    if (!succ.data) return;
                    let d = succ.data[0].measures;
                    this.single.xcategories = [];
                    this.single.seriesset1 = [];
                    d.forEach(val => {
                        if (!val || !val.service_type) return;
                        this.single.xcategories.push(val.service_type);
                        if (!val[this.filter.goal_units.state]) {
                            this.single.seriesset1.push(null);
                        } else {
                            this.single.seriesset1.push(this.$helper.maybe(val[this.filter.goal_units.state]));
                        }
                    });
                    this.single.render = true;
                },
                this.$helper.error
            );
        },
        update_filter_after_sales: function(filter) {
            this.filter.after_sales.state = filter;
            this.$store.state.global.temp_after_sales_filter = filter;
        },
        update_filter_goal_units: function(filter) {
            this.filter.goal_units.state = filter;
            this.$store.state.global.temp_goal_units_filter = filter;
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: `${this.location}/by-market-graph`
            });
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.load_by_market_data();
        },
        'filter.after_sales.state': function() {
            this.load_by_market_data();
        },
        'filter.goal_units.state': function() {
            this.load_by_market_data();
        }
    }
}
</script>
