<template>
<div class="has-text-left">
    <div class="field">
        <label v-show="label" class="label">{{label}}</label>
        <div class="control">
            <input
                @change="callback(modelled_input)"
                v-model="modelled_input"
                class="input"
                type="textinput"
                :placeholder="placeholder || ''"
            />
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        input: String,
        label: String,
        placeholder: String,
        callback: Function
    },
    data() {
        return {
            modelled_input: this.input
        }
    }
}
</script>

<style scoped>
.field {
    max-width: 768px;
}
</style>