var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("AdminUniversal", {
        attrs: { component: "title", props: _vm.title_props },
      }),
      _c("AdminUniversal", {
        attrs: { component: "textinput", props: _vm.input_props },
      }),
      _c("AdminUniversal", {
        attrs: { component: "button", props: _vm.button_props },
      }),
      _vm.table_props.render
        ? _c("AdminUniversal", {
            attrs: { component: "table", props: _vm.table_props },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }