var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "router-link",
        { staticClass: "button mb", attrs: { to: "/admin/user-management" } },
        [_vm._v("Back")]
      ),
      _c(
        "a",
        {
          staticClass: "button is-fullwidth mb",
          on: { click: _vm.save_template },
        },
        [_vm._v("Save Template")]
      ),
      _c("div", { staticClass: "columns is-gapless" }, [
        _c("div", { staticClass: "column is-2" }, [_vm._v(" Name ")]),
        _c("div", { staticClass: "column" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.template_name,
                expression: "template_name",
              },
            ],
            staticClass: "input mb",
            domProps: { value: _vm.template_name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.template_name = $event.target.value
              },
            },
          }),
        ]),
      ]),
      _c("AccessTemplate", {
        attrs: { data: _vm.access_template },
        on: { update: _vm.update },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }