export default {
    props: Object,
    logic: function(props, data) {
        this.data = data;
        let headers = this.data.parsed_headers;
        let rows = this.data.parsed_rows;
        let column_index;
        let header_index;
        let header_row;
        let found = false;
        
        let thead = document.querySelectorAll('table.custom.table thead tr');
        let tbody = document.querySelectorAll('table.custom.table tbody tr');

        // Find the right column to add the border too
        for (let key in props) {
            // For each row in the headers
            for (let [i, header] of headers.entries()) {
                column_index = 0;

                // Check if the cell contains the prop key
                for (let [index, cell] of header.entries()) {
                    let column_title = Object.keys(cell).shift();
                    let span = Object.values(cell).shift();

                    if (column_title === key) {
                        found = true;
                        header_row = i;
                        header_index = index;
                        break;
                    }

                    column_index += span;
                }

                if (found) break;
            }

            // Now we've found the column to add a padding to, let's add our space
            let header_span = headers[header_row].slice(0, header_index)
                .reduce((acc, v) => acc + Object.values(v).shift(), 0);

            // Now we know the span, we can work out the indexes of each
            // column, for each header row
            let header_indexes = [];
            for (let row of headers) {
                let acc = 0;
                for (let [index, header] of row.entries()) {
                    if (acc === header_span) {
                        header_indexes.push(index);
                    }
                    let span = Object.values(header).shift();
                    acc += span;
                }
            }

            let side = props[key].side ? props[key].side : 'left';
            let class_name = `spacing-${side}`

            thead.forEach((tr, index) => {
                (tr.childNodes[header_indexes[index]]).classList.add('spacing-left');
            });

            tbody.forEach((tr, index) => {
                (tr.childNodes[header_span]).classList.add('spacing-left');
            });
        }
    }
}