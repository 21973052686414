<template>
<div>
    <Title
        title="Core Areas"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
        
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size + 1" class="section three-quarters-height box ms">
        <Graph
            v-if="graph.render"

            :title="`Showing data for: ${$store.getters.selected_country_name}`"
            :show-title="!$store.state.global.bot_mode"
            
            v-bind="graph"
        />
        <div v-else> No data found for {{$store.getters.selected_country_name}}</div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Graph from '@/components/visualisations/general/Graph.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Graph,
        Title,
    },
    props: {
        market: String
    },
    data: function() {
        return {
            graph: {
                colors: [
                    this.$store.state.colors['yellow'],
                    '#FFA500',
                    '#169100',
                    this.$store.state.colors['light-grey']
                ],
                data_sets: [],
                disabled_labels: [true, true, true, true],
                names: ['Living room', 'Bedroom', 'Kitchen & Bathroom', 'Other'],
                data_names: ['Living room', 'Bedroom', 'Kitchen and Bathroom', 'Other'],
                types: ['bar', 'bar', 'bar', 'bar'],
                stack: ['1', '1', '1', '1'],
                x_categories: null,
                y_axis_label: 'Sales in MEUR per week',
                y_metric: '€',

                render: false
            },
            location: '/business/sales-per-range/core-areas'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.get_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: `${this.location}/null`
                },
                {
                    name: 'Trend',
                    link: `${this.location}/trend/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8/null`
                },
                {
                    name: 'Top Family',
                    link: `${this.location}/top-families/null/${this.$store.getters.market}`
                },
                {
                    name: 'Growth Contribution',
                    link: `${this.location}/growth-contribution/${this.$store.getters.market}`
                },
                {
                    name: 'Sales in MEUR per week',
                    link: `${this.location}/sales-in-meur/${this.$store.getters.market}`
                },
                {
                    name: 'Index per week',
                    link: `${this.location}/index/${this.$store.getters.market}`
                }
            ];
        },
        get_data: function() {
            this.graph.render = false;
            this.$http.post('business/sales-per-range/core-areas/growth-contribution', {
                market: this.$store.getters.market,
                filter: 'Sales in MEUR per week'
            }).then(
                succ => {
                    let d = succ.data;
                    if (!d[0]) return;
                    this.graph.x_categories = Object.keys(d[0].data);
                    this.graph.data_sets = [[], [], [], []];
                    this.graph.x_categories.forEach(week => {
                        let val = d[0].data[week];
                        this.graph.data_names.forEach((data_name, i) => {
                            this.graph.data_sets[i].push(this.$helper.maybe(val[data_name]));
                        });
                    });
                    this.graph.render = true;
                }, 
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: `${this.location}/sales-in-meur`,
            });
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.get_data();
        }
    }
}
</script>

<style>

</style>