var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "edit-container" },
    [
      _c(
        "Button",
        {
          staticClass: "full-width mb",
          on: {
            click: function ($event) {
              return _vm.back()
            },
          },
        },
        [_vm._v("Cancel Changes")]
      ),
      _c("BackModal", {
        attrs: { back_clicked: _vm.back_clicked },
        on: {
          "clear-storage": _vm.clear_storage,
          "toggle-edit-mode": function ($event) {
            return _vm.$emit("toggle-edit-mode")
          },
          cancel: function ($event) {
            _vm.back_clicked = false
          },
        },
      }),
      _c("DeleteModal", {
        attrs: { delete_clicked: _vm.delete_clicked },
        on: {
          update: _vm.update,
          cancel: function ($event) {
            _vm.delete_clicked = false
          },
        },
      }),
      _c(
        "div",
        { staticClass: "schedule mb" },
        [
          _c("SwitchBoxUniversal", {
            staticClass: "center-flex mb",
            attrs: {
              label: "Posting on someones behalf?",
              element_id: "edit-switch",
              boolean_item: this.commentToEdit.author_id ? true : false,
            },
            on: { switch_toggle: _vm.switch_toggle },
          }),
          _vm.posting_on_behalf
            ? _c("UserSearch", {
                staticClass: "center-flex mb",
                attrs: {
                  display_name: this.commentToEdit.display_name,
                  user_id: this.commentToEdit.id,
                },
                on: { SelectedUser: _vm.selected_user },
              })
            : _vm._e(),
          _c("br"),
          _vm.insights_titles.options.length > 1
            ? _c("DropDown", {
                key: _vm.insights_titles.options[0],
                staticClass: "center-flex mb",
                attrs: {
                  options: _vm.insights_titles.options,
                  values: _vm.insights_titles.values,
                  default: String(_vm.commentToEdit.insights_title_id),
                },
                on: { updateDropDown: _vm.select_title },
              })
            : _vm._e(),
          _c("WYSIWYGEditor", {
            attrs: { content: _vm.updated_comment.content },
            on: { textUpdate: _vm.update_content },
          }),
          _c("p", [_vm._v("Select the start and end dates for the comment:")]),
          _c("DateTimePicker", {
            attrs: {
              default: [
                _vm.commentToEdit.scheduled_date,
                _vm.commentToEdit.comment_lifetime,
              ],
            },
            on: { updated: _vm.update_scheduled_date },
          }),
        ],
        1
      ),
      _c("InfoButtons", {
        attrs: {
          buttons: _vm.buttons,
          calculated_status: _vm.calculated_status,
        },
        on: { update: _vm.update, delete_button: _vm.delete_button },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }