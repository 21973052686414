<template>
<div>
    <Title
        title="Affordability"
        :location="location"
    />

    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container">
            <p>Rolling period</p>
            <DropDown
                :key="filter.rolling_period.state"
                :options="filter.rolling_period.options"
                :values="filter.rolling_period.values"
                :default="filter.rolling_period.state"
                @updateDropDown="update_filter_rolling_period"
            />
        </div>

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                default=""
                @updateDropDown="update_view"
            />
        </div>
    </div>

    <div :key="$store.getters.page_size" class="section three-quarters-height box ms mb">
        <Top8BarCompetitor
            v-if="top_8.render"

            title="Affordability Top 10 Countries"
            :showtitle="!$store.state.global.bot_mode"
            subtitle=""
            :xcategories="top_8.xcategories"
            :yaxislabel="top_8.yaxislabel"
            ymetric="%"

            :seriesname1="top_8.seriesname1"
            :seriestype1="top_8.seriestype1"
            :seriesset1="top_8.seriesset1"
            :color1="$store.state.colors['yellow']"
            
            seriesname2="Competitor 1"
            :seriestype2="top_8.seriestype2"
            :seriesset2="top_8.seriesset2"
            :color2="$store.state.colors['grey']"

            seriesname3="Competitor 2"
            :seriestype3="top_8.seriestype3"
            :seriesset3="top_8.seriesset3"
            color3="#787878"
            
            :seriesname4="top_8.seriesname4"
            :seriestype4="top_8.seriestype4"
            :seriesset4="top_8.seriesset4"
            :color4="$store.state.colors.YG"

            :ranking="top_8.ranking"
            :competitors="top_8.competitors"
        />
        <div v-else> No top 10 data availible</div>
    </div>
    

</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Top8BarCompetitor from '@/components/visualisations/bar/Top8BarCompetitor.vue';

export default {
    props: {
        market: String
    },
    components: {
        Buttons,
        DropDown,
        Title,
        Top8BarCompetitor
    },
    data: function() {
        return {
            filter: {
                rolling_period: {
                    state: null,
                    options: [],
                    values: []
                }
            },
            top_8: {
                xcategories: null,
                yaxislabel: "Y Axis Label",
                ymetric: "Y Metric",

                seriesname1: "IKEA",
                seriestype1: "bar",
                seriesset1: [],
                color1: "#0F0",
                
                seriesname2: "",
                seriestype2: "bar",
                seriesset2: [],
                color2: "#F00",

                seriesname3: "",
                seriestype3: "bar",
                seriesset3: [],
                color3: "#00F",

                seriesname4: "Year Goal",
                seriestype4: "scatter",
                seriesset4: [],
                color4: "#ADE",

                ranking: [],
                competitors: [],

                render: false
            },
            location: '/three-roads/furniture-that-fits-my-budget'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'ngct-1',
                disable_sort: true
            }).then(
                succ => {
                    succ.data.forEach(filter => {
                        this.filter.rolling_period.values.push(filter.value);
                        this.filter.rolling_period.options.push(filter.option);
                    });
                    this.filter.rolling_period.state = this.$store.getters.filter_check(this.filter.rolling_period.values); 
                },
                this.$helper.error
            );
        },
        get_data: function() {
            this.top_8.render = false;
            this.reset_data_obj();
            if (!this.filter.rolling_period.state) return;
            this.$http.post('/three-roads/furniture-that-fits-my-budget/top-8', {
                rolling_period: this.filter.rolling_period.state
            }).then(
                succ => {
                    let data = succ.data;
                    if (!data) return;

                    this.top_8.xcategories = data.map(d => d.caption);
                    data.forEach(d => {
                        this.top_8.ranking.push(this.$helper.maybe(d.measures.Rank));
                        this.top_8.seriesset1.push(this.$helper.maybe(d.measures.IKEA));
                        this.top_8.seriesset4.push(this.$helper.maybe(d.measures.Goal));

                        let competitors_to_sort = {...d.measures};
                        delete competitors_to_sort.IKEA;
                        delete competitors_to_sort.Goal;
                        delete competitors_to_sort.Rank;
                        
                        let competitors_sorted = Object.keys(competitors_to_sort).sort(function(a,b) {
                            return competitors_to_sort[a] + competitors_to_sort[b];
                        });
                        this.top_8.competitors.push(competitors_sorted[0], competitors_sorted[1]);
                        this.top_8.seriesset2.push(this.$helper.maybe(d.measures[competitors_sorted[0]]));
                        this.top_8.seriesset3.push(this.$helper.maybe(d.measures[competitors_sorted[1]]));
                        this.top_8.yaxislabel = `Affordability Top 10 Countries ${this.filter.rolling_period.state.split('M')[0]} Months %`;
                    });
                    this.top_8.render = true;
                },
                this.$helper.error
            );
        },
        reset_data_obj: function() {
            this.top_8.seriesset1 = [];
            this.top_8.seriesset2 = [];
            this.top_8.seriesset3 = [];
            this.top_8.seriesset4 = [];
            this.top_8.ranking = [];
            this.top_8.competitors = [];
        },
        update_filter_rolling_period: function(rolling_period_filter) {
            this.filter.rolling_period.state = rolling_period_filter;
            this.$store.state.global.temp_rolling_period_filter = rolling_period_filter;
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.get_data();
        },
        'filter.rolling_period.state': function() {
            this.get_data();
        }
    }
}
</script>