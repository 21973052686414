<template>
    <div class="notice columns is-gapless" v-if="show === true">
		<i class="fas fa-exclamation column is-1"></i>
		<div v-if="name" class="column is-10"> 
			This application is built for Edge, Google Chrome and Firefox. Please switch now as some features on this site may not work
		</div>
        <div v-else class="column is-10">
            We cannot detect the browser you are using. This application is optimised for Edge, Google Chrome and Firefox. 
            Please switch now if possible
		</div>
        <div class="column is-1">
            <i class="fas fa-times-circle cursor-pointer" @click="toggle_browser_notice()"></i>
        </div>
        
    </div>
</template>

<script>
import  { detect }  from 'detect-browser';

export default {
    data: function() {
	  return {
			name: null,
			version: null,
			os: null,
			show: false
	 	}
	},
    mounted: function() {
        if (this.$store.state.global.legacy_menu === null) {
            const browser = detect();
            if (browser) {
                this.name = browser.name;
                this.version = browser.version;
                this.os = browser.os;

                let b = document.documentElement;
                b.setAttribute('data-useragent', this.name);

                if (
                    this.name === 'chrome'  ||
                    this.name === 'firefox' ||
                    this.name === 'edge-chromium' ||
                    this.name === 'crios'  ||
                    this.name === 'ios'
                ) {
                    this.show = false;
                    this.$store.state.global.legacy_menu = false;
                } else if (this.name === 'edge') {
                    if (parseInt(browser.version) <= 15) {
                        this.$store.state.global.legacy_menu = true;
                    } 
                    this.show = false;
                } else {
                    this.$store.state.global.legacy_menu = true;
                    this.show = true;
                }
            }
        }
	},
    methods: {
        toggle_browser_notice: function() {
            this.show = false;
        }
    },
    computed: {
        browser_name: function() {
            return this.name[0].toUpperCase() + this.name.slice(1)
        }
    }
}
</script>

<style scoped>

.columns {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

.notice {
    color: #FFF;
    background-color: #df0606;
    padding-bottom: 0.75em;
    padding-top: 0.75em;
}

.fas {
	font-size: 1.5em;
	font-weight: 50%;
}

</style>