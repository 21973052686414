<template>
<div>
    <Title
        title="Goods value connected to a service"
        :location="location"
    />

    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms mb">
        <Top8Bar
            :key="top_8.render"

            title="Services Sales Top 10 Countries"
            :showtitle="!$store.state.global.bot_mode"

            subtitle=""
            :xcategories="top_8.xcategories"
            yaxislabel="Goods Value connected to a service TY/LY"
            ymetric=""

            seriesname1="YTD Index"
            seriesname3="4WK Index"
            
            seriestype1="bar"
            seriestype2="scatter"
            seriestype3="scatter"
            seriestype4="scatter"

            :seriesset1="top_8.seriesset1"
            :color1="$store.state.colors['yellow']"
            
            :seriesset3="top_8.seriesset2"
            color3="#787878"

            :trend="true"
            :ranking="[]"
            :competitors="[]"
        />
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Top8Bar from '@/components/visualisations/bar/Top8Bar.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Title,
        Top8Bar
    },
    data: function() {
        return {
            top_8: {
                render: false,

                xcategories: [],

                seriesset1: [],                
                seriesset2: [],
                seriesset3: [],
                seriesset4: []
            },
            location: '/business/sales-per-range/goods-value-connected'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.load_market_data();
    },
    methods: {
         generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
                {
                    name: 'By HFB',
                    link: `${this.location}/by-hfb/${this.$store.getters.market}`
                },
            ];
        },
        load_market_data: function() {
            this.$http.get('/business/sales-per-range/goods-value-connected/top-8').then(
                succ => {
                    let d = succ.data;
                    d.map(row => {
                        this.top_8.xcategories.push(this.$helper.maybe(row.caption));
                        this.top_8.seriesset1.push(this.$helper.maybe(row.measures['YTD Index']));
                        this.top_8.seriesset2.push(this.$helper.maybe(row.measures['4wk Index']));
                    });

                    this.top_8.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.countries': function(newCountries, oldCountries) {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        }
    }
}
</script>

<style>

</style>