var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "table" } }, [
    _c(
      "table",
      {
        staticClass: "table table-component is-narrow is-striped",
        style: _vm.render_font,
      },
      [
        _c("thead", [
          _c(
            "tr",
            _vm._l(_vm.headers, function (header, index) {
              return _c(
                "th",
                {
                  key: index,
                  staticClass: "ikea-yellow",
                  on: {
                    click: function ($event) {
                      return _vm.sort_row_by(index)
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: index !== _vm.last_col_clicked && index !== 0,
                          expression:
                            "index !== last_col_clicked && index !== 0",
                        },
                      ],
                      staticClass: "icon has-text-white",
                    },
                    [
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.asc,
                            expression: "!asc",
                          },
                        ],
                        staticClass: "fas invisible fa-arrow-down",
                      }),
                    ]
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: index === _vm.last_col_clicked && index !== 0,
                          expression:
                            "index === last_col_clicked && index !== 0",
                        },
                      ],
                      staticClass: "icon has-text-white",
                    },
                    [
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.asc,
                            expression: "asc",
                          },
                        ],
                        staticClass: "fas fa-sort-up",
                      }),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.asc,
                            expression: "!asc",
                          },
                        ],
                        staticClass: "fas fa-sort-down",
                      }),
                    ]
                  ),
                  _vm._v(" " + _vm._s(header) + " "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: index === _vm.last_col_clicked && index === 0,
                          expression:
                            "index === last_col_clicked && index === 0",
                        },
                      ],
                      staticClass: "icon has-text-white",
                    },
                    [
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.asc,
                            expression: "asc",
                          },
                        ],
                        staticClass: "fas fa-sort-up",
                      }),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.asc,
                            expression: "!asc",
                          },
                        ],
                        staticClass: "fas fa-sort-down",
                      }),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.sorted_rows, function (row, index) {
            return _c(
              "tr",
              {
                key: index,
                class: _vm.render_row(row[0]),
                on: {
                  click: function ($event) {
                    return _vm.clickedTable(row[0])
                  },
                },
              },
              _vm._l(row, function (col, i) {
                return _c("td", { key: i }, [
                  typeof col === "string"
                    ? _c("span", { domProps: { innerHTML: _vm._s(col) } })
                    : _c("span", [_vm._v(_vm._s(col))]),
                ])
              }),
              0
            )
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }