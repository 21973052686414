<template>
    <div class="button-container">
      <!-- Button 1 -->
      <Button 
        :class="['total-ingka-button ikea-yellow', { disabled: !isButtonEnabled('Retail Flight Tower') }]"
        :disabled="!isButtonEnabled('Retail Flight Tower')"
        @click="route_to(`/country-overview`)"
      >
        Retail Flight Tower
      </Button>
  
      <!-- Button 2 -->
      <Button 
        :class="['total-ingka-button ikea-yellow', { disabled: !isButtonEnabled('Centres Flight Tower') }]"
        :disabled="!isButtonEnabled('Centres Flight Tower')"
        @click="handleClick('https://app.powerbi.com/Redirect?action=OpenReport&appId=f2efbd11-fac2-4eea-b6a7-faf691aa7b1a&reportObjectId=d22f797c-4d65-4d80-a7a4-3ca2352204d6&ctid=720b637a-655a-40cf-816a-f22f40755c2c&reportPage=ReportSectiondd96fe2368bc690a66a1&pbi_source=appShareLink&portalSessionId=8e1ff0ca-3ec5-4ee9-b610-33079cfc2da1')"
      >
        Centres Flight Tower
      </Button>
  
      <!-- Button 3 -->
      <Button 
      :class="['total-ingka-button ikea-yellow', { disabled: !isButtonEnabled('Investments Flight Tower') }]"
        :disabled="!isButtonEnabled('Investments Flight Tower')"
       
      >
        Investments Flight Tower
      </Button>
    </div>
  </template>
  
  <script>
  import Button from '@/components/ui/Button.vue';
  
  export default {
    components: {
      Button
    },
    data() {
      return {
        disabledButtons: ['Investments Flight Tower'] // disabled button
      };
    },
    methods: {
        route_to: function(location) {
            this.$router.push(location);
        },
        handleClick(url) {
          if (this.isButtonEnabled('Centres Flight Tower')) {
             window.location.href = url;
          }
        },
      isButtonEnabled(buttonLabel) {
        return !this.disabledButtons.includes(buttonLabel);
      }
    }
  }
  </script>
  
  <style scoped>
  .button-container {
    display: flex;
    gap: 10px;
  }
  
  .disabled {
    pointer-events: none;
  }

  .total-ingka-button {
    font-family: 'NotoIKEALatin-Regular';
    border: none;
    border-radius: 0;
    color: #0051BA;
    height: 2rem;
    top:0.8rem;
    color: #000;
}
.total-ingka-button:hover {
    color: #000;
}

.total-ingka-button:focus, .total-ingka-button.is-focused {
    border-color: #3273dc;
    color: #000;
}

  </style>
  