var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.show
      ? _c("div", { staticClass: "notification is-danger mt" }, [
          _c("button", { staticClass: "delete", on: { click: _vm.hide } }),
          _vm._v(" " + _vm._s(_vm.message) + " "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }