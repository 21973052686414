<template>
<div>
    <Button class="full-width mb mt" @click="back()">Cancel</Button>

    <BackModal
        :back_clicked="back_clicked"
        @clear-storage="clear_storage"
        @toggle-edit-mode="$emit('toggle-edit-mode')"
        @cancel="back_clicked = false"
    />

    <div class="schedule mb">
        <SwitchBoxUniversal
            class="mb"
            label="Posting on someones behalf?"
            element_id="new-switch"
            @switch_toggle="switch_toggle"
        /> 
        <UserSearch
            v-if="posting_on_behalf"
            @SelectedUser="selected_user"
        />

        <div class="summary text-left">Summary</div>
        <WYSIWYGEditor
            :key="seperated_content[0]"
            :content="seperated_content[0]"
            @textUpdate="update_summary"
        />
        <div class="columns mtp5">
            <div class="column is-6 pr">
                <div class="strengthen text-left">To Further Strengthen</div>
                <WYSIWYGEditor
                    :key="seperated_content[1]"
                    :content="seperated_content[1]"
                    @textUpdate="update_strengthen"
                />
            </div>
            <div class="column is-6">
                <div class="action text-left">Call for action</div>
                <WYSIWYGEditor
                    :key="seperated_content[2]"
                    :content="seperated_content[2]"
                    @textUpdate="update_action"
                />
            </div>
        </div>
        <p>Select the start and end dates for the comment:</p>
        <DateTimePicker
            @updated="update_scheduled_date"
        />
    </div>

    <InfoButtons
        :buttons="buttons"
        :calculated_status="calculated_status"
        @update="create_new"
    />
</div>
</template>

<script>
import BackModal from '@/components/bespoke/commenting/BackModal.vue';
import Button from '@/components/ui/Button.vue';
import DateTimePicker from '@/components/bespoke/commenting/DateTimePicker.vue';
import SwitchBoxUniversal from '@/components/ui/SwitchBoxUniversal.vue';
import InfoButtons from '@/components/ui/InfoButtons.vue';
import UserSearch from '@/components/admin/UserSearch.vue';
import WYSIWYGEditor from '@/components/bespoke/commenting/QuillWYSIWYGEditor.vue';

export default {
    components: {
        BackModal,
        Button,
        DateTimePicker,
        InfoButtons,
        SwitchBoxUniversal,
        UserSearch,
        WYSIWYGEditor
    },
    data: function() {
        return {
            new_comment: {
                status: null,
                title: null,
                content: localStorage['new_executive_comment'],
                scheduled_date: null,
                comment_lifetime: null,
                author_id: null
            },
            posting_on_behalf: false,
            summary: '<p></p>',
            strengthen: '<p></p>',
            action: '<p></p>',
            back_clicked : false,
            buttons: [
                {
                    type: 'post',
                    clicked: false,
                    button: 'Comment',
                    info: `This button saves the comment to the`,
                    operation: null
                },
                {
                    type: 'draft',
                    clicked: false,
                    button: 'Save to Draft',
                    info: 'This button saves the comment to the Draft tab',
                    operation: 'DRAFT'
                },
            ]
        }
    },
    mounted: function() {
        this.summary = this.seperated_content[0];
        this.strengthen = this.seperated_content[1];
        this.action = this.seperated_content[2];
    },
    methods: {
        back: function() {
            if (localStorage['new_executive_comment']) {
                this.back_clicked = true;
            } else {
                this.$emit('toggle-edit-mode');
            }
        },
        create_new: function(status) {
            if (!this.new_comment.scheduled_date || !this.new_comment.comment_lifetime) {
                alert('Please select and start and end date');
            } else {
                if (this.new_comment.scheduled_date && !status) { //check if first date selected is todays date
                    let today = new Date().getTime();
                    let input_date = this.new_comment.scheduled_date.split('-')

                    let input_timestemp = new Date(input_date[1]+"/"+input_date[2]+"/"+input_date[0]).getTime();

                    if (input_timestemp < today) {
                        this.new_comment.status = 'LIVE'; //if post date is today make it live
                    } else {
                        this.new_comment.status = 'SCHEDULED'; //if post date in the future make it scheduled
                    }
                } else if (status) {
                    this.new_comment.status = status;
                }
                this.new_comment.content = this.combined_content;
                if (Object.keys(this.new_comment).length > 0) {
                    this.$chttp.post(`/comments/new`, {
                        comment: this.new_comment,
                        location: this.$helper.location()
                    }).then(
                        succ => {
                            this.$emit('updated', this.new_comment.status);
                            delete localStorage['new_executive_comment'];
                        },
                        error => {
                            console.log(error);
                        }
                    );
                }
            }
        },
        clear_storage: function() {
            delete localStorage['new_executive_comment'];
        },
        update_summary: function(text) {
            this.summary = text;
            this.update_storage();
        },
        update_strengthen: function(text) {
            this.strengthen = text;
            this.update_storage();
        },
        update_action: function(text) {
            this.action = text;
            this.update_storage();
        },
        update_storage: function() {
            localStorage['new_executive_comment'] = this.combined_content;
        },
        update_scheduled_date: function(date) {
            this.new_comment.scheduled_date = date[0];
            this.new_comment.comment_lifetime = date[1];
        },
        selected_user: function(user) {
            this.new_comment.author_id = user.id;
        },
        switch_toggle: function() {
            this.posting_on_behalf = !this.posting_on_behalf;
            if (!this.posting_on_behalf) this.new_comment.author_id = null;
        }
    },
    computed: {
        combined_content: function() {
            return `${this.summary}{\\SUMMARY\\}${this.strengthen}{\\STRENGTHEN\\}${this.action}`;
        },
        seperated_content: function() {
            if (localStorage['new_executive_comment']) {
                let stored_text = localStorage['new_executive_comment']
                let sub_comments = stored_text.split("{\\SUMMARY\\}")[1];
                
                return [
                    stored_text.split("{\\SUMMARY\\}")[0],
                    sub_comments.split("{\\STRENGTHEN\\}")[0],
                    sub_comments.split("{\\STRENGTHEN\\}")[1]
                ]
            } else {
                return ['<p></p>', '<p></p>', '<p></p>']
            }
        },
        calculated_status: function() {
            let today = new Date().getTime();
            if (this.new_comment.scheduled_date) {
                let input_date = this.new_comment.scheduled_date.split('-');
                let input_timestemp = new Date(input_date[1]+"/"+input_date[2]+"/"+input_date[0]).getTime();
                if (input_timestemp < today) {
                    return 'LIVE' //if post date is today make it live
                } else {
                    return 'SCHEDULED' //if post date in the future make it scheduled
                }
            }
        }
    }
}
</script>

<style scoped>
.buttons.is-centered:not(.has-addons) .button:not(.is-fullwidth) {
    margin: 0;
}
.character-alert {
    background-color: #ff0000;
    color:#f5f5f5;
}
.is-centered {
    justify-content: space-between !important;
}
.section {
    background-color: rgb(245, 245, 245);
}
.schedule {
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fbfbfb;
    padding: 0.5em;
}
</style>