var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", { attrs: { title: "Penetration", location: _vm.location } }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons() },
          }),
          _c(
            "div",
            { staticClass: "label-container" },
            [
              _c("p", [_vm._v("Rolling period")]),
              _c("DropDown", {
                key: _vm.filter.rolling_period.state,
                attrs: {
                  options: _vm.filter.rolling_period.options,
                  values: _vm.filter.rolling_period.values,
                  default: _vm.filter.rolling_period.state,
                },
                on: { updateDropDown: _vm.update_filter_rolling_period },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                key: _vm.$store.getters.market,
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: _vm.$store.getters.market,
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size,
          staticClass: "section half-height box ms",
        },
        [
          _vm.graph.render
            ? _c(
                "Graph",
                _vm._b(
                  {
                    attrs: {
                      title: `Penetration (${_vm.$store.getters.selected_country_name} ${_vm.filter.rolling_period.state})`,
                      "show-title": !_vm.$store.state.global.bot_mode,
                    },
                  },
                  "Graph",
                  _vm.graph,
                  false
                )
              )
            : _c("div", [
                _vm._v(
                  " No data found for " +
                    _vm._s(_vm.$store.getters.selected_country_name)
                ),
              ]),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size + 1,
          staticClass: "section half-height box ms mb",
        },
        [
          _vm.competitor_data.render
            ? _c("HorizontalSingleBar", {
                attrs: {
                  title: `Penetration Competitor Data (${_vm.$store.getters.selected_country_name} ${_vm.filter.rolling_period.state})`,
                  showtitle: !_vm.$store.state.global.bot_mode,
                  ycategories: _vm.competitor_data.names,
                  xaxislabel: "Penetration",
                  xaxismin: Math.min(_vm.competitor_data.values),
                  xinterval: 5,
                  xmetric: "%",
                  seriesname1: "Competitors",
                  seriesset1: _vm.competitor_data.values,
                  color1: _vm.$store.state.colors["yellow"],
                  color2: _vm.$store.state.colors["grey"],
                },
              })
            : _c("div", [
                _vm._v(
                  " No data found for " +
                    _vm._s(_vm.$store.getters.selected_country_name)
                ),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }