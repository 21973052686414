/** Bridge Settings Module */
const key = obj => Object.keys(obj).pop();
const val = obj => Object.values(obj).pop();

export default {
    props: Object,
    data: {
    },
    hidden_columns: [],
    hidden_rows: [],
    shown_columns: [],
    shown_rows: [],
    clicked_columns: [],
    clicked_rows: [],
    parent_columns: [],
    old_span: {},
    logic: function(props, data) {
        // Import Data
        this.data = {...props, ...data};
        let headers = this.data.parsed_headers;
        let rows = this.data.parsed_rows;

        // Check's to see if 'classes' is in the obj already, if not, make a new list
        const add_class = (obj, css_class) => {
            if (!obj) return;
            if ('classes' in obj) {
                if (obj.classes.findIndex(i => i === css_class) > 0) return;
                obj.classes.push(css_class);
            } else {
                obj.classes = [css_class];
            }
        }

        if (props.rows) {
            for (let row of props.rows) {
                // Find the row that has been passed through
                for (let key in row) {
                    rows.forEach(r => {
                        if (r[0].value === key) {
                            add_class(r[0], 'bridge-row');
                            this.clicked_rows.push(r[0].value);
                        }
                    });
                }
            }
        }
        
        // -- Not working as intended right now, stripping out - 09/02/21 - Melvyn Mathews
        // Find the index of the columns passed through and add css classes
        if (!props.columns) return;
        let acc = 0;
        let start = 0;
        headers[0].forEach((header, index) => {
            Object.entries(header).forEach(([title, span]) => {
                if (isNaN(span)) return;
                start = acc;
                let end = start + span;
                // Once we've worked out the indexes of the header columns, check if we should add CSS classes
                Object.keys(props.columns).forEach(col => {
                    if (col === title) {
                        // Add the border classes to the respective start and end cells
                        rows.forEach(row => {
                            if (start > 0) add_class(row[start], 'border-left');
                            add_class(row[end - 1], 'border-right');
                        });
                    }
                });
                acc = end;
            });
        });
    },
    unfold_bridge: function(cell) {
        let row_title = cell.value;
        
        // Toggle the clicked rows
        let clicked_row_idx = this.clicked_rows.indexOf(row_title);
        // Hasn't been clicked yet, add to clicked_rows[]
        if (clicked_row_idx > -1) {
            this.clicked_rows.splice(clicked_row_idx, 1);
        } else {
            this.clicked_rows.push(row_title);
        }

        // Check if this row title is one of the row props
        if (!this.data.rows) return;
        let row_idx = this.data.rows.findIndex(row => {
            let k = key(row);
            return row_title === k;
        });
        if (row_idx === -1) return;

        // Add the hidden rows to the hidden_rows[]
        let hidden_rows = this.data.rows[row_idx][row_title].hide;
        if (!hidden_rows) return;

        hidden_rows.forEach(hidden_row => {
            let idx = this.hidden_rows.indexOf(hidden_row);
            if (idx === -1) {
                // Hide the rows
                this.hidden_rows.push(hidden_row);
            } else {
                // Show the rows
                this.hidden_rows.splice(idx, 1);
            }
        });
    }
}