var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "editor-menu" }, [
        _c(
          "button",
          {
            staticClass: "button",
            class: { "is-active": _vm.editor?.isActive("bold") },
            on: {
              click: function ($event) {
                _vm.editor?.chain().focus().toggleBold().run()
              },
            },
          },
          [_c("i", { staticClass: "fas fa-bold" })]
        ),
        _c(
          "button",
          {
            staticClass: "button",
            class: { "is-active": _vm.editor?.isActive("italic") },
            on: {
              click: function ($event) {
                _vm.editor?.chain().focus().toggleItalic().run()
              },
            },
          },
          [_c("i", { staticClass: "fas fa-italic" })]
        ),
        _c(
          "button",
          {
            staticClass: "button",
            class: { "is-active": _vm.editor?.isActive("underline") },
            on: {
              click: function ($event) {
                _vm.editor?.chain().focus().toggleUnderline().run()
              },
            },
          },
          [_c("i", { staticClass: "fas fa-underline" })]
        ),
        _c(
          "button",
          {
            staticClass: "button",
            class: { "is-active": _vm.editor?.isActive("strike") },
            on: {
              click: function ($event) {
                _vm.editor?.chain().focus().toggleStrike().run()
              },
            },
          },
          [_c("i", { staticClass: "fas fa-strikethrough" })]
        ),
        _c(
          "button",
          {
            staticClass: "button",
            class: { "is-active": _vm.editor?.isActive("bulletList") },
            on: {
              click: function ($event) {
                _vm.editor?.chain().focus().toggleBulletList().run()
              },
            },
          },
          [_c("i", { staticClass: "fas fa-list-ul" })]
        ),
        _c(
          "button",
          {
            staticClass: "button",
            class: { "is-active": _vm.editor?.isActive("orderedList") },
            on: {
              click: function ($event) {
                _vm.editor?.chain().focus().toggleOrderedList().run()
              },
            },
          },
          [_c("i", { staticClass: "fas fa-list-ol" })]
        ),
      ]),
      _c("editor-content", {
        staticClass: "editor-content",
        attrs: { id: "editor", editor: _vm.editor },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }