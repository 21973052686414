<template>
<div class="container">
    <div class="title">Sitemap</div>
    <div class="columns is-multiline">
        <div class="column is-4 text-left" v-for="(option, index) in options" :key="index">
            <p>
                <b>{{option.name}}</b>
            </p>
            <div v-for="(area, i) in option.areas" :key="i">
                <div v-if="'areas' in area" class="mb">
                    {{area.name}}
                    <div v-for="(a, j) in area.areas" class="ml" :key="j">
                        <router-link :to="a.path">{{a.name}}</router-link>
                    </div>

                </div>
                <div v-else>
                    <router-link :to="area.path">{{area.name}}</router-link>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import MenuOptions from '@/assets/scripts/kpi-data-mapping.js';
MenuOptions.sort((a, b) => {
    let count_length = function(item) {
        if ('areas' in item) {
            return item.areas.length + count_length(...item.areas);
        } else {
            return 1;
        }
    }
    if (count_length(a) > count_length(b)) {
        return 1;
    } else {
        return -1;
    }
});
export default {
    data: function() {
        return {
            options: MenuOptions
        }
    },
    mounted: function() {
        this.$store.state.commenting.width = 0;
    },
    destroyed: function() {
        this.$store.state.commenting.width = 4;
    }
}
</script>

<style scoped>
.area {
    display: inline-block;
    width: fit-content;
}
</style>