<template>
<div>
    <Title
        title="Brand Trust"
        :location="location"
    />

    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container">
            <p>Rolling period</p>
            <DropDown
                :key="filter.rolling_period.state"
                :options="filter.rolling_period.options"
                :values="filter.rolling_period.values"
                :default="filter.rolling_period.state"
                @updateDropDown="update_filter_rolling_period"
            />
        </div>

        <!-- <div class="label-container">
            <p>Time Period</p>
            <DropDown
                :key="filter.time_period.state"
                :options="filter.time_period.options"
                :values="filter.time_period.values"
                :default="filter.time_period.state"
                @updateDropDown="update_filter_time_period"
            />
        </div> -->

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>

    <div :key="$store.getters.page_size" class="section half-height box ms">
        <Graph
            v-if="graph.render"

            :title="`Brand Trust (${$store.getters.selected_country_name} ${filter.rolling_period.state})`"
            :show-title="!$store.state.global.bot_mode"
            
            v-bind="graph"
        />
        <div v-else> No data found for {{$store.getters.selected_country_name}}</div>
    </div>

    <div class="section half-height box ms mb">
        <HorizontalSingleBar
            v-if="competitor_data.values"

            :title="`Brand Trust Competitor Data (${filter.rolling_period.state})`"
            :showtitle="!$store.state.global.bot_mode"
            :ycategories="competitor_data.names"
            xaxislabel="Penetration"
            :xaxismin="Math.min(competitor_data.values)"
            :xinterval="5"
            xmetric="%"
            seriesname1="Competitors"
            :seriesset1="competitor_data.values"
            :color1="$store.state.colors['yellow']"
            :color2="$store.state.colors['grey']"
        />
        <div v-else> No data found for {{$store.getters.selected_country_name}}</div>
    </div>
    

</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Graph from '@/components/visualisations/general/Graph.vue';
import HorizontalSingleBar from '@/components/visualisations/bar/HorizontalSingleBar.vue';
import Title from '@/components/ui/Title.vue';
import DesireTop8ViewVue from './DesireTop8View.vue';

export default {
    props: {
        market: String,
        dropdown_options: Array,
        dropdown_values: Array
    },
    components: {
        Buttons,
        DropDown,
        Graph,
        HorizontalSingleBar,
        Title
    },
    data: function() {
        return {
            filter: {
                rolling_period: {
                    state: null,
                    options: [],
                    values: []
                },
                time_period: {
                    state: this.$store.state.global.temp_time_period_filter || '-1',
                    options: ['Current Year', 'Current and Last Year', 'Current and Last 2 Years', 'Current and Last 3 Years'],
                    values: ['-1', '-2', '-3', '-4']
                }
            },
            graph: {
                colors: [],
                data_sets: [],
                names: [],
                data_names: [],
                types: [],
                x_categories: null,
                y_axis_label: 'Brand Trust in %',
                year_goal_index: null,
                y_metric: '%',
                styling: [],
                legend_selected: {},
                y_zoom: true,
                x_zoom: true,

                render: false
            },
            competitor_data: {
                names: null,
                values: null,
                render: false
            },
            location: '/brand/trust'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'ngct-1',
                disable_sort: true
            }).then(
                succ => {
                    succ.data.forEach(filter => {
                        this.filter.rolling_period.values.push(filter.value);
                        this.filter.rolling_period.options.push(filter.option);
                    });
                    this.filter.rolling_period.state = this.$store.getters.filter_check(this.filter.rolling_period.values);
                    this.get_data();
                },
                this.$helper.error
            );
        },
        get_data: function() {
            this.graph.render = false;
            if (!this.filter.rolling_period.state) return;
            this.$http.post('/brand/trust/market', {
                market: this.$store.getters.market,
                rolling_period: this.filter.rolling_period.state
            }).then(
                succ => {
                    let d = succ.data;
                    if (!d[0] || !d[0].measures) return;
                    this.graph.render = false;
                    let all_company_data = [];
                    let latest_fiscal_year = Object.keys(d[0].measures.data['IKEA'])[Object.keys(d[0].measures.data['IKEA']).length + Number(this.filter.time_period.state)];
                    this.graph.x_start_value = String(Object.keys(Object.values(d[0].measures.data['IKEA'][latest_fiscal_year])[0])[0]);
                    Object.keys(d[0].measures.data).forEach(company_name => {
                        let years_to_show = Object.keys(d[0].measures.data[company_name]).slice(-4);
                        let value_object = {};
                        years_to_show.forEach(year => {
                            if (!d[0].measures.data[company_name][year]) return;
                            Object.values(d[0].measures.data[company_name][year]).forEach(tertial => {
                                value_object = Object.assign(value_object, tertial);
                            });
                        });
                        all_company_data.push([company_name, value_object]);
                    });

                    let index_of_IKEA = null;
                    all_company_data.forEach((company_data, index) => {company_data[0] === 'IKEA' ? index_of_IKEA = index : null});
                    let IKEA_data = {...all_company_data[index_of_IKEA][1]};
                    let latest_week_for_IKEA = Object.keys(IKEA_data)[Object.keys(IKEA_data).length - 1]
                    
                    all_company_data.splice(index_of_IKEA, 1);
                    all_company_data = all_company_data.sort((a, b) => {
                       return (a[1][latest_week_for_IKEA] || -Infinity) < (b[1][latest_week_for_IKEA] || -Infinity) ? 1 : -1
                    });
                    all_company_data.unshift(['IKEA', IKEA_data]);

                    this.graph.data_sets = new Array(all_company_data.length + 1).fill('');
                    this.graph.types = new Array(all_company_data.length + 1).fill('line');
                    this.graph.names = new Array(all_company_data.length).fill('');
                    this.graph.data_names = new Array(all_company_data.length).fill('');
                    this.graph.styling = new Array(all_company_data.length + 1).fill({
                        smooth: true,
                        showSymbol: false,
                    });
                    this.graph.colors = new Array(all_company_data.length + 1).fill(null);
                    this.graph.colors[this.graph.colors.length -1] = this.$store.state.colors.YG;
                    let indexes_to_show_on_graph = [0, 1, 2, 3, all_company_data.length];
                    this.graph.x_categories = Object.keys(all_company_data[0][1]);
                    all_company_data.forEach((company_data, index) => {
                        this.graph.data_sets[index] = Object.values(company_data[1]);
                        this.graph.names[index] = company_data[0];
                        this.graph.data_names[index] = company_data[0];
                        this.graph.legend_selected[company_data[0]] = indexes_to_show_on_graph.includes(index);
                        if (index === 0) {
                            this.graph.colors[0] = this.$store.state.colors['yellow'];
                        } else {
                            this.graph.colors[index] = this.$store.state.colors.NGCT[index];
                        }
                    });
                    this.graph.data_names.push('Year Goal');
                    this.graph.names.push('Year Goal');
                    this.graph.year_goal_index = all_company_data.length;
                    this.graph.data_sets[all_company_data.length] = new Array(Object.values(IKEA_data).length).fill(d[0].measures.goal_value);
                    this.graph.render = true;
                },
                this.$helper.error
            );
            this.competitor_data.render = false;
            if (!this.filter.rolling_period.state) return;
            this.competitor_data.names, this.competitor_data.values = null;
            this.competitor_data = {
                names: null,
                values: null,
                render: false
            },
            this.$http.post('/brand/trust/market/competitors', {
                market: this.$store.getters.market,
                rolling_period: this.filter.rolling_period.state
            }).then(
                succ => {
                    if (!succ.data || !succ.data[0] || !succ.data[0].measures) return;
                    let competitor_data = succ.data[0];
                    this.competitor_data.names = Object.keys(competitor_data.measures);
                    this.competitor_data.values = Object.values(competitor_data.measures);
                    this.competitor_data.names.reverse();
                    this.competitor_data.values.reverse();
                    this.competitor_data.render = true;
                },
                this.$helper.error
            );
        },
        update_filter_rolling_period: function(rolling_period_filter) {
            this.filter.rolling_period.state = rolling_period_filter;
            this.$store.state.global.temp_rolling_period_filter = rolling_period_filter;
            this.get_data();
        },
        update_filter_time_period: function(time_period_filter) {
            this.filter.time_period.state = time_period_filter;
            this.$store.state.global.temp_time_period_filter = time_period_filter;
            this.get_data();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.get_data();
        },
        'this.filter.rolling_period.state': function() {
            this.get_data();
        }
    }
}
</script>

<style scoped>

</style>
