import OnlineBuyability from '@/views/customer/OnlineBuyability.vue';
import OnlineBuyabilityMarket from '@/views/customer/OnlineBuyabilityMarket.vue';
import OnlineBuyabilityTop8 from '@/views/customer/OnlineBuyabilityTop8.vue';

import PromisedDeliveryPrecision from '@/views/customer/PromisedDeliveryPrecision.vue';
import PromisedDeliveryPrecisionMarket from '@/views/customer/PromisedDeliveryPrecisionMarket.vue';
import PromisedDeliveryPrecisionTop8 from '@/views/customer/PromisedDeliveryPrecisionTop8.vue';

import NonValueContacts from '@/views/customer/NonValueContacts.vue';
import NonValueContactsMarket from '@/views/customer/NonValueContactsMarket.vue';
import NonValueContactsTop8 from '@/views/customer/NonValueContactsTop8.vue';

import FirstContactResolution from '@/views/customer/FirstContactResolution.vue';
import FirstContactResolutionMarket from '@/views/customer/FirstContactResolutionMarket.vue';
import FirstContactResolutionTop8 from '@/views/customer/FirstContactResolutionTop8.vue';

import HappyCustomer from '@/views/customer/HappyCustomer.vue';
import HappyCustomerSummary from '@/views/customer/HappyCustomerSummary.vue';
import HappyCustomerMarket from '@/views/customer/HappyCustomerMarket.vue';
import HappyCustomerTop8 from '@/views/customer/HappyCustomerTop8.vue';

export default [
    {
        path: '/customer/customer-satisfaction',
        props: {template: './customer/CustomerSatisfaction/by_country.js'}
    },
    // {
    //     path: '/customer/online-buyability',
    //     component: OnlineBuyability,
    // },
    // {
    //     path: '/customer/online-buyability/top-8',
    //     component: OnlineBuyabilityTop8,
    // },
    // {
    //     path: '/customer/online-buyability/:market',
    //     component: OnlineBuyabilityMarket,
    //     props: true
    // },
    // {
    //     path: '/customer/promised-delivery-precision',
    //     component: PromisedDeliveryPrecision,
    // },
    // {
    //     path: '/customer/promised-delivery-precision/top-8',
    //     component: PromisedDeliveryPrecisionTop8,
    // },
    // {
    //     path: '/customer/promised-delivery-precision/:market',
    //     component: PromisedDeliveryPrecisionMarket,
    //     props: true
    // },
    {
        path: '/customer/non-value-contacts',
        component: NonValueContacts,
    },
    {
        path: '/customer/non-value-contacts/top-8',
        component: NonValueContactsTop8,
    },
    {
        path: '/customer/non-value-contacts/:market',
        component: NonValueContactsMarket,
        props: true
    },
    {
        path: '/customer/first-contact-resolution',
        component: FirstContactResolution
    },
    {
        path: '/customer/first-contact-resolution/top-8',
        component: FirstContactResolutionTop8
    },
    {
        path: '/customer/first-contact-resolution/:market',
        component: FirstContactResolutionMarket,
        props: true
    },
    // {
    //     path: '/customer/happy-customer',
    //     component: HappyCustomer,
    // },
    // {
    //     path: '/customer/happy-customer/top-8',
    //     component: HappyCustomerTop8,
    // },
    // {
    //     path: '/customer/happy-customer/summary/:market',
    //     component: HappyCustomerSummary,
    //     props: true
    // },
    // {
    //     path: '/customer/happy-customer/:market',
    //     component: HappyCustomerMarket,
    //     props: true
    // },
];