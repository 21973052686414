export default [
    {
        "name": "3 Roads",
        "path": "/three-roads",
        "areas": [
            {
                "name": "Affordability",
                "path": "/three-roads/furniture-that-fits-my-budget",
                "alt": [
                    {
                        "name": "Furniture that fits my budget"
                    }
                ]
            },
            {
                "name": "Low price in reality",
                "path": "/three-roads/low-price-in-reality"
            },
            {
                "name": "Penetration",
                "path": "/three-roads/penetration"
            },
            {
                "name": "Positive Impact",
                "path": "/three-roads/positive-impact"
            }
        ]
    },
    {
        "name": "Brand",
        "path": "/brand",
        "areas": [
            {
                "name": "Brand Desire",
                "path": "/brand/desire"
            },
            {
                "name": "Brand Trust",
                "path": "/brand/trust"
            }
        ]
    },
    {
        "name": "Business",
        "path": "/business",
        "areas": [
            {
                "name": "Total Sales",
                "path": "/business/total-sales",
                "areas": [
                    {
                        "name": "Sales Trend Total",
                        "path": "/business/total-sales/sales-trend-total/summary/null",
                        "location": "/business/total-sales/sales-trend-total",
                        "alt": [
                            {
                                "name": "Total Sales Dev",
                                "filter" : "Total"
                            },
                            {
                                "name": "Comp Cash & Carry",
                                "filter" : "Store comp"
                            },
                            {
                                "name": "Online",
                                "filter": "Online"
                            }
                        ]
                    },
                    {
                        "name": "Flow matrix",
                        "path": "/business/total-sales/flow-matrix/null"
                    },
                    {
                        "name": "Sales contribution per country",
                        "path": "/business/total-sales/sales-contribution-per-country"
                    },
                    {
                        "name": "Channel contribution per country",
                        "path": "/business/total-sales/channel-contribution-per-country"
                    },
                    {
                        "name": "Sales per country vs Goal",
                        "path": "/business/total-sales/sales-per-country-vs-goal"
                    },
                    {
                        "name": "Sales and quantity per country",
                        "path": "/business/total-sales/sales-and-quantity-per-country",
                        "document": "SalesandQuantity"
                    },
                    {
                        "name": "Prioritised cities",
                        "path": "/business/total-sales/sales-prioritized-thirty-cities"
                    }
                ]
            },
            {
                "name": "Market Share",
                "path": "/business/market-share",
                "areas": [
                    {
                        "name": "Market Share",
                        "path": "/business/market-share",
                        "alt": [
                            {
                                "name": "Market Share"
                            }
                        ]
                    },
                    {
                        "name": "Prioritised cities",
                        "path": "/business/market-share/market-share-prioritized-thirty-cities"
                    }
                ]
            },
            {
                "name": "Sales per Range",
                "path": "/business/sales-per-range",
                "areas": [
                    {
                        "name": "HFB",
                        "path": "/business/sales-per-range/hfb/by-hfb/null",
                        "location": "/business/sales-per-range/hfb/by-hfb"
                    },
                    {
                        "name": "Core Areas",
                        "path": "/business/sales-per-range/core-areas/null",
                        "location": "/business/sales-per-range/core-areas"
                    },
                    {
                        "name": "HF Accessories",
                        "path": "/business/sales-per-range/hf-accessories"
                    },
                    {
                        "name": "Goods value connected to a service",
                        "path": "/business/sales-per-range/goods-value-connected"
                    },
                    {
                        "name": "Food Sales",
                        "path": "/business/sales-per-range/food-sales"
                    },
                    {
                        "name": "Top Families Growth",
                        "path": "/business/sales-per-range/top-families-growth/top-10-turnover"
                    },
                ]
            },
            {
                "name": "Fulfilment Unit Operations",
                "path": "/business/fuo",
                "areas": [
                    {
                        "name": "DC Lead Time Precision",
                        "path": "/business/fuo/dc-lead-time-precision",
                        "document": "DcLeadTimePrecision"
                    },
                    {
                        "name": "DC Transport Equipment Utilization",
                        "path": "/business/fuo/dc-transport-equipment-utilization",
                        "document": "DcTransportEquipmentUtilization"
                    },
                    {
                        "name": "Stock Accuracy",
                        "path": "/business/stock-accuracy",
                    },
                    {
                        "name": "CDC Stock Accuracy",
                        "path": "/business/fuo/cdc-stock-accuracy",
                        "document": "CDCStockAccuracy"
                    },
                    {
                        "name": "Cost Of Internal Damages",
                        "path": "/business/fuo/cost-of-internal-damages",
                        "document": "CostOfInternalDamages"
                    },
                    {
                        "name": "Cost Of Internal Damages (Store)",
                        "path": "/business/fuo/cost-of-internal-damages-store",
                        "document": "CostOfInternalDamages_Store"
                    },
                    {
                        "name": "Outbound Quality CDC",
                        "path": "/business/fuo/customer-order-quality",
                        "document": "CustomerOrderQuality"
                    },
                    {
                        "name": "Gross Inventory Differences",
                        "path": "/business/fuo/gross-inventory-differences",
                        "document": "GrossInventoryDifferences"
                    },
                    {
                        "name": "Gross Inventory Differences (Store)",
                        "path": "/business/fuo/gross-inventory-differences-store",
                        "document": "GrossInventoryDifferences_Store"
                    },
                    {
                        "name": "FUO Volumes",
                        "path": "/business/fuo/volumes/fulfilled",
                        "document": "FulfilmentUnitVolumes"
                    },
                    // {
                    //     "name": "Missing Slots For Inbound",
                    //     "path": "/business/missing-slots-for-inbound",
                    //     "document": "MissingSlotsForInbound"
                    // },
                    {
                        "name": "Store Fulfilment Productivity",
                        "path": "/business/fuo/store-fulfilment-productivity",
                        "document": "StoreFulfilmentProductivity"
                    },
                    {
                        "name": "Central Units Productivity",
                        "path": "/business/central-units-productivity",
                        "document": "CentralUnitsProductivity"
                    },
                ]
            },
            // {
            //     "name": "Store Fulfilment",
            //     "path": "/business/store-fulfilment",
            //     "areas": [
            //         {
            //             "name": "Flows 3-12",
            //             "path": "/business/store-fulfilment",
            //         },
            //         {
            //             "name": "Flows 7-8",
            //             "path": "/business/store-fulfilment-7-8"
            //         }
            //     ]
            // },
            {
                "name": "Add to Cart Rate",
                "path": "/business/add-to-cart-rate",
                "document": "Add_to_cart_rate"
            },
            {
                "name": "Lead-times",
                "path": "/business/lead-times",
            },
            {
                "name": "Offered Lead Time",
                "path": "/business/offered-lead-time",
                "document": "OfferedLeadTime"
            },
            {
                "name": "Sales Tree",
                "path": "/business/sales-tree",
                "alt": [
                    {
                        "name": "Store Visits (comp. units)",
                        "filter": "Comp Stores"
                    },
                    {
                        "name": "Online Visits",
                        "filter": "Online"
                    },
                    {
                        "name": "Online Conversion Rate",
                        "filter": "tree"
                    }
                ]
            },
            {
                "name": "Financial Services",
                "path": "/business/financial-services"
            },
            {
                "name": "Product Availability",
                "path": "/business/product-availability",
                "alt": [
                    {
                        "name": "Product Availability OSA"
                    },
                    { 
                        "name": "Product Availability CDC"
                    }
                ]
            },
            {
                "name": "Stock Levels",
                "path": "/business/stock-levels/null"
            },
            {
                "name": "IKEA Family share of sales",
                "path": "/business/ikea-family-share-of-sales",
                "document": "IKEAFamilyShareOfSales"
            },
            // {
            //     "name": "IKEA Business share of sales",
            //     "path": "/business/ikea-business-share-of-sales"
            // },
            {
                "name": "IKEA Business share of sales",
                "path": "/business/ikea-business-share-of-sales-b2b",
                "document": "B2B"               
            }
            // {
            //     "name": "Cumulative Checkout Abandonment Rate",
            //     "path": "/business/cumulative-checkout-abandonment-rate",
            //     "document": "cumulative_checkout_abandonment_rate"
            // },
        ]
    },
    {
        "name": "Customer",
        "path": "/customer",
        "areas": [
            {
                "name": "Customer Satisfaction (Task Rabbit)",
                "path": "/customer/customer-satisfaction",
                "document": "CustomerSatisfaction"
            },
            {
                "name": "Customer Promised Service Precision",
                "path": "/customer/promised-delivery-precision"
            },
            {
                "name": "Happy Customer",
                "path": "/customer/happy-customer",
                "alt": [
                    {
                        "name": "Happy Customer Topline"
                    },
                    {
                        "name": "Happy Customer Digital"
                    },
                    {
                        "name": "Happy Customer Physical"
                    },
                    {
                        "name": "Happy Customer Services"
                    },
                    {
                        "name": "Happy Customer Support"
                    },
                ]
            },
            {
                "name": "Non Value Adding Contacts (per 1000 customers)",
                "path": "/customer/non-value-contacts"
            },
            {
                "name": "Perceived Customer Buyability",
                "path": "/customer/online-buyability"
            },
            {
                "name": "Perceived First Contact Resolution",
                "path": "/customer/first-contact-resolution"
            },
        ]
    },
    {
        "name": "Finance",
        "path": "/finance",
        "areas": [
            {
                "name": "After Sales Delivery Share",
                "path": "/finance/after-sales-delivery-share"
            },
            {
                "name": "Cost per Country vs Goal",
                "path": "/finance/cost-per-country",
                "location": "/finance/cost-per-country"
            },
            {
                "name": "Cost per Country vs Goal",
                "path": "/finance/cost-per-country-vs-goal"
            },
            {
                "name": "Cost Per Flow",
                "path": "/finance/cost-per-flow"
            },
            {
                "name": "Gross Margin per Country",
                "path": "/finance/gross-margin-per-country/by-country/null",
                "location": "/finance/gross-margin-per-country/by-country"
            },
            {
                "name": "Operational Gross Margin",
                "path": "/finance/operational-gross-margin"
            },
            {
                "name": "Operational Gross Margin Forecast vs Goal",
                "path": "/finance/operational-gross-margin-forecast-vs-goal"
            },
            {
                "name": "Product Related Services (pending FY20 data)",
                "path": "/finance/product-related-services"
            },
            // { //Hidden 25/10/2023 @ Mukesh's request
            //     "name": "Product Quality Costs",
            //     "path": "/finance/product-quality",
            // },
            {
                "name": "Profit & Loss",
                "path": "/finance/profit-and-loss",
                "alt": [
                    {
                        "name": "Operational GM"
                    },
                    {
                        "name": "Operational Result Before Global Allocations, Excl. Portfolios and AO"
                    },
                    {
                        "name": "Operational Cost Before Global Allocations, Excl. Portfolios and AO"
                    }
                ]
            },
            {
                "name": "Result Contribution per Country",
                "path": "/finance/result-contribution-per-country"
            },
            {
                "name": "Result per Country vs Goal",
                "path": "/finance/result-per-country-vs-goal"
            },
            { 
                "name": "Service Net",
                "path": "/finance/service-net",
                "areas": [
                    {
                        "name": "Services Gross Margin",
                        "path": "/finance/service-net/services-gross-margin",
                        "location": "/finance/service-net/services-gross-margin"
                    },
                    {
                        "name": "GM Deviation",
                        "path": "/finance/service-net/gm-deviation",
                        "location": "/finance/service-net/gm-deviation"
                    },
                    {
                        "name": "Cost Per Service",
                        "path": "/finance/cost-per-service",
                        "location": "/finance/cost-per-service"
                    }
                ]
            }
        ]
    },
    {
        "name": "People",
        "path": "/people",
        "areas": [
            {
                "name": "Retention",
                "path": "/people/balanced-co-worker-turnover",
                "alt": [
                    {
                        "name": "Co Worker Turnover"
                    }
                ]
            },
            {
                "name": "Engagement Index",
                "path": "/people/engagement-index"
            },
            {
                "name": "Gender Manager Share",
                "path": "/people/gender-manager-share",
                "alt": [
                    {
                        "name": "Gender Share"
                    }
                ]
            },
            {
                "name": "IKEA Values",
                "path": "/people/ikea-values"
            },
            {
                "name": "Leadership Index",
                "path": "/people/leadership-index"
            },
            {
                "name": "eNPS",
                "path": "/people/e-nps",
                "document": "eNPS",
                "alt": [
                    {
                        "name": "Happy Coworker"
                    },
                    {
                        "name": "Happy Co-worker"
                    }
                ]
            },
            {
                "name": "Inspired and Enabled People",
                "path": "/people/inspired-and-enabled-people",
                "document": "InspiredandEnabledPeople"
            },
            {
                "name": "Change Makers",
                "path": "/people/change-makers",
                "document": "ChangeMakers"
            },
            {
                "name": "Social Impact",
                "path": "/people/social-impact",
                "document": "SocialImpact"
            },
        ]
    },
    {
        "name": "Sustainability",
        "path": "/sustainability",
        "areas": [
            {
                "name": "People + Planet Positive Sales",
                "path": "/sustainability/sustainable-sales"
            },
            {
                "name": "Operational Climate Footprint",
                "path": "/sustainability/climate-footprint"
            },
            {
                "name": "Climate Footprint - Food Ingredients",
                "path": "/sustainability/climate-footprint-food-ingredients",
                "document": "FoodIngredients"
            },
            {
                "name": "Zero Emissions Deliveries",
                "path": "/sustainability/zero-emissions",
                "document": "ZeroEmissions"
            },
            {
                "name": "Zero Waste",
                "path": "/sustainability/zero-waste",
                "document": "ZeroWaste"
            },
            {
                "name": "Ikea Sustainability Mentions",
                "path": "/sustainability/mentions"
            }
        ]
    },
    {
        "name": "Process",
        "path": "/process",
        "areas": [
            {
                "name": "Source to Pay indirect (S2Pi)",
                "path": "/process/procurement",
                "alt": [
                    {
                        "name": "Purchase Order Compliance"
                    }
                ]
            },
            {
                "name": "Sales Planning Precision",
                "path": "/process/sales-planning-precision"
            },
            {
                "name": "NOSLO",
                "path": "/process/noslo",
                "document": "NOSLO"
            },
        ]
    }
]
