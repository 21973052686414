<template>
    <div :id="title" style="width: 100%; height:100%;"></div>
</template>

<script>
import * as echarts from 'echarts/dist/echarts.min.js';
export default {
    props: {
        title: String, //title of the graph
        showtitle: Boolean,
        subtitle: String, // subtitle of the graph
        xcategories: Array, //data that will be displayed on the x axis
        yaxislabel1: String, //name of the data on the y axis e.g 'After sales delivery share' or 'services sale by month'
        ymetric1: String, //'%', '£', 'kg' etc.
        yaxislabel2: String,
        ymetric2: String,
        yaxismin1: Number, //set the minimum value of the the y axis
        yaxismax1: Number, //set the maximum value of the the y axis
        yaxismin2: Number,
        yaxismax2: Number,
        seriesname1: String, //name of the data such as IKEA 
        seriestype1: String, //bar, line, scatter etc.
        seriesset1: Array, //actual data array to fill the graph
        color1: String, //color of the bar lie or scatter for the paticular data set
        seriesname2: String,
        seriestype2: String,
        seriesset2: Array,
        color2: String,
        seriesname3: String,
        seriestype3: String,
        seriesset3: Array,
        color3: String,
        seriesname4: String,
        seriestype4: String,
        seriesset4: Array,
        color4: String,
        trend: Boolean,
        trendInvert: Boolean,
        show: Boolean, // true to show the exta features such as 'save graph as image', 'refresh', 'change to bar graph', etc
    },
    mounted: function() {
        let myChart = echarts.init(document.getElementById(this.title));
        let div_width = document.getElementById(`${this.title}`).clientWidth;
        let width_factor = 0.75;
        let offset_factor = 4.55;
        let country_competitor = [];
        let above = [];
        let below = [];
        const push_above = (data) => {
            above.push(data);
            below.push(null);
        }
        const push_below = (data) => {
            below.push(data);
            above.push(null);
        }
        if (this.trend) {
            for (let i = 0; i < this.seriesset3.length; i++) {
                if (this.seriesset3[i] >= parseFloat(this.seriesset2[i])) {
                    push_above(this.seriesset3[i]);
                } else {
                    push_below(this.seriesset3[i]);
                }
            }
        }
        this.xcategories.forEach(category => {
            country_competitor.push(category.replace(/ /g, "\n").replace(/Total\nRetail/g, "Total Retail"));
        });
        let NumberSet = this.seriesset2.concat(this.seriesset3, this.seriesset4);
        let limit1 = 0;
        let limit2 = 0;
        let highest1 = 0;
        let lowest1 = 100;
        NumberSet.forEach(val => {
            if (val > highest1) highest1= parseFloat(val);
            if (val < lowest1) lowest1 = parseFloat(val);
        });

        highest1 = Math.ceil(highest1.toFixed(0)/200)*200;
        lowest1 = Math.floor(lowest1.toFixed(0)/200)*200;
        if (highest1 > (lowest1 * -1)) limit1 = highest1;
        else limit1 = lowest1 * -1;

        NumberSet = this.seriesset1;
        
        let highest2 = 0;
        let lowest2 = 100;
        NumberSet.forEach(val => {
            if (val > highest2) highest2 = val;
            if (val < lowest2) lowest2 = val;
        })
        highest2 = Math.ceil(highest2.toFixed(0)/200)*200;
        lowest2 = Math.floor(lowest2.toFixed(0)/200)*200;
        if (highest2 > (lowest2 * -1)) limit2 = highest2;
        else limit2 = lowest2 * -1;
        let option = {
            title:{
                text: this.title,
                subtext: this.subtitle,
                show: this.showtitle,
                subtextStyle: {
                    color: 'black',
                },
                top: '-2em',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            toolbox: {
                top: '50em',
                right: '70em',
                show: this.show,
                feature: {
                    restore: {},
                    saveAsImage: {}
                }
            },
            grid: {
               top: '80em',
               left: '70vh',
               bottom: '40em'
            },
            legend: {
                top: '35em',
                left: '0em',
                itemGap: 15,
                data:[
                    this.seriesname2, 
                    this.trend ? null : this.seriesname3,
                    this.trend ? `${this.seriesname3} above ${this.seriesname2}` : null,
                    this.trend ? `${this.seriesname3} below ${this.seriesname2}`: null,
                    this.seriesname4,
                    this.seriesname1
                ]
            },
            textStyle: {
                fontFamily: 'NotoIKEALatin-Regular',
            },
            xAxis: [
                {
                    type: 'category',
                    data: country_competitor,
                    axisPointer: {
                        type: 'shadow'
                    },
                    axisLabel: {
                        interval: '0',
                    },
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: this.yaxislabel1,
                    min: (limit1 * -1),
                    max: limit1,
                    nameLocation: 'middle',
                    nameGap: 50,
                    axisLabel: {
                        formatter: `{value} ${this.ymetric1}`
                    }
                },
                {
                    type: 'value',
                    name: this.yaxislabel2,
                    min: (limit2 * -1),
                    max: limit2,
                    nameLocation: 'middle',
                    nameGap: 50,
                    nameRotate: '270',
                    axisLabel: {
                        formatter: `{value} ${this.ymetric2}`
                    }
                }
            ],
            series: [
                {
                    name: this.seriesname2,
                    type: this.seriestype2,
                    data: this.seriesset2,
                    color: this.color2,
                    barGap: 0,
                    label: {
                        show: this.seriestype2 === 'bar' ? true : false,
                        align: 'center',
                        verticalAlign: 'middle',
                        position: 'insideTop',
                        distance: 20,
                        color: 'black',
                        fontSize: 11
                    },
                },
                {
                    name: this.seriesname1,
                    type: this.seriestype1,
                    data: this.seriesset1,
                    yAxisIndex: 1,
                    color: this.color1,
                    label: {
                        show: this.seriestype1 === 'bar' ? true : false,
                        align: 'center',
                        verticalAlign: 'middle',
                        position: 'insideBottom',
                        distance: 10,
                        color: 'black',
                        fontSize: 9
                    },
                },
                {
                    name: this.seriesname3,
                    type: this.seriestype3,
                    data: this.trend ? null : this.seriesset3,
                    color: this.color3,
                    symbol: this.seriestype3 === 'scatter' ? 'rect' : 'circle',
                    symbolSize:  this.seriestype3 === 'scatter' ? [(div_width / (this.xcategories.length * width_factor * 3.5)), 6] : 10,
                    // symbolOffset: this.seriestype3 === 'scatter' ? [-(div_width / (12 * offset_factor)), 0] : 0,
                    label: {
                        show: this.seriestype3 === 'bar' ? true : false,
                        align: 'center',
                        verticalAlign: 'middle',
                        position: 'insideTop',
                        distance: 20,
                        color: 'black',
                        fontSize: 11
                    },
                },
                {
                    name: this.seriesname4,
                    type: this.seriestype4,
                    symbol: this.seriestype4 === 'scatter' ? 'rect' : 'none',
                    symbolSize:  this.seriestype4 === 'scatter' ? [(div_width / this.xcategories.length * width_factor), 2] : 10,
                    data: this.seriesset4,
                    color: this.color4,
                    hoverAnimation: false,
                    label: {
                        show: this.seriestype4 === 'bar' ? true : false,
                        align: 'center',
                        verticalAlign: 'middle',
                        position: 'insideTop',
                        distance: 20,
                        color: 'black',
                        fontSize: 11
                    },
                },
                this.scatter(true, true, above, div_width, width_factor, `${this.trendInvert? 'red' : 'green'}`),
                this.scatter(true, false, below, div_width, width_factor, `${this.trendInvert? 'green' : 'red'}`)
            ]
        };
        myChart.setOption(option);
    },
    methods: {
        scatter: function(comparison, above, dataset, div_width, width_factor, color) {
            if (comparison === this.trend) {
                return  {
                    name: this.trend ? above ? `${this.seriesname3} above ${this.seriesname2}` : `${this.seriesname3} below ${this.seriesname2}`: this.seriesname3,
                    type: this.seriestype3,
                    data: dataset,
                    color: color,
                    symbol: this.seriestype3 === 'scatter' ? 'rect' : 'circle',
                    symbolSize:  this.seriestype3 === 'scatter' ? [(div_width / (this.xcategories.length * width_factor * 3)), 6] : 10,
                    barGap: '0',
                    label: {
                        show: this.seriestype3 === 'bar' ? true : false,
                        align: 'center',
                        verticalAlign: 'middle',
                        position: 'insideTop',
                        distance: 20,
                        color: 'black',
                    },
                }
            } else { 
                return null
            }
            
        }
    }
}
</script>