<template>
    <div>
        <div class="columns is-multiline pt">
            <div class="column is-2 country-box cursor-pointer"  @click="update_filter(country)" v-for="(country, i) in displayed_countries" :key="i">
                <div class="dropdown-flag" v-bind:class="render_flag(country)"></div>
                <div class="country-title">{{country.caption}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        path: String
    },
    methods: {
        render_flag: function(country) {
            if (country) {
                let key = `flag-icon flag-icon-${country.market.toLowerCase()}`;
                let obj = {};
                obj[key] = true;
                return obj;
            }
            return {};
        },
        update_filter: function(country) {
            this.$store.commit('update_view', {
                market: country.market,
                location: this.path
            });
        },
    },
    mounted: function() {
        this.$store.state.commenting.width = 0;
    },
    destroyed: function() {
        this.$store.state.commenting.width = 4;
    },
    computed: {
        market: function() {
            return this.$store.getters.market;
        },
        displayed_countries: function() {
            let temp = this.$store.state.countries.all;
            if (temp) {
                temp = temp.sort((a, b) => {
                    if (a.caption < b.caption) {
                        return -1;
                    } else if (a.caption > b.caption) {
                        return 1;
                    }
                    return 0;
                });
            }
            return temp;
        }
    }
}
</script>

<style scoped>
.country-box {
    transition: 0s;
}

.country-box:hover {
    background-color: #f5f5f5;
    border-radius: 5px;
    box-shadow: 0 0 3px #d4d4d4;
}

.country-title{
    height: 3em;
}

.flag-icon {
    width: 100%;
    height: 4em;
    line-height: auto;
}

.title-section {
    background-color: rgb(75, 126, 59);
    color: white;
    padding: 2vh;
}
</style>