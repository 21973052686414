var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: {
          title: "Goods value connected to a service",
          location: _vm.location,
        },
      }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons() },
          }),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                key: _vm.$store.getters.market,
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: _vm.$store.getters.market,
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size,
          staticClass: "section three-quarters-height box ms mb",
        },
        [
          _c("Top8Bar", {
            key: _vm.top_8.render,
            attrs: {
              title: "Services Sales Top 10 Countries",
              showtitle: !_vm.$store.state.global.bot_mode,
              subtitle: "",
              xcategories: _vm.top_8.xcategories,
              yaxislabel: "Goods Value connected to a service TY/LY",
              ymetric: "",
              seriesname1: "YTD Index",
              seriesname3: "4WK Index",
              seriestype1: "bar",
              seriestype2: "scatter",
              seriestype3: "scatter",
              seriestype4: "scatter",
              seriesset1: _vm.top_8.seriesset1,
              color1: _vm.$store.state.colors["yellow"],
              seriesset3: _vm.top_8.seriesset2,
              color3: "#787878",
              trend: true,
              ranking: [],
              competitors: [],
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }