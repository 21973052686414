var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("AdminUniversal", {
        attrs: { component: "title", props: _vm.title_props },
      }),
      _c("AdminUniversal", {
        attrs: { component: "button", props: _vm.button_props },
      }),
      _c("hr"),
      _vm.table_props.render
        ? _c("AdminUniversal", {
            attrs: { component: "table2", props: _vm.table_props },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "modal", class: { "is-active": _vm.modal.visible } },
        [
          _c("div", {
            staticClass: "modal-background",
            on: {
              click: function ($event) {
                _vm.modal.visible = false
              },
            },
          }),
          _c("div", { staticClass: "modal-card" }, [
            _c("header", { staticClass: "modal-card-head" }, [
              _c("p", { staticClass: "modal-card-title" }, [
                _vm._v(_vm._s(_vm.modal.title)),
              ]),
              _c("button", {
                staticClass: "delete",
                attrs: { "aria-label": "close" },
                on: {
                  click: function ($event) {
                    _vm.modal.visible = false
                  },
                },
              }),
            ]),
            _c("section", { staticClass: "modal-card-body" }, [
              _c("div", { staticClass: "field" }, [
                _c("label", { staticClass: "label" }, [_vm._v("KPI Name")]),
                _c("div", { staticClass: "control" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.modal.data.report,
                        expression: "modal.data.report",
                      },
                    ],
                    staticClass: "input",
                    attrs: { type: "text" },
                    domProps: { value: _vm.modal.data.report },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.modal.data, "report", $event.target.value)
                      },
                    },
                  }),
                  _c("p", { staticClass: "help" }, [
                    _vm._v("Required - will appear as written"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "field" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Type")]),
                _c("div", { staticClass: "control" }, [
                  _c("div", { staticClass: "select" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.modal.data.load_type,
                            expression: "modal.data.load_type",
                          },
                        ],
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.modal.data,
                              "load_type",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { disabled: "", value: "" } }, [
                          _vm._v("Please select one"),
                        ]),
                        _c("option", [_vm._v("manual")]),
                        _c("option", [_vm._v("automated")]),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "field" }, [
                _c("label", { staticClass: "label" }, [_vm._v("KPI code")]),
                _c("div", { staticClass: "control" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.modal.data.kpi_code,
                        expression: "modal.data.kpi_code",
                      },
                    ],
                    staticClass: "input",
                    attrs: {
                      type: "text",
                      placeholder: "e.g. goodsvalue-services",
                    },
                    domProps: { value: _vm.modal.data.kpi_code },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.modal.data,
                          "kpi_code",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _c("p", { staticClass: "help" }, [
                    _vm._v(
                      "Required - string used to fetch version, speak to a developer if unsure."
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "field" }, [
                _c("label", { staticClass: "label" }, [
                  _vm._v("Processing Frequency as Cron"),
                ]),
                _c("div", { staticClass: "control" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.modal.data.processing_frequency,
                        expression: "modal.data.processing_frequency",
                      },
                    ],
                    staticClass: "input",
                    attrs: { type: "text", placeholder: "e.g. 0 6 * * 1" },
                    domProps: { value: _vm.modal.data.processing_frequency },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.modal.data,
                          "processing_frequency",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._m(0),
                ]),
              ]),
              _c("div", { staticClass: "field" }, [
                _c("label", { staticClass: "label" }, [
                  _vm._v("Processing Duration (seconds)"),
                ]),
                _c("div", { staticClass: "control" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.modal.data.processing_duration,
                        expression: "modal.data.processing_duration",
                      },
                    ],
                    staticClass: "input",
                    attrs: { type: "number", placeholder: "" },
                    domProps: { value: _vm.modal.data.processing_duration },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.modal.data,
                          "processing_duration",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _c("p", { staticClass: "help" }, [
                    _vm._v("Required - interval of time for processing"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "field" }, [
                _c("label", { staticClass: "label" }, [
                  _vm._v("Lag (seconds)"),
                ]),
                _c("div", { staticClass: "control" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.modal.data.lag,
                        expression: "modal.data.lag",
                      },
                    ],
                    staticClass: "input",
                    attrs: { type: "number", placeholder: "" },
                    domProps: { value: _vm.modal.data.lag },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.modal.data, "lag", $event.target.value)
                      },
                    },
                  }),
                  _c("p", { staticClass: "help" }, [
                    _vm._v(
                      "Required - time interval data lags behind real time (typically one cycle)"
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "field" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Comment")]),
                _c("div", { staticClass: "control" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.modal.data.load_comment,
                        expression: "modal.data.load_comment",
                      },
                    ],
                    staticClass: "textarea",
                    attrs: { rows: "2", placeholder: "Add Notes here" },
                    domProps: { value: _vm.modal.data.load_comment },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.modal.data,
                          "load_comment",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _c("footer", { staticClass: "modal-card-foot" }, [
              _c(
                "button",
                {
                  staticClass: "button is-success",
                  on: { click: _vm.write_kpi },
                },
                [_vm._v("Save changes")]
              ),
              _c(
                "button",
                {
                  staticClass: "button",
                  on: {
                    click: function ($event) {
                      _vm.modal.visible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "help" }, [
      _vm._v("Required - "),
      _c("a", { attrs: { href: "https://crontab.guru/" } }, [
        _vm._v("Cron expression"),
      ]),
      _vm._v(" that defines load frequency"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }