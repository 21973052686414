<template>
<div>
    <Title
        title="Cost Per Flow"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
        <div class="label-container">
            <p>Flow</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms">
        <DeviationWith3Lines
            v-if="double.render"

            :title="`Showing data for: ${$store.getters.selected_country_name === 'Total Retail Countries' ? 'Total' : $store.getters.selected_country_name}`"
            :showtitle="!$store.state.global.bot_mode"
            subtitle=""

            :xcategories="double.xcategories"
            
            yaxislabel1=""
            ymetric1="€"
            yaxislabel2=""
            ymetric2=""
            :yaxismax1="double.yaxismax1"
            :yaxismax2="double.yaxismax2"

            :seriesname1="`Flow Index vs Goal (${filter === 'store-distribution-high-flow' ? 'm3' : 'OL'})`"
            seriestype1="line"
            :seriesset1="double.seriesset2"
            :color1="$store.state.colors['dark-grey']"
            
            seriestype2="line"
            
            seriestype3="line"

            :seriesname4="`SD HF - €/${filter === 'store-distribution-high-flow' ? 'm3' : 'OL'}`"
            seriestype4="bar"
            :seriesset4="double.seriesset1"
            :color4="$store.state.colors['yellow']"

            :show="!$store.state.global.bot_mode"
        />
        <div v-else> No data found for {{$store.getters.selected_country_name}}</div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DeviationWith3Lines from '@/components/visualisations/general/DeviationWith3Lines.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DeviationWith3Lines,
        DropDown,
        Title
    },
    props: {
        market: String
    },
    data: function() {
        return {
            options: ['Store Distribution High Flow', 'Store Distribution Low Flow', 'Customer Distribution Parcel' , 'Customer Distribution Truck'],
            values: ['store-distribution-high-flow', 'store-distribution-low-flow', 'customer-distribution-parcel', 'customer-distribution-truck'],
            filter: 'store-distribution-high-flow',
            double: {
                xcategories: null,
                
                seriesset1: [],
                seriesset2: [],
                yaxismax1: null,
                yaxismax2: null,

                render: false
            },
            location: '/finance/cost-per-flow',
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.load_market_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
            ];
        },
        load_market_data: function() {
            this.$http.post(`finance/cost-per-order-line/${this.filter}/market`, {
                market: this.$store.getters.market
            }).then(
                succ => {
                    let d = succ.data;
                    this.double.seriesset1 = [];
                    this.double.seriesset2 = [];
                    if (!d || !d[0] || !d[0].measures) return;
                    this.double.xcategories = Object.keys(d[0].measures);
                    let ymax1 = -Infinity;
                    let ymax2 = -Infinity;
                    let val1;
                    let val2;
                    Object.values(d[0].measures).map(val => {
                        if (this.filter === 'store-distribution-high-flow') {
                            val1 = Number(val['EUR/M3']);
                            val2 = Number(val['Flow Index vs Goal (M3)']);
                        } else {
                            val1 = Number(val['EUR/OL']);
                            val2 = Number(val['Flow Index vs Goal (OL)']);
                        }
                        this.double.seriesset1.push(this.$helper.maybe(val1, null));
                        ymax1 = val1 > ymax1 ? val1 : ymax1;
                        this.double.seriesset2.push(this.$helper.maybe(val2, null));
                        ymax2 = val2 > ymax2 ? val2 : ymax2;
                    });
                    this.double.yaxismax1 = Math.ceil(ymax1 / 5)*5;
                    this.double.yaxismax2 = Math.ceil(ymax2 / 50)*50;
                    this.double.render = true;
                },
                this.$helper.error
            );
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.double.render = false;
            this.load_market_data();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.countries': function(newCountries, oldCountries) {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.double.render = false;
            this.update_view(newMarket);
            this.load_market_data();
        }
    }
}
</script>