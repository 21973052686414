import DesireView from '@/views/brand/DesireView.vue';
import DesireTop8View from '@/views/brand/DesireTop8View.vue';
import DesireMarketView from '@/views/brand/DesireMarketView.vue';
import TrustView from '@/views/brand/TrustView.vue';
import TrustMarketView from '@/views/brand/TrustMarketView.vue';
import TrustTop8View from '@/views/brand/TrustTop8View.vue';

export default [
    // {
    //     path: '/brand/desire',
    //     component: DesireView,
    // },
    // {
    //     path: '/brand/desire/top-8',
    //     component: DesireTop8View,
    // },
    // {
    //     path: '/brand/desire/:market',
    //     component: DesireMarketView,
    //     props: true
    // },
    // {
    //     path: '/brand/trust',
    //     component: TrustView,
    // },
    // {
    //     path: '/brand/trust/top-8',
    //     component: TrustTop8View,
    // },
    // {
    //     path: '/brand/trust/:market',
    //     component: TrustMarketView,
    //     props: true
    // },
];