var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Button",
        {
          staticClass: "full-width mt mb",
          on: {
            click: function ($event) {
              return _vm.back()
            },
          },
        },
        [_vm._v("Cancel Changes")]
      ),
      _c("BackModal", {
        attrs: { back_clicked: _vm.back_clicked },
        on: {
          "clear-storage": _vm.clear_storage,
          "toggle-edit-mode": function ($event) {
            return _vm.$emit("toggle-edit-mode")
          },
          cancel: function ($event) {
            _vm.back_clicked = false
          },
        },
      }),
      _c("DeleteModal", {
        attrs: { delete_clicked: _vm.delete_clicked },
        on: {
          update: _vm.update,
          cancel: function ($event) {
            _vm.delete_clicked = false
          },
        },
      }),
      _c(
        "div",
        { staticClass: "schedule mb" },
        [
          _c("SwitchBoxUniversal", {
            staticClass: "mb",
            attrs: {
              label: "Posting on someones behalf?",
              element_id: "edit-switch",
              boolean_item: this.commentToEdit.author_id ? true : false,
            },
            on: { switch_toggle: _vm.switch_toggle },
          }),
          _vm.posting_on_behalf
            ? _c("UserSearch", {
                attrs: {
                  display_name: this.commentToEdit.display_name,
                  user_id: this.commentToEdit.id,
                },
                on: { SelectedUser: _vm.selected_user },
              })
            : _vm._e(),
          _c("div", { staticClass: "summary text-left" }, [_vm._v("Summary")]),
          _c("WYSIWYGEditor", {
            key: _vm.posting_on_behalf,
            attrs: { content: _vm.summary },
            on: { textUpdate: _vm.update_summary },
          }),
          _c("div", { staticClass: "columns mtp5" }, [
            _c(
              "div",
              { staticClass: "column is-6 pr" },
              [
                _c("div", { staticClass: "strengthen text-left" }, [
                  _vm._v("To Further Strengthen"),
                ]),
                _c("WYSIWYGEditor", {
                  key: _vm.posting_on_behalf,
                  attrs: { content: _vm.strengthen },
                  on: { textUpdate: _vm.update_strengthen },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "column is-6" },
              [
                _c("div", { staticClass: "action text-left" }, [
                  _vm._v("Call for action"),
                ]),
                _c("WYSIWYGEditor", {
                  key: _vm.posting_on_behalf,
                  attrs: { content: _vm.action },
                  on: { textUpdate: _vm.update_action },
                }),
              ],
              1
            ),
          ]),
          _c("p", [_vm._v("Select the start and end dates for the comment:")]),
          _c("DateTimePicker", {
            attrs: {
              default: [
                _vm.commentToEdit.scheduled_date,
                _vm.commentToEdit.comment_lifetime,
              ],
            },
            on: { updated: _vm.update_scheduled_date },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "schedule info-buttons" },
        [
          _c("InfoButtons", {
            attrs: {
              buttons: _vm.buttons,
              calculated_status: _vm.calculated_status,
            },
            on: { update: _vm.update, delete_button: _vm.delete_button },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }