var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("AdminUniversal", {
        attrs: { component: "title", props: _vm.title_props },
      }),
      _c("KPIChooser", {
        staticClass: "kpi-chooser",
        on: {
          chosen: _vm.kpi_chosen,
          reset: function ($event) {
            _vm.chosen_kpi = null
          },
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.chosen_kpi,
              expression: "chosen_kpi",
            },
          ],
          staticClass: "editor-wrapper",
        },
        [
          _c("div", { staticClass: "radio-group" }, [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.definitionType,
                    expression: "definitionType",
                  },
                ],
                attrs: { type: "radio", value: "global" },
                domProps: { checked: _vm._q(_vm.definitionType, "global") },
                on: {
                  change: [
                    function ($event) {
                      _vm.definitionType = "global"
                    },
                    function ($event) {
                      return _vm.handleDefinitionTypeChange(
                        _vm.chosen_kpi,
                        "global"
                      )
                    },
                  ],
                },
              }),
              _vm._v(" Global "),
            ]),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.definitionType,
                    expression: "definitionType",
                  },
                ],
                attrs: { type: "radio", value: "bycountry" },
                domProps: { checked: _vm._q(_vm.definitionType, "bycountry") },
                on: {
                  change: [
                    function ($event) {
                      _vm.definitionType = "bycountry"
                    },
                    function ($event) {
                      return _vm.handleDefinitionTypeChange(
                        _vm.chosen_kpi,
                        "bycountry"
                      )
                    },
                  ],
                },
              }),
              _vm._v(" By Country "),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }