<template>
<div>
    <AdminUniversal
        component="title"
        :props="title_props"
    />
    <AdminUniversal
        component="dropdown"
        :props="dropdown_props"
    />
    <AdminUniversal
        v-if="table_props.render"
        component="table"
        :props="table_props"
    />
    <Modal
        v-if="selected_request"
        :closeable="true"
        title="Approve an Access Request"
        
        :class="{'is-active': is_active}"
        @close="is_active = false"
    >
        <template v-slot:content>
            <div>Request #{{selected_request.req_id}} for {{selected_request.first_name}} {{selected_request.last_name}}</div>
            <div>E: {{selected_request.email}} T: {{selected_request.phone_number}} O: {{selected_request.office_location}}</div>
            <br>
            <div>Current Role: {{selected_request.job_title}}</div>
            <div>Requested Role: {{selected_request.req_job_title}}</div>
            <br>
            <div>
                <span>User comments</span>
                <br>
                <div v-if="selected_request.extra_access.description">
                    {{selected_request.extra_access.description}}
                </div>
                <div v-else>
                    No comments was left by the user.
                </div>
                
            </div>
        </template>
        <template v-slot:buttons>
            <a class="buttons">
                <a class="button is-success" @click="approve_request(selected_request.req_id)">Approve</a>
                <a class="button is-danger" @click="reject_request(selected_request.req_id)">Reject</a>
            </a>
        </template>
    </Modal>
</div>
</template>
<script>
import AdminUniversal from '@/components/admin/index.vue';
import Modal from '@/components/ui/Modal.vue';

export default {
    components: {
        AdminUniversal,
        Modal
    },
    data: function() {
        return {
            title_props: {
                title: 'Manage Access Requests',
            },
            dropdown_props: {
                selection: 'ALL',
                options: ['ALL', 'APPROVED', 'REJECTED', 'PENDING'],
                label: 'Filter by access',
                callback: this.update_dropdown_selection
            },
            table_props: {
                buttons: [{
                    label: 'View Request',
                    classes: '',
                    method: this.view_request
                }],
                headers: ['Case ID', 'User', 'Actual Job Title', 'Requested Job Title', 'Status', 'Actions'],
                data: null,
                render: false
            },
            access_requests: null,
            is_active: false,
            selected_request: null,
            filter: 'ALL',
        }
    },
    mounted: function() {
        this.load_access_requests();
    },
    methods: {
        approve_request: function(access_request_id) {
            this.$http.post('/admin/user-management/access-requests/approve', {
                access_request_id: access_request_id
            }).then(
                succ => {
                    this.is_active = false;
                    this.load_access_requests();
                },
                this.$helper.error
            );
        },
        reject_request: function(access_request_id) {
            this.$http.post('/admin/user-management/access-requests/reject', {
                access_request_id: access_request_id
            }).then(
                succ => {
                    console.log(succ.data);
                },
                this.$helper.error
            );
        },
        load_access_requests: function() {
            this.table_props.render = false;
            this.$http.post('/admin/user-management/access-requests/all', {
                status: this.filter
            }).then(
                succ => {
                    this.access_requests = succ.data;
                    this.table_props.data = succ.data.map(datum => {
                        const row = {
                            req_id: datum.req_id,
                            name: `${datum.first_name} ${datum.last_name}`,
                            job_title: datum.job_title,
                            req_job_title: datum.req_job_title,
                            status: datum.status
                        };
                        return row;
                    })
                    this.table_props.render = true;
                },
                this.$helper.error
            );
        },
        update_dropdown_selection: function(selection) {
            this.filter = selection;
            this.load_access_requests();
        },
        view_request: function(index) {
            this.selected_request = this.access_requests[index];
            this.is_active = true;
        }
    }
}
</script>

<style>

</style>