<template>
    <div :id="title" style="width: 100%; height:100%;"></div>
</template>

<script>
import * as echarts from 'echarts/dist/echarts.min.js';
export default {
    props: {
        title: String,
        subtitle: String,
        names: Array,
        values: Array
    },
    mounted: function() {
        // based ready dom, initialize echarts instance 
        let myChart = echarts.init(document.getElementById(this.title));
        
        // Zip up names and values
        let series_data = this.names.map((name, index) => {
            return {
                name: name,
                value: this.values[index]
            }
        });

        // Specify configurations and data graphs 
        let option = {
            title : {
                text: this.title,
                subtext: this.subtitle,
                subtextStyle: {
                    color: 'black',
                },
                x:'center'
            },
            tooltip : {
                trigger: 'item',
                formatter: "{a} <br/>{b} : {c} ({d}%)"
            },
            legend: {
                orient: 'vertical',
                left: 'left',
                top: '25em',
                data: this.names
            },
            series : [
                {
                    name: this.title,
                    type: 'pie',
                    radius : '70%',

                    center: ['50%', '60%'],
                    data: series_data,
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 0,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0)'
                        }
                    }
                }
            ]
        };

        // Use just the specified configurations and data charts. 
        myChart.setOption(option);
    },
}
</script>