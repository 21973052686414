var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-form" },
    [
      _c("AdminUniversal", {
        attrs: { component: "title", props: _vm.title_props },
      }),
      _c("AdminUniversal", {
        attrs: { component: "textinput", props: _vm.input_props },
      }),
      _vm._l(_vm.form, function (question, index) {
        return _c("div", { key: index, staticClass: "text-left" }, [
          _c("div", { staticClass: "mbp5" }, [
            _c("b", [_vm._v(_vm._s(index + 1) + ":")]),
            _vm._v(" " + _vm._s(question.question) + " "),
          ]),
          _c("b", [_vm._v("Answer Type: ")]),
          _vm._v(_vm._s(question.answer_type) + " "),
          _c("div", [_vm._v("|" + _vm._s(question.optional) + "|")]),
          _c("hr"),
        ])
      }),
      _c(
        "div",
        { staticClass: "box text-left" },
        [
          _vm._m(0),
          _c("div", { staticClass: "select mb is-fullwidth" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selected,
                    expression: "selected",
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selected = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              _vm._l(_vm.questions, function ({ id, question }, index) {
                return _c("option", { key: index, domProps: { value: id } }, [
                  _vm._v(" (" + _vm._s(id) + ") " + _vm._s(question) + " "),
                ])
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "text-bold" }, [
            _vm._v("Select an answer type"),
          ]),
          _vm._l(_vm.questions, function (question, index) {
            return _c("div", { key: index }, [
              question.id === _vm.selected
                ? _c("div", { staticClass: "is-gapless" }, [
                    _c("div", { staticClass: "select is-fullwidth mbp5" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: question.answer_type,
                              expression: "question.answer_type",
                            },
                          ],
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                question,
                                "answer_type",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.answers, function (answer, index) {
                          return _c(
                            "option",
                            {
                              key: index,
                              domProps: { value: answer.description },
                            },
                            [
                              _vm._v(
                                " (" +
                                  _vm._s(answer.id) +
                                  ") " +
                                  _vm._s(answer.description) +
                                  " "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: question.optional,
                          expression: "question.optional",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "optional",
                        name: "optional",
                      },
                      domProps: {
                        checked: Array.isArray(question.optional)
                          ? _vm._i(question.optional, null) > -1
                          : question.optional,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = question.optional,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  question,
                                  "optional",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  question,
                                  "optional",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(question, "optional", $$c)
                          }
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "cursor-pointer",
                        attrs: { for: "optional" },
                      },
                      [_vm._v(" Optional?")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "button is-fullwidth is-link is-square mtp5",
                        on: {
                          click: function ($event) {
                            return _vm.add_question(question)
                          },
                        },
                      },
                      [_vm._v("Add")]
                    ),
                  ])
                : _vm._e(),
            ])
          }),
        ],
        2
      ),
      _c("AdminUniversal", {
        attrs: { component: "button", props: _vm.button_props1 },
      }),
      _c("AdminUniversal", {
        attrs: { component: "button", props: _vm.button_props2 },
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("b", [_vm._v("Select a question")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }