<template>
<div>
    <Title
        title="Gross Margin Per Country"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container">
            <p>Filter</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <Table
        v-if="table.render"
        :headers="table.headers"
        :rows="table.rows"
        :icon-settings="table.icon_settings"
        :decimal-settings="table.decimal_settings"
        :font-settings="{ bold: {row: {regex: 'Total Retail Countries' } } }"
        :sort-settings="{ 'Country ': { asc: true } }"
        
        class="ms1p5"
    />
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    props: {
        option: String
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: [],
                icon_settings: {},
                decimal_settings: {},
                render: false
            },
            location: '/finance/gross-margin-per-country',
            options: [],
            values: [],
            filter: null,
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.filter_url
                },
                {
                    name: 'By Market',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8/${this.filter_formatted}`
                }
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'fin-gm-1'
            }).then(
                succ => {
                    succ.data.map(filter => {
                        this.values.push(filter.value);
                        this.options.push(filter.option);
                    });
                    if (this.option && this.option !== 'null') {
                        this.filter = this.option.replace(/-/g, ' ');
                    } else {
                        this.filter = this.$store.getters.filter_check(this.values);
                    }
                    this.load_ingka_by_country();
                },
                this.$helper.error
            );
        },
        load_ingka_by_country: function() {
            this.table.render = false;
            if (!this.filter) return;
            this.$http.post('/finance/gross-margin-per-country/total-ingka-by-country', {
                filter: this.filter
            }).then(
                succ => {
                    let d = succ.data;
                    if (d[0].measure_metadata) {
                        this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                    }
                    let keys = Object.keys(d[0].measures);
                    this.table.headers = [['Country'].concat(keys).map(c => {
                        return {
                            [c]: 1
                        }
                    })];
                    this.table.rows = d.map(row => {
                        let temp = [];
                        // If the key/values don't exist, set them to 0
                        keys.map(key => {
                            temp.push(this.$helper.maybe(row.measures[key]));
                        });
                        return [row.caption].concat(temp);
                    });
                    this.table.icon_settings = {
                        'YTD': {
                            icon: 'circle',
                            comparison: 'Year Goal',
                            fix: { post: '%' }
                        },
                        '4WK Trend': {
                            icon: 'arrow',
                            comparison: 'YTD',
                            fix: { post: '%' }
                        },
                        'Year Goal': {
                            fix: { post: '%' }
                        },
                        'Diff Goal-YTD': {
                            fix: { post: '%' }
                        }
                    }
                    this.$helper.header_replace(this.table.headers, {
                        '4 Weeks': '4WK Trend'
                    });
                    if ('4 Weeks' in this.table.decimal_settings) {
                        this.table.decimal_settings['4WK Trend'] = this.table.decimal_settings['4 Weeks'];
                    }
                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.filter_url
            });
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.table.render = false;
            this.update_view(newMarket);
            this.load_ingka_by_country();
        },
        filter: function() {
            this.table.render = false;
            this.$router.push(this.filter_url);
            this.load_ingka_by_country();
        },
        '$route': function(to, from) {
            if (to.params.option === 'null') {
                this.$router.push(this.filter_url);
            }
        }
    },
    computed: {
        filter_formatted:  function() {
            return this.filter ? this.filter.replace(/ /g, '-').replace(/_/g, '-') : null;
        },
        filter_url: function() {
            return `${this.location}/by-country/${this.filter_formatted}`
        }
    }
}
</script>