<template>
<div>
    <Title
        title="After Sales Delivery Share"
        :location="location"
    />

    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container">
            <p>Reason</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>

    <div :key="$store.getters.page_size" class="section half-height box ms">
        <Triple
            v-if="triple.render"

            :title="`Showing data for: ${$store.getters.selected_country_name}`"
            :showtitle="!$store.state.global.bot_mode"
            :xcategories="triple.x_categories"

            :yaxislabel="this.filter"
            ymetric="%"
            
            seriesname1="YTD"
            seriestype1="bar"
            :seriesset1="triple.series1data"
            :color1="$store.state.colors['yellow']"

            seriesname2="LY YTD"
            seriestype2="bar"
            :seriesset2="triple.series2data"
            :color2="$store.state.colors['grey']"

            seriesname3="Year Goal"
            seriestype3="line"
            :seriesset3="triple.series3data"
            :color3="$store.state.colors.YG"
        />
        <div v-else> No data found for {{$store.getters.selected_country_name}}</div>
    </div>
    <div class="section box half-height ms1p5 mb">
        <PieChart
            v-if="reason_data.render"

            :title="`Reason (${$store.getters.selected_country_name})`"
            :subtitle="null"
            :names="reason_data.names"
            :values="reason_data.values"
        />
        <div v-else> No data found for {{$store.getters.selected_country_name}}</div>
    </div>
    <!-- commented out due to lack of data, 25/09/2020 Ben Chambers -->

    <!-- <div class="section half-height box ms">
        <QuadCustom
            v-if="quad.render"
            :key="$router.currentRoute.path"

            :title="`Showing data for: ${$store.getters.selected_country_name}.`"
            :showtitle="!$store.state.global.bot_mode"
            :xcategories="quad.x_categories"

            yaxislabel="After Sales Delivery Share %"
            ymetric="%"
            
            seriesname1="Sales Process TY"
            seriestype1="bar"
            :seriesset1="quad.series1data"
            :color1="$store.state.colors['yellow']"

            seriesname2="Service Process TY"
            seriestype2="bar"
            :seriesset2="quad.series2data"
            :color2="$store.state.colors['grey']"

            seriesname3="Sales Process LY"
            seriestype3="scatter"
            :seriesset3="quad.series3data"
            color3="#8560af"

            seriesname4="Service Process LY"
            seriestype4="scatter"
            :seriesset4="quad.series4data"
            color4="#616161"
        />
        <div v-else> No data found for {{$store.getters.selected_country_name}}</div>
    </div> -->
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import HorizontalSingleBar from '@/components/visualisations/bar/HorizontalSingleBar.vue';
import Title from '@/components/ui/Title.vue';
import Triple from '@/components/visualisations/general/Triple.vue';
import PieChart from '@/components/visualisations/pie/PieChart.vue';
import QuadCustom from '@/components/visualisations/general/QuadCustom.vue';

export default {
    props: {
        market: String
    },
    components: {
        Buttons,
        DropDown,
        HorizontalSingleBar,
        Title,
        Triple,
        PieChart,
        QuadCustom
    },
    data: function() {
        return {
            colors: {
                three_roads: '#bd97e9'
            },
            triple: {
                x_categories: null,

                series1data: [],
                series2data: [],
                series3data: [],

                render: false,
            },
            reason_data: {
                names: [],
                values: [],
                render: false
            },

            quad: {
                x_categories: null,

                series1data: [],
                series2data: [],
                series3data: [],
                series4data: [],

                render: false
            },
            options: ['Change Of Mind', 'Customer Assistance', 'Product Quality', 'Sales Process', 'Service Process'],
            values: ['Change Of Mind', 'Customer Assistance', 'Product Quality', 'Sales Process', 'Service Process'],
            filter: 'Change Of Mind',
            location: '/finance/after-sales-delivery-share'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.get_market_data();
        this.get_reason_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8/null`
                },
            ];
        },
        get_reason_data: function() {
            this.reason_data.names = [];
            this.reason_data.values = [];
            this.reason_data.render = false;
            this.$http.post('/finance/after-sales-delivery-share/reason', {
                market: this.$store.getters.market
            }).then(
                succ => {
                    if (!succ.data[0]) return;
                    let reason_data = succ.data[0];
                    this.reason_data.names = Object.keys(reason_data.measures);
                    this.reason_data.values = Object.values(reason_data.measures);
                    
                    this.reason_data.render = true;
                },
                this.$helper.error
            );
        },
        get_development_data: function() {
            this.quad.render = false;
            this.$http.post('/finance/after-sales-delivery-share/development', {
                market: this.$store.getters.market
            }).then(
                succ => {
                    if (!succ.data[0]) return;
                    let market_data = succ.data[0];
                    let measures = market_data.measures;

                    this.quad.x_categories = Object.keys(measures);

                    this.quad.series1data = [];
                    this.quad.series2data = [];
                    this.quad.series3data = [];
                    this.quad.series4data = [];

                    Object.values(measures).forEach(week => {
                        this.quad.series1data.push(week['Sales Process Cy']);
                        this.quad.series2data.push(week['Sales Process Ly']);
                        this.quad.series3data.push(week['Service Process Cy']);
                        this.quad.series4data.push(week['Service Process Ly']);
                    });

                    this.quad.render = true;
                },
                this.$helper.error
            );
        },
        get_market_data: function() {
            this.triple.render = false;
            this.$http.post('/finance/after-sales-delivery-share/market', {
                market: this.$store.getters.market,
                filter: this.filter
            }).then(
                succ => {
                    if (!succ.data[0]) return;
                    let market_data = succ.data[0];
                    let measures = market_data.measures;

                    this.triple.x_categories = Object.keys(measures);

                    this.triple.series1data = [];
                    this.triple.series2data = [];
                    this.triple.series3data = [];

                    Object.values(measures).forEach(week => {
                        this.triple.series1data.push(week['YTD']);
                        this.triple.series2data.push(week['LY YTD']);
                        this.triple.series3data.push(week['Year Goal']);
                    });

                    this.triple.render = true;
                },
                this.$helper.error
            );
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.get_market_data();
            this.get_reason_data();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.countries': function(newCountries, oldCountries) {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.reason_data.render = false;
            this.triple.render = false;
            this.quad.render = false;

            this.update_view(newMarket);

            this.get_reason_data();
            this.get_market_data();
        }
    }
}
</script>
