<template>
<div>
    <div class="buttons" v-show="!this.$store.getters.presentation_mode">
        <button
        v-for="(button, index) in buttons"
        :id="formatId(button.name)"
        :data-index="index"
        :key="index"
        @click="handleClick(index, button.reportSection, button.country_filter, button.pbi_filter)"
        :class="['button ikea-blue', { 'router-link-active': activeButtonIndex === index }]"
        >
            {{button.name}}
        </button>
        <div
            v-if="this.dataset && this.dataset.headers.length"
            class="button download ft-tooltip"
            tooltiptext="Download current page"
            @click="download_xlsx()"
        >
            <i class="fas fa-file-excel"></i>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        buttons: Array,
        type: {
            type: String,
            default: 'table'
        } ,
        dataset: {
            type: Object,
        },
        clickHandler: {
            type: Function,
            required: true
        }
    },
    data: function() {
        return {
            blacklist_decimal_settings: [
                '/three-roads/penetration',
                '/brand/desire',
                '/three-roads/positive-impact',
                '/three-roads/penetration',
                '/brand/trust'
            ],
            activeButtonIndex: 0,
        }
    },
    mounted(){
    //    setTimeout(() => {
    //     this.autoBtnClick();
    // }, 5000);
            
            // this.autoBtnClick();          
    },
    methods: {
       
        //  to generate automatic reports on selenium, the autoClick function is used
        autoBtnClick(){            
                const pagePathKey = this.$route.query.button;                
                if (pagePathKey) {
                    const button = document.getElementById(pagePathKey);
                    if (button) {
                    button.click();
                    }
        }
        },      
        handleClick(index, reportSection = null, country_filter = null, pbi_filter = null) {
            // console.log("index", index);
            this.activeButtonIndex = index; // Set the active button index
            this.clickHandler(reportSection, country_filter, pbi_filter);
        },
         formatId(name) {
            return name
            .toLowerCase()                 // Convert all to lowercase
            .replace(/\s+/g, '')          // Replace spaces with empty string '')
            .replace(/[-_]/g, '')         // Remove hyphens and underscores
            .replace(/[^\w]/g, '');       // Remove special characters, leaving only letters and digits
        },
        download_xlsx: function() {
            if (!this.type || !Object.keys(this.dataset).length) return;
            let formatted_row_dataset = null;
            if (this.dataset['xlsx_rows']) {
                formatted_row_dataset = {
                    'headers': this.dataset['headers'],
                    'rows': this.dataset['xlsx_rows'],
                    'decimal_settings': this.dataset['decimal_settings']
                }
            }
            if (this.blacklist_decimal_settings.includes(this.$router.history.current.fullPath)) {
                if (this.dataset && this.dataset.decimal_settings) delete this.dataset.decimal_settings;
                if (formatted_row_dataset && formatted_row_dataset.decimal_settings) delete formatted_row_dataset.decimal_settings;
            }
            
            this.$http.post('/download/generate', {
                api_location: this.$router.history.current.fullPath,
                type: this.type,
                dataset: formatted_row_dataset || this.dataset
            }).then(
                succ => {
                    if (succ.data.link) {
                        window.open(succ.data.link, '_blank');
                    }
                },
                this.$helper.error
            );
        }
    }
}
</script>

<style scoped>
.download {
    border: 1px solid #dbdbdb !important;
}

.fa-file-excel {
    color: #016f36;
}

.buttons .button {
    border: none;
    border-radius: 0;
    color: rgb(204, 204, 204);
}

/* .buttons .button {
    border: none;
    border-radius: 0.5vw;
    color:white;
} */

.buttons:not(:last-child) {
    margin-bottom: 0;
}

.router-link-active {
    color: #ffffff !important;
    box-shadow: none!important;
    /* text-shadow: 0 0 3px #7a7a7a; */
}

.buttons .button {
    margin-bottom: 1rem;
}
</style>
