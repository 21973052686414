var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "button is-success mt button-left",
          on: {
            click: function ($event) {
              return _vm.toggle_new_modal()
            },
          },
        },
        [_vm._v("Add new or change responsible for an insights title")]
      ),
      _vm.show_responsibles_modals.new
        ? _c("Modal", {
            class: { "is-active": _vm.show_responsibles_modals.new },
            attrs: {
              closeable: true,
              title: "Add new Responsible for an insights title",
            },
            on: {
              close: function ($event) {
                return _vm.toggle_new_modal()
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      !_vm.edit_buttons.user
                        ? _c(
                            "div",
                            { staticClass: "text-left" },
                            [
                              _vm._v(" Please select a user "),
                              _c("UserSearch", {
                                on: { SelectedUser: _vm.selected_user },
                              }),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "text-left flex" }, [
                            _c("div", { staticClass: "selected-user" }, [
                              _c("b", [_vm._v("User Selected:")]),
                              _vm._v(
                                " " + _vm._s(_vm.new_responsible.user_name)
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "button is-info",
                                on: {
                                  click: function ($event) {
                                    return _vm.edit_button_click("user")
                                  },
                                },
                              },
                              [_vm._v("Change")]
                            ),
                          ]),
                      !_vm.edit_buttons.title
                        ? _c(
                            "div",
                            [
                              _vm.new_responsible.user_name
                                ? _c("AdminUniversal", {
                                    attrs: {
                                      component: "dropdown",
                                      props: _vm.dropdown_props1,
                                    },
                                  })
                                : _vm._e(),
                              _vm.title_rows.length &&
                              _vm.new_responsible.user_name
                                ? _c(
                                    "table",
                                    [
                                      _c("tr", [
                                        _c("th", [_vm._v(" Select a Title ")]),
                                      ]),
                                      _vm._l(_vm.title_rows, function (row, i) {
                                        return _c("tr", { key: i }, [
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "title-hover cursor-pointer",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.select_row(row)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(row.title) + " "
                                              ),
                                            ]
                                          ),
                                        ])
                                      }),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "text-left flex" }, [
                            _c("div", { staticClass: "selected-user" }, [
                              _c("b", [_vm._v("Title Selected:")]),
                              _vm._v(" " + _vm._s(_vm.new_responsible.title)),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "button is-info",
                                on: {
                                  click: function ($event) {
                                    return _vm.edit_button_click("title")
                                  },
                                },
                              },
                              [_vm._v("Change")]
                            ),
                          ]),
                      !_vm.edit_buttons.country
                        ? _c(
                            "div",
                            [
                              _vm.new_responsible.title_id
                                ? _c("AdminUniversal", {
                                    attrs: {
                                      component: "dropdown",
                                      props: _vm.dropdown_props2,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "text-left flex" }, [
                            _c("div", { staticClass: "selected-user" }, [
                              _c("b", [_vm._v("Country Selected:")]),
                              _vm._v(" " + _vm._s(_vm.new_responsible.title)),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "button is-info",
                                on: {
                                  click: function ($event) {
                                    return _vm.edit_button_click("title")
                                  },
                                },
                              },
                              [_vm._v("Change")]
                            ),
                          ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c("a", { staticClass: "buttons" }, [
                        _c(
                          "a",
                          {
                            staticClass: "button is-success",
                            on: {
                              click: function ($event) {
                                return _vm.add_new_responsible()
                              },
                            },
                          },
                          [_vm._v("Add")]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "button is-danger",
                            on: {
                              click: function ($event) {
                                return _vm.toggle_new_modal()
                              },
                            },
                          },
                          [_vm._v("Cancel")]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3057471586
            ),
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex" },
        [
          _c("AdminUniversal", {
            attrs: { component: "dropdown", props: _vm.dropdown_props3 },
          }),
          _c("AdminUniversal", {
            staticClass: "ml",
            attrs: { component: "dropdown", props: _vm.dropdown_props4 },
          }),
        ],
        1
      ),
      this.$store.getters.countries.values
        ? _c(
            "table",
            [
              _c(
                "tr",
                _vm._l(_vm.responsibles_headers, function (header, i) {
                  return _c("th", { key: i, staticClass: "capitalize" }, [
                    _vm._v(" " + _vm._s(header) + " "),
                  ])
                }),
                0
              ),
              _vm._l(_vm.filtered_responsibles_rows, function (row, i) {
                return _c(
                  "tr",
                  { key: i },
                  [
                    _vm._l(_vm.responsibles_headers, function (header, i) {
                      return _c("td", { key: i }, [
                        header === "country"
                          ? _c("div", [
                              _vm._v(
                                _vm._s(_vm.return_country_name(row[header]))
                              ),
                            ])
                          : _c("div", [_vm._v(_vm._s(row[header]))]),
                      ])
                    }),
                    _c("td", [
                      _c(
                        "div",
                        {
                          staticClass: "button is-danger",
                          on: {
                            click: function ($event) {
                              return _vm.remove_responsible(row.responsible_id)
                            },
                          },
                        },
                        [_vm._v("Remove")]
                      ),
                    ]),
                  ],
                  2
                )
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }