var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: { title: "Product Availability", location: _vm.location },
      }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons() },
          }),
          _c(
            "div",
            { staticClass: "label-container" },
            [
              _c("p", [_vm._v("Status")]),
              _c("DropDown", {
                key: _vm.filter,
                attrs: {
                  options: _vm.options,
                  values: _vm.values,
                  default: _vm.filter,
                },
                on: { updateDropDown: _vm.update_filter },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size,
          staticClass: "section three-quarters-height box ms",
        },
        [
          _vm.sextuple.render
            ? _c("Sextuple", {
                attrs: {
                  title: "CDC",
                  showtitle: !_vm.$store.state.global.bot_mode,
                  subtitle: "",
                  xcategories: _vm.sextuple.xcategories,
                  yaxislabel: "Product Availability",
                  ymetric: "%",
                  yaxismin: 60,
                  seriesname1: "Total",
                  seriestype1: "line",
                  seriesset1: _vm.sextuple.seriesset1,
                  color1: _vm.$store.state.colors.YG,
                  seriesname2: "SL1",
                  seriestype2: "line",
                  seriesset2: _vm.sextuple.seriesset2,
                  color2: _vm.$store.state.colors["focus-yellow"],
                  seriesname3: "SL2",
                  seriestype3: "line",
                  seriesset3: _vm.sextuple.seriesset3,
                  color3: _vm.$store.state.colors["yellow"],
                  seriesname4: "SL3",
                  seriestype4: "line",
                  seriesset4: _vm.sextuple.seriesset4,
                  color4: _vm.$store.state.colors["dark-grey"],
                  seriesname5: "SL4",
                  seriestype5: "line",
                  seriesset5: _vm.sextuple.seriesset5,
                  color5: _vm.$store.state.colors["light-grey"],
                  seriestype6: "line",
                  show: !_vm.$store.state.global.bot_mode,
                },
              })
            : _c("div", [
                _vm._v(
                  " No data found for " + _vm._s(_vm.$store.getters.market)
                ),
              ]),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size + 1,
          staticClass: "section three-quarters-height box ms",
        },
        [
          _vm.sextuple.render
            ? _c("Sextuple", {
                attrs: {
                  title: "OSA",
                  showtitle: !_vm.$store.state.global.bot_mode,
                  subtitle: "",
                  xcategories: _vm.sextuple2.xcategories,
                  yaxislabel: "Product Availability",
                  ymetric: "%",
                  yaxismin: 60,
                  seriesname1: "Total",
                  seriestype1: "line",
                  seriesset1: _vm.sextuple2.seriesset1,
                  color1: _vm.$store.state.colors.YG,
                  seriesname2: "SL1",
                  seriestype2: "line",
                  seriesset2: _vm.sextuple2.seriesset2,
                  color2: _vm.$store.state.colors["focus-yellow"],
                  seriesname3: "SL2",
                  seriestype3: "line",
                  seriesset3: _vm.sextuple2.seriesset3,
                  color3: _vm.$store.state.colors["yellow"],
                  seriesname4: "SL3",
                  seriestype4: "line",
                  seriesset4: _vm.sextuple2.seriesset4,
                  color4: _vm.$store.state.colors["dark-grey"],
                  seriesname5: "SL4",
                  seriestype5: "line",
                  seriesset5: _vm.sextuple2.seriesset5,
                  color5: _vm.$store.state.colors["light-grey"],
                  seriestype6: "line",
                  show: !_vm.$store.state.global.bot_mode,
                },
              })
            : _c("div", [
                _vm._v(
                  " No data found for " + _vm._s(_vm.$store.getters.market)
                ),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }