<template>
<div>
    <Title
        title="Sales Trend Total"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />

         <div class="label-container">
            <p>Filter</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <Table
        id="sales-trend-total-by-market"
        v-if="data_found"
        :headers="table.headers"
        :rows="table.rows"
        :decimal-settings="table.decimal_settings"
        :icon-settings="table.icon_settings"
        :font-settings="{ bold: {row: {regex: 'Total' } } }"
        :sort-settings="{ 'Market-Area': { asc: true } }"
        :metaSettings="{is_selectable: false}"
        
        class="ms1p5"
    />
    <div v-else>No data for {{$store.state.selected.country}}, please select another country</div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';


export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    props: {
        market: String
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: [],
                icon_settings: {},
                decimal_settings: {},
                render: false
            },
            location: '/business/total-sales/sales-trend-total',
            data_found: true,
            store: null,
            filter: null,
            options: [],
            values: [],
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'Summary',
                    link: `${this.location}/summary/${this.$store.getters.market}`
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'By Market',
                    link: `${this.location}/by-market/${this.$store.getters.market}`
                },
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'sales-channel-1'
            }).then(
                succ => {
                    succ.data.map(filter => {
                        this.values.push(filter.value);
                        this.options.push(filter.option);
                    });
                    this.filter = this.$store.getters.filter_check(this.values);
                    if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
                    this.load_ingka_by_country();
                },
                this.$helper.error
            );
        },
        load_ingka_by_country: function() {
            this.data_found = false;
            if (!this.$store.getters.market) return;
            this.$http.post('business/total-sales/sales-trend-total/by-market', {
                market: this.$store.getters.market,
                filter: this.filter
            }).then(
                succ => {
                    let d = succ.data;
                    if (d === undefined) return;
                    let keys = Object.keys(d[0].measures);
                    if (d[0].measure_metadata) {
                        this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                    }
                    this.table.headers = [['Market-Area'].concat(keys).map(c => {
                        return {
                            [c]: 1
                        }
                    })];
                    // this.table.headers.splice(this.table.headers.indexOf('Remaining'), 1); keeping incase need to remove remaining again at a later date - Ben 09/10/2020
                    this.table.rows = d.map(row => {
                        let temp = [];
                        // If the key/values don't exist, set them to 0
                        keys.map(key => {
                            // if (key !== 'Remaining') { keeping incase need to remove remaining again at a later date - Ben 09/10/2020
                                temp.push(this.$helper.maybe(row.measures[key]));
                            //}
                        });
                        return [row.caption].concat(temp);   
                    });
                    this.table.icon_settings = {
                        'YTD Index': {
                            icon: 'circle',
                            comparison: 'Year Goal'
                        },
                        '4WK': {
                            icon: 'arrow',
                            comparison: 'YTD Index',
                        }
                    }
                    this.$helper.header_replace(this.table.headers, {
                        '1 Week': '1WK',
                        '4 Weeks': '4WK',
                        '8 Weeks': '8WK',
                        '13 Weeks': '13WK',
                    });
                    this.data_found = true;
                },
                this.$helper.error
            );
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.load_ingka_by_country();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: `${this.location}/by-market`
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.load_ingka_by_country();
        },
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        }
    }
}
</script>
