var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: {
          title: "Goods value connected to a service",
          location: _vm.location,
        },
      }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons() },
          }),
          _c(
            "div",
            { staticClass: "label-container" },
            [
              _c("p", [_vm._v("HFB")]),
              _vm.hfbs.all.length > 0
                ? _c("DropDown", {
                    key: _vm.hfbs.all.length,
                    attrs: {
                      options: _vm.hfbs.options,
                      values: _vm.hfbs.values,
                      default: _vm.hfbs.values[0],
                    },
                    on: { updateDropDown: _vm.update_hfb },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                key: _vm.$store.getters.market,
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: _vm.$store.getters.market,
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size,
          staticClass: "section three-quarters-height box ms",
        },
        [
          _vm.triple.render
            ? _c("Triple", {
                key: _vm.triple.render,
                attrs: {
                  title: `Showing data for: ${_vm.$store.getters.selected_country_name}`,
                  showtitle: !_vm.$store.state.global.bot_mode,
                  subtitle: "",
                  xcategories: _vm.triple.xcategories,
                  yaxislabel: "Goods Value connected to a service Index",
                  ymetric: "",
                  seriesname1: "YTD",
                  seriestype1: "line",
                  seriesset1: _vm.triple.seriesset1,
                  color1: _vm.$store.state.colors["focus-yellow"],
                  seriesname2: "Total HFO Sales",
                  seriestype2: "line",
                  seriesset2: _vm.triple.seriesset2,
                  color2: _vm.$store.state.colors["light-grey"],
                  seriesname3: "4WK",
                  seriestype3: "line",
                  seriesset3: _vm.triple.seriesset3,
                  color3: _vm.$store.state.colors["dark-grey"],
                  show: !_vm.$store.state.global.bot_mode,
                },
              })
            : _c("div", [
                _vm._v(
                  " No data found for " + _vm._s(_vm.$store.getters.market)
                ),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }