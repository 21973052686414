<template>
    <div :id="title" style="width: 100%; height:100%;"></div>
</template>

<script>
import * as echarts from 'echarts/dist/echarts.min.js';
export default {
    props: {
         title: String, //title of the graph
        showtitle: Boolean,
        subtitle: String, //subtitle of the graph
        xcategories: Array, //data that will be displayed on the x axis
        yaxislabel: String, //name of the data on the y axis e.g 'After sales delivery share' or 'services sale by month'
        ymetric: String, //'%', '£', 'kg' etc.
        seriesname1: String, //name of the data such as IKEA 
        seriestype1: String, //bar, line, scatter etc.
        seriesset1: Array, //actual data array to fill the graph
        color1: String, //color of the bar lie or scatter for the paticular data set
        seriesname2: String,
        seriestype2: String,
        seriesset2: Array,
        color2: String,
        seriesname3: String,
        seriestype3: String,
        seriesset3: Array,
        color3: String,
        seriesname4: String,
        seriestype4: String,
        seriesset4: Array,
        color4: String,
        hidebarlabel: Boolean,
        show: Boolean, // true to show the exta features such as 'save graph as image', 'refresh', 'change to bar graph', etc
    },
    mounted: function() {
        let myChart = echarts.init(document.getElementById(this.title));
        let option = {
           title:{
                text: this.title,
                subtext: this.subtitle,
                show: this.showtitle,
                subtextStyle: {
                    color: 'black',
                },
                top: '-2em',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            toolbox: {
                top: '85em',
                right: '30em',
                show: this.show,
                feature: {
                    restore: {},
                    saveAsImage: {}
                }
            },
            grid: {
                top: '80em',
                left: '70vh',
                bottom: '20em',
                containLabel: true
            },
            legend: {
                top: '30em',
                left: '0em',
                data:[this.seriesname1, this.seriesname2, this.seriesname3, this.seriesname4],
            },
            textStyle: {
                fontFamily: 'NotoIKEALatin-Regular',
            },
            xAxis: [
                {
                    type: 'category',
                    data: this.xcategories,
                    axisPointer: {
                        type: 'shadow'
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: this.yaxislabel,
                    nameLocation: 'middle',
                    nameGap: 50,
                    axisLabel: {
                        formatter: `{value} ${this.ymetric}`
                    }
                }
            ],
            series: [
                {
                    name: this.seriesname1,
                    type: this.seriestype1,
                    data: this.seriesset1,
                    color: this.color1,
                    stack: 'example',
                    label: {
                        color: 'black',
                        show: this.hidebarlabel ? false : true,
                        position: 'inside'
                    },
                },
                {
                    name: this.seriesname2,
                    type: this.seriestype2,
                    data: this.seriesset2,
                    color: this.color2,
                    stack: 'example',
                    label: {
                        show: this.hidebarlabel ? false : true,
                        position: 'inside'
                    },
                },
                {
                    name: this.seriesname3,
                    type: this.seriestype3,
                    data: this.seriesset3,
                    color: this.color3,
                    stack: 'example',
                    label: {
                        show: this.hidebarlabel ? false : true,
                        position: 'inside'
                    },
                },
                {
                    name: this.seriesname4,
                    type: this.seriestype4,
                    data: this.seriesset4,
                    color: this.color4,
                    stack: 'example',
                    label: {
                        show: this.hidebarlabel ? false : true,
                        position: 'inside'
                    },
                },
            ]
        };
        myChart.setOption(option);
    }
}
</script>