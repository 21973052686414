<template>
<div>
    <Title
        title="Happy Customer"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />

        <div v-if="values.length > 0" class="label-container">
            <p>CMP</p>
            <DropDown
                :key="values[0]"
                :options="options"
                :values="values"
                :default="values[0]"
                @updateDropDown="update_filter"
            />
        </div>
        
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section box one-quarter-height ms1p5 mb">
        <!-- Horizontal Bar Chart -->
        <HorizontalSingleBar
            v-if="render.horizontal"

            :title="get_feedback_score_title"

            :showtitle="!$store.state.global.bot_mode"
            :ycategories="['']"
            :yaxislabel="'Feedback\nVolume'"
            :xaxismin="Math.min(score_data.values)"
            :xinterval="5"
            xmetric=""

            :seriesset1="[score_data.values[0]]"
            seriestype1="bar"
            color1="#00802b"
            seriesname1="Positive"

            :seriesset2="[score_data.values[1]]"
            seriestype2="bar"
            color2="#6a6a6a"
            seriesname2="Neutral"

            :seriesset3="[score_data.values[2]]"
            seriestype3="bar"
            color3="#b32d00"
            seriesname3="Negative"

        />
        <div v-else> No data found for {{$store.getters.selected_country_name}}</div>
    </div>
    <div class="section three-quarters-height box ms">
        <QuintupleStack
            v-if="render.chart"

            :title="`Showing data for: ${$store.getters.selected_country_name}`"
            :showtitle="!$store.state.global.bot_mode"

            subtitle=""

            :xcategories="triple.xcategories"
            
            :yaxislabel1="`Happy Customer Score ${ filter === 'total' ? 'Topline' : options[values.indexOf(filter)]}`"
            ymetric1=""

            yaxislabel2="Feedback Volume"
            ymetric2=""

            seriesname1="Total" 
            seriestype1="bar"
            :seriesset1="triple.seriesset1"
            :color1="$store.state.colors['grey']"
            
            seriestype2="bar"
            seriestype3="bar"
            seriestype4="line"

            seriesname5="CEM phase HCS (sentiment)"
            seriestype5="line"
            :seriesset5="triple.seriesset5"
            :color5="$store.state.colors['focus-yellow']"

            :hidebarlabel="true"
        />
        <div v-else> No data found for {{$store.getters.selected_country_name}}</div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import QuintupleStack from '@/components/visualisations/general/QuintupleStack.vue';
import HorizontalSingleBar from '@/components/visualisations/bar/HorizontalSingleBarStack.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Title,
        QuintupleStack,
        HorizontalSingleBar
    },
    props: {
        market: String
    },
    data: function() {
        return {
            triple: {
                title: null,
                subtitle: null,

                xcategories: null,
                yaxislabel: null,
                ymetric: null,
                
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],
                seriesset4: [],
                seriesset5: [],
            },
            render: {
                chart: false,
                horizontal: false
            },
            score_data: {
                names: null,
                values: null,

                title: null,
                subtitle: null,

                xcategories: null,
                yaxislabel: null,
                ymetric: null,
                
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],
                seriesset4: [],
                seriesset5: [],
            },
            options: [],
            values: [],
            filter: null,
            location: '/customer/happy-customer'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
                // {
                //     name: 'Customer Journey',
                //     link: `${this.location}/summary/${this.$store.getters.market}`
                // }
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'hcs-cmp-1'
            }).then(
                succ => {
                    succ.data.map(filter => {
                        this.values.push(filter.value);
                        this.options.push(filter.option);
                    });
                    this.filter = this.$store.getters.filter_check(this.values);
                    if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
                    this.load_market_data();
                },
                this.$helper.error
            );
        },
        load_market_data: function() {
            let url = 'customer/happy-customer/summary';
            this.$http.post('customer/happy-customer/summary', {
                market: this.$store.getters.market,
                filter: this.filter
            }).then(
                succ => {
                    if (!succ.data) return;
                    let d = succ.data;

                    // For the main graph
                    this.triple.seriesset1 = [];
                    this.triple.seriesset2 = [];
                    this.triple.seriesset3 = [];
                    this.triple.seriesset4 = [];
                    this.triple.seriesset5 = [];

                    // Sort the measures into defined order - ideally this should be done by database
                    const measures_sorted = [{
                        "Exploring": d[0].measures["exploring"],
                        "Choosing": d[0].measures["choosing"],
                        "Committing": d[0].measures["committing"],
                        "Integrating": d[0].measures["integrating"],
                        "Living": d[0].measures["living"],
                    }];

                    this.triple.xcategories = Object.keys(measures_sorted[0]);

                    // This sets the graphy
                    this.triple.xcategories.map(subarea => {
                        let val = measures_sorted[0][subarea];

                        this.triple.seriesset1.push(this.$helper.maybe(val['total']));
                        this.triple.seriesset5.push(this.$helper.maybe(val['score']));   
                    });
                    
                    // Horizontal graph
                    let total_score_data = d[0].measures["total"];

                    this.score_data.names, this.score_data.values = null;

                    // Sort the measures - again should be sorted by the data
                    let score_measures_sorted = {
                        "Positive": total_score_data['positive'],
                        "Neutral": total_score_data['neutral'],
                        "Negative": total_score_data['negative']
                    };

                    // Push the values
                    this.score_data.names = Object.keys(score_measures_sorted);
                    this.score_data.values = Object.values(score_measures_sorted);

                    // Render
                    this.render.chart = true;
                    this.render.horizontal = true;
                },
                this.$helper.error
            );
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.render = {
                chart: false,
                horizontal: false
            }
            this.load_market_data();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: `${this.location}/summary`,
            });
        }
    },
    computed: {
        get_feedback_score_title: function() {
            let filter_uc = this.filter.charAt(0).toUpperCase() + this.filter.slice(1);

            // Make an exception for 'total' so we don't get 'Total Total Feedback in the title'
            return `${this.filter !== 'total' ? 'Total ' : ''}${filter_uc} Feedback Sentiment Breakdown for: ${this.$store.getters.selected_country_name}`;
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.render = {
                chart: false,
                horizontal: false
            }
            this.update_view(newMarket);
            this.load_market_data();
        }
    }
}
</script>

<style>

</style>
