var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "table" } }, [
    _c("div", { staticClass: "table-container" }, [
      _c("table", { staticClass: "custom table desktop" }, [
        _c(
          "thead",
          _vm._l(_vm.parsed_headers, function (row, index) {
            return _c(
              "tr",
              { key: index },
              _vm._l(row, function (cell, i) {
                return _c(
                  "th",
                  {
                    key: i,
                    staticClass: "ikea-yellow",
                    class: [
                      /*render_colors(),*/ _vm.render_td_classes(cell.classes),
                    ],
                    attrs: { colspan: Object.values(cell).shift() },
                    on: {
                      click: function ($event) {
                        return _vm.sort.sort(
                          index,
                          i,
                          _vm.parsed_headers,
                          _vm.parsed_rows
                        )
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(Object.keys(cell).shift()) + " ")]
                )
              }),
              0
            )
          }),
          0
        ),
        _c(
          "tbody",
          { key: _vm.meta.selected_row },
          _vm._l(_vm.parsed_rows, function (row, index) {
            return _c(
              "tr",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.bridge.hidden_rows.includes(row[0].value),
                    expression: "!bridge.hidden_rows.includes(row[0].value)",
                  },
                ],
                key: index,
                class: _vm.render_classes(
                  index,
                  row[0] && row[0].classes ? row[0].classes : [],
                  row[0]
                ),
                on: {
                  click: function ($event) {
                    _vm.select_row(row, index)
                    _vm.bridge.unfold_bridge(row[0])
                  },
                },
              },
              _vm._l(row, function (cell, i) {
                return _c(
                  "td",
                  { key: i, class: _vm.render_td_classes(cell.classes) },
                  [
                    i === 0 &&
                    row[0].classes &&
                    row[0].classes.includes("bridge-row")
                      ? _c("i", {
                          staticClass: "fas float-left mr",
                          class: {
                            "fa-minus": _vm.bridge.clicked_rows.includes(
                              row[0].value
                            ),
                            "fa-plus": !_vm.bridge.clicked_rows.includes(
                              row[0].value
                            ),
                          },
                        })
                      : _vm._e(),
                    cell.html
                      ? _c("div", {
                          domProps: { innerHTML: _vm._s(cell.html) },
                        })
                      : _c("div", [_vm._v(_vm._s(cell.value))]),
                  ]
                )
              }),
              0
            )
          }),
          0
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "mobile text-left" },
      _vm._l(_vm.parsed_rows, function (row, i) {
        return _c(
          "table",
          { key: i, staticClass: "table is-fullwidth is-striped is-bordered" },
          [
            _c(
              "tbody",
              _vm._l([..._vm.parsed_headers].pop(), function (header, j) {
                return _c(
                  "tr",
                  { key: j, class: _vm.render_mobile_classes(i, j) },
                  [
                    _c(
                      "td",
                      {
                        staticStyle: { width: "50%" },
                        on: {
                          click: function ($event) {
                            return _vm.sort.sort(
                              _vm.headers.length - 1,
                              j,
                              _vm.parsed_headers,
                              _vm.parsed_rows
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(header.alias || Object.keys(header).shift())
                        ),
                      ]
                    ),
                    _c("td", [
                      _vm.parsed_rows[i][j].html
                        ? _c("div", {
                            domProps: {
                              innerHTML: _vm._s(_vm.parsed_rows[i][j].html),
                            },
                          })
                        : _c("div", { staticClass: "text-left" }, [
                            _vm._v(_vm._s(_vm.parsed_rows[i][j].value)),
                          ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }