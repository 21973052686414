<template>
<div>
    <Title
        title="Sales Tree"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />
 
        <div class="label-container">
            <p>Channel</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>
        <div class="label-container">
            <p>Index / Value</p>
            <DropDown
                :key="filter2"
                :options="options2"
                :values="values2"
                :default="filter2"
                @updateDropDown="update_filter2"
            />
        </div>
        <div class="label-container hidden-on-pc is-fullwidth">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div id="sales-tree-table">
        <Table
            v-if="table.render && $store.getters.market"

            :headers="table.headers"
            :rows="table.rows"

            :bridge-settings="table.bridge_settings"
            :decimal-settings="table.decimal_settings"
            :data-settings="table.data_settings"
            :icon-settings="table.icon_settings"
            :sort-settings="{ 'Country': { asc: true } }"
            :font-settings="{ bold: {row: {regex: 'Total Retail Countries' } } }"
        />
        <div v-else>There is no data for <b>{{$store.getters.selected_country_name}}</b> with the <b>{{options[values.indexOf(filter)]}}</b> and <b>{{options2[values2.indexOf(filter2)]}}</b> filters selected</div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    props: {
        market: String
    },
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            beta_mode: false,
            options: ['Total Store','Store (Comp)', 'Online', 'Remote'],
			values: ['Store', 'Store(Comp)', 'Online', 'Remote'],
			filter: 'Store',
            options2: ['Index', 'Value'],
			values2: ['indices', 'values'],
			filter2: 'indices',
            location: '/business/sales-tree',

            table: {
                headers: [],
                rows: [],
                decimal_settings: {},
                data_settings: { nullify_zeros: true },

                render: false
            },
            data_names: [
                'visitors_ytd',
                'visitors_4wk',
                'customer_gross_ytd',
                'customer_4wk',
                'conversion_ytd',
                'conversion_4wk',
                'avg_ticket_ytd',
                'avg_ticket_4wk',
                'cre_sales_gross_ytd',
                'cre_sales_gross_4wk',
                'cre_sales_net_ytd',
                'cre_sales_net_4wk',
                'gap_ytd',
                'gap_4wk',
                'dlvd_sales_net_ytd',
                'dlvd_sales_net_4wk',
            ]
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        this.filter = this.$store.getters.filter_check(this.values);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.load_ingka_by_country();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'By Market',
                    link: `${this.location}/by-market/${this.$store.getters.market}`
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
					name: "Tree",
					link: `${this.location}/tree/${this.$store.getters.market}`
				},
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8/Online`
                }
            ];
        },
        load_ingka_by_country: function() {     
            if (this.filter) {
                this.$http.post('business/sales-tree/by-market', {
                    filter1: this.filter,
                    filter2: this.filter2,
                    market: this.$store.getters.market
                }).then(
                    succ => {
                        let d = succ.data;
                        if (this.filter === 'Store' && !d[0].measures) {
                            this.options.shift();
                            this.values.shift();
                            this.filter = this.values[0];
                            this.load_ingka_by_country();
                            return;
                        }
                        if (!d[0] || !d[0].measures) return;
                        if (d[0].measure_metadata) {
                            this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                        }
                        if (this.filter2.toLowerCase() === 'values') this.table.decimal_settings = {global: 2};

                        this.table.headers = [
                            // Row 1
                            [
                                { '': 1 },
                                { 'Visitors': 2 },
                                { 'Customers, gross': 2 },
                                { 'Conversion': 2 },
                                { 'Avg, ticket': 2 },
                                { 'Created Sales, gross': 2 },
                                
                                { 'Created Sales, net': 2 },
                                { 'Gap (Created - Delivered)': 2 },
                                { 'Delivered Sales, net': 2 },
                            ],
                            // Row 2
                            []
                        ];
                        this.table.bridge_settings = {
                            columns: {
                                'Visitors': { hide: true },
                                'Customers, gross': { hide: true },
                                'Conversion': { hide: true },
                                'Avg, ticket': { hide: true },
                                'Created Sales, gross': { hide: true },
                                'Created Sales, net': { hide: true },
                                'Gap (Created - Delivered)': { hide: true },
                                'Delivered Sales, net': { hide: true }
                            }
                        }
                        if (this.filter2 === 'indices') {
                            this.table.headers[1] = [
                                // Country
                                { 'Country': 1},

                                // Visitors
                                { 'YTD Index': 1, alias: 'Visitors YTD'},
                                { '4WK Index': 1, alias: 'Visitors 4WK'},

                                // Customer
                                { 'YTD Index': 1, alias: 'Customer Gross YTD'},
                                { '4WK Index': 1, alias: 'Customer Gross 4WK'},

                                // Conversion
                                { 'YTD Index': 1, alias: 'Conversion YTD'},
                                { '4WK Index': 1, alias: 'Conversion 4WK'},

                                // Avg Ticket
                                { 'YTD Index': 1, alias: 'Avg Ticket YTD'},
                                { '4WK Index': 1, alias: 'Avg Ticket 4Wk'},

                                // Created Sales, gross
                                { 'YTD Index': 1, alias: 'Created Sales Gross YTD'},
                                { '4WK Index': 1, alias: 'Created Sales Gross 4WK'},
                                
                                // Created Sales, net
                                { 'YTD Index': 1, alias: 'Created Sales Net YTD'},
                                { '4WK Index': 1, alias: 'Created Sales Net 4WK'},

                                // Gap
                                { 'YTD Index': 1, alias: 'Gap YTD'},
                                { '4WK Index': 1, alias: 'Gap 4WK'},

                                // Delivered
                                { 'YTD Index': 1, alias: 'Delivered Sales Net YTD'},
                                { '4WK Index': 1, alias: 'Delivered Sales Net 4WK'},
                            ]
                        } else {
                            this.table.headers[1] = [
                                // Country
                                { 'Country': 1},

                                // Visitors
                                { 'YTD (Millions)': 1, alias: 'Visitors YTD'},
                                { '4WK (Millions)': 1, alias: 'Visitors 4WK'},

                                // Customer
                                { 'YTD (Millions)': 1, alias: 'Customer Gross YTD'},
                                { '4WK (Millions)': 1, alias: 'Customer Gross 4WK'},

                                // Conversion
                                { 'YTD %': 1, alias: 'Conversion YTD'},
                                { '4WK %': 1, alias: 'Conversion 4WK'},

                                // Avg Ticket
                                { 'YTD EUR': 1, alias: 'Avg Ticket YTD'},
                                { '4WK EUR': 1, alias: 'Avg Ticket 4WK'},

                                // Created Sales, gross
                                { 'YTD MEUR': 1, alias: 'Created Sales Gross YTD'},
                                { '4WK MEUR': 1, alias: 'Created Sales Gross 4WK'},
                                
                                // Created Sales, net
                                { 'YTD MEUR': 1, alias: 'Created Sales Net YTD'},
                                { '4WK MEUR': 1, alias: 'Created Sales Net 4WK'},

                                // Gap
                                { 'YTD MEUR': 1, alias: 'Gap YTD'},
                                { '4WK MEUR': 1, alias: 'Gap 4WK'},

                                // Delivered
                                { 'YTD MEUR': 1, alias: 'Delivered Sales Net YTD'},
                                { '4WK MEUR': 1, alias: 'Delivered Sales Net 4WK'}
                            ]
                        }
                        this.table.rows = d.map(row => {
                            let val = row.measures;
                            let x = this.filter2 === 'indices' ? '_index' : '';
                            let temp = [];
                            this.data_names.forEach(name => {
                                temp.push(val ? val[`${name}${x}`] || 0 : 0);
                            });
                            return [row.caption].concat(temp);
                        });

                        this.table.icon_settings = {
                            'Visitors 4WK': {
                                icon: 'arrow',
                                comparison: 'Visitors YTD',
                            },
                            'Customer Gross 4WK': {
                                icon: 'arrow',
                                comparison: 'Customer Gross YTD',
                            },
                            'Conversion 4WK': {
                                icon: 'arrow',
                                comparison: 'Conversion YTD',
                            },
                            'Avg Ticket 4WK': {
                                icon: 'arrow',
                                comparison: 'Avg Ticket YTD',
                            },
                            'Created Sales Gross 4WK': {
                                icon: 'arrow',
                                comparison: 'Created Sales Gross YTD',
                            },
                            'Created Sales Net 4WK': {
                                icon: 'arrow',
                                comparison: 'Created Sales Net YTD',
                            },
                            'Gap 4WK': {
                                icon: 'arrow',
                                comparison: 'Gap YTD',
                            },
                            'Delivered Sales Net 4WK': {
                                icon: 'arrow',
                                comparison: 'Delivered Sales Net YTD',
                            }
                        };
                        this.table.render = true;
                    },
                    this.$helper.error
                );
            }
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.table.render = false;
            this.load_ingka_by_country();
        },
        update_filter2: function(filter) {
            this.filter2 = filter;
            this.table.render = false;
            this.load_ingka_by_country();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: `${this.location}/by-market`
            });
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);  
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.table.render = false;
            this.update_view(newMarket);
            this.load_ingka_by_country();
        }
    }
}
</script>

<style lang="scss">
#sales-tree-table {
    #table {
        thead {
            tr {
                &:nth-child(1) {
                    th:nth-child(7) {
                        border-left: 1rem solid white;
                    }
                }
                &:nth-child(2) {
                    th:nth-child(12) {
                        border-left: 1rem solid white;
                    }
                }
            }
        }
        tbody {
            tr {
                td:nth-child(12) {
                    border-left: 1rem solid white !important;
                }
            }
        }
    }
}
</style>
