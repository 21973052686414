<template>
<div id="executive-summary">
    <div class="columns is-gapless buttons has-addons edit-buttons inline-block ui-rounded no-left-margin is-mobile overflow-visible">
        <div class="column">
            <button class="button retail-manager is-pulled-left ml">
                <div v-if="display_name">Retail Business Navigation Team:<br/>{{display_name}}</div>
                <div v-else>No author set.</div>
            </button>
        </div>
        <div class="column is-centered text-left">
            <div class="text-very-large text-black">Executive Summary</div>
        </div>
        <div class="column is-narrow is-offset">
            <button class="button" v-if="$store.getters.check_access(['executive-summary', '*', 'r'])" @click="update_view('current')" :class="{'is-active': view === 'current'}">
                Current <i class="far fa-comments hidden-on-mobile ml"></i>
            </button>
            <button class="button" v-if="$store.getters.check_access(['executive-summary', '*', 'w']) && edit_mode" @click="update_view('new')" :class="{'is-active': view === 'new'}">
                New
            </button>
            <button class="button" v-if="$store.getters.check_access(['executive-summary', '*', 'w']) && edit_mode" @click="update_view('draft')" :class="{'is-active': view === 'draft'}">
                Draft
            </button>
            <button class="button" v-if="$store.getters.check_access(['executive-summary', '*', 'w']) && edit_mode" @click="update_view('scheduled')" :class="{'is-active': view === 'scheduled'}">
                Scheduled
            </button>
            <button class="button" v-if="$store.getters.check_access(['executive-summary', '*', 'r'])" @click="update_view('archived')" :class="{'is-active': view === 'archived'}">
                Archived<i class="fas fa-archive hidden-on-mobile ml"></i>
            </button>
            <button
                class="button presentation-mode ft-tooltip-bottom mr"
                @click="toggle_edit_mode" :class="{'active': $store.state.global.show_edit}"
            >
                <i class="fas fa-edit" :tooltiptext="$store.state.global.show_edit ? 'Finish Editing' : 'Edit'"></i>
                <i class="fas fa-slash absolute" v-show="edit_mode"></i>
            </button>
        </div>
    </div>

    <BrowserNotice />
    <div v-if="view === 'new'" class="ms">
        <New
            @toggle-edit-mode="view = 'current'"
            @updated="edit_updated"
        />
    </div>

    <div v-if="view === 'edit'" class="ms">
        <Edit
            :comment-to-edit="selected_comment"
            :status="view"

            @change_status="change_status"
            @toggle-edit-mode="view = 'current'"
            @updated="edit_updated"
        />
    </div>

    <div v-if="['current', 'draft', 'archived', 'scheduled'].includes(view)">
        <div v-show="!comments" class="comment-container">
            <p>Sorry, there are no <b>{{view}}</b> summaries.</p>
        </div>
        <div class="comment-container" v-for="(comment, i) in comments" :key="i">
            <div class="comment-title">
                <div class="summary">Summary</div>
                <div class="comment-meta">
                    From {{comment.scheduled_date.split('T')[0]}}, to {{comment.comment_lifetime.split('T')[0]}}
                </div>
            </div>
            <div class="full-width ProseMirror" v-html="seperated_comment(comment.content)[0]"></div>
            
            <div class="columns" v-show="has_footer(comment.content)">
                <div class="column is-6">
                    <div class="strengthen text-left">To Further Strengthen</div>
                    <div class="ProseMirror" v-html="seperated_comment(comment.content)[1]"></div>
                </div>
                <div class="column is-6">
                    <div class="action text-left">Call for action</div>
                    <div class="ProseMirror" v-html="seperated_comment(comment.content)[2]"></div>
                </div>
            </div>
            <div class="button-container" v-if="$store.getters.check_access(['comments', 'edit', '*', 'w']) && edit_mode">
                <div class="button edit-button is-half" @click="view = 'edit'; selected_comment = comment">
                    Edit
                </div>
                <div v-if="selected !== 'archived'" class="button edit-button is-half" @click="comment_to_edit = comment, change_status('ARCHIVED')">
                    Archive
                </div>
            </div>
            
        </div>
    </div>
</div>
</template>

<script>
import BrowserNotice from '@/components/bespoke/home/BrowserNotice.vue';
import Edit from '@/components/bespoke/executive-summary/Edit.vue';
import New from '@/components/bespoke/executive-summary/New.vue';
import SwitchBox from '@/components/ui/SwitchBox.vue';

export default {
    components:{
        BrowserNotice,
        Edit,
        New,
        SwitchBox
    },
    data: function() {
        return {
            display_name: null,
            selected: null,
            comment: null,
            comments: [],
            selected: 'current',
            filters: {
				order_by: null,
				search: null
            },
            edit_mode: false,
            comment_to_edit: null,
            view: 'current'
        }
    },
    mounted: function() {
        this.selected = 'current';
        this.$store.state.commenting.width = 0;
        this.load_comments();
    },
    destroyed: function() {
        this.$store.state.commenting.width = 4;
    },
    methods: {
        update_view: function(view) {
            this.view = view;
            if (view === 'new') return;
            this.load_comments();
        },
        toggle_edit_mode: function() {
            this.edit_mode = !this.edit_mode;
        },
        edit_updated: function(status) {
            this.edit_mode = false;
            this.view = status;
            if (status === 'LIVE') {
                this.view = 'current';
            } else {
                this.view = status.toLowerCase();
            }
            this.load_comments();
        },
        change_status: function(status) {
            this.$chttp.post('/comments/edit', {
                id: this.comment_to_edit.comment_id, 
                status
            }).then(
                succ => {
                    this.edit_updated(status);
                },
                this.$helper.error
            );
        },
        has_footer: function(comment) {
            return comment === '{\\SUMMARY\\}<p></p>{\\STRENGTHEN\\}<p></p>';
        },
        load_comments: function() {
            let settings = this.$helper.location();
            settings.status = (this.view === 'current' ? 'live' : this.view).toUpperCase();
			settings.filters = {
				order_by: this.view === 'archived' ? this.filters.order_by : null,
				search: this.view === 'archived' ? this.filters.search : null
			};

            this.comments = null;
			this.$http.post('force', '/comments/load', settings).then(
				succ => {
                    if (!succ || !succ.data || succ.data.length === 0) return;
                    this.comments = succ.data;
                    this.display_name = this.comments[0].u_display_name;
				},
				this.$helper.error
			);
        },
        seperated_comment: function(comment) {
            let sum_split = comment.split("{\\SUMMARY\\}");
            let summary = sum_split[0];
            let sub_comments = sum_split[1];

            let str_split = sub_comments.split("{\\STRENGTHEN\\}");
            let strengthen = str_split[0];
            let action = str_split[1];
            return [summary, strengthen, action];
        }
    },
    computed: {
        show_edit: function() {
            return this.$store.state.global.show_edit;
        }
    },
    watch: {
        '$route': function(to, from) {
            this.$store.commit('set_location', to.path);
        },
        show_edit: function() {
            if (this.selected !== 'current' && this.selected !== 'archived') {
                this.selected = 'current';
            }
        }
    }
}
</script>

<style lang="scss">
.action {
    color: red;
    font-size: 1.2em;
    font-weight: bold;
}
.comment-container {
    background-color: #FFF;
    box-shadow: 0px 1px 2px #969696;
    margin: 1rem;
    padding: 1.5rem 2rem;
}
.comment-meta {
    line-height: 2.3rem;
    font-weight: bold;
    font-size: 1.1rem;
}
.comment-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.edit-buttons {
    background-color: #FFF;
    border-left: none;
    border-right: none;
    margin-bottom: 0 !important;
    overflow-x: auto;
    padding: 0;
    width: 100%;
}
.edit-buttons button {
    background-color: #FFF;
    border: none;
    border-radius: 0;
    height: 3rem;
    margin: 0;
    min-width: 5rem
}
.edit-buttons button:hover {
    background-color: #CCC;
    color: #FFF;
}
.edit-buttons button.is-active {
    background-color: #CCC;
    color: #000;
}
#executive-summary {
    background-color: #5f906e;
    height: 100%;
    min-height: calc(100vh - 3.5rem);
    width: 100%;
}
.strengthen {
    color: green;
    font-size: 1.2em;
    font-weight: bold;
}
.summary {
    font-size: 1.5rem;
    font-weight: bold;
}
.retail-manager {
    cursor: default !important;
}
.tabs li.is-active a {
    color: #000000 !important;
}
.title-section {
    /* background-color: rgb(75, 126, 59); */
    color: #000;
    margin: 0 !important;
}
</style>