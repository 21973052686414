<template>
    <div
        v-show="show_notification && !!notification_content"
        class="notification"
    >
        <div class="content-wrapper">
            <p class="announcement-container">
                <i class="fas fa-exclamation-triangle is-size-4 has-text-danger"></i>
                <span
                    class="announcement-body has-text-danger"
                    v-html="notification_content"
                ></span>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            notification_content: "",
            show_notification: true,
        };
    },
    mounted: function () {
        // let show_notification = localStorage.getItem('display_home_notification_2');
        // if (show_notification) this.show_notification = JSON.parse(show_notification);
        this.fetch_home_notification_content();
    },
    methods: {
        fetch_home_notification_content: function () {
            this.$http
                .get("/landing-page/home-notification-content")
                .then((succ) => {
                    this.notification_content = succ.data;
                }, this.$helper.error);
        },
        // hide_notification: function() {
        //     this.show_notification = false;
        //     localStorage.setItem('display_home_notification_2', false);
        // }
    },
};
</script>

<style>
.content-wrapper {
    margin: auto;
    text-align: center;
}
.notification {
    margin-bottom: 0 !important;
}
.announcement-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
}
.announcement-body {
    margin-left: 10px;
}
</style>
