<template>
    <div :id="title" style="width: 100%; height:100%;"></div>
</template>

<script>
import * as echarts from 'echarts/dist/echarts.min.js';
export default {
    props: {
        title: String, //title of the graph
        showtitle: Boolean,
        subtitle: String, // subtitle of the graph
        xcategories: Array, //data that will be displayed on the x axis
        yaxislabel1: String, //name of the data on the y axis e.g 'After sales delivery share' or 'services sale by month'
        ymetric1: String, //'%', '£', 'kg' etc.
        yaxislabel2: String,
        ymetric2: String,
        yaxismin1: Number, //set the minimum value of the the y axis
        yaxismax1: Number, //set the maximum value of the the y axis
        yaxismin2: Number,
        yaxismax2: Number,
        seriesname1: String, //name of the data such as IKEA 
        seriestype1: String, //bar, line, scatter etc.
        seriesset1: Array, //actual data array to fill the graph
        color1: String, //color of the bar lie or scatter for the paticular data set
        seriesname2: String,
        seriestype2: String,
        seriesset2: Array,
        color2: String,
        seriesname3: String,
        seriestype3: String,
        seriesset3: Array,
        color3: String,
        seriesname4: String,
        seriestype4: String,
        seriesset4: Array,
        color4: String,
        seriesname5: String,
        seriestype5: String,
        seriesset5: Array,
        color5: String,
        show: Boolean, // true to show the exta features such as 'save graph as image', 'refresh', 'change to bar graph', etc
    },
    mounted: function() {
        let myChart = echarts.init(document.getElementById(this.title));
        let option = {
            title:{
                text: this.title,
                subtext: this.subtitle,
                show: this.showtitle,
                subtextStyle: {
                    color: 'black',
                },
                top: '-5em',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            toolbox: {
                top: '20em',
                right: '70em',
                show: this.show,
                feature: {
                    restore: {},
                    saveAsImage: {}
                }
            },
            grid: {
               top: '60em',
               left: '105em',
               bottom: '70em',
               right: '120em'
            },
            legend: {
                top: '20em',
                left: '0em',
                data:[this.seriesname1, this.seriesname2, this.seriesname3, this.seriesname4, this.seriesname5],
            },
            textStyle: {
                fontFamily: 'NotoIKEALatin-Regular',
            },
            xAxis: [
                {
                    type: 'category',
                    data: this.xcategories,
                    axisPointer: {
                        type: 'shadow'
                    },
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: this.yaxislabel1,
                    min: this.yaxismin1,
                    max: this.yaxismax1,
                    nameLocation: 'middle',
                    nameGap: 50,
                    axisLabel: {
                        formatter: `{value} ${this.ymetric1}`
                    }
                },
                {
                    type: 'value',
                    name: this.yaxislabel2,
                    min: this.yaxismin2,
                    max: this.yaxismax2,
                    nameLocation: 'middle',
                    nameGap: 70,
                    nameRotate: '270',
                    axisLabel: {
                        formatter: `{value} ${this.ymetric2}`
                    }
                }
            ],
            dataZoom: [
                {
                    show: this.show,
                    type: 'slider',
                    xAxisIndex: 0,
                    filterMode: 'empty',
                    left: '100em' ,
                    bottom: '0em' ,
                    handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                    handleSize: '80%', 
                    handleStyle: {
                        color: '#fff',
                        shadowBlur: 3,
                        shadowColor: 'rgba(0, 0, 0, 0.6)',
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    }
                },
                {
                    show: this.show,
                    type: 'slider',
                    yAxisIndex: 0,
                    filterMode: 'empty',
                    left: '0em' ,
                    bottom: '70em' ,
                    handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                    handleSize: '80%',
                    handleStyle: {
                        color: '#fff',
                        shadowBlur: 3,
                        shadowColor: 'rgba(0, 0, 0, 0.6)',
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    }
                },
                {
                    show: this.show,
                    type: 'slider',
                    yAxisIndex: 1,
                    filterMode: 'empty',
                    right: '0em' ,
                    bottom: '70em' ,
                    handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                    handleSize: '80%',
                    handleStyle: {
                        color: '#fff',
                        shadowBlur: 3,
                        shadowColor: 'rgba(0, 0, 0, 0.6)',
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    }
                }
            ],
            series: [
                {
                    name: this.seriesname1,
                    type: this.seriestype1,
                    data: this.seriesset1,
                    yAxisIndex: 1,
                    color: this.color1
                },
                {
                    name: this.seriesname2,
                    type: this.seriestype2,
                    data: this.seriesset2,
                    color: this.color2,
                    yAxisIndex: 0,
                    barGap: 0,
                },
                {
                    name: this.seriesname3,
                    type: this.seriestype3,
                    data: this.seriesset3,
                    yAxisIndex: 0,
                    color: this.color3
                },
                {
                    name: this.seriesname4,
                    type: this.seriestype4,
                    data: this.seriesset4,
                    yAxisIndex: 0,
                    color: this.color4
                },
                {
                    name: this.seriesname5,
                    type: this.seriestype5,
                    data: this.seriesset5,
                    yAxisIndex: 0,
                    symbol: 'none',
                    color: this.color5
                }
            ]
        };
        myChart.setOption(option);
    }
}
</script>