<template>
    <div>
        <div class="icon" v-if="icon === 'Sales'">
            <img src="assets/images/icons/sales.svg" alt="Sales"/>
        </div>
        <div class="icon" v-if="icon === 'Quantity'">
            <img src="assets/images/icons/quantity.svg" alt="Quantity"/>
        </div>
        <div class="icon" v-if="icon === 'Customer'">
            <img src="assets/images/icons/customer.svg" alt="Customer"/>
        </div>
        <div class="icon" v-if="icon === 'Visitation'">
            <img src="assets/images/icons/visitors.svg" alt="Visitor"/>
        </div>
    </div>
    </template>
    
    <script>
    export default {
        props: {
            icon: String
        },
    }
    </script>
    <style scoped>
    .icon {
        width: 4rem;
        margin: 0.5rem 0 0 0.5rem;
        height: auto;
    }
    .tagline {
        font-size: 0.8rem;
        max-width: 3.8rem;
        overflow-wrap: break-word;
        position: absolute;
    }
    </style>
    