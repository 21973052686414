var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("AdminUniversal", {
        attrs: { component: "title", props: _vm.title_props },
      }),
      _c("AdminUniversal", {
        attrs: { component: "button", props: _vm.button_props },
      }),
      _c("table", { staticClass: "table is-narrow" }, [
        _vm._m(0),
        _c(
          "tbody",
          _vm._l(_vm.table.rows, function (row, index) {
            return _c("tr", { key: index }, [
              _c("td", [_vm._v(_vm._s(row.id))]),
              _c("td", [_vm._v(_vm._s(row.user_id))]),
              _c("td", [_vm._v(_vm._s(row.name))]),
              _c("td", [_vm._v(_vm._s(row.created_at))]),
              _c("td", [
                _c(
                  "a",
                  {
                    staticClass: "button",
                    class: {
                      "is-danger": row.enabled,
                      "is-success": !row.enabled,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.update_form_status(row.id, row.enabled)
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(row.enabled ? "Disable" : "Enable") + " "
                    ),
                  ]
                ),
              ]),
            ])
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "business" }, [
        _c("th", { staticClass: "text-white" }, [_vm._v("ID")]),
        _c("th", [_vm._v("User")]),
        _c("th", [_vm._v("Name")]),
        _c("th", [_vm._v("Created At")]),
        _c("th", [_vm._v("Options")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }