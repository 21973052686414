var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !this.$store.getters.presentation_mode,
            expression: "!this.$store.getters.presentation_mode",
          },
        ],
        staticClass: "buttons",
      },
      [
        _vm._l(_vm.buttons, function (button, index) {
          return _c(
            "button",
            {
              key: index,
              class: [
                "button ikea-blue",
                { "router-link-active": _vm.activeButtonIndex === index },
              ],
              attrs: { id: _vm.formatId(button.name), "data-index": index },
              on: {
                click: function ($event) {
                  return _vm.handleClick(
                    index,
                    button.reportSection,
                    button.country_filter,
                    button.pbi_filter
                  )
                },
              },
            },
            [_vm._v(" " + _vm._s(button.name) + " ")]
          )
        }),
        this.dataset && this.dataset.headers.length
          ? _c(
              "div",
              {
                staticClass: "button download ft-tooltip",
                attrs: { tooltiptext: "Download current page" },
                on: {
                  click: function ($event) {
                    return _vm.download_xlsx()
                  },
                },
              },
              [_c("i", { staticClass: "fas fa-file-excel" })]
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }