var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("AdminUniversal", {
        attrs: { component: "title", props: _vm.title_props },
      }),
      _c("AdminUniversal", {
        attrs: { component: "dropdown", props: _vm.dropdown_props },
      }),
      _vm.table_props.render
        ? _c("AdminUniversal", {
            attrs: { component: "table", props: _vm.table_props },
          })
        : _vm._e(),
      _vm.selected_request
        ? _c("Modal", {
            class: { "is-active": _vm.is_active },
            attrs: { closeable: true, title: "Approve an Access Request" },
            on: {
              close: function ($event) {
                _vm.is_active = false
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("div", [
                        _vm._v(
                          "Request #" +
                            _vm._s(_vm.selected_request.req_id) +
                            " for " +
                            _vm._s(_vm.selected_request.first_name) +
                            " " +
                            _vm._s(_vm.selected_request.last_name)
                        ),
                      ]),
                      _c("div", [
                        _vm._v(
                          "E: " +
                            _vm._s(_vm.selected_request.email) +
                            " T: " +
                            _vm._s(_vm.selected_request.phone_number) +
                            " O: " +
                            _vm._s(_vm.selected_request.office_location)
                        ),
                      ]),
                      _c("br"),
                      _c("div", [
                        _vm._v(
                          "Current Role: " +
                            _vm._s(_vm.selected_request.job_title)
                        ),
                      ]),
                      _c("div", [
                        _vm._v(
                          "Requested Role: " +
                            _vm._s(_vm.selected_request.req_job_title)
                        ),
                      ]),
                      _c("br"),
                      _c("div", [
                        _c("span", [_vm._v("User comments")]),
                        _c("br"),
                        _vm.selected_request.extra_access.description
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.selected_request.extra_access
                                      .description
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("div", [
                              _vm._v(" No comments was left by the user. "),
                            ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c("a", { staticClass: "buttons" }, [
                        _c(
                          "a",
                          {
                            staticClass: "button is-success",
                            on: {
                              click: function ($event) {
                                return _vm.approve_request(
                                  _vm.selected_request.req_id
                                )
                              },
                            },
                          },
                          [_vm._v("Approve")]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "button is-danger",
                            on: {
                              click: function ($event) {
                                return _vm.reject_request(
                                  _vm.selected_request.req_id
                                )
                              },
                            },
                          },
                          [_vm._v("Reject")]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2172452779
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }