var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: {
          title: "Sales and Quantity per Country",
          location: _vm.location,
        },
      }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons() },
          }),
          _c(
            "div",
            {
              staticClass: "label-container",
              class: !_vm.$store.getters.market ? "ft-tooltip" : null,
              attrs: {
                tooltiptext: !_vm.$store.getters.market
                  ? "Select a country from top filter to see stores"
                  : null,
              },
            },
            [
              _c("p", [_vm._v("Market Area")]),
              _c("DropDown", {
                key: _vm.stores.values.length,
                attrs: {
                  options: _vm.stores.options,
                  values: _vm.stores.values,
                  default: _vm.stores.values[0],
                },
                on: { updateDropDown: _vm.update_store },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                key: _vm.$store.getters.market,
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: _vm.$store.getters.market,
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size,
          staticClass: "section three-quarters-height box ms",
          attrs: { id: "sales-and-quantity-trend" },
        },
        [
          _vm.triple.render
            ? _c("DeviationWith3Lines", {
                attrs: {
                  title: `Showing data for: ${_vm.$store.getters.selected_country_name}`,
                  showtitle: !_vm.$store.state.global.bot_mode,
                  subtitle: "",
                  xcategories: _vm.triple.xcategories,
                  yaxislabel1: "Sales & Sales Quantity index",
                  ymetric1: "",
                  yaxislabel2: "Gap Qty and sales YTD Index ",
                  ymetric2: "",
                  yaxismin1: -150,
                  yaxismax1: 150,
                  yaxismin2: -15,
                  yaxismax2: 15,
                  seriesname1: "Gap Qty and sales YTD Index",
                  seriestype1: "bar",
                  seriesset1: _vm.triple.seriesset3,
                  color1: _vm.$store.state.colors["dark-grey"],
                  seriesname2: "Sales YTD Index",
                  seriestype2: "line",
                  seriesset2: _vm.triple.seriesset1,
                  color2: _vm.$store.state.colors["focus-yellow"],
                  seriesname3: "Qty YTD Index",
                  seriestype3: "line",
                  seriesset3: _vm.triple.seriesset2,
                  color3: _vm.$store.state.colors["light-grey"],
                  seriesname4: "Goal",
                  seriestype4: "line",
                  seriesset4: _vm.triple.seriesset4,
                  symbol4: "none",
                  color4: _vm.$store.state.colors.YG,
                  show: !_vm.$store.state.global.bot_mode,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }