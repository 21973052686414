var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", { attrs: { title: "Market Share", location: _vm.location } }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons() },
          }),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                key: _vm.$store.getters.market,
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: _vm.$store.getters.market,
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size,
          staticClass: "section three-quarters-height box ms",
          attrs: { id: "market-share-trend" },
        },
        [
          _c("div", { staticClass: "title-text" }, [
            _c("div", { staticClass: "title-header" }, [
              _c("div", { staticClass: "blue-highlight" }, [
                _vm._v(_vm._s(_vm.$store.getters.selected_country_name)),
              ]),
              _c("div", { staticClass: "mlp25" }, [
                _vm._v("Market Share development"),
              ]),
            ]),
            _vm._m(0),
            _vm._m(1),
          ]),
          _vm.graph.render
            ? _c(
                "ForecastTrendGraph",
                _vm._b(
                  {
                    attrs: {
                      title:
                        `${_vm.$store.getters.selected_country_name}` +
                        " Market Share development",
                      showTitle: false,
                    },
                  },
                  "ForecastTrendGraph",
                  _vm.graph,
                  false
                )
              )
            : _c("div", [
                _vm._v("There is no data for "),
                _c("b", [
                  _vm._v(_vm._s(_vm.$store.getters.selected_country_name)),
                ]),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sub-title-text font-size-p8" }, [
      _vm._v("PRS's Gaming and Living with Pets are "),
      _c("div", { staticClass: "blue-highlight mlp25" }, [_vm._v("excluded")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sub-title-text font-size-p8" }, [
      _vm._v("Excluded rate used is "),
      _c("div", { staticClass: "blue-highlight mlp25" }, [_vm._v("GFAR23")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }