<template>
<div>
    <Button class="full-width mt mb" @click="back()">Cancel Changes</Button>

    <BackModal
        :back_clicked="back_clicked"
        @clear-storage="clear_storage"
        @toggle-edit-mode="$emit('toggle-edit-mode')"
        @cancel="back_clicked = false"
    />

    <DeleteModal
        :delete_clicked="delete_clicked"
        @update="update"
        @cancel="delete_clicked = false"
    />

    <div class="schedule mb">
        <SwitchBoxUniversal
            class="mb"
            label="Posting on someones behalf?"
            element_id="edit-switch"
            :boolean_item="this.commentToEdit.author_id ? true : false"
            @switch_toggle="switch_toggle"
        />
        <UserSearch
            v-if="posting_on_behalf"
            :display_name="this.commentToEdit.display_name"
            :user_id="this.commentToEdit.id"
            @SelectedUser="selected_user"
        />
        <div class="summary text-left">Summary</div>
        <WYSIWYGEditor
            :key="posting_on_behalf"
            :content="summary"
            @textUpdate="update_summary"
        />
        <div class="columns mtp5">
            <div class="column is-6 pr">
                <div class="strengthen text-left">To Further Strengthen</div>
                <WYSIWYGEditor
                    :key="posting_on_behalf"
                    :content="strengthen"
                    @textUpdate="update_strengthen"
                />
            </div>
            <div class="column is-6">
                <div class="action text-left">Call for action</div>
                <WYSIWYGEditor
                    :key="posting_on_behalf"
                    :content="action"
                    @textUpdate="update_action"
                />
            </div>
        </div>
        <p>Select the start and end dates for the comment:</p>
        <DateTimePicker
            :default="[commentToEdit.scheduled_date, commentToEdit.comment_lifetime]"
            @updated="update_scheduled_date"
        />
    </div>
    <div class="schedule info-buttons">
        <InfoButtons
            :buttons="buttons"
            :calculated_status="calculated_status"

            @update="update"
            @delete_button="delete_button"
        />
    </div>
    
</div>
</template>

<script>
import BackModal from '@/components/bespoke/commenting/BackModal.vue';
import Button from '@/components/ui/Button.vue';
import DateTimePicker from '@/components/bespoke/commenting/DateTimePicker.vue';
import DeleteModal from '@/components/bespoke/commenting/DeleteModal.vue';
import InfoButtons from '@/components/ui/InfoButtons.vue';
import SwitchBoxUniversal from '@/components/ui/SwitchBoxUniversal.vue';
import UserSearch from '@/components/admin/UserSearch.vue';
import WYSIWYGEditor from '@/components/bespoke/commenting/QuillWYSIWYGEditor.vue';

export default {
    components: {
        BackModal,
        Button,
        DateTimePicker,
        DeleteModal,
        InfoButtons,
        UserSearch,
        SwitchBoxUniversal,
        WYSIWYGEditor
    },
    props: {
        commentToEdit: Object,
        status: String,
        archive_comment: Boolean
    },
    data: function() {
        return {
            updated_comment: {
                content: localStorage[this.commentToEdit.comment_id] ? localStorage[this.commentToEdit.comment_id] : this.commentToEdit.content,
                scheduled_date: this.commentToEdit.scheduled_date.split('T')[0],
                comment_lifetime: this.commentToEdit.comment_lifetime.split('T')[0]
            },
            posting_on_behalf: null,
            back_clicked: false,
            delete_clicked: false,
            summary: '<p></p>',
            strengthen: '<p></p>',
            action: '<p></p>',
            buttons: [
                {
                    type: 'post',
                    clicked: false,
                    button: 'Comment',
                    info: `This button saves the comment to the`,
                    operation: null
                },
                {
                    type: 'draft',
                    clicked: false,
                    button: 'Save to Draft',
                    info: 'This button saves the comment to the Draft tab',
                    operation: 'DRAFT'
                },
                {
                    type: 'archive',
                    clicked: false,
                    button: 'Archive Comment',
                    info: 'This button will sent the comment to the archived tab',
                    operation: 'ARCHIVED'
                },
                {
                    type: 'delete',
                    clicked: false,
                    button: 'Delete',
                    info: `This button will delete the comment and it will be removed from Flight Tower. If a mistake is made the comment can be retrived if you contact the site's maintenaince team`,
                    operation: null
                }
            ]
        }
    },
    mounted: function() {
        let sub_comments = this.updated_comment.content.split("{\\SUMMARY\\}")[1];
        
        this.summary = this.updated_comment.content.split("{\\SUMMARY\\}")[0];
        this.strengthen = sub_comments.split("{\\STRENGTHEN\\}")[0];
        this.action = sub_comments.split("{\\STRENGTHEN\\}")[1];
            
        this.updated_comment.id = this.commentToEdit.comment_id;
        this.posting_on_behalf = this.commentToEdit.author_id ? true : false;
    },
    methods: {
        back: function() {
            if (localStorage[this.commentToEdit.comment_id]) {
                this.back_clicked = true;
            } else {
                this.$emit('toggle-edit-mode');
            }
        },
        clear_storage: function() {
            delete localStorage[this.commentToEdit.comment_id];
        },
        delete_button: function() {
            this.delete_clicked = true;
        },
        update: function(status) {
            let update = {};
            for (let key in this.updated_comment) {
                update[key] = this.updated_comment[key];
            }
            update['content'] = this.combined_content;
            if (!update.scheduled_date || !update.comment_lifetime) {
                alert('Please select and start and end date');
            } else {
                if (update.scheduled_date) { //check if first date selected is todays date
                    let today = new Date().getTime();
                    let input_date = update.scheduled_date.split('-');
                    let input_timestemp = new Date(input_date[1]+"/"+input_date[2]+"/"+input_date[0]).getTime();
                    if (status) {
                        update.status = status;
                    } else {
                        if (input_timestemp < today) {
                            update.status !== 'LIVE' ? update.status = 'LIVE' : null; //if post date is today make it live
                        } else {
                            update.status !== 'SCHEDULED' ? update.status = 'SCHEDULED' : null; //if post date in the future make it scheduled
                        }
                    }
                }
                if (Object.keys(update).length > 0) {
                    this.$chttp.post(`/comments/edit`, update).then(
                        succ => {
                            this.$emit('updated', update.status);
                            this.clear_storage();
                        },
                        error => {
                            console.log(error);
                        }
                    );
                }
            }
        },
        update_summary: function(text) {
            this.summary = text;
            this.update_storage();
        },
        update_strengthen: function(text) {
            this.strengthen = text;
            this.update_storage();
        },
        update_action: function(text) {
            this.action = text;
            this.update_storage();
        },
        update_storage: function() {
            localStorage[this.commentToEdit.comment_id] = this.combined_content;
        },
        update_scheduled_date: function(date) {
            this.updated_comment.scheduled_date = date[0];
            this.updated_comment.comment_lifetime = date[1];
        },
        selected_user: function(user) {
            this.updated_comment.author_id = user.id;
        },
        switch_toggle: function() {
            this.posting_on_behalf = !this.posting_on_behalf;
            if (!this.posting_on_behalf) this.updated_comment.author_id = null;
        }
    },
    computed: {
        combined_content: function() {
            return `${this.summary}{\\SUMMARY\\}${this.strengthen}{\\STRENGTHEN\\}${this.action}`;
        },
        calculated_status: function() {
            let today = new Date().getTime();
            if (this.updated_comment.scheduled_date) {
                let input_date = this.updated_comment.scheduled_date.split('-');
                let input_timestemp = new Date(input_date[1]+"/"+input_date[2]+"/"+input_date[0]).getTime();
                if (input_timestemp < today) {
                    return 'LIVE' //if post date is today make it live
                } else {
                    return 'SCHEDULED' //if post date in the future make it scheduled
                }
            }
        }    
    }
}
</script>

<style scoped>
.buttons.is-centered:not(.has-addons) .button:not(.is-fullwidth) {
    margin: 0;
}
.character-alert {
    background-color: #ff0000;
    color:#f5f5f5;
}
.info-buttons {
    padding: 1rem 0.5rem !important;
}
.is-centered {
    justify-content: space-between !important;
}
.section {
    background-color: rgb(245, 245, 245);
}
.schedule {
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fbfbfb;
    padding: 1rem 1.5rem;
}
</style>