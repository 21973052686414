<template>
<div>
    <div class="button is-success mt button-left" @click="toggle_new_modal()">Add new or change responsible for an insights title</div>
    <Modal
        v-if="show_responsibles_modals.new"
        :closeable="true"
        title="Add new Responsible for an insights title"
        
        :class="{'is-active': show_responsibles_modals.new}"
        @close="toggle_new_modal()"
    >
        <template v-slot:content>
            <div v-if="!edit_buttons.user" class="text-left">
                Please select a user
                <UserSearch @SelectedUser="selected_user" />
            </div>
            <div v-else class="text-left flex">
                <div class="selected-user"><b>User Selected:</b> {{new_responsible.user_name}}</div>
                <div class="button is-info" @click="edit_button_click('user')">Change</div>
            </div>
            <div v-if="!edit_buttons.title">
                <AdminUniversal
                    v-if="new_responsible.user_name"
                    component="dropdown"
                    :props="dropdown_props1"
                />
                <table v-if="title_rows.length && new_responsible.user_name">
                    <tr>
                        <th>
                            Select a Title
                        </th>
                    </tr>
                    <tr v-for="(row, i) in title_rows" :key="i">
                        <td class="title-hover cursor-pointer" @click="select_row(row)">
                            {{row.title}}
                        </td>
                    </tr>
                </table>
            </div>
            <div v-else class="text-left flex">
                <div class="selected-user"><b>Title Selected:</b> {{new_responsible.title}}</div>
                <div class="button is-info" @click="edit_button_click('title')">Change</div>
            </div>
            <div v-if="!edit_buttons.country">
                <AdminUniversal
                    v-if="new_responsible.title_id"
                    component="dropdown"
                    :props="dropdown_props2"
                />
            </div>
            <div v-else class="text-left flex">
                <div class="selected-user"><b>Country Selected:</b> {{new_responsible.title}}</div>
                <div class="button is-info" @click="edit_button_click('title')">Change</div>
            </div>

        </template>
        <template v-slot:buttons>
            <a class="buttons">
                <a class="button is-success" @click="add_new_responsible()">Add</a>
                <a class="button is-danger" @click="toggle_new_modal()">Cancel</a>
            </a>
        </template>
    </Modal>
    <div class="flex">
        <AdminUniversal
            component="dropdown"
            :props="dropdown_props3"
        />

        <AdminUniversal
            class="ml"
            component="dropdown"
            :props="dropdown_props4"
        />
    </div>
    <table v-if="this.$store.getters.countries.values">
        <tr>
            <th v-for="(header, i) in responsibles_headers" :key="i" class="capitalize">
                {{header}}
            </th>
        </tr>
        <tr v-for="(row, i) in filtered_responsibles_rows" :key="i">
            <td v-for="(header, i) in responsibles_headers" :key="i">
                <div v-if="header === 'country'">{{return_country_name(row[header])}}</div>
                <div v-else>{{row[header]}}</div>
            </td>
            <td>
                <div class="button is-danger" @click="remove_responsible(row.responsible_id)">Remove</div>
            </td>
        </tr>
    </table>
</div>
</template>

<script>
import AdminUniversal from '@/components/admin/index.vue';
import Modal from '@/components/ui/Modal.vue';
import UserSearch from '@/components/admin/UserSearch.vue';

export default {
    components: {
        AdminUniversal,
        Modal,
        UserSearch
    },
    data: function() {
        return {
            new_responsible: {
                area: null,
                user_id: null,
                user_name: null,
                title_id: null,
                title: null,
                country: 'Total Retail Countries',
                country_code: 'null'
            },
            edit_buttons: {
                user: false,
                title: false,
                country: false
            },
            show_responsibles_modals: {
                new: false,
                edit: false,
                remove: false
            },
            dropdown_props1: {
                selection: 'Introduction',
                options: ['Introduction', 'Better Homes', 'Better Company', 'Better Lives', 'Better Planet'],
                label: 'Select an area',
                callback: this.update_dropdown_selection1
            },
            dropdown_props2: {
                selection: '',
                options: [''],
                label: 'Select a country',
                callback: this.update_dropdown_selection2
            },
            dropdown_props3: {
                selection: 'ALL',
                options: ['ALL', 'Introduction', 'Better Homes', 'Better Company', 'Better Lives', 'Better Planet'],
                label: 'Filter by area',
                callback: this.update_dropdown_selection3
            },
            dropdown_props4: {
                selection: 'ALL',
                options: ['ALL', 'Global', 'Non-Global'],
                label: 'Filter by Global Users',
                callback: this.update_dropdown_selection4
            },
            title_rows: [],
            responsibles_headers: ['title', 'country', 'area','responsible'],
            responsibles_rows: []
        }
    },
    mounted: function() {
        this.search_and_get_responsibles();
        this.search_and_get_titles();
        if (this.$store.getters.countries.options) {
            this.dropdown_props2.selection = this.$store.getters.countries.options[0];
            this.dropdown_props2.options = this.$store.getters.countries.options;
        }
    },
    methods: {
        add_new_responsible: function() {
            if (!this.new_responsible.user_id || !this.new_responsible.title_id || !this.new_responsible.country_code) {
                alert(`Missing: 
                    ${!this.new_responsible.user_id ? 'User' : ''}
                    ${!this.new_responsible.title_id ? 'Title' : ''}
                    ${!this.new_responsible.country_code ? 'Country' : ''}
                `);
                return;
            }
            this.$http.post('/admin/insights-responsibles/add', {
                user_id: this.new_responsible.user_id,
                title_id: this.new_responsible.title_id,
                country_code: this.new_responsible.country_code
            }).then(
                succ => {
                    this.toggle_new_modal();
                    this.search_and_get_responsibles();
                },
                this.$helper.error
            );
        },
        toggle_new_modal: function() {
            this.show_responsibles_modals.new = !this.show_responsibles_modals.new;
        },
        search_and_get_responsibles: function() {
            this.responsibles_rows = [];
            this.$http.post('force', '/admin/insights-responsibles/search', {
                area: this.dropdown_props3.selection
            }).then(
                succ => {
                    let d = succ.data;
                    if (!succ || !d) {
                        this.responsibles_rows = [];
                        return;
                    }
                    this.responsibles_rows = d;
                },
                this.$helper.error
            );
        },
        search_and_get_titles: function() {
            this.$http.post('/admin/insights-templates/search', {
                area: this.dropdown_props1.selection
            }).then(
                succ => {
                    let d = succ.data;
                    if (!succ || !d) {
                        this.title_rows = [];
                        return;
                    }
                    this.title_rows = d;
                },
                this.$helper.error
            );
        },
        selected_user: function(user) {
            this.new_responsible.user_id = user.id;
            this.new_responsible.user_name = user.display_name;
            this.edit_buttons.user = true;
        },
        edit_button_click: function(type) {
            this.edit_buttons[type] = false;
        },
        select_row: function(title) {
            this.edit_buttons.title = true;
            this.new_responsible.title_id = title.id;
            this.new_responsible.title = title.title;
        },
        update_dropdown_selection1: function(selection) {
            this.dropdown_props1.selection = selection;
            this.search_and_get_titles();
        },
        update_dropdown_selection2: function(selection) {
            this.new_responsible.country = selection;
            this.new_responsible.country_code = this.$store.getters.countries.values[this.$store.getters.countries.options.indexOf(selection)];
        },
        update_dropdown_selection3: function(selection) {
            this.dropdown_props3.selection = selection;
            this.search_and_get_responsibles();
        },
        update_dropdown_selection4: function(selection) {
            this.dropdown_props4.selection = selection;
        },
        return_country_name: function(country_code) {
            return this.$store.getters.countries.options[this.$store.getters.countries.values.indexOf(country_code)];
        },
        remove_responsible: function(id) {
            this.$http.post('/admin/insights-responsibles/remove', {
                id: id
            }).then(
                succ => {
                    this.search_and_get_responsibles();
                },
                this.$helper.error
            );
        }
    },
    computed: {
        filtered_responsibles_rows: function() {
            if (this.dropdown_props4.selection === 'Global') return this.responsibles_rows.filter(row => row.country === 'null')
            else if (this.dropdown_props4.selection === 'Non-Global') return this.responsibles_rows.filter(row => row.country !== 'null');
            else return this.responsibles_rows;
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.dropdown_props2.selection = this.$store.getters.countries.options[0];
            this.dropdown_props2.options = this.$store.getters.countries.options;
        }
    },
}
</script>

<style scoped>
.button-left {
    text-align: left;
    display: block;
    width: fit-content;
}

.title-hover:hover {
    color: #3273dc;
    background-color: #e2e2e2;
}

.selected-user {
    padding-top: 0.4rem;
    margin-right: 1rem;
}

th {
    padding-right: 1rem;
}

tr:nth-child(even) {
    background-color: #f5f5f5;
}

td {
    padding: 0.25rem 0.5rem;
}


</style>