<template>
<div>
    <Title
        title="Top Families Growth"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div class="text-left ml">Showing data for: {{$store.getters.selected_country_name}}</div>
    <Table
        v-if="table.render"

        :headers="table.headers"
        :rows="table.rows"

        :icon-settings="table.icon_settings"
        :sort-settings="table.sort_settings"

        class="ms1p5"
    />
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            table: {
                render: false,
                headers: [],
                rows: [],
            },
            location: '/business/sales-per-range/top-families-growth',
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.market_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'Top 10 Turnover',
                    link: `${this.location}/top-10-turnover`
                },
                {
                    name: 'Top 10 Quantity',
                    link: `${this.location}/top-10-quantity`
                }
            ];
        },
        market_data: function() {
            this.$http.post('/process/top-families-growth/top-10-quantity', {
                market: this.$store.getters.market,
                filter: '20'
            }).then(
                succ => {
                    let d = succ.data;
                    if (!d || d.length === 0) return;

                    this.table.headers, this.table.rows = [];
                    let keys = Object.keys(d[0].measures[0]);
                    keys.splice(7, 1); //remove columns which we havent built the logic for yet
                    keys.splice(5, 1);
                    this.table.headers = [
                        keys.map(k => ({[k]: 1}))
                    ];
                    
                    Object.keys(d[0].measures).forEach(row => {
                        let temp = [];
                        keys.map(key => {
                            temp.push(this.$helper.maybe(d[0].measures[row][key]));
                        });
                        this.table.rows.push(temp)
                    });
 
                    this.table.icon_settings = {
                        // 'Latest 4 Weeks': { will be re added at a later date
                        //     icon: 'arrow',
                        //     comparison: 'SP Goal Index'
                        // },
                        // 'SP Goal Index': {
                        //     icon: 'circle',
                        //     comparison: 'YTD Index',
                        //     reversed: true
                        // },
                        'MQTY': { 
                            icon: 'bar',
                            comparison: 'MQTY'
                        }
                    }

                    this.table.sort_settings = {
                        'Rank': { asc: true }
                    }
                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.table.render = false;
            this.market_data();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.table.render = false;
            this.market_data();
        }
    }
}
</script>
