<template>
    <div :id="title" style="height: 100%; width: 100%;"></div>
</template>
<script>
import * as echarts from 'echarts/dist/echarts.min.js';
export default {
    data: function() {
        return {}
    },
    props: {
        title: String, //title of the graph
        showtitle: Boolean,
        subtitle: String, //subtitle of the graph
        xcategories: Array, //data that will be displayed on the x axis
        yaxislabel: String, //name of the data on the y axis e.g 'After sales delivery share' or 'services sale by month'
        ymetric: String, //'%', '£', 'kg' etc.
        
        seriesname1: String, //name of the data such as IKEA
        seriestype1: String, //bar, line, scatter etc.
        seriesset1: Array, //actual data array to fill the graph
        color1: String, //color of the bar lie or scatter for the paticular data set
        
        seriesname2: String,
        seriestype2: String,
        seriesset2: Array,
        color2: String,
        
        seriesname3: String,
        seriestype3: String,
        seriesset3: Array,
        color3: String,
        
        seriesname4: String,
        seriestype4: String,
        seriesset4: Array,
        color4: String,

        ranking: Array,
        competitors: Array,
        trend: Boolean,
        trendInvert: Boolean,
        show: Boolean, // true to show the exta features such as 'save graph as image', 'refresh', 'change to bar graph', etc
    },
    mounted: function() {
        let myChart = echarts.init(document.getElementById(this.title));
        let div_width = document.getElementById(this.title).clientWidth;
        let width_factor = 1.3;
        let country_competitor = [];
        let above = [];
        let below = [];
        const push_above = (data) => {
            above.push(data);
            below.push(null);
        }
        const push_below = (data) => {
            below.push(data);
            above.push(null);
        }
        if (this.trend) {
            for (let i = 0; i < this.seriesset3.length; i++) {
                if (this.seriesset3[i] >= parseFloat(this.seriesset1[i])) {
                    push_above(this.seriesset3[i]);
                } else {
                    push_below(this.seriesset3[i]);
                }
            }
        }
        this.xcategories.forEach(category => {
            country_competitor.push(category.replace(/ /g, "\n").replace(/Total\nRetail/g, "Total Retail"));
        });
        let option = {
            title: {
                text: this.title,
                subtext: this.subtitle,
                show: this.showtitle,
                subtextStyle: {
                    color: 'black',
                },
                top: '-2em',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            toolbox: {
                top: '85em',
                left: '0em',
                show: this.show,
                feature: {
                    restore: {},
                    saveAsImage: {}
                }
            },
            grid: {
               top: '100em',
               left: '75em',
               width: 'auto',
               bottom: '30em'
            },
            legend: {
                top: '25em',
                left: '5em',
                itemGap: 30,
                data:[
                    this.seriesname1,
                    this.seriesname2,
                    this.trend ? null : this.seriesname3,
                    this.trend ? `${this.seriesname3} above ${this.seriesname1}` : null,
                    this.trend ? `${this.seriesname3} below ${this.seriesname1}`: null,
                    this.seriesname4
                ]
            },
            textStyle: {
                fontFamily: 'NotoIKEALatin-Regular',
                fontSize: 14,
            },
            xAxis: [{
                type: 'category',
                data: country_competitor,
                axisLabel: {
                    interval: '0',
                },
                axisPointer: {
                    type: 'shadow'
                },
            }],
            yAxis: [
                {
                    type: 'value',
                    logBase: 2,
                    name: this.yaxislabel,
                    nameLocation: 'middle',
                    nameGap: 60,
                    axisLabel: {
                        formatter: `{value} ${this.ymetric}`
                    }
                }
            ],
            series: [
                {
                    name: this.seriesname1,
                    type: this.seriestype1,
                    data: this.seriesset1,
                    color: this.color1,
                    barGap: '0',
                    label: {
                        show: this.seriestype1 === 'bar' ? true : false,
                        align: 'center',
                        verticalAlign: 'middle',
                        z: 2,
                        position: 'insideTop',
                        distance: 20,
                        color: 'black',
                    },
                },
                {
                    name: this.seriesname2,
                    type: this.seriestype2,
                    data: this.seriesset2,
                    color: this.color2,
                    barGap: '0',
                    label: {
                        show: this.seriestype2 === 'bar' ? true : false,
                        align: 'center',
                        verticalAlign: 'middle',
                        position: 'insideTop',
                        distance: 20,
                        color: 'black',
                    },
                },
                this.scatter(false, true, this.seriesset3, div_width, width_factor, this.color3),
                {
                    name: this.seriesname4,
                    type: this.seriestype4,
                    data: this.seriesset4,
                    color: this.color4,
                    symbol: this.seriestype4 === 'scatter' ? 'rect' : 'none',
                    symbolSize: this.seriestype4 === 'scatter' ? [(div_width / (this.xcategories.length * width_factor)), 2] : 10,
                    hoverAnimation: false,
                    label: {
                        show: this.seriestype4 === 'bar' ? true : false,
                        align: 'center',
                        verticalAlign: 'middle',
                        position: 'insideTop',
                        distance: 20,
                        color: 'black',
                    },
                    markPoint: this.year_goal_label(this.seriesname4, this.seriestype4, this.seriesset4)
                },
                this.scatter(true, true, above, div_width, width_factor, `${this.trendInvert? 'red' : 'green'}`),
                this.scatter(true, false, below, div_width, width_factor, `${this.trendInvert? 'green' : 'red'}`)
            ]
        };
        myChart.setOption(option);
    },
    methods: {
        year_goal_label: function(name, type, set) {
            if (name === 'Year Goal' && type === 'line') {
                let length = set.length;
                return {
                    symbolOffset: ['40em', '-20em'],
                    symbolSize: 1,
                    label: {
                        color: 'black',
                        fontSize: 12,
                    },
                    data: [{value: `${name}: ${set[length - 1]}${this.ymetric}`, xAxis: length - 1, yAxis: set[length - 1]}]
                }
            }
        },
        scatter: function(comparison, above, dataset, div_width, width_factor, color) {
            if (comparison === this.trend) {
                return  {
                    name: this.trend ? above ? `${this.seriesname3} above ${this.seriesname1}` : `${this.seriesname3} below ${this.seriesname1}`: this.seriesname3,
                    type: this.seriestype3,
                    data: dataset,
                    color: color,
                    symbol: this.seriestype3 === 'scatter' ? 'rect' : 'circle',
                    symbolSize:  this.seriestype3 === 'scatter' ? [(div_width / (this.xcategories.length * width_factor * 3)), 6] : 10,
                    barGap: '0',
                    label: {
                        show: this.seriestype3 === 'bar' ? true : false,
                        align: 'center',
                        verticalAlign: 'middle',
                        position: 'insideTop',
                        distance: 20,
                        color: 'black',
                    },
                }
            } else { 
                return null
            }
            
        }
    }
}
</script>
<style scoped>
.overall-container {
    position: relative;
}
.ranking-container {
    height: 2.5vw;
    margin-right: 10%;
    position: absolute;
    top: 12vh;
    width: 100%;
    margin-left: 0;
    font-size: 0.8vw;
}
.ranking-label {
    width: 3em;
    height: 3em;
    padding-top: 0.75em;
    margin-left: 0;
    border-radius: 100%;
    float: left;
    font-weight: bold;
}
.ranking-label:first-child{
    margin-left: 76%;
}
.ranking-box {
    width: calc((90% - 3.5vw)/9);
    height: 2.5vw;
    float: left;
    position: relative;
}
.name-section {
    margin-left: 3.5vw;
    height: 4em;
    margin-right: 10%
}
.name-container {
    width: calc((100%)/9);
    float: left;
    position: relative;
    top: 1vh;
}
.competitor-1 {
    color: lightgreen;
}
.competitor-2 {
    color: grey
}
</style>
