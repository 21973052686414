var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: {
          title: "Perceived First Contact Resolution",
          location: _vm.location,
        },
      }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons() },
          }),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                key: _vm.$store.getters.market,
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: _vm.$store.getters.market,
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size,
          staticClass: "section three-quarters-height box ms",
        },
        [
          _vm.quad.render
            ? _c("Quad", {
                key: _vm.quad.render,
                attrs: {
                  title: `Showing data for: ${_vm.$store.getters.selected_country_name}`,
                  showtitle: !_vm.$store.state.global.bot_mode,
                  subtitle: "",
                  xcategories: _vm.quad.xcategories,
                  yaxislabel: "% Perceived First Contact Resolution",
                  ymetric: "",
                  seriesname1: "YTD",
                  seriestype1: "bar",
                  seriesset1: _vm.quad.seriesset1,
                  color1: _vm.$store.state.colors["yellow"],
                  seriesname2: "TY Month Trend",
                  seriestype2: "bar",
                  seriesset2: _vm.quad.seriesset2,
                  color2: _vm.$store.state.colors["grey"],
                  seriesname3: "LY Month Trend",
                  seriestype3: "line",
                  seriesset3: _vm.quad.seriesset3,
                  color3: _vm.$store.state.colors["dark-grey"],
                  seriesname4: "Year Goal",
                  seriestype4: "line",
                  seriesset4: _vm.quad.seriesset4,
                  color4: _vm.$store.state.colors.YG,
                },
              })
            : _c("div", [
                _vm._v(
                  " No data found for " +
                    _vm._s(_vm.$store.getters.selected_country_name)
                ),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }