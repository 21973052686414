<template>
<div>
    <Button class="full-width mb mt" @click="back()">Cancel</Button>

    <BackModal
        :back_clicked="back_clicked"
        @clear-storage="clear_storage"
        @toggle-edit-mode="$emit('toggle-edit-mode')"
        @cancel="back_clicked = false"
    />

    <div class="schedule mb">
        <SwitchBoxUniversal
            class="center-flex mb"
            label="Posting on someones behalf?"
            element_id="new-switch"
            @switch_toggle="switch_toggle"
        /> 
        <UserSearch
            v-if="posting_on_behalf"
            class="center-flex"
            @SelectedUser="selected_user"
        />
        <br>
        <DropDown
            v-if="title_options.options.length > 1"

            :key="title_options.options[0]"
            :options="title_options.options"
            :values="title_options.values"
            :default="title_options.values[0]"
            @updateDropDown="select_title"

            class="center-flex mb"
        />

        <WYSIWYGEditor
            @textUpdate="update_content"
            :content="new_comment.content"
        />
        <p>Select the start and end dates for the comment:</p>
        <DateTimePicker
            @updated="update_scheduled_date"
        />
    </div>

    <InfoButtons
        :buttons="buttons"
        :calculated_status="calculated_status"
        @update="create_new"
    />
</div>
</template>

<script>
import BackModal from '@/components/bespoke/commenting/BackModal.vue';
import Button from '@/components/ui/Button.vue';
import DateTimePicker from '@/components/bespoke/commenting/DateTimePicker.vue';
import DropDown from '@/components/ui/DropDown.vue';
import InfoButtons from '@/components/ui/InfoButtons.vue';
import SwitchBoxUniversal from '@/components/ui/SwitchBoxUniversal.vue';
import UserSearch from '@/components/admin/UserSearch.vue';
import WYSIWYGEditor from '@/components/bespoke/commenting/QuillWYSIWYGEditor.vue';

export default {
    props: {
        kpi: String,
        insights_titles: Object
    },
    components: {
        BackModal,
        Button,
        DateTimePicker,
        DropDown,
        InfoButtons,
        SwitchBoxUniversal,
        UserSearch,
        WYSIWYGEditor
    },
    data: function() {
        return {
            new_comment: {
                status: null,
                title_id: null,
                content: localStorage['new_comment'],
                scheduled_date: null,
                comment_lifetime: null,
                author_id: null
            },
            posting_on_behalf: false,
            back_clicked : false,
            buttons: [
                {
                    type: 'post',
                    clicked: false,
                    button: 'Comment',
                    info: `This button saves the comment to the`,
                    operation: null
                },
                {
                    type: 'draft',
                    clicked: false,
                    button: 'Save to Draft',
                    info: 'This button saves the comment to the Draft tab',
                    operation: 'DRAFT'
                },
            ],
            title_options: {
                options: ['Please select a title'],
                values: [null]
            }
        }
    },
    mounted: function() {
        this.title_options.options = this.insights_titles.options;
        this.title_options.values = this.insights_titles.values;
    },
    methods: {
        back: function() {
            if (localStorage['new_comment']) {
                this.back_clicked = true;
            } else {
                this.$emit('toggle-edit-mode');
            }
        },
        create_new: function(status) {
            if (!this.new_comment.scheduled_date || !this.new_comment.comment_lifetime) {
                alert('Please select and start and end date');
            } else if (!this.new_comment.title_id) {
                alert('Please select a title from the dropdown');
            } else {
                if (this.new_comment.scheduled_date && !status) { //check if first date selected is todays date
                    let today = new Date().getTime();
                    let input_date = this.new_comment.scheduled_date.split('-')

                    let input_timestemp = new Date(input_date[1]+"/"+input_date[2]+"/"+input_date[0]).getTime();

                    if (input_timestemp < today) {
                        this.new_comment.status = 'LIVE'; //if post date is today make it live
                    } else {
                        this.new_comment.status = 'SCHEDULED'; //if post date in the future make it scheduled
                    }
                } else if (status) {
                    this.new_comment.status = status;
                }
                if (Object.keys(this.new_comment).length > 0) {
                    this.$chttp.post(`/comments/new`, {
                        comment: this.new_comment,
                        location: this.$helper.location()
                    }).then(
                        succ => {
                            this.$emit('updated', this.new_comment.status);
                            delete localStorage['new_comment'];
                        },
                        error => {
                            console.log(error);
                        }
                    );
                }
            }
        },
        clear_storage: function() {
            delete localStorage['new_comment'];
        },
        update_content: function(text) {
            this.new_comment.content = text;
            localStorage['new_comment'] = text;
        },
        update_scheduled_date: function(date) {
            this.new_comment.scheduled_date = date[0];
            scheduled_date: null,
            this.new_comment.comment_lifetime = date[1];
        },
        select_title: function(title_id) {
            this.new_comment.title_id = title_id;
        },
        selected_user: function(user) {
            this.new_comment.author_id = user.id;
        },
        switch_toggle: function() {
            this.posting_on_behalf = !this.posting_on_behalf;
            if (!this.posting_on_behalf) this.new_comment.author_id = null;
        }
    },
    computed: {
        calculated_status: function() {
            if (this.new_comment.scheduled_date) {
                let today = new Date().getTime();
                let input_date = this.new_comment.scheduled_date.split('-');
                let input_timestemp = new Date(input_date[1]+"/"+input_date[2]+"/"+input_date[0]).getTime();
                if (input_timestemp < today) {
                    return 'LIVE' //if post date is today make it live
                } else {
                    return 'SCHEDULED' //if post date in the future make it scheduled
                }
            }
        }
    }
}
</script>

<style scoped>
.is-centered {
    justify-content: space-between !important;
}
.buttons.is-centered:not(.has-addons) .button:not(.is-fullwidth) {
    margin: 0;
}
.center-flex {
    width: fit-content;
    margin: auto;
}
.section {
    background-color: rgb(245, 245, 245);
}
.schedule {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.5em;
    background-color: #fbfbfb;
}
</style>