<template>
<div class="container">
    <AdminUniversal
        component="title"
        :props="title_props"
    />
    <AdminUniversal
        component="textinput"
        :props="input_props"
    />
    <AdminUniversal
        component="button"
        :props="button_props1"
    />
    <AdminUniversal
        component="button"
        :props="button_props2"
    />
</div>
</template>

<script>
import AdminUniversal from '@/components/admin/index.vue';

export default {
    components: {
        AdminUniversal
    },
    data: function() {
        return {
            title_props: {
                title: 'New Question',
            },
            input_props: {
                input: '',
                label: '',
                placeholder: 'Enter a Question',
                callback: this.update_input_question
            },
            button_props1: {
                label: 'Add Question',
                classes: 'is-success',
                method: this.add_question
            },
            button_props2: {
                label: 'Cancel',
                classes: 'is-danger',
                method: this.back
            },
            question: null
        }
    },
    methods: {
        add_question: function() {
            if (this.question || this.question.trim() !== '') {
                this.$http.post('force', '/nps/questions/question/new', {
                    question: this.question
                }).then(
                    succ => {
                        this.question = null;
                        this.$router.push('/admin/nps/questions');
                    },
                    err => console.log(err)
                );
            } else {
                alert('Please enter a question for you save it.')
            }
        },
        back: function() {
            this.$router.push('/admin/nps/questions')
        },
        update_input_question: function(input) {this.question = input;}
    }
}
</script>