<template>
<div>
    <div class="button is-success mt button-left" @click="toggle_new_modal()">Add new Reminder</div>

    <div>* All Reminders are sent at 8am CET*</div>
    <div>* Global Users are the ones responsible for the Total Ingka Retail Insights*</div>
    <Modal
        v-if="show_reminder_modal"
        :closeable="true"
        title="Add new Reminder"
        
        :class="{'is-active': show_reminder_modal}"
        @close="toggle_new_modal()"
    >
        <template v-slot:content>
            <DateTimePicker
                :singleDate="true"
                @updated="update_reminder_date"
            />
            <label class="checkbox mt">
                <input type="checkbox" @click="check_global_user()">
                Reminder for Global Insights
            </label>
        </template>
        <template v-slot:buttons>
            <a class="buttons">
                <a class="button is-success" @click="add_new_reminder()">Add</a>
                <a class="button is-danger" @click="toggle_new_modal()">Cancel</a>
            </a>
        </template>
    </Modal>
    <AdminUniversal
        component="dropdown"
        :props="dropdown_props1"
        class="mt"
    />
    <table v-if="reminders_rows.length">
        <tr>
            <th v-for="(header, i) in reminders_headers" :key="i" class="capitalize">
                {{formatted_header(header)}}
            </th>
        </tr>
        <tr v-for="(row, i) in reminders_rows" :key="i">
            <td v-for="(header, i) in reminders_headers" :key="i">
                <div v-if="header === 'reminder_date'">{{row[header].split('T')[0]}}</div>
                <div v-else>{{row[header]}}</div>
            </td>
            <td>
                <div class="button is-danger" @click="remove_reminder(row.id)">Remove</div>
            </td>
        </tr>
    </table>
</div>
</template>

<script>
import AdminUniversal from '@/components/admin/index.vue';
import DateTimePicker from '@/components/bespoke/commenting/DateTimePicker.vue';
import Modal from '@/components/ui/Modal.vue';

export default {
    components: {
        AdminUniversal,
        DateTimePicker,
        Modal
    },
    data: function() {
        return {
            show_reminder_modal: false,
            new_reminder: {
                global: false,
                reminder_date: null
            },
            reminders_headers: ['global', 'reminder_date'],
            reminders_rows: [],
            dropdown_props1: {
                selection: 'ALL',
                options: ['ALL', 'Global', 'Non-Global'],
                label: 'Select type of reminder',
                callback: this.update_dropdown_selection1
            },
        }
    },
    mounted: function() {
        this.search_and_get_reminders();
    },
    methods: {
        add_new_reminder: function() {
            this.$http.post('/admin/insights-reminders/add', {
                global: this.new_reminder.global,
                reminder_date: this.new_reminder.reminder_date,
            }).then(
                succ => {
                    this.toggle_new_modal();
                    this.search_and_get_reminders();
                },
                this.$helper.error
            );
        },
        search_and_get_reminders: function() {
            let global = 'ALL';
            if (this.dropdown_props1.selection !== 'ALL') global = this.dropdown_props1.selection === 'Global' ? true : false
            this.$http.post('force', '/admin/insights-reminders/search', {
                global
            }).then(
                succ => {
                    let d = succ.data;
                    if (!succ || !d) {
                        this.reminders_rows = [];
                        return;
                    }
                    d.sort(function(a,b){
                        return new Date(b.reminder_date) - new Date(a.reminder_date);
                    });
                    this.reminders_rows = d;
                },
                this.$helper.error
            );
        },
        toggle_new_modal: function() {
            this.show_reminder_modal = !this.show_reminder_modal;
            if (!this.show_reminder_modal) {
                this.new_reminder = {
                    global: false,
                    reminder_date: null
                }
            }
        },
        check_global_user: function() {
            this.new_reminder.global = !this.new_reminder.global;
        },
        update_reminder_date: function(date) {
            this.new_reminder.reminder_date = date[0];
        },
        update_dropdown_selection1: function(selection) {
            this.dropdown_props1.selection = selection;
            this.search_and_get_reminders();
        },
        remove_reminder: function(id) {
            this.$http.post('/admin/insights-reminders/remove', {
                id
            }).then(
                succ => {
                    this.search_and_get_reminders();
                },
                this.$helper.error
            );
        },
        formatted_header: function(unformatted_header) {
            if (!unformatted_header) return;
            let formatted_header = unformatted_header.replace('_', ' ');
            return formatted_header;
        }
    },
    watch: {

    },
}
</script>

<style scoped>
.button-left {
    text-align: left;
    display: block;
    width: fit-content;
}

.title-hover:hover {
    color: #3273dc;
    background-color: #e2e2e2;
}

.selected-user {
    padding-top: 0.4rem;
    margin-right: 1rem;
}

th {
    padding-right: 1rem;
}

tr:nth-child(even) {
    background-color: #f5f5f5;
}

td {
    padding: 0.25rem 0.5rem;
}


</style>