<template>
    <div class="full-page">
        <div class="title mt">Announcements</div>

        <div v-for="(announcment, i) in announcements" :key="i" class="box" style="margin: 1rem;">
            <div class="ms pbp5">{{announcment.main}}</div>
            <img class="image-resizing" :src="announcment.image"/>
            <div v-if="announcment.sub" class="ms">{{announcment.sub}}</div>
        </div>
    </div>
</template>
<script>

export default {
    data: function() {
        return {
            announcements:
            [
                {
                    main: 'Did you know that Flight Tower can be accessed through your phone?',
                    sub: 'Try it out and leave us feedback!',
                    image: 'assets/images/presentation/mobile.png'
                },
            ]
        }
    }
}
</script>
<style scoped>
.full-page {
    position: absolute;
    z-index: 3;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    font-size:1.8rem;
}

.image-resizing {
    height: 5rem;
    width: 5rem;
}
</style>