<template>
<div>
    <Title
        title="Store Fulfilment (Flows 3-12)"
        :location="location"
    />

    <div class="options-bar">
        <Buttons
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <Table
        v-if="table.render"

        :headers="table.headers"
        :rows="table.rows"
        :decimal-settings="table.decimal_settings"
        :icon-settings="table.icon_settings"
        :font-settings="{ bold: {row: {regex: 'Share' } } }"
        :sort-settings="{ enabled: false }"
        class="ms1p5"
    />
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: [],
                icon_settings: {},
                decimal_settings: {},
                render: false
            },
            location: '/business/store-fulfilment'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.load_ingka_by_country();
    },
    methods: {
        load_ingka_by_country: function() {
            this.$http.get('/business/store-fulfilment/total-ingka-by-country-3-8').then(
                succ => {
                    let d = succ.data[0].measures;
                    
                    let keys = Object.keys(d);
                    if (succ.data[0].measure_metadata) {
                        this.table.decimal_settings = succ.data[0].measure_metadata.decimal_settings || {};
                    }
                    this.table.headers = [Object.keys(d[0]).map(c => {
                        return {
                            [c]: 1
                        }
                    })];
                    Object.keys(d).forEach(row => {
                        let temp = [];
                        Object.keys(d[row]).forEach( cell_key => {
                            temp.push(d[row][cell_key]);
                        });
                        this.table.rows.push(temp)
                    });
                    this.table.icon_settings = {
                        // 'Montly Avg.': {
                        //     icon: 'arrow',
                        //     comparison: 'Actual Cy'
                        // },
                        // 'Actual Cy': {
                        //     icon: 'circle',
                        //     comparison: 'Goal'
                        // },
                        'Share': {
                            fix: {
                                post: '%'
                            },
                            row: true
                        },   
                    }
                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        }
    }
}
</script>

