var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal", class: { "is-active": _vm.delete_clicked } },
    [
      _c("div", { staticClass: "modal-background" }),
      _c("div", { staticClass: "modal-card" }, [
        _c("header", { staticClass: "modal-card-head" }, [
          _c("p", { staticClass: "modal-card-title" }, [
            _vm._v("Delete Comment"),
          ]),
          _c("button", {
            staticClass: "delete",
            attrs: { "aria-label": "close" },
            on: {
              click: function ($event) {
                _vm.delete_clicked = false
              },
            },
          }),
        ]),
        _c("section", { staticClass: "modal-card-body" }, [
          _vm._v(
            " Deleting this insight will remove it completely from flight tower and it will not be visible or editable by anyone. "
          ),
        ]),
        _c("footer", { staticClass: "modal-card-foot" }, [
          _c(
            "button",
            {
              staticClass: "button is-success",
              on: { click: _vm.delete_button },
            },
            [_vm._v("Delete")]
          ),
          _c(
            "button",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [_vm._v("Cancel")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }