<template>
<div>
    <AdminUniversal
        component="title"
        :props="title_props"
    />
    <KPIChooser
        @chosen="kpi_chosen"
        @reset="chosen_kpi = null"
		:global_location="true"
        class="kpi-chooser"
    />
    <div v-show="chosen_kpi" class="editor-wrapper">
        <div class="text-title-left mt">
            Current announcement
        </div>
        <div v-if="current_announcement" class="current-announcement" v-html="current_announcement"></div>

        <div class="text-title-left">
            New announcement
        </div>
        <WYSIWYG
            v-if="current_announcement"
            :content="current_announcement"
            @textUpdate="update_text"

            class="new-announcement"
        />
        <div><button class="button is-success mb" :disabled="!update_available" @click="update_announcement">Update announcement</button></div>
        <div><button class="button is-danger" @click="remove_announcement">Remove announcement</button></div>
    </div>

</div>
</template>

<script>
import AdminUniversal from '@/components/admin/index.vue';
import KPIChooser from '@/components/admin/KPIChooser.vue';
import Title from '@/components/admin/Title.vue';
import WYSIWYG from '@/components/bespoke/commenting/WYSIWYGEditor.vue';

export default {
    components: {
        AdminUniversal,
        KPIChooser,
        Title,
        WYSIWYG
    },
    data: function() {
        return {
            title_props: {
                title: 'Announcements'
            },
            chosen_kpi: null,
            updated_announcement: null,
            current_announcement: null,
            new_announcement: null,
            location: null,
            update_available: true
        }
    },
    methods: {
        kpi_chosen: function(kpi) {
            this.chosen_kpi = kpi;
            let path = kpi.path.split('/');
            path.shift();
            let keys = ["kpi", "kpi_area", "market_area", "store_area"];
            this.location = {};
            keys.map((key, index) => {
                if (path[index]) {
                    this.location[key] = path[index];
                } else {
                    this.location[key] = null;
                }
            });
            this.current_announcement = null;
            this.$http.post('/admin/announcements/load', {
                location: this.location
            }).then(
                succ => {
                    if (succ.data.length === 0 || succ.data[0].content === '<p></p>' || succ.data[0].content === null) {
                        this.current_announcement = 'There is no announcement for the kpi';
                        this.update_available = false;
                    } else {
                        this.current_announcement = succ.data[0].content;
                    }
                },
                this.$helper.error
            );
        },
        update_text: function(text) {
            this.updated_announcement = text;
            this.update_available = true;
        },
        update_announcement: function() {
            this.$http.post('/admin/announcements/update', {
                location: this.location,
                announcement: this.updated_announcement
            }).then(
                succ => {
                    this.current_announcement = this.updated_announcement;
                },
                this.$helper.error
            );
            alert('Refresh your page to see your changes to announcments');
        },
        remove_announcement: function() {
            this.$http.post('/admin/announcements/remove', {
                location: this.location,
            }).then(
                succ => {
                    this.current_announcement = 'There is no announcement for the kpi';
                },
                this.$helper.error
            );
            alert('Refresh your page to see your changes to announcments');
        }
    }
}
</script>

<style>
.current-announcement {
    background-color: #f3f3f3;
    border-radius: 5px;
    color: #666;
    margin: 0.5em 1.5em 1.5em 1.5em;
    padding: 1em;
}
.editor-wrapper {
    max-width: 600px;
}
.kpi-chooser {
    max-width: 500px;
}

.new-announcement {
    margin: 0.5em 1.5em 1.5em 1.5em;
}

.section-small {
    padding: 1.5rem;
}
</style>