<template>
<div :id="title" style="height: 100%; width: 100%;"></div>  
</template>
<script>
import * as echarts from 'echarts/dist/echarts.min.js';
export default {
    data: function() {
        return {}
    },
    props: {
        title: String, //title of the graph
        showtitle: Boolean,
        subtitle: String, //subtitle of the graph
        xcategories: Array, //data that will be displayed on the x axis
        yaxislabel: String, //name of the data on the y axis e.g 'After sales delivery share' or 'services sale by month'
        ymetric: String, //'%', '£', 'kg' etc.
        
        seriesname1: String, //name of the data such as IKEA
        seriestype1: String, //bar, line, scatter etc.
        seriesset1: Array, //actual data array to fill the graph
        color1: String, //color of the bar lie or scatter for the paticular data set
        
        seriesname2: String,
        seriestype2: String,
        seriesset2: Array,
        color2: String,
        
        seriesname3: String,
        seriestype3: String,
        seriesset3: Array,
        color3: String,
        
        seriesname4: String,
        seriestype4: String,
        seriesset4: Array,
        color4: String,

        ranking: Array,
        competitors: Array,

        show: Boolean, // true to show the exta features such as 'save graph as image', 'refresh', 'change to bar graph', etc
    },
    mounted: function() {
        if (!this.xcategories) return;
        let div_width = document.getElementById(`${this.title}`).clientWidth;
        let width_factor = 3.3;
        let offset_factor = 4.45;
        let myChart = echarts.init(document.getElementById(this.title));
        let appended_ranking = [];
        let max = -Infinity;
        this.seriesset1.concat(this.seriesset2, this.seriesset3).forEach(val => {
            max = val > max ? val : max;
        });
        max = Math.ceil((max + 1) / 50) * 50;
        this.ranking.map(rank => {
            if (rank === 1) {
                appended_ranking.push(`${rank}st`);
            } else if (rank === 2) {
                appended_ranking.push(`${rank}nd`);
            } else if (rank === 3) {
                appended_ranking.push(`${rank}rd`);
            } else if (rank > 3 && rank < 21) {
                appended_ranking.push(`${rank}th`);
            } else if (!rank) {
                appended_ranking.push(null);
            }
        });
        let country_competitor = [];
        for (let i = 1; i <= this.xcategories.length; i++) {
            let strings = [
                this.competitors[(i * 2) - 2] ? `\n\n1: ${this.competitors[(i * 2) - 2]}` : '', 
                this.competitors[(i * 2) - 1] ? `\n\n2: ${this.competitors[(i * 2) - 1]}` : ''
            ];
            country_competitor.push(`${this.xcategories[i - 1].replaceAll(' ', '\n')}${strings[0]}${strings[1]}`);
        }
        let option = {
            title: {
                text: this.title,
                subtext: this.subtitle,
                show: this.showtitle,
                subtextStyle: {
                    color: 'black',
                },
                top: '-2em',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            toolbox: {
                top: '85em',
                left: '0em',
                show: this.show,
                feature: {
                    restore: {},
                    saveAsImage: {}
                }
            },
            grid: {
               top: '120em',
               left: '70em',
               width: 'auto',
               bottom: '80em'
            },
            legend: {
                top: '40em',
                left: '0em',
                itemGap: 25,
                data:[this.seriesname1, this.seriesname2, this.seriesname3, this.seriesname4]
            },
            textStyle: {
                fontFamily: 'NotoIKEALatin-Regular',
                fontSize: 14,
            },
            xAxis: [{
                type: 'category',
                data: country_competitor,
                axisLabel: {
                    fontSize: 12,
                    interval: params => {
                        return true;
                    },
                },
                axisPointer: {
                    type: 'shadow'
                },
            }],
            yAxis: [
                {
                    type: 'value',
                    name: this.yaxislabel,
                    nameLocation: 'middle',
                    nameGap: 50,
                    max: max,
                    axisLabel: {
                        formatter: `{value} ${this.ymetric}`
                    }
                }
            ],
            series: [
                {
                    name: this.seriesname1,
                    type: this.seriestype1,
                    data: this.seriesset1,
                    color: this.color1,
                    barGap: '0',
                    label: {
                        show: this.seriestype1 === 'bar' ? true : false,
                        align: 'center',
                        verticalAlign: 'middle',
                        position: 'insideTop',
                        distance: 20,
                        color: 'black',
                    },
                    markPoint: {
                        symbolOffset: [0, -20],
                        zlevel: 0,
                        symbol: 'circle',
                        symbolSize: 30,
                        label: {
                            formatter: {
                                dataset: this.ranking
                            },
                            color: 'black',
                            borderWidth: 1,
                            
                        },
                        data: this.comparison_data(appended_ranking, max),
                    }
                },
                {
                    name: this.seriesname2,
                    type: this.seriestype2,
                    data: this.seriesset2,
                    color: this.color2,
                    barGap: '0',
                    label: {
                        show: this.seriestype2 === 'bar' ? true : false,
                        align: 'center',
                        verticalAlign: 'middle',
                        position: 'insideTop',
                        distance: 20,
                        color: 'black',
                        formatter: params => {
                            return params.data || '' ;
                        }
                    },
                },
                {
                    name: this.seriesname3,
                    type: this.seriestype3,
                    data: this.seriesset3,
                    color: this.color3,
                    barGap: '0',
                    label: {
                        show: this.seriestype3 === 'bar' ? true : false,
                        align: 'center',
                        verticalAlign: 'middle',
                        position: 'insideTop',
                        distance: 20,
                        color: 'black',
                        formatter: params => {
                            return params.data || '' ;
                        }
                    },
                },
                {
                    name: this.seriesname4,
                    type: this.seriestype4,
                    data: this.seriesset4,
                    color: this.color4,
                    symbol: this.seriestype4 === 'scatter' ? 'rect' : 'circle',
                    symbolSize:  this.seriestype4 === 'scatter' ? [(div_width / (country_competitor.length * width_factor)), 3] : 10,
                    symbolOffset: this.seriestype4 === 'scatter' ? [-(div_width / (country_competitor.length * offset_factor)), 0] : 0,
                    label: {
                        show: this.seriestype4 === 'bar' ? true : false,
                        align: 'center',
                        verticalAlign: 'middle',
                        position: 'insideTop',
                        distance: 20,
                        color: 'black',
                    },
                },
            ]
        };
        myChart.setOption(option);
    },
    methods: {
        comparison_data: function(appended_ranking, max) {
            let temp = [];
            appended_ranking.map((rank, i) => {
                if (!rank) {
                    temp.push({});
                } else {
                    temp.push({
                        value: rank,
                        xAxis: i,
                        yAxis: max
                    });
                }
            });
            return temp
        }
    }
}
</script>



