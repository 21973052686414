<template>
<div>
    <KpiHeader
        v-if="page_data && api_url"

        v-bind="page_data"
        :tabs="tabs"
        :filters="filters"
        :filter_render="filter_render"
        :version_api_url="api_url"
        @update_filter="update_filter"
        @update_measure="update_measure"
    />

    <component
        v-if="render"
        :is="page_data.type"
        v-bind="formatted_data"
        class="mx-4"
    />

    <div v-else>
        <div v-if="$store.state.loading !== 0">Loading Data</div>
        <div v-else>
            <div v-if="page_data" v-html="no_data_message"></div>
        </div>
    </div>
</div>
</template>

<script>
import universal_parser from '@/helper/response_parsers';
import KpiHeader from '@/components/ui/KPIheader.vue';
import Table from './Unviersal/table.vue';
import Graph from './Unviersal/graph.vue';
import SimpleGraph from './Unviersal/simple_graph.vue';
import Top10 from './Unviersal/top_10.vue';

export default {
    components: {
        KpiHeader,
        Graph,
        Table,
        SimpleGraph,
        Top10
    },
    props: {
        template: String,
        market: String,
    },
    data: function() {
        return {
            api_url: '',
            page_data: false,
            filters: {},
            filter_render: false,
            formatted_data: {},
            measure_filter: null,
            render: false,
            selected_measure: null,
            tabs: null,
            decimal_settings: null
        }
    },
    mounted: async function() {
        this.import_new_template();
    },
    computed: {
        no_data_message: function() {
            let message = `<p>No data found for <b>${this.page_data.title}</b>`;
            let added = '';
            if (this.template.includes('by_country')) added += ' By Country';
            else if (this.template.includes('by_trend')) added += ` for country <b>${this.$store.getters.selected_country_name.charAt(0) + this.$store.getters.selected_country_name.slice(1).toLowerCase()}</b>`;

            if (this.page_data.filter_keys && this.page_data.filter_keys.length) {
                added = ` with the `;
                this.page_data.filter_keys.forEach((key, i) => {
                    if (i > 0) added += (i + 1) < this.page_data.filter_keys.length ? ', ' : ' and ';
                    added += `<b>${key[0]}</b>`;
                });
                added += ` filter${this.page_data.filter_keys.length === 1 ? '' : 's'} applied`;
            }
            return `${message + added}</p>`;
        }
    },
    methods: {
        clear_embedded_data: function() {
            this.measure_filter = null;
            this.selected_measure = null;
        },
        generate_tabs: function() {
            if (!this.page_data || !this.page_data.tabs) return;
            let temp_tabs = this.page_data.tabs.map(button => {
                let temp_tab = {...button};
                if (button.link.includes(':market')) {
                    temp_tab.link = temp_tab.link.replace(':market', this.$store.getters.market);
                }
                return temp_tab;
            });
            this.tabs = temp_tabs;
            return;
        },
        import_new_template: async function() {
            let address = this.template.split('/');
            const type = address.pop().replace('.js', '');
            const kpi_name = address.pop();

            const kpi_data = (await import(`${address.join('/')}/${kpi_name}/`)).default;
            const page_data = (await import(`${this.template}`)).default;

            this.page_data = {...kpi_data, ...page_data};
            this.api_url = `${kpi_name}/${type}`;
            
            this.initialise();
        },
        initialise: function() {
            this.filter_render = false;
            this.$store.commit('set_routing', !!this.$router.app.$route.params.market || false);
            this.generate_tabs();

            if (this.page_data.filter_keys && this.page_data.filter_keys.length) this.get_filters();
            else this.get_data();
        },
        get_filters: function() {
            if (!this.page_data.filter_keys || !this.page_data.filter_keys.length) return;

            const filter_keys = this.page_data.filter_keys.map(key => key[1]);
            this.$http.post('/filters/load_array', {
                filter_keys
            }).then(
                succ => {
                    this.filters = succ.data;

                    //Check for saved filter values
                    Object.keys(this.filters).forEach(filter_key => {
                        const stored_filter_value = localStorage.getItem(filter_key);
                        if (stored_filter_value) this.filters[filter_key].state = stored_filter_value;
                    });

                    this.filter_render = true;
                    this.get_data();
                },
                this.$helper.error
            );
        },
        update_filter: function(filter_key, value) {
            this.filters[filter_key].state = value;
            localStorage.setItem(filter_key, value);
            this.get_data();
        },
        update_measure: function(filter_key, value) {
            this.selected_measure = value;
        },
        get_data: function() {
            this.render = false;
            this.clear_embedded_data();
            if (!this.api_url) return;
            this.$http.get(`${this.api_url}`)
            .then(
                succ => {
                    let applied_filters = [];
                    if ('filter_keys' in this.page_data) {
                        applied_filters = this.page_data.filter_keys.map(filter_key => {
                            return this.filters[filter_key[1]].state;
                        });
                    }
                    if (this.page_data.response_parsing) {
                        this.page_data.response_parsing.apply(this, [succ]);
                    } else {
                        try {
                            const {embedded_props, ...formatted_data} = universal_parser(this.page_data.type, succ, this.page_data, applied_filters, this.$store.getters.market, this.page_data.other_options);
                            this.formatted_data = formatted_data;
                            if (this.formatted_data.table) {
                                this.page_data['table'] = this.formatted_data.table;
                                this.page_data.table['xlsx_rows'] = this.xlsx_format(this.formatted_data.table, succ.data);
                            }
                            if (this.page_data.id) this.formatted_data.id = this.page_data.id;
                            if (embedded_props) {
                                const {measure_filter} = embedded_props; // All possible embeded props should be listed here
                                this.measure_filter = measure_filter;
                            }
                        } catch (err) {
                            console.error(err);
                            this.formatted_data = null;
                            return;
                        }
                    }
                    
                    this.render = true;
                },
                this.$helper.error
            );
        },
        xlsx_format: function(page_data, api_data) {
            if (!page_data.rows) return [];
            function drill_down(passed_data) {
                if ('measures' in passed_data && passed_data.measures[Object.keys(passed_data.measures)[0]]) {
                    return passed_data.measures[Object.keys(passed_data.measures)[0]].decimal;
                } else if (typeof passed_data === 'object') {
                    return drill_down(passed_data[Object.keys(passed_data)[0]]);
                } else {
                    return 0;
                }
            }
            this.page_data.table.decimal_settings = {'global': drill_down(api_data)};
            return page_data.rows.map(row => {
                return row.map(cell => {
                    return cell.display_value;
                });
            });
        }
    },
    watch: {
        template: function() {
            this.import_new_template();
        },
        '$store.getters.countries': function(newCountries, oldCountries) {
            const market = this.market === 'null' ? null : this.market;
            this.$store.commit('update_market', market);
            this.generate_tabs();
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            if (!this.page_data || !this.page_data.location) return;
            this.$store.commit('update_view', {
                market: newMarket,
                location: this.page_data.location
            });
            this.generate_tabs();
            this.get_data();
        },
    }
}
</script>
