var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal", class: { "is-active": _vm.back_clicked } },
    [
      _c("div", { staticClass: "modal-background" }),
      _c("div", { staticClass: "modal-card" }, [
        _c("header", { staticClass: "modal-card-head" }, [
          _c("p", { staticClass: "modal-card-title" }, [
            _vm._v("Unsaved Work"),
          ]),
          _c("button", {
            staticClass: "delete",
            attrs: { "aria-label": "close" },
            on: {
              click: function ($event) {
                _vm.back_clicked = false
              },
            },
          }),
        ]),
        _c("section", { staticClass: "modal-card-body" }, [
          _vm._v(" You have unsaved work, leaving this tab will delete it. "),
        ]),
        _c("footer", { staticClass: "modal-card-foot" }, [
          _c(
            "button",
            {
              staticClass: "button is-success",
              on: {
                click: function ($event) {
                  _vm.$emit("clear-storage"), _vm.$emit("toggle-edit-mode")
                },
              },
            },
            [_vm._v("Delete Changes")]
          ),
          _c(
            "button",
            {
              staticClass: "button",
              on: {
                click: function ($event) {
                  _vm.back_clicked = false
                },
              },
            },
            [_vm._v("Cancel")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }