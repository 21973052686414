var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: { title: "Sales Trend Total", location: _vm.location },
      }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons() },
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !this.$store.getters.presentation_mode,
                  expression: "!this.$store.getters.presentation_mode",
                },
              ],
              staticClass: "label-container",
              class: !_vm.$store.getters.market ? "ft-tooltip" : null,
              attrs: {
                tooltiptext: !_vm.$store.getters.market
                  ? "Select a country from top filter to see stores"
                  : null,
              },
            },
            [
              _c("p", [_vm._v("Market Area")]),
              _c("DropDown", {
                key: _vm.stores.values.length,
                attrs: {
                  options: _vm.stores.options,
                  values: _vm.stores.values,
                  default: _vm.stores.values[0],
                },
                on: { updateDropDown: _vm.update_store },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                key: _vm.$store.getters.market,
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: _vm.$store.getters.market,
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size,
          staticClass: "section half-height box ms",
          attrs: { id: "sales-trend-total-summary" },
        },
        [
          _vm.summary_top.render
            ? _c("SummaryTop", {
                attrs: {
                  title: `Showing data for: ${_vm.$store.getters.selected_country_name}`,
                  showtitle: !_vm.$store.state.global.bot_mode,
                  subtitle:
                    "The height of the bars represents the value in Growth Contribution and the values showing in the bars represents the Sales Index.",
                  xcategories: _vm.summary_top.xcategories,
                  yaxislabel: "Growth Contribution",
                  ymetric: "",
                  type: "bar",
                  color1: _vm.$store.state.colors["yellow"],
                  color2: _vm.$store.state.colors["grey"],
                  color3: "#787878",
                  seriesname1: "Year Goal",
                  seriesset1: _vm.summary_top.seriesset1,
                  seriesname2: "Forecast",
                  seriesset2: _vm.summary_top.seriesset2,
                  seriesname3: "YTD",
                  seriesset3: _vm.summary_top.seriesset3,
                  seriesname4: "13WK Trend",
                  seriesset4: _vm.summary_top.seriesset4,
                  seriesname5: "8WK Trend",
                  seriesset5: _vm.summary_top.seriesset5,
                  seriesname6: "4WK Trend",
                  seriesset6: _vm.summary_top.seriesset6,
                  seriesname7: "1WK Trend",
                  seriesset7: _vm.summary_top.seriesset7,
                  seriesname8: "Remaining (to FC)",
                  seriesset8: _vm.summary_top.seriesset8,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "section half-height box ms mb" },
        [
          _vm.summary_bottom.render
            ? _c("SummaryBottom", {
                attrs: {
                  title: `Showing data for: ${_vm.$store.getters.selected_country_name}.`,
                  showtitle: !_vm.$store.state.global.bot_mode,
                  subtitle: "",
                  xcategories: _vm.summary_bottom.xcategories,
                  xvalue: 1,
                  yaxislabel: "Growth Contribution",
                  ymetric: "%",
                  type: "bar",
                  color1: _vm.$store.state.colors["yellow"],
                  color2: "#787878",
                  color3: "#0051ba",
                  color4: "#008000",
                  seriesname1: "Year Goal",
                  seriesset1: _vm.summary_bottom.seriesset1,
                  seriesname2: "Forecast",
                  seriesset2: _vm.summary_bottom.seriesset2,
                  seriesname3: "Year To Date",
                  seriesset3: _vm.summary_bottom.seriesset3,
                  seriesname4: "13 Weeks",
                  seriesset4: _vm.summary_bottom.seriesset4,
                  seriesname5: "8 Weeks",
                  seriesset5: _vm.summary_bottom.seriesset5,
                  seriesname6: "4 Weeks",
                  seriesset6: _vm.summary_bottom.seriesset6,
                  seriesname7: "1 Week",
                  seriesset7: _vm.summary_bottom.seriesset7,
                  seriesname8: "Remaining",
                  seriesset8: _vm.summary_bottom.seriesset8,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }