<template>
<div>
    <div class="title">Access Denied</div>
    <p>You do not have sufficient access level to view this page.</p>
    <p><span @click="easter = true">Access</span> is determined by your job role. If you believe you need to have your access elevated, please visit the <router-link to="/access-summary" target="_blank">access management page</router-link> and click "raise level of access".</p>

    <div class="mt">
        <router-link to="/" class="button">Take me back</router-link>
    </div>

    <div v-if="easter" :style="image" class="easter mt"></div>
</div>
</template>

<script>
export default {
    data: function() {
        return {
            easter: false
        }
    },
    mounted: function() {
        this.$store.state.commenting.width = 0;
    },
    destroyed: function() {
        this.$store.state.commenting.width = 4;
    },
    computed: {
        image: function() {
            return {
                "background-image": "url('./assets/images/easter/accessdenied.jpg')"
            }
        }
    }
}
</script>

<style>
.easter {
    height: 300px;
    width: auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

</style>