<template>
<div>
    <Title
        title="HFB"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
         
        <div class="label-container">
            <p>HFB</p>
            <DropDown
                v-if="hfbs.all.length > 0"
                :key="hfbs.all.length"
                :options="hfbs.options"
                :values="hfbs.values"
                :default="hfbs.values[1]"
                @updateDropDown="update_hfb"
            />
        </div>
        
        <div class="label-container" 
            :class="!$store.getters.market ? 'ft-tooltip': null" 
            :tooltiptext="!$store.getters.market ? 'Select a country from top filter to see stores' : null"
        >
            <p>Market Area</p>
            <DropDown
                :key="stores.values.length"
                :options="stores.options"
                :values="stores.values"
                :default="stores.values[0]"
                @updateDropDown="update_store"

                
            />
        </div>
        
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms">
        <QuadStack
            :key="triple.render"
            v-if="triple.render"

            :title="`Showing data for: ${$store.getters.selected_country_name}`"
            :showtitle="!$store.state.global.bot_mode"

            subtitle=""

            :xcategories="triple.xcategories"
            yaxislabel="Growth Contribution YTD"
            ymetric="%"
            
            seriesname1="Store Comp."
            seriestype1="bar"
            :seriesset1="triple.seriesset1"
            :color1="$store.state.colors['yellow']"
            
            seriesname2="Store Non-Comp."
            seriestype2="bar"
            :seriesset2="triple.seriesset2"
            :color2="$store.state.colors['grey']"
            
            seriesname3="Online"
            seriestype3="bar"
            :seriesset3="triple.seriesset3"
            color3="#787878"

            seriesname4="Remote"
            seriestype4="bar"
            :seriesset4="triple.seriesset4"
            color4="#008000"
            
        />
        <div v-else> No data found for {{$store.getters.market}}</div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import QuadStack from '@/components/visualisations/general/QuadStack.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Title,
        QuadStack
    },
    props: {
        market: String
    },
    data: function() {
        return {
            triple: {
                title: null,
                subtitle: null,

                xcategories: null,
                yaxislabel: null,
                ymetric: null,
                
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],
                seriesset4: [],

                render: false
            },
            store: null,
            hfb: '01',
            location: '/business/sales-per-range/hfb',
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.load_market_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By HFB',
                    link: `${this.location}/by-hfb/${this.$store.getters.market}`
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Channel Contribution',
                    link: `${this.location}/channel-contribution/${this.$store.getters.market}`
                },
            ];
        },
        load_market_data: function() {
            let reqdata = {};
            if (this.$store.getters.market === null && this.store === null) {
                reqdata =  { parent: 'TOP', market: 'ALL', hfb: this.hfb };
            } else if (this.$store.getters.market !== null && this.store === null) {
                reqdata = { parent: 'ALL', market: this.$store.getters.market, hfb: this.hfb };
            } else {
                reqdata = { parent: this.$store.getters.market, market: this.store, hfb: this.hfb };
            }
            this.$http.post('business/sales-per-range/sales-per-hfb/growth-contribution/all', 
                reqdata
            ).then(
                succ => {
                    let d = succ.data;
                    if (!d || !d[0] || !d[0].data) return;
                    this.triple.xcategories = Object.keys(d[0].data);
                    
                    this.triple.seriesset1 = [];
                    this.triple.seriesset2 = [];
                    this.triple.seriesset3 = [];
                    this.triple.seriesset4 = [];
                    
                    this.triple.xcategories.map(month => {
                        let val = d[0].data[month];
                        this.triple.seriesset1.push(this.$helper.maybe(val['Store Comp.']));
                        this.triple.seriesset2.push(this.$helper.maybe(val['Store Non-comp.'], null));
                        this.triple.seriesset3.push(this.$helper.maybe(val['Online']));
                        this.triple.seriesset4.push(this.$helper.maybe(val['Remote']));
                    });

                    this.triple.render = false;
                    this.triple.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: `${this.location}/channel-contribution`,
            });
        },
        update_hfb: function(hfb) {
            this.hfb = hfb;
            this.triple.render = false;
            this.load_market_data();
        },
        update_store: function(store) {
            this.store = store;
            this.triple.render = false;
            this.load_market_data();
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.triple.render = false;
            this.store = null;
            this.load_market_data();
        },
        '$store.getters.stores': function(newStores, oldStores) {
            return this.$store.getters.stores;
        }
    },
    computed: {
        stores: function() {
            if (this.$store.getters.stores.values.length) {
                return this.$store.getters.stores;
            } else {
                return {
                    all: [],
                    options: [],
                    values: []
                }
            }
        },
        hfbs: function() {
            return this.$store.getters.hfbs;
        }
    }
}
</script>

<style>

</style>
