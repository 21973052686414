var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: { title: "Sales Prioritised Cities", location: _vm.location },
      }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [_c("Buttons", { attrs: { buttons: _vm.generate_buttons() } })],
        1
      ),
      _c("div", { staticClass: "columns three-quarters-height" }, [
        _c(
          "div",
          { staticClass: "column is-3" },
          [
            _c("BubbleGraphOptions", {
              attrs: {
                options: _vm.bubble.options,
                title: "Select Cities",
                "selected-options": _vm.bubble.selected_options,
              },
              on: { reset: _vm.reset_options, selected: _vm.city_selected },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "column overflow-visible" }, [
          _c(
            "div",
            {
              key: _vm.$store.getters.page_size,
              staticClass: "section half-height box ms",
            },
            [
              _vm.bubble.render
                ? _c("Bubble", {
                    attrs: {
                      title: "Store Sales MEUR vs Online Sales MEUR",
                      "sub-title":
                        "The size of the bubble doesnt represent any data",
                      "show-title": !_vm.$store.state.global.bot_mode,
                      "x-axis-label": "Store Sales MEUR",
                      "y-axis-label": "Online Sales MEUR",
                      sizeLabel: "Sales MEUR",
                      yMin: 0,
                      "y-metric": "",
                      colors: [],
                      names: _vm.bubble2.selected_options,
                      datasets: _vm.bubble2.datasets,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              key: _vm.$store.getters.page_size + 1,
              staticClass: "section half-height box ms",
            },
            [
              _vm.bubble.render
                ? _c("Bubble", {
                    attrs: {
                      title: "City sales index vs Rest of country index",
                      "sub-title":
                        "Size based on combination of all sales in MEUR",
                      "show-title": !_vm.$store.state.global.bot_mode,
                      "x-axis-label": "Rest of Country Index",
                      "y-axis-label": "City Sales Index",
                      "size-label": "Online and Store Sales MEUR",
                      yMin: 0,
                      "y-metric": "",
                      colors: [],
                      names: _vm.bubble.selected_options,
                      datasets: _vm.bubble.datasets,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }