var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "title" }, [_vm._v("Sitemap")]),
    _c(
      "div",
      { staticClass: "columns is-multiline" },
      _vm._l(_vm.options, function (option, index) {
        return _c(
          "div",
          { key: index, staticClass: "column is-4 text-left" },
          [
            _c("p", [_c("b", [_vm._v(_vm._s(option.name))])]),
            _vm._l(option.areas, function (area, i) {
              return _c("div", { key: i }, [
                "areas" in area
                  ? _c(
                      "div",
                      { staticClass: "mb" },
                      [
                        _vm._v(" " + _vm._s(area.name) + " "),
                        _vm._l(area.areas, function (a, j) {
                          return _c(
                            "div",
                            { key: j, staticClass: "ml" },
                            [
                              _c("router-link", { attrs: { to: a.path } }, [
                                _vm._v(_vm._s(a.name)),
                              ]),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    )
                  : _c(
                      "div",
                      [
                        _c("router-link", { attrs: { to: area.path } }, [
                          _vm._v(_vm._s(area.name)),
                        ]),
                      ],
                      1
                    ),
              ])
            }),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }