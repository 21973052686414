<template>
<div>
    <Title
        title="Sales Contribution Per Country"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <Table
        v-if="data_found"
        :headers="table.headers"
        :rows="table.rows"
        :decimal-settings="table.decimal_settings"
        :icon-settings="table.icon_settings"
        :font-settings="{ bold: {row: {regex: 'Total' } } }"
        :sort-settings="{ 'Market-Area': { asc: true } }"
        :metaSettings="{is_selectable: false}"
        
        class="ms1p5"
    />
    <div v-else>No data for {{$store.state.selected.country}}, please select another country</div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';


export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    props: {
        market: String
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: [],
                icon_settings: {},
                decimal_settings: {},
                render: false
            },
            location: '/business/total-sales/sales-contribution-per-country',
            data_found: false
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.load_ingka_by_country();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'By Market',
                    link: `${this.location}/${this.$store.getters.market}`
                }
            ];
        },
        load_ingka_by_country: function() {
            this.data_found = false;
            if (!this.$store.getters.market) return;
            this.$http.post(`/business/total-sales/sales-contribution-per-country/market`, {
                market: this.$store.getters.market
            }).then(
                succ => {
                    let d = succ.data;
                    if (!d || !d[0]) return;
                    let keys = Object.keys(d[0].measures);
                    if (d[0].measure_metadata) {
                        this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                    }
                    this.table.headers = [['Market-Area'].concat(keys).map(c => {
                        return {
                            [c]: 1
                        }
                    })];
                    this.table.rows = d.map(row => {
                        let temp = [];
                        // If the key/values don't exist, set them to 0
                        keys.map(key => {
                            if (
                                typeof (row.measures[key]) === 'number' ||
                                typeof (row.measures[key]) === 'string'
                            ) temp.push(this.$helper.maybe(row.measures[key]));
                            else temp.push('');
                        });
                        return [row.caption].concat(temp);
                    });
                    this.table.icon_settings = {
                        '4WK Index': {
                            icon: 'arrow',
                            comparison: 'YTD Index',
                        }
                    }
                    this.$helper.header_replace(this.table.headers, {
                        '4 Week Index': '4WK Index'
                    });
                    if ('4 Week Index' in this.table.decimal_settings) {
                        this.table.decimal_settings['4WK Index'] = this.table.decimal_settings['4 Week Index'];
                    }
                    this.data_found = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.load_ingka_by_country();
        },
    }
}
</script>
