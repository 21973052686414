<template>
    <div :id="title" style="width: 100%; height:100%;"></div>
</template>

<script>
import * as echarts from 'echarts/dist/echarts.min.js';
export default {
    props: {
        title: String, //title of the graph
        showtitle: Boolean,
        subtitle: String, //subtitle of the graph
        ycategories: Array, //values that will be displayed on the y axis 
        yaxislabel: String,
        xaxislabel: String, //name of the data on the x axis
        xmetric: String, //'%', '£', 'kg' etc.
        seriestype1: String,
        seriestype2: String,
        seriestype3: String,
        seriesname1: String, //name of the data such as IKEA 
        seriesname2: String,
        seriesname3: String,
        seriesset1: Array, //actual data array to fill the graph
        seriesset2: Array,
        seriesset3: Array,
        color1: String, //color of the bar lie or scatter for the paticular data set
        color2: String,
        color3: String,
        show: Boolean, // true to show the exta features such as 'save graph as image', 'refresh', 'change to bar graph', etc
        hidebarlabel: Boolean
    },
    mounted: function() {
        let myChart = echarts.init(document.getElementById(this.title));
        let option = {
            title:{
                text: this.title,
                subtext: this.subtitle,
                show: this.showtitle,
                subtextStyle: {
                    color: 'black',
                },
                top: '0em',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            toolbox: {
                top: '55em',
                right: '73em',
                show: this.show,
                feature: {
                    restore: {},
                    saveAsImage: {}
                }
            },
            grid: {
               top: '60em',
               left: '90em',
               right: '20em',
               bottom: '25em',
            },
            legend: {
                top: '30em',
                left: '0em',
                data: [this.seriesname1, this.seriesname2, this.seriesname3],
            },
            textStyle: {
                fontFamily: 'NotoIKEALatin-Regular',
            },
            yAxis: [
                {
                    type: 'category',
                    name: this.yaxislabel,
                    data: this.ycategories,
                    nameLocation: 'middle',
                    nameGap: 10,
                    nameRotate: 0,
                    axisPointer: {
                        type: 'shadow'
                    },
                }
            ],
            xAxis: [
                {
                    type: 'value',
                    name: this.xaxislabel,
                    nameLocation: 'middle',
                    nameGap: 0,
                    nameRotate: 0,
                    axisLabel: {
                        formatter: `{value} ${this.xmetric}`
                    }
                }
            ],
            series: [
                {
                    name: this.seriesname1,
                    type: this.seriestype1,
                    data: this.seriesset1,
                    color: this.color1,
                    stack: 'example',
                    label: {
                        show: this.hidebarlabel ? false : true,
                        position: 'inside',
                        color: "#FFF"
                    },
                },
                {
                    name: this.seriesname2,
                    type: this.seriestype2,
                    data: this.seriesset2,
                    color: this.color2,
                    stack: 'example',
                    label: {
                        show: this.hidebarlabel ? false : true,
                        position: 'inside',
                        color: "#FFF"
                    },
                },
                {
                    name: this.seriesname3,
                    type: this.seriestype3,
                    data: this.seriesset3,
                    color: this.color3,
                    stack: 'example',
                    label: {
                        show: this.hidebarlabel ? false : true,
                        position: 'inside',
                        color: "#FFF"
                    }
                }           
            ]
        };
        myChart.setOption(option);
    }
}
</script>