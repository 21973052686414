<template>
<div>
    <Table
        v-if="table"

        :headers="table.headers"
        :rows="table.rows"
        
        class="ms1p5"
    />
</div>
</template>

<script>
import Table from '@/components/visualisations/table/tablenosql.vue';
export default {
    components: {
        Table
    },
    props: {
        table: Object
    }
}
</script>