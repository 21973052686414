<template>
    <div>
        <AdminUniversal component="title" :props="title_props" />

        <KPIChooser @chosen="kpi_chosen" @reset="chosen_kpi = null" class="kpi-chooser" />

        <div v-show="chosen_kpi" class="editor-wrapper">
            <div class="radio-group">
                <label>
                    <input
                        type="radio"
                        value="global"
                        v-model="definitionType"
                        @change="handleDefinitionTypeChange(chosen_kpi, 'global')"
                    />
                    Global
                </label>
                <label>
                    <input
                        type="radio"
                        value="bycountry"
                        v-model="definitionType"
                        @change="handleDefinitionTypeChange(chosen_kpi, 'bycountry')"
                    />
                    By Country
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import AdminUniversal from '@/components/admin/index.vue';
import KPIChooser from '@/components/admin/KPIChooser.vue';
import Title from '@/components/admin/Title.vue';

export default {
    components: {
        AdminUniversal,
        KPIChooser,
        Title
    },
    data: function () {
        return {
            title_props: {
                title: 'KPI Insights Settings'
            },
            chosen_kpi: null,
            location: null,
            definitionType: 'global'
        };
    },
    methods: {
        kpi_chosen: function (kpi) {
            this.chosen_kpi = kpi;
            this.location = this.$helper.seperate_link_into_areas(kpi.location || kpi.path);
            this.fetchKpiObservations(kpi.name);
        },
        handleDefinitionTypeChange: function (kpi, type) {
            // Handler function for radio button change
            this.$http
                .post('force', '/kpidata/kpi-observations', {
                    location: this.location,
                    type: type
                })
                .then()
                .catch(error => {
                    console.error('Error Changing Definition Type in KPI observations:', error);
                });
        },
        fetchKpiObservations() {
            // Function to fetch KPI observations
            this.$http
                .post('force', '/kpidata/get-kpi-observations', { location: this.location })
                .then(response => {
                    this.definitionType = response.data.type;
                })
                .catch(error => {
                    console.error('Error fetching KPI observations:', error);
                    this.definitionType = 'global';
                });
        }
    },
    computed: {
        current_kpi: function () {
            if (this.chosen_kpi) {
                return this.chosen_kpi[this.chosen_kpi.length - 1];
            }
        }
    }
};
</script>

<style scoped>
.current-definition {
    background-color: #f3f3f3;
    border-radius: 5px;
    color: #666;
    margin: 0.5em 1.5em 1.5em 1.5em;
    padding: 1em;
}
.editor-wrapper {
    max-width: 600px;
}
.kpi-chooser {
    max-width: 500px;
}

.radio-group {
    display: flex;
    flex-direction: column; /* Stack radio buttons vertically */
    align-items: flex-start; /* Left align the radio buttons */
    padding: 1em; /* Add padding around the radio buttons */
}

.radio-group label {
    margin-bottom: 0.5em; /* Space between radio buttons */
    font-size: 1.2em; /* Increase font size for better visibility */
}

.new-definition {
    margin: 0.5em 1.5em 1.5em 1.5em;
}

.section-small {
    padding: 1.5rem;
}
</style>
