var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "columns is-multiline pt" },
      [
        _c(
          "div",
          {
            staticClass: "column is-2 country-box cursor-pointer",
            on: {
              click: function ($event) {
                return _vm.set_total_retail_countries()
              },
            },
          },
          [
            _vm._m(0),
            _c("div", { staticClass: "country-title" }, [
              _vm._v("TOTAL RETAIL COUNTRIES"),
            ]),
          ]
        ),
        _vm._l(_vm.displayed_countries, function (country, i) {
          return _c(
            "div",
            {
              key: i,
              staticClass: "column is-2 country-box cursor-pointer",
              on: {
                click: function ($event) {
                  return _vm.update_filter(country)
                },
              },
            },
            [
              _c("div", {
                staticClass: "dropdown-flag",
                class: _vm.render_flag(country),
              }),
              _c("div", { staticClass: "country-title" }, [
                _vm._v(_vm._s(country.caption)),
              ]),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dropdown-flag" }, [
      _c("div", { staticClass: "total-retail-countries" }, [
        _c("i", { staticClass: "fas fa-globe-europe globe" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }