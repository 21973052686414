var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "kitchensink" } },
    [
      _c("Title", { attrs: { title: "Index of Power BI pages" } }),
      _c("div", [
        _vm._v("An Index of prototype pages using an embedded Power BI report"),
      ]),
      _c("div", { staticClass: "section box" }, [
        _c("ol", [
          _c("li", [
            _c(
              "a",
              {
                on: {
                  click: function ($event) {
                    return _vm.$router.push(`/testing/power-bi/gross-margin`)
                  },
                },
              },
              [_vm._v("Gross Margin")]
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }