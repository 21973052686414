var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "has-text-left" },
    [
      _c("label", { staticClass: "label" }, [_vm._v(" Select KPI Area ")]),
      _vm.kpi_areas
        ? _c("DropDown", {
            key: _vm.kpi_areas[0],
            staticClass: "float-left",
            attrs: {
              options: _vm.kpi_areas,
              values: _vm.kpi_areas,
              default: _vm.kpi_areas[0],
            },
            on: { updateDropDown: _vm.updated_areas_dropdown },
          })
        : _vm._e(),
      _vm.kpi_lvl1
        ? _c("DropDown", {
            key: _vm.kpi_lvl1[0],
            staticClass: "float-left",
            attrs: {
              options: _vm.kpi_lvl1,
              values: _vm.kpi_lvl1,
              default: _vm.kpi_lvl1[0],
            },
            on: { updateDropDown: _vm.updated_lvl1_dropdown },
          })
        : _vm._e(),
      _vm.selected_kpi_lvl2
        ? _c("DropDown", {
            key: _vm.kpi_lvl2[0],
            staticClass: "float-left",
            attrs: {
              options: _vm.kpi_lvl2,
              values: _vm.kpi_lvl2,
              default: _vm.kpi_lvl2[0],
            },
            on: { updateDropDown: _vm.updated_lvl2_dropdown },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }