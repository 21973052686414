var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: {
          title: "Operational Climate Footprint",
          location: _vm.location,
        },
      }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons() },
          }),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                key: _vm.$store.getters.market,
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: _vm.$store.getters.market,
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size,
          staticClass: "section three-quarters-height box ms",
        },
        [
          _vm.graph.render
            ? _c("Deviation", {
                key: _vm.graph.render,
                attrs: {
                  title: `Showing data for: ${_vm.$store.getters.selected_country_name}`,
                  showtitle: !_vm.$store.state.global.bot_mode,
                  xcategories: _vm.graph.xcategories,
                  yaxislabel1: "Ton CO2e",
                  ymetric1: "",
                  yaxislabel2: "Ton CO2e",
                  ymetric2: "",
                  datanames: _vm.graph.names,
                  datatypes: _vm.graph.types,
                  dataset: _vm.graph.datasets,
                  colors: _vm.graph.colors,
                  yaxisindex: [1, 4],
                  show: !_vm.$store.state.global.bot_mode,
                },
              })
            : _c("div", [
                _vm._v(
                  " No data found for " +
                    _vm._s(_vm.$store.getters.selected_country_name)
                ),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }