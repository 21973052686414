<template>
<div>
    <Title
        title="Core Areas"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />
        
        <div v-if="values.length > 0" class="label-container">
            <p>Filter</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
 
    <Table
        :key="table.render"

        :headers="table.headers"
        :rows="table.rows"
        :decimal-settings="table.decimal_settings"
        :icon-settings="table.icon_settings"
        :sort-settings="{'Growth Contribution': {asc: false} }"
        :metaSettings="{is_selectable: false}"
        class="ms1p5 no-all"
    />
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    props: {
        option: String,
        market: String
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: [],
                decimal_settings: {},
            },
            store: null,
            options: ['Living Room', 'Bedroom', 'Kitchen and Bathroom', 'Remaining Range'],
            values: ['Living room', 'Bedroom', 'Kitchen and Bathroom', 'Other'], //need to return to this and change to filtersets table - Ben 16 Jul 2020
            filter: 'Living Room',
            location: '/business/sales-per-range/core-areas'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.option && this.option !== 'null') {
            this.filter = this.option.replace(/-/g, ' ');
        } else {
            this.filter = this.$store.getters.filter_check(this.values);
        }
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.load_ingka_by_country();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: `${this.location}/null`
                },
                {
                    name: 'Trend',
                    link: `${this.location}/trend/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8/null`
                },
                {
                    name: 'Top Family',
                    link: this.filter_url
                },
                {
                    name: 'Growth Contribution',
                    link: `${this.location}/growth-contribution/${this.$store.getters.market}`
                },
                {
                    name: 'Sales in MEUR per week',
                    link: `${this.location}/sales-in-meur/${this.$store.getters.market}`
                },
                {
                    name: 'Index per week',
                    link: `${this.location}/index/${this.$store.getters.market}`
                }
            ];
        },
        load_ingka_by_country: function() {
            this.$http.post('/business/sales-per-range/core-areas/top-family', {
                market: this.$store.getters.market, filter: this.filter
            }).then(
                succ => {
                    let d = succ.data;
                    this.table.headers, this.table.rows = [];

                    let first = d[0];
                    let first_key = Object.keys(first.data).pop();

                    let keys = Object.keys(first.data[first_key]);
                    if (first.measure_metadata) {
                        this.table.decimal_settings = first.measure_metadata.decimal_settings || {};
                    }
                    Object.keys(first.data).forEach(row => {
                        Object.keys(first.data[row]).forEach(key => {
                            if (keys.indexOf(key) === - 1) {
                                keys.push(key)
                            }
                        });
                    });
                    
                    this.table.headers = [[''].concat(keys).map(h => {let c = {}; c[h] = 1; return c;})];

                    Object.keys(first.data).forEach(range => {
                        let temp = [];
                        // If the key/values don't exist, set them to 0
                        temp.push(range);
                        keys.map(key => {
                            temp.push(this.$helper.maybe(first.data[range][key]));
                        });
                        this.table.rows.push(temp);
                    });
                    this.table.icon_settings = {
                        '4WK Trend': {
                            comparison: 'YTD Index',
                            icon: 'arrow',
                        },
                        'Growth Contribution': {
                            comparison: 'Growth Contribution',
                            icon: 'bar'
                        }
                    }
                    this.$helper.header_replace(this.table.headers, {
                        '4 Weeks': '4WK Trend'
                    });
                    if ('4 Weeks' in this.table.decimal_settings) {
                        this.table.decimal_settings['4WK Trend'] = this.table.decimal_settings['4 Weeks'];
                    }
                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$router.push(this.filter_url);
            this.table.render = false;
            this.load_ingka_by_country();
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: `${this.location}/top-families/${this.filter_formatted}`
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.table.render = false;
            this.load_ingka_by_country();
        },
        '$store.getters.countries': function(newCountries, oldCountries) {
            this.$store.commit('update_market', this.market);
        },
        '$route': function(to, from) {
            if (to.params.option === 'null') {
                this.$router.push(this.filter_url);
            }
        }
    },
    computed: {
        filter_formatted:  function() {
            return this.filter ? this.filter.replace(/ /g, '-').replace(/_/g, '-') : null;
        },
        filter_url: function() {
            return `${this.location}/top-families/${this.filter_formatted}/${this.$helper.market_check(this.market)}`
        }
    }
}
</script>
