<template>
    <div :id="title" style="width: 100%; height:100%;"></div>
</template>

<script>
import * as echarts from 'echarts/dist/echarts.min.js';
export default {
    props: {
        title: String, //title of the graph
        showtitle: Boolean,
        subtitle: String, //subtitle of the graph
        xcategories: Array, //data that will be displayed on the x axis
        yaxislabel: String, //name of the data on the y axis e.g 'After sales delivery share' or 'services sale by month'
        ymetric: String, //'%', '£', 'kg' etc.
        seriesname1: String, //name of the data such as IKEA 
        seriesset1: Array, //actual data array to fill the graph
        color1: String, //color of the bar lie or scatter for the paticular data set
        seriesname2: String,
        seriesset2: Array,
        color2: String,
        seriesname3: String,
        seriesset3: Array,
        color3: String,
        seriesname4: String,
        seriesset4: Array,
        color4: String,
        seriesname5: String,
        seriesset5: Array,
        color5: String,
        seriesname6: String,
        seriesset6: Array,
        color6: String,
        show: Boolean, // true to show the exta features such as 'save graph as image', 'refresh', 'change to bar graph', etc
    },
    mounted: function() {
        let myChart = echarts.init(document.getElementById(this.title));
        let option = {
            title:{
                text: this.title,
                subtext: this.subtitle,
                show: this.showtitle,
                subtextStyle: {
                    color: 'black',
                },
                top: '-2em',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            toolbox: {
                top: '100em',
                right: '30em',
                show: this.show,
                feature: {
                    restore: {},
                    saveAsImage: {}
                }
            },
            grid: {
               top: '140em',
               left: '70vh',
            },
            legend: {
                top: '55em',
                left: '0em',
                data:[this.seriesname1, this.seriesname2, this.seriesname3, this.seriesname4, this.seriesname5, this.seriesname6]
            },
            textStyle: {
                fontFamily: 'NotoIKEALatin-Regular',
            },
            xAxis : [
                {
                    type : 'category',
                    data : this.xcategories,
                }
            ],
            yAxis : [
                {
                    type : 'value',
                    name: this.yaxislabel,
                    nameLocation: 'middle',
                    nameGap: 50,
                    axisLabel: {
                        formatter: `{value} ${this.ymetric}`
                    }
                }
            ],
            series : [
                {
                    name:this.seriesname1,
                    type:'line',
                    stack: 'example',
                    areaStyle: {},
                    data: this.seriesset1,
                    color: this.color1
                },
                {
                    name:this.seriesname2,
                    type:'line',
                    stack: 'example',
                    areaStyle: {},
                    data: this.seriesset2,
                    color: this.color2
                },
                {
                    name:this.seriesname3,
                    type:'line',
                    stack: 'example',
                    areaStyle: {},
                    data: this.seriesset3,
                    color: this.color3
                },
                {
                    name:this.seriesname4,
                    type:'line',
                    stack: 'example',
                    areaStyle: {},
                    data: this.seriesset4,
                    color: this.color4
                },
                {
                    name:this.seriesname5,
                    type:'line',
                    stack: 'example',
                    areaStyle: {},
                    data: this.seriesset5,
                    color: this.color5
                },
                {
                    name:this.seriesname6,
                    type:'line',
                    stack: 'example',
                    areaStyle: {},
                    data: this.seriesset6,
                    color: this.color6
                }
            ]
        };
        myChart.setOption(option);
    }
}
</script>

