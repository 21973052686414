<template>
<div>
    <Title
        title="Market Share"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    
    <div :key="$store.getters.page_size" id="market-share-trend" class="section three-quarters-height box ms">
        <div class="title-text">
            <div class="title-header">
                <div class="blue-highlight">{{$store.getters.selected_country_name}}</div>
                <div class="mlp25">Market Share development</div>
            </div>
            <div class="sub-title-text font-size-p8">PRS's Gaming and Living with Pets are <div class="blue-highlight mlp25">excluded</div></div>
            <div class="sub-title-text font-size-p8">Excluded rate used is <div class="blue-highlight mlp25">GFAR23</div></div>
        </div>
        <ForecastTrendGraph
            v-if="graph.render"

            :title="`${$store.getters.selected_country_name}` + ' Market Share development'"
            :showTitle="false"
            v-bind="graph"
        />
        <div v-else>There is no data for <b>{{$store.getters.selected_country_name}}</b></div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import ForecastTrendGraph from '@/components/visualisations/general/ForecastTrendGraph.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        ForecastTrendGraph,
        Title
    },
    props: {
        market: String
    },
    data: function() {
        return {
            graph: {
                x_categories: [],
                y_axis_label: '',
                y_metric: '%',
                
                names: [],
                data_sets: [],
                types: ['line', 'line', 'line', 'line'],
                type_styles: [{}, {}, {}, {}],
                colors: [
                    this.$store.state.colors['light-grey'],
                    '#1565c0',
                    this.$store.state.colors['focus-yellow'],
                    this.$store.state.colors['YG']
                ],
                year_goal_index: 3,
                render: false
            },

            location: '/business/market-share'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.get_data();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                }
            ];
        },
        get_data: function() {
            this.graph.render = false;
            this.$http.post('/business/market-share-calculator/market', {
                market: this.$store.getters.market
            }).then(
                succ => {
                    let d = succ.data;
                    
                    if (!d[0] || !d[0].measures) return;
                    d = d[0].measures;

                    this.graph.names = Object.keys(d).sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1);
                    this.graph.x_categories = Object.keys(d[this.graph.names[0]]);

                    this.graph.data_sets = [];
                    this.graph.names.forEach(series => {
                        this.graph.data_sets.push(Object.values(d[series]).map(datum => this.$helper.maybe(datum)));
                    });
                    
                    this.graph.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.get_data();
        }
    }
}
</script>
<style scoped>
.blue-highlight {
    color: #1565c0
}
.title-header {
    font-size: 1.2rem;
    text-align: left;
    font-weight: bold;
    display: flex;
}
.sub-title-text {
    font-size: 0.8rem;
    text-align: left;
    font-weight: bold;
    display: flex;
}
.title-text{
    position: absolute;
}
</style>
