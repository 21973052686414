var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: { title: "Cost of Internal Damages", location: _vm.location },
      }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons() },
          }),
          _c(
            "div",
            { staticClass: "label-container" },
            [
              _c("p", [_vm._v("Filter")]),
              _c("DropDown", {
                key: _vm.filter,
                attrs: {
                  options: _vm.options,
                  values: _vm.values,
                  default: _vm.filter,
                },
                on: { updateDropDown: _vm.update_filter },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                key: _vm.$store.getters.market,
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: _vm.$store.getters.market,
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size,
          staticClass: "section three-quarters-height box ms",
        },
        [
          _vm.sextuple.render
            ? _c("Sextuple", {
                attrs: {
                  title: "Stock Accuracy",
                  showtitle: !_vm.$store.state.global.bot_mode,
                  subtitle: "",
                  xcategories: _vm.sextuple.xcategories,
                  yaxislabel1: "Stock Accuracy %",
                  ymetric1: "",
                  yaxislabel2: "Number of Checks",
                  ymetric2: "",
                  seriesname1: "YTD Total Stock Checks",
                  seriestype1: "line",
                  seriesset1: _vm.sextuple.seriesset1,
                  color1: _vm.$store.state.colors["grey"],
                  seriesname2: "YTD %",
                  seriestype2: "line",
                  seriesset2: _vm.sextuple.seriesset2,
                  color2: _vm.$store.state.colors["focus-yellow"],
                  seriesname3: "1WK %",
                  seriestype3: "line",
                  seriesset3: _vm.sextuple.seriesset3,
                  color3: _vm.$store.state.colors["light-grey"],
                  seriesname4: "4WK %",
                  seriestype4: "line",
                  seriesset4: _vm.sextuple.seriesset4,
                  color4: _vm.$store.state.colors["dark-grey"],
                  seriesname5: this.goal,
                  seriestype5: "line",
                  seriesset5: _vm.sextuple.seriesset5,
                  color5: _vm.$store.state.colors.YG,
                  show: !_vm.$store.state.global.bot_mode,
                },
              })
            : _c("div", [
                _vm._v(
                  " No data found for " +
                    _vm._s(_vm.$store.getters.selected_country_name)
                ),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }