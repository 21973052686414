<template>
<div>
    <Title
        :title="title"
        :location="location"
        :version_api_url="version_api_url"
    />
    <div class="options-bar">

        <Buttons
            :buttons="tabs"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />

        <div v-if="filter_render">
            <div v-for="(filter, i) in filter_keys" :key="i">
                <DropDown2
                    :label="filter[0]"
                    :name="filter[1]"
                    :options="filters[filter[1]].options"
                    :values="filters[filter[1]].values"
                    :default="filters[filter[1]].state"
                    @updateDropDown="update_filter"
                />
            </div>
        </div>

        <div v-if="measure_filter">
            <DropDown2
                v-bind="measure_filter"
                @updateDropDown="update_measure"
            />
        </div>

        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import DropDown2 from '@/components/ui/DropDown2.vue';
import Title from '@/components/ui/Title.vue';
export default {
    components: {
        Buttons,
        DropDown,
        DropDown2,
        Title
    },
    props: {
        title: String,
        location: String,
        version_api_url: String,
        tabs: Array,
        table: Object,
        filter_keys: Array,
        filters: Object,
        filter_render: Boolean,

        measure_filter: Object
    },
    methods: {
        update_filter: function(state, state2) {
            this.$emit('update_filter', state, state2);
        },
        update_measure: function(state, state2) {
            this.$emit('update_measure', state, state2);
        },        
        update_view: function(newMarket) {
            this.$store.commit('update_view', {
                market: newMarket,
                location: this.location
            });
        }
    }
}
</script>