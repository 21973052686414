<template>
<div>
    <Title title="Happy Customer" :location="location" />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown  
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>

    <Table
        v-if="table.render"
        :key="table.render"

        :headers="table.headers"
        :rows="table.rows"

        class="ms1p5"
        
        :bridge-settings="table.bridge_settings"
        :icon-settings="table.icon_settings"
        :decimal-settings="table.decimal_settings"
        :font-settings="{ bold: {row: {regex: 'Total Retail Countries' } } }"
        :sort-settings="{ 'Country': { asc: true } }"
    />
</div>
</template>


<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: [],
                decimal_settings: {},
                render: true,
            },
            location: '/customer/happy-customer',
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.load_ingka_by_country();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
                // {
                //     name: 'Customer Journey',
                //     link: `${this.location}/summary/${this.$store.getters.market}`
                // }
            ];
        },
        load_ingka_by_country: function() {
            this.table.render = false;
            this.$http.get('/customer/happy-customer/total-ingka-by-country').then(
                succ => {
                    if (!succ.data) return;
                    let d = succ.data;
                    if (d[0].measure_metadata) {
                        this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                    }
                    this.table.headers, this.table.rows = [];

                    // Setup the headers
                    this.table.headers = [
                        // Row 1
                        [{'': 1}, {'Topline Happy Customer Score': 3}, {'Physical': 2}, {'Digital': 2}, {'Support': 2}, {'Services': 2}],
                        //Row 2
                        [
                            {'Country': 1},

                            {'YTD': 1, alias: 'Topline YTD'},
                            {'Month Trend': 1, alias: 'Topline Month Trend'},
                            {'Goal': 1, alias: 'Topline Goal'}, 

                            {'YTD': 1, alias: 'Physical YTD'},
                            {'Goal': 1, alias: 'Physical Goal'}, 

                            {'YTD': 1, alias: 'Digital YTD'},
                            {'Goal': 1, alias: 'Digital Goal'}, 

                            {'YTD': 1, alias: 'Support YTD'},
                            {'Goal': 1, alias: 'Support Goal'}, 

                            {'YTD': 1, alias: 'Services YTD'},
                            {'Goal': 1, alias: 'Services Goal'},
                        ]
                    ];

                    this.table.bridge_settings = {
                        columns: {
                            'Topline Happy Customer Score': { hide: true },
                            'Physical': { hide: true },
                            'Digital': { hide: true },
                            'Support': { hide: true },
                            'Services': { hide: true }
                        }
                    }

                    this.table.icon_settings = {
                        'Topline YTD': {
                            comparison: 'Topline Goal',
                            icon: 'circle',
                            inverted: false,
                            colors: {
                                positive: '#0F0',
                                equal: '#00F',
                                negative: '#F00',
                            }
                        },
                        'Topline Month Trend': {
                            comparison: 'Topline YTD',
                            icon: 'arrow',
                            inverted: false,
                            colors: {
                                positive: '#0F0',
                                equal: '#00F',
                                negative: '#F00',
                            }
                        },
                        'Physical YTD': {
                            comparison: 'Physical Goal',
                            icon: 'circle',
                            inverted: false,
                            colors: {
                                positive: '#0F0',
                                equal: '#00F',
                                negative: '#F00',
                            }
                        },
                        'Digital YTD': {
                            comparison: 'Digital Goal',
                            icon: 'circle',
                            inverted: false,
                            colors: {
                                positive: '#0F0',
                                equal: '#00F',
                                negative: '#F00',
                            }
                        },
                        'Support YTD': {
                            comparison: 'Support Goal',
                            icon: 'circle',
                            inverted: false,
                            colors: {
                                positive: '#0F0',
                                equal: '#00F',
                                negative: '#F00',
                            }
                        },
                        'Services YTD': {
                            comparison: 'Services Goal',
                            icon: 'circle',
                            inverted: false,
                            colors: {
                                positive: '#0F0',
                                equal: '#00F',
                                negative: '#F00',
                            }
                        }
                    }

                    // Populate the rows
                    this.table.rows = d.map(row => {
                        let t = [row.caption];
                        let val = row.measures;
                        // Push the measures
                        t.push(
                            // Total values
                            val["total"]["YTD"] || null,
                            val["total"]["Month"] || null,
                            val["total"]["Goal"],

                            // Area values
                            val["physical"]["YTD"] || null,
                            val["physical"]["Goal"],
                            val["digital"]["YTD"] || null,
                            val["digital"]["Goal"],
                            val["support"]["YTD"] || null,
                            val["support"]["Goal"],
                            val["services"]["YTD"] || null,
                            val["services"]["Goal"],
                        );

                        return t;
                    });
                    this.table.render = true;
                }, 
                this.$helper.error
            );
        },
        update_view: function (market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function (newMarket, oldMarket) {
            this.update_view(newMarket);
        }
    }
}
</script>
