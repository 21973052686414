<template>
    <div class="assembly-component">
      <h1>Hej! I will be assembled, for now I’m just a picture. Please stay tuned!</h1>
      <img src="assets/images/home/IngkaFlightTower.jpg" alt= "Total Ingka Home"/>
    </div>
  </template>
  
  <script>
 export default {
   name: 'TotalIngkaHome' 
 };
  </script>

<style scoped>
.assembly-component {
  text-align: center;
  margin: 20px;
}

h1 {
  font-size: 1.2em;
  margin-bottom: 15px;
  padding: 1rem 0;
}

img {
  max-width: 100%;
  height: auto;
}
</style>
 
  