var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "grid" } }, [
    _c("div", {}),
    _c(
      "div",
      {
        staticClass: "item mrp5 ft-tooltip cursor-pointer",
        attrs: {
          tooltiptext:
            "Follow the flow of a customer bringing an item home from a store.",
        },
      },
      [_vm._m(0), _c("SubGrid", { attrs: { values: _vm.matrix[1] } })],
      1
    ),
    _c(
      "div",
      {
        staticClass: "item ft-tooltip cursor-pointer",
        attrs: {
          tooltiptext:
            "Follow the flow of IKEA delivering an item home for a customer.",
        },
      },
      [_vm._m(1), _c("SubGrid", { attrs: { values: _vm.matrix[2] } })],
      1
    ),
    _c(
      "div",
      {
        staticClass: "item ft-tooltip cursor-pointer",
        attrs: { tooltiptext: "Follow the flow of purchasing in the store." },
      },
      [_vm._m(2), _c("SubGrid", { attrs: { values: _vm.matrix[3] } })],
      1
    ),
    _c(
      "div",
      {
        staticClass: "item ft-tooltip cursor-pointer",
        attrs: {
          tooltiptext:
            "Customer bought an item in the store and has taken it home by themselves.",
        },
      },
      [
        _vm._m(3),
        _c("SubGrid", { attrs: { values: _vm.matrix[4], showLabels: false } }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "item ft-tooltip cursor-pointer",
        attrs: {
          tooltiptext:
            "Customer bought an item in the store and has it delivered home by IKEA.",
        },
      },
      [
        _vm._m(4),
        _c("SubGrid", { attrs: { values: _vm.matrix[5], showLabels: false } }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "item mtp5 ft-tooltip cursor-pointer",
        attrs: { tooltiptext: "Follow the flow of purchasing online." },
      },
      [_vm._m(5), _c("SubGrid", { attrs: { values: _vm.matrix[6] } })],
      1
    ),
    _c(
      "div",
      {
        staticClass: "item ft-tooltip cursor-pointer",
        attrs: {
          tooltiptext: "Customer bought an item online and collected in store.",
        },
      },
      [
        _vm._m(6),
        _c("SubGrid", { attrs: { values: _vm.matrix[7], showLabels: false } }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "item ft-tooltip cursor-pointer",
        attrs: {
          tooltiptext:
            "Customer bought an item online and got it delivered home by IKEA.",
        },
      },
      [
        _c("div", { staticClass: "white-square" }),
        _vm._m(7),
        _c("SubGrid", { attrs: { values: _vm.matrix[8], showLabels: false } }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "item mtp5 ft-tooltip cursor-pointer",
        attrs: { tooltiptext: "Follow the flow of purchasing remotely." },
      },
      [_vm._m(8), _c("SubGrid", { attrs: { values: _vm.matrix[9] } })],
      1
    ),
    _c(
      "div",
      {
        staticClass: "item ft-tooltip cursor-pointer",
        attrs: {
          tooltiptext:
            "Customer bought an item remotely and collected in store.",
        },
      },
      [
        _vm._m(9),
        _c("SubGrid", { attrs: { values: _vm.matrix[10], showLabels: false } }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "item ft-tooltip cursor-pointer",
        attrs: {
          tooltiptext:
            "Customer bought an item remotely and got it delivered home by IKEA.",
        },
      },
      [
        _c("div", { staticClass: "white-square" }),
        _vm._m(10),
        _c("SubGrid", { attrs: { values: _vm.matrix[11], showLabels: false } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title underline" }, [
      _c("div", { staticClass: "icon" }, [
        _c("i", { staticClass: "fas fa-taxi" }),
      ]),
      _c("b", [_vm._v("Customer")]),
      _vm._v(" to home "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title underline" }, [
      _c("div", { staticClass: "icon" }, [
        _c("i", { staticClass: "fas fa-shipping-fast" }),
      ]),
      _c("b", [_vm._v("IKEA")]),
      _vm._v(" to home "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title underline" }, [
      _c("div", { staticClass: "icon" }, [
        _c("i", { staticClass: "fas fa-shopping-basket" }),
      ]),
      _c("b", [_vm._v("Store")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("i", [_vm._v("Cash and Carry")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("i", [_vm._v("Cash and Deliver")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title underline" }, [
      _c("div", { staticClass: "icon" }, [
        _c("i", { staticClass: "fas fa-desktop" }),
      ]),
      _c("b", [_vm._v("Online")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("i", [_vm._v("Click and Collect")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("i", [_vm._v("Click and Deliver")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title underline" }, [
      _c("div", { staticClass: "icon" }, [
        _c("i", { staticClass: "fas fa-phone-volume" }),
      ]),
      _c("b", [_vm._v("Remote")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("i", [_vm._v("Call and Collect")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("i", [_vm._v("Call and Deliver")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }