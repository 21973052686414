<template>
    <div>
        <div class="title-bar">
            <div class="export-title">Reports Downloads</div>
            <div class="export-key">
                <div>
                    <i class="fas fa-file-word mr"></i>
                    Country Board Package
                </div>
            </div>
        </div>
        <div class="mt">
            <b>
                Downloads shown are for
                {{ $store.getters.selected_country_name }}
            </b>
        </div>
        <div class="mb">* The country selected in the top filter decides which country the download belong to *</div>
        <div v-if="current_year && Object.keys(formatted_downloads).length">
            <div v-for="(number, i) in Object.keys(formatted_downloads).length" :key="i" class="text-left ml mb">
                <div class="title">{{ current_year - i }}</div>
                <div
                    v-for="(week, j) in formatted_downloads[current_year + 1 - number]"
                    :key="j"
                    class="export mb"
                    @click="download_path(week)"
                >
                    <b>{{ get_week(week, i) }}</b>
                    <i class="fas fa-file-word ml"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            exports: {
                values: [],
                options: []
            },
            current_year: null,
            formatted_downloads: {}
        };
    },
    mounted: function () {
        this.$store.state.commenting.width = 0;
        this.get_docx();
    },
    destroyed: function () {
        this.$store.state.commenting.width = 4;
    },
    methods: {
        get_docx: function () {
            this.$http
                .post('/download/list', {
                    market: this.$store.getters.selected_country_name
                })
                .then(
                    succ => {
                        this.exports = {
                            values: succ.data.map(filter => filter.value),
                            options: succ.data.map(filter => filter.option)
                        };
                        this.format_downloads();
                    },
                    error => console.log(error)
                );
        },
        format_downloads: function () {
            this.formatted_downloads = {};
            this.current_year = new Date().getFullYear();
            for (let i = 0; i < 3; i++) {
                let filtered = this.exports.values.filter(value => value.includes(this.current_year - i));
                if (!filtered.length) continue;
                this.formatted_downloads[this.current_year - i] = filtered;
            }
        },
        download_path: function (file_name) {
            window.open(`${process.env.VUE_APP_BASE_URL}/download/docx/${file_name}`);
        },
        get_week: function (file, i) {
            return file.split('_')[0].replace(this.current_year - i, '');
        }
    },
    watch: {
        '$store.getters.market': function () {
            this.get_docx();
        }
    }
};
</script>

<style scoped>
.export {
    display: inline-flex;
    width: 6rem;
    height: 4rem;
    border-radius: 0.4rem;
    box-shadow: 0.25rem 0.25rem 0.5rem rgb(182, 182, 182);
    margin-right: 1rem;
    cursor: pointer;
    padding: 1.25rem 0 0 1rem;
}
.export:hover {
    background-color: rgb(231, 231, 231);
}

.title-bar {
    height: fit-content;
    padding-bottom: 1rem;
    width: 100vw;
    background-color: #ebebeb;
    margin-bottom: 0 !important;
}

.export-title {
    color: #363636;
    font-size: 2rem;
    padding: 2rem 2rem 0rem 2rem;
    text-align: left;
}

.export-key {
    text-align: left;
    padding-left: 2rem;
}

.fas {
    color: #0051ba;
    font-size: 1.2rem;
}
</style>
