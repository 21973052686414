<template>
<div>
    <quillEditor
        v-model="editorText"
        :options="editorOption"
        @change="textUpdate($event)"
    />
</div>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';

export default {
    props: {
        content: String
    },
    components: {
        quillEditor
    },
    data: function() {
        return {
            editorText: null,
            editorOption: {
                modules: {
                    toolbar: {
                        container: [
                            ['bold', 'italic', 'underline'],
                            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                            ['image', 'link']
                        ]
                    }
                }
            }
        }
    },
    mounted: function() {
        this.editorText = this.content;
    },
    beforeDestroy() {
        // Always destroy your editor instance when it's no longer needed
        this.editorOption.content = null;
    },
    methods: {
        textUpdate({ html, text }) {
            this.$emit('textUpdate', html);
        }
    }
}
</script>

<style>
.ql-container {
    border: 1px solid #cccccc !important;
    margin-top: 0.5rem;
    border-radius: 0.25rem;
}
.ql-editor {
    font-size: 1rem;
    background-color: #ffffff;
}
.ql-toolbar {
    border-radius: 0.25rem;
    width: fit-content;
    margin: auto;
    background-color: #ffffff;
}
</style>