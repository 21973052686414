<template>
<div class="app">
	<div class="progress-bar"><div id="progress_bar" class="fill"></div></div>
    <div class="columns is-gapless">
        <div :key="$store.state.commenting.width" class="column" :class="`is-${12 - Number($store.state.commenting.width)}`">
            <Spinner v-show="$store.state.loading !== 0" />
            <router-view />
        </div>

        <div class="column"
			:class="`is-${$store.state.commenting.width}`"
			v-if="$store.state.commenting.width > 0">
            <Comments/>
        </div>
    </div>
</div>
</template>

<script>
import Comments from '@/views/Comments.vue';
import PresentationMode from '@/components/presentation-mode/PresentationMode.vue';
import Spinner from '@/components/ui/Spinner.vue';

export default {
	components: {
		Comments,
		Spinner,
		PresentationMode,
	},
	data: function() {
		return {
			page: 0,
			pages: [
				'/customer/csc-service-level-phone/null',
				'/finance/after-sales-delivery-share',
				'/business/total-sales/sales-trend-total/summary/null',
				'/business/total-sales/sales-contribution-per-country',
				'/announcement'
			],
			interval_length: 30000,
			pb_width: 0
		}
	},
	mounted: function() {
		this.$store.dispatch('get_countries');
		this.$store.dispatch('get_hfbs');
		this.$store.dispatch('get_stores');

		/* Check if they've been redirected */
		let last_visited = localStorage.last_visited;
		if (last_visited) {
			last_visited = JSON.parse(last_visited);
			localStorage.removeItem('last_visited');
			if (this.$route.path !== last_visited.path) {
				this.$router.push(last_visited.path);
			}
		}

		if (this.$store.getters.presentation_mode == 1) {
			this.$router.push('/');
		} else {
			this.$router.push(this.pages[0]);
			this.page++;
			this.move_progress_bar();
	
			setInterval(() => {
				if (this.page >= this.pages.length) {
					this.page = 0;
				}
				this.$router.push(this.pages[this.page]);
				this.pb_width = 0;
				this.page++;
			}, this.interval_length);
		}
	},
	methods: {
		move_progress_bar: function() {
			let pb = document.getElementById('progress_bar');
			
			let interval_length = 100;
			let increments = this.interval_length / interval_length; // Work out how much we need to increment each time
			let increment = 100 / increments;
			let interval = setInterval(() => {
				this.pb_width += increment;
				pb.style.width = `${this.pb_width}%`;
			}, interval_length);
		}
	},
	computed: {
		presentation_mode: function() {
			return this.$store.getters.presentation_mode;
		},
	}
}
</script>

<style scoped>
.app {
	color: #2c3e50;
	font-family: 'NotoIKEALatin-Regular';
	margin-top: -3.5rem !important;
	text-align: center;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.column {
  	transition: all 0.5s ease-in;
}

.half-height {
  	height: 50vh;
}

#nav {
  	padding: 30px;
}

#nav a {
	color: #2c3e50;
	font-weight: bold;
}

#comment-widget {
	top: 0.2rem;
	height: 100vh;
}

.options-bar {
	display: inline-block;
	margin-top: -0.5rem;
	padding: 0rem 1rem 1rem 1rem;
	width: 100%;
}

.options-bar-sub {
  	text-align: left;
}

.options-bar-sub p {
  	padding-top: 0.3rem;
}

.progress-bar {
	height: 0.2rem;
	width: 100%;
}

.progress-bar .fill {
	background-color: #0051BA;
	height: 0.2rem;
	transition: all 0.1s;
}
</style>

