var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: { title: "Cost Per Flow", location: _vm.location },
      }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons() },
          }),
          _c(
            "div",
            { staticClass: "label-container" },
            [
              _c("p", [_vm._v("Flow")]),
              _c("DropDown", {
                key: _vm.filter,
                attrs: {
                  options: _vm.options,
                  values: _vm.values,
                  default: _vm.filter,
                },
                on: { updateDropDown: _vm.update_filter },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                key: _vm.$store.getters.market,
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: _vm.$store.getters.market,
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size,
          staticClass: "section three-quarters-height box ms",
        },
        [
          _vm.double.render
            ? _c("DeviationWith3Lines", {
                attrs: {
                  title: `Showing data for: ${
                    _vm.$store.getters.selected_country_name ===
                    "Total Retail Countries"
                      ? "Total"
                      : _vm.$store.getters.selected_country_name
                  }`,
                  showtitle: !_vm.$store.state.global.bot_mode,
                  subtitle: "",
                  xcategories: _vm.double.xcategories,
                  yaxislabel1: "",
                  ymetric1: "€",
                  yaxislabel2: "",
                  ymetric2: "",
                  yaxismax1: _vm.double.yaxismax1,
                  yaxismax2: _vm.double.yaxismax2,
                  seriesname1: `Flow Index vs Goal (${
                    _vm.filter === "store-distribution-high-flow" ? "m3" : "OL"
                  })`,
                  seriestype1: "line",
                  seriesset1: _vm.double.seriesset2,
                  color1: _vm.$store.state.colors["dark-grey"],
                  seriestype2: "line",
                  seriestype3: "line",
                  seriesname4: `SD HF - €/${
                    _vm.filter === "store-distribution-high-flow" ? "m3" : "OL"
                  }`,
                  seriestype4: "bar",
                  seriesset4: _vm.double.seriesset1,
                  color4: _vm.$store.state.colors["yellow"],
                  show: !_vm.$store.state.global.bot_mode,
                },
              })
            : _c("div", [
                _vm._v(
                  " No data found for " +
                    _vm._s(_vm.$store.getters.selected_country_name)
                ),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }