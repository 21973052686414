// Functions to process data stored in ft_load_tracker_kpis table for display

import cronparser from 'cron-parser';
import { getDay, getISOWeek,
    isThisWeek, isThisMonth, isThisYear, isWithinInterval,
    addDays, addYears,
    subSeconds, subDays, subWeeks, subMonths } from 'date-fns';
const helper = require('./index.js');
const IS_NOT_PROD = process.env.VUE_APP_COOKIE !== 'FT-JWT-PROD';

const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

/**
 * Main funcion that will process rows from ft_load_tracker_kpis (typically the only function that needs importing)
 * @param {object} kpi - kpi object as returned from querying ft_load_tracker_kpis
 * @returns {object} The same kpi object with new and updated properties for dispaying to user
 */
const process_load_tracker_table_row = kpi => {
    kpi = format_current_version(kpi);
    // kpi = format_lag(kpi); // Presently lag is not being displayed so will skip for speed
    kpi = compute_processing_dates(kpi);
    kpi = compute_expected_version(kpi);
    kpi = compute_status_indicator(kpi);
    kpi = describe_status(kpi);
    return kpi;
}


/**
 * @param versions formatted as arrays of [year, month, week, day] should be comparable with null in non applicable elements
 * @returns {number} where 0 = same version, <0 means current is behind expected, >0 means current is ahead of expected
 */
const compare_versions = (current_version, expected_version) => {
    if (current_version.some((val, i) => val !== null && !expected_version[i])) return null // versions must be equally formatted

    if (current_version[0] !== expected_version[0]) return current_version[0] - expected_version[0]; // year
    if (current_version[1] !== expected_version[1]) return current_version[1] - expected_version[1]; // month
    if (current_version[2] !== expected_version[2]) return current_version[2] - expected_version[2]; // week
    if (current_version[3] !== expected_version[3]) return current_version[3] - expected_version[3]; // day
    return 0;
}

const compute_expected_version = kpi => {
    const stop = (custom_message = '') => {
        if (custom_message) custom_message += '\n';
        if (IS_NOT_PROD) console.error(`${kpi.report}: Failed to compute_expected_version()\n${custom_message}version: ${version}\nprocessing_end: ${processing_end}`);
        kpi.expected_version = null;
        kpi.formatted_expected_version = '';
        return kpi;
    };

    const {frequency_label, processing_start, processing_end, version, lag} = kpi;
    if (!version || !processing_start || !processing_end) stop();

    let expected_date = subSeconds(new Date(), lag || 0);
    
    switch (frequency_label) {
        case 'Weekly':
            if (isThisWeek(processing_end)) expected_date = subWeeks(expected_date, 1);
            break;
        case 'Monthly':
            if (isThisMonth(processing_end)) expected_date = subMonths(expected_date, 1);
            break;
        case 'Yearly':
            if (!isThisYear(processing_end)) expected_date = addYears(expected_date, 1);
            break;
        default:
            stop('Unrecognised frequency_label');
    }

    kpi.expected_version = [
        expected_date.getFullYear(),
        expected_date.getMonth() + 1,
        getISOWeek(expected_date),
        null // day to be added in the future (currently only used by one kpi)
    ].map((value, i) => !!version[i] || version[i] === 0 ? value : null); // Sync expected version with version for specific kpi (same null elements)

    kpi.formatted_expected_version = format_version_date(kpi.expected_version);
    return kpi;
}


const compute_processing_dates = kpi => {
    const stop = (custom_message = '') => {
        if (custom_message) custom_message += '\n';
        if (IS_NOT_PROD) console.error(`${kpi.report}: Failed to compute_processing_dates()\n${custom_message}processing_frequency: ${processing_frequency}\nprocessing_duration: ${processing_duration}`);
        kpi.processing_start = '';
        kpi.processing_end = '';
        return kpi;
    };

    const {processing_frequency, processing_duration} = kpi;
    if (!processing_frequency) stop();
    
    let processing_start, processing_end;
    try {
        processing_end = parse_custom_cron_expression(processing_frequency);
    } catch(err) {
        stop(err);
    }
    processing_start = subSeconds(processing_end, processing_duration || 0);

    kpi.processing_start = processing_start;
    kpi.processing_end = processing_end;
    return kpi;
}


const compute_status_indicator = kpi => {
    const stop = (custom_message = '') => {
        if (custom_message) custom_message += '\n';
        if (IS_NOT_PROD) console.error(`${kpi.report}: Failed to compute_status_indicator()\n${custom_message}version: ${version}\nexpected_version: ${expected_version}\nprocessing_start: ${processing_start}\nprocessing_end: ${processing_end}`);
        kpi.status_indicator = 'is-invisible';
        return kpi;
    };

    const {version, expected_version, processing_start, processing_end} = kpi;
    if (!version || !expected_version || !processing_start || !processing_end) stop();
    
    const version_status = compare_versions(version, expected_version);
    if (version_status > 0) {
        kpi.status_indicator = 'has-text-success';
    } else if (version_status < 0) {
        kpi.status_indicator = 'has-text-danger';
    } else if (version_status === 0) {
        const currently_in_processing_interval = isWithinInterval(new Date(), { start: processing_start, end: processing_end });
        kpi.status_indicator = currently_in_processing_interval ? 'has-text-warning' : 'has-text-success';
    } else {
        stop('Invalid version_status');
    }

    return kpi;
}

const convert_calendar_year_to_financial_year = (year, month, week) => {
    if (!year || (month && week)) throw new Error(`Failed converting params to FY: ${year} ${month} ${week}`);
    year = parseInt(year);
    month = parseInt(month);
    week = parseInt(week);
    if (month) return month >= 9 ? year + 1 : year;
    if (week) return week >= 35 ? year + 1 : year;
    return year;
}

const describe_status = kpi => {
    switch (kpi.status_indicator) {
        case 'has-text-success':
            kpi.status_description = 'Data is up to date';            
            break;
        case 'has-text-warning':
            kpi.status_description = 'Fresh data is being loaded';            
            break;
        case 'has-text-danger':
            kpi.status_description = 'Data is behind schedule';            
            break;
        case 'is-invisible':
        default:
            kpi.status_description = 'Status unavailable';
            break;
    }
    return kpi;
}

const format_current_version = kpi => {
    const stop = (custom_message = '') => {
        if (custom_message) custom_message += '\n';
        if (IS_NOT_PROD) console.error(`${kpi.report}: Failed to format_current_version()\n${custom_message}version: ${version}\nversion_format: ${version_format}`);
        kpi.version = null;
        kpi.formatted_current_version = null;
        return kpi;
    };

    const {version, version_format} = kpi;
    const version_str = version.toString();
    if (!version_str || !version_format || version_str.length !== version_format.length) stop();
    
    let year = [];
    let month = [];
    let week = [];
    let day = [];
    version_format.split('').forEach((char, i) => {
        switch (char) {
            case 'Y': year.push(version_str[i]); break;
            case 'M': month.push(version_str[i]); break;
            case 'W': week.push(version_str[i]); break;
            case 'D': day.push(version_str[i]); break;
            default: stop('Unexpected char in version_format.');
        };
    });

    if (month.length && week.length) stop('version_format should not contain both months and weeks.');

    // Store version as [year, month, week, day] with null values in unused elements.
    kpi.version = [year, month, week, day].map(arr => parseInt(arr.join(''))).map(num => isNaN(num) ? null : num);
    if (kpi.version[0] < 2000) kpi.version[0] += 2000;
    kpi.formatted_current_version = format_version_date(kpi.version);
    return kpi;
}

//not in use
const format_frequency = kpi => {
    const stop = (custom_message = '') => {
        if (custom_message) custom_message += '\n';
        if (IS_NOT_PROD) console.error(`${kpi.report}: Failed to format_frequency()\n${custom_message}processing_frequency: ${processing_frequency}`);
        kpi.frequency = null;
        return kpi;
    };

    const {processing_frequency} = kpi;
    if (!processing_frequency) stop();

    try {
        const interval = cronparser.parseExpression(processing_frequency);
        const prev = new Date(interval.prev());
        const next = new Date(interval.next());
        const interval_period = next.getTime() - prev.getTime();

        kpi.frequency = generalise_ms_period(interval_period, true);
        return kpi;
    } catch {
        stop();
    }
}

const format_lag = kpi => {
    const {lag} = kpi;
    if (lag == 0) {
        kpi.lag = 'None';
        return kpi;
    }
    if (!lag || typeof(lag) !== 'number') {
        if (IS_NOT_PROD) console.error(`${kpi.report}: Failed to format_lag()\nlag: ${lag}`);
        kpi.lag = '';
        return kpi;
    }
    kpi.formatted_lag = generalise_ms_period(lag);
    return kpi;
}


const format_version_date = ([year, month, week, day]) => {
    try {
        year = convert_calendar_year_to_financial_year(year, month, week);
    } catch(err) {
        console.error(err);
        return '';
    }
    month = MONTHS[parseInt(month) - 1] || month;
    year = year % 100;
    return `FY${year} ${month || ''} ${week ? `Week ${week}` : ''} ${day ? `Day ${day}` : ''}`;
}


const generalise_ms_period = period => {
    const hours = helper.round(period / (60 * 60));
    if (hours < 24) return `${hours} hour${hours == 1 ? '' : 's'}`;

    const days = helper.round(hours / 24);
    if (days%7 != 0 && days < 28) return `${days} day${days == 1 ? '' : 's'}`;

    const weeks = helper.round(days / 7);
    if (weeks%4 != 0 && weeks < 8) return `${weeks} week${weeks == 1 ? '' : 's'}`;

    const months = helper.round(weeks / 4);
    if (months%12 == 0) return `${months/12} year${months == 12 ? '' : 's'}`;
    else return `${months} month${months == 1 ? '' : 's'}`;
}


const parse_custom_cron_closest_day_of_week = cron_expression => {
    const day_of_week = cron_expression.slice(-1);
    cron_expression = cron_expression.slice(0, -1) + '*';

    let next_end;
    try {
        const interval = cronparser.parseExpression(cron_expression);
        next_end = new Date(interval.next());
    } catch(err) {
        throw new Error(err);
    }
    /**
     * @param date date to start searching from
     * @param day day of week to find (0-6) where 0 is Sunday
     * @param i iteration or recusion, not to be manually initialised
     * @returns closest date to starting date that is on specified day of the week
     */
    const recursive_search = (date, day, i = 0) => {
        if (getDay(date) === parseInt(day)) return date;
        i++;
        if (i%2 === 0) date = addDays(date, i);
        else date = subDays(date, i)
        return recursive_search(date, day, i);
    };

    return recursive_search(next_end, day_of_week);
}


const parse_custom_cron_expression = cron_expression => {
    if (!cron_expression.includes('#')) { // not a custom cron espression, parse normally
        try {
            const interval = cronparser.parseExpression(cron_expression);
            return new Date(interval.next());
        } catch(err) {
            throw new Error(err);
        }
    } else { // Expression starts with # therefore is custom
        cron_expression = cron_expression.slice(1);
        // Check for each custom option - ensure that there is no overlap
        if (cron_expression.includes('C')) { // Closest weekday to a day of the month (e.g. 0 0 20 * 1C = closest Monday to the 20th of the month)
            return parse_custom_cron_closest_day_of_week(cron_expression.slice(0, -1));
        } else {
            throw new Error(`Unable to parse custom cron expression: ${cron_expression}`);
        }
    }
}

export default {
    process_load_tracker_table_row
};
