var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Modal", {
        class: { "is-active": _vm.is_active },
        attrs: {
          title: "Retail Flight Tower",
          closeable: false,
          id: "access-modal",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("p", { staticClass: "text-large text-bold" }, [
                  _vm._v(
                    "Welcome to Retail Flight Tower, let's figure out your level of access"
                  ),
                ]),
                _c("div", { staticClass: "text-left content" }, [
                  _c("p", [_vm._v("Choose one of the following access types")]),
                  _c(
                    "table",
                    {
                      staticClass: "table is-striped is-hoverable is-fullwidth",
                    },
                    [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v("Selection")]),
                          _c("th", [_vm._v("Access Type")]),
                          _c("th", [_vm._v("Description")]),
                          _c("th", [_vm._v("Manager Approval Required?")]),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.roles_table, function (row, index) {
                          return _c(
                            "tr",
                            {
                              key: index,
                              staticClass: "cursor-pointer",
                              on: {
                                click: function ($event) {
                                  _vm.selected_role = row.access_type
                                },
                              },
                            },
                            [
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selected_role,
                                      expression: "selected_role",
                                    },
                                  ],
                                  staticClass: "radio",
                                  attrs: { type: "radio", name: "job-title" },
                                  domProps: {
                                    value: row.access_type,
                                    checked: _vm._q(
                                      _vm.selected_role,
                                      row.access_type
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.selected_role = row.access_type
                                    },
                                  },
                                }),
                              ]),
                              _c("td", [_vm._v(_vm._s(row.access_type))]),
                              _c("td", [_vm._v(_vm._s(row.description))]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    row.manager_approval
                                      ? "Yes, BASIC access will be granted while awaiting approval."
                                      : "No."
                                  )
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                  _c("p", [_vm._v("Which access is right for me?")]),
                  _c(
                    "table",
                    {
                      staticClass: "table is-striped is-hoverable is-fullwidth",
                    },
                    [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v("BASIC")]),
                          _c("th", [_vm._v("ADVANCED")]),
                          _c("th", [_vm._v("CONTRIBUTOR")]),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.access_descriptions, function (desc, index) {
                          return _c("tr", { key: index }, [
                            _c("td", [_vm._v(_vm._s(desc.basic))]),
                            _c("td", [_vm._v(_vm._s(desc.advanced))]),
                            _c("td", [_vm._v(_vm._s(desc.contributor))]),
                          ])
                        }),
                        0
                      ),
                    ]
                  ),
                ]),
                _c("hr"),
                _c("div", { staticClass: "text-left text-small" }, [
                  _c("b", { staticClass: "text-medium text-center" }, [
                    _vm._v("Collection of personal information"),
                  ]),
                  _c("p", [
                    _vm._v(
                      "When you use Retail Flight Tower we will collect your personal data related to the usage of Retail Flight Tower."
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "The personal information collected by Retail Flight Tower will be treated in accordance with the Group Rule on Data Privacy."
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "We will only retain personal information within Retail Flight Tower for as long as is needed and in line with the retention schedule of the platforms."
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "The law on data protection gives you certain rights over your personal information. If you would like more information on your rights and how to exercise them please visit the Individual Rights internal site (Inside - Co-worker individual rights)"
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "buttons",
            fn: function () {
              return [
                _c("div", { staticClass: "buttons" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button ikea-blue text-white",
                      attrs: { disabled: _vm.is_confirmed },
                      on: { click: _vm.confirm },
                    },
                    [_vm._v("Confirm")]
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }