<template>
<div>
    <Title
        title="Channel Contribution per Country"
        :location="location"
    />

    <div class="options-bar">
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
        <div class="options-bar mt">
            <div class="buttons has-addons">
                <div class="button is-success" v-for="(country, index) in available_list" :key="index" 
                    @click="choose(country)"
                    :class="`${remove_list.indexOf(country) !== -1 ? 'is-danger' : ''}`"
                >
                    {{country}}
                </div>
                <div class="button is-warning" @click="remove_all()">
                    Remove all
                </div>
                <div class="button is-warning" @click="add_all()">
                    Add all
                </div>
                <div class="button is-warning" @click="top_8()">
                    Top 10 Countries
                </div>
            </div>
            
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms mb">
        <StackLineFlex
            :key="stack_line_flex.render"
            v-if="stack_line_flex.render"

            title="Showing the Channel Contribution for each country"
            :showtitle="!$store.state.global.bot_mode"

            subtitle="The height of the bars represents the value in TEUR and the values showing in the bars represents the YTD Index."

            :xcategories="stack_line_flex.xcategories"
            yaxislabel="Growth contribution KEUR"
            ymetric=""
            
            type="bar"
            :color1="$store.state.colors['yellow']"
            color2="#0051ba"
            color3="#787878"
            color4="#008000"

            seriesname1="Store Comp"
            :seriesset1="stack_line_flex.seriesset1"
            
            seriesname2="Store non-Comp"
            :seriesset2="stack_line_flex.seriesset2"
            
            seriesname3="Online"
            :seriesset3="stack_line_flex.seriesset3"

            seriesname4="Remote"
            :seriesset4="stack_line_flex.seriesset4"

            seriesname5="Total Growth"
            :seriesset5="stack_line_flex.seriesset5"
        />
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import StackLineFlex from '@/components/visualisations/general/StackLineFlex.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        StackLineFlex,
        Title
    },
    data: function() {
        return {
            stack_line_flex: {
                title: null,
                subtitle: null,

                xcategories: [],
                yaxislabel: null,
                ymetric: null,
                
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],
                seriesset4: [],
                seriesset5: [],
                
                render: false
            },
            remove_list: [],
            available_list: [],
            store: null,
            location: '/business/total-sales/channel-contribution-per-country'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.top_8();
    },
    methods: {
        load_market_data: function() {
            this.stack_line_flex.render = false;
            let url = '/business/total-sales/channel-contribution-per-country/market';
            let succ = (succ) => {
                
                let d = succ.data;
                this.stack_line_flex.xcategories = [];
                this.available_list = [];
                this.stack_line_flex.seriesset1 = [];
                this.stack_line_flex.seriesset2 = [];
                this.stack_line_flex.seriesset3 = [];
                this.stack_line_flex.seriesset4 = [];
                this.stack_line_flex.seriesset5 = [];
                
                d.forEach(country => {
                    let val = country.measures['YTD Index'];
                    const removed_index = this.remove_list.indexOf(country.market);
                    this.available_list.push(country.market);
                    if (removed_index === -1) {
                        this.stack_line_flex.xcategories.push(country.market)
                        this.stack_line_flex.seriesset1.push(val['Store comp'])
                        this.stack_line_flex.seriesset2.push(val['Store non-comp'])
                        this.stack_line_flex.seriesset3.push(val['Online'])
                        this.stack_line_flex.seriesset4.push(val['Remote'])
                        this.stack_line_flex.seriesset5.push(val['Total growth'])
                    }
                });
                this.stack_line_flex.render = true;
            }
            let error = (error) => console.log(error);

            if (this.$helper.market_check(this.market) === null && this.store === null) {
                this.$http.post(url, 
                        {market: 'ALL', parent: null}
                    ).then(succ, error);
            } else {
                if (this.store == null) {
                    this.$http.post(url, 
                        {market: this.$helper.market_check(this.market), parent: 'ALL'}
                    ).then(succ, error);
                } else {
                    this.$http.post(url,
                        {market: this.store, parent: this.$helper.market_check(this.market)}
                    ).then(succ, error);
                }
            }
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: `${this.location}`,
            });
        },
        choose: function(country) {
            let index = this.remove_list.indexOf(country)
            if (index === -1) {
                this.remove_list.push(country);
            } else {
                this.remove_list.splice(index, 1)
            }
            this.load_market_data();
        },
        remove_all: function() {
            this.remove_list = this.available_list;
            this.load_market_data();
        },
        add_all: function() {
            this.remove_list = [];
            this.load_market_data();
        },
        top_8: function() {
            this.remove_list = ['ALL'].concat(this.$store.state.countries.values);
            const top_8_countries = this.$store.state.global.top_8_countries;

            for (let i = 0; i < top_8_countries.length; i++) {
                this.remove_list.splice(this.remove_list.indexOf(top_8_countries[i]), 1);
            }
            this.load_market_data();
        }
    },
    watch: {
        '$store.state.countries.values': {
            handler: function(newValue) {
                this.top_8();
            },
            deep: true
        }
    }
}
</script>

<style>

</style>
