<template>
<div>
    <Title
        title="Sales Tree"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
 
        <div class="label-container">
            <p>Filter</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>

    <TableNestedHeaders
        v-if="table.render"

        :headers="table.headers"
        :rows="table.rows"
        
        :icon-settings="table.icon_settings"
        :collapse-settings="table.collapse_settings"
        :nest-settings="table.nest_settings"
        :sort-settings="table.sort_settings"

        @clickedTable="table_update"
    />

</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/Table.vue';
import TableNestedHeaders from '@/components/visualisations/table/TableNestedHeaders.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        TableNestedHeaders,
        Title
    },
    data: function() {
        return {
            table: {
                headers: [],
                render: false,
                rows: [],
            },
            options: [],
            values: [],
            filter: null,
            location: '/business/sales-tree-hidden'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Detailed',
                    link: `${this.location}/detailed`
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                }
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'sales-tree-channel-comp-1'
            }).then(
                succ => {
                    succ.data.map(filter => {
                        this.values.push(filter.value);
                        this.options.push(filter.option);
                    });
                    
                    let filter = this.$store.state.global.temp_filter;
                    if (this.values.indexOf(filter) > 0) {
                        this.filter = filter;
                    } else {
                        this.filter = this.values[0];
                    }
                },
                this.$helper.error
            ).finally(() => this.load_ingka_by_country());
        },
        load_ingka_by_country: function() {
            let url = 'business/sales-tree/total-ingka-by-country';
            let filter = this.filter;
            
            let succ = (succ) => {
                let d = succ.data;
                this.table.headers = null;
                this.table.rows = null;

                let filtered_measures = d[0].measures[filter];
                let level_1_keys = Object.keys(filtered_measures);
                let level_2_keys = level_1_keys.map(key => Object.keys(filtered_measures[key]));

                let level_2 = [{'Country': 1}];
                level_2_keys.forEach(keys => {
                    keys.forEach(key => {
                        let obj = {};
                        obj[key] = 1;
                        level_2.push(obj);
                    })
                });

                let level_1_headers = [
                    {'': 1},
                    {'Visitors': 2},
                    {'Customers, gross': 2},
                    {'Conversion': 2},
                    {'Avg. ticket': 2},
                    {'Created Sales, gross': 2},
                    
                    {'Created Sales, net': 2},
                    {'Gap (Created - Delivered)': 2},
                    {'Delivered Sales, net': 2},
                ];

                if (filter === 'Online') {
                    level_1_headers = [
                        {'': 1},
                        {'Visitors': 2},
                        {'Customers, gross (Online)': 2},
                        {'Conversion': 2},
                        {'Avg. ticket': 2},
                        {'Created Sales, gross (Online)': 2},
                        
                        {'Created Sales, net (Online+CSC)': 2},
                        {'Gap (Created - Delivered)': 2},
                        {'Delivered Sales, net (Online+CSC)': 2},
                    ];
                }

                let headers = [
                    level_1_headers,
                    [
                        {'Country': 1},
                        {'YTD Index': 1},
                        {'4WK Index': 1},
                        {'YTD Index': 1},
                        {'4WK Index': 1},
                        {'YTD %': 1},
                        {'4WK %': 1},
                        {'YTD EUR': 1},
                        {'4WK EUR': 1},
                        {'YTD Index': 1},
                        {'4WK Index': 1},
                        {'YTD Index': 1},
                        {'4WK Index': 1},
                        {'YTD %': 1},
                        {'4WK %': 1},
                        {'YTD Index': 1},
                        {'4WK Index': 1},
                    ]
                ];

                this.table.nest_settings = {
                    rows: 2,
                    headers
                }

                let level_2_w_o_country = level_2.slice(1);
                this.table.rows = d.map(
                    row => {
                        let r = row.measures[filter];
                        return [
                            row.caption
                        ].concat(
                            [
                                this.$helper.maybe(r['Visitors']['YTD Index']),
                                this.$helper.maybe(r['Visitors']['4WK Index']),
                                this.$helper.maybe(r['Customers, gross']['YTD Index']),
                                this.$helper.maybe(r['Customers, gross']['4WK Index']),
                                this.$helper.maybe(r['Conversion']['YTD %']),
                                this.$helper.maybe(r['Conversion']['4WK %']),
                                this.$helper.maybe(r['Avg. ticket']['YTD EUR']),                                
                                this.$helper.maybe(r['Avg. ticket']['4WK EUR']),
                                this.$helper.maybe(r['Created Sales, net']['YTD Index']),
                                this.$helper.maybe(r['Created Sales, net']['4WK Index']),
                                
                                this.$helper.maybe(r['Created Sales, gross']['YTD Index']),
                                this.$helper.maybe(r['Created Sales, gross']['4WK Index']),
                                this.$helper.maybe(r['Gap (Created - Delivered)']['YTD %']),
                                this.$helper.maybe(r['Gap (Created - Delivered)']['4WK %']),
                                this.$helper.maybe(r['Delivered Sales, net']['4WK Index']),
                                this.$helper.maybe(r['Delivered Sales, net']['YTD Index']),
                            ]
                        )
                    }
                );

                this.table.headers = [
                    'Country',
                    'Visitors YTD Index',
                    'Visitors 4WK Index',
                    'Customers YTD Index',
                    'Customers 4WK Index',
                    'Conversion YTD %',
                    'Conversion 4WK %',
                    'Avg. ticket YTD EUR',
                    'Avg. ticket 4WK EUR',
                    'Created Sales, gross YTD Index',
                    'Created Sales, gross 4WK Index',
                    
                    'Created Sales, net YTD Index',
                    'Created Sales, net 4WK Index',
                    'Gap (Created - Delivered) YTD %',
                    'Gap (Created - Delivered) 4WK %',
                    'Delivered Sales, net YTD Index',
                    'Delivered Sales, net 4WK Index',
                ];
                
                this.table.collapse_settings = [
                    'Created Sales, net YTD Index',
                    'Created Sales, net 4WK Index',
                    'Gap (Created - Delivered) YTD %',
                    'Gap (Created - Delivered) 4WK %',
                    'Delivered Sales, net YTD Index',
                    'Delivered Sales, net 4WK Index',
                ]

                this.table.icon_settings = {
                    'Avg. ticket 4WK EUR': {
                        comparison: 'Avg. ticket YTD EUR',
                        icon: 'arrow'
                    },
                    'Conversion 4WK %': {
                        comparison: 'Conversion YTD %',
                        icon: 'arrow'
                    },
                    'Created Sales, gross 4WK Index': {
                        comparison: 'Created Sales, gross YTD Index',
                        icon: 'arrow'
                    },
                    'Created Sales, net 4WK Index': {
                        comparison: 'Created Sales, net YTD Index',
                        icon: 'arrow'
                    },
                    'Customers 4WK Index': {
                        comparison: 'Customers YTD Index',
                        icon: 'arrow'
                    },
                    'Delivered Sales, net 4WK Index': {
                        comparison: 'Delivered Sales, net YTD Index',
                        icon: 'arrow'
                    },
                    'Gap (Created - Delivered) 4WK %': {
                        comparison: 'Gap (Created - Delivered) YTD %',
                        icon: 'arrow'
                    },
                    'Visitors 4WK Index': {
                        comparison: 'Visitors YTD Index',
                        icon: 'arrow'
                    },
                }

                this.table.sort_settings = {
                    asc: true,
                    column: 0
                }

                this.table.render = true;
            }

            let error = (error) => console.log(error);
            
            if (this.filter) {
                this.$http.post(url, {market: 'ALL', filter}).then(succ, error);
            }
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
        },
        table_update: function(country_name) {
            let market_index = this.$store.getters.countries.options.findIndex(country => country === country_name);
            if (market_index > -1) {
                let market = this.$store.getters.countries.values[market_index];
                this.update_view(market);
            } else {
                this.update_view(null);
            }
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        },
        filter: function() {
            this.load_ingka_by_country();
        }
    }
}
</script>
