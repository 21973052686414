<template>
<div>
	<AdminUniversal
		component="title"
		:props="title_props"
	/>
	<AdminUniversal
		v-if="table_props.render"
		component="table"
		:props="table_props"
	/>
	<div v-else>No Retail Managers to display</div>

	<hr>

	<AdminUniversal
		component="title"
		:props="title_props2"
	/>
	<div class="rm-form">
		<label class="label">Select Country</label>
		<DropDown
			v-if="$store.state.countries.options"
			:key="$store.state.selected.country"
			:options="$store.state.countries.options"
			:values="$store.state.countries.values"
			:default="$store.state.countries.values[0]"
			@updateDropDown="update_selected_country"
		/>
	</div>
	<div class="rm-form">
		<label class="label">Search User</label>
		<UserSearch
			class="user-search"
			@SelectedUser="update_selected_user"
		/>
	</div>
	<AdminUniversal
		component="button"
		:props="button_props"
	/>
</div>
</template>

<script>
import AdminUniversal from '@/components/admin/index.vue';
import DropDown from '@/components/ui/DropDown.vue';
import UserSearch from '@/components/admin/UserSearch.vue';

export default {
	components: {
		AdminUniversal,
		DropDown,
		UserSearch
	},
	data: function() {
		return {
			title_props: {
                title: 'Retail Managers',
                subtitle: ''
            },
			table_props: {
                headers: ['Country', 'First Name', 'Last Name'],
                data: null,
                render: false
            },
			title_props2: {
                title: 'Add or Edit Retail Managers'
            },
            button_props: {
                label: 'Update Retail Manager',
                classes: 'is-success',
                method: this.add_retail_manager
            },

			selected_country: '',
			selected_user: '',
		}
	},
	mounted: function() {
		this.load_table_data();
	},
	methods: {
		add_retail_manager: function() {
			this.$http.post('/admin/retail-managers/update', {
				user_id: this.selected_user,
				country: this.selected_country
			})
			.then(
				succ => this.load_table_data(),
				this.$helper.error
			);
		},
		load_table_data: function() {
			this.table_props.render = false;
			this.$http.get('force', '/admin/retail-managers/load')
			.then(
				succ => {
					let d = succ.data;
					this.table_props.data = d;
					this.table_props.render = true;
				},
				this.$helper.error
			);
		},
		update_selected_country: function(country) {this.selected_country = country;},
		update_selected_user: function(user) {this.selected_user = user;}
	}
}
</script>

<style scoped>
.dropdown-menu {
	max-height: 20vh;
	overflow-y: scroll;
    /* overflow-x: hidden; */
}
.input {
	width: auto;
}
.rm-form {
	margin-top: 1em;
	text-align: left;
}
.user-search {
	margin-left: -16px;
	max-width: 500px;
}
</style>
