import table_parser from './table.js';
import graph_parser from './graph.js';
import simple_graph_parser from './simple_graph.js';
import top_10_parser from './top_10.js';

const universal_parser = (data_type, data, page_data = {}, applied_filters = [], market = null, other_options = null) => {
    if (!data || !data.data) return;
    const d = data.data;
    switch (data_type) {
        case 'Table':
            return table_parser(d, page_data, applied_filters, other_options);
            break;
        case 'Graph':
            return graph_parser(d, page_data, applied_filters, market);
            break;
        case 'SimpleGraph':
            return simple_graph_parser(d, page_data, applied_filters, market);
            break;
        case 'Top10':
            return top_10_parser(d, page_data, applied_filters, market);
            break;
        default:
            console.error(`Unrecognised data type: ${data_type}`);
            break;
    }
}

export default universal_parser;
