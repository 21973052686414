
<template>
    <div :id="title" style="width: 100%; height:100%;"></div>
</template>

<script>
import * as echarts from 'echarts/dist/echarts.min.js';
export default {
    props: {
        title: String, //title of the graph
        showtitle: Boolean,
        subtitle: String, //subtitle of the graph
        xcategories: Array, //data that will be displayed on the x axis
        yaxislabel: String, //name of the data on the y axis e.g 'After sales delivery share' or 'services sale by month'
        ymetric: String, //'%', '£', 'kg' etc.
        type: String, //bar

        color1: String, //color of the bar lie or scatter for the paticular data set
        color2: String,
        color3: String,

        seriesname1: String, //name of the data such as IKEA 
        seriesname2: String,
        seriesname3: String,

        seriesset1: Array,
        seriesset2: Array,
        seriesset3: Array,

        show: Boolean, // true to show the exta features such as 'save graph as image', 'refresh', 'change to bar graph', etc
    },
    mounted: function() {
        let myChart = echarts.init(document.getElementById(this.title));

        let NumberSet = this.seriesset1.concat(this.seriesset2).concat(this.seriesset3);

        let highest = 0;
        let lowest = 100;
        NumberSet.forEach(val => {
            if (val > highest) highest = val;
            if (val < lowest) lowest = val;
        })
        highest = Math.ceil((highest + 1 - 100).toFixed(0)/5)*5;
        lowest  = Math.floor((lowest - 101).toFixed(0)/5)*5;
        let option = {
            title:{
                text: this.title,
                subtext: this.subtitle,
                show: this.showtitle,
                subtextStyle: {
                    color: 'black',
                },
                top: '-5em',
            },
            tooltip: {
                show: false,
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            toolbox: {
                top: '85em',
                right: '30em',
                show: this.show,
                feature: {
                    restore: {},
                    saveAsImage: {}
                }
            },
            grid: {
               top: '65em',
               left: '70vh',
               bottom: '25em',
               containLabel: true
			},
			legend: {
                top: '25em',
                left: '0em',
                itemGap: 15,
                data:[this.seriesname1, this.seriesname2, this.seriesname3]
			},
			textStyle: {
                fontFamily: 'NotoIKEALatin-Regular',
            },
            xAxis: [
                {
                    type: 'category',
					data: [' '],
                    axisPointer: {
                        type: 'shadow'
                    },
                    axisLabel: {
                        interval: '0',
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: this.yaxislabel,
                    nameLocation: 'middle',
                    max: highest,
                    min: lowest,
                    nameGap: 50,
                    axisLabel: {
                        formatter: `{value} ${this.ymetric}`
                    }
                }
            ],
            series: this.call_data(lowest)
        };
        myChart.setOption(option);
    },
    methods: {
        call_data: function(lowest) {
            let data = [];
            for (let i = 0; i < this.xcategories.length; i++) {
                data.push(
                    {
                        name: this.seriesname1,
                        type: this.type,
                        data: [(this.seriesset1[i] ? (this.seriesset1[i] - 100).toPrecision(3) : this.seriesset1[i])],
                        color: this.color1,
                        barGap: 0,
                        label: {
                            color: 'black',
                            show: true, 
                            formatter: `${this.seriesset1[i]}`
                        },
                    },
                    {
                        type: this.type,
                        data: [],
                        barWidth: 0.01,
                        markPoint: {
                            symbolOffset: [0, '30em'],
                            symbolSize: 0.1,
                            label: {color: 'black'},
                            data: [{value: this.xcategories[i], xAxis: 0, yAxis: lowest}]
                        }
                    },
                    {
                        name: this.seriesname2,
                        type: this.type,
                        data: [(this.seriesset2[i] ? (this.seriesset2[i] - 100).toPrecision(3) : this.seriesset2[i])],
                        color: this.color2,
                        label: {
                            color: 'black',
                            show: true, 
                            formatter: `${this.seriesset2[i]}`
                        },
                    },
                    {
                        name: this.seriesname3,
                        type: this.type,
                        data: [(this.seriesset3[i] ? (this.seriesset3[i] - 100).toPrecision(3) : this.seriesset3[i])],
                        color: this.color3,
                        label: {
                            show: true, 
                            formatter: `${this.seriesset3[i]}`
                        },
                    },
                    {
                        type: this.type,
                        data: [],
                    }
                )
            }
            return data;
        }
    }
}
</script>
