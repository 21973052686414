var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sub-grid" }, [
    _c("div", { staticClass: "sub-item left first-row" }, [
      _vm._v(_vm._s(_vm.showLabels ? _vm.labels[0] : "")),
    ]),
    _c(
      "div",
      { staticClass: "sub-item right first-row", class: _vm.render_classes() },
      [_vm._v(_vm._s(_vm.vals[0]))]
    ),
    _c("div", { staticClass: "sub-item left light" }, [
      _vm._v(_vm._s(_vm.showLabels ? _vm.labels[1] : "")),
    ]),
    _c("div", { staticClass: "sub-item right light" }, [
      _vm._v(_vm._s(_vm.vals[1])),
    ]),
    _c("div", { staticClass: "sub-item left light" }, [
      _vm._v(_vm._s(_vm.showLabels ? _vm.labels[2] : "")),
    ]),
    _c("div", { staticClass: "sub-item right light" }, [
      _vm._v(_vm._s(_vm.vals[2]) + _vm._s(_vm.vals[2] !== "n/a" ? "%" : "")),
    ]),
    _c("div", { staticClass: "sub-item left" }, [
      _vm._v(_vm._s(_vm.showLabels ? _vm.labels[3] : "")),
    ]),
    _c("div", { staticClass: "sub-item right" }, [
      _vm._v(_vm._s(_vm.vals[3]) + _vm._s(_vm.vals[3] !== "n/a" ? "%" : "")),
    ]),
    _c("div", { staticClass: "sub-item left" }, [
      _vm._v(_vm._s(_vm.showLabels ? _vm.labels[4] : "")),
    ]),
    _c("div", { staticClass: "sub-item right" }, [_vm._v(_vm._s(_vm.vals[4]))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }