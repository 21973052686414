<template>
<div id="kitchensink">
    test
    <Title
        title="Cheat Sheet"
        subtitle="A compilation of all of the usable graphs for FT2, for any more complex additions to the graphs ask developers"
    />
    <div class="section">
        <Single
            title="Single Bar Chart"
            subtitle="This is a single chart"
            :xcategories="[1,2,3,4,5,6,7,8,9,10,11,12]"
            yaxislabel="Y Label"
            ymetric="%"
            seriesname1="Series 1"
            seriestype1="bar"
            :seriesset1="[-10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80]"
            color1="#169100"
            :show="true"
        />
    </div>
    <div class="section">
        <Single
            title="Single Line Chart"
            subtitle="This is a single chart"
            :xcategories="[1,2,3,4,5,6,7,8,9,10,11,12]"
            yaxislabel="Y Label"
            ymetric="%"
            seriesname1="Series 1"
            seriestype1="line"
            :seriesset1="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80]"
            color1="#169100"
            :show="true"
        />
    </div>
    <div class="section">
        <Single
            title="Single Scatter Graph"
            subtitle="This is a single chart"
            :xcategories="[1,2,3,4,5,6,7,8,9,10,11,12]"
            yaxislabel="Y Label"
            ymetric="%"
            seriesname1="Series 1"
            seriestype1="scatter"
            :seriesset1="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80]"
            color1="#169100"
            :show="true"
        />
    </div>
    <div class="section">
        <Double
            title="Double Bar Graph"
            subtitle="This is a double chart"
            :xcategories="[1,2,3,4,5,6,7,8,9,10,11,12]"
            yaxislabel="Y Label"
            ymetric="%"
            seriesname1="Series 1"
            seriestype1="bar"
            :seriesset1="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80]"
            color1="#169100"
            seriesname2="Series 2"
            seriestype2="bar"
            :seriesset2="[80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50]"
            color2="#787878"
            :show="true"
        />
    </div>
    <div class="section">
        <Double
            title="Double Bar and Line Graph"
            subtitle="This is a double chart"
            :xcategories="[1,2,3,4,5,6,7,8,9,10,11,12]"
            yaxislabel="Y Label"
            ymetric="%"
            seriesname1="Series 1"
            seriestype1="bar"
            :seriesset1="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80]"
            color1="#169100"
            seriesname2="Series 2"
            seriestype2="line"
            :seriesset2="[80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50]"
            color2="#787878"
            :show="true"
        />
    </div>
    <div class="section">
        <Double
            title="Double Bar and Scatter Graph"
            subtitle="This is a double chart"
            :xcategories="[1,2,3,4,5,6,7,8,9,10,11,12]"
            yaxislabel="Y Label"
            ymetric="%"
            seriesname1="Series 1"
            seriestype1="bar"
            :seriesset1="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80]"
            color1="#169100"
            seriesname2="Series 2"
            seriestype2="scatter"
            :seriesset2="[80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50]"
            color2="#787878"
            :show="true"
        />
    </div>
    <div class="section">
        <Double
            title="Double Line Graph"
            subtitle="This is a double chart"
            :xcategories="[1,2,3,4,5,6,7,8,9,10,11,12]"
            yaxislabel="Y Label"
            ymetric="%"
            seriesname1="Series 1"
            seriestype1="line"
            :seriesset1="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80]"
            color1="#169100"
            seriesname2="Series 2"
            seriestype2="line"
            :seriesset2="[80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50]"
            color2="#787878"
            :show="true"
        />
    </div>
    <div class="section">
        <Triple
            title="Triple Bar Graph"
            subtitle="This is a triple chart"
            :xcategories="[1,2,3,4,5,6,7,8,9,10,11,12]"
            yaxislabel="Y Label"
            ymetric="%"
            seriesname1="Series 1"
            seriestype1="bar"
            :seriesset1="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80]"
            color1="#169100"
            seriesname2="Series 2"
            seriestype2="bar"
            :seriesset2="[80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50]"
            color2="#787878"
            seriesname3="Series 3"
            seriestype3="bar"
            :seriesset3="[0, 5, 20, 50, 80, 60 , 40, 30, 20, 10, 20, 55]"
            color3="#fcfc05"
            :show="true"
        />
    </div>
    <div class="section">
        <Triple
            title="Double Bar with Line Graph"
            subtitle="This is a triple chart"
            :xcategories="[1,2,3,4,5,6,7,8,9,10,11,12]"
            yaxislabel="Y Label"
            ymetric="%"
            seriesname1="Series 1"
            seriestype1="bar"
            :seriesset1="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80]"
            color1="#169100"
            seriesname2="Series 2"
            seriestype2="bar"
            :seriesset2="[80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50]"
            color2="#787878"
            seriesname3="Series 3"
            seriestype3="line"
            :seriesset3="[0, 5, 20, 50, 80, 60 , 40, 30, 20, 10, 20, 55]"
            color3="#fcfc05"
            :show="true"
        />
    </div>
    <div class="section">
        <Triple
            title="Bar with Line and Scatter Graph"
            subtitle="This is a triple chart"
            :xcategories="[1,2,3,4,5,6,7,8,9,10,11,12]"
            yaxislabel="Y Label"
            ymetric="%"
            seriesname1="Series 1"
            seriestype1="bar"
            :seriesset1="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80]"
            color1="#169100"
            seriesname2="Series 2"
            seriestype2="line"
            :seriesset2="[80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50]"
            color2="#787878"
            seriesname3="Series 3"
            seriestype3="scatter"
            :seriesset3="[0, 5, 20, 50, 80, 60 , 40, 30, 20, 10, 20, 55]"
            color3="#fcfc05"
            :show="true"
        />
    </div>
    <div class="section">
        <Triple
            title="Triple Line Graph"
            subtitle="This is a triple chart"
            :xcategories="[1,2,3,4,5,6,7,8,9,10,11,12]"
            yaxislabel="Y Label"
            ymetric="%"
            seriesname1="Series 1"
            seriestype1="line"
            :seriesset1="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80]"
            color1="#169100"
            seriesname2="Series 2"
            seriestype2="line"
            :seriesset2="[80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50]"
            color2="#787878"
            seriesname3="Series 3"
            seriestype3="line"
            :seriesset3="[0, 5, -20, 50, 80, 60 , 40, 30, 20, 10, 20, 55]"
            color3="#fcfc05"
            :show="true"
        />
    </div>
    <div class="section">
        <Quad
            title="Quad Bar Graph"
            subtitle="This is a quad chart"
            :xcategories="[1,2,3,4,5,6,7,8,9,10,11,12]"
            yaxislabel="Y Label"
            ymetric="%"
            seriesname1="Series 1"
            seriestype1="bar"
            :seriesset1="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80]"
            color1="#169100"
            seriesname2="Series 2"
            seriestype2="bar"
            :seriesset2="[80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50]"
            color2="#787878"
            seriesname3="Series 3"
            seriestype3="bar"
            :seriesset3="[0, 5, 20, 50, 80, 60 , 40, 30, 20, 10, 20, 55]"
            color3="#fcfc05"
            seriesname4="Series 4"
            seriestype4="bar"
            :seriesset4="[60, 30, 40, 60, 70, 30, 20, 10, 50, 70, 100, 90]"
            color4="blue"
            :show="true"
        />
    </div>
    <div class="section">
        <Quad
            title="Triple Bar with Line"
            subtitle="This is a quad chart"
            :xcategories="[1,2,3,4,5,6,7,8,9,10,11,12]"
            yaxislabel="Y Label"
            ymetric="%"
            seriesname1="Series 1"
            seriestype1="bar"
            :seriesset1="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80]"
            color1="#169100"
            seriesname2="Series 2"
            seriestype2="bar"
            :seriesset2="[80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50]"
            color2="#787878"
            seriesname3="Series 3"
            seriestype3="bar"
            :seriesset3="[0, 5, 20, 50, 80, 60 , 40, 30, 20, 10, 20, 55]"
            color3="#fcfc05"
            seriesname4="Series 4"
            seriestype4="line"
            :seriesset4="[60, 30, 40, 60, 70, 30, 20, 10, 50, 70, 100, 90]"
            color4="blue"
            :show="true"
        />
    </div>
    <div class="section">
        <Quad
            title="Double Bar with Double Line"
            subtitle="This is a quad chart"
            :xcategories="[1,2,3,4,5,6,7,8,9,10,11,12]"
            yaxislabel="Y Label"
            ymetric="%"
            seriesname1="Series 1"
            seriestype1="bar"
            :seriesset1="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80]"
            color1="#169100"
            seriesname2="Series 2"
            seriestype2="bar"
            :seriesset2="[80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50]"
            color2="#787878"
            seriesname3="Series 3"
            seriestype3="line"
            :seriesset3="[0, 5, 20, 50, 80, 60 , 40, 30, 20, 10, 20, 55]"
            color3="#fcfc05"
            seriesname4="Series 4"
            seriestype4="line"
            :seriesset4="[60, 30, 40, 60, 70, 30, 20, 10, 50, 70, 100, 90]"
            color4="blue"
            :show="true"
        />
    </div>
    <div class="section">
        <Quad
            title="Double Bar with Line and Scatter"
            subtitle="This is a quad chart"
            :xcategories="[1,2,3,4,5,6,7,8,9,10,11,12]"
            yaxislabel="Y Label"
            ymetric="%"
            seriesname1="Series 1"
            seriestype1="bar"
            :seriesset1="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80]"
            color1="#169100"
            seriesname2="Series 2"
            seriestype2="bar"
            :seriesset2="[80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50]"
            color2="#787878"
            seriesname3="Series 3"
            seriestype3="line"
            :seriesset3="[0, 5, 20, 50, 80, 60 , 40, 30, 20, 10, 20, 55]"
            color3="#fcfc05"
            seriesname4="Series 4"
            seriestype4="scatter"
            :seriesset4="[60, 30, 40, 60, 70, 30, 20, 10, 50, 70, 100, 90]"
            color4="blue"
            :show="true"
        />
    </div>
    <div class="section">
        <QuadStack
            title="Triple Bar Stack with Line"
            subtitle="This is a quad stack chart"
            :xcategories="[1,2,3,4,5,6,7,8,9,10,11,12]"
            yaxislabel="Y Label"
            ymetric="%"
            seriesname1="Series 1"
            seriestype1="bar"
            :seriesset1="[10, 20, 30, 15, 20, 10, 5, 2, 17, 38, 30, 10]"
            color1="#169100"
            seriesname2="Series 2"
            seriestype2="bar"
            :seriesset2="[2, 16, 35, 7, 24, 16, 23, 7, 21, 4, 40, 22]"
            color2="#787878"
            seriesname3="Series 3"
            seriestype3="bar"
            :seriesset3="[0, 5, 20, 30, 40, 21, 12, 15, 3, 6, 27, 25]"
            color3="#fcfc05"
            seriesname4="Series 4"
            seriestype4="line"
            :seriesset4="[60, 30, 40, 60, 70, 30, 20, 10, 50, 70, 100, 90]"
            color4="blue"
            :show="true"
        />
    </div>
    <div class="section">
        <AreaStack
            title="Area Stack Graph"
            subtitle="This is a area stack chart"
            :xcategories="[1,2,3,4,5,6,7,8,9,10,11,12]"
            yaxislabel="Y Label"
            ymetric="%"
            seriesname1="Series 1"
            :seriesset1="[10, 20, 30, 15, 20, 10, 5, 2, 17, 38, 30, 10]"
            color1="#169100"
            seriesname2="Series 2"
            :seriesset2="[2, 16, 35, 7, 24, 16, 23, 7, 21, 4, 40, 22]"
            color2="#787878"
            seriesname3="Series 3"
            :seriesset3="[0, 5, 20, 30, 40, 21, 12, 15, 3, 6, 27, 25]"
            color3="#fcfc05"
            seriesname4="Series 4"
            :seriesset4="[10, 20, 6, 9, 12, 14, 3, 13, 5, 2, 7, 9]"
            color4="purple"
            seriesname5="Series 5"
            :seriesset5="[1, 3, 5, 7, 9, 2, 4, 6, 8, 10, 12, 13]"
            color5="#ffc105"
            seriesname6="Series 6"
            :seriesset6="[9, 7, 3, 5, 9, 5, 12, 14, 6, 3, 7, 9]"
            color6="aqua"
            :show="true"
        />
    </div>
    <div class="section">
        <HorizontalSingleBar
            :title="title"
            :subtitle="subtitle"
            :ycategories="ycategories"
            :xaxislabel="xaxislabel"
            :xmetric="xmetric"
            :seriesname1="seriesname1"
            :seriestype1="seriestype1"
            :seriesset1="seriesset4"
            :color1="color1"
            :show="show"
        />
    </div>
    <div class="section">
        <PieChart
            title="Pie Chart"
            subtitle="This is a Pie Chart"
            :names="['IKEA', 'Harvey Norman / Domaine', 'Freedom', 'Bunnings Warehouse', 'Kmart', 'Ebay']"
            :values="[39, 23, 7, 69, 63, 43]"
        />
    </div>

    <div class="section long">
        <DeviationWith2Lines
            title="Deviation Graph"
            subtitle="This is a deviation graph with 2 lines"
            :xcategories="[1,2,3,4,5,6,7,8,9,10,11,12]"
            yaxislabel1="Y Label"
            ymetric1="%"
            yaxislabel2="Deviation"
            ymetric2="%"
            :yaxismin1=-100
            :yaxismax1=100
            :yaxismin2=-200
            :yaxismax2=200
            seriesname1="Deviation"
            seriestype1="bar"
            :seriesset1="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80]"
            color1="#169100"
            seriesname2="Series 2"
            seriestype2="line"
            :seriesset2="[80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50]"
            color2="#787878"
            seriesname3="Series 3"
            seriestype3="line"
            :seriesset3="[0, 5, -20, 50, 80, 60 , 40, 30, 20, 10, 20, 55]"
            color3="#fcfc05"
            :show="true"
        />
    </div>
    <div class="section long">
        <DeviationWith3Lines
            title="Deviation Graph with 3 Lines"
            subtitle="This is a deviation graph with 2 lines"
            :xcategories="[1,2,3,4,5,6,7,8,9,10,11,12]"
            yaxislabel1="Y Label"
            ymetric1="%"
            yaxislabel2="Deviation"
            ymetric2="%"
            yaxismin1="-100"
            yaxismax1="100"
            yaxismin2="-200"
            yaxismax2="200"
            seriesname1="Deviation"
            seriestype1="bar"
            :seriesset1="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80]"
            color1="#169100"
            seriesname2="Series 2"
            seriestype2="line"
            :seriesset2="[80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50]"
            color2="#787878"
            seriesname3="Series 3"
            seriestype3="line"
            :seriesset3="[0, 5, -20, 50, 80, 60 , 40, 30, 20, 10, 20, 55]"
            color3="#fcfc05"
            seriesname4="Series 4"
            seriestype4="line"
            :seriesset4="[60, 30, 40, 60, 70, 30, 20, 10, 50, 70, 100, 90]"
            color4="blue"
            :show="true"
        />
    </div>
    <div class="section long">
        <Quad
            title="Double Bar with Line and Scatter (long)"
            subtitle="This is a quad chart"
            :xcategories="[1,2,3,4,5,6,7,8,9,10,11,12]"
            yaxislabel="Y Label"
            ymetric="%"
            seriesname1="Series 1"
            seriestype1="bar"
            :seriesset1="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80]"
            color1="#169100"
            seriesname2="Series 2"
            seriestype2="bar"
            :seriesset2="[80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50]"
            color2="#787878"
            seriesname3="Series 3"
            seriestype3="line"
            :seriesset3="[0, 5, 20, 50, 80, 60 , 40, 30, 20, 10, 20, 55]"
            color3="#fcfc05"
            seriesname4="Series 4"
            seriestype4="scatter"
            :seriesset4="[60, 30, 40, 60, 70, 30, 20, 10, 50, 70, 100, 90]"
            color4="blue"
            :show="true"
        />
    </div>
    <div class="section">
        <Quad
            title="Double Bar with Line and Scatter (comparison)"
            subtitle="This is a quad chart"
            :xcategories="[1,2,3,4,5,6,7,8,9,10,11,12]"
            yaxislabel="Y Label"
            ymetric="%"
            seriesname1="Series 1"
            seriestype1="bar"
            :seriesset1="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80]"
            color1="#169100"
            seriesname2="Series 2"
            seriestype2="bar"
            :seriesset2="[80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50]"
            color2="#787878"
            seriesname3="Series 3"
            seriestype3="line"
            :seriesset3="[0, 5, 20, 50, 80, 60 , 40, 30, 20, 10, 20, 55]"
            color3="#fcfc05"
            seriesname4="Series 4"
            seriestype4="scatter"
            :seriesset4="[60, 30, 40, 60, 70, 30, 20, 10, 50, 70, 100, 90]"
            color4="blue"
            :show="true"
        />
    </div>
    <div class="section long">
        <AreaStack
            title="Area Stack Graph (long)"
            subtitle="This is a area stack chart"
            :xcategories="[1,2,3,4,5,6,7,8,9,10,11,12]"
            yaxislabel="Y Label"
            ymetric="%"
            seriesname1="Series 1"
            :seriesset1="[10, 20, 30, 15, 20, 10, 5, 2, 17, 38, 30, 10]"
            color1="#169100"
            seriesname2="Series 2"
            :seriesset2="[2, 16, 35, 7, 24, 16, 23, 7, 21, 4, 40, 22]"
            color2="#787878"
            seriesname3="Series 3"
            :seriesset3="[0, 5, 20, 30, 40, 21, 12, 15, 3, 6, 27, 25]"
            color3="#fcfc05"
            seriesname4="Series 4"
            :seriesset4="[10, 20, 6, 9, 12, 14, 3, 13, 5, 2, 7, 9]"
            color4="purple"
            seriesname5="Series 5"
            :seriesset5="[1, 3, 5, 7, 9, 2, 4, 6, 8, 10, 12, 13]"
            color5="#ffc105"
            seriesname6="Series 6"
            :seriesset6="[9, 7, 3, 5, 9, 5, 12, 14, 6, 3, 7, 9]"
            color6="aqua"
            :show="true"
        />
    </div>
    <div class="section">
        <AreaStack
            title="Area Stack Graph (comparison)"
            subtitle="This is a area stack chart"
            :xcategories="[1,2,3,4,5,6,7,8,9,10,11,12]"
            yaxislabel="Y Label"
            ymetric="%"
            seriesname1="Series 1"
            :seriesset1="[10, 20, 30, 15, 20, 10, 5, 2, 17, 38, 30, 10]"
            color1="#169100"
            seriesname2="Series 2"
            :seriesset2="[2, 16, 35, 7, 24, 16, 23, 7, 21, 4, 40, 22]"
            color2="#787878"
            seriesname3="Series 3"
            :seriesset3="[0, 5, 20, 30, 40, 21, 12, 15, 3, 6, 27, 25]"
            color3="#fcfc05"
            seriesname4="Series 4"
            :seriesset4="[10, 20, 6, 9, 12, 14, 3, 13, 5, 2, 7, 9]"
            color4="purple"
            seriesname5="Series 5"
            :seriesset5="[1, 3, 5, 7, 9, 2, 4, 6, 8, 10, 12, 13]"
            color5="#ffc105"
            seriesname6="Series 6"
            :seriesset6="[9, 7, 3, 5, 9, 5, 12, 14, 6, 3, 7, 9]"
            color6="aqua"
            :show="true"
        />
    </div>
    <div class="section high">
        <Quad
            title="Double Bar with Line and Scatter (test)"
            subtitle="This is a quad chart"
            :xcategories="[1,2,3,4,5,6,7,8,9,10,11,12]"
            yaxislabel="Y Label"
            ymetric="%"
            seriesname1="Series 1"
            seriestype1="bar"
            :seriesset1="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80]"
            color1="#169100"
            seriesname2="Series 2"
            seriestype2="bar"
            :seriesset2="[80, 90, 100, 80, 50, 20, 60, 70, 20, 10, 45, 50]"
            color2="#787878"
            seriesname3="Series 3"
            seriestype3="line"
            :seriesset3="[0, 5, 20, 50, 80, 60 , 40, 30, 20, 10, 20, 55]"
            color3="#fcfc05"
            seriesname4="Series 4"
            seriestype4="scatter"
            :seriesset4="[60, 30, 40, 60, 70, 30, 20, 10, 50, 70, 100, 90]"
            color4="blue"
            :show="true"
        />
    </div>
    <div class="section box ms">
        <Top8Bar
            title="Top 10 Countries Penetration" 
            subtitle="Showing penetration data for the Top 10 countries including IKEA and the other top 2 competitors" 
            :xcategories="['Sweden', 'Italy', 'China', 'Germany', 'France', 'Canada', 'UK', 'US']" 
            yaxislabel="Penetration" 
            ymetric="%"
            seriesname1="IKEA"
            seriestype1="bar"
            :seriesset1="[74, 62, 56, 68, 46, 40, 39, 25]"
            color1="#169100"
            seriesname2="Company 2"
            seriestype2="bar"
            :seriesset2="[43, 67, 44, 68, 50, 63, 70, 75]"
            color2="lightgreen"
            seriesname3="Company 3"
            seriestype3="bar"
            :seriesset3="[29, 36, 30, 23, 34, 61, 48, 68]"
            color3="#787878"
            seriesname4="Goal"
            seriestype4="scatter"
            :seriesset4="[78, 63, , 56, 48, 41, 39, 22]"
            color4="#ffc105"
            :show="true"
        />
    </div>
</div>
</template>

<script>
import Title from '@/components/ui/Title.vue';
import HorizontalSingleBar from '@/components/visualisations/bar/HorizontalSingleBar.vue';
import Single from '@/components/visualisations/general/Single.vue';
import Double from '@/components/visualisations/general/Double.vue';
import Triple from '@/components/visualisations/general/Triple.vue';
import Quad from '@/components/visualisations/general/Quad.vue';
import QuadStack from '@/components/visualisations/general/QuadStack.vue';
import AreaStack from '@/components/visualisations/general/AreaStack.vue';
import PieChart from '@/components/visualisations/pie/PieChart.vue';
import DeviationWith2Lines from '@/components/visualisations/general/DeviationWith2Lines.vue';
import DeviationWith3Lines from '@/components/visualisations/general/DeviationWith3Lines.vue';
import Top8Bar from '@/components/visualisations/bar/Top8Bar.vue';
export default {
    components: {
        Title,
        HorizontalSingleBar,
        Single,
        Double, 
        Triple, 
        Quad,
        QuadStack,
        AreaStack,
        PieChart,
        DeviationWith2Lines,
        DeviationWith3Lines,
        Top8Bar,
    },
    data: function() {
        return{
            title: 'Example Graph',
            subtitle: 'This is a horizontal bar chart',
            legend1: 'series1',
            legend2: 'series2',
            legend3: 'series3',
            xcategories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            ycategories: ['IKEA', 'Amazon', 'Argos', 'Dunelm', 'The Range', 'John Lewis', 'Wayfair', 'DFS'],
            xaxislabel: 'X axis Label',
            yaxislabel: 'Y axis Label',
            yaxismin: 0,
            xaxismin: 0,
            yaxismax: 10,
            xaxismax: 30,
            yinterval: 1,
            xinterval: 10,
            ymetric: '%',
            xmetric: '%',
            seriesname1: 'series1',
            seriestype1: 'bar',
            seriesset1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            color1:  'red',
            seriesname2: 'series2',
            seriestype2: 'bar',
            seriesset2: [0, 2, 4, 6, 8, 10, 8, 6, 4, 2, 0, 2],
            color2: 'blue',
            seriesname3: 'series3',
            seriestype3: 'line',
            seriesset3: [0, 3, 6, 9, 6, 3, 0, 3, 6, 9, 6, 3],
            color3: 'yellow',
            seriesset4: [1, 2, 3, 4, 5, 6, 7, 8],
            showdbwl: true,
            show: true,
        }
    },
    mounted: function() {
        this.$store.state.commenting.width = 0;
    },
    destroyed: function() {
        this.$store.state.commenting.width = 4;
    },
}
</script>

<style scoped>
.section{
    float: left;
    margin-left: 1em;
    width: calc(33% - 1em);
    height: 25em;
    border: 1px solid green;
    margin-top: 1em;
}
.long{
    width: calc(2 * (33% - 0.5em));
    height: 25em;
}
.high{
    width: calc(2 * (33% - 0.5em));
    height: 100vh;
}
.box{
    width: 100%;
    height: 100vh;
}
</style>