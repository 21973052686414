<template>
<div>
    <AdminUniversal
        component="title"
        :props="title_props"
    />
    <div class="columns is-gapless">
        <div class="column is-2">Search User:</div>
        <UserSearch
            @SelectedUser="user_selected"

            class="column is-3"
        />
		<button class="button is-danger column is-1" @click="cancel_search()">Cancel</button>

            <p class="column is-2"></p>
            <p class="column is-1">Access:</p>
            <div class="column is-3">
                <DropDown
                    :key="values.length"
                    :options="options"
                    :values="values"
                    :default="filter"
                    @updateDropDown="update_filter"

                />
            </div>
	</div>
    
    <table class="table">
        <thead>
            <tr>
                <th v-for="(header, index) in table.headers" :key="index" class="ikea-blue">{{header}}</th>
                <th class="ikea-blue">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(row, index) in table.rows" :key="index">
                <td v-for="(dimension, i) in row" :key="i">
                    {{dimension}}
                </td>
                <td>
                    <a class="button" @click="edit_user(index)">Edit</a>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="buttons block">
        <a class="button is-pulled-left" @click="back">Back</a>

        <a class="button" v-for="(index, i) in page_range" :key="i" @click="update_page(index)" :class="{'ikea-blue': page === index}">{{index + 1}}</a>
        
        <a class="button is-pulled-right" @click="next">Next</a>
    </div>

    <Modal
        v-if="user"
        title="Edit User"
        :closeable="true"
        :class="{'is-active': is_active}"
        @close="is_active = false"
    >
        <template v-slot:content>
            <div class="columns">
                <div class="column is-narrow">
                    <div v-for="key in Object.keys(user)" :key="key">
                        <div class="text-left user-row">{{key}}</div>
                        <hr />
                    </div>

                    <div class="text-left user-row">Access Levels</div>
                    <hr />
                </div>
                <div class="column">
                    <div v-for="key in Object.values(user)" :key="key">
                        <div class="text-left user-row">{{key}}</div>
                        <hr />
                    </div>

                    <div class="text-left user-row">
                        <div class="select">
                            <select v-model="updated_user.access">
                                <option>ADMIN</option>
                                <option>ADVANCED</option>
                                <option>BASIC</option>
                                <option>CONTRIBUTOR</option>
                                <option>EXECUTIVE SUMMARY</option>
                            </select>
                        </div>
                    </div>
                    <hr />
                </div>
            </div>
            <button class="button is-fullwidth" @click="load_logs">See Logs</button>
            <div v-if="can_see_logs">
                <table class="table is-fullwidth">
                    <thead>
                        <tr>
                            <th>Timestamp</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(log, index) in updated_user.logs" :key="index">
                            <td>{{render_date(log.created_at)}}</td>
                            <td>{{log.details}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>

        <template v-slot:buttons>
            <div class="buttons">
                <button class="button is-danger" @click="close_modal">Cancel</button>
                <button class="button ikea-blue text-white" @click="update_user">Confirm</button>
            </div>
        </template>
    </Modal>

</div>
</template>

<script>
import AdminUniversal from '@/components/admin/index.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Modal from '@/components/ui/Modal.vue';
import UserSearch from '@/components/admin/UserSearch.vue';
export default {
    components: {
        AdminUniversal,
        DropDown,
        Modal,
        UserSearch
    },
    data: function() {
        return {
            title_props: {
                title: 'Edit Users',
            },
            can_see_logs: false,
            table: {
                headers: [],
                rows: []
            },
            is_active: false,
            page: 0,
            page_size: 10,
            no_more_users: false,
            total_users: 0,
            user: null,
            updated_user: null,
            users: null,
            selected_user: null,
            options: ['ALL'],
            values: ['ALL'],
            filter: 'ALL',
        }
    },
    mounted: function() {
        this.load_access_templates();
        this.load_users();
    },
    methods: {
        back: function() {
            if (this.page > 0) {
                this.page--;
            }
        },
        close_modal: function() {
            this.can_see_logs = false;
            this.is_active = false;
        },
        edit_user: function(index) {
            this.user = this.users[index];
            this.updated_user = JSON.parse(JSON.stringify(this.user));
            this.is_active = true;
        },
        load_access_templates: function() {
            this.$http.get('/admin/user-management/access-templates/all').then(
                succ => {
                    succ.data.map(template => {
                        this.options.push(template.name);
                        this.values.push(template.name);
                    })
                },
                this.$helper.error
            );
        },
        load_logs: function() {
            this.updated_user.logs = null;
            this.can_see_logs = false;
            this.$http.post('/admin/user-management/logs', {
                user_id: this.updated_user.user_id
            }).then(
                succ => {
                    this.updated_user.logs = succ.data;
                    this.can_see_logs = true;
                },
                this.$helper.error
            );
        },
        load_users: function() {
            this.$http.post('/admin/user-management/edit-users/load', {
                page: this.page,
                page_size: this.page_size,
                user_id: this.selected_user,
                access: this.filter
            }).then(
                succ => {
                    let users = succ.data.users;
                    this.total_users = succ.data.total_users;
                    this.users = JSON.parse(JSON.stringify(succ.data.users)); // Deep copy the object

                    if (users.length === 0) {
                        return;
                    } else if (users.length < this.page_size) {
                        this.no_more_users = true;
                    } else {
                        this.no_more_users = false;
                    }

                    let header = users[0];
                    let to_remove = ['user_id', 'preferred_language', 'office_location', 'first_name', 'last_name', 'access_template_id'];
                    to_remove.forEach(item => delete header[item]);

                    this.table.headers = Object.keys(users[0]);
                    this.table.rows = users.map(user => {
                        to_remove.forEach(item => delete user[item]);
                        return Object.values(user);
                    });
                },
                this.$helper.error
            );
        },
        render_date: function(date) {
            return new Date(date).toUTCString();
        },
        next: function() {
            if (!this.no_more_users && (this.page + 1) < this.page_range.pop()) {
                this.page++;
            }
        },
        update_page: function(index) {
            this.page = index;
        },
        update_user: function() {
            this.$http.post('/admin/user-management/edit-users/edit', this.updated_user).then(
                succ  => {
                    alert("Successfully updated user.");
                    this.is_active = false;
                    this.load_users();
                },
                this.$helper.error
            );
        },
        user_selected: function(user) {
            this.selected_user = user;
            this.load_users(); 
        },
        cancel_search: function() {
            this.selected_user = null;
            this.load_users(); 
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.load_users();
        },
    },
    computed: {
        page_range: function() {
            let pages = [];
            let bottom = this.page - 3;
            let top = this.page + 3;

            if (this.page < 3) {
                bottom = 0;
            }

            if (top > this.total_users/this.page_size) {
                top = this.total_users/this.page_size;
            }

            for (let i = bottom; i < top; i++) {
                pages.push(i);
            }
            return pages;
        }
    },
    watch: {
        page: function() {
            this.load_users();
        }
    }
}
</script>

<style scoped>
hr {
    margin: 0.5em;
}

.user-row {
    height: 2em;
    margin: 0.5em;
}
</style>