var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "admin" } }, [
    _c(
      "div",
      { staticClass: "columns" },
      [
        _c("SideMenu", { staticClass: "column is-3" }),
        _c("router-view", { staticClass: "column is-9" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }