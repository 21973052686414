<template>
<div>
	<AdminUniversal
		component="title"
		:props="title_props"
	/>
	<div class="flex">
		<DropDown
			:key="filter"
			:options="kpi_options"
			:values="kpi_values"
			:default="filter"
			@updateDropDown="update_kpi_area"

			class="mb"
		/>
		<CommentKey 
			:status_colors="status_colors"
		/>
	</div>
	<section v-if="render" class="accordions accordians-height vertical-scroll">
		<article class="accordion" v-for="(country, index) in all_countries" :key="index" :class="`${active === country.market ? 'is-active' : ''}`">
			
			<div class="accordion-header relative" @click="toggle_active(country.market)">
				{{country.caption}}
				<div class="status-badge" :style="`background-color:${status_colors[country.status]}`"></div>
				<i :class="`${active === country.market ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}`"></i>
			</div>

			<div class="accordion-body cursor-default">
				<div class="accordion-content bg-white">
					<div class="insight columns is-gapless" v-for="(comment, i) in country.insights" :key="i">

						<div class="column">Status: {{comment.status}}</div>

						<div class="column">Title: {{comment.title}}</div>

						<div class="column">
							{{comment.status === 'LIVE' ? 'Started: ' : 'Starting: '}}{{display_date(comment.scheduled_date)}}
							<br><b class="">-</b><br> 
							Ending: {{display_date(comment.comment_lifetime)}}
						</div>

						<div class="column">Author: {{comment.display_name}}</div>

						<div class="column">
							<div class="button is-success content-button" @click="open_modal(comment.content_id)">Content</div>
						</div>

						<div class="modal" :class="`${active_modal === comment.content_id ? 'is-active' : ''}`">
							<div class="modal-background"></div>
							<div class="modal-content relative">
								<button class="delete top-right" aria-label="close" @click="close_modal()"></button>
								<div class="modal-head pap5">
									<p class="modal-card-title">
										{{comment.title}}
									</p>
								</div>
								<div v-html="comment.content"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</article>
	</section>
	
</div>
</template>

<script>
import AdminUniversal from '@/components/admin/index.vue';
import CommentKey from '@/components/admin/CommentKey.vue';
import DropDown from '@/components/ui/DropDown.vue';

export default {
	components: {
		AdminUniversal,
		CommentKey,
		DropDown
	},
	data: function() {
		return {
			title_props: {
				title: "Insights Tracker"
			},
			active: null,
			active_modal: null,
			all_countries: null,
			filter: 'introduction',
			kpi_options: ['Introduction', 'Three Roads', 'Brand', 'Business', 'Customer', 'Finance', 'People', 'Sustainability', 'Process'],
			kpi_values: ['introduction', 'three-roads', 'brand', 'business', 'customer', 'financial', 'people', 'sustainability', 'process'],
			render: false,
			status_colors: {
				live: '#008000',
				scheduled: '#7cb9e8',
				draft: '#ffff00',
				none: '#ff0000'
			}
		}
	},
	mounted:function() {
		this.load_country_insights();
	},
	methods: {
		close_modal: function() {
			this.active_modal = null;
		},
		display_date: function(date) {
			return date.split('T')[0];
		},
		load_country_insights: function() {
			
			this.$http.post('/admin/country-insights-tracker', {
				kpi_area: this.filter
			}).then(
				succ => {
					let d = succ.data;
					let all_countries = this.$store.state.countries.all;
					let count = 0;
					all_countries.map(country => {
						let temp_array = [];
						let status = {
							live: false,
							scheduled: false,
							draft: false
						};
						d.map(insight => {
							if (country.market === insight.market_area) {
								temp_array.push(insight)
								if (insight.status === 'LIVE') status.live = true;
								if (insight.status === 'SCHEDULED') status.scheduled = true;
								if (insight.status === 'DRAFT') status.draft = true;								
							}
						});
						all_countries[count] = {
							caption: country.caption,
							market: country.market,
							insights: temp_array,
							status: status.live ? 'live' : status.scheduled ? 'scheduled' : status.draft ? 'draft' : 'none'
						}
						count = count + 1;
					});
					this.all_countries = all_countries;
					this.render = true;
				},
				this.$helper.error
			);
		},
		open_modal: function (id) {
			this.active_modal = id;
		},
		toggle_active: function(market) {
            if (this.active === market) {
                this.active = null;
            } else {
                this.active = market;
            }
		},
		update_kpi_area: function(filter) {
			this.all_countries = null;
			this.render = false;
			this.filter = filter;
		}
	},
	watch: {
		filter: function() {
			this.load_country_insights();
		}
	}
}
</script>

<style>
.accordion-header {
    background-color: rgb(243, 243, 243) !important;
    border-bottom: 1px solid #ececec;
    border-radius: 0 !important;
    color: #4a4a4a !important;
}

.accordians-height {
	height: 50rem;
}

.content-button {
	padding: 2rem !important;
}

.insight {
	border-bottom: 1px solid black;
	margin-bottom: 1rem;
	padding-bottom: 1rem;
}

.insight:last-child {
	border-bottom: 0;
}

.modal-background {
	background-color: rgba(138, 138, 138, 0.445) !important;
}

.status-badge {
	border-radius: 100%;
	height: 1.5rem;
	left: 10rem;
	position: absolute;
	width: 1.5rem;
}

.top-right {
	position: absolute !important;
    right: 0.5rem;
    top: 0.5rem;
}

</style>