<template>
<div>
    <Title
        title="Happy Customer"
        :location="location"
    />

    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
        <div class="label-container">
            <p>CMP</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms">
        <Triple
            :key="triple.render"
            v-if="triple.render"

            :title="`Showing data for: ${$store.getters.selected_country_name}`"
            :showtitle="!$store.state.global.bot_mode"
            subtitle=""

            :xcategories="triple.xcategories"
            :yaxislabel="`Happy Customer Score ${ filter === 'total' ? 'Topline' : options[values.indexOf(filter)]}`"
            ymetric=""
            
            seriesname1="YTD"
            seriestype1="bar"
            :seriesset1="triple.seriesset1"
            :color1="$store.state.colors['yellow']"
            
            seriesname2="Month Trend"
            seriestype2="line"
            :seriesset2="triple.seriesset2"
            :color2="$store.state.colors['grey']"
            
            seriesname3="Year Goal"
            seriestype3="line"
            :seriesset3="triple.seriesset3"
            :color3="$store.state.colors.YG"
        />
        <div v-else> No data found for {{$store.getters.selected_country_name}}</div>
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Triple from '@/components/visualisations/general/Triple.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Title,
        Triple
    },
    props: {
        market: String
    },
    data: function() {
        return {
            triple: {
                title: null,
                subtitle: null,

                xcategories: null,
                yaxislabel: null,
                ymetric: null,
                
                seriesset1: [],
                seriesset2: [],
                seriesset3: [],

                render: false
            },

            options: [],
            values: [],
            filter: "total",
            location: '/customer/happy-customer'
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        if (this.$helper.market_check(this.market) !== this.$store.getters.market) return;
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'Trend',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8`
                },
                // {
                //     name: 'Customer Journey',
                //     link: `${this.location}/summary/${this.$store.getters.market}`
                // }
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'hcs-cmp-1'
            }).then(
                succ => {
                    succ.data.map(filter => {
                        this.values.push(filter.value);
                        this.options.push(filter.option);
                    });
                    this.filter = this.$store.getters.filter_check(this.values);
                    this.load_market_data();
                },
                this.$helper.error
            );
        },
        load_market_data: function() {
            if (this.filter) {
                this.$http.post('/customer/happy-customer/market', {
                   market: this.$store.getters.market, filter: this.filter 
                }).then(
                    succ => {
                        let d = succ.data;
                        
                        if (d[0] !== undefined) {
                            this.triple.xcategories = Object.keys(d[0].measures);
                            
                            this.triple.seriesset1 = [];
                            this.triple.seriesset2 = [];
                            this.triple.seriesset3 = [];
                            
                            this.triple.xcategories.map(month => {
                                let val = d[0].measures[month];
                                this.triple.seriesset1.push(val['YTD']);
                                this.triple.seriesset2.push(val['Month']); 
                                //this.triple.seriesset2.push(val['YTD LY']); // Removed 12 Nov 20 per Fredrik Hoffman - We wont have LY until next year and it looks strange without - TJ
                                this.triple.seriesset3.push(val['Goal']);
                            });
                        
                            this.triple.render = true;
                        }
                    },
                    this.$helper.error
                );
            }
            
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.triple.render = false;
            this.load_market_data();
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.triple.render = false;
            this.update_view(newMarket);
            this.load_market_data();
        }
    }
}
</script>

<style>

</style>
