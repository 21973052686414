<template>
    <div id="admin">
        <div class="columns">
            <SideMenu class="column is-3" />
            <router-view class="column is-9" />
        </div>
    </div>
</template>

<script>
import SideMenu from '@/components/admin/SideMenu.vue';
export default {
    components: {
        SideMenu
    },
    mounted: function () {
        this.$store.state.commenting.width = 0;
        this.check_user_access();
    },
    destroyed: function () {
        this.$store.state.commenting.width = 4;
    },
    methods: {
        check_user_access: function () {
            this.$http.get('force', '/user/me').then(
                succ => {
                    let a_id = succ.data.access_template_id;
                    if (a_id === undefined || a_id === null || !('template' in succ.data)) {
                        this.$router.push('/');
                    } else {
                        if (succ.data.name !== 'ADMIN') {
                            this.$router.push('/');
                        }
                    }
                },
                error => console.log(error)
            );
        }
    }
};
</script>

<style>
.columns {
    width: 100%;
}
</style>
