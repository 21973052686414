<template>
<div class="full-width">
    <div class="mbp5" v-for="(option, i) in buttons" :key="i">
        <div class="has-addons">
            <div class="control full-width">
                <button v-if="option.type === 'post'" class="button full-width" @click="update(option.operation)">
                    {{calculated_status === 'SCHEDULED' ? 'Schedule' : 'Post'}} {{option.button}}
                </button>
                <button
                    v-else
                    class="button full-width"
                    :class="option.type === 'delete' ? 'is-danger' : ''"
                    @click="option.type === 'delete' ? delete_button() : update(option.operation)"
                >
                    {{option.button}}
                </button>
            </div>
            <!-- <div class="control width-10"> This is commented out temporarily as a design possiblity
                <button class="button is-link full-width" @click="info_click(option.type)">
                    <i v-if="option.clicked" class="fas fa-times"></i>
                    <i v-else class="fas fa-info"></i>
                </button>
            </div> -->
        </div>
        <!-- <div v-if="option.clicked" class="ms full-width-ms box -mtp5">
            <div v-if="option.type === 'post'">{{option.info}}{{calculated_status === 'SCHEDULED' ? ' scheduled tab' : ' current tab'}}</div>
            <div v-else>{{option.info}}</div>
        </div> -->
    </div>
</div>
</template>

<script>
export default {
    props: {
        buttons: Array,
        calculated_status: String
    },
    methods: {
        update: function(type) {
            this.$emit('update', type)
        },
        delete_button: function() {
            this.$emit('delete_button')
        }
    }
}
</script>

<style scoped>
.full-width-ms {
    width: calc(100% - 2rem);
}

.width-10 {
    width: 10%;
}

.width-90 {
    width: 90%;
}
</style>