var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("AdminUniversal", {
        attrs: { component: "title", props: _vm.title_props },
      }),
      _c("KPIChooser", {
        staticClass: "kpi-chooser",
        on: { chosen: _vm.kpi_chosen, reset: _vm.back },
      }),
      _c("AdminUniversal", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.disable_button_visable,
            expression: "disable_button_visable",
          },
        ],
        attrs: { component: "button", props: _vm.button_props },
      }),
      _c("hr"),
      _c("AdminUniversal", {
        attrs: { component: "table", props: _vm.table_props },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }