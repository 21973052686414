<template>
<div>
    <Title
        title="Gross Margin Per Country"
        :location="location"
    />
    <div class="options-bar">

        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />

        <div class="label-container">
            <p>Filter</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms mb">
        <Top8Bar
            :key="top_8.render"

            title="Gross Margin Top 10 Countries"
            :showtitle="!$store.state.global.bot_mode"
            subtitle=""
            :xcategories="top_8.xcategories"
            :yaxislabel="filter"
            ymetric="%"

            seriesname1="YTD"
            seriesname3="4WK"
            seriesname4="Year Goal"

            seriestype1="bar"
            seriestype2="scatter"
            seriestype3="scatter"
            seriestype4="scatter"

            :seriesset1="top_8.seriesset1"
            :color1="$store.state.colors['yellow']"

            :seriesset3="top_8.seriesset2"
            :color3="$store.state.colors['grey']"

            :seriesset4="top_8.seriesset3"
            :color4="$store.state.colors.YG"

            :trend="true"
            :ranking="[]"
            :competitors="[]"
        />
    </div>
</div>
</template>

<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Top8Bar from '@/components/visualisations/bar/Top8Bar.vue';
import Title from '@/components/ui/Title.vue';


export default {
    props: {
        option: String
    },
    components: {
        Buttons,
        DropDown,
        Top8Bar,
        Title
    },
    data: function() {
        return {
            top_8: {
                render: false,

                xcategories: [],

                seriesset1: [],                
                seriesset2: [],
                seriesset3: [],
                seriesset4: []
            },
            location: '/finance/gross-margin-per-country',
            options: [],
            values: [],
            filter: null,
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            return [
                {
                    name: 'By Country',
                    link: `${this.location}/by-country/${this.filter_formatted}`
                },
                {
                    name: 'By Market',
                    link: `${this.location}/${this.$store.getters.market}`
                },
                {
                    name: 'Top 10',
                    link: `${this.location}/top-8/${this.filter_formatted}`
                }
            ];
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'fin-gm-1'
            }).then(
                succ => {
                    succ.data.map(filter => {
                        this.values.push(filter.value);
                        this.options.push(filter.option);
                    });
                    this.filter = this.$store.getters.filter_check(this.values);
                    this.load_ingka_by_country();                 
                },
                this.$helper.error
            );
        },
        load_ingka_by_country: function() {
            this.$http.post(`/finance/gross-margin-per-country/top-8`, {filter: this.filter}).then(
                succ => {
                    let d = succ.data;
                    this.top_8.xcategories = [];
                    this.top_8.seriesset1 = [];
                    this.top_8.seriesset2 = [];
                    this.top_8.seriesset3 = [];
                    d.map(row => {
                        this.top_8.xcategories.push(this.$helper.maybe(row.caption));
                        this.top_8.seriesset1.push(this.$helper.maybe(row.measures['YTD']));
                        this.top_8.seriesset2.push(this.$helper.maybe(row.measures['4 Weeks']));
                        this.top_8.seriesset3.push(this.$helper.maybe(row.measures['Year Goal']));
                    });
                    this.top_8.render = true;
                },
                this.$helper.error
            );
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
            this.top_8.render = false;
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        },
        filter: function() {
            this.$router.push(this.filter_url);
            this.load_ingka_by_country();
        },
        '$route': function(to, from) {
            if (to.params.option === 'null') {
                this.$router.push(this.filter_url);
            }
        }
    },
    computed: {
        filter_formatted:  function() {
            return this.filter ? this.filter.replace(/ /g, '-').replace(/_/g, '-') : null;
        },
        filter_url: function() {
            return `${this.location}/top-8/${this.filter_formatted}`
        }
    }
}
</script>