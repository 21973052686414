var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show_notification && !!_vm.notification_content,
          expression: "show_notification && !!notification_content",
        },
      ],
      staticClass: "notification",
    },
    [
      _c("div", { staticClass: "content-wrapper" }, [
        _c("p", { staticClass: "announcement-container" }, [
          _c("i", {
            staticClass:
              "fas fa-exclamation-triangle is-size-4 has-text-danger",
          }),
          _c("span", {
            staticClass: "announcement-body has-text-danger",
            domProps: { innerHTML: _vm._s(_vm.notification_content) },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }