var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", { attrs: { title: "HFB", location: _vm.location } }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons() },
          }),
          _c(
            "div",
            { staticClass: "label-container" },
            [
              _c("p", [_vm._v("HFB")]),
              _vm.hfbs.all.length > 0
                ? _c("DropDown", {
                    key: _vm.hfbs.all.length,
                    attrs: {
                      options: _vm.hfbs.options,
                      values: _vm.hfbs.values,
                      default: _vm.hfbs.values[1],
                    },
                    on: { updateDropDown: _vm.update_hfb },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "label-container",
              class: !_vm.$store.getters.market ? "ft-tooltip" : null,
              attrs: {
                tooltiptext: !_vm.$store.getters.market
                  ? "Select a country from top filter to see stores"
                  : null,
              },
            },
            [
              _c("p", [_vm._v("Market Area")]),
              _c("DropDown", {
                key: _vm.stores.values.length,
                attrs: {
                  options: _vm.stores.options,
                  values: _vm.stores.values,
                  default: _vm.stores.values[0],
                },
                on: { updateDropDown: _vm.update_store },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                key: _vm.$store.getters.market,
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: _vm.$store.getters.market,
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size,
          staticClass: "section three-quarters-height box ms",
        },
        [
          _vm.triple.render
            ? _c("QuadStack", {
                key: _vm.triple.render,
                attrs: {
                  title: `Showing data for: ${_vm.$store.getters.selected_country_name}`,
                  showtitle: !_vm.$store.state.global.bot_mode,
                  subtitle: "",
                  xcategories: _vm.triple.xcategories,
                  yaxislabel: "Growth Contribution YTD",
                  ymetric: "%",
                  seriesname1: "Store Comp.",
                  seriestype1: "bar",
                  seriesset1: _vm.triple.seriesset1,
                  color1: _vm.$store.state.colors["yellow"],
                  seriesname2: "Store Non-Comp.",
                  seriestype2: "bar",
                  seriesset2: _vm.triple.seriesset2,
                  color2: _vm.$store.state.colors["grey"],
                  seriesname3: "Online",
                  seriestype3: "bar",
                  seriesset3: _vm.triple.seriesset3,
                  color3: "#787878",
                  seriesname4: "Remote",
                  seriestype4: "bar",
                  seriesset4: _vm.triple.seriesset4,
                  color4: "#008000",
                },
              })
            : _c("div", [
                _vm._v(
                  " No data found for " + _vm._s(_vm.$store.getters.market)
                ),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }