import { render, staticRenderFns } from "./DeleteModal.vue?vue&type=template&id=61f1e56c"
import script from "./DeleteModal.vue?vue&type=script&lang=js"
export * from "./DeleteModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('61f1e56c')) {
      api.createRecord('61f1e56c', component.options)
    } else {
      api.reload('61f1e56c', component.options)
    }
    module.hot.accept("./DeleteModal.vue?vue&type=template&id=61f1e56c", function () {
      api.rerender('61f1e56c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/bespoke/commenting/DeleteModal.vue"
export default component.exports