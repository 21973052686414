var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", {
        attrs: { title: "Hf Accessories", location: _vm.location },
      }),
      _c(
        "div",
        { staticClass: "options-bar" },
        [
          _c("Buttons", {
            staticClass: "mt1p5 is-pulled-left",
            attrs: { buttons: _vm.generate_buttons() },
          }),
          _c(
            "div",
            { staticClass: "label-container hidden-on-pc" },
            [
              _c("p", [_vm._v("Country")]),
              _c("DropDown", {
                key: _vm.$store.getters.market,
                attrs: {
                  options: _vm.$store.state.countries.options,
                  values: _vm.$store.state.countries.values,
                  default: _vm.$store.getters.market,
                },
                on: { updateDropDown: _vm.update_view },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.$store.getters.page_size,
          staticClass: "section three-quarters-height box ms",
        },
        [
          _vm.sextuple.render
            ? _c("Sextuple", {
                key: _vm.sextuple.render,
                attrs: {
                  title: `Showing data for: ${_vm.$store.getters.selected_country_name}`,
                  showtitle: !_vm.$store.state.global.bot_mode,
                  xcategories: _vm.sextuple.xcategories,
                  yaxislabel: "Sales TY/LY",
                  ymetric: "",
                  seriesname1: "HFA, Sales YTD Index",
                  seriestype1: "line",
                  seriesset1: _vm.sextuple.seriesset1,
                  color1: "#6B66C2",
                  seriesname2: "HFA, Qty YTD Index",
                  seriestype2: "line",
                  seriesset2: _vm.sextuple.seriesset2,
                  color2: "#393678",
                  seriesname3: "HFA, Goal Index",
                  seriestype3: "line",
                  seriesset3: _vm.sextuple.seriesset3,
                  color3: _vm.$store.state.colors.YG,
                  symbol3: "none",
                  seriesname4: "Total Range, Sales YTD Index",
                  seriestype4: "line",
                  seriesset4: _vm.sextuple.seriesset4,
                  color4: _vm.$store.state.colors["focus-yellow"],
                  seriesname5: "Total Range, Qty YTD Index",
                  seriestype5: "line",
                  seriesset5: _vm.sextuple.seriesset5,
                  color5: "#CC8400",
                  seriestype6: "scatter",
                  show: !_vm.$store.state.global.bot_mode,
                },
              })
            : _c("div", [
                _vm._v(
                  " No data found for " +
                    _vm._s(_vm.$store.getters.selected_country_name)
                ),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }