var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _c("div", { staticClass: "modal-background" }),
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head pap5" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
        _vm.closeable
          ? _c("button", {
              staticClass: "delete",
              attrs: { "aria-label": "close" },
              on: { click: _vm.close_modal },
            })
          : _vm._e(),
      ]),
      _c("section", { staticClass: "modal-card-body" }, [_vm._t("content")], 2),
      _c(
        "footer",
        { staticClass: "modal-card-foot pap5" },
        [_vm._t("buttons")],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }