var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "table" } }, [
    _c("div", { staticClass: "table-container" }, [
      _c("table", { staticClass: "custom table desktop" }, [
        _c(
          "thead",
          _vm._l(_vm.headers, function (row, header_row) {
            return _c(
              "tr",
              { key: header_row },
              _vm._l(row, function (cell, header_index) {
                return _c(
                  "th",
                  {
                    key: header_index,
                    staticClass: "ikea-yellow text-black",
                    attrs: { colspan: Object.values(cell).shift() },
                    on: {
                      click: function ($event) {
                        _vm.headers.length - 1 == header_row
                          ? _vm.sort(header_index)
                          : ""
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(Object.keys(cell).shift()) + " ")]
                )
              }),
              0
            )
          }),
          0
        ),
        _c(
          "tbody",
          _vm._l(_vm.rows, function (row, row_index) {
            return _c(
              "tr",
              {
                key: row_index,
                class: row_index === _vm.selected_row ? "is-selected" : "",
                on: {
                  click: function ($event) {
                    return _vm.select_row(row, row_index)
                  },
                },
              },
              _vm._l(row, function (cell, column_index) {
                return _c(
                  "td",
                  { key: column_index, staticClass: "no-text-wrap" },
                  [_c("div", { domProps: { innerHTML: _vm._s(cell.html) } })]
                )
              }),
              0
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }