<template>
<div>
	<Title 
		title="Market Share"
		:location="location"
	/>
	<div class="options-bar">
		<Buttons
			:buttons="generate_buttons()"
			:dataset="table"
			class="mt1p5 is-pulled-left"
		/>
		<div class="label-container">
			<p>Channel</p>
			<DropDown
				:key="filter2"
				:options="filter2_options"
				:values="filter2_values"
				:default="filter2"
				@updateDropDown="update_filter2"
			/>
		</div>
		<div class="label-container">
			<p>Index / Value</p>
			<DropDown
				:key="filter1"
				:options="filter1_options"
				:values="filter1_values"
				:default="filter1"
				@updateDropDown="update_filter1"
			/>
		</div>
		<div class="label-container hidden-on-pc">
			<p>Country</p>
			<DropDown
				:key="$store.getters.market"
				:options="$store.state.countries.options"
				:values="$store.state.countries.values"
				:default="$store.getters.market"
				@updateDropDown="update_view"
			/>
		</div>
	</div>
	<Table
		v-if="table.render"
		:headers="table.headers"
		:rows="table.rows"
		:decimal-settings="table.decimal_settings"
		:icon-settings="table.icon_settings"
		:data-settings="{ nullify_zeros: true }"
		:font-settings="{ bold: { row: { regex: 'Total Retail Countries' } } }"
		:sort-settings="{ Country: { asc: true } }"
		class="ms1p5"
	/>
</div>
</template>

<script>
import Buttons from "@/components/ui/Buttons.vue";
import DropDown from "@/components/ui/DropDown.vue";
import Table from "@/components/visualisations/table/tablev2/TableV2.vue";
import Title from "@/components/ui/Title.vue";

export default {
	components: {
		Buttons,
		DropDown,
		Table,
		Title,
	},
	data: function () {
		return {
		filter1: "Value",
		filter1_options: ["Value", "Index"],
		filter1_values: ["Value", "Index"],
		filter2: "Total",
		filter2_options: ["Total", "Online", "Offline"],
		filter2_values: ["Total", "Online", "Offline"],
		table: {
			headers: [],
			rows: [],
			icon_settings: {},
			decimal_settings: {},
			render: false,
		},
		location: "/business/market-share",
		};
	},
	mounted: function () {
		this.$store.commit("set_routing", false);
		this.get_data();
	},
	methods: {
		generate_buttons: function () {
		return [
			{
			name: "By Country",
			link: this.location,
			},
			{
			name: "Trend",
			link: `${this.location}/${this.$store.getters.market}`,
			},
		];
		},
		get_data: function () {
		this.table.render = false;
		this.$http.post("/business/market-share-calculator/total-ingka-by-country", {
			filter1: this.filter1,
			filter2: this.filter2,
		}).then((succ) => {
			let d = succ.data;
			let columns = Object.keys(d[0].measures);
			this.table.decimal_settings = {
				global: this.filter1 === "Index" ? 0 : 1,
			};
			this.table.headers = [
				["Country"].concat(columns).map((c) => {
					return { [c]: 1 };
				}),
			];
			this.table.rows = d.map((row) => {
				let temp = [];
				// If the key/values don't exist, set them to 0
				columns.map((key) => {
				temp.push(this.$helper.maybe(row.measures[key]));
				});
				return [row.caption].concat(temp);
			});

			this.table.icon_settings = {};
			const has_goal = columns.includes("Goal");
			let extra_percentages_filter2 = [ 'Online', 'Offline' ];
			columns.map((key) => {
				let tmp = {};
				if (key === columns[extra_percentages_filter2.indexOf(this.filter2) >= 0 ? columns.length : columns.length - 2]) {
					tmp[key] = {
						icon: has_goal ? "circle" : "arrow",
						comparison: has_goal ? columns[columns.length - 1] : null,
						fix: { post: this.filter1 === "Value" ? "%" : "" },
					};
				} else {
					tmp[key] = {
						fix: { post: this.filter1 === "Value" ? "%" : "" },
					};
				}
				this.table.icon_settings = Object.assign(this.table.icon_settings, tmp);
			});
			this.table.render = true;
		},
			this.$helper.error
		);
		},
		update_filter1: function (filter) {
			this.filter1 = filter;
			this.table.render = false;
			this.get_data();
		},
		update_filter2: function (filter) {
			this.filter2 = filter;
			this.table.render = false;
			this.get_data();
		},
		update_view: function (market) {
			this.$store.commit("update_view", {
				market: market,
				location: this.location,
			});
		},
	},
	watch: {
		"$store.getters.market": function (newMarket, oldMarket) {
			this.update_view(newMarket);
		}
	}
};
</script>
