<template>
<div>
    <Title title="Source to Pay indirect (S2Pi)" :location="location" />
    <div class="options-bar">
        <Buttons
            :dataset="table"
            class="mt1p5 is-pulled-left"
        />
        <div class="label-container">
            <p>Filter</p>
            <DropDown
                :key="filter"
                :options="options"
                :values="values"
                :default="filter"
                @updateDropDown="update_filter"
            />
        </div>
        <div class="label-container hidden-on-pc">
            <p>Country</p>
            <DropDown  
                :key="$store.getters.market"
                :options="$store.state.countries.options"
                :values="$store.state.countries.values"
                :default="$store.getters.market"
                @updateDropDown="update_view"
            />
        </div>
    </div>

    <Table
        v-if="table.render"
        :key="table.render"

        :headers="table.headers"
        :rows="table.rows"
        :data-settings="{ nullify_zeros: true }"
        :sort-settings="{ 'Country': { asc: true } }"
        :font-settings="{ bold: {row: {regex: 'Total Retail Countries' } } }"
        :decimal-settings="table.decimal_settings"

        class="ms1p5"
        
        :bridge-settings="table.bridge_settings"
        :icon-settings="table.icon_settings"
    />
</div>
</template>


<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';
import Title from '@/components/ui/Title.vue';

export default {
    components: {
        Buttons,
        DropDown,
        Table,
        Title
    },
    data: function() {
        return {
            table: {
                headers: [],
                rows: [],
                decimal_settings: {},
                render: true
            },
            options: [],
            values: [],
            filter: null,
            location: '/process/procurement',
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', false);
        this.get_filters();
    },
    methods: {
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'procurement-area-1'
            }).then(
                succ => {
                    succ.data.map(filter => {
                        this.values.push(filter.value);
                        this.options.push(filter.option);
                    });
                    
                    let filter = this.$store.state.global.temp_filter;
                
                    if (this.values.indexOf(filter) > 0) {
                        this.filter = filter;
                    } else {
                        if (this.values[0]) {
                            this.filter = this.values[0];
                        }
                    }
                    this.get_data();
                },
                this.$helper.error
            );
        },
        get_data: function() {
            this.table.render = false;
            if (this.filter) {
                this.$http.post('process/procurement/total', {
                    market: 'ALL',
                    filter: this.filter
                }).then(
                    succ => {
                        if (!succ.data) return;

                        let d = succ.data;
                        if (d[0].measure_metadata) {
                            this.table.decimal_settings = d[0].measure_metadata.decimal_settings || {};
                        }
                        this.table.headers, this.table.rows = [];

                        // Setup the headers
                        this.table.headers = [
                            // Row 1
                            [{'': 1}, {'Purchase Order (PO) Compliance %': 3}, {'No. of Suppliers Used': 3}],
                            //Row 2
                            [
                                {'Country': 1},

                                {'YTD': 1, alias: 'PO YTD'},
                                {'Year Goal': 1, alias: 'PO Goal'},
                                {'Dev. YTD - LY': 1, alias: 'PO YTD-LY'},

                                {'12M': 1, alias: 'Suppliers YTD'},
                                {'Year Goal': 1, alias: 'Suppliers Goal'},
                                {'Dev. 12M - LY': 1, alias: 'Suppliers YTD-LY'}
                            ]
                        ];

                        this.table.bridge_settings = {
                            columns: {
                                'Purchase Order (PO) Compliance %': { hide: true },
                                'No. of Suppliers Used': { hide: true }
                            }
                        }

                        this.table.icon_settings = {
                            'PO YTD': {
                                comparison: 'PO Goal',
                                icon: 'circle',
                                fix: {
                                    post: '%',
                                },
                                colors: {
                                    positive: '#0F0',
                                    equal: '#00F',
                                    negative: '#F00',
                                }
                            },
                            'PO Goal': {
                                fix: {
                                    post: '%',
                                },
                            },
                            'PO YTD-LY': {
                                comparison: 'PO YTD-LY',
                                icon: 'bar',
                                colors: {
                                    positive: '#0F0',
                                    equal: '#00F',
                                    negative: '#F00',
                                },
                                ignore: ['Total Retail Countries']
                            },
                            'Suppliers YTD': {
                                comparison: 'Suppliers Goal',
                                icon: 'circle',
                                reversed: true,
                                colors: {
                                    positive: '#0F0',
                                    equal: '#00F',
                                    negative: '#F00',
                                }
                            },
                            'Suppliers YTD-LY': {
                                comparison: 'Suppliers YTD-LY',
                                ignore: ['Total Retail Countries'],
                                icon: 'bar',
                                reversed: true,
                                colors: {
                                    positive: '#0F0',
                                    equal: '#00F',
                                    negative: '#0F0',
                                }
                            },
                        }

                        // Populate the rows
                        let keys = Object.keys(d[0].measures);
                        
                        this.table.rows = d.map(row => {
                            let temp = [];
                            // If the key/values don't exist, set them to 0
                            keys.map(key => {
                                if (row.measures) {
                                    temp.push
                                    (
                                        this.$helper.maybe(row.measures[key]['YTD']),
                                        this.$helper.maybe(row.measures[key]['Year Goal']),
                                        this.$helper.maybe(row.measures[key]['Development YTD - Full LY'])
                                    );
                                } else {
                                    temp.push("", "", ""); // Push three empty elements
                                } 
                            });
                            return [row.caption].concat(temp);
                        });
                        this.table.render = true;
                    },
                    this.$helper.error
                );
            }
        },
        update_filter: function(filter) {
            this.filter = filter;
            this.$store.state.global.temp_filter = filter;
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: this.location
            });
        }
    },
    watch: {
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
        },
        filter: function() {
            this.get_data();
        }
    }
}
</script>
