<template>
<div class="field">
  	<input id="switchRounded" type="checkbox" class="switch is-rounded is-normal is-success" @click="toggle_edit()">
  	<label for="switchRounded">
		<i v-if="!disable_icon" class="fas fa-edit"></i>
		<i v-if="this.$store.state.global.show_edit === false && !disable_icon" class="fas fa-slash"></i>
	</label>
</div>
</template>

<script>
export default {
	props: {
		disable_icon: Boolean,
	},
	mounted:function() {
		let checkbox = document.getElementsByTagName('input');
		checkbox.switchRounded.checked = this.$store.state.global.show_edit;
	},
	methods: {
		toggle_edit:function() {
			this.$store.state.global.show_edit = !this.$store.state.global.show_edit
		}
	}
}
</script>

<style scoped>
.fa-edit{
	font-size: 1.3rem;
	position: absolute;
	top: 0;
}

.fa-slash {
	position: absolute;
    top: 0.05rem;
    right: -1.4rem;
    transform: rotate(5deg);
    font-size: 1.3rem;
}
</style>