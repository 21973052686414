var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("AdminUniversal", {
        attrs: { component: "title", props: _vm.title_props },
      }),
      _c("AdminUniversal", {
        attrs: { component: "textinput", props: _vm.input_props },
      }),
      _c("AdminUniversal", {
        attrs: { component: "button", props: _vm.button_props1 },
      }),
      _c("AdminUniversal", {
        attrs: { component: "button", props: _vm.button_props2 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }