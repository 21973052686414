<template>
<div>
    <Title
        title="Services Net Waterfall"
        :location="location"
    />
    <div class="options-bar">
        <Buttons
            :buttons="generate_buttons()"
            class="mt1p5 is-pulled-left"
        />
        <div class="label-container">
            <p>Filter</p>
            <DropDown
                :key="filter.comparison.state"
                :options="filter.comparison.options"
                :values="filter.comparison.values"
                :default="filter.comparison.state"
                @updateDropDown="update_filter_comparison"
            />
        </div>
        <div class="label-container">
            <p>After Sales</p>
            <DropDown
                :key="filter.after_sales.state"
                :options="filter.after_sales.options"
                :values="filter.after_sales.values"
                :default="filter.after_sales.state"
                @updateDropDown="update_filter_after_sales"
            />
        </div>
    </div>
    <div :key="$store.getters.page_size" class="section three-quarters-height box ms mb">
        <Waterfall
            v-if="graph.render"
            title="Gross Margin Waterfall"
            :showtitle="true"
            :seriesset="seriesset"
            yaxislabel=""
            ymetric=""
            seriesname="GM Deviation"
            seriestype="bar"
            :totals="this.filter_split"
            :remove="['GM YTD', 'GM']"
            :show="true"
        />
    </div>
</div>
</template>


<script>
import Buttons from '@/components/ui/Buttons.vue';
import DropDown from '@/components/ui/DropDown.vue';
import Title from '@/components/ui/Title.vue';
import Waterfall from '@/components/visualisations/general/WaterFall.vue';
export default {
    components: {
        Buttons,
        DropDown,
        Waterfall,
        Title
    },
    props: {
        market: String,
        location: String
    },
    data: function() {
        return {
            filter: {
                after_sales: {
                    state: null,
                    options: [],
                    values: []
                },
                comparison: {
                    state: null,
                    options: [],
                    values: []
                }
            },
            graph: {
                seriesset: null,
                render: false
            }
        }
    },
    mounted: function() {
        this.$store.commit('set_routing', true);
        this.get_filters();
    },
    methods: {
        generate_buttons: function() {
            let buttons = [
                {
                    name: 'By Country',
                    link: this.location
                },
                {
                    name: 'By Service',
                    link: `${this.location}/by-market/${this.$store.getters.market}`
                },
                {
                    name: 'Waterfall',
                    link: `${this.location}/waterfall/${this.$store.getters.market}`
                },
                {
                    name: 'By Service (Graph)',
                    link: `${this.location}/by-market-graph/${this.$store.getters.market}`
                }
            ];
            if (this.location === '/finance/service-net/gm-deviation') {
                buttons.splice(3, 0, {
                    name: 'By Country (Graph)',
                    link: `${this.location}/graph`
                });
            }
            return buttons;
        },
        get_filters: function() {
            this.$http.post('/filters/load', {
                filter: 'service-net-after-sales',
                disable_sort: true
            }).then(
                succ => {
                    succ.data.forEach(filter => {
                        this.filter.after_sales.values.push(filter.value);
                        this.filter.after_sales.options.push(filter.option);
                    });
                    this.filter.after_sales.state = this.$store.getters.filter_check(this.filter.after_sales.values);
                },
                this.$helper.error
            );

            this.$http.post('/filters/load', {
                filter: 'service-net-waterfall-comparison',
                disable_sort: true
            }).then(
                succ => {
                    succ.data.forEach(filter => {
                        this.filter.comparison.values.push(filter.value);
                        this.filter.comparison.options.push(filter.option);
                    });
                    this.filter.comparison.state = this.$store.getters.filter_check(this.filter.comparison.values);
                },
                this.$helper.error
            );
        },
        load_by_market_data: function() {
            this.graph.render = false;
            if (this.filter.after_sales.state === null || this.filter.comparison.state === null) return;
            this.$http.post('finance/service-net-dashboard/waterfall', {
                after_sales_filter: this.filter.after_sales.state,
                comparison_filter: this.filter.comparison.state,
                market: this.$store.getters.market
            }).then(
                succ => {
                    let d = succ.data;
                    if (!d || !d[0] || !d[0].measures) return;
                    let temp = d[0].measures;
                    temp[this.filter_split[0]] = temp['GM YTD'];
                    temp[this.filter_split[1]] = temp['GM'];
                    this.seriesset = temp;
                    this.graph.render = true;
                },
                this.$helper.error
            );
        },
        update_filter_after_sales: function(filter) {
            this.filter.after_sales.state = filter;
            this.$store.state.global.temp_after_sales_filter = filter;
        },
        update_filter_comparison: function(filter) {
            this.filter.comparison.state = filter;
            this.$store.state.global.temp_comparison_filter = filter;
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market: market,
                location: `${this.location}/waterfall`
            });
        }
    },
    watch: {
        '$store.getters.countries': function() {
            this.$store.commit('update_market', this.market);
        },
        '$store.getters.market': function(newMarket, oldMarket) {
            this.update_view(newMarket);
            this.load_by_market_data();
        },
        'filter.after_sales.state': function() {
            this.load_by_market_data();
        },
        'filter.comparison.state': function() {
            this.load_by_market_data();
        }
    },
    computed: {
        filter_split: function() {
            let filters = this.filter.comparison.state.split('_to_');
            return [filters[0].toUpperCase(), filters[1].toUpperCase()]
        }
    }
}
</script>

<style>

</style>