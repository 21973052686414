var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("AdminUniversal", {
        attrs: { component: "title", props: _vm.title_props },
      }),
      _c(
        "div",
        { staticClass: "flex" },
        [
          _c("DropDown", {
            key: _vm.filter,
            staticClass: "mb",
            attrs: {
              options: _vm.kpi_options,
              values: _vm.kpi_values,
              default: _vm.filter,
            },
            on: { updateDropDown: _vm.update_kpi_area },
          }),
          _c("CommentKey", { attrs: { status_colors: _vm.status_colors } }),
        ],
        1
      ),
      _vm.render
        ? _c(
            "section",
            { staticClass: "accordions accordians-height vertical-scroll" },
            _vm._l(_vm.all_countries, function (country, index) {
              return _c(
                "article",
                {
                  key: index,
                  staticClass: "accordion",
                  class: `${_vm.active === country.market ? "is-active" : ""}`,
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "accordion-header relative",
                      on: {
                        click: function ($event) {
                          return _vm.toggle_active(country.market)
                        },
                      },
                    },
                    [
                      _vm._v(" " + _vm._s(country.caption) + " "),
                      _c("div", {
                        staticClass: "status-badge",
                        style: `background-color:${
                          _vm.status_colors[country.status]
                        }`,
                      }),
                      _c("i", {
                        class: `${
                          _vm.active === country.market
                            ? "fas fa-chevron-up"
                            : "fas fa-chevron-down"
                        }`,
                      }),
                    ]
                  ),
                  _c("div", { staticClass: "accordion-body cursor-default" }, [
                    _c(
                      "div",
                      { staticClass: "accordion-content bg-white" },
                      _vm._l(country.insights, function (comment, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "insight columns is-gapless" },
                          [
                            _c("div", { staticClass: "column" }, [
                              _vm._v("Status: " + _vm._s(comment.status)),
                            ]),
                            _c("div", { staticClass: "column" }, [
                              _vm._v("Title: " + _vm._s(comment.title)),
                            ]),
                            _c("div", { staticClass: "column" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    comment.status === "LIVE"
                                      ? "Started: "
                                      : "Starting: "
                                  ) +
                                  _vm._s(
                                    _vm.display_date(comment.scheduled_date)
                                  ) +
                                  " "
                              ),
                              _c("br"),
                              _c("b", {}, [_vm._v("-")]),
                              _c("br"),
                              _vm._v(
                                " Ending: " +
                                  _vm._s(
                                    _vm.display_date(comment.comment_lifetime)
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("div", { staticClass: "column" }, [
                              _vm._v("Author: " + _vm._s(comment.display_name)),
                            ]),
                            _c("div", { staticClass: "column" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "button is-success content-button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.open_modal(comment.content_id)
                                    },
                                  },
                                },
                                [_vm._v("Content")]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "modal",
                                class: `${
                                  _vm.active_modal === comment.content_id
                                    ? "is-active"
                                    : ""
                                }`,
                              },
                              [
                                _c("div", { staticClass: "modal-background" }),
                                _c(
                                  "div",
                                  { staticClass: "modal-content relative" },
                                  [
                                    _c("button", {
                                      staticClass: "delete top-right",
                                      attrs: { "aria-label": "close" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.close_modal()
                                        },
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "modal-head pap5" },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "modal-card-title" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(comment.title) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(comment.content),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }