<template>
<div class="nps" v-if="nudge">
    <div class="nps-button" @click="open_survey">{{opened ? 'X' : 'Survey'}}</div>
    
    <Modal
        :title="meta.name"
        :closeable="true"
        :class="{'is-active': opened}"

        @close="close_modal"
    >
        <template v-slot:content>
            <div class="content is-medium">
                <div v-for="(question, index) in questions" :key="index">
                    <div class="text-left text-bold mb">
                        {{index + 1}}) {{question.question}}
                        <div class="inline-block text-normal">{{question.optional ? '(Optional)' : ''}}</div>
                    </div>

                    <div v-if="question.answer_range.type === 'comment'">
                        <textarea rows="1" class="textarea" v-model="question.answer"></textarea>
                    </div>
                    <div v-else-if="question.answer_range.type === '0-10'">
                        <div class="inline-block pr">Bad</div>
                        <div v-for="(num, i) in 11" :key="i" class="inline-block pr">
                            <input type="radio" :id="`${index} ${num}`" class="mrp5 cursor-pointer" :name="index" :value="i" v-model="question.answer" />
                            <label :for="`${index} ${num}`" class="cursor-pointer">{{i}}</label>
                        </div>
                        <div class="inline-block">Good</div>
                    </div>
                    <hr>
                </div>
            </div>
        </template>

        <template v-slot:buttons>
            <div class="flex-space-between full-width">
                <a class="button is-danger text-white" @click="dont_show">Don't show this questionnaire again</a>
                <a class="button ikea-blue text-white" @click="submit">Submit</a>
            </div>
        </template>
    </Modal>
    
</div>
</template>
<script>
import Modal from '@/components/ui/Modal.vue';
export default {
    components: {
        Modal
    },
    data: function() {
        return {
            opened: false,
            meta: null,
            nudge: false,
            questions: null,
        }
    },
    mounted: function() {
        this.load_meta();
    },
    methods: {
        close_modal: function() {
            this.opened = false;
        },
        dont_show: function() {
            this.$http.post('/nps/forms/form/close', {
                form_id: this.meta.id
            }).then(
                succ => {
                    this.nudge = false;
                    this.load_meta();
                },
                err => console.log(err)
            );
        },
        load_meta: function() {
            this.$http.get('force', '/nps/meta/check').then(
                succ => {
                    this.meta = succ.data;
                    if (this.meta) {
                        // Show the form if meta doesn't contain 'status' and 
                        // when the access form isn't showing
                        // also hidden during the presentation mode
                        this.nudge = !('status' in this.meta) &&
                            !this.$store.getters.access_request_show &&
                            !this.$store.getters.presentation_mode;
                    }
                },
                error => console.log(err)
            );
        },
        submit: function() {
            // Check if there are any non-optional questions left
            if (this.questions.filter(q => !q.optional && !q.answer).length > 0) {
                return alert('Please answer all non-optional questions before submitting.');
            }
            this.$http.post('/nps/forms/form/answer', {
                form_id: this.meta.id,
                questions: this.questions.filter(q => !!q.answer)
            }).then(
                succ => this.load_meta(),
                err => console.log(err)
            );
        },
        open_survey: function() {
            this.opened = !this.opened;
            this.$http.post('/nps/questionnaire/load', {
                form_id: this.meta.id
            }).then(
                succ => this.questions = succ.data,
                error => console.log(err)
            );
        }
    }
}
</script>

<style scoped>
.nps {
    bottom: 0;
    position: fixed;
    width: 350px;
    z-index: 998;
    display: none;
}

.nps-button {
    background-color: #FFF;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    border-radius: 100%;
    bottom: 2rem;
    box-shadow: 0px 2px 3px #110f0f;
    color: #000;
    cursor: pointer;
    font-size: 0.8rem;
    height: 3rem;
    left: 2rem;
    line-height: 3rem;
    user-select: none;
    position: inherit;
    width: 3rem;
}
</style>