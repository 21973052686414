/** Data Settings Module */
export default {
    props: Object,
    logic: function (props, data) {
        this.data = {...props, ...data};
        let headers = this.data.parsed_headers;
        let rows = this.data.parsed_rows;

        if (this.data.nullify_zeros) {
            rows.forEach(row => {
                row.forEach(cell => { cell.value === 0 ? cell.value = null : cell; });
            });
        }

        return rows;
    }
}