var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", { attrs: { title: "Flow Matrix", location: _vm.location } }),
      _c("div", { staticClass: "options-bar" }, [
        _c(
          "div",
          { staticClass: "label-container" },
          [
            _c("p", [_vm._v("Filter by HFB")]),
            _c("DropDown", {
              key: _vm.$store.getters.market,
              attrs: {
                options: _vm.$store.getters.hfbs.options,
                values: _vm.$store.getters.hfbs.values,
                default: _vm.hfb || "Total HFB",
              },
              on: { updateDropDown: _vm.update_hfb },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "section p0 box ms" },
        [
          _vm.render
            ? _c("FlowMatrix", { attrs: { matrix: _vm.matrix } })
            : _c("div", [
                _vm._v(
                  " No data found for HFB " +
                    _vm._s(_vm.hfb) +
                    " for the country: " +
                    _vm._s(_vm.$store.getters.selected_country_name) +
                    " "
                ),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }